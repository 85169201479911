import React from 'utils/react';
import Button from 'components/Button';
import withTranslation from 'utils/react/withTranslation';
import ButtonsWrap from './ButtonsWrap';

import Title from './Title';
import Container from './Container';

const Panel = ({
  t,
  errors = [],
  warnings = [],
  fixes = [],
  clearCommuteOfferWarnings,
}) => (
  <Container>
    <Title>
      {[
        t('p.Editor.ErrorReport.Errors', { count: errors.length }),
        t('p.Editor.ErrorReport.Warnings', { count: warnings.length }),
        t('p.Editor.ErrorReport.Fixes', { count: fixes.length }),
      ].join(', ')}
    </Title>
    {(errors.length === 0 ||
      global.GEODISC_COMMUTE_OFFER_ERROR_REPORTING_DISABLED) && (
      <ButtonsWrap>
        <Button
          onClick={() => {
            if (errors.length > 0) {
              global.openInfoMessage(
                [
                  'You have open a commute offer with errors.',
                  'The behavior of the application will be unpredictable.',
                ].join(' '),
                { title: 'Warning' }
              );
            }
            clearCommuteOfferWarnings();
          }}
        >
          Continue
        </Button>
      </ButtonsWrap>
    )}
  </Container>
);

export default withTranslation()(Panel);

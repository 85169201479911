import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { duplicateCommuteOffer } from 'modules/commuteOffer/actions';

import { currentProjectUrlSelector } from 'modules/user/selectors';

import DuplicateCommuteOffer from './DuplicateCommuteOffer';

const mapDispatchToProps = {
  duplicateCommuteOffer,
};

const mapStateToProps = createImmutableSelector(
  currentProjectUrlSelector,
  projectUrl => ({
    projectUrl,
  })
);

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(DuplicateCommuteOffer);

// @flow
import { memoize } from 'utils/memoize';
import createImmutableSelector from 'utils/immutable-selector';
// import createTransientSelector from 'utils/transient-selector';
import createObjectSelector from 'utils/object-selector';

import moment from 'moment-timezone';

import {
  calculateVehicleRoute,
  getVehicleOnlineStatus,
} from 'utils/CommuteOffer';
import { removeInternalFieldsFromObject } from 'utils';

import { stopsSelector } from 'modules/entities/selectors';
import {
  editableBookingIdSelector,
  editableVehicleIdSelector,
  searchQuerySelector,
  bookingsFilterSelector,
  vehiclesFilterSelector,
  activeVehicleIdsSelector,
  activeBookingIdSelector,
  commuteOffersIsTemplateDataSourceSelector,
  commuteOffersServiceDateSelector,
} from 'modules/ui/selectors';
import {
  isDeliveryLayoutSelector,
  isCurrentDateEditableSelector,
  capacityFunctorSelector,
  currentProjectMemberInfoSelector,
  currentUserAllowChangeSelector,
  currentProjectLogisticsSettingsSelector,
} from 'modules/user/selectors';
import { colorByVehicle } from 'utils/color';

import debug from 'utils/debug';
import {
  capacityConversionFunctor,
  capacityFunctor,
  convertDemandLoadsToDisplay,
} from 'utils/Logistics/conversions';
import { NODE_STATUSES } from 'utils/constants';

const D2 = debug('m:CommuteOffer:selectors');

export const commuteOfferStateSelector = state =>
  D2.S.FUNCTION('commuteOfferStateSelector', { state }, () => state);

export const commuteOfferStoreSelector = state =>
  D2.S.FUNCTION('commuteOfferStoreSelector', { state }, () =>
    state.getIn(['commuteOffer'])
  );

export const isEditingInProgressSelector = createImmutableSelector(
  commuteOfferStoreSelector,
  commuteOffer =>
    D2.S.SELECTOR('isEditingInProgressSelector', { commuteOffer }, () =>
      commuteOffer.get('isEditingInProgress')
    )
);

export const commuteOfferVehiclesRealtimeDataSelector = createImmutableSelector(
  commuteOfferStoreSelector,
  commuteOffer =>
    D2.S.SELECTOR(
      'commuteOfferVehiclesRealtimeDataSelector',
      { commuteOffer },
      () => commuteOffer.get('vehiclesRealtimeData')
    )
);

export const commuteOfferNodesRealtimeDataSelector = createImmutableSelector(
  commuteOfferStoreSelector,
  commuteOffer =>
    D2.S.SELECTOR(
      'commuteOfferNodesRealtimeDataSelector',
      { commuteOffer },
      () => commuteOffer.get('nodesRealtimeData')
    )
);

export const commuteOfferLoadedCommuteOffersSelector = createImmutableSelector(
  commuteOfferStoreSelector,
  commuteOffer =>
    D2.S.SELECTOR(
      'commuteOfferLoadedCommuteOffersSelector',
      { commuteOffer },
      () => commuteOffer.get('loadedOffers')
    )
);

export const commuteOfferLlastProcessedDataHashSelector =
  createImmutableSelector(commuteOfferStoreSelector, commuteOffer =>
    D2.S.SELECTOR(
      'commuteOfferLoadedCommuteOffersSelector',
      { commuteOffer },
      () => commuteOffer.get('lastProcessedDataHash')
    )
  );

export const commuteOfferLoadedSimulationsSelector = createImmutableSelector(
  commuteOfferStoreSelector,
  commuteOffer =>
    D2.S.SELECTOR(
      'commuteOfferLoadedSimulationsSelector',
      { commuteOffer },
      () => commuteOffer.get('loadedSimulations')
    )
);

export const commuteOfferLoadedCommuteOfferSelector = createImmutableSelector(
  commuteOfferLoadedCommuteOffersSelector,
  loadedCommuteOffers =>
    D2.S.SELECTOR(
      'commuteOfferLoadedCommuteOfferSelector',
      { loadedCommuteOffers },
      () =>
        loadedCommuteOffers.length === 1
          ? loadedCommuteOffers[0].$source?.simulation
          : null
    )
);

export const commuteOfferLoadedSimulationSelector = createImmutableSelector(
  commuteOfferLoadedSimulationsSelector,
  loadedSimulations =>
    D2.S.SELECTOR(
      'commuteOfferLoadedSimulationsSelector',
      { loadedSimulations },
      () =>
        loadedSimulations.length === 1
          ? loadedSimulations[0].$source?.simulation
          : null
    )
);

export const commuteOfferRemoveAllOrdersResultSelector =
  createImmutableSelector(commuteOfferStoreSelector, commuteOffer =>
    D2.S.SELECTOR(
      'commuteOfferRemoveAllOrdersResultSelector',
      { commuteOffer },
      () => commuteOffer.get('removeAllOrdersResult')
    )
  );

export const commuteOfferRemoveAllOrdersLoadingSelector =
  createImmutableSelector(commuteOfferStoreSelector, commuteOffer =>
    D2.S.SELECTOR(
      'commuteOfferRemoveAllOrdersLoadingSelector',
      { commuteOffer },
      () => commuteOffer.get('removeAllOrdersLoading')
    )
  );

export const commuteOfferIsFilteringEnabledSelector = createImmutableSelector(
  isDeliveryLayoutSelector,
  isDeliveryLayout =>
    D2.S.SELECTOR(
      'commuteOfferIsFilteringEnabledSelector',
      { isDeliveryLayout },
      () => !global.GEODISC_UI_COMMUTE_OFFER_PANEL_FILTERING_DISABLE
    )
);

export const commuteOfferLoadedSimulationIdSelector = createImmutableSelector(
  commuteOfferLoadedSimulationSelector,
  simulation =>
    D2.S.SELECTOR(
      'commuteOfferLoadedSimulationIdSelector',
      { simulation },
      () => (simulation ? simulation.id : null)
    )
);

export const commuteOfferCurrentStorageNonceSelector = createImmutableSelector(
  commuteOfferStoreSelector,
  commuteOffer =>
    D2.S.SELECTOR(
      'commuteOfferCurrentStorageNonceSelector',
      { commuteOffer },
      () => commuteOffer.get('currentStorageNonce')
    )
);

export const commuteOfferCurrentStorageIndexSelector = createImmutableSelector(
  commuteOfferStoreSelector,
  commuteOffer =>
    D2.S.SELECTOR(
      'commuteOfferCurrentStorageIndexSelector',
      { commuteOffer },
      () => commuteOffer.get('currentStorageIndex')
    )
);

export const commuteOfferHistorySelector = createImmutableSelector(
  commuteOfferStoreSelector,
  commuteOfferCurrentStorageIndexSelector,
  commuteOfferCurrentStorageNonceSelector,
  (commuteOffer, currentStorageIndex) =>
    D2.S.SELECTOR('commuteOfferHistorySelector', { commuteOffer }, () => ({
      past: global.GEODISC_COMMUTE_OFFER_STORAGE.slice(0, currentStorageIndex),
      future: global.GEODISC_COMMUTE_OFFER_STORAGE.slice(
        currentStorageIndex + 1
      ),
    }))
);

export const commuteOfferListSelector = createImmutableSelector(
  commuteOfferStoreSelector,
  commuteOffer =>
    D2.S.SELECTOR('commuteOfferListSelector', { commuteOffer }, () =>
      commuteOffer.get('list')
    )
);

export const serialNumberSelector = createImmutableSelector(
  commuteOfferStoreSelector,
  commuteOffer =>
    D2.S.SELECTOR('serialNumberSelector', { commuteOffer }, () => {
      const serialNumber = commuteOffer.get('serialNumber');
      return serialNumber;
    })
);

export const isForceReloadEnabledSelector = createImmutableSelector(
  commuteOfferStoreSelector,
  commuteOffer =>
    D2.S.SELECTOR('isForceReloadEnabledSelector', { commuteOffer }, () =>
      commuteOffer.get('isForceReloadEnabled')
    )
);

export const permissionsSerialNumberSelector = createImmutableSelector(
  commuteOfferStoreSelector,
  commuteOffer =>
    D2.S.SELECTOR('permissionsSerialNumberSelector', { commuteOffer }, () =>
      commuteOffer.get('permissionsSerialNumber')
    )
);

export const countSelector = createImmutableSelector(
  commuteOfferStoreSelector,
  commuteOffer =>
    D2.S.SELECTOR('countSelector', { commuteOffer }, () =>
      commuteOffer.get('count')
    )
);

export const commuteOfferDataStorageSelector = createImmutableSelector(
  commuteOfferStoreSelector,
  commuteOffer =>
    D2.S.SELECTOR(
      'commuteOfferDataStorageSelector',
      { commuteOffer },
      () => global.GEODISC_COMMUTE_OFFER_STORAGE
    )
);

// export const commuteOfferDataStorageSelector = () =>
//   D2.S.SELECTOR(
//     'commuteOfferDataStorageSelector',
//     {},
//     () => global.GEODISC_COMMUTE_OFFER_STORAGE
//   );

export const commuteOfferOriginalStorageSelector = createImmutableSelector(
  commuteOfferDataStorageSelector,
  dataStorage =>
    D2.S.SELECTOR('commuteOfferOriginalStorageSelector', { dataStorage }, () =>
      dataStorage.get(0)
    )
);

export const commuteOfferOriginalDataSelector = createImmutableSelector(
  commuteOfferOriginalStorageSelector,
  originalStorage =>
    D2.S.SELECTOR(
      'commuteOfferOriginalDataSelector',
      { originalStorage },
      () => {
        const resultOffer = originalStorage.get('data');
        return resultOffer;
      }
    )
);

export const commuteOfferCurrentStorageSelector = createImmutableSelector(
  commuteOfferDataStorageSelector,
  commuteOfferCurrentStorageIndexSelector,
  commuteOfferCurrentStorageNonceSelector,
  (dataStorage, currentStorageIndex) =>
    D2.S.SELECTOR(
      'commuteOfferCurrentStorageSelector',
      { dataStorage, currentStorageIndex },
      () => dataStorage.get(currentStorageIndex)
    )
);

export const commuteOfferCurrentStorageHashSelector = createImmutableSelector(
  commuteOfferCurrentStorageSelector,
  currentStorage =>
    D2.S.SELECTOR(
      'commuteOfferCurrentStorageHashSelector',
      { currentStorage },
      () => currentStorage.hashCode()
    )
);

export const commuteOfferCurrentDataSelector = createImmutableSelector(
  commuteOfferCurrentStorageSelector,
  currentUserAllowChangeSelector,
  (currentStorage, currentUserAllowChange) =>
    D2.S.SELECTOR('commuteOfferCurrentDataSelector', { currentStorage }, () => {
      const commuteOffer = currentStorage.get('data');

      if (!commuteOffer) {
        return commuteOffer;
      }

      const currentOffer = {
        ...commuteOffer,
        stateless_api_request_data: {
          ...commuteOffer.stateless_api_request_data,
          readOnly: currentUserAllowChangeSelector
            ? commuteOffer.stateless_api_request_data.readOnly
            : false,
        },
      };

      const $isReadOnly = currentOffer.stateless_api_request_data.readOnly;

      const vehicles = currentOffer.stateless_api_request_data.vehicles.map(
        (vehicle) => {
          const isVehicleReadOnly = currentUserAllowChange
            ? vehicle.readOnly
            : false;
          return {
            ...vehicle,
            $isReadOnly: $isReadOnly ? true : isVehicleReadOnly,
          };
        }
      );

      const resultOffer = {
        ...currentOffer,
        stateless_api_request_data: {
          ...currentOffer.stateless_api_request_data,
          vehicles,
        },
        $isReadOnly,
      };

      return resultOffer;
    })
);

export const commuteOfferDataSelector = createImmutableSelector(
  commuteOfferOriginalDataSelector,
  commuteOfferCurrentDataSelector,
  (original, selected) => ({ original, selected })
);

export const commuteOfferIndexedVehiclesSelector = createImmutableSelector(
  commuteOfferDataSelector,
  (data) => {
    const indexVehicles = (commuteOffer) => {
      return (
        commuteOffer?.stateless_api_request_data.vehicles.reduce(
          (memo, vehicle) => ({
            ...memo,
            [vehicle.agent_id]: vehicle,
          }),
          {}
        ) ?? {}
      );
    };

    const result = {
      original: indexVehicles(data.original),
      selected: indexVehicles(data.selected),
    };

    return result;
  }
);

export const commuteOfferRoutesSelector = createImmutableSelector(
  commuteOfferStoreSelector,
  commuteOfferCurrentStorageSelector,
  commuteOfferCurrentStorageHashSelector,
  serialNumberSelector,
  (commuteOffer, currentStorage) =>
    D2.S.SELECTOR('commuteOfferRoutesSelector', { commuteOffer }, () =>
      currentStorage.get('routes')
    )
);

export const nodesSelector = createImmutableSelector(
  commuteOfferCurrentDataSelector,
  commuteOfferNodesRealtimeDataSelector,
  (commuteOffer, nodesRealtimeData) =>
    D2.S.SELECTOR(
      'nodesSelector',
      { commuteOffer },
      () =>
        commuteOffer &&
        commuteOffer.stateless_api_request_data.nodes.map((node) => {
          const rtinfo = nodesRealtimeData?.[node.uid];
          if (rtinfo) {
            return { ...node, ...rtinfo };
          }
          return node;
        })
    )
);

export const nodesByBookingIdSelector = createObjectSelector(
  D2.N('nodesByBookingIdSelector'),
  D2.S.$FN(nodesSelector, 'nodesByBookingIdSelector:nodesSelector'),
  (nodes) => {
    const res = D2.S.SELECTOR(
      'nodesByBookingIdSelector',
      { nodes },
      () =>
        nodes &&
        nodes.reduce((acc, item) => {
          if (!acc[item.booking_uid]) {
            acc[item.booking_uid] = [item];
          }
          acc[item.booking_uid].push(item);
          return acc;
        }, {})
    );
    return res;
  }
);

export const nodesDirectorySelector = createObjectSelector(
  D2.N('nodesDirectorySelector'),
  nodesSelector,
  nodes =>
    D2.S.SELECTOR(
      'nodesDirectorySelector',
      { nodes },
      () =>
        nodes &&
        nodes.reduce((acc, item) => {
          if (item.uid) {
            acc[item.uid] = item;
          }

          return acc;
        }, {})
    )
);

export const vehiclesRequestSelector = createObjectSelector(
  D2.N('vehiclesRequestSelector'),
  commuteOfferCurrentDataSelector,
  (data) => {
    const res = D2.S.SELECTOR('vehiclesRequestSelector', { data }, () =>
      data ? data.stateless_api_request_data.vehicles : []
    );
    return res;
  }
);

export const vehiclesWithColorSelector = createObjectSelector(
  D2.N('vehiclesWithColorSelector'),
  commuteOfferCurrentDataSelector,
  data =>
    D2.S.SELECTOR('vehiclesWithColorSelector', { data }, () => {
      const requestVehicles = data
        ? data.stateless_api_request_data.vehicles
        : [];
      const resultVehicles = data ? data.result.vehicles : [];

      if (!requestVehicles || !Array.isArray(requestVehicles)) {
        D2.S.MESSAGE('vehiclesWithColorSelector', {
          message: 'Error: Invalid vehicles list type. It must be an array.',
          requestVehicles,
        });
        return [];
      }

      return requestVehicles
        .map((vehicle) => {
          const resultVehicle = resultVehicles[vehicle.agent_id];
          const $hasResult = !!(
            resultVehicle &&
            resultVehicle.filter(
              node =>
                node.partial_route_index !== 1 &&
                node.partial_route_index !== -1
            ).length
          );
          const colors = $hasResult
            ? colorByVehicle(vehicle)
            : {
                // color: '#c5cede',
                $activeColor: '#c5cede',
                $draggableColor: '#c5cede',
              };

          return {
            ...vehicle,
            ...colors,
            $hasResult,
          };
        })
        .sort(vehicle => (vehicle.$hasResult ? -1 : 0));
    })
);

export const allVehiclesSelector = createObjectSelector(
  D2.N('allVehiclesSelector'),
  serialNumberSelector,
  vehiclesWithColorSelector,
  commuteOfferCurrentDataSelector,
  nodesDirectorySelector,
  capacityFunctorSelector,
  (serialNumber, vehicles, currentData, nodes, capacityFunctor) =>
    D2.S.SELECTOR(
      'allVehiclesSelector',
      {
        serialNumber,
        vehicles,
        currentData,
        nodes,
      },
      () => {
        if (!currentData) {
          return [];
        }

        return vehicles.map((vehicle) => {
          const currentVehicle =
            currentData.result.vehicles &&
            currentData.result.vehicles[vehicle.agent_id];

          if (!currentVehicle) {
            D2.S.MESSAGE('allVehiclesSelector', {
              message: 'Error: Vehicle not found',
              vehicle,
            });
          }
          const vehicleRoute =
            currentVehicle &&
            calculateVehicleRoute(
              vehicle,
              currentVehicle,
              nodes,
              vehicle.agent_id,
              capacityFunctor
            );
          const route = currentVehicle ? vehicleRoute : [];

          return {
            ...vehicle,
            route,
            $route: route,
          };
        });
      }
    )
);

export const availableVehiclesSelector = createObjectSelector(
  D2.N('availableVehiclesSelector'),
  serialNumberSelector,
  vehiclesWithColorSelector,
  commuteOfferOriginalDataSelector,
  commuteOfferCurrentDataSelector,
  nodesDirectorySelector,
  capacityFunctorSelector,
  commuteOfferIndexedVehiclesSelector,
  (
    serialNumber,
    vehicles,
    originalData,
    currentData,
    nodes,
    capacityFunctor,
    indexedVehicles
  ) =>
    D2.S.SELECTOR(
      'availableVehiclesSelector',
      {
        serialNumber,
        vehicles,
        originalData,
        currentData,
        nodes,
        indexedVehicles,
      },
      ({ $D2 }) => {
        if (!currentData) {
          return [];
        }

        const result = vehicles
          .map(vehicle => ({
            ...vehicle,
            $start_time: vehicle.start_time
              ? moment(vehicle.start_time)
              : undefined,
            $end_time: vehicle.end_time ? moment(vehicle.end_time) : undefined,
          }))
          .map((vehicle) => {
            const currentVehicleAssignments =
              currentData.result.vehicles &&
              currentData.result.vehicles[vehicle.agent_id];

            const removeInternalFieldsFromVehicle = (vehicleObject = {}) =>
              removeInternalFieldsFromObject({
                ...vehicleObject,
                $activeColor: undefined,
                $draggableColor: undefined,
              });
            const originalVehicleRequest = removeInternalFieldsFromVehicle(
              indexedVehicles.original[vehicle.agent_id]
            );
            const currentVehicleRequest = removeInternalFieldsFromVehicle(
              indexedVehicles.selected[vehicle.agent_id]
            );
            const originalVehicle = removeInternalFieldsFromObject(
              originalData.result.vehicles?.[vehicle.agent_id] ?? []
            );
            const currentVehicle = removeInternalFieldsFromObject(
              currentData.result.vehicles?.[vehicle.agent_id] ?? []
            );
            const isChanged =
              JSON.stringify(originalVehicle) !==
                JSON.stringify(currentVehicle) ||
              JSON.stringify(originalVehicleRequest) !==
                JSON.stringify(currentVehicleRequest);
            $D2.S.INFO('isChanged', {
              isChanged,
              originalVehicle,
              currentVehicle,
              originalVehicleRequest,
              currentVehicleRequest,
              vehicle,
              originalData,
              currentData,
            });
            const vehicleRoute =
              currentVehicle &&
              calculateVehicleRoute(
                vehicle,
                currentVehicle,
                nodes,
                vehicle.agent_id,
                capacityFunctor
              );

            // const $capacity_info = {};
            const $capacity_info_base = Object.entries(vehicle.capacity).reduce(
              (memo, [capacityKey, capacity]) => ({
                ...memo,
                [capacityKey]: vehicleRoute
                  ? vehicleRoute.reduce(
                      (acc, node) => {
                        const capacityValue =
                          node.$demand_info?.[capacityKey] ?? 0;
                        if (node.node_type === 'pickup') {
                          acc.count += capacityValue;
                          acc.transferred += capacityValue;
                        } else if (node.node_type === 'dropoff') {
                          acc.count -= capacityValue;
                        }
                        acc.occupied = Math.max(acc.count, acc.occupied);
                        return acc;
                      },
                      { occupied: 0, transferred: 0, count: 0, capacity }
                    )
                  : { occupied: 0, transferred: 0, capacity },
              }),
              {}
            );

            const $capacity_info = Object.entries(
              $capacity_info_base || {}
            ).reduce((memo, [capacityKey, capacityInfo]) => {
              const capacityFunction =
                capacityConversionFunctor[capacityKey] ??
                capacityConversionFunctor.$default;
              const newInfo = capacityFunction(capacityKey, capacityInfo);
              return {
                ...memo,
                [newInfo.name]: newInfo.info,
              };
            }, {});

            const passengers =
              vehicleRoute &&
              vehicleRoute.reduce(
                (acc, node) => {
                  if (node.node_type === 'pickup') {
                    acc.count += node.pax;
                    acc.transferred += node.pax;
                  } else if (node.node_type === 'dropoff') {
                    acc.count -= node.pax;
                  }
                  acc.occupied = Math.max(acc.count, acc.occupied);
                  return acc;
                },
                { occupied: 0, transferred: 0, count: 0 }
              );

            (vehicleRoute || []).forEach((route, index) => {
              const currentLoad = vehicleRoute[index - 1]
                ? { ...vehicleRoute[index - 1].$loadAfterStop }
                : {};
              route.bookings.forEach((booking) => {
                if (
                  booking.node.node_type !== 'pickup' &&
                  booking.node.node_type !== 'dropoff'
                )
                  return;
                const loadTypeOperator =
                  booking.node.node_type === 'pickup' ? 1 : -1;

                const convertedDemandInfo = {};
                Object.keys(booking?.$demand_info || {})?.map((demand) => {
                  const convertedDemandLoads = convertDemandLoadsToDisplay(
                    demand,
                    booking.$demand_info[demand]
                  );
                  convertedDemandInfo[convertedDemandLoads.type] =
                    convertedDemandLoads.value;
                });

                Object.keys(convertedDemandInfo).forEach((demand) => {
                  const value = convertedDemandInfo[demand] * loadTypeOperator;
                  const convertedDemandLoads = convertDemandLoadsToDisplay(
                    demand,
                    value
                  );
                  currentLoad[convertedDemandLoads.type] =
                    Number(currentLoad[demand] || 0) +
                    convertedDemandLoads?.value;
                });
              });
              vehicleRoute[index].$loadAfterStop = currentLoad;
              vehicleRoute[index].$capacity_info = $capacity_info;
            }, []);

            const $isOperatedVehicle = !!vehicleRoute.find(
              stop =>
                stop.status === NODE_STATUSES.COMPLETED ||
                stop.status === NODE_STATUSES.CANCELLED_BY_USER ||
                stop.status === NODE_STATUSES.FAILED_TO_DELIVER ||
                stop.status == NODE_STATUSES.IN_SERVICE
            );

            const result = {
              ...vehicle,
              isChanged,
              route: vehicleRoute || [],
              passengers: passengers || {
                occupied: 0,
                transferred: 0,
              },
              $capacity_info,
              $orderKey: vehicle.service_number || vehicle.agent_id,
              $debug: {
                currentVehicle,
                originalVehicleRequest,
                currentVehicleRequest,
                vehicleRoute,
              },
              $isOperatedVehicle,
            };

            memoize(() => {
              // eslint-disable-next-line no-console
              // const { agent_id, modified_at } = vehicle;
              // console.log(`%c*** CALCULATING VEHICLE ${agent_id}`, 'color: blue');
              // console.log({
              //   modified_at,
              //   currentVehicleAssignments,
              // });

              return result;
            })(
              vehicle.agent_id,
              vehicle.modified_at,
              currentVehicleAssignments
            );

            return result;
          })
          .map((vehicle) => {
            $D2.S.INFO('2:vehicle', { vehicle });
            return vehicle.$debug.currentVehicle &&
              vehicle.$debug.currentVehicle.length
              ? {
                  ...vehicle,
                  $first_time: moment(
                    vehicle.$debug.currentVehicle[0].scheduled_ts
                  ),
                  $last_time: moment(
                    vehicle.$debug.currentVehicle[
                      vehicle.$debug.currentVehicle.length - 1
                    ].scheduled_ts
                  ),
                }
              : {
                  ...vehicle,
                  $first_time: vehicle.$start_time,
                  $last_time: vehicle.$last_time,
                };
          });
        // .sort((a, b) => {
        //     if (a.$orderKey === b.$orderKey) {
        //       return 0;
        //     } else if (a.$orderKey < b.$orderKey) {
        //       return -1;
        //     }
        //   return 1;
        // });

        global.GEODISC_CO_UNFILTERED_VEHICLES = result;
        return result;
      }
    )
);

export const bookingReadySelector = createImmutableSelector(
  commuteOfferStoreSelector,
  commuteOffer =>
    D2.S.SELECTOR('bookingReadySelector', { commuteOffer }, () => {
      const bookingReady = commuteOffer.get('finishOfferLoading');
      // console.log('*** bookingReady', { bookingReady });
      return bookingReady;
    })
);

export const commuteOfferIsReadOnlySelector = createImmutableSelector(
  commuteOfferLoadedCommuteOffersSelector,
  commuteOfferLoadedSimulationsSelector,
  commuteOfferLoadedSimulationSelector,
  commuteOffersIsTemplateDataSourceSelector,
  isCurrentDateEditableSelector,
  availableVehiclesSelector,
  permissionsSerialNumberSelector,
  isDeliveryLayoutSelector,
  currentUserAllowChangeSelector,
  bookingReadySelector,
  commuteOffersServiceDateSelector,
  (
    loadedCommuteOffers,
    loadedSimulations,
    simulation,
    isTemplate,
    isCurrentDateEditable,
    vehicles,
    permissionsSerialNumber,
    isDeliveryLayout,
    currentUserAllowChange,
    bookingReady,
    serviceDate
  ) => {
    // we will remove this log and replace isCurrentDateEditable after verify SP-1200 is fixed
    const currentDate = moment().format('YYYY-MM-DD');
    const isPastDate = currentDate > serviceDate;
    const isCurrentDateEditableFixed = currentUserAllowChange && !isPastDate;
    if (isCurrentDateEditable !== isCurrentDateEditableFixed) {
      // eslint-disable-next-line no-console
      console.log('Debug(SP-1200):', {
        bookingReady,
        isCurrentDateEditable,
        currentUserAllowChange,
        serviceDate,
        currentDate,
        isCurrentDateEditableFixed,
      });
    }

    if (!isCurrentDateEditableFixed) {
      return true;
    }

    if (!bookingReady) {
      return true;
    }

    if (loadedCommuteOffers.length === 0 && loadedSimulations.length === 0) {
      const isReadOnly = false;
      return isReadOnly;
    }

    if (loadedCommuteOffers.length === 1 && loadedSimulations.length === 0) {
      const commuteOffer = loadedCommuteOffers[0];
      const isDataReadOnly =
        commuteOffer.stateless_api_request_data?.readOnly || false;
      const isReadWrite = currentUserAllowChange && !isDataReadOnly;
      const isReadOnly = !isReadWrite;
      return isReadOnly;
    }
    if (loadedCommuteOffers.length === 0 && loadedSimulations.length === 1) {
      if (!isDeliveryLayout) {
        return false;
      }

      if (isTemplate) {
        return !currentUserAllowChange;
      }

      const currentTime = moment();
      const endTime = moment(simulation.end_time);

      const isReadOnly = currentTime.isAfter(endTime);

      // console.log('*** isReadOnly', {
      //   isReadOnly,
      //   endTime: endTime.format(),
      //   currentTime: currentTime.format(),
      //   isCurrentDateEditable,
      //   simulation,
      // });
      return isReadOnly;

      // if (isCurrentDateEditable) {

      //   //     const scheduledVehicles = vehicles.filter(
      //   //       vehicle => vehicle.route.length > 0 && vehicle.$first_time
      //   //     );

      //   //     const logPrefix = `--- [${moment()
      //   //       .tz(global.GEODISC_TIMEZONE)
      //   //       .format('HH:mm')}]`;

      //   //     if (scheduledVehicles.length === 0) {
      //   //       const isReadOnly = !isCurrentDateEditable;
      //   //       // eslint-disable-next-line
      //   //       // console.log(`${logPrefix} Service is empty.`);
      //   //       return isReadOnly;
      //   //     }

      //   //     const minFirstTime = moment.min(scheduledVehicles.map(vehicle => vehicle.$first_time));
      //   //     const lastEditableTime = moment().add(60, 'minutes');

      //   //     const isReadOnly = lastEditableTime.isAfter(minFirstTime);

      //   //     if (isReadOnly) {
      //   //       const duration = moment
      //   //         .duration(minFirstTime.unix() - lastEditableTime.unix(), 's')
      //   //         .humanize();
      //   //       // eslint-disable-next-line
      //   //       // console.log(`${logPrefix} Service started ${duration} ago.`);
      //   //     } else {
      //   //       const duration = moment
      //   //         .duration(lastEditableTime.unix() - minFirstTime.unix(), 's')
      //   //         .humanize();
      //   //       // eslint-disable-next-line
      //   //       // console.log(`${logPrefix} Service is going to start in ${duration}.`);
      //   //     }

      //   //     return isReadOnly;
      // }
    }

    return true;
  }
);

export const commuteOfferCanBeSavedSelector = createImmutableSelector(
  serialNumberSelector,
  commuteOfferOriginalDataSelector,
  commuteOfferCurrentDataSelector,
  commuteOfferLoadedCommuteOffersSelector,
  commuteOfferLoadedSimulationSelector,
  allVehiclesSelector,
  currentProjectLogisticsSettingsSelector,
  (
    serialNumber,
    originalData,
    currentData,
    loadedCommuteOffers,
    loadedSimulations,
    vehicles,
    currentProjectLogisticsSettings
  ) =>
    D2.S.SELECTOR(
      'commuteOfferCanBeSavedSelector',
      {
        serialNumber,
        originalData,
        currentData,
        loadedCommuteOffers,
        loadedSimulations,
        vehicles,
      },
      ({ $D2 }) => {
        if (!currentData) {
          return false;
        }

        if (loadedCommuteOffers.length > 0) {
          return true;
        }

        const incocsistentOrderRoute = vehicles.find((vehicle) => {
          if (loadedSimulations) {
            if (
              (!!vehicle.id && !vehicle.resource_uri) ||
              (!vehicle.id && !!vehicle.resource_uri)
            ) {
              $D2.S.INFO('*** Invalid vehicle', [
                vehicle.id,
                vehicle.resource_uri,
                vehicle,
                loadedSimulations,
              ]);
              return true;
            }
          }
          if (!vehicle.$route) {
            return false;
          }
          const visitedDropoffBookings = new Set();
          const incocsistentWaypoint = vehicle.$route.find((waypoint) => {
            const incocsistentWaypointNode = waypoint.bookings.find(
              ({ $node }) => {
                if ($node.node_type === 'dropoff') {
                  visitedDropoffBookings.add($node.booking_uid);
                } else if ($node.node_type === 'pickup') {
                  if (visitedDropoffBookings.has($node.booking_uid)) {
                    // eslint-disable-next-line no-console
                    console.log('*** Found inconsistent nodes order', {
                      $node,
                      visitedDropoffBookings,
                    });
                    return true;
                  }
                }
                return false;
              }
            );
            return !!incocsistentWaypointNode;
          });
          return !!incocsistentWaypoint;
        });
        if (!!incocsistentOrderRoute) {
          // console.log('*** Found inconsistent route', {
          //   incocsistentOrderRoute,
          //   vehicles,
          // });
          return false;
        }

        const { allow_break_timewindow_constraint = true } =
          currentProjectLogisticsSettings;

        const incocsistentScheduledTsNode = allow_break_timewindow_constraint
          ? null
          : currentData.stateless_api_request_data.nodes
              .filter(node => !!node.$assigned_vehicle_id)
              .find((node) => {
                const scheduled_mts = moment(node.scheduled_ts);

                const open_time_mts = moment(node.open_time_ts);
                if (scheduled_mts.isBefore(open_time_mts)) {
                  return true;
                }

                const close_time_mts = moment(node.close_time_ts);
                if (scheduled_mts.isAfter(close_time_mts)) {
                  return true;
                }

                return false;
              });

        if (!!incocsistentScheduledTsNode) {
          // eslint-disable-next-line no-console
          console.log('*** Found inconsistent timestamp', {
            incocsistentScheduledTsNode,
          });
          return false;
        }

        const { allow_break_capacity_constraint = true } =
          currentProjectLogisticsSettings;

        const incocsistentCapacityRoute = allow_break_capacity_constraint
          ? null
          : vehicles.find((vehicle) => {
              if (!vehicle.$route) {
                return false;
              }
              const incocsistentWaypointInfo = vehicle.$route.reduce(
                (memo, waypoint) => {
                  if (memo.inconsystentWaypoint) {
                    return memo;
                  }
                  const capacity = waypoint.bookings.reduce(
                    (capacityMemo, booking) => {
                      const demand = booking.$node.demand;
                      const newCapacity = Object.entries(demand).reduce(
                        (newCapacitydMemo, [demandKey, demandValue]) => {
                          const capacityValue =
                            newCapacitydMemo[demandKey] ?? 0;
                          return {
                            ...newCapacitydMemo,
                            [demandKey]: capacityValue - demandValue,
                          };
                        },
                        capacityMemo
                      );
                      return newCapacity;
                    },
                    memo.capacity
                  );
                  const negativeCapacity = Object.values(capacity).find(
                    x => x < 0
                  );
                  if (negativeCapacity) {
                    // eslint-disable-next-line no-console
                    console.log('*** Found inconsistent capacity', {
                      capacity,
                      waypoint,
                    });
                    return {
                      ...memo,
                      capacity,
                      inconsystentWaypoint: waypoint,
                      inconsystentCapacity: negativeCapacity,
                    };
                  }
                  return { ...memo, capacity };
                },
                {
                  capacity: vehicle.capacity,
                  inconsystentWaypoint: null,
                  inconsystentCapacity: null,
                }
              );

              return !!incocsistentWaypointInfo?.inconsystentWaypoint;
            });
        if (!!incocsistentCapacityRoute) {
          return false;
        }

        return true;
      }
    )
);

export const commuteOfferHasOnlineVehiclesSelector = createImmutableSelector(
  availableVehiclesSelector,
  vehicles =>
    D2.S.SELECTOR(
      'commuteOfferHasOnlineVehiclesSelector',
      { vehicles },
      ({ $D2 }) => {
        // There is no direct way to find a vehicle is online.
        //  If current_sim_ts has a value and it is not on the 0,0 location on the map we can consider it as an online vehicle
        const onlineVehicle = vehicles.find(
          vehicle => !!vehicle.current_sim_ts && (vehicle.lon || vehicle.lat)
        );
        const hasOnlineVehicles = !!onlineVehicle;

        return hasOnlineVehicles;
      }
    )
);

export const filteredVehiclesSelector = createObjectSelector(
  D2.N('filteredVehiclesSelector'),
  availableVehiclesSelector,
  vehiclesFilterSelector,
  (vehicles, vehiclesFilter) =>
    D2.S.SELECTOR(
      'filteredVehiclesSelector',
      { vehicles, vehiclesFilter },
      ({ $D2 }) => {
        const filteringArgs = vehiclesFilter
          .trim()
          .split(',')
          .map(x => x.trim())
          .filter(x => !!x);

        const commands = filteringArgs.filter(x => x.indexOf('!') === 0);
        const filters = filteringArgs.filter(x => x.indexOf('!') !== 0);

        $D2.S.INFO('Filters', { filters, commands });

        if (!filters.length && !commands) {
          return vehicles;
        }

        if (filters.length === 1 && filters[0] === '') {
          return vehicles;
        }

        return vehicles.filter(
          vehicle =>
            filters
              .map((filter) => {
                const {
                  name = '',
                  agent_id,
                  tags = [],
                  service_number = '',
                  display_name = '',
                } = vehicle;
                const { vehicle_model = '' } = vehicle.routing_engine ?? {};
                const searchIn = [
                  ...tags,
                  name,
                  agent_id,
                  service_number,
                  display_name,
                  vehicle_model,
                ].filter(x => !!x && typeof x === 'string');
                // console.log('*** searchIn', { searchIn, filter, filters, vehicle });
                return !!searchIn.find(
                  x => x.toLowerCase().indexOf(filter) >= 0
                );
              })
              .find(x => !x) !== false
        );
      }
    )
);

export const filteredAssignedVehicles = createObjectSelector(
  D2.N('filteredAssignedVehicles'),
  filteredVehiclesSelector,
  vehicles =>
    D2.S.SELECTOR('filteredAssignedVehicles', { vehicles }, () =>
      vehicles.filter(vehicle => vehicle.$hasResult === true)
    )
);

export const filteredUnassignedVehicles = createObjectSelector(
  D2.N('filteredUnassignedVehicles'),
  filteredVehiclesSelector,
  vehicles =>
    D2.S.SELECTOR('filteredUnassignedVehicles', { vehicles }, () =>
      vehicles.filter(vehicle => !vehicle.$hasResult)
    )
);

export const filteredVehicleIdsSelector = createObjectSelector(
  D2.N('filteredVehicleIdsSelector'),
  filteredVehiclesSelector,
  vehicles =>
    D2.S.SELECTOR('filteredVehicleIdsSelector', { vehicles }, () =>
      vehicles.map(vehicle => vehicle.agent_id)
    )
);

export const availableBookingsSelector = createObjectSelector(
  D2.N('availableBookingsSelector'),
  serialNumberSelector,
  commuteOfferCurrentDataSelector,
  stopsSelector,
  availableVehiclesSelector,
  bookingsFilterSelector,
  (serialNumber, data, stops, vehicles, bookingsFilter) =>
    D2.S.SELECTOR(
      'availableBookingsSelector',
      { serialNumber, data, stops, vehicles, bookingsFilter },
      ({ $D2 }) => {
        if (!data) {
          return [];
        }
        const { result, stateless_api_request_data } = data;
        const { assigned_bookings, rejected_bookings } = result;

        const assignedBookings = assigned_bookings
          .map(booking => ({
            ...booking,
            $scheduled_pickup_time: moment(booking.scheduled_pickup_time),
            $scheduled_dropoff_time: moment(booking.scheduled_dropoff_time),
          }))
          .sort((a, b) =>
            a.$scheduled_pickup_time.diff(b.$scheduled_pickup_time)
          );
        const rejectedBookings = rejected_bookings;
        const allBookings = [...assignedBookings, ...rejectedBookings];
        $D2.S.INFO('bookings', {
          allBookings,
          assignedBookings,
          rejectedBookings,
        });
        const res = allBookings
          .filter(
            booking => !!stateless_api_request_data.bookings[booking.uid]
          )
          .map((resultBooking) => {
            const requestBooking =
              stateless_api_request_data.bookings[resultBooking.uid];

            const { demand } = requestBooking;
            const convertedDemand = {};

            Object.keys(demand || {}).map((type) => {
              const convertedDemandLoads = convertDemandLoadsToDisplay(
                type,
                demand[type]
              );
              convertedDemand[convertedDemandLoads?.type] =
                convertedDemandLoads?.value;
            });

            const $assignedVehicle = resultBooking.assigned_vehicle_id
              ? vehicles.find(
                  vehicle =>
                    resultBooking.assigned_vehicle_id === vehicle.agent_id
                )
              : undefined;
            $D2.S.INFO('allBookings.map:$assignedVehicle', {
              $assignedVehicle,
            });

            const $routeStops =
              $assignedVehicle &&
              $assignedVehicle.route.filter(
                stop =>
                  stop.bookings &&
                  stop.bookings.find(
                    booking => booking.id === resultBooking.uid
                  )
              );
            $D2.S.INFO('allBookings.map:$routeStops', { $routeStops });

            const routeStop =
              $assignedVehicle &&
              $assignedVehicle.route.find(
                stop =>
                  stop.bookings &&
                  stop.bookings.find(
                    booking => booking.id === resultBooking.uid
                  )
              );

            return {
              ...requestBooking,
              ...resultBooking,
              color: $assignedVehicle && $assignedVehicle.$activeColor,
              $assignedVehicle,
              $routeStopId: routeStop && routeStop.id,
              $routeStops,
              demand: convertedDemand,
            };
          });
        return res;
      }
    )
);

export const filteredBookingsSelector = createObjectSelector(
  D2.N('filteredBookingsSelector'),
  availableBookingsSelector,
  bookingsFilterSelector,
  filteredVehiclesSelector,
  (bookings, bookingsFilter, filteredVehicles) =>
    D2.S.SELECTOR(
      'filteredBookingsSelector',
      { bookings, bookingsFilter, filteredVehicles },
      ({ $D2 }) => {
        const filteredVehiclesSet = filteredVehicles.reduce((acc, vehicle) => {
          return acc.add(vehicle.agent_id);
        }, new Set());
        $D2.S.INFO('filteredVehiclesSet', filteredVehiclesSet);
        // const invalidBookings = bookings.filter(
        //   booking =>
        //     booking.assigned_vehicle_id &&
        //     !filteredVehiclesSet.has(booking.assigned_vehicle_id)
        // );
        // $D2.S.INFO('filteredBookingsSelector:invalidBookings', {
        //   filteredVehiclesSet,
        //   invalidBookings
        // });

        const filteringArgs = bookingsFilter.trim()
          ? bookingsFilter
              .split(',')
              .map(x => x.trim())
              .filter(x => !!x)
          : [];

        const commands = filteringArgs.filter(x => x.indexOf('!') === 0);
        const filters = filteringArgs.filter(x => x.indexOf('!') !== 0);

        if (!filters.length && !commands.length) {
          return bookings;
        }

        if (filters.length === 1 && filters[0] === '') {
          return bookings;
        }
        const isUnassignedOnly = !!commands.filter(x => x.indexOf('!u') === 0)
          .length;
        const isAssignedOnly = !!commands.filter(x => x.indexOf('!a') === 0)
          .length;
        const selectAll = !isUnassignedOnly && !isAssignedOnly;

        const searchCriteria = filters.join(' ');

        $D2.S.INFO('criteria', {
          bookingsFilter,
          commands,
          filters,
          isUnassignedOnly,
          isAssignedOnly,
          selectAll,
          searchCriteria,
        });

        const selectedBookings = bookings.filter(
          booking =>
            (((selectAll || isUnassignedOnly) &&
              !booking.assigned_vehicle_id) ||
              ((selectAll || isAssignedOnly) &&
                booking.assigned_vehicle_id &&
                filteredVehiclesSet.has(booking.assigned_vehicle_id))) &&
            filters
              .map((filter) => {
                const {
                  name = '',
                  display_name = '',
                  uid,
                  tags = [],
                  $assignedVehicle = {},
                  pickup_location_name,
                  dropoff_location_name,
                  id = '',
                } = booking;
                const { username = '', external_id = '' } = booking.data ?? {};
                const searchIn = [
                  ...[
                    name,
                    uid,
                    display_name,
                    username,
                    external_id,
                    pickup_location_name,
                    dropoff_location_name,
                    id,
                  ],
                  ...tags,
                  ...[
                    $assignedVehicle.service_number || '',
                    $assignedVehicle.name || '',
                    $assignedVehicle.agent_id || '',
                  ],
                  ...($assignedVehicle.tags || []),
                ].filter(
                  x => !!x && (typeof x === 'string' || typeof x === 'number')
                );
                // console.log('*** searchIn', { filter, searchIn });
                return !!searchIn.find(
                  x => String(x).toLowerCase().indexOf(filter) >= 0
                );
              })
              .find(x => !x) !== false
        );
        $D2.S.INFO('selectedBookings', {
          commands,
          filters,
          isUnassignedOnly,
          isAssignedOnly,
          selectAll,
          searchCriteria,
          selectedBookings,
        });

        return selectedBookings;
      }
    )
);

export const validOrdersToDisplay = createObjectSelector(
  D2.N('validOrdersToDisplay'),
  filteredBookingsSelector,
  orders =>
    orders.filter(
      order => order.dropoff_location_lon && order.dropoff_location_lat
    )
);

export const numberOfUnresolvedAddressSelector = createObjectSelector(
  D2.N('numberOFUnresolvedAddressSelector'),
  availableBookingsSelector,
  orders =>
    D2.S.SELECTOR('numberOFUnresolvedAddressSelector', { orders }, () => {
      const unResolvedAddresses = {};
      const allOrders = [...orders];
      allOrders.map((order) => {
        if (
          order.dropoff_location_lat === 0 &&
          order.dropoff_location_lon === 0
        ) {
          const combinedDropoffAddress = [
            order?.data?.raw_order_record?.dropoff_address,
            order?.data?.raw_order_record?.dropoff_address2,
            order?.data?.raw_order_record?.dropoff_zip_code,
          ]
            .filter(item => item)
            .join(' ');
          unResolvedAddresses[combinedDropoffAddress] = order;
        }
        if (
          order.pickup_location_lat === 0 &&
          order.pickup_location_lon === 0
        ) {
          const combinedPickupAddress = [
            order?.data?.raw_order_record?.pickup_address,
            order?.data?.raw_order_record?.pickup_address2,
            order?.data?.raw_order_record?.pickup_zip_code,
          ]
            .filter(item => item)
            .join(' ');
          unResolvedAddresses[combinedPickupAddress] = order;
        }
      });
      const numberOfAddresses = Object.keys(unResolvedAddresses).length;
      return numberOfAddresses > 99 ? '+99' : numberOfAddresses;
    })
);

export const resolvedAddressSelector = createObjectSelector(
  D2.N('resolvedAddressSelector'),
  availableBookingsSelector,
  orders =>
    D2.S.SELECTOR('resolvedAddressSelector', { orders }, () => {
      const filteredOrders = orders.filter((order) => {
        const isDropOffLatLonAvailable =
          order?.dropoff_location_lat && order?.dropoff_location_lon;
        const isPickupLatLonAvailable =
          order?.pickup_location_lat && order?.pickup_location_lon;
        return isDropOffLatLonAvailable && isPickupLatLonAvailable;
      });
      return filteredOrders;
    })
);

export const editableBookingsSelector = createObjectSelector(
  D2.N('editableBookingsSelector'),
  vehiclesWithColorSelector,
  activeVehicleIdsSelector,
  commuteOfferCurrentDataSelector,
  editableBookingIdSelector,
  (vehicles, activeVehicleIds, data, editableBookingId) =>
    D2.S.SELECTOR(
      'editableBookingsSelector',
      { vehicles, activeVehicleIds, data, editableBookingId },
      ({ $D2 }) => {
        if (!data || !activeVehicleIds.size || !editableBookingId) {
          return null;
        }

        return {
          ...activeVehicleIds.reduce((acc, vehicleId) => {
            const vehicleNodes = data.result.vehicles[vehicleId];
            $D2.S.INFO('vehicleNodes', { vehicleNodes, vehicleId });
            if (vehicleNodes) {
              acc[vehicleId] = vehicleNodes
                .filter(node => node.booking_uid === editableBookingId)
                .reduce(
                  (vehicleAcc, node) => {
                    // eslint-disable-next-line no-param-reassign
                    vehicleAcc[node.node_type] = {
                      ...node,
                      scheduled_ts:
                        node.uid &&
                        moment(node.scheduled_ts)
                          .tz(global.GEODISC_TIMEZONE)
                          .format('HH:mm'),
                    };
                    return vehicleAcc;
                  },
                  {
                    booking_uid: editableBookingId,
                    pickup: {},
                    dropoff: {},
                  }
                );
            }
            return acc;
          }, {}),
        };
      }
    )
);

export const editableVehicleSelector = createObjectSelector(
  D2.N('editableVehicleSelector'),
  vehiclesRequestSelector,
  editableVehicleIdSelector,
  (vehicles, id) =>
    D2.S.SELECTOR(
      'editableVehicleSelector',
      { vehicles, id },
      () => id && vehicles.find(item => item.agent_id === id)
    )
);

export const vehiclesFilteredBySearchSelector = createObjectSelector(
  D2.N('vehiclesFilteredBySearchSelector'),
  filteredVehiclesSelector,
  searchQuerySelector,
  (vehicles, searchQuery) =>
    D2.S.SELECTOR(
      'vehiclesFilteredBySearchSelector',
      { vehicles, searchQuery },
      () =>
        searchQuery !== ''
          ? vehicles.filter(vehicle =>
              vehicle.agent_id.toLowerCase().match(searchQuery)
            )
          : []
    )
);

export const bookingsFilteredBySearchSelector = createObjectSelector(
  D2.N('bookingsFilteredBySearchSelector'),
  filteredBookingsSelector,
  searchQuerySelector,
  (bookings, searchQuery) => {
    D2.S.SELECTOR(
      'bookingsFilteredBySearchSelector',
      { bookings, searchQuery },
      () =>
        searchQuery !== ''
          ? bookings.filter(booking =>
              booking.uid.toLowerCase().match(searchQuery)
            )
          : []
    );
  }
);

export const activeBookingIndexSelector = createObjectSelector(
  D2.N('activeBookingIndexSelector'),
  filteredBookingsSelector,
  activeBookingIdSelector,
  (bookings, activeBookingId) =>
    D2.S.SELECTOR(
      'activeBookingIndexSelector',
      { bookings, activeBookingId },
      () => bookings.findIndex(booking => booking.uid === activeBookingId)
    )
);

export const activeVehiclesIndexSelector = createImmutableSelector(
  filteredVehiclesSelector,
  activeVehicleIdsSelector,
  (vehicles, activeVehicleIds) =>
    D2.S.SELECTOR(
      'activeVehiclesIndexSelector',
      { vehicles, activeVehicleIds },
      () =>
        vehicles.findIndex(vehicle => vehicle.agent_id === activeVehicleIds)
    )
);

export const activeVehiclesSelector = createImmutableSelector(
  filteredVehiclesSelector,
  activeVehicleIdsSelector,
  (vehicles, activeVehicleIds) =>
    D2.S.SELECTOR(
      'activeVehiclesSelector',
      { vehicles, activeVehicleIds },
      () => vehicles
    )
);

export const activeOnlineVehiclesSelector = createImmutableSelector(
  activeVehiclesSelector,
  commuteOfferVehiclesRealtimeDataSelector,
  (vehicles, vehiclesRealtimeData) =>
    D2.S.SELECTOR('activeOnlineVehiclesSelector', { vehicles }, () =>
      vehicles
        .map((vehicle) => {
          const rtinfo = vehiclesRealtimeData?.[vehicle.agent_id];
          if (rtinfo) {
            return { ...vehicle, ...rtinfo };
          }
          return vehicle;
        })
        .filter((vehicle) => {
          if (!vehicle.current_sim_ts) {
            return false;
          }
          const { isOnline } = getVehicleOnlineStatus(vehicle);
          return isOnline;
        })
    )
);

export const activeOnlineVehiclesSourceSelector = createImmutableSelector(
  activeOnlineVehiclesSelector,
  vehicles =>
    D2.S.SELECTOR('activeOnlineVehiclesSourceSelector', { vehicles }, () => ({
      features: vehicles.map(vehicle => ({
        geometry: {
          coordinates: [vehicle.lon, vehicle.lat],
          type: 'Point',
        },
        properties: {
          agent_id: vehicle.agent_id,
          service_number: vehicle.service_number,
          $activeColor: vehicle.$activeColor,
          $draggableColor: vehicle.$draggableColor,
        },
        type: 'Feature',
      })),
      type: 'FeatureCollection',
    }))
);

export const transitstopSetsSelector = createObjectSelector(
  D2.N('transitstopSetsSelector'),
  commuteOfferStoreSelector,
  commuteOffer =>
    D2.S.SELECTOR('transitstopSetsSelector', { commuteOffer }, () =>
      commuteOffer.get('transitstopSets')
    )
);

export const operationStatisticsSelector = createObjectSelector(
  D2.N('operationStatisticsSelector'),
  allVehiclesSelector,
  vehicles =>
    D2.S.SELECTOR(
      'operationStatisticsSelector',
      {
        vehicles,
      },
      () => {
        const statistics = {
          operation: {},
          driving: {},
          vehicles: [],
        };

        return statistics;
      }
    )
);

import styled from 'styled-components';

const Overflow = styled.div`
  height: 100%;
  overflow: scroll;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    border: transparent 1px solid;
    background-color: transparent;
  }

  overflow: -moz-scrollbars-none;
`;

export default Overflow;

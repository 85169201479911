import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import createImmutableSelector from 'utils/immutable-selector';
import { compose } from 'redux';

import {
  deleteDataset,
  updateDataset,
  saveDataset,
  downloadDatasetCsv,
  clearList,
} from 'modules/datasets/actions';

import {
  setEditableDataset,
  updateEditableDataset,
  toggleSelectedDatasets,
} from 'modules/ui/actions';

import {
  datasetsErrorSelector,
  editableDatasetSelector,
  selectedIdsDatasetsSelector,
} from 'modules/ui/selectors';

import { countSelector, listSelector } from 'modules/datasets/selectors';
import { routePageSelector } from 'modules/router/selectors';

import Table from './Table';

const mapDispatchToProps = {
  deleteDataset,
  updateDataset,
  setEditableDataset,
  updateEditableDataset,
  saveDataset,
  downloadDatasetCsv,
  toggleSelectedDatasets,
  clearList,
};

const mapStateToProps = createImmutableSelector(
  editableDatasetSelector,
  listSelector,
  datasetsErrorSelector,
  selectedIdsDatasetsSelector,
  countSelector,
  routePageSelector,
  (editableDataset, list, error, selectedIds, count, page) => ({
    editableDataset,
    list,
    error,
    selectedIds,
    count,
    page,
  })
);

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const enhance = compose(withConnect, withRouter);

export default enhance(Table);

import React from 'utils/react';
import debug from 'utils/debug';

import CSV from 'comma-separated-values';

import DropZone from 'components/DropZone';
// import { validateCsvFile } from 'utils/file';

import Preview from './Preview';
import Progress from './Progress';
import Error from './Error';
import withTranslation from 'utils/react/withTranslation';

const D2 = debug('c:ModalWindow:NewDataset:NewDataset');

class UploadDataset extends React.PureComponent {
  state = {
    data: null,
    file: null,
    error: null,
  };

  readFile = (file) => {
    D2.S.INFO('readFile', file);

    const reader = new FileReader();

    reader.onloadend = (e) => {
      if (e.target.readyState === FileReader.DONE) {
        D2.S.INFO('readFile:Done', e.target.result);
        const data = new CSV(e.target.result).parse();
        D2.S.INFO('readFile:Data', { data, slicedData: data.slice(0, 6) });
        this.setState({ data: data.slice(0, 6) });
      }
    };

    const blob = file.slice(0, 1024 ** 3);
    reader.readAsText(blob, 'UTF-8');
  };

  handleFileSelect = (e) => {
    D2.S.INFO('handleFileSelect', e);

    const file = e.target.files[0]; // FileList object

    // if (validateCsvFile(file)) {
    this.setState({ file, error: null });
    this.readFile(file);
    // } else {
    //   this.setState({ error: 'Oops! Invalid format' });
    // }
  };

  handleDropFileSelect = (e) => {
    D2.S.INFO('handleDropFileSelect', e);

    e.stopPropagation();
    e.preventDefault();
    const file = e.dataTransfer.files[0];

    // if (validateCsvFile(file)) {
    this.setState({ file, error: null });
    this.readFile(file);
    // } else {
    //   this.setState({ error: 'Oops! Invalid format' });
    // }
  };

  handleDragOver = (e) => {
    D2.S.INFO('handleDragOver', e);

    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
  };

  uploadData = (body) => {
    D2.S.INFO('uploadData', { body });

    const file = this.state.file;
    this.props.addDataset(body, file);
  };

  clearData = () => {
    D2.S.INFO('clearData');

    this.setState({ file: null, data: null });
  };

  render() {
    const { t } = this.props;

    D2.S.INFO('render', { props: this.props });

    if (!(window.File && window.FileReader && window.FileList && window.Blob)) {
      return <div>{t('NotSupportedAPI')}</div>;
    }

    const { data, file, error } = this.state;
    const { progress, status, projectUrl } = this.props;
    const apiError = this.props.error;
    const isError = error || apiError;

    if (progress) {
      return <Progress progress={progress} status={status} />;
    }

    if (data && !isError) {
      return (
        <Preview
          data={data}
          file={file}
          uploadData={this.uploadData}
          onReset={this.clearData}
          projectUrl={projectUrl}
        />
      );
    }

    return (
      <React.Fragment>
        <DropZone
          onDragOver={this.handleDragOver}
          onDrop={this.handleDropFileSelect}
          onChange={this.handleFileSelect}
        />
        {isError && <Error>{`${error || apiError}. ${t('TryAgain')}.`}</Error>}
      </React.Fragment>
    );
  }
}

export default withTranslation()(UploadDataset);

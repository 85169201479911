import styled from 'styled-components';

const Content = styled.div`
  position: absolute;
  top: 0;
  right: 100%;
  padding: 10px;
  border-radius: 10px;
  width: ${({ width }) => width || '200px'};
  background: #4d576a;
  z-index: 1;
`;

export default Content;

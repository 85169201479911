import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled(Link)`
  display: flex;
  margin-left: 10px;
  height: 100%;
  width: 100%;
  color: #c5cede;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  padding: 15px 0;
`;

export default Container;

import React from 'utils/react';

import Container from './Container';

const LoadingScreen = () => (
  <React.Fragment>
    <Container />
  </React.Fragment>
);

export default LoadingScreen;

import React from 'utils/react';
import debug from 'utils/debug';

import Progress from './Progress';

const D2 = debug('c:ModalWindow:ProgressWindow:Window');

class ModalWindow extends React.PureComponent {
  state = {};

  render() {
    D2.S.INFO('render', { props: this.props });

    const { progress, status } = this.props;

    return <Progress progress={progress} status={status} />;
  }
}

export default ModalWindow;

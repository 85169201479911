import styled from 'styled-components';

const DescriptionInput = styled.input`
  font-size: 17px;
  font-weight: 400;
  color: #edece8;
  background: transparent;
  border: 1px;
  border-radius: 5px;
  pointer-events: ${({ isEditable }) => (isEditable ? 'auto' : 'none')};
  padding: 3px 5px 3px;
  border-style: solid;
  border-color: ${({ isEditable }) => (isEditable ? '#58616d' : 'transparent')};
  outline: none;
  width: 250px;
  margin-bottom: 5px;
  margin-left: 5px;
`;

export default DescriptionInput;

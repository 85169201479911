import Object$fromEntries from 'fromentries';

import debug from 'utils/debug';

const D2 = debug('m:CommuteOffer');

// eslint-disable-next-line import/prefer-default-export
export const commuteOffer$FilterVehicles = (commuteOffer, vehicles) =>
  D2.S.FUNCTION('FilterVehicles', { commuteOffer, vehicles }, ({ $D2 }) => {
    const vehicleIdSet = (() => {
      if (vehicles instanceof Set) {
        $D2.S.INFO('vehicles:Set', { vehicles });
        return vehicles;
      }
      if (Array.isArray(vehicles)) {
        $D2.S.INFO('vehicles:Array', { vehicles });
        return new Set(vehicles.filter(id => id !== 'DEADBEEF'));
      }
      if (typeof vehicles === 'string') {
        $D2.S.INFO('vehicles:String', { vehicles });
        return new Set([vehicles]);
      }
      $D2.S.INFO('vehicles:Invalid', { vehicles, $vehicles: typeof vehicles });
      throw new Error(`Invalid type ${typeof vehicles}`);
    })();
    $D2.S.INFO('vehicleIdSet', { vehicleIdSet });

    const { result, stateless_api_request_data } = commuteOffer;

    const selectedObjects = Object.keys(result.vehicles).reduce(
      (resultVehiclesMemo, vehicleId) => {
        if (!vehicleIdSet.has(vehicleId)) {
          return resultVehiclesMemo;
        }
        const vehicle = result.vehicles[vehicleId];
        $D2.S.INFO('selectedObjects:vehicle', {
          resultVehiclesMemo,
          vehicleId,
          vehicle,
        });
        return {
          vehicles: {
            ...resultVehiclesMemo.vehicles,
            [vehicleId]: vehicle,
          },
          bookings: [
            ...resultVehiclesMemo.bookings,
            ...vehicle
              .filter(node => !!node.booking_uid)
              .map(node => node.booking_uid.toString()),
          ],
        };
      },
      { vehicles: {}, bookings: [] }
    );
    $D2.S.INFO('selectedObjects', { selectedObjects });

    const bookingIdSet = new Set(selectedObjects.bookings);
    $D2.S.INFO('bookingIdSet', { bookingIdSet });

    const requestNodes = stateless_api_request_data.nodes.filter(node =>
      bookingIdSet.has(node.booking_uid.toString())
    );
    $D2.S.INFO('requestNodes', { requestNodes });

    const bookings = Object$fromEntries(
      Object.entries(stateless_api_request_data.bookings)
    );

    const requestBookings = Object.keys(bookings).reduce(
      (requestBookingsMemo, bookingId) =>
        bookingIdSet.has(bookingId)
          ? {
              ...requestBookingsMemo,
              [bookingId]: stateless_api_request_data.bookings[bookingId],
            }
          : requestBookingsMemo,
      {}
    );
    $D2.S.INFO('requestBookings', {
      requestBookings,
      bookings,
    });

    const requestVehicles = stateless_api_request_data.vehicles.filter(
      vehicle => !!selectedObjects.vehicles[vehicle.agent_id]
    );
    $D2.S.INFO('requestVehicles', { requestVehicles });

    const resultAssignedBookings = result.assigned_bookings.filter(
      booking => !!selectedObjects.vehicles[booking.assigned_vehicle_id]
    );
    $D2.S.INFO('resultAssignedBookings', { resultAssignedBookings });

    return {
      ...commuteOffer,
      stateless_api_request_data: {
        ...commuteOffer.stateless_api_request_data,
        bookings: requestBookings,
        nodes: requestNodes,
        vehicles: requestVehicles,
      },
      result: {
        ...commuteOffer.result,
        vehicles: selectedObjects.vehicles,
        assigned_bookings: resultAssignedBookings,
      },
    };
  });

import React from 'utils/react';
import MapGL, { Layer, Source } from '@urbica/react-map-gl';
import LanguageControl from 'components/Map/LanguageControl';
import turfBbox from '@turf/bbox';
import geoViewport from '@mapbox/geo-viewport';
import debug from 'utils/debug';

import { map } from 'config';
import { geofenceLayers } from 'config/simulations';
import { getSourceGeofence } from 'utils/simulations';

import Container from './Container';
import Route from './Route';

const D2 = debug('c:MapSimulation:Map');

class Map extends React.Component {
  componentDidUpdate(prevProps) {
    D2.S.EVENT('componentDidUpdate');

    const { geofenceGeom } = this.props;
    if (!prevProps.geofenceGeom && geofenceGeom) {
      this.zoomToGeofence(geofenceGeom);
    }
  }

  zoomToGeofence = (geometry) => {
    D2.S.EVENT('zoomToGeofence');

    if (geometry) {
      const bbox = turfBbox(geometry).map(item => parseFloat(item, 10));
      const { center, zoom } = geoViewport.viewport(bbox, [800, 500]);
      const [longitude, latitude] = center;

      const { viewport } = this.props;
      viewport.zoom = zoom;
      viewport.latitude = latitude;
      viewport.longitude = longitude;

      this.props.onViewportChange(viewport);
    }
  };

  render() {
    D2.S.INFO('render', this.props);

    const { data, times, viewport, geofenceGeom } = this.props;

    const vehicles = times.index ? data.data[times.index] : {};

    return (
      <Container>
        <MapGL
          style={{ width: '100%', height: '100vh' }}
          mapStyle={map.mapStyle}
          accessToken={map.token}
          onViewportChange={this.props.onViewportChange}
          {...viewport}
        >
          <LanguageControl />
          <Source
            key='geofence'
            id='geofence'
            type='geojson'
            data={getSourceGeofence(geofenceGeom)}
          />
          {geofenceLayers.map(layer => (
            <Layer key={layer.id} {...layer} />
          ))}
          {times.time &&
            vehicles &&
            Object.keys(vehicles).map((key) => {
              const vehicle = vehicles[key];

              return (
                <Route
                  key={`route_${key}`}
                  id={key}
                  vehicle={vehicle}
                  index={vehicle.vehicle_id}
                />
              );
            })}
        </MapGL>
      </Container>
    );
  }
}

export default Map;

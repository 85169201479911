import React from 'utils/react';
import withTranslation from 'utils/react/withTranslation';

import LinkButton from './LinkButton';

class FileInput extends React.PureComponent {
  filesRef = React.createRef();

  onClickHandler = () => {
    this.filesRef.current.click();
  };

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <input
          ref={this.filesRef}
          type='file'
          id='files'
          value=''
          style={{ display: 'none' }}
          onChange={this.props.onChange}
        />
        {`${t('baseui.fileuploader.or')} `}
        <LinkButton onClick={this.onClickHandler}>
          {t('baseui.fileuploader.browseFiles')}
        </LinkButton>
      </React.Fragment>
    );
  }
}

export default withTranslation()(FileInput);

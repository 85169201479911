import React from 'utils/react';
import useTranslation from 'utils/react/useTranslation';
import Dropdown from 'components/Dropdown';

import Container from './Container';
import Content from './Content';
import Name from './Name';
import Type from './Type';
import Info from './Info';
import Logout from './Logout';

global.geodisc$logout = () => {};

const UserMenu = ({ userName, isAdmin, deleteUser }) => {
  const { t } = useTranslation();

  global.geodisc$logout = deleteUser;

  const onLogOut = () => {
    deleteUser();
  };
  if (!userName) {
    return null;
  }
  return (
    <Dropdown
      renderHandler={({ toggle, toggledOn }) => (
        <Container isActive={toggledOn} onClick={toggle}>
          {userName}
        </Container>
      )}
      renderContent={({ toggle }) => (
        <Content onClick={toggle}>
          <Info>
            <Name>{userName}</Name>
            <Type>
              {isAdmin
                ? t('header.User.Type.Admin')
                : t('header.User.Type.User')}
            </Type>
          </Info>
          <Logout onClick={onLogOut}>{t('header.Logout')}</Logout>
        </Content>
      )}
    />
  );
};

export default UserMenu;

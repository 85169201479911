import styled from 'styled-components';

const Label = styled.div`
  font-size: 17px;
  letter-spacing: 0;
  color: rgba(62, 71, 85, 0.5);
  padding: 10px 0;
`;

export default Label;

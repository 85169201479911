import moment from 'moment-timezone';

import debug from 'utils/debug';

const D2 = debug('u');

export default (time, timezone) =>
  D2.S.FUNCTION('normalizeTime', { time }, () => {
    if (!time) {
      return null;
    }

    const momentTime = moment(time);

    const offset = momentTime.format('SSSSSS') === '000000' ? 0 : 1000;

    const normalizedTime = moment(momentTime.unix() * 1000 + offset);

    return timezone
      ? normalizedTime.tz(timezone).format()
      : normalizedTime.format();
  });

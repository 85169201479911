import styled from 'styled-components';

const Container = styled.div`
  flex: 1 1;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
`;

export default Container;

import { put, take, select, call } from 'redux-saga/effects';

import debug from 'utils/debug';

import * as actions from 'modules/commuteOffer/actions';

import {
  commuteOfferCurrentDataSelector,
  allVehiclesSelector,
} from 'modules/commuteOffer/selectors';

const D2 = debug('m:CommuteOffer:saga:removeBookingFromRoute');

export const removeBookingFromRouteMethod = async (currentOffer, bookingId) =>
  D2.A.FUNCTION('Method', {}, async () => {
    const { assigned_bookings, rejected_bookings, vehicles } =
      currentOffer.result;
    const assignedBookingIdx = assigned_bookings.findIndex(
      item => item.uid === bookingId
    );
    const currentVehicleId =
      assigned_bookings[assignedBookingIdx].assigned_vehicle_id;

    const nodesInCurrentVehicle = vehicles[currentVehicleId].filter(
      vehicle => vehicle?.booking_uid !== bookingId
    );

    const updatedVehicles =
      currentOffer.stateless_api_request_data.vehicles.map((vehicle) => {
        if (
          vehicle?.agent_id === currentVehicleId &&
          nodesInCurrentVehicle.length === 0
        ) {
          return { ...vehicle, current_sim_ts: null };
        }
        return vehicle;
      });

    return {
      resultOffer: {
        ...currentOffer,
        result: {
          assigned_bookings: assigned_bookings
            .slice(0, assignedBookingIdx)
            .concat(
              assigned_bookings.slice(
                assignedBookingIdx + 1,
                assigned_bookings.length
              )
            ),
          rejected_bookings: [
            ...rejected_bookings,
            {
              ...assigned_bookings[assignedBookingIdx],
              assigned_vehicle_id: undefined,
            },
          ],
          vehicles: {
            ...vehicles,
            [currentVehicleId]: nodesInCurrentVehicle,
          },
        },
        stateless_api_request_data: {
          ...currentOffer.stateless_api_request_data,
          bookings: {
            ...currentOffer.stateless_api_request_data.bookings,
            [bookingId]: {
              ...currentOffer.stateless_api_request_data.bookings[bookingId],
              state: 'prepared',
            },
          },
          vehicles: updatedVehicles,
        },
      },
      updatedVehicleId: currentVehicleId,
    };
  });

// eslint-disable-next-line import/prefer-default-export
export function* removeBookingFromRouteHandler({ payload }) {
  D2.S.INFO('Handler:Request', { payload });

  try {
    const { bookingId } = payload;

    const currentOffer = yield select(commuteOfferCurrentDataSelector);
    D2.S.INFO('Handler:currentOffer', { currentOffer });

    const { resultOffer, updatedVehicleId } = yield call(
      removeBookingFromRouteMethod,
      currentOffer,
      bookingId
    );

    D2.S.INFO('Handler:resultOffer', { resultOffer, updatedVehicleId });

    yield put(actions.setResultOffer(resultOffer));
    yield take(actions.SET_RESULT_OFFER_RESULTS);

    if (updatedVehicleId) {
      const vehicles = yield select(allVehiclesSelector);
      const vehicle = vehicles.find(
        item => item.agent_id === updatedVehicleId
      );

      yield put(actions.fetchRoute(vehicle));
      yield take(actions.ROUTE_FETCH_RESULTS);

      yield put(actions.recalculateVehicleTime([updatedVehicleId], 'set'));
    }

    D2.S.INFO('Handler:Success', { payload });
  } catch (error) {
    D2.S.INFO('Handler:Failure', { error, payload });
    throw error;
  }
}

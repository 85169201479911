import styled from 'styled-components';

const CheckBox = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${({ isActive }) => (isActive ? '#E3E2EB' : 'transparent')};
  border: 1px solid ${({ isActive }) => (isActive ? 'transparent' : '#E3E2EB')};
  opacity: ${({ isActive }) => (isActive ? 1 : 0.6)};
  flex-shrink: 0;

  &:hover {
    border: 1px solid transparent;
    background: #e3e2eb;
    opacity: 0.8;
  }
`;

export default CheckBox;

import * as React from 'react';
import { List } from 'react-virtualized';

import Container from './Container';
import Result from './Result';

const SearchResults = ({ stops, addStopToRoute, vehicleId }) => {
  if (stops.length === 0) {
    return null;
  }

  const rowRenderer = ({ key, index, style }) => {
    const onClick = () => {
      addStopToRoute(stops[index].id, vehicleId);
    };

    return (
      <Result key={key} type='button' style={style} onClick={onClick}>
        {stops[index].name}
      </Result>
    );
  };

  return (
    <Container>
      <List
        width={220}
        height={100}
        rowCount={stops.length}
        rowHeight={20}
        rowRenderer={rowRenderer}
      />
    </Container>
  );
};

export default React.memo(SearchResults);

import React from 'utils/react';
import Tag from 'components/Tag';

import Container from './Container';
import Name from './Name';
import Description from './Description';
import Tags from './Tags';
import Button from './Button';
import Text from './Text';
import withTranslation from 'utils/react/withTranslation';

const Title = (props) => {
  const { dataset, isEditable, onChange, t } = props;

  const tags = dataset.get('tags');
  const dataset_id = dataset.get('id');
  const name = dataset.get('name');
  const description = dataset.get('description');
  const isDescription = tags.size > 0 || description;

  const onSave = () => {
    const body = JSON.stringify({
      name,
      description,
    });

    props.saveDataset(dataset_id, body);
  };

  const onCancelEdit = () => {
    props.setEditableDataset(null);
  };

  const handlerOnChange = (e) => {
    const { id, value } = e.target;
    onChange(id, value);
  };

  return (
    <Container>
      <Name
        id='name'
        value={name}
        onChange={handlerOnChange}
        isEditable={isEditable}
        placeholder={t('c.Header.placeholder.name')}
      />
      {isDescription && !isEditable && (
        <Text>
          {description}
          <Tags>
            {tags.map(tag => (
              <Tag key={tag} name={tag} />
            ))}
          </Tags>
        </Text>
      )}
      {isEditable && (
        <Description
          id='description'
          value={description}
          onChange={handlerOnChange}
          isEditable={isEditable}
          placeholder={t('c.Header.placeholder.description')}
        />
      )}
      {isEditable && (
        <div>
          <Button onClick={onSave}>{t('c.messages.Save')}</Button>
          <Button onClick={onCancelEdit}>{t('c.messages.Cancel')}</Button>
        </div>
      )}
    </Container>
  );
};

export default withTranslation()(Title);

import React from 'utils/react';
import useTranslation from 'utils/react/useTranslation';
import { StatefulPopover, PLACEMENT, TRIGGER_TYPE } from 'baseui/popover';
import { Block } from 'baseui/block';
import { geoToH3 } from 'h3-js';
import debug from 'utils/debug';
import displayNames from 'utils/display-names';
import moment from 'moment-timezone';
import { getRouteCoordinates } from 'utils/CommuteOffer';
import formatDurationTime from 'utils/moment/formatDurationTime';
import Container from './Container';
import Booking from './Booking';
import Close from './Close';
import Row from './Row';
import Wrap from './Wrap';
import Name from './Name';
import InfoRow from './InfoRow';
import InfoName from './InfoName';
import InfoValue from './InfoValue';
import PODSection from './PODSection';
import ServiceTimeTitle from '../ServiceTimeTitle';
import ServiceTimeCell from '../ServiceTimeCell';
import IdleTimeCell from '../IdleTimeCell';
import OrderStatus from 'components/OrderStatus';

const D2 = debug('p:CommuteOffer:Panels:Vehicles:Vehicle:Route:Stop');

export default (props) => {
  const { t, i18n } = useTranslation();
  return D2.S.FUNCTION('Info', { props }, () => {
    const {
      stop,
      bookings,
      isReadOnly,
      removeBookingFromRoute,
      setEditableBookingId,
      activeBookingId,
      layoutName,
      isTemplate,
      commuteOfferIsReadOnly,
    } = props;

    const isDeliveryLayout = layoutName === 'delivery';
    const timezone = global.GEODISC_TIMEZONE;

    return (
      <Container>
        {stop.bookings.map((booking) => {
          const { id, pax } = booking;
          const { booking_uid, slack, service_time } = booking.node;

          D2.S.INFO('Info:Booking', {
            id,
            pax,
            booking,
            slack,
            slack$type: typeof slack,
          });

          const sourceBooking = bookings.find(
            item => item.uid === booking_uid
          );

          const routeId = `${id}:${stop.uid}`;

          const coordinates =
            stop.node_type === 'pickup'
              ? [
                  {
                    lon: sourceBooking?.pickup_location_lon,
                    lat: sourceBooking?.pickup_location_lat,
                  },
                  {
                    lon: booking.node.lon,
                    lat: booking.node.lat,
                  },
                ]
              : [
                  {
                    lon: sourceBooking?.dropoff_location_lon,
                    lat: sourceBooking?.dropoff_location_lat,
                  },
                  {
                    lon: booking.node.lon,
                    lat: booking.node.lat,
                  },
                ];

          const isArrived = false;
          // stop.lon === booking.node.lon && stop.lat === booking.node.lat;

          const routeCoordinates = getRouteCoordinates(
            coordinates,
            true,
            false
          );

          const walkingRoute =
            global.WALKING_ROUTES &&
            global.WALKING_ROUTES[routeCoordinates] &&
            global.WALKING_ROUTES[routeCoordinates].route;
          const legs =
            walkingRoute &&
            walkingRoute.routes &&
            walkingRoute.routes[0] &&
            walkingRoute.routes[0].legs;

          const distance = isArrived
            ? 0
            : legs &&
              legs.reduce((acc, leg) => {
                return acc + leg.distance;
              }, 0);
          const duration = isArrived
            ? 0
            : legs &&
              legs.reduce((acc, leg) => {
                return acc + leg.duration;
              }, 0);
          D2.S.INFO('Info:WalkingRoute', {
            bookings_id: booking.id,
            isArrived,
            booking,
            stop,
            coordinates,
            routeId,
            routeCoordinates,
            walkingRoute,
            legs,
            distance,
            duration,
          });

          const readOnlyError = () => {
            // global.openInfoMessage('Vehicle is read-only', { title: 'Error' });
          };

          const removeBooking = () => {
            if (!isReadOnly) {
              removeBookingFromRoute(id);
            } else {
              readOnlyError();
            }
          };

          const setActive = () => {
            if (!isReadOnly) {
              setEditableBookingId(
                id,
                'p:CommuteOffer:Panels:Vehicles:Vehicle:Route:Stop:setActive'
              );
            } else {
              readOnlyError();
            }
          };

          const isEucledianOrSpheroid =
            window.GEODISC_WALKING_ROUTING_ENGINE !== 'euclidean' ||
            window.GEODISC_WALKING_ROUTING_ENGINE !== 'spheroid';

          const isDistanceEnabled =
            !window.GEODISC_UI_COMMUTE_OFFER_WALKING_DISTANCE_DISABLE &&
            isEucledianOrSpheroid;
          const isDurationEnabled =
            !window.GEODISC_UI_COMMUTE_OFFER_WALKING_DURATION_DISABLE &&
            isEucledianOrSpheroid;

          const { open_time_ts, close_time_ts, scheduled_ts } =
            booking.$node || booking.node;

          const open_time_mts = moment(open_time_ts);
          const close_time_mts = moment(close_time_ts);
          const scheduled_mts = moment(scheduled_ts);
          const isScheduledTimeValid =
            !scheduled_mts.isBefore(open_time_mts) &&
            !scheduled_mts.isAfter(close_time_mts);

          const bookingDisplayNames = displayNames(
            sourceBooking?.$display_name ||
              sourceBooking?.display_name ||
              sourceBooking?.data?.username ||
              sourceBooking?.name ||
              `Booking ${id}`
          );
          D2.S.INFO('Info:bookingDisplayNames', {
            bookingDisplayNames,
            sourceBooking,
          });

          // Only show booking status (Success, Failed to deliver)
          // for Logistics, not in a template, and for dropoffs
          // Other statuses (enroute, assigned, etc) are not shown for this case
          const showBookingStatus =
            isDeliveryLayout &&
            !isTemplate &&
            // stop.node_type !== 'pickup' &&
            /(completed|fail_to_deliver)/i.test(sourceBooking?.state);

          const bookingComponent = () => (
            <Booking key={id}>
              <Wrap
                onClick={setActive}
                isScheduledTimeValid={isScheduledTimeValid}
                isActive={activeBookingId === id}
              >
                <Row key='booking-names'>
                  {bookingDisplayNames.map((x, i) => (
                    <Name key={`booking-name-${i}`}>{x}</Name>
                  ))}
                </Row>
                {Object.entries(booking.$node?.demand ?? {})
                  .filter(([, value]) => !!value)
                  .map(([name, value]) => (
                    <Row key={`demand-${name}`}>
                      {name}
                      :&nbsp;
                      {Math.abs(value)}
                    </Row>
                  ))}
                <table>
                  <tbody>
                    {!!(service_time && service_time >= 60) && (
                      <tr>
                        <ServiceTimeTitle>
                          {t('c.messages.ServiceTime')}
                          :&nbsp;
                        </ServiceTimeTitle>
                        <ServiceTimeCell>
                          {formatDurationTime(service_time, i18n.language)}
                        </ServiceTimeCell>
                      </tr>
                    )}
                    {!!(slack && slack >= 60) && (
                      <tr>
                        <IdleTimeCell>
                          {t('c.messages.IdleTime')}
                          :&nbsp;
                        </IdleTimeCell>
                        <IdleTimeCell>
                          {formatDurationTime(slack, i18n.language)}
                        </IdleTimeCell>
                      </tr>
                    )}
                  </tbody>
                </table>
                {showBookingStatus && (
                  <div>
                    <OrderStatus status={sourceBooking.state} />
                  </div>
                )}
                {window.GEODISC_POD_SECTION_ENABLED &&
                  isDeliveryLayout &&
                  !isTemplate &&
                  booking?.$node?.status === 'completed' && (
                    <PODSection node={booking.$node} />
                  )}
                {isDistanceEnabled && (
                  <div>
                    {typeof distance !== 'undefined'
                      ? `${Math.round(distance)} meters`
                      : 'unknown distance'}
                  </div>
                )}
                {isDurationEnabled && (
                  <div>
                    {typeof duration !== 'undefined'
                      ? `${moment
                          .duration(Math.round(duration), 'seconds')
                          .humanize()}`
                      : 'unknown duration'}
                  </div>
                )}
              </Wrap>
              {!commuteOfferIsReadOnly && !isReadOnly ? (
                <Close onClick={removeBooking} />
              ) : (
                <Close disabled />
              )}
            </Booking>
          );

          return isDeliveryLayout || !isScheduledTimeValid ? (
            <StatefulPopover
              key={`booking-popover-${id}`}
              content={() => (
                <Block
                  key={`booking-popover-content-${id}`}
                  padding='10px'
                  maxWidth='320px'
                  overflow='hidden'
                >
                  <table>
                    <tbody>
                      {isDeliveryLayout && (
                        <InfoRow>
                          <InfoName>Unit No:</InfoName>
                          <InfoValue>
                            {stop.node_type === 'pickup'
                              ? stop.data?.pickup_unit_number || 'none'
                              : stop.data?.dropoff_unit_number || 'none'}
                          </InfoValue>
                        </InfoRow>
                      )}
                      {isDeliveryLayout && (
                        <InfoRow>
                          <InfoName>
                            {t('c.messages.InfoName.Phone')} Phone:
                          </InfoName>
                          <InfoValue>{stop.data?.phone || 'none'}</InfoValue>
                        </InfoRow>
                      )}
                      {isDeliveryLayout && (
                        <InfoRow>
                          <InfoName>Remarks:</InfoName>
                          <InfoValue>{stop.data?.remarks || 'none'}</InfoValue>
                        </InfoRow>
                      )}
                      <InfoRow>
                        <InfoName>Earliest time:</InfoName>
                        <InfoValue>
                          {open_time_mts.tz(timezone).format('HH:mm')}
                        </InfoValue>
                      </InfoRow>
                      <InfoRow>
                        <InfoName>Latest time:</InfoName>
                        <InfoValue>
                          {close_time_mts.tz(timezone).format('HH:mm')}
                        </InfoValue>
                      </InfoRow>
                      <InfoRow>
                        <InfoName>H3:</InfoName>
                        <InfoValue>{geoToH3(stop.lat, stop.lon, 13)}</InfoValue>
                      </InfoRow>
                    </tbody>
                  </table>
                </Block>
              )}
              overrides={{
                Body: {
                  style: () => ({
                    backgroundColor: '#161B1F',
                  }),
                },
                Arrow: {
                  style: () => ({
                    backgroundColor: '#161B1F',
                  }),
                },
                Inner: {
                  style: () => ({
                    backgroundColor: '#161B1F',
                  }),
                },
              }}
              placement={PLACEMENT.right}
              triggerType={TRIGGER_TYPE.hover}
              mountNode={document.body}
              ignoreBoundary
              showArrow
            >
              {bookingComponent()}
            </StatefulPopover>
          ) : (
            <React.Fragment key={`booking-fragment-${id}`}>
              {bookingComponent()}
            </React.Fragment>
          );
        })}
      </Container>
    );
  });
};

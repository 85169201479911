import React from 'utils/react';
import useTranslation from 'utils/react/useTranslation';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';

import { FileUploader } from 'baseui/file-uploader';

import debug from 'utils/debug';

const D2 = debug('c:ModalWindows:FileUploaderDialog');

export default (props) => {
  D2.S.INFO('FileUploaderDialog', props);

  const { t } = useTranslation();
  const l = msg => `c.ModalWindow.FileUploaderDialog.${msg}`;

  const [isOpen, setIsOpen] = React.useState(false);
  const [windowTitle, setWindowTitle] = React.useState(l('Title.Default'));
  const [eventHandlers, setEventHandlers] = React.useState({});

  React.useEffect(() => {
    D2.S.INFO('Update');

    window.openFileUploaderDialog = (opts) => {
      D2.S.INFO('openFileUploaderDialog', { opts });
      const { title, onDrop = null } = opts || {};
      if (title) {
        setWindowTitle(title || l('Title.Default'));
        // setWindowTitle(l('Title.Default'));
      }
      setEventHandlers({ onDrop });
      setIsOpen(true);
    };

    window.closeFileUploaderDialog = () => {
      D2.S.INFO('closeFileUploaderDialog');
      setIsOpen(false);
    };

    return () => {
      D2.S.INFO('Cleanup');
      window.openFileUploaderDialog = () => {};
      window.closeFileUploaderDialog = () => {};
    };
  });

  const onDrop = (acceptedFiles, rejectedFiles) => {
    D2.S.INFO('onDrop', { acceptedFiles, rejectedFiles });
    if (eventHandlers.onDrop) {
      eventHandlers.onDrop({ acceptedFiles, rejectedFiles });
    }
    setIsOpen(false);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      <Modal onClose={onClose} isOpen={isOpen}>
        <ModalHeader>{t(windowTitle)}</ModalHeader>
        <ModalBody>
          <FileUploader onDrop={onDrop} />
        </ModalBody>
        <ModalFooter>
          <ModalButton onClick={onClose}>{t(l('Footer.Close'))}</ModalButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

window.openFileUploaderDialog = () => {};
window.closeFileUploaderDialog = () => {};

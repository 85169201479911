import React from 'utils/react';
import Container from './Container';
import Button from './Button';

const StopsButtons = (props) => {
  const {
    busStopsVisible,
    toggleActiveStopsType,
    clearActiveStopsType,
    stopsTypes,
  } = props;

  const onClickStopsButton = () => {
    if (busStopsVisible.length) {
      clearActiveStopsType();
    } else {
      toggleActiveStopsType('bus_stop');
    }
  };

  return (
    <Container>
      {!!busStopsVisible.length &&
        stopsTypes.map((stopType) => {
          const onClick = () => {
            toggleActiveStopsType(stopType);
          };

          return (
            <Button
              key={stopType}
              onClick={onClick}
              isActive={busStopsVisible.includes(stopType)}
            >
              {stopType}
            </Button>
          );
        })}
      <Button onClick={onClickStopsButton}>
        {busStopsVisible.length ? 'Close' : 'Stops'}
      </Button>
    </Container>
  );
};

export default StopsButtons;

import { urls } from 'config';
import { fetchData } from './net';
import { getHeaders } from './headers';

// eslint-disable-next-line
export const getStops = async () => {
  try {
    const response = await fetchData(urls.stops, { headers: getHeaders() });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();

    return responseJSON;
  } catch (error) {
    throw error;
  }
};

export const getDriversForProject = async (project_id) => {
  const response = await fetchData(
    `${urls.driverApi}?offset=0&limit=0&project=${project_id}`,
    {
      method: 'GET',
      headers: getHeaders(),
    }
  );

  if (!response.ok) {
    throw new Error(response.status);
  }
  const responseJSON = await response.json();
  let drivers = responseJSON.objects || [];
  // Sort the drivers list to show only the earliest driver on the list if there are multiple drivers with same user account
  //(SGERP allows to create multiple drivers under same user).
  drivers = drivers.sort((a, b) => a.id - b.id);

  // filter to get drivers that the original user belong to the same project
  if (drivers.length) {
    const usersResponse = await fetchData(
      `${urls.projectmemberApi}?user__in=${drivers
        .map(i => i.user.split('/').at(-1))
        .join(',')}&project=${project_id}&limit=0`,
      {
        method: 'GET',
        headers: getHeaders(),
      }
    );

    if (!usersResponse.ok) {
      throw new Error(usersResponse.status);
    }

    const usersResponseJSON = await usersResponse.json();
    drivers = usersResponseJSON.objects.map(user =>
      drivers.find(driver => driver.user === user.user)
    );
  }

  return drivers;
};

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-right: 30px;
  margin-left: 30px;
  flex-shrink: 0;
`;

export default Container;

import moment from 'moment-timezone';
import debug from 'utils/debug';
import weekdays from 'utils/weekdays';

const D2 = debug('m:CommuteSchedule:PassengersTableFromOffer');

const formatBoolean = (v, y, n) => (v ? y || 'Yes' : n || '');

const formatTime = (v) => {
  if (!v || v === '') {
    return '';
  }
  try {
    const text = moment.tz(v, 'LT', global.GEODISC_TIMEZONE).format('HH:mm');
    return text !== 'Invalid date' ? text : '';
  } catch (e) {
    return '';
  }
};

// const formatDuration = v => {
//   const text = moment.duration(v, 'milliseconds').humanize();
//   return text !== 'Invalid date' && text !== 'a few seconds' ? text : '';
// };

// eslint-disable-next-line import/prefer-default-export
export const commuteSchedule$PassengersTableFromOffer = (commuteOffer) => {
  D2.S.INFO('Request', { commuteOffer });

  const columns = [
    ...[
      {
        kind: 'STRING',
        name: 'name',
        label: 'Name',
        width: 'minmax(350px, max-content)',
      },
      {
        kind: 'STRING',
        label: 'Postal code',
        name: 'zip_code',
        width: '120px',
      },
      {
        kind: 'STRING',
        label: 'Going to the Eldercare center at',
        name: 'morning_dropoff_ts',
        width: 'minmax(150px, max-content)',
      },
      {
        kind: 'STRING',
        label: 'Scheduled pick up time from home',
        name: 'morning_pickup_scheduled_time',
        width: 'minmax(150px, max-content)',
      },
      // {
      //   kind: 'STRING',
      //   label: 'Scheduled pick up time from home starts at',
      //   name: 'morning_pickup_scheduled_open_time',
      //   width: 'minmax(150px, max-content)'
      // },
      // {
      //   kind: 'STRING',
      //   label: 'Scheduled pick up time from home ends at',
      //   name: 'morning_pickup_scheduled_close_time',
      //   width: 'minmax(150px, max-content)'
      // },
      {
        kind: 'STRING',
        label: 'Earliest possible pick up time from home',
        name: 'morning_pickup_from_ts',
        width: 'minmax(150px, max-content)',
      },
      {
        kind: 'STRING',
        label: 'Latest possible pick up time from home',
        name: 'morning_pickup_till_ts',
        width: 'minmax(150px, max-content)',
      },

      {
        kind: 'STRING',
        label: 'Leaving the Eldercare center at',
        name: 'evening_pickup_ts',
        width: 'minmax(150px, max-content)',
      },
      {
        kind: 'STRING',
        label: 'Scheduled drop off time at home',
        name: 'evening_dropoff_scheduled_time',
        width: 'minmax(150px, max-content)',
      },
      // {
      //   kind: 'STRING',
      //   label: 'Scheduled drop off time at home starts at',
      //   name: 'evening_dropoff_scheduled_open_time',
      //   width: 'minmax(150px, max-content)'
      // },
      // {
      //   kind: 'STRING',
      //   label: 'Scheduled drop off time at home ends at',
      //   name: 'evening_dropoff_scheduled_close_time',
      //   width: 'minmax(150px, max-content)'
      // },
      {
        kind: 'STRING',
        label: 'Earliest possible drop off time at home',
        name: 'evening_dropoff_from_ts',
        width: 'minmax(150px, max-content)',
      },
      {
        kind: 'STRING',
        label: 'Latest possible drop off time at home',
        name: 'evening_dropoff_till_ts',
        width: 'minmax(150px, max-content)',
      },
      {
        kind: 'BOOLEAN',
        label: 'Wheelchair',
        name: 'wheelchair',
        width: 'max-content',
      },
      {
        kind: 'BOOLEAN',
        label: 'Mon',
        name: 'mon',
        width: '60px',
      },
      {
        kind: 'BOOLEAN',
        label: 'Tue',
        name: 'tue',
        width: '60px',
      },
      {
        kind: 'BOOLEAN',
        label: 'Wed',
        name: 'wed',
        width: '60px',
      },
      {
        kind: 'BOOLEAN',
        label: 'Thu',
        name: 'thu',
        width: '60px',
      },
      {
        kind: 'BOOLEAN',
        label: 'Fri',
        name: 'fri',
        width: '60px',
      },
      {
        kind: 'BOOLEAN',
        label: 'Sat',
        name: 'sat',
        width: '60px',
      },
      {
        kind: 'BOOLEAN',
        label: 'Sun',
        name: 'sun',
        width: '60px',
      },
    ],
    // ...Object.keys(weekdays).reduce((memo, weekday) => {
    //   return [
    //     ...memo,
    //     ...[
    //       {
    //         kind: 'STRING',
    //         label: `${weekdays[weekday].name} morning service time`,
    //         name: `${weekday}_morning_service_time`,
    //         width: 'minmax(150px, max-content)'
    //       },
    //       {
    //         kind: 'STRING',
    //         label: `${weekdays[weekday].name} morning service duration`,
    //         name: `${weekday}_morning_service_duration`,
    //         width: 'minmax(150px, max-content)'
    //       },
    //       {
    //         kind: 'STRING',
    //         label: `${weekdays[weekday].name} evening service time`,
    //         name: `${weekday}_evening_service_time`,
    //         width: 'minmax(150px, max-content)'
    //       },
    //       {
    //         kind: 'STRING',
    //         label: `${weekdays[weekday].name} evening service duration`,
    //         name: `${weekday}_morning_service_duration`,
    //         width: 'minmax(200px, max-content)'
    //       }
    //     ]
    //   ];
    // }, []),
    ...[
      {
        kind: 'STRING',
        label: 'Country',
        name: 'country',
        width: '150px',
      },
      {
        kind: 'STRING',
        label: 'City',
        name: 'city',
        width: '150px',
      },
      {
        kind: 'NUMERICAL',
        label: 'Longitude',
        name: 'lon',
        width: '120px',
      },
      {
        kind: 'NUMERICAL',
        label: 'Latitude',
        name: 'lat',
        width: '120px',
      },
    ],
  ];

  const schedule = commuteOffer.stateless_api_request_data.inbound.schedule;
  D2.S.INFO('schedule', { schedule });

  const fixedSchedule =
    commuteOffer.stateless_api_request_data.inbound.fixed_schedule;
  D2.S.INFO('fixedSchedule', { fixedSchedule });

  // const trips = commuteOffer.stateless_api_request_data.inbound.trips;
  // D2.S.INFO('trips', { trips });

  const rows = Object.keys(schedule).map((id) => {
    D2.S.INFO('resultTable:Item', { id, schedule });

    const p = schedule[id];

    return [
      ...[
        p.name,
        p.zip_code,
        formatTime(p.morning_dropoff_ts),
        formatTime(p.morning_pickup_scheduled_time),
        // formatTime(p.morning_pickup_scheduled_open_time),
        // formatTime(p.morning_pickup_scheduled_close_time),
        formatTime(p.morning_pickup_from_ts),
        formatTime(p.morning_pickup_till_ts),

        formatTime(p.evening_pickup_ts),
        formatTime(p.evening_dropoff_scheduled_time),
        // formatTime(p.evening_dropoff_scheduled_open_time),
        // formatTime(p.evening_dropoff_scheduled_close_time),
        formatTime(p.evening_dropoff_from_ts),
        formatTime(p.evening_dropoff_till_ts),
        formatBoolean(p.wheelchair),
      ],
      ...Object.keys(weekdays)
        // .filter(x => x !== 'sat' && x !== 'sun')
        .map(weekday => formatBoolean(p[weekday], p.wheelchair ? 'WC' : 'A')),
      // ...Object.keys(weekdays).reduce((memo, weekday) => {
      //   const tripsSchedule = trips[weekday].schedule[id];

      //   const scheduled_morning_pickup_ts = tripsSchedule
      //     ? tripsSchedule.morning_pickup_ts
      //     : '';
      //   const scheduled_evening_dropoff_ts = tripsSchedule
      //     ? tripsSchedule.evening_dropoff_ts
      //     : '';

      //   const morning_pickup_ts =
      //     fixedSchedule && fixedSchedule[id]
      //       ? fixedSchedule[id].morning_pickup_ts
      //       : scheduled_morning_pickup_ts;

      //   const evening_dropoff_ts =
      //     fixedSchedule && fixedSchedule[id]
      //       ? fixedSchedule[id].evening_dropoff_ts
      //       : scheduled_evening_dropoff_ts;

      //   const morning_service_duration =
      //     p[weekday] && morning_pickup_ts !== ''
      //       ? moment
      //           .tz(p.morning_dropoff_ts, 'LT', 'UTC')
      //           .diff(moment.tz(morning_pickup_ts, 'LT', 'UTC'))
      //       : '';

      //   const evening_service_duration =
      //     p[weekday] && evening_dropoff_ts !== ''
      //       ? moment
      //           .tz(evening_dropoff_ts, 'LT', 'UTC')
      //           .diff(moment.tz(p.evening_pickup_ts, 'LT', 'UTC'))
      //       : '';

      //   return [
      //     ...memo,
      //     ...[
      //       formatTime(morning_pickup_ts),
      //       formatDuration(morning_service_duration),
      //       formatTime(evening_dropoff_ts),
      //       formatDuration(evening_service_duration)
      //     ]
      //   ];
      // }, []),
      ...[p.country, p.city, p.lon, p.lat],
    ];
  });

  const resultTable = {
    columns,
    rows,
  };
  D2.S.INFO('Success', {
    commuteOffer,
    resultTable,
  });

  return resultTable;
};

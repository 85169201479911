import React from 'utils/react';

import Header from 'components/Header';
import Layout from 'components/Layout';

import Container from './Container';
import Panel from './Panel';
import Table from './Table';
import TableHeader from './TableHeader';
import { useEffect } from 'react';

const Datasets = (props) => {
  const { list, page, projectId, fetchDatasets } = props;

  useEffect(() => {
    if ((!list && projectId) || (page && projectId)) {
      fetchDatasets();
    }
  }, [page, projectId]);

  return (
    <Layout hasBackground>
      <Container>
        <Header />
        <Panel />
        <TableHeader />
        <Table />
      </Container>
    </Layout>
  );
};

export default Datasets;

import styled from 'styled-components';
import close from './close.svg';

export default styled.div`
  position: absolute;
  top: 12px;
  right: 3px;
  width: 10px;
  height: 10px;
  background: url(${close}) no-repeat;
  background-size: contain;
  cursor: pointer;
  transition: 0.3s;
`;

import styled from 'styled-components';

export default styled.div`
  position: fixed;
  bottom: 0;
  left: 0px;
  height: 50px;
  width: 360px;
  padding: 7px 10px;
`;

import React from 'utils/react';
import withDragDropContext from './withDragDropContext';

import Container from './Container';
import Vehicles from './Vehicles';
import Bookings from './Bookings';
import Footer from './Footer';

const Panels = () => (
  <Container>
    <Vehicles />
    <Bookings />
  </Container>
);

export default withDragDropContext(Panels);

import styled from 'styled-components';

export default styled.div`
  width: 300px;
  height: 100%;
  padding: 24px;
  border-radius: 10px;
  margin: 0 0 0 10px;

  background-color: #080d14bf;
  background-image: linear-gradient(180deg, #0c0f14 40px, rgba(0, 0, 0, 0) 70px),
    linear-gradient(0deg, #0c0f14 40px, rgba(0, 0, 0, 0) 70px);
  backdrop-filter: blur(8px);
  transition: ${({ isHidden }) =>
    isHidden ? 'opacity 0.3s ease-out' : 'opacity 0.5s ease-out'};
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  transform-origin: left;
  pointer-events: ${({ isHidden }) => (isHidden ? 'none' : 'auto')};
`;

import React from 'utils/react';
import Select from 'react-select';

import Option from './Option';
import colourStyles from './colourStyles';
import Container from './Container';
import Label from './Label';

class InputSelect extends React.PureComponent {
  handleChange = (value) => {
    this.props.onChange(this.props.name, value);
  };

  handleBlur = () => {
    this.props.onBlur(this.props.name, true);
  };

  render() {
    const { label, ...otherProps } = this.props;
    return (
      <Container>
        <Label>{label}</Label>
        <Select
          {...otherProps}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          components={{ Option }}
          styles={colourStyles}
          placeholder=''
        />
      </Container>
    );
  }
}

export default InputSelect;

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { isDeliveryLayoutSelector } from 'modules/user/selectors';
import { commuteOfferLoadedSimulationSelector } from 'modules/commuteOffer/selectors';

import {
  Stop as StopComponent,
  DraggableStop as DraggableStopComponent,
} from './Stop';

const mapStateToProps = createImmutableSelector(
  isDeliveryLayoutSelector,
  commuteOfferLoadedSimulationSelector,
  (isDeliveryLayout, simulation) => ({
    isDeliveryLayout,
    simulation,
  })
);

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export const Stop = withConnect(StopComponent);
export const DraggableStop = withConnect(DraggableStopComponent);

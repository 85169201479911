/* eslint-disable */
import React from 'utils/react';
import useTranslation from 'utils/react/useTranslation';
import { DragSource, DropTarget } from 'react-dnd';
import moment from 'moment-timezone';
import Button from 'pages/CommuteOffer/TextButton';
import debug from 'utils/debug';

import Container from './Container';
import ButtonContainer from './ButtonsContainer';
import NodeType from './NodeType';
import Row from './Row';
import Info from './Info';
import Header from './Header';
import ArrivalTime from './ArrivalTime';
import DepartureTime from './DepartureTime';
import Location from './Location';
import IndexBadge from './IndexBadge';
import ServiceTimeCell from './ServiceTimeCell';
import IdleTimeCell from './IdleTimeCell';
import PickupDropoffCard from './PickupDropoffCard';
import { ReactComponent as BusIcon } from 'assets/bus.svg';
import Break from 'assets/break.svg';
import formatDurationTime from 'utils/moment/formatDurationTime';

import Label from '../../Label';
import Value from '../../Value';

const D2 = debug('p:Logistics:Panels:Vehicles:Vehicle:Route');

export const Stop = React.forwardRef((props, ref) => {
  D2.S.INFO('Stop', props);

  const { t, i18n } = useTranslation();

  const {
    showRouteExtensionLine,
    index,
    isActive,
    hasNodesWithInvalidScheduledTime,
    isReadOnly,
    vehicleId,
    onClick,
    color,
    isHaveEditable,
    stop,
    nextStop,
    isDragging,
    connectDragSource,
    connectDropTarget,
    deleteRoutePoint,
    cleanActiveRouteStop,
    isDeliveryLayout,
    simulation,
    isBreak,
    displayNumber,
  } = props;

  const isCompleted =
    (stop.status === 'completed' && !!stop.completed_service_at) ||
    (stop.status === 'assigned' && !!stop.completed_service_at);

  if (stop.status === 'completed' && !stop.completed_service_at) {
    // eslint-disable-next-line no-console
    console.log(
      'WARNING: Waypoint is completed, however, the completion time is absent',
      stop
    );
  }

  if (stop.status !== 'completed' && !!stop.completed_service_at) {
    // eslint-disable-next-line no-console
    console.log(
      'WARNING: Waypoint is NOT completed, however, the completion time is present',
      stop
    );
  }

  const isNextStopCompleted =
    nextStop &&
    (nextStop.status === 'completed' || !!nextStop.completed_service_at);
  const isLastCompletedStop = isCompleted && !isNextStopCompleted;

  const elementRef = React.useRef(null);
  if (connectDragSource) {
    connectDragSource(elementRef);
  }
  if (connectDropTarget) {
    connectDropTarget(elementRef);
  }
  const opacity = isDragging ? 0.3 : 1;
  React.useImperativeHandle(ref, () => ({
    getNode: () => elementRef.current,
  }));

  const readOnlyError = () => {
    // global.openInfoMessage('Vehicle is read-only', { title: 'Error' });
  };

  const onDeleteRoutePoint = () => {
    if (!isReadOnly && !isCompleted) {
      deleteRoutePoint(stop.uid, vehicleId);
      cleanActiveRouteStop();
    } else {
      readOnlyError();
    }
  };

  const {
    slack = 0,
    service_time = 0,
    scheduled_ts,
    estimated_scheduled_ts,
    completed_service_at,
    location_name,
    node_type,
    $loadAfterStop,
    $capacity_info,
    dynamic_break,
  } = stop;

  const isPoint = node_type === 'point' || node_type === 'stop';

  const isRealtime = !!estimated_scheduled_ts && !isCompleted;
  const isScheduled = !isRealtime && !isCompleted;

  const scheduledTs = moment(scheduled_ts)
    .tz(global.GEODISC_TIMEZONE)
    .format('HH:mm');

  const nodeArrivalTs = moment(
    completed_service_at || estimated_scheduled_ts || scheduled_ts
  );

  // SP-4233: round up the millisecond if required
  if (nodeArrivalTs.millisecond() >= 500) {
    nodeArrivalTs.add(1, 'second');
    nodeArrivalTs.set({ millisecond: 0 });
  }

  const nodeArrivalTime = nodeArrivalTs
    .tz(global.GEODISC_TIMEZONE)
    .format('HH:mm');

  let nodeDepartureTs = moment(nodeArrivalTs);
  nodeDepartureTs.add(service_time + slack, 'seconds');
  const nodeDepartureTime = nodeDepartureTs
    .tz(global.GEODISC_TIMEZONE)
    .format('HH:mm');

  const hrPax =
    !isPoint && t(`u.capacity.type.${stop.pax_type}`, { count: stop.pax });

  const demandInfo = stop.bookings.reduce(
    (demandInfoMemo, booking) => ({
      ...demandInfoMemo,
      ...Object.entries(booking.$node.demand).reduce(
        (memo, [k, v]) => ({
          ...memo,
          [k]: demandInfoMemo[k]
            ? demandInfoMemo[k] + Math.abs(v)
            : Math.abs(v),
        }),
        {}
      ),
    }),
    {}
  );

  const dropoffCount = stop.bookings?.reduce((count, booking) => {
    if (booking.node?.node_type === 'dropoff') {
      return count + 1;
    }
    return count;
  }, 0);
  const pickupCount = stop.bookings?.reduce((count, booking) => {
    if (booking.node?.node_type === 'pickup') {
      return count + 1;
    }
    return count;
  }, 0);

  const { display_vehicle_load_for_pickup: isDisplayLoadAfterPickup = true } =
    simulation?.data.logistics_api_settings;
  const { display_vehicle_load_for_dropoff: isDisplayLoadAfterDropoff = true } =
    simulation?.data.logistics_api_settings;
  const isDisplayLoad =
    (pickupCount > 0 && isDisplayLoadAfterPickup) ||
    (dropoffCount > 0 && isDisplayLoadAfterDropoff);

  const isWholeNumber = (number) => {
    return number % 1 === 0;
  };

  return (
    <Container
      isActive={isActive}
      color={isCompleted ? 'rgb(94, 100, 114)' : color}
      isDropoff={node_type === 'dropoff'}
      content={isCompleted ? `'✔'` : isBreak ? `''` : `'${displayNumber}'`}
      icon={isBreak && !isCompleted ? `url(${Break})` : ``}
      showRouteExtensionLine={showRouteExtensionLine}
      data-testid='Route-Stop'
    >
      {isLastCompletedStop && nextStop ? (
        <>
          <div
            style={{
              width: 24,
              height: 24,
              padding: 3,
              borderRadius: '50%',
              left: -24,
              top: '50%',
              position: 'absolute',
              background: color,
              overflow: 'hidden',
              zIndex: 2,
            }}
          >
            <BusIcon
              style={{
                width: '100%',
                height: '100%',
                color: 'black',
              }}
            />
          </div>
          <div
            style={{
              position: 'absolute',
              left: -13,
              bottom: -20,
              height: '50%',
              background: color,
              width: 2,
            }}
          ></div>
        </>
      ) : null}

      <div ref={elementRef} style={Object.assign({}, { opacity })}>
        <Header onClick={onClick}>
          <Location hasErrors={hasNodesWithInvalidScheduledTime}>
            {isBreak
              ? t('p.booking.card.labels.break')
              : location_name || '---'}
          </Location>
          {dropoffCount > 0 && (
            <PickupDropoffCard
              content={
                <span style={{ color: '#97A0C0' }}>
                  {t('p.booking.card.labels.dropoff', { count: dropoffCount })}
                </span>
              }
            />
          )}
          {pickupCount > 0 && (
            <PickupDropoffCard
              isPickup={true}
              content={
                <span style={{ color: '#97A0C0' }}>
                  {t('p.booking.card.labels.pickup', { count: pickupCount })}
                </span>
              }
            />
          )}
          {global.GEODISC_COMMUTE_OFFER_SHOW_FULL_CAPACITY_INFO &&
            isDisplayLoad && (
              <>
                <div>
                  <Value>
                    {t('p.Editor.Panels.Vehicles.Vehicle.LoadAfterStop')}:
                  </Value>
                </div>
                {Object.entries($loadAfterStop).map(([key, info]) => {
                  return (
                    <div key={key}>
                      <Value $isOverLoad={info > $capacity_info[key]?.capacity}>
                        <span>
                          {isWholeNumber(info) ? info : info.toFixed(3)}
                        </span>
                        {` / ${$capacity_info[key]?.capacity || 0}`}
                      </Value>
                      <Label>{`${key}`}</Label>
                    </div>
                  );
                })}
              </>
            )}
          {!isDeliveryLayout && (
            <Row>
              <NodeType>{t('p.booking.card.labels.scheduled')}</NodeType>
              {scheduledTs}
            </Row>
          )}
          <table>
            <tbody>
              {!!(service_time && service_time >= 60) && (
                <tr>
                  {!isBreak && <td>{t('c.messages.ServiceTime')}:&nbsp;</td>}
                  <ServiceTimeCell>
                    {formatDurationTime(service_time, i18n.language)}
                  </ServiceTimeCell>
                </tr>
              )}
              {!!(slack && slack >= 60) && (
                <tr>
                  <IdleTimeCell>{t('c.messages.IdleTime')}:&nbsp;</IdleTimeCell>
                  <IdleTimeCell>
                    {formatDurationTime(slack, i18n.language)}
                  </IdleTimeCell>
                </tr>
              )}
            </tbody>
          </table>
          {nodeArrivalTime && (
            <ArrivalTime>
              {isRealtime && <span>&#x231a;&nbsp;</span>}
              {nodeArrivalTime}
            </ArrivalTime>
          )}
          {!isBreak && isScheduled && nodeDepartureTime !== nodeArrivalTime && (
            <DepartureTime>
              {isRealtime && <span>&#x231a;&nbsp;</span>}
              {nodeDepartureTime}
            </DepartureTime>
          )}
        </Header>
        {isActive && !isPoint && (
          <Info
            isReadOnly={isReadOnly || isCompleted}
            stop={stop}
            bookings={stop.bookings}
            isHaveEditable={isHaveEditable}
          />
        )}
        {isActive && isPoint && !isBreak && (
          <ButtonContainer>
            {!isReadOnly && (
              <Button
                onClick={onDeleteRoutePoint}
                disabled={isReadOnly || isCompleted}
              >
                {t('p.Editor.Panels.Vehicles.Vehicle.Menu.Delete')}
              </Button>
            )}
          </ButtonContainer>
        )}
      </div>
    </Container>
  );
});

Stop.displayName = 'Stop';

export const DraggableStop = DropTarget(
  'stop',
  {
    hover(props, monitor, component) {
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }
      const dragIndex = monitor.getItem().index;
      const isBreak = monitor.getItem().isBreak;
      const hoverIndex = props.index;
      if (isBreak && hoverIndex === 0) {
        return null;
      }
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return null;
      }

      // Time to return the indexes
      props.moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
      return null;
    },
    drop(props) {
      props.dropCard && props.dropCard(true);
      return;
    },
  },
  (connect) => ({
    connectDropTarget: connect.dropTarget(),
  })
)(
  DragSource(
    'stop',
    {
      beginDrag: (props) => ({
        id: props.id,
        index: props.index,
        isBreak: props.isBreak,
      }),
      endDrag: (props) => {
        props.dropCard && props.dropCard(false);
      },
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  )(Stop)
);

import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  font-size: 16px;
  color: #edece8;
  margin-left: 30px;
`;

export default Container;

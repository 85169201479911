import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 8px;
  left: 116px;
  width: 350px;
  bottom: 58px;
  padding: 14px 15px 20px;
  background-color: #080d14bf;
  background-image: linear-gradient(180deg, #0c0f14 40px, rgba(0, 0, 0, 0) 70px),
    linear-gradient(0deg, #0c0f14 40px, rgba(0, 0, 0, 0) 70px);
  backdrop-filter: blur(8px);
  border-radius: 16px;

  h2 {
    margin: 0;
  }
`;

export default Container;

import 'normalize.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
  }
  
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    border: transparent 1px solid;
    background-color: transparent;
  }

  html {
    overflow: -moz-scrollbars-none;
  }

  body {
    font-family: "Montserrat", Arial, sans-serif;
    color: #c5cede;

    margin: 0;
    height: 100%;
    overflow: hidden;
    background: rgb(54, 61, 74);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ul.error-detail {
    margin:0;
    padding:0;
  }

  .lg-6 {
    width: 50%
  }

  // left align react tooltip text
  .multi-line {
    text-align: left !important;
  }
  [data-baseweb="toaster"] {
    z-index: 22;
  }
`;

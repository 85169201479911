import styled from 'styled-components';

const Rows = styled.div`
  width: 150px;
  text-align: left;
  margin-right: 20px;
  flex-shrink: 0;
`;

export default Rows;

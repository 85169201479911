import { useState, useEffect } from 'react';

import { detect } from 'detect-browser';

const useDetectBrowser = () => {
  const [name, setName] = useState(null);
  const [version, setVersion] = useState(null);
  const [os, setOs] = useState(null);

  useEffect(() => {
    const browser = detect();

    setName(browser?.name);
    setVersion(browser?.version);
    setOs(browser?.os);
  }, [setName, setVersion, setOs]);

  return [name, version, os];
};

export default useDetectBrowser;

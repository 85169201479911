import styled from 'styled-components';

export default styled.div`
  width: 40px;
  padding-right: 10px;

  div {
    padding: 10px 0;
  }
`;

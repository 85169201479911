import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { currentProjectUrlSelector } from 'modules/user/selectors';

import { openPopup, closePopup } from 'modules/ui/actions';

import Window from './Window';

const mapDispatchToProps = {
  openPopup,
  closePopup,
};

const mapStateToProps = createImmutableSelector(
  currentProjectUrlSelector,
  projectUrl => ({
    projectUrl,
  })
);

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Window);

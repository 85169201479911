import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const ClickOutside = (props) => {
  const refContainer = useRef(null);
  const { children, onClickOutside, ...rest } = props;

  const handle = (e) => {
    if (!refContainer?.current?.contains(e.target)) {
      onClickOutside(e);
    }
  };

  useEffect(() => {
    document.addEventListener('touchend', handle, true);
    document.addEventListener('click', handle, true);
    return () => {
      document.removeEventListener('touchend', handle, true);
      document.removeEventListener('click', handle, true);
    };
  }, [refContainer]);

  return (
    <div {...rest} ref={refContainer}>
      {children}
    </div>
  );
};

ClickOutside.propTypes = {
  children: PropTypes.element.isRequired,
  onClickOutside: PropTypes.func.isRequired,
};

export default ClickOutside;

import { put, select } from 'redux-saga/effects';

import Immutable from 'immutable';
import moment from 'moment-timezone';
import debug from 'utils/debug';

import { dumpCommuteOfferChanges } from 'utils/CommuteOffer';

import * as actions from 'modules/commuteOffer/actions';

import {
  commuteOfferRoutesSelector,
  commuteOfferCurrentDataSelector,
  allVehiclesSelector,
} from 'modules/commuteOffer/selectors';
import { routingEngineSelector } from 'modules/ui/selectors';

const D2 = debug('m:CommuteOffer:saga:recalculateVehicleTime');

global.DRIVING_TIMESTAMPS_SN = 0;

// eslint-disable-next-line import/prefer-default-export
export function* recalculateVehicleTimeHandler({ payload }) {
  D2.S.INFO('Handler:Request', payload);

  try {
    const { vehicleIds, mode = 'update' } = payload;
    const vehicleIdSet = new Set(vehicleIds);
    D2.S.INFO('Handler:vehicleIds', {
      vehicleIds,
    });

    const commuteOffer = yield select(commuteOfferCurrentDataSelector);
    D2.S.INFO('Handler:commuteOffer', {
      commuteOffer,
    });

    const routingEngine = yield select(routingEngineSelector);
    if (routingEngine === 'euclidean' || routingEngine === 'spheroid') {
      D2.S.INFO('Handler:Success', {
        reason: `Routing engine: ${routingEngine}`,
        payload,
      });
      return;
    }

    const routes = yield select(commuteOfferRoutesSelector);

    const allVehicles = yield select(allVehiclesSelector);
    D2.S.INFO('Handler:allVehicles', {
      allVehicles,
    });

    const filteredVehicles = allVehicles.filter(vehicle =>
      vehicleIdSet.has(vehicle.agent_id)
    );
    D2.S.INFO('Handler:filteredVehicles', {
      filteredVehicles,
      allVehicles,
    });

    const vehiclesToBeUpdated = filteredVehicles.reduce((memo, vehicle) => {
      const { agent_id } = vehicle;

      const data = routes.get(agent_id);

      if (!data) {
        return memo;
      }

      if (
        !vehicle ||
        vehicle.readonly ||
        vehicle.readOnly ||
        vehicle.isReadOnly ||
        global.GEODISC_ALL_VEHICLES_READONLY
      ) {
        return memo;
      }

      const { route } = vehicle;

      if (!route || !route.length) {
        return memo;
      }

      const legs = data.routes && data.routes.length && data.routes[0].legs;

      if (!legs || legs.length !== route.length - 1) {
        return memo;
      }

      const hasValidSlacks = !!route?.find((waypoint) => {
        return waypoint.bookings?.find(
          booking =>
            typeof booking.node.slack !== 'undefined' &&
            booking.node.slack !== null
        );
      });
      const hasEmptySlacks = !!route?.find((waypoint) => {
        return waypoint.bookings?.find(
          booking =>
            typeof booking.node.slack === 'undefined' ||
            booking.node.slack === null
        );
      });
      D2.S.INFO('Handler:hasEmptySlacks', {
        hasValidSlacks,
        hasEmptySlacks,
        route,
      });

      const routeInfo = {
        hasValidSlacks,
        hasEmptySlacks,
        route: route.map((waypoint, waypointIndex) => {
          const leg = legs[waypointIndex];

          const durations = waypoint.bookings.reduce(
            (durationsMemo, lastBooking) => ({
              values: [
                ...durationsMemo.values,
                {
                  service_time: lastBooking.node.service_time || 0,
                  slack: lastBooking.node.slack || 0,
                },
              ],
            }),
            { values: [] }
          );

          const { service_time = 0, slack = 0 } = durations.values.reduce(
            (waypointInfoMemo, waypointInfo) => ({
              service_time:
                waypointInfoMemo.service_time + waypointInfo.service_time || 0,
              slack: waypointInfoMemo.slack + waypointInfo.slack || 0,
            }),
            { service_time: 0, slack: 0 }
          );

          return {
            ...waypoint,
            $leg: leg,
            $service_time: service_time,
            $slack: slack,
          };
        }),
      };

      return [
        ...memo,
        {
          vehicle: {
            ...vehicle,
            route: routeInfo.route,
            $hasValidSlacks: routeInfo.hasValidSlacks,
            $hasEmptySlacks: routeInfo.hasEmptySlacks,
          },
          legs,
        },
      ];
    }, []);
    D2.S.INFO('Handler:vehiclesToBeUpdated', {
      vehiclesToBeUpdated,
      filteredVehicles,
      allVehicles,
    });

    const vehiclesWithEmptySlacks = vehiclesToBeUpdated.filter(
      vehicleInfo =>
        vehicleInfo.vehicle.$hasEmptySlacks &&
        !vehicleInfo.vehicle.$hasValidSlacks
    );
    const hasEmptySlacks = vehiclesWithEmptySlacks.length > 0;
    D2.S.INFO('Handler:vehiclesWithEmptySlacks', {
      vehiclesWithEmptySlacks,
      vehiclesToBeUpdated,
      filteredVehicles,
      allVehicles,
      hasEmptySlacks,
    });

    const recalculatedVehicles = !hasEmptySlacks
      ? null
      : vehiclesWithEmptySlacks.map((vehicleInfo) => {
          const { vehicle } = vehicleInfo;
          // if (!vehicle.$hasEmptySlacks) {
          //   return vehicleInfo;
          // }
          const routing_engine =
            vehicle.routing_engine || vehicle.routing_engine_settings || {};
          const { time_factor = 1.0 } = routing_engine;

          const newRouteInfo = vehicle.route.reduce(
            (newRouteMemo, currentWaypoint, index) =>
              D2.S.FUNCTION(
                'Handler:vehicle.route.reduce',
                { newRouteMemo, currentWaypoint, index, vehicle },
                () => {
                  const currentScheduledTs = moment(
                    currentWaypoint.scheduled_ts
                  );
                  currentScheduledTs.add(newRouteMemo.shift, 'seconds');

                  const nextWaypoint = vehicle.route[index + 1];
                  if (!nextWaypoint) {
                    return {
                      ...newRouteMemo,
                      route: [
                        ...newRouteMemo.route,
                        {
                          ...currentWaypoint,
                          bookings: currentWaypoint.bookings.map(booking => ({
                            ...booking,
                            node: {
                              ...booking.node,
                              scheduled_ts: currentScheduledTs.format(),
                              slack: 0,
                              // ti: {
                              //   scheduled_ts: currentScheduledTs.format(),
                              // },
                            },
                          })),
                          scheduled_ts: currentScheduledTs.format(),
                          $slack: 0,
                        },
                      ],
                    };
                  }
                  const nextScheduledTs = moment(nextWaypoint.scheduled_ts);
                  nextScheduledTs.add(newRouteMemo.shift, 'seconds');

                  const timeDiff =
                    nextScheduledTs.unix() - currentScheduledTs.unix();

                  const newSlackValue =
                    timeDiff -
                    currentWaypoint.$service_time -
                    currentWaypoint.$leg.duration * time_factor;

                  if (
                    newSlackValue < 0 &&
                    global.GEODISC_ADJUST_SCHEDULED_TS_WHEN_SLACK_IS_NEGATIVE
                  ) {
                    const additionalShift = -newSlackValue;
                    return {
                      ...newRouteMemo,
                      route: [
                        ...newRouteMemo.route,
                        {
                          ...currentWaypoint,
                          bookings: currentWaypoint.bookings.map(booking => ({
                            ...booking,
                            node: {
                              ...booking.node,
                              scheduled_ts: currentScheduledTs.format(),
                              slack: 0,
                            },
                          })),
                          scheduled_ts: currentScheduledTs.format(),
                          $slack: 0,
                        },
                      ],
                      shift: newRouteMemo.shift + additionalShift,
                    };
                  }
                  const newSlack = newSlackValue < 0 ? 0 : newSlackValue;
                  const slackPerNode =
                    newSlack / currentWaypoint.bookings.length;

                  return {
                    ...newRouteMemo,
                    route: [
                      ...newRouteMemo.route,
                      {
                        ...currentWaypoint,
                        bookings: currentWaypoint.bookings.map(booking => ({
                          ...booking,
                          node: {
                            ...booking.node,
                            scheduled_ts: currentScheduledTs.format(),
                            slack: slackPerNode,
                          },
                        })),
                        scheduled_ts: currentScheduledTs.format(),
                        $slack: newSlack,
                      },
                    ],
                  };
                }
              ),
            {
              route: [],
              shift: 0,
            }
          );
          return {
            ...vehicleInfo,
            vehicle: {
              ...vehicleInfo.vehicle,
              route: newRouteInfo.route,
            },
          };
        });
    D2.S.INFO('Handler:recalculatedVehicles', {
      recalculatedVehicles,
      vehiclesToBeUpdated,
      filteredVehicles,
      allVehicles,
      hasEmptySlacks,
    });

    const recalculatedVehiclesMap = !hasEmptySlacks
      ? null
      : recalculatedVehicles.reduce(
          (updatedVehicleMapMemo, vehicleInfo) => ({
            ...updatedVehicleMapMemo,
            [vehicleInfo.vehicle.agent_id]: vehicleInfo.vehicle,
          }),
          {}
        );
    D2.S.INFO('Handler:recalculatedVehicles', {
      recalculatedVehiclesMap,
      recalculatedVehicles,
      vehiclesToBeUpdated,
      hasEmptySlacks,
    });
    if (hasEmptySlacks) {
      const resultOffer = {
        ...commuteOffer,
        $origin: 'RECALCULATE_VEHICLE_TIME/hasEmptySlacks:true',
        result: {
          ...commuteOffer.result,
          vehicles: Object.entries(commuteOffer.result.vehicles).reduce(
            (memo, [agent_id, vehicle]) => {
              const updatedVehicle = recalculatedVehiclesMap[agent_id];
              const resultVehicleNodes = !updatedVehicle
                ? vehicle
                : updatedVehicle.route.reduce((resultVehicleMemo, waypoint) => {
                    const nodes = waypoint.bookings.map(
                      booking => booking.node
                    );
                    return [...resultVehicleMemo, ...nodes];
                  }, []);
              return {
                ...memo,
                [agent_id]: resultVehicleNodes,
              };
            },
            {}
          ),
        },
      };
      D2.S.INFO('Handler:resultOffer', {
        resultOffer,
        commuteOffer,
        hasEmptySlacks,
      });

      if (global.GEODISC_DEBUG_JSONDIFF_ENABLED) {
        dumpCommuteOfferChanges(
          'recalculateVehicleTimeHandler',
          commuteOffer,
          resultOffer
        );
      }

      yield put(actions.setResultOffer(resultOffer, mode));
    } else {
      const { resultOffer, isUpdated } = vehiclesToBeUpdated.reduce(
        (memo, vehicleInfo) => {
          const currentOffer = memo.resultOffer;

          const { vehicle, legs } = vehicleInfo;

          const { route, agent_id } = vehicle;

          D2.S.INFO('Handler:vehicleIds.reduce:route', {
            route,
            vehicle,
          });

          if (!route.length) {
            return memo;
          }

          const { routing_engine = {} } = vehicle;

          const { osrme_timestamp_mode = 'start_time', time_factor = 1.0 } =
            routing_engine;

          const firstPoint = route[0];
          firstPoint.scheduled_ts =
            firstPoint.open_time_ts &&
            firstPoint.open_time_ts !== firstPoint.scheduled_ts
              ? firstPoint.open_time_ts
              : firstPoint.scheduled_ts;

          const firstPointTime =
            firstPoint.open_time_ts || firstPoint.scheduled_ts;

          const startTime = moment(firstPointTime);
          D2.S.INFO('Handler:vehicleIds.reduce:startTime', {
            startTime,
            firstPointTime,
            firstPoint,
          });

          const currTime = startTime;

          const oldNodes = [].concat(
            ...route.map((routePoint) => {
              return routePoint.bookings.map(item => ({ ...item.node }));
            })
          );
          D2.S.INFO('Handler:vehicleIds.reduce:oldNodes', {
            oldNodes,
            vehicle,
          });
          let previousNodeScheduledTS = route[0]?.scheduled_ts;
          let previousPoint = route[0];
          const { calculatedNodes, calculatedNodesInfo } = route.reduce(
            (nodesMemo, routePoint, i) => {
              const { lastPoint } = nodesMemo;
              D2.S.INFO('Handler:route.map:routePoint', {
                lastPoint,
                routePoint,
                i,
                vehicle,
              });
              const durations = lastPoint
                ? lastPoint.bookings.reduce(
                    (durationsMemo, lastBooking) => ({
                      hasUndefinedSlack:
                        durationsMemo.hasUndefinedSlack ||
                        typeof lastBooking.node.slack === 'undefined' ||
                        lastBooking.node.slack === null,
                      values: [
                        ...durationsMemo.values,
                        {
                          service_time: lastBooking.node.service_time || 0,
                          slack: lastBooking.node.slack || 0,
                        },
                      ],
                    }),
                    { hasUndefinedSlack: false, values: [] }
                  )
                : { hasUndefinedSlack: false, values: [] };

              const { service_time = 0, slack = 0 } = durations.values.reduce(
                (bookingsMemo, booking) => ({
                  service_time:
                    bookingsMemo.service_time + booking.service_time || 0,
                  slack: bookingsMemo.slack + booking.slack || 0,
                }),
                { service_time: 0, slack: 0 }
              );
              const leg =
                i > 0 && legs && legs[i - 1] ? legs[i - 1] : { duration: 0 };
              const { duration } = leg;
              const lastTime = currTime.clone();
              currTime.add(duration * time_factor + service_time + slack, 's');
              D2.S.INFO('Handler:route.map:currTime', {
                currTime,
                lastTime,
                duration,
                time_factor,
                durations,
                service_time,
                slack,
                leg,
                lastPoint,
                routePoint,
                i,
                vehicle,
              });
              const currentNodes = routePoint.bookings.map((item) => {
                const isBreak = item?.node?.dynamic_break;

                //Replaces the break node's scheduled_ts and estimated_scheduled_ts with the values of the previous node
                const scheduled_ts = isBreak
                  ? // Break node's scheduled time = previous stop's scheduled time + previous stop's service time
                    moment(previousNodeScheduledTS)
                      .add(previousPoint.service_time, 's')
                      .tz(global.GEODISC_TIMEZONE)
                      .format()
                  : currTime.tz(global.GEODISC_TIMEZONE).format();
                const lat = isBreak ? previousPoint.lat : item.node.lat;
                const lon = isBreak ? previousPoint.lon : item.node.lon;

                previousNodeScheduledTS = scheduled_ts;
                previousPoint = routePoint;
                return {
                  ...item.node,
                  lat,
                  lon,
                  scheduled_ts,
                  slack: item.node.slack ?? 0,
                };
              });

              D2.S.INFO('Handler:route.map:currentNodes', { currentNodes });

              return {
                lastPoint: routePoint,
                calculatedNodes: [
                  ...nodesMemo.calculatedNodes,
                  ...currentNodes,
                ],
                calculatedNodesInfo: [
                  ...nodesMemo.calculatedNodesInfo,
                  {
                    duration,
                    time_factor,
                    service_time,
                    slack,
                    durations,
                    leg,
                  },
                ],
              };
            },
            { lastPoint: null, calculatedNodes: [], calculatedNodesInfo: [] }
          );
          D2.S.INFO('Handler:vehicleIds.reduce:calculatedNodes', {
            calculatedNodes,
            calculatedNodesInfo,
            vehicle,
          });

          const firstOriginalNode = oldNodes[0];
          const firstCalculatedNode = calculatedNodes[0];

          const lastOriginalNode = oldNodes[oldNodes.length - 1];
          const lastCalculatedNode =
            calculatedNodes[calculatedNodes.length - 1];
          const timeDiff =
            osrme_timestamp_mode === 'end_time'
              ? moment(lastCalculatedNode.scheduled_ts).diff(
                  moment(lastOriginalNode.scheduled_ts)
                )
              : moment(firstCalculatedNode.scheduled_ts).diff(
                  moment(firstOriginalNode.scheduled_ts)
                );
          D2.S.INFO('Handler:vehicleIds.reduce:timeDiff', {
            timeDiff,
            osrme_timestamp_mode,
            firstCalculatedNode,
            firstOriginalNode,
            lastCalculatedNode,
            lastOriginalNode,
          });

          let newScheduledTS = calculatedNodes[0]?.scheduled_ts;
          let newEstimatedScheduledTS =
            calculatedNodes[0]?.estimated_scheduled_ts;
          const newNodes =
            timeDiff !== 0
              ? calculatedNodes.map((node) => {
                  const isBreak = node?.dynamic_break;
                  //Replaces the break node's scheduled_ts and estimated_scheduled_ts with the values of the previous node
                  const scheduled_ts = isBreak
                    ? newScheduledTS
                    : moment(node.scheduled_ts)
                        .subtract(timeDiff, 'ms')
                        .tz(global.GEODISC_TIMEZONE)
                        .format();
                  const estimated_scheduled_ts = isBreak
                    ? newEstimatedScheduledTS
                    : node?.estimated_scheduled_ts;

                  newScheduledTS = node?.scheduled_ts;
                  newEstimatedScheduledTS = node?.estimated_scheduled_ts;
                  return {
                    ...node,
                    scheduled_ts,
                    estimated_scheduled_ts,
                  };
                })
              : calculatedNodes;

          // const isPathUpdated = true;
          const imOldNodes = Immutable.fromJS({ nodes: oldNodes });
          const imNewNodes = imOldNodes.merge(
            Immutable.fromJS({ nodes: newNodes })
          );
          const isPathUpdated = !Immutable.is(imOldNodes, imNewNodes);

          D2.S.INFO('Handler:vehicleIds.reduce:newNodes', {
            oldNodes,
            newNodes,
            isPathUpdated,
            vehicle,
          });

          const newOffer = !isPathUpdated
            ? {
                ...currentOffer,
                $origin: 'RECALCULATE_VEHICLE_TIME/isPathUpdated:false',
              }
            : {
                ...currentOffer,
                $origin: 'RECALCULATE_VEHICLE_TIME/isPathUpdated:true',
                result: {
                  ...currentOffer.result,
                  vehicles: {
                    ...currentOffer.result.vehicles,
                    [agent_id]: newNodes,
                  },
                },
              };
          D2.S.INFO('Handler:vehicleIds.reduce:resultOffer', {
            newOffer,
            isPathUpdated,
            oldNodes,
            newNodes,
            vehicle,
          });

          if (!isPathUpdated) {
            if (global.GEODISC_DEBUG_ENABLED) {
              // eslint-disable-next-line no-console
              console.log('=== Nothing is changed', {
                vehicle,
                oldNodes,
                newNodes,
                route,
              });
            }
          }

          return {
            resultOffer: newOffer,
            isUpdated: isPathUpdated ? true : memo.isUpdated,
          };
        },
        { resultOffer: commuteOffer, isUpdated: false }
      );

      D2.S.INFO('Handler:resultOffer', {
        resultOffer,
        commuteOffer,
        isUpdated,
      });
      yield put(actions.setResultOffer(resultOffer, mode));
      D2.S.INFO('Handler:Success', {
        resultOffer,
        isUpdated,
        payload,
      });
    }

    global.DRIVING_TIMESTAMPS_SN += 1;
  } catch (error) {
    D2.S.INFO('Handler:Failure', { error, payload });
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
}

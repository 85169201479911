// @flow

import * as React from 'react';
import { debounce } from 'debounce';

import Form from './Form';
import Input from './Input';

const Filter = (props) => {
  const { t, changeBookingsFilter } = props;

  const delayedChangeFilterCriteria = debounce(changeBookingsFilter, 300);

  const onChange = (event) => {
    event.persist();
    const filter = event.target.value.toLowerCase();
    delayedChangeFilterCriteria(filter);
  };

  return (
    <Form>
      <Input
        type='text'
        onChange={onChange}
        defaultValue={window.GEODISC_DEBUG_DEFAULT_BOOKINGS_PANEL_FILTER || ''}
        placeholder={t('p.Editor.Panels.Bookings.Header.Filter')}
      />
    </Form>
  );
};

export default React.memo(Filter);

import React from 'utils/react';
import Button from 'components/Button';
import ButtonsWrap from './ButtonsWrap';

import Container from './Container';
import Search from './Search';
import Title from './Title';
import Selected from './Selected';

const Panel = (props) => {
  const {
    t,
    count,
    selectedIds,
    layoutName,
    toggleCompareMod,
    clearSelectedSimulations,
  } = props;

  const isCompareEnabled = layoutName !== 'delivery';

  return (
    <Container>
      <Title>
        {t(`p.Simulations.Panel.Title.${layoutName}`, { count: count || 0 })}
      </Title>
      <Selected
        count={selectedIds.size}
        clearSelectedSimulations={clearSelectedSimulations}
      />
      {isCompareEnabled && (
        <ButtonsWrap>
          <Button
            isDisable={selectedIds.size < 2 || selectedIds.size > 5}
            onClick={toggleCompareMod}
          >
            Compare
          </Button>
        </ButtonsWrap>
      )}
      {isCompareEnabled && (
        <Search placeholder={t(`p.Simulations.Panel.Search.${layoutName}`)} />
      )}
    </Container>
  );
};

export default Panel;

import moment from 'moment-timezone';

import { getRouteCoordinates } from 'utils/CommuteOffer';

import debug from 'utils/debug';

const D2 = debug('config:routing');

window.test1 = a => moment(a);

const routing = {
  euclidean: {
    name: 'Euclidean',
    url: null,
  },
  spheroid: {
    name: 'Spheroid',
    url: null,
  },
  asteria: {
    name: 'Asteria',
    url: null,
  },
  mapbox: {
    name: 'Mapbox',
    url: (route, params, opts = {}) => {
      // const mapboxUrl = opts.url || window.GEODISC_MAPBOX_URL;
      // const mapboxKey = opts.key || window.GEODISC_MAPBOX_TOKEN;
      const mapboxUrl = window.GEODISC_MAPBOX_URL;
      const mapboxKey = window.GEODISC_MAPBOX_TOKEN;
      const mapboxRoutingProfile =
        opts.routing_engine?.road_network ||
        opts.road_network ||
        window.GEODISC_MAPBOX_ROUTING_PROFILE;

      const { approaches, curb } = params;
      const coordinates = getRouteCoordinates(route, approaches, curb);

      // eslint-disable-next-line
      return `${mapboxUrl}/directions/v5/mapbox/${mapboxRoutingProfile}/${coordinates}access_token=${mapboxKey}&overview=full&geometries=geojson`;
    },
  },
  osrme: {
    name: 'OSRME',
    url: (route, params, opts = {}) =>
      D2.S.FUNCTION('osrme:url', { route, params, opts }, () => {
        // const osrmUrl = opts.url || window.GEODISC_OSRM_URL;
        // const osrmKey = opts.key || window.GEODISC_OSRM_TOKEN;
        const osrmUrl = window.GEODISC_OSRM_URL;
        const osrmKey = window.GEODISC_OSRM_TOKEN;
        const osrmRoutingProfile =
          opts.routing_engine?.road_network ||
          opts.road_network ||
          window.GEODISC_MAPBOX_ROUTING_PROFILE;

        const { approaches, curb, continue_straight, start_time, end_time } =
          params;
        const coordinates = getRouteCoordinates(route, approaches, curb);

        const continueStraightArgs = [`continue_straight=${continue_straight}`];

        const startTime = start_time
          ? encodeURIComponent(
              moment(start_time).utc().format('YYYY-MM-DDTHH:mm:ssZ')
            )
          : undefined;

        const startTimeArgs = startTime ? [`start_time=${startTime}`] : [];

        const endTime = end_time
          ? encodeURIComponent(
              moment(end_time).utc().format('YYYY-MM-DDTHH:mm:ssZ')
            )
          : undefined;

        const endTimeArgs = endTime ? [`end_time=${endTime}`] : [];

        const args = [
          ...[
            `${osrmUrl}/tdroute/v1/${osrmRoutingProfile}/${coordinates}`,
            `access_token=${osrmKey}`,
            'steps=true&geometries=geojson&overview=full',
          ],
          ...continueStraightArgs,
          ...startTimeArgs,
          ...endTimeArgs,
        ];

        return args.join('&');
      }),
  },
  osrm: {
    name: 'OSRM',
    url: (route, params, opts = {}) => {
      // const osrmUrl = opts.url || window.GEODISC_OSRM_URL;
      // const osrmKey = opts.key || window.GEODISC_OSRM_TOKEN;
      const osrmUrl = window.GEODISC_OSRM_URL;
      const osrmKey = window.GEODISC_OSRM_TOKEN;
      const osrmRoutingProfile =
        opts.routing_engine?.road_network ||
        opts.road_network ||
        window.GEODISC_MAPBOX_ROUTING_PROFILE;

      const { approaches, curb, continue_straight } = params;
      const coordinates = getRouteCoordinates(route, approaches, curb);

      const continueStraightArgs = [`continue_straight=${continue_straight}`];

      return [
        ...[
          `${osrmUrl}/directions/v5/mapbox/${osrmRoutingProfile}/${coordinates}`,
          `access_token=${osrmKey}`,
          'steps=true&geometries=geojson&overview=full',
        ],
        ...continueStraightArgs,
      ].join('&');
    },
  },
  here: {
    name: 'here.com',
    url: null,
  },
  defined_by_vehicle_settings: {
    name: 'Defined by Vehicle Settings',
    url: null,
  },
};

if (global.GEODISC_HERE_DISABLE) {
  delete routing.here;
}

export default routing;

import styled from 'styled-components';
import share from 'assets/share.svg';

export default styled.div`
  position: absolute;
  top: 13px;
  right: 3px;
  width: 10px;
  height: 10px;
  background: url(${share}) no-repeat;
  background-size: contain;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: ${({ disabled = false }) => (disabled ? 0.0 : 0.5)};
  }
`;

import styled from 'styled-components';

export default styled.div`
  z-index: 1;
  position: fixed;
  bottom: 155px;
  right: 10px;
  background-color: rgb(54, 61, 74);
  border-radius: 9999px;
  overflow: hidden;
`;

import { currentProjectSelector } from 'modules/user/selectors';
import OrderDetails from './OrderDetails';
import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

const mapStateToProps = createImmutableSelector(
  currentProjectSelector,
  currentProject => ({
    currentProject,
  })
);

const connectOrdersPanel = connect(mapStateToProps);

export default connectOrdersPanel(OrderDetails);

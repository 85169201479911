import styled from 'styled-components';

const Container = styled.div`
  border-radius: 50px;
  border: solid 1px #c5cede;
  padding: 2px 12px;
  margin-right: 10px;
  cursor: pointer;
`;

export default Container;

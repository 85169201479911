import styled from 'styled-components';

const StatusCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${({ color }) => color || 'transparent'};
  border: 1px solid ${({ color }) => (color ? 'transparent' : 'gray')};
  flex-shrink: 0;
`;

export default StatusCircle;

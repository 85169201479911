import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { simulationSelector } from 'modules/simulations/selectors';
import { updateSimulation } from 'modules/simulations/actions';
import { commuteOffersIsTemplateDataSourceSelector } from 'modules/ui/selectors';

import Info from './Info';

const mapStateToProps = createImmutableSelector(
  simulationSelector,
  commuteOffersIsTemplateDataSourceSelector,
  (simulation, isTemplate) => ({ simulation, isTemplate })
);

const mapDispatchToProps = { updateSimulation };

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Info);

import styled from 'styled-components';

const Text = styled.div`
  font-size: 17px;
  letter-spacing: 0;
  color: #edece8;
  margin-left: 5px;
`;

export default Text;

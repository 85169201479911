import styled from 'styled-components';

const Column = styled.div`
  display: flex;
  position: sticky;
  right: 0;

  align-items: center;
  min-height: 75px;
  padding-right: 20px;
  flex-shrink: 0;
  z-index: 1;
`;

export default Column;

import styled from 'styled-components';

export default styled.div`
  display: flex;
  position: fixed;
  bottom: 25px;
  right: 10px;

  height: 36px;
  border-radius: 5px;
  background-color: rgb(54, 61, 74);
`;

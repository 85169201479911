import styled from 'styled-components';

export default styled.div`
  position: relative;
  user-select: none;
  padding: 16px;
  margin: 0 0 12px 0;
  border-radius: 4px;
  font-size: 14px;
  background: #1c232d;
  cursor: ${({ isReadOnly }) => (isReadOnly ? 'auto' : 'move')};
  z-index: ${({ isActive }) => (isActive ? 1 : 'auto')};

  &:hover {
    background: #23282e;
  }
`;

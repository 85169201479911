import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 20px 20px 10px;
`;

export default Container;

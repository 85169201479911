const makeCRCTable = () => {
  let c;
  const crcTable = [];
  for (let n = 0; n < 256; n++) {
    c = n;
    for (let k = 0; k < 8; k++) {
      c = c & 1 ? 0xedb88320 ^ (c >>> 1) : c >>> 1; // eslint-disable-line
    }
    crcTable[n] = c;
  }
  return crcTable;
};

const crcTable = makeCRCTable();

export const crc32 = (str) => {
  let crc = 0 ^ -1; // eslint-disable-line

  for (let i = 0; i < str.length; i++) {
    crc = (crc >>> 8) ^ crcTable[(crc ^ str.charCodeAt(i)) & 0xff]; // eslint-disable-line
  }

  return (crc ^ -1) >>> 0; // eslint-disable-line
};

export default crc32;

import React from 'utils/react';

import debug from 'utils/debug';
import Container from './Container';
import ButtonPlus from '../ButtonPlus';
import ModalWindow from '../ModalWindow';

const D2 = debug('c:Layout');

const Layout = (props) => {
  D2.S.INFO('Layout', props);
  const { hasBackground, children, currentUserAllowChange } = props;

  return (
    <Container hasBackground={hasBackground}>
      {children}
      <ModalWindow />
      {currentUserAllowChange && <ButtonPlus />}
    </Container>
  );
};

export default React.memo(Layout);

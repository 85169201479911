import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  margin: 10px 0;
  padding-bottom: 20px;
  border-bottom: solid 2px rgba(197, 206, 222, 0.2);
  flex-shrink: 0;
`;

export default Container;

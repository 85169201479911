import Immutable from 'immutable';

import Object$fromEntries from 'fromentries';

import debug from 'utils/debug';

const D2 = debug('u:object');

const doRemoveInternalFieldsFromObject = (src) => {
  if (Array.isArray(src)) {
    return src.map(x => (x ? doRemoveInternalFieldsFromObject(x) : x));
  }

  if (typeof src === 'object') {
    return Object.keys(src).reduce((memo, key) => {
      if (typeof key === 'string' && key.indexOf('$') === 0) {
        return memo;
      }
      const item = src[key];
      return {
        ...memo,
        [key]: item ? doRemoveInternalFieldsFromObject(item) : item,
      };
    }, {});
  }

  return src;
};

export const removeInternalFieldsFromObject = src =>
  D2.S.FUNCTION('removeInternalFieldsFromObject', { src }, () =>
    src ? doRemoveInternalFieldsFromObject(src) : src
  );

export const removeUndefinedProperties = src =>
  D2.S.FUNCTION('removeUndefinedProperties', { src }, () =>
    Object$fromEntries(
      Object.entries(src).filter(([, v]) => typeof v !== 'undefined')
    )
  );

export const makeLinearFromStructured = (src, prefix = []) => {
  if (typeof src !== 'object') {
    return { [prefix.join('.')]: src };
  }

  return Object.entries(src).reduce(
    (memo, [k, v]) => ({
      ...memo,
      ...makeLinearFromStructured(v, [...prefix, k]),
    }),
    {}
  );
};

export const makeStructuredFromLinear = src =>
  Object.entries(src)
    .reduce((memo, [k, v]) => memo.setIn(k.split('.'), v), new Immutable.Map())
    .toJS();

export const isSame = (oa, ob) => {
  const ia = Immutable.fromJS(oa);
  const ib = Immutable.fromJS(ob);
  const ic = ia.merge(ib);
  return Immutable.is(ic, ia);
};

import styled from 'styled-components';

export default styled.div`
  width: 100%;
  height: calc(
    100% -
      ${({ isFilteringEnabled }) => (isFilteringEnabled ? '110px' : '55px')}
  );
  overflow: scroll;
  margin-top: 5px;

  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'auto')};
  user-select: ${({ isHidden }) => (isHidden ? 'none' : 'auto')};
  ${({ isHidden }) => isHidden && 'cursor: pointer'};

  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    border: transparent 1px solid;
    background-color: transparent;
  }

  overflow: -moz-scrollbars-none;
`;

import React from 'utils/react';
import DropZone from 'components/DropZone';
import Form from './Form';

class ChooseFile extends React.PureComponent {
  state = {
    file: null,
    geojson: null,
  };

  readFile = (file) => {
    const reader = new FileReader();

    reader.onloadend = (e) => {
      if (e.target.readyState === FileReader.DONE) {
        // DONE == 2
        const data = JSON.parse(e.target.result);
        this.setState({ geojson: data });
      }
    };

    reader.readAsText(file, 'UTF-8');
  };

  handleFileSelect = (e) => {
    const file = e.target.files[0]; // FileList object

    this.setState({ file });
    this.readFile(file);
  };

  handleDropFileSelect = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    this.readFile(file);

    this.setState({ file });
  };

  handleDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
  };

  onSubmit = (values) => {
    const { geojson } = this.state;
    const { geometry } =
      geojson &&
      geojson.features &&
      geojson.features.length &&
      geojson.features[0];

    if (geometry) {
      // no geometry
      const { tags, ...otherValues } = values;

      const body = JSON.stringify({
        ...otherValues,
        geometry,
        creation_type: 'F',
        project: this.props.projectUrl,
      });

      this.props.addGeofence(body);
    }
  };

  onReset = () => {
    this.setState({ file: null });
  };

  render() {
    const { file } = this.state;
    return (
      <React.Fragment>
        {file ? (
          <Form file={file} onSubmit={this.onSubmit} onReset={this.onReset} />
        ) : (
          <DropZone
            onDragOver={this.handleDragOver}
            onDrop={this.handleDropFileSelect}
            onChange={this.handleFileSelect}
          />
        )}
      </React.Fragment>
    );
  }
}

export default ChooseFile;

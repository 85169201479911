const defaultConfig = {};

const deliveryConfig = {
  layout_name: 'delivery',
};

const commonLogisticsProjects = {
  the_meat_club: deliveryConfig,
  'The Meat Club': deliveryConfig,
  'Logistics test': deliveryConfig,
  'Sheng Siong': deliveryConfig,
  'Whole Kitchen': deliveryConfig,
  Uglyfood: deliveryConfig,
  Foodgears: deliveryConfig,
};

const configs = {
  'https://sgerp.d.gcprod.swatrider.com': {
    ...commonLogisticsProjects,
  },
  'https://sgerp-stage.d.gcdev.swatrider.com': {
    ...commonLogisticsProjects,
  },
};

const config = configs[global.GEODISC_API_URL] || defaultConfig;

export default config;

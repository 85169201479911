/* eslint camelcase: 0 */
import React from 'utils/react';
import Star from 'components/Star';
import { simulations as simulationsConfig } from 'config';

import Group from './Group';
import Title from './Title';
import Label from './Label';
import Value from './Value';
import Row from './Row';
import Error from 'components/Error';
import Loading from 'components/Loading';

const { metrics } = simulationsConfig;

const Info = React.memo(({ simulation, updateSimulation, error }) => {
  const bookmarked = simulation?.get('bookmarked');
  const onClickStar = () => {
    const body = JSON.stringify({ bookmarked: !bookmarked });
    updateSimulation(simulation.get('id'), body);
  };
  if (error) {
    return <Error />;
  }
  if (!simulation) {
    return <Loading />;
  }
  return (
    <React.Fragment>
      <Group name='Simulation'>
        <Title>
          {simulation.get('name')}
          <Star isActive={bookmarked} onClick={onClickStar} />
        </Title>
      </Group>
      <Group name='Source'>
        <Row>
          <Label>Dataset</Label>
          <Value>{simulation.get('dataset_name')}</Value>
        </Row>
        <Row>
          <Label>Geofence</Label>
          <Value>{simulation.get('geofence_name')}</Value>
        </Row>
      </Group>
      <Group name='Metrics' flexShrink={1}>
        {metrics.map((metric) => {
          const { label, getValue } = metric;
          const key = metric.id;
          const value = getValue(simulation.get(key));

          return (
            <Row key={key}>
              <Label>{label}</Label>
              <Value>{value}</Value>
            </Row>
          );
        })}
      </Group>
    </React.Fragment>
  );
});

export default Info;

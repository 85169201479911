import styled from 'styled-components';

const Container = styled.input`
  display: block;
  position: relative;
  height: 33px;
  width: 70%;
  outline: none;
  border: none;
  font-size: 12px;
  color: #fffdf6;
  padding: 5px;
  padding-left: 10px;

  border-radius: 5px;
  background-color: #3b4251;

  &::placeholder {
    letter-spacing: 0px;
    color: #7a818f;
  }
`;

export default Container;

import withTranslation from 'utils/react/withTranslation';

import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import {
  currentUserConfigSelector,
  currentProjectConfigSelector,
  currentProjectLayoutNameSelector,
} from 'modules/user/selectors';

import { routePageAddressSelector } from 'modules/router/selectors';

import Navigation from './Navigation';

const mapStateToProps = createSelector(
  currentUserConfigSelector,
  currentProjectConfigSelector,
  currentProjectLayoutNameSelector,
  routePageAddressSelector,
  (currentUserConfig, currentProjectConfig, layoutName, pageAddress) => ({
    currentUserConfig,
    currentProjectConfig,
    layoutName,
    pageAddress,
  })
);

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation()(withConnect(Navigation));

import { csvToMap } from 'utils/CommuteOffer/silveray';

import debug from 'utils/debug';
import columns from './columns';

const D2 = debug('u:Logistics:OrdersTable');

const logisticsCsvToJson = ({ data, importColumnMap }) =>
  D2.S.FUNCTION('logisticsCsvToJson', { data, columns }, () => {
    const importColumnMapJs = Object.entries(
      importColumnMap?.toJS() ?? {}
    ).reduce(
      (memo, [k, v]) => ({
        ...memo,
        [k.toLowerCase()]: v.toLowerCase(),
      }),
      {}
    );

    return csvToMap(data, {
      columns: new Map(Object.entries({ ...columns, ...importColumnMapJs })),
    });
  });

export default logisticsCsvToJson;

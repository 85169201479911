import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';
import { saveSimulationSettings } from 'modules/simulations/actions';
import {
  bookingReadySelector,
  commuteOfferCurrentDataSelector,
} from 'modules/commuteOffer/selectors';
import MasterSettings from './MasterSettings';
import { isSuperuserSelector } from 'modules/user/selectors';
import { commuteOfferRequestUpdate } from 'modules/commuteOffer/actions';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { routePageAddressSelector } from '../../modules/router/selectors';

const mapStateToProps = createImmutableSelector(
  bookingReadySelector,
  commuteOfferCurrentDataSelector,
  isSuperuserSelector,
  routePageAddressSelector,
  (finishOfferLoading, commuteOfferData, isSuperUser, pageAddress) => ({
    finishOfferLoading,
    commuteOfferData,
    isSuperUser,
    pageAddress,
  })
);

const mapDispatchToProps = {
  saveSimulationSettings,
  commuteOfferRequestUpdate,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const enhance = compose(withConnect, withRouter);

export default enhance(MasterSettings);

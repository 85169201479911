import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerWrapper } from 'components/AdvancedSettings/styles';
import DataTable from 'components/DataTable';
import ModalHeader from 'components/ModalHeader';
import { styled, useStyletron } from 'baseui';
import { getAddressList } from './utils';
import debug from 'utils/debug';
import compare from 'just-compare';
import { useMemo } from 'react';
import Edit from '../Edit';
import { useHistory } from 'react-router-dom';
import { SelectedAddressContext } from 'pages/Logistics/OrderSelectedDataContextProvider';

const Container = styled('div', ({ $theme }) => {
  return {
    margin: '0 auto',
    padding: '40px 15px',
    background: $theme.colors.containerBackground,
    height: '100%',
  };
});

const Verification = (props) => {
  const [addressesData, setAddressesData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const {
    orders,
    confirmMatchedAddress,
    currentOffer,
    updateCommuteOffer,
    originalData,
    history,
    selectedRow,
    numberOfRows,
  } = props;
  const { t } = useTranslation();
  const [css, theme] = useStyletron();
  const pageSize = 50;
  const statusLabels = {
    check: t('c.Geocoding.content.addressType.check'),
    matched: t('c.Geocoding.content.addressType.matched'),
    manually: t('c.Geocoding.content.addressType.manually'),
    enter: t('c.Geocoding.content.addressType.enter'),
  };

  const D2 = debug('p:Logistics:addressVerification');
  useEffect(() => {
    const addressesList = getAddressList({
      orders,
      statusLabels: statusLabels,
    });
    setAddressesData(addressesList);
  }, [orders]);

  const browserhistory = useHistory();

  const { selectedData } = useContext(SelectedAddressContext);

  const addressDataRef = useRef();

  useEffect(() => {
    addressDataRef.current = addressesData;
  }, [addressesData]);

  useEffect(() => {
    // const params = new URLSearchParams(window.location.search);
    // params.set('page', 'verification');
    // window.history.replaceState({}, null, `${window.location.pathname}?${params}`);

    const backListener = browserhistory.listen((location, action) => {
      if (action === 'POP') {
        const params = new URLSearchParams(window.location.search);
        if (params.get('page') === null) {
          // when user click browser back
          handleCloseWithForward();
        } else if (params.get('page') === 'edit') {
          // when user click browser back
          handleClose(
            () => {
              goForwardBrowserHistory();
              global.closeWarningMessage();
            },
            () => {
              confirmMatchedAddress({
                commuteOffer: {
                  ...originalData,
                },
              });
              openEditComponent(
                selectedData.selectedRow,
                selectedData.numberOfRows
              );
              global.closeWarningMessage();
            },
            () => {
              openEditComponent(
                selectedData.selectedRow,
                selectedData.numberOfRows
              );
            }
          );
        } else if (params.get('page') === 'edit1') {
          // when user click browser forward
          handleCloseEditWithForward();
        }
      }
    });
    return () => {
      backListener();
    };
  }, []);

  const originalAddressesData = useMemo(() => {
    return (
      getAddressList({
        orders,
        statusLabels: statusLabels,
      }) || []
    );
  }, []);

  const columnName = [
    {
      name: t('c.Geocoding.content.table.address'),
      key: 'enteredAddress',
      width: '40%',
    },
    {
      name: t('c.Geocoding.content.table.match_address'),
      key: 'matchedAddress',
      width: '40%',
    },
    {
      name: t('c.Geocoding.content.table.status'),
      key: 'status',
      width: '20%',
    },
  ];

  const cellColors = {
    status: {
      [statusLabels.enter]: theme.colors.negative,
      [statusLabels.check]: theme.colors.warning,
      [statusLabels.manually]: theme.colors.positive,
    },
  };

  const getMenuItems = ({ selectedRow }) => {
    const items = [{ label: t('c.Geocoding.content.address.option.edit') }];

    if (selectedRow?.status === t('c.Geocoding.content.addressType.check')) {
      items.push({ label: t('c.Geocoding.content.address.option.confirm') });
    }
    return items;
  };

  const getContactsOfAddress = (order, address) => {
    return order
      .reduce((memo, item) => {
        return [
          ...memo,
          {
            orderID: item?.data?.external_id,
            name: item?.data?.customer_name,
            number: item?.data?.pickup_customer_phone,
            address: [
              item?.data?.raw_order_record?.pickup_address,
              item?.data?.raw_order_record?.pickup_address2,
              item?.data?.raw_order_record?.pickup_zip_code,
            ]
              .filter(item => item)
              .join(' '),
          },
          {
            orderID: item?.data?.external_id,
            name: item?.data?.dropoff_customer_name,
            number: item?.data?.dropoff_customer_phone,
            address: [
              item?.data?.raw_order_record?.dropoff_address,
              item?.data?.raw_order_record?.dropoff_address2,
              item?.data?.raw_order_record?.dropoff_zip_code,
            ]
              .filter(item => item)
              .join(' '),
          },
        ];
      }, [])
      .filter(item => item?.address === address);
  };

  const openEditComponent = (selectedRow, numberOfRows) => {
    global.closeFullScreen();
    global.openFullScreen({
      modalContent: (
        <Edit
          selectedRow={selectedRow}
          number={numberOfRows}
          contactsOfAddress={getContactsOfAddress(
            orders,
            selectedRow?.enteredAddress
          )}
        />
      ),
    });
  };

  const changeEditBrowserHistory = () => {
    const params = new URLSearchParams(window.location.search);
    params.set('page', 'edit');
    window.history.replaceState(
      {},
      null,
      `${window.location.pathname}?${params}`
    );
  };

  const changeVerificationBrowserHistory = () => {
    const params = new URLSearchParams(window.location.search);
    params.set('page', 'verification');
    window.history.replaceState(
      {},
      null,
      `${window.location.pathname}?${params}`
    );
  };

  const addVerificationBrowserHistory = () => {
    changeVerificationBrowserHistory();
    const params = new URLSearchParams(window.location.search);
    params.delete('page');
    browserhistory.push(`${window.location.pathname}?${params}&page=edit`);
  };

  const goBackBrowserHistory = () => {
    browserhistory.goBack();
  };

  const goForwardBrowserHistory = () => {
    window.history.forward();
  };

  const replaceBrowserHistoryToOrderPage = () => {
    const params = new URLSearchParams(window.location.search);
    params.delete('page');
    browserhistory.push(`${window.location.pathname}?${params}`);
  };

  const menuActions = ({ item, selectedRow }) => {
    switch (item?.label) {
      case t('c.Geocoding.content.address.option.edit'): {
        // action upon pressing Edit matched address
        // const numberOfRows = addressesData?.filter(
        //   data => data?.enteredAddress === selectedRow?.enteredAddress
        // ).length;
        // SP-4791: Not sure what is addressesData meant for but above code will always return 1, the code below will look at the address types list as it store information of each order so it might reflect number of order more accurately.
        const numberOfRows = Math.max(
          addressesData
            ?.filter(
              data => data?.enteredAddress === selectedRow?.enteredAddress
            )
            .map(data => data.AddressTypesList.length)
        );
        addVerificationBrowserHistory();
        openEditComponent(selectedRow, numberOfRows);
        break;
      }
      case t('c.Geocoding.content.address.option.confirm'):
        const newCommuteOffer = { ...currentOffer };

        // just in case if some of record with same address got lat long. because record with lat long will be have dropoff_location_variants or pickup_location_variants as no need to geocode.
        let pickup_location_variants = [];
        let dropoff_location_variants = [];

        for (let i = 0; i < selectedRow?.AddressTypesList?.length; i++) {
          const data = selectedRow?.AddressTypesList[i];

          const bookings = {
            ...newCommuteOffer.stateless_api_request_data.bookings,
          };
          const bookingData = bookings?.[data?.uid]?.data;

          if (
            bookingData?.pickup_location_variants !== undefined &&
            bookingData?.pickup_location_variants !== null &&
            bookingData?.pickup_location_variants.length > 0
          ) {
            pickup_location_variants = bookingData?.pickup_location_variants;
          }

          if (
            bookingData?.dropoff_location_variants !== undefined &&
            bookingData?.dropoff_location_variants !== null &&
            bookingData?.dropoff_location_variants.length > 0
          ) {
            dropoff_location_variants = bookingData?.dropoff_location_variants;
          }

          if (
            pickup_location_variants.length > 0 &&
            dropoff_location_variants.length > 0
          ) {
            break;
          }
        }

        selectedRow?.AddressTypesList?.map((data) => {
          const bookings = {
            ...newCommuteOffer.stateless_api_request_data.bookings,
          };
          const isPickup = data?.type === 'pickup';
          const verifiedAddressTypeKey = isPickup
            ? 'confirm_pickup_address'
            : 'confirm_dropoff_address';
          const bookingData = bookings?.[data?.uid]?.data;
          const confirmPickupLocation =
            bookingData?.pickup_location_variants !== undefined
              ? {
                  pickup_location_lat:
                    bookingData?.pickup_location_variants?.[0]?.lat,
                  pickup_location_lon:
                    bookingData?.pickup_location_variants?.[0]?.lon,
                  pickup_location_name:
                    bookingData?.pickup_location_variants?.[0]?.address,
                }
              : {
                  pickup_location_lat: pickup_location_variants?.[0]?.lat,
                  pickup_location_lon: pickup_location_variants?.[0]?.lon,
                  pickup_location_name: pickup_location_variants?.[0]?.address,
                };
          const confirmDropOffLocation =
            bookingData?.dropoff_location_variants !== undefined
              ? {
                  dropoff_location_lat:
                    bookingData?.dropoff_location_variants?.[0]?.lat,
                  dropoff_location_lon:
                    bookingData?.dropoff_location_variants?.[0]?.lon,
                  dropoff_location_name:
                    bookingData?.dropoff_location_variants?.[0]?.address,
                }
              : {
                  dropoff_location_lat: dropoff_location_variants?.[0]?.lat,
                  dropoff_location_lon: dropoff_location_variants?.[0]?.lon,
                  dropoff_location_name:
                    dropoff_location_variants?.[0]?.address,
                };
          const confirmedBooking = {
            ...bookings?.[data?.uid],
            data: {
              ...bookingData,
              [verifiedAddressTypeKey]: true,
            },
            ...(isPickup ? confirmPickupLocation : confirmDropOffLocation),
            state: 'prepared',
          };

          newCommuteOffer.stateless_api_request_data.bookings = {
            ...newCommuteOffer.stateless_api_request_data.bookings,
            [data?.uid]: confirmedBooking,
          };
        });

        confirmMatchedAddress({
          commuteOffer: {
            ...newCommuteOffer,
          },
        });
        // action upon pressing Confirm matched address
        break;
      default:
        break;
    }
  };

  const handleClose = (noAction, yesAction, noChangeAction) => {
    if (!compare(originalAddressesData, addressDataRef.current)) {
      global.openWarningMessage({
        title: t('c.Geocoding.warning.close.title'),
        message: t('c.Geocoding.warning.close.message'),
        buttons: [
          {
            text: t('c.Geocoding.warning.close.btn.no'),
            action: noAction,
            fill: false,
          },
          {
            text: t('c.Geocoding.warning.close.btn.yes'),
            action: yesAction,
            fill: true,
          },
        ],
      });
    } else {
      noChangeAction();
    }
  };

  const handleCloseWithForward = () => {
    handleClose(
      () => {
        goForwardBrowserHistory();
        global.closeWarningMessage();
      },
      () => {
        confirmMatchedAddress({
          commuteOffer: {
            ...originalData,
          },
        });
        global.closeFullScreen();
        global.closeWarningMessage();
      },
      () => {
        global.closeFullScreen();
      }
    );
  };

  const handleCloseEditWithForward = () => {
    handleClose(
      () => {
        goBackBrowserHistory();
        global.closeWarningMessage();
      },
      () => {
        confirmMatchedAddress({
          commuteOffer: {
            ...originalData,
          },
        });
        global.closeFullScreen();
        changeEditBrowserHistory();
        openEditComponent(selectedData.selectedRow, selectedData.numberOfRows);

        global.closeWarningMessage();
      },
      () => {
        global.closeFullScreen();
        changeEditBrowserHistory();
        openEditComponent(selectedData.selectedRow, selectedData.numberOfRows);
      }
    );
  };

  const handleCloseWithBack = () => {
    // when user click cross
    handleClose(
      undefined,
      () => {
        confirmMatchedAddress({
          commuteOffer: {
            ...originalData,
          },
        });
        // goBackBrowserHistory();
        replaceBrowserHistoryToOrderPage();
        global.closeWarningMessage();
        global.closeFullScreen();
      },
      () => {
        replaceBrowserHistoryToOrderPage();
        global.closeFullScreen();
      }
    );
  };

  const saveAddresses = () => {
    const data = currentOffer;
    const isDataValid = !!data;

    return D2.S.FUNCTION('onSave', { data, id: data.id, isDataValid }, () => {
      if ((isDataValid && data.id) || data.$source?.simulation) {
        setTimeout(() => {
          updateCommuteOffer(data.id, data, originalData, false, t);
        }, 0);
        replaceBrowserHistoryToOrderPage();
        global.closeFullScreen();
      }
    });
  };
  const successMessage = () => {};
  const isReadOnly = false;

  return (
    <React.Fragment>
      <ModalHeader
        handleClose={handleCloseWithBack}
        pageTitle={t('c.Geocoding.content.title')}
        btnTwoAction={saveAddresses}
        btnTwoText={t('c.messages.Save')}
        disableSaveBtn={!history.past.size}
        // backBtn={!!title}
      />
      <ContainerWrapper>
        <Container>
          <DataTable
            columnName={columnName}
            menuItems={getMenuItems}
            menuActions={menuActions}
            data={addressesData}
            setData={setAddressesData}
            // bulkRemove={false}
            checkbox={false}
            successMessage={successMessage()}
            isReadOnly={isReadOnly}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            totalRecords={addressesData?.length}
            cellColors={cellColors}
            isFixedHeader={true}
          />
        </Container>
      </ContainerWrapper>
    </React.Fragment>
  );
};

export default Verification;

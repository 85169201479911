import styled from 'styled-components';

export default styled.div`
  position: relative;
  padding: 15px;
  margin-bottom: 5px;
  font-weight: 600;

  ${({ isHidden, hiddenWritingMode, hiddenTransform, hiddenTextAlign }) =>
    isHidden &&
    `
    padding: 10px 6px;

    writing-mode: ${hiddenWritingMode};
    -webkit-writing-mode: ${hiddenWritingMode};
    -ms-writing-mode: ${hiddenWritingMode};
  
    width: 32px;
    height: 200px;
    text-align: ${hiddenTextAlign};
    transform: ${hiddenTransform};
  `}
`;

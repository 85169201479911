import md5 from 'blueimp-md5';

import debug from 'utils/debug';

const D2 = debug('u:memoize');

global.GEODISC_CACHE = {};

export const clearMemoCache = () => {
  global.GEODISC_CACHE = {};
};

const calculateKey = (...options) =>
  global.GEODISC_CACHE_USES_MD5
    ? md5(JSON.stringify(options))
    : JSON.stringify(options);

export const memoize = (fn) => {
  return (...options) =>
    D2.S.FUNCTION('fn', { options }, ({ $D2 }) => {
      const key = calculateKey(...options);
      if (global.GEODISC_CACHE[key]) {
        $D2.S.INFO('CACHE_HIT', { options });
        return global.GEODISC_CACHE[key];
      }
      $D2.S.INFO('CACHE_MISS', { options });
      const val = fn(...options);
      global.GEODISC_CACHE[key] = val;
      return val;
    });
};

export const memoize_async = (fn) => {
  return async (...options) =>
    D2.A.FUNCTION('async', { options }, async ({ $D2 }) => {
      const key = calculateKey(...options);
      if (global.GEODISC_CACHE[key]) {
        $D2.S.INFO('CACHE_HIT', { options });
        return global.GEODISC_CACHE[key];
      }
      $D2.S.INFO('CACHE_MISS', { options });
      const val = await fn(...options);
      global.GEODISC_CACHE[key] = val;
      return val;
    });
};

import { sync } from 'utils/async';

import debug from 'utils/debug';

const D2 = debug('u:CommuteOffer:Validation:Validators');

export const validator = async commuteOffer =>
  D2.A.FUNCTION(
    '0040:RequestResultOrders',
    { commuteOffer },
    async ({ $D2 }) => {
      // if (commuteOffer.$source?.type !== 'Simulation') {
      //   return commuteOffer;
      // }

      const { stateless_api_request_data, result } = commuteOffer;

      const resultNodes = Object.entries(result.vehicles).reduce(
        (vehiclesMemo, [agent_id, resultVehicle]) => {
          const vehicle = resultVehicle ?? [];
          $D2.S.INFO('vehicle', { agent_id, vehicle, resultVehicle });
          const nodesMap = vehicle.reduce(
            (nodesMemo, node) => ({
              ...nodesMemo,
              [node.uid]: node,
            }),
            {}
          );
          return { ...vehiclesMemo, ...nodesMap };
        },
        {}
      );

      const newNodes = stateless_api_request_data.nodes.map((node) => {
        const resultNode = resultNodes[node.uid];
        if (resultNode) {
          const { scheduled_ts } = resultNode;
          return {
            ...node,
            scheduled_ts,
          };
        }
        return { ...node, scheduled_ts: null };
      });

      await sync();
      return {
        ...commuteOffer,
        stateless_api_request_data: {
          ...commuteOffer.stateless_api_request_data,
          nodes: newNodes,
        },
      };
    }
  );

export default validator;

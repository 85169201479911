import styled from 'styled-components';

const Content = styled.div`
  position: fixed;
  bottom: 150px;
  right: 30px;
  padding: 10px;
  border-radius: 10px;
  width: 200px;
  background: #4d576a;
  z-index: 2;
`;

export default Content;

import React from 'utils/react';

import ButtonPlus from 'components/ButtonPlus';

import Panel from './Panel';
import { useEffect } from 'react';
import debug from 'utils/debug';
import Layout from 'components/Layout';

const D2 = debug('p:Geofences');

const Geofences = (props) => {
  const { projectId, fetchGeofences, geofences } = props;

  useEffect(() => {
    D2.S.INFO('useEffect', { props });
    if (projectId || !geofences) {
      fetchGeofences();
    }
  }, [projectId]);

  return (
    <Layout>
      <Panel />
      <ButtonPlus />
    </Layout>
  );
};

export default React.memo(Geofences);

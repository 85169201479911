import React from 'utils/react';
import moment from 'moment-timezone';
import { Popup } from '@urbica/react-map-gl';

import debug from 'utils/debug';
import PopupContainer from '../PopupContainer';
import OnlineVehiclePopupContainer from '../OnlineVehiclePopupContainer';
import MenuPopupButton from './MenuPopupButton';

const D2 = debug('m:MapCommuteOfferEditor:utils');

export const getPopup = (layerId, feature, t) => {
  D2.S.INFO(`getPopup:${layerId}`, feature);

  const { properties, geometry } = feature;
  const defaultParams = {
    longitude: geometry.coordinates[0],
    latitude: geometry.coordinates[1],
    closeButton: true,
    closeOnClick: false,
    offset: 20,
  };

  // const curb =
  //   // eslint-disable-next-line no-nested-ternary
  //   typeof properties.curb !== 'undefined'
  //     ? properties.curb
  //       ? 'curb'
  //       : 'unrestricted'
  //     : 'undefined';

  if (layerId === 'activeOnlineVehicles') {
    return (
      <Popup {...defaultParams}>
        <OnlineVehiclePopupContainer>
          <div>{t('Vehicle')}</div>
          <div>
            <strong>{properties.service_number || properties.agent_id}</strong>
          </div>
        </OnlineVehiclePopupContainer>
      </Popup>
    );
  }

  if (layerId === 'vehicles') {
    return (
      <Popup {...defaultParams}>
        <PopupContainer>
          <div>
            {`Vehicle: ${
              properties.$assigned_vehicle_display_name || properties.agent_id
            }`}
          </div>
          <div>{`Location: ${properties.location_name}`}</div>
          <div>{`Stop ID: ${properties.stop_id}`}</div>
          <div>{`Type: ${properties.node_type}`}</div>
        </PopupContainer>
      </Popup>
    );
  }

  if (layerId === 'nodes') {
    return (
      <Popup {...defaultParams}>
        <PopupContainer>
          <div>{`Booking: #${properties.booking_uid}`}</div>
          <div>{`Location: ${properties.location_name}`}</div>
          <div>{`Stop ID: ${properties.stop_id}`}</div>
          <div>
            {`Time: ${moment(properties.open_time_ts)
              .tz(global.GEODISC_TIMEZONE)
              .format('HH:mm')}`}
          </div>
          <div>{`Walking distance: ${properties.walking_distance_to_node}`}</div>
          <div>{`Type: ${properties.node_type}`}</div>
        </PopupContainer>
      </Popup>
    );
  }

  if (layerId === 'bookings') {
    return (
      <Popup {...defaultParams}>
        <PopupContainer>
          <div>{`Booking: #${properties.name || properties.uid}`}</div>
          <div>
            {`Assigned vehicle: #${
              properties.assigned_vehicle_name || properties.assigned_vehicle_id
            }`}
          </div>
          <div>
            {t('c.Map.PickUpStop')} {properties.pickup_location_name} #
            {properties.scheduled_pickup_stop_id}
          </div>
          <div>
            {t('c.Map.PickUpTime')} {properties.scheduled_pickup_time}
          </div>
          <div>
            {t('c.Map.DropOffStop')} {properties.dropoff_location_name} #
            {properties.scheduled_dropoff_stop_id}
          </div>
          <div>
            {t('c.Map.DropOffTime')} {properties.scheduled_dropoff_time}
          </div>
        </PopupContainer>
      </Popup>
    );
  }

  if (layerId === 'stops') {
    return (
      <Popup {...defaultParams}>
        <PopupContainer>
          <div>{`Name: #${properties.name}`}</div>
          <div>{`id: #${properties.code}`}</div>
          <div>{`Type: ${properties.stop_type}`}</div>
        </PopupContainer>
      </Popup>
    );
  }

  return null;
};

export const getClickPopup = ({ event, features }, onMapClick) => {
  const { geometry } = features[0];
  const defaultParams = {
    longitude: geometry.coordinates[0],
    latitude: geometry.coordinates[1],
    closeButton: false,
    closeOnClick: false,
    offset: 20,
  };

  return (
    <Popup {...defaultParams}>
      <PopupContainer>
        {features.map((feature) => {
          const { properties, source } = feature;
          const onClick = () => {
            const newEvent = {
              ...event,
              features: [feature],
            };

            onMapClick(newEvent);
          };

          const id = properties.uid || properties.id;

          return (
            <MenuPopupButton key={source + id} onClick={onClick} type='button'>
              {`${source}: ${id}`}
            </MenuPopupButton>
          );
        })}
      </PopupContainer>
    </Popup>
  );
};

export default getPopup;

import React from 'utils/react';

import debug from 'utils/debug';
import Container from './Container';
import NavLink from './NavLink';

const D2 = debug('c:Header:Navigation:Navigation');

const Navigation = props =>
  D2.S.FUNCTION('Default', { props }, () => {
    const { t, currentUserConfig } = props;

    const { pages = {} } = currentUserConfig?.geodisc_settings || {};
    const {
      datasets = true,
      geofences = true,
      simulations = true,
      commute_offers = true,
    } = pages;

    return (
      <Container>
        {!window.GEODISC_UI_DATASETS_DISABLE && datasets && (
          <NavLink to='/datasets'>{t('header.default.Datasets')}</NavLink>
        )}
        {!window.GEODISC_UI_GEOFENCES_DISABLE && geofences && (
          <NavLink to='/geofences'>{t('header.default.Geofences')}</NavLink>
        )}
        {!window.GEODISC_UI_SIMULATIONS_DISABLE && simulations && (
          <NavLink to='/simulations'>{t('header.default.Simulations')}</NavLink>
        )}
        {!window.GEODISC_UI_COMMUTE_OFFER_DISABLE && commute_offers && (
          <NavLink to='/commuteoffers'>
            {t('header.default.CommuteOffers')}
          </NavLink>
        )}
      </Container>
    );
  });

export default React.memo(Navigation);

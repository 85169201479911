const allowedDatasetTypes = {
  lta: true,
  gadp: false,
  sample: true,
  sample_csv: true,
  night_odbs: true,
  night_csv: true,
};

export const datasetsToOptions = (datasets, allTypes) => {
  if (!datasets) {
    return [];
  }
  const needAllTypes = allTypes || false;

  return datasets.reduce((acc, value) => {
    const dataFormat = value.get('booking_data_format');
    if (
      needAllTypes ||
      (dataFormat in allowedDatasetTypes && allowedDatasetTypes[dataFormat])
    ) {
      acc.push({ label: value.get('name'), value: value.get('id') });
    }

    return acc;
  }, []);
};

export const geofencesToOptions = (geofences) => {
  if (!geofences) {
    return [];
  }

  return geofences.reduce((acc, value) => {
    acc.push({ label: value.name, value: value.id });
    return acc;
  }, []);
};

import * as React from 'react';
import useTranslation from 'utils/react/useTranslation';

import { Tag } from 'baseui/tag';

const Badge = (props) => {
  const { t } = useTranslation();

  return (
    <Tag
      onClick={props.onClick}
      closeable={false}
      kind='custom'
      variant='light'
      color='#ffffff'
      overrides={{
        Root: {
          style: ({ $isHovered }) => ({
            backgroundColor: $isHovered ? '#aaaaaa' : 'gray',
          }),
        },
        Text: {
          style: ({ $isHovered }) => ({
            color: $isHovered ? '#000000' : '#ffffff',
          }),
        },
      }}
    >
      {t('p.Editor.Panels.Vehicles.Vehicle.Badge.Updated')}
    </Tag>
  );
};

export default Badge;

import React from 'utils/react';
import useTranslation from 'utils/react/useTranslation';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';

import debug from 'utils/debug';

const D2 = debug('c:ModalWindows:InfoMessage');

export default (props) => {
  D2.S.INFO('InfoMessage', props);

  const { t } = useTranslation();

  const [isOpen, setIsOpen] = React.useState(false);
  const [title, setTitle] = React.useState(
    t('c.messages.4059b0251f66a18cb56f544728796875')
  );
  const [message, setMessage] = React.useState('');

  React.useEffect(() => {
    window.openInfoMessage = (text, opts) => {
      const options = opts || {};
      setMessage(text);
      setTitle(
        options.title || t('c.messages.4059b0251f66a18cb56f544728796875')
      );
      setIsOpen(true);
    };
    window.closeInfoMessage = () => {
      setIsOpen(false);
    };
    return () => {
      window.infoMessage = null;
    };
  });

  function onClose() {
    setIsOpen(false);
  }

  return (
    <React.Fragment>
      <Modal onClose={onClose} isOpen={isOpen}>
        <ModalHeader data-testid='InfoMessage-Header'>{title}</ModalHeader>
        <ModalBody data-testid='InfoMessage-Body'>
          <div data-testid='InfoMessage-Message'>{message}</div>
        </ModalBody>
        <ModalFooter data-testid='InfoMessage-Footer'>
          <ModalButton data-testid='InfoMessage-Button-Close' onClick={onClose}>
            {t('c.messages.d3d2e617335f08df83599665eef8a418')}
          </ModalButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

window.openInfoMessage = () => {};
window.closeInfoMessage = () => {};

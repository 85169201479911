import styled from 'styled-components';
import star from './star-empty.svg';
import starActive from './star.svg';

const Star = styled.div`
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-image: url(${({ isActive }) => (isActive ? starActive : star)});
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 5px;

  &:hover {
    background-image: url(${starActive});
  }
`;

export default Star;

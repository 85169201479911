import { styled } from 'baseui';

export const Container = styled('div', () => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1000,
  display: 'flex',
  width: '100vw',
  height: '100vh',
  background: 'rgb(0 0 0 / 56%)',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ContainerInner = styled('div', ({ $theme }) => ({
  width: '329px',
  height: '162px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: $theme.colors.containerBackground,
  boxShadow:
    '0px 9px 46px 8px rgb(0 0 0 / 12%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 11px 15px -7px rgb(0 0 0 / 20%)',
  borderRadius: '4px',
  flexDirection: 'column',
}));

export const TextInner = styled('p', () => ({
  marginTop: '20px',
  marginBottom: '0px',
}));

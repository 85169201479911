import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { closePopup } from 'modules/ui/actions';

import { modalWindowSelector } from 'modules/ui/selectors';

import Modal from './Modal';

const mapStateToProps = createImmutableSelector(
  modalWindowSelector,
  modalWindow => ({
    modalWindow,
  })
);

const mapDispatchToProps = {
  closePopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);

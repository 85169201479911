import styled from 'styled-components';

const Description = styled.div`
  font-size: 17px;
  font-weight: 400;
  background: transparent;
  padding: 3px 5px 3px;
  outline: none;
  width: 250px;
  color: #e3e2eb;
  opacity: 0.5;
  margin-bottom: 15px;
`;

export default Description;

import withTranslation from 'utils/react/withTranslation';
import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { isDeliveryLayoutSelector } from 'modules/user/selectors';

import Panels from './Panels';

const mapStateToProps = createImmutableSelector(
  isDeliveryLayoutSelector,
  isDeliveryLayout => ({
    isDeliveryLayout,
  })
);

const mapDispatchToProps = null;

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation()(withConnect(Panels));

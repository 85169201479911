// The yellow box
import React, { useState, useEffect } from 'react';
import { styled } from 'baseui';
import useTranslation from 'utils/react/useTranslation';
import { ReactComponent as WarningIcon } from 'assets/warning.svg';
import rightArrow from 'assets/rightArrow.svg';

// Wrapper
const WarningBox = styled('div', ({ $isActive }) => {
  return {
    backgroundColor: 'rgba(249, 192, 58, 0.1)',
    border: 'solid 1px rgba(249, 192, 58, 0.4)',
    padding: '16px',
    borderRadius: '4px',
    display: $isActive ? 'visible' : 'none',
    marginTop: '9px',
    marginBottom: '12px',
    cursor: 'pointer',
  };
});

const Header = styled('div', () => {
  return {
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(249, 192, 58, 1)',
    fontWeight: 700,
    fontSize: '14px',
    fontFamily: 'Montserrat',
  };
});

const Message = styled('div', () => {
  return {
    paddingLeft: '8px',
    fontSize: '14px',
    textAlign: 'justify',
    marginTop: '6px',
    fontFamily: 'Montserrat',
    lineHeight: '140%',
  };
});

const ExpandButton = styled('button', () => {
  return {
    cursor: 'pointer',
    background: `url(${rightArrow}) no-repeat center`,
    marginLeft: 'auto',
    height: '12px',
    width: '8px',
    border: 'none',
    opacity: 0.5,
    ':hover': {
      opacity: 1,
    },
  };
});

export default (props) => {
  const [unassignedBookingsLength, setUnassignedBookingsLength] = useState(0);
  const [isDisplayed, setDisplayStatus] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (props.unassignedBookings) {
      setUnassignedBookingsLength(props.unassignedBookings);
      setDisplayStatus(true);
    }
  });

  return (
    <WarningBox
      $isActive={isDisplayed}
      onClick={(e) => {
        e.preventDefault();
        props.toggleBookings();
        if (props.activeBookingId) {
          props.cleanActiveBookingId();
        }
      }}
    >
      <Header>
        <WarningIcon />
        <span style={{ marginLeft: 12 }}>
          {t('panel.header.unassigned.order', {
            count: unassignedBookingsLength,
          })}
        </span>
        <ExpandButton
          onClick={(e) => {
            e.preventDefault();
            props.toggleBookings();
          }}
        />
      </Header>
      <Message>{t('panel.content.unassigned.order')}</Message>
    </WarningBox>
  );
};

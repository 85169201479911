export const DRIVERS_FETCH_REQUEST = '@@entities/DRIVERS_FETCH_REQUEST';
export const STOPS_FETCH_REQUEST = '@@entities/STOPS_FETCH_REQUEST';
export const STOPS_FETCH_SUCCESS = '@@entities/STOPS_FETCH_SUCCESS';
export const DRIVERS_FETCH_SUCCESS = '@@entities/DRIVERS_FETCH_SUCCESS';
export const STOPS_FETCH_FAILURE = '@@entities/STOPS_FETCH_FAILURE';
export const DRIVERS_FETCH_FAILURE = '@@entities/DRIVERS_FETCH_FAILURE';

export const fetchDrivers = projectId => ({
  type: DRIVERS_FETCH_REQUEST,
  payload: { projectId },
});

export const fetchStops = () => ({
  type: STOPS_FETCH_REQUEST,
});

import React from 'utils/react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'baseui/modal';
import FormField from 'components/Forms/FormField';
import { Divider } from 'components/Forms/FormStyles';
import CustomButton from 'components/Forms/Button';
import { useEffect, useMemo, useState } from 'react';
import { useStyletron } from 'baseui';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import regexValidation from 'utils/forms';
import useTranslation from 'utils/react/useTranslation';
import ArrayField from 'components/Forms/ArrayField';
import Select from 'components/Forms/Select';
import {
  convertDemandLoadsToDisplay,
  getConvertedDemandType,
} from 'utils/Logistics/conversions';
import { getMapBoxTokens } from 'api/logistics';

const FieldWrapper = styled.div`
  margin-top: 45px;
`;

const getCapacityObject = (capacityArray) => {
  const capacity = {};
  capacityArray.map((object) => {
    const convertedDemandType = getConvertedDemandType(object.unit);
    capacity[convertedDemandType.demandType] =
      parseFloat(object.maxLoad) * convertedDemandType.multiplier;
  });
  return capacity;
};

const getCapacityArray = (capacityObject) => {
  const capacity = [];
  Object.keys(capacityObject).map((key) => {
    const convertedDemandLoads = convertDemandLoadsToDisplay(
      key,
      capacityObject[key]
    );
    capacity.push({
      maxLoad: convertedDemandLoads.value,
      unit: convertedDemandLoads.type,
    });
  });
  return capacity;
};

const getAllLabelOptions = (labels) => {
  const labelOptions = labels.map((label) => {
    return { value: label, label };
  });
  return labelOptions;
};

const VehicleTypeEditor = (props) => {
  const {
    addVehicleType,
    updateVehicleType,
    projectData,
    vehicleTypes,
    labels,
  } = props;

  const [formMode, setFormMode] = useState('new');
  const { t } = useTranslation();
  const defaultValues = {
    vehicleType: '',
    unitMeasure: [
      {
        maxLoad: '',
        unit: '',
      },
    ],
    maxHeight: '',
    maxWeight: '',
    manpower: '',
    vehicleLabel: [],
  };
  const {
    register,
    formState: { errors, isValid },
    getValues,
    control,
    reset,
    setError,
    clearErrors,
    handleSubmit,
  } = useForm({
    criteriaMode: 'firstError',
    mode: 'onChange',
    defaultValues,
  });

  const [isOpen, setIsOpen] = React.useState(false);
  const [css, theme] = useStyletron('');
  const vehicleLabelOptions = getAllLabelOptions(labels);

  useEffect(() => {
    window.openVehicleTypeEditor = (props) => {
      const data = props?.data || {};
      if (props?.mode) {
        setFormMode(props?.mode);
      } else {
        setFormMode('new');
      }

      if (data?.id) {
        const labelsData = (data.vehicle_labels || []).map((label) => {
          return { value: label, label: label };
        });

        const roadNetwork = data?.routing_engine_settings?.road_network || '';

        const routingProfileValue = [
          {
            label: roadNetwork,
            value: roadNetwork,
          },
        ];

        reset({
          ...data,
          vehicleType: data?.id,
          routingProfile: routingProfileValue,
          unitMeasure: Array.isArray(data?.capacity)
            ? data?.capacity
            : getCapacityArray(data?.capacity) || defaultValues?.unitMeasure,
          maxHeight: data?.characteristics?.height,
          maxWeight: data?.characteristics?.weight,
          manpower: data?.characteristics?.manpower,
          unique_id: data?.unique_id,
          routing_engine_settings: data?.routing_engine_settings,
          vehicleLabel: labelsData,
        });
      } else {
        reset(defaultValues);
      }
      setIsOpen(true);
    };
    window.closeVehicleTypeEditor = () => {
      setIsOpen(false);
    };
  }, []);

  function onClose({ closeSource }) {
    if (closeSource !== 'backdrop') {
      setIsOpen(false);
    }
  }

  const onSubmit = async () => {
    const formData = getValues();
    const vehicleCharacteristics = {
      height: parseFloat(formData?.maxHeight) || null,
      manpower: parseFloat(formData?.manpower) || null,
      weight: parseFloat(formData?.maxWeight) || null,
    };
    Object.keys(vehicleCharacteristics).forEach((key) => {
      if (vehicleCharacteristics[key] === null) {
        delete vehicleCharacteristics[key];
      }
    });

    // Since the project data field is a JSON field it rewrite everything upon update.
    // workaround below is used to avoid deleting existing values and writing unwanted values to the data object
    const otherData = { ...formData };
    delete otherData?.maxHeight;
    delete otherData?.manpower;
    delete otherData?.maxWeight;
    delete otherData?.unitMeasure;
    delete otherData?.vehicleType;
    delete otherData?.vehicleLabel;

    const projectDataJS = projectData.toJS();

    const tokens = await getMapBoxTokens(projectDataJS.id);
    const firstToken = tokens?.[0]?.token;

    const vehicleType = {
      ...otherData,
      capacity: getCapacityObject(formData?.unitMeasure || []),
      characteristics: { ...vehicleCharacteristics },
      id: formData?.vehicleType,
      routing_engine_settings: {
        ...(firstToken ? { key: firstToken } : {}),
        url: 'http://mapbox-osrm-proxy',
        ...formData?.routing_engine_settings,
        road_network: formData?.routingProfile?.[0]?.value,
      },
      vehicle_labels: formData?.vehicleLabel.map(data => data.label),
    };

    const allProjectData =
      formMode === 'edit'
        ? {
            ...projectDataJS,
            data: {
              ...projectDataJS?.data,
              vehicle_models: [
                ...vehicleTypes.slice(0, formData?.unique_id),
                vehicleType,
                ...vehicleTypes.slice(formData?.unique_id + 1),
              ],
            },
          }
        : {
            ...projectDataJS,
            data: {
              ...projectDataJS?.data,
              vehicle_models: vehicleTypes,
            },
          };

    if (formMode === 'edit') {
      updateVehicleType({
        data: allProjectData,
        updateId: vehicleTypes[formData?.unique_id]?.id,
        vehicleType,
      });
    } else if (formMode === 'clone' || formMode === 'new') {
      addVehicleType({
        values: vehicleType,
        data: allProjectData,
        vehicleType,
      });
    }

    global.closeVehicleTypeEditor();
  };

  const values = getValues();

  useEffect(() => {
    if (errors?.vehicleType) {
      return;
    }

    const isInvalidId =
      (formMode === 'clone' || formMode === 'new') &&
      !!vehicleTypes?.find(({ id }) => id === values?.vehicleType);

    if (isInvalidId) {
      setError('vehicleType', {
        type: 'custom',
        message: t('p.fleet.type.editor.duplicatedId'),
      });
    }
  }, [values, errors]);

  const routingProfileOptions = useMemo(() => {
    return (projectData?.toJS()?.data?.road_network_options || []).map(
      item => ({ label: item, value: item })
    );
  }, [projectData]);

  const hasError = Object.keys(errors).length;
  return (
    <React.Fragment>
      <Modal
        overrides={{
          Root: {
            style: {
              zIndex: 22,
            },
          },
          Dialog: {
            style: {
              backgroundColor: theme.colors.containerBackground,
              padding: '0 12px',
            },
          },
          Close: {
            style: {
              color: theme.colors.white,
            },
          },
        }}
        size='auto'
        onClose={onClose}
        isOpen={isOpen}
      >
        <ModalHeader
          className={css({
            fontFamily: 'Montserrat',
            fontSize: '24px',
            fontWeight: 700,
            color: `${theme?.colors?.white} !important`,
            marginTop: '10px !important',
          })}
        >
          {formMode === 'edit'
            ? t('p.fleet.type.editor.title.dynamic', {
                name: values?.vehicleType,
              })
            : t('p.fleet.type.editor.title')}
        </ModalHeader>
        <ModalBody
          className={css({
            fontFamily: 'Montserrat',
            color: `${theme?.colors?.white} !important`,
            fontSize: '14px',
            fontWeight: 500,
          })}
        >
          <div className='form-group' />
          <form id='add-vehicle-type-form'>
            <div className='form-group'>
              <div style={{ marginTop: '40px' }}>
                <FieldWrapper>
                  <FormField
                    type='string'
                    label={t('p.fleet.type.editor.vehicleType')}
                    placeholder={t(
                      'p.fleet.type.editor.vehicleType.placeholder'
                    )}
                    name='vehicleType'
                    value={values?.vehicleType}
                    register={register('vehicleType', {
                      required: t('u.validation.required'),
                      maxLength: {
                        value: 30,
                        message: t('u.validation.maxLength', { count: 30 }),
                      },
                    })}
                    errors={errors.vehicleType}
                    width='288px'
                  />
                </FieldWrapper>

                {routingProfileOptions.length ? (
                  <FieldWrapper>
                    <Select
                      info={t('p.fleet.type.editor.routingProfile.info')}
                      type='string'
                      label={t('p.fleet.type.editor.routingProfile')}
                      name='routingProfile'
                      errors={errors.routingProfile}
                      width='490px'
                      value={values?.routingProfile}
                      options={routingProfileOptions}
                      control={control}
                      labelKey='label'
                      valueKey='value'
                      clearErrors={clearErrors}
                      placeholder={t(
                        'p.fleet.type.editor.routingProfile.placeholder'
                      )}
                      setError={setError}
                      rules={{ required: t('u.validation.required') }}
                    />
                  </FieldWrapper>
                ) : null}

                <FieldWrapper data-testid='vehicle_label'>
                  <Select
                    type='string'
                    label={t('p.fleet.type.editor.vehicleLabel')}
                    name='vehicleLabel'
                    errors={errors.vehicleLabel}
                    width='490px'
                    value={values.vehicleLabel}
                    options={vehicleLabelOptions}
                    control={control}
                    labelKey='label'
                    valueKey='value'
                    creatable={true}
                    clearable={true}
                    multi={true}
                    clearErrors={clearErrors}
                    placeholder={t(
                      'p.fleet.type.editor.vehicleLabel.placeholder'
                    )}
                    setError={setError}
                  />
                </FieldWrapper>

                <FieldWrapper style={{ display: 'flex', flexDirection: 'row' }}>
                  <ArrayField
                    fieldName='unitMeasure'
                    register={register}
                    errors={errors}
                    control={control}
                    addButtonText='Add row'
                    subFields={[
                      {
                        name: 'maxLoad',
                        label: t('p.fleet.type.editor.maxLoad'),
                        width: '282px',
                        type: 'string',
                        validate: {
                          required: t('u.validation.required'),
                          pattern: {
                            value: regexValidation?.positiveInteger,
                            message: t('u.validation.isPositiveNumber'),
                          },
                          maxLength: {
                            value: 20,
                            message: t('u.validation.maxLength', { count: 20 }),
                          },
                        },
                        placeholder: '1000',
                        errors: errors.maxLoad,
                      },
                      {
                        name: 'unit',
                        label: t('p.fleet.type.editor.unit'),
                        width: '188px',
                        validate: {
                          required: t('u.validation.required'),
                          maxLength: {
                            value: 20,
                            message: t('u.validation.maxLength', { count: 20 }),
                          },
                        },
                        placeholder: t('p.fleet.type.editor.unit.placeholder'),
                        errors: errors.unit,
                      },
                    ]}
                  />
                </FieldWrapper>

                <Divider style={{ marginTop: '45px', marginBottom: '25px' }} />

                <div>
                  <h2
                    style={{
                      fontSize: '16px',
                      display: 'inline-block',
                      cursor: 'pointer',
                      margin: '0',
                    }}
                  >
                    {t('p.fleet.type.editor.subtitle')}
                  </h2>
                  <React.Fragment>
                    <FieldWrapper
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <FormField
                        type='string'
                        label={t('p.fleet.type.editor.maxHeight')}
                        placeholder='1'
                        name='maxHeight'
                        value={values?.maxHeight}
                        register={register('maxHeight', {
                          pattern: {
                            value: regexValidation?.decimalNumberRegex,
                            message: t('u.validation.isNumber'),
                          },
                          maxLength: {
                            value: 10,
                            message: t('u.validation.maxLength', { count: 10 }),
                          },
                        })}
                        errors={errors.maxHeight}
                        width='184px'
                        style={{ marginRight: '20px' }}
                        unit='cm'
                      />
                      <FormField
                        type='string'
                        label={t('p.fleet.type.editor.maxWeight')}
                        placeholder='1'
                        name='maxWeight'
                        value={values?.maxWeight}
                        register={register('maxWeight', {
                          pattern: {
                            value: regexValidation?.decimalNumberRegex,
                            message: t('u.validation.isNumber'),
                          },
                          maxLength: {
                            value: 10,
                            message: t('u.validation.maxLength', { count: 10 }),
                          },
                        })}
                        errors={errors.maxWeight}
                        width='184px'
                        unit='ton'
                      />
                    </FieldWrapper>
                  </React.Fragment>
                  <React.Fragment>
                    <FieldWrapper
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <FormField
                        type='string'
                        label={t('p.fleet.type.editor.manpower')}
                        placeholder='1'
                        name='manpower'
                        value={values?.manpower}
                        register={register('manpower', {
                          pattern: {
                            value: regexValidation?.decimalNumberRegex,
                            message: t('u.validation.isNumber'),
                          },
                          maxLength: {
                            value: 3,
                            message: t('u.validation.maxLength', { count: 3 }),
                          },
                        })}
                        errors={errors.manpower}
                        width='184px'
                      />
                    </FieldWrapper>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>

        <ModalFooter>
          <CustomButton onClick={global.closeVehicleTypeEditor}>
            {t('c.messages.Cancel')}
          </CustomButton>

          <CustomButton
            type='button'
            form='add-vehicle-type-form'
            $filled={true}
            style={{ marginLeft: 12 }}
            disabled={hasError}
            onClick={handleSubmit(onSubmit)}
          >
            {t('c.messages.Save')}
          </CustomButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
window.openVehicleTypeEditor = () => {};
window.closeVehicleTypeEditor = () => {};

export default VehicleTypeEditor;

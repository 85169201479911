import { styled } from 'baseui';

const sizes = Object.freeze({
  xs: '4px 8px',
  sm: '8px 16px',
  md: '12px 20px',
  lg: '16px 24px',
});

const CustomButton = styled(
  'button',
  ({ $theme, disabled, $filled, size = 'md' }) => {
    const colors = $theme?.colors;
    const ifDisabled = disabled
      ? {
          borderColor: colors?.buttonBorderDisabled,
          color: colors?.buttonTextDisabled,
          backgroundColor: colors?.buttonBorderDisabled,
          cursor: 'not-allowed',
        }
      : {
          borderColor: colors?.buttonBorder,
          color: colors?.white,
        };
    const ifFilled = $filled
      ? { backgroundColor: colors?.buttonBorder }
      : { backgroundColor: 'transparent' };
    return {
      borderStyle: 'solid ',
      borderWidth: '2px',
      fontWeight: 600,
      fontFamily: 'Montserrat',
      fontSize: size === 'lg' ? '16px' : '14px',
      borderRadius: '4px',
      padding: sizes[size],
      margin: '12px',
      cursor: 'pointer',
      ...ifFilled,
      ...ifDisabled,
    };
  }
);

export default CustomButton;

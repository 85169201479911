import React from 'utils/react';
import useTranslation from 'utils/react/useTranslation';

import Container from './Container';
import ProgressBar from './ProgressBar';

const getStatus = (t, progress, status = 'Processing') => {
  const messageKey = `c.message.${status}`;
  const messageTxt = t(messageKey);
  const message = messageTxt !== messageKey ? messageTxt : status;

  if (progress === 1) {
    return `${message}...`;
  }

  return `${message} (${Math.round(progress * 100)}%)`;
};

const Progress = ({ progress, status }) => {
  const { t } = useTranslation();

  return (
    <Container>
      {getStatus(t, progress, status)}
      <ProgressBar progress={progress} />
    </Container>
  );
};

export default Progress;

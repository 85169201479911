import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { toggleVisibilityTransitstopSet } from 'modules/ui/actions';
import { transitstopSetsSelector } from 'modules/commuteOffer/selectors';
import { visibleTransitstopSetsSelector } from 'modules/ui/selectors';

import TransitstopSetsMenu from './TransitstopSetsMenu';

const mapStateToProps = createSelector(
  transitstopSetsSelector,
  visibleTransitstopSetsSelector,
  (transitstopSets, visibleTransitstopSets) => ({
    transitstopSets,
    visibleTransitstopSets,
  })
);

const mapDispatchToProps = {
  toggleVisibilityTransitstopSet,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(TransitstopSetsMenu);

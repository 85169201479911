export default {
  points: {
    pickup: true,
    pickupColor: [255, 76, 116],
    pickupRadius: 60,
    pickupOpacity: 1,
    dropoff: true,
    dropoffColor: [38, 122, 255],
    dropoffRadius: 60,
    dropoffOpacity: 1,
  },
  arcs: {
    pickupArcColor: [255, 76, 116],
    dropoffArcColor: [38, 122, 255],
    width: 2,
  },
  clusters: {
    pickup: true,
    pickupColor: [255, 76, 116],
    pickupOpacity: 1,
    pickupRadius: 70,
    pickupRadiusMin: 2,
    pickupRadiusMax: 40,
    dropoff: true,
    dropoffColor: [38, 122, 255],
    dropoffOpacity: 1,
    dropoffRadius: 70,
    dropoffRadiusMin: 2,
    dropoffRadiusMax: 40,
  },
  arcClusters: {
    pickupArcColor: [255, 76, 116],
    dropoffArcColor: [38, 122, 255],
    width: 2,
    pickup: true,
    pickupColor: [255, 76, 116],
    pickupOpacity: 1,
    pickupRadius: 70,
    pickupRadiusMin: 2,
    pickupRadiusMax: 40,
    dropoff: true,
    dropoffColor: [38, 122, 255],
    dropoffOpacity: 1,
    dropoffRadius: 70,
    dropoffRadiusMin: 2,
    dropoffRadiusMax: 40,
  },
  hex_pickup: {
    colorRange: [
      [253, 235, 227],
      [251, 197, 193],
      [248, 160, 181],
      [245, 106, 161],
      [195, 36, 137],
      [121, 14, 117],
    ],
    radius: 500,
    coverage: 0.9,
    opacity: 0.5,
    extruded: false,
    elevationScale: 6,
  },
  hex_dropoff: {
    colorRange: [
      [191, 255, 242],
      [127, 242, 255],
      [76, 204, 255],
      [30, 153, 255],
      [32, 84, 251],
      [71, 43, 201],
    ],
    radius: 500,
    coverage: 0.9,
    opacity: 0.5,
    extruded: false,
    elevationScale: 4,
  },
};

import React from 'utils/react';
import useTranslation from 'utils/react/useTranslation';

import logo from 'assets/SWAT-Icon-Orange.png';

import { ProgressBar } from 'baseui/progress-bar';
import Container from './Container';
import Logo from './Logo';

const LoadingPanel = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Logo src={logo} />
      <ProgressBar
        getProgressLabel={() => ''}
        showLabel={false}
        overrides={{
          BarProgress: {
            style: () => ({
              outline: '#f85f39 solid',
              backgroundColor: '#f85f39',
            }),
          },
        }}
        infinite
      />
      <h2>{t('c.LoadingScreen.Loading')}</h2>
    </Container>
  );
};

export default LoadingPanel;

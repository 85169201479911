import styled from 'styled-components';

export default styled.button`
  display: block;
  padding: 0;
  border: none;
  text-align: left;

  font-size: 14px;
  color: hsla(47, 100%, 98%, 0.7);

  background-color: transparent;
  cursor: pointer;

  :hover {
    color: hsla(48, 6%, 70%, 0.7);
  }

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: 35px 70px;
  padding-left: 200px;
  padding-right: 100px;

  z-index: 50;
  background: rgb(54, 61, 74);
`;

export default Container;

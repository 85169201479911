import styled from 'styled-components';

const Container = styled.div`
  color: #edece8;
  height: calc(100vh - 160px);

  .ReactVirtualized__List {
    outline: none;
  }
`;

export default Container;

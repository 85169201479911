import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1140px;
  height: 100vh;
  padding: 20px;
  padding-top: 90px;
  padding-left: 40px;
  padding-right: 40px;
`;

export default Container;

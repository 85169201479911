import { v4 as uuidv4 } from 'uuid';

import { emptyCommuteOfferJSON } from 'utils/CommuteOffer/defaults';

import moment from 'moment-timezone';
import deepmerge from 'deepmerge';
import debug from 'utils/debug';
import { crc32 } from 'utils/crc32';
import weekdays from 'utils/weekdays';

import {
  silverayVehicleTemplateJSON,
  generateVehiclesForEachBooking,
  silverayDestinationTimes,
} from 'utils/CommuteOffer/silveray';

const D2 = debug('m:CommuteSchedule:GenerateOffersForDate');

const ignoreCalculatedTimewindow = true;

const commuteSchedule$GenerateDailyNodeDemands = (booking, demand) => {
  return Object.keys(demand).reduce((nodeDemandMemo, key) => {
    const value = demand[key];
    const weeklyDemand = Object.keys(weekdays).reduce((memo, weekday) => {
      if (booking.$record[weekday]) {
        return {
          ...memo,
          [`${weekday}_${key}`]: value,
        };
      }
      return memo;
    }, {});
    return { ...nodeDemandMemo, ...weeklyDemand };
  }, {});
};

// eslint-disable-next-line import/prefer-default-export
export const commuteSchedule$GenerateOffersForDate = async (
  data,
  currentDate,
  timezone,
  opts
) => {
  D2.S.INFO('Request', {
    data,
    currentDate,
    timezone,
    opts,
  });

  try {
    const options = opts || {};

    const {
      areCalculationGroupsEnabled,
      areMutuallyExclusiveGroupsEnabled,
      areSpecialDemandsEnabled,
      human_readable_uids,
      vehicle_passenger,
      vehicle_wheelchair,
      unassigned_only,
      engineSettingsBase,
      max_slack,
      optimize_quantity,
      time_limit_ms,
      first_solution_strategy,
      externalOffer,
      maxTripDurationInMinutes = 50,
      morningDestinationTimeWindowDuration = 0,
      eveningDestinationTimeWindowDuration = 0,
      generateDailyNodeDemands = false,
      generateGroupIDs = true,
      generateGeofenceIDs = true,
    } = options;

    const currentDateFmt = currentDate.format('YYYY-MM-DD');
    const currentDay = currentDate.format('ddd').toLowerCase();
    const A = {
      currentDateFmt,
      currentDay,
    };

    const { demand_settings = {} } = engineSettingsBase;

    const ambulantOneDemand = demand_settings.ambulant;
    // || {
    //   passenger: 1,
    //   ambulant: 1
    // };
    if (!ambulantOneDemand) {
      throw new Error('engine_settings.demand_settings.ambulant is required');
    }

    const wheelchairOneDemand = demand_settings.wheelchair;
    // || {
    //   passenger: 1,
    //   wheelchair: 1
    // };
    if (!wheelchairOneDemand) {
      throw new Error('engine_settings.demand_settings.wheelchair is required');
    }

    D2.S.INFO('demand_settings', {
      ...A,
      ambulantOneDemand,
      wheelchairOneDemand,
      demand_settings,
      engineSettingsBase,
    });

    const formatForCurrentDate = value =>
      value ? `${currentDateFmt}T${value.format('HH:mm:ssZ')}` : undefined;

    const timeForCurrentDate = value =>
      value
        ? formatForCurrentDate(moment.tz(value, 'LT', timezone))
        : undefined;

    D2.S.INFO('externalOffer', { ...A, externalOffer });

    const dataByWeekdays = currentDay
      ? data.filter(record => record[currentDay])
      : data;
    D2.S.INFO('dataByWeekdays', { ...A, dataByWeekdays });

    if (!dataByWeekdays.length) {
      return [];
    }

    const calculationGroupSet = areCalculationGroupsEnabled
      ? dataByWeekdays.reduce((acc, person) => {
          acc.add(person.calculation_group);
          return acc;
        }, new Set(['None']))
      : new Set(['None']);
    const calculationGroups = [...calculationGroupSet.values()];
    D2.S.INFO('calculationGroups', {
      calculationGroups,
    });

    const offersByGroups = calculationGroups.reduce(
      (offersByGroupsAcc, currentGroup) => {
        // eslint-disable-next-line no-shadow
        const A = {
          currentDateFmt,
          currentDay,
          currentGroup,
        };

        const currentGroupTag = `#CG:${currentGroup}`;

        const dataByGroups = dataByWeekdays.filter(
          x => x.calculation_group === currentGroup
        );
        D2.S.INFO('dataByGroups', { ...A, dataByGroups });

        if (!dataByGroups.length) {
          return offersByGroupsAcc;
        }

        const offersByDeliveryTypes = ['H2C', 'C2H'].reduce(
          (offersByDeliveryTypesAcc, currentDeliveryType) => {
            // eslint-disable-next-line no-shadow
            const A = {
              currentDateFmt,
              currentDay,
              currentGroup,
              currentDeliveryType,
            };

            const isMorning = currentDeliveryType === 'H2C';
            const currentDeliveryTypeName = isMorning ? 'Morning' : 'Evening';
            const currentDeliveryTypeTag = `#${currentDeliveryTypeName}`;

            const dataByDeliveryTypes = dataByGroups.filter(record => record);
            D2.S.INFO('dataByDeliveryTypes', {
              ...A,
              dataByDeliveryTypes,
            });

            if (!dataByDeliveryTypes.length) {
              return offersByDeliveryTypesAcc;
            }

            const offersByDestinationTimes = [
              ...silverayDestinationTimes[currentDeliveryType].keys(),
            ].reduce((offersByDestinationTimesAcc, currentDestinationTime) => {
              const currentDestinationTimeName = moment
                .tz(currentDestinationTime, 'LT', timezone)
                .format('HH:mm');
              const currentDestinationTimeTag = `#${currentDestinationTimeName}`;

              // eslint-disable-next-line no-shadow
              const A = {
                currentDateFmt,
                currentDay,
                currentGroup,
                currentDeliveryType,
                currentDestinationTimeName,
              };

              const dataByDestinationTimes =
                currentDeliveryType === 'H2C'
                  ? dataByDeliveryTypes.filter(
                      x => x.morning_dropoff_ts === currentDestinationTime
                    )
                  : dataByDeliveryTypes.filter(
                      x => x.evening_pickup_ts === currentDestinationTime
                    );
              D2.S.INFO('dataByDestinationTimes', {
                ...A,
                dataByDestinationTimes,
              });

              if (!dataByDestinationTimes.length) {
                return offersByDestinationTimesAcc;
              }

              const dataForGeneration = dataByDestinationTimes;

              const bookings = dataForGeneration.reduce(
                (bookingsAcc, $record) =>
                  D2.S.FUNCTION(
                    'dataForGeneration.reduce',
                    { bookingsAcc, $record },
                    ({ $D2 }) => {
                      const currentGeofenceId = isMorning ? 'AM' : 'PM';

                      const morning_geofence_id = generateGeofenceIDs
                        ? currentGeofenceId
                        : undefined;

                      const morning_groups =
                        generateGroupIDs && $record.morning_dropoff_ts
                          ? [
                              moment
                                .tz($record.morning_dropoff_ts, 'LT', timezone)
                                .format('HH:mm'),
                            ]
                          : undefined;

                      const evening_geofence_id = generateGeofenceIDs
                        ? currentGeofenceId
                        : undefined;

                      const evening_groups =
                        generateGroupIDs && $record.evening_pickup_ts
                          ? [
                              moment
                                .tz($record.evening_pickup_ts, 'LT', timezone)
                                .format('HH:mm'),
                            ]
                          : undefined;

                      const uid = human_readable_uids
                        ? [
                            moment(currentDate).format('YYYY-MM-DD #ddd'),
                            currentDeliveryTypeTag,
                            currentGroupTag,
                            $record.name,
                            $record.zip_code,
                          ]
                            .filter(x => x)
                            .join(' ')
                        : uuidv4();

                      const tags = [
                        moment(currentDate).format('YYYY-MM-DD'),
                        moment(currentDate).format('ddd'),
                        currentDeliveryTypeName,
                        isMorning
                          ? moment
                              .tz($record.morning_dropoff_ts, 'LT', timezone)
                              .format('HH:mm')
                          : moment
                              .tz($record.evening_pickup_ts, 'LT', timezone)
                              .format('HH:mm'),
                      ];

                      const name = [
                        ...[$record.name, $record.zip_code],
                        ...tags.map(x => `#${x}`),
                      ].join(' ');
                      // const { zip_code } = $record;

                      const srcPoint = $record.home.$buildings[0];
                      $D2.S.INFO('srcPoint', {
                        ...A,
                        $record,
                        srcPoint,
                      });

                      const dstPoint =
                        $record.office.$buildings[0].nearestPoint;
                      $D2.S.INFO('dstPoint', {
                        ...A,
                        $record,
                        dstPoint,
                      });

                      const $morningDropoffTime = formatForCurrentDate(
                        moment.tz($record.morning_dropoff_ts, 'LT', timezone)
                      );

                      const $eveningPickupTime = formatForCurrentDate(
                        moment.tz($record.evening_pickup_ts, 'LT', timezone)
                      );

                      // const optionalPenalty = -1;
                      // const mandatoryPenalty = -1;
                      const optionalPenalty = 100000000;
                      const mandatoryPenalty = -1;

                      const morningPenalty =
                        $record.morning_pickup_scheduled_open_time !== '' &&
                        $record.morning_pickup_scheduled_close_time !== ''
                          ? mandatoryPenalty
                          : optionalPenalty;
                      const eveningPenalty =
                        $record.evening_dropoff_scheduled_open_time !== '' &&
                        $record.evening_dropoff_scheduled_close_time !== ''
                          ? mandatoryPenalty
                          : optionalPenalty;

                      $D2.S.INFO('penalty', {
                        ...A,
                        $record,
                        morningPenalty,
                        eveningPenalty,
                      });

                      const $times = {
                        morning: !$morningDropoffTime
                          ? null
                          : {
                              pickup:
                                !ignoreCalculatedTimewindow &&
                                $record.morning_pickup_scheduled_open_time !==
                                  '' &&
                                $record.morning_pickup_scheduled_close_time !==
                                  ''
                                  ? {
                                      penalty: morningPenalty,
                                      from: formatForCurrentDate(
                                        moment.tz(
                                          $record.morning_pickup_scheduled_open_time,
                                          'LT',
                                          timezone
                                        )
                                      ),
                                      till: formatForCurrentDate(
                                        moment.tz(
                                          $record.morning_pickup_scheduled_close_time,
                                          'LT',
                                          timezone
                                        )
                                      ),
                                    }
                                  : {
                                      penalty: morningPenalty,
                                      from: $record.morning_pickup_from_ts
                                        ? timeForCurrentDate(
                                            $record.morning_pickup_from_ts
                                          )
                                        : formatForCurrentDate(
                                            moment($morningDropoffTime)
                                              .subtract(
                                                maxTripDurationInMinutes,
                                                'minutes'
                                              )
                                              .tz(timezone)
                                          ),
                                      till: $record.morning_pickup_till_ts
                                        ? timeForCurrentDate(
                                            $record.morning_pickup_till_ts
                                          )
                                        : formatForCurrentDate(
                                            moment($morningDropoffTime)
                                              // .subtract(5, 'minutes')
                                              .tz(timezone)
                                          ),
                                    },
                              dropoff: {
                                penalty: morningPenalty,
                                from: formatForCurrentDate(
                                  moment($morningDropoffTime)
                                    .subtract(
                                      morningDestinationTimeWindowDuration,
                                      'seconds'
                                    )
                                    .tz(timezone)
                                ),
                                till: $morningDropoffTime,
                              },
                            },
                        evening: !$eveningPickupTime
                          ? null
                          : {
                              pickup: {
                                penalty: eveningPenalty,
                                from: $eveningPickupTime,
                                till: formatForCurrentDate(
                                  moment($eveningPickupTime)
                                    .add(
                                      eveningDestinationTimeWindowDuration,
                                      'seconds'
                                    )
                                    .tz(timezone)
                                ),
                              },
                              dropoff:
                                !ignoreCalculatedTimewindow &&
                                $record.evening_dropoff_scheduled_open_time !==
                                  '' &&
                                $record.evening_dropoff_scheduled_close_time !==
                                  ''
                                  ? {
                                      penalty: eveningPenalty,
                                      from: formatForCurrentDate(
                                        moment.tz(
                                          $record.evening_dropoff_scheduled_open_time,
                                          'LT',
                                          timezone
                                        )
                                      ),
                                      till: formatForCurrentDate(
                                        moment.tz(
                                          $record.evening_dropoff_scheduled_close_time,
                                          'LT',
                                          timezone
                                        )
                                      ),
                                    }
                                  : {
                                      penalty: eveningPenalty,
                                      from: $record.evening_dropoff_from_ts
                                        ? timeForCurrentDate(
                                            $record.evening_dropoff_from_ts
                                          )
                                        : formatForCurrentDate(
                                            moment($eveningPickupTime)
                                              // .add(5, 'minutes')
                                              .tz(timezone)
                                          ),
                                      till: $record.evening_dropoff_till_ts
                                        ? timeForCurrentDate(
                                            $record.evening_dropoff_till_ts
                                          )
                                        : formatForCurrentDate(
                                            moment($eveningPickupTime)
                                              .add(
                                                maxTripDurationInMinutes,
                                                'minutes'
                                              )
                                              .tz(timezone)
                                          ),
                                    },
                            },
                      };
                      $D2.S.INFO('$times', {
                        ...A,
                        $record,
                        $times,
                      });

                      // if (
                      //   $times.morning.pickup.from === 'Invalid date' ||
                      //   $times.morning.pickup.till === 'Invalid date' ||
                      //   $times.evening.pickup.from === 'Invalid date' ||
                      //   $times.evening.pickup.till === 'Invalid date' ||
                      //   $times.morning.dropoff.from === 'Invalid date' ||
                      //   $times.morning.dropoff.till === 'Invalid date' ||
                      //   $times.evening.dropoff.from === 'Invalid date' ||
                      //   $times.evening.dropoff.till === 'Invalid date'
                      // ) {
                      //   D2.S.INFO('bookings:$times:Invalid', {
                      //     ...A,
                      //     $record,
                      //     $times
                      //   });
                      //   throw new Error('Invalid date');
                      // }

                      if (
                        $times.morning &&
                        moment($times.morning.pickup.till).valueOf() -
                          moment($times.morning.pickup.from).valueOf() <
                          0
                      ) {
                        throw new Error(
                          // eslint-disable-next-line
                          `Morning pickup time-window error: ${$times.morning.pickup.from} must be before ${$times.morning.pickup.till}`
                        );
                      }

                      if (
                        $times.morning &&
                        moment($times.morning.dropoff.till).valueOf() -
                          moment($times.morning.dropoff.from).valueOf() <
                          0
                      ) {
                        throw new Error(
                          // eslint-disable-next-line
                          `Morning dropoff time-window error: ${$times.morning.dropoff.from} must be before ${$times.morning.dropoff.till}`
                        );
                      }

                      if (
                        $times.evening &&
                        moment($times.evening.pickup.till).valueOf() -
                          moment($times.evening.pickup.from).valueOf() <
                          0
                      ) {
                        throw new Error(
                          // eslint-disable-next-line
                          `Evening pickup time-window error: ${$times.evening.pickup.from} must be before ${$times.evening.pickup.till}`
                        );
                      }

                      if (
                        $times.evening &&
                        moment($times.evening.dropoff.till).valueOf() -
                          moment($times.evening.dropoff.from).valueOf() <
                          0
                      ) {
                        throw new Error(
                          // eslint-disable-next-line
                          `Evening dropoff time-window error: ${$times.evening.dropoff.from} must be before ${$times.evening.dropoff.till}`
                        );
                      }

                      const newBookingBase = {
                        uid,
                        name,
                        tags,
                        passenger_uid: $record.uid,
                        passenger_name: $record.name,
                        passenger_zip_code: $record.zip_code,
                        $times,
                        $record,
                      };

                      const $centerTime = isMorning
                        ? $morningDropoffTime
                        : $eveningPickupTime;

                      if (!$centerTime) {
                        return bookingsAcc;
                      }

                      const newBooking = isMorning
                        ? {
                            ...newBookingBase,
                            groups: morning_groups,
                            geofence_id: morning_geofence_id,
                            pickup_location_lon: srcPoint.coordinates[0],
                            pickup_location_lat: srcPoint.coordinates[1],
                            pickup_location_name: srcPoint.address,
                            pickup_time: null,
                            dropoff_location_lon: dstPoint.lon,
                            dropoff_location_lat: dstPoint.lat,
                            dropoff_location_name: dstPoint.street,
                            dropoff_time: $morningDropoffTime,
                          }
                        : {
                            ...newBookingBase,
                            groups: evening_groups,
                            geofence_id: evening_geofence_id,
                            dropoff_location_lon: srcPoint.coordinates[0],
                            dropoff_location_lat: srcPoint.coordinates[1],
                            dropoff_location_name: srcPoint.address,
                            dropoff_time: null,
                            pickup_location_lon: dstPoint.lon,
                            pickup_location_lat: dstPoint.lat,
                            pickup_location_name: dstPoint.street,
                            pickup_time: $eveningPickupTime,
                          };

                      return {
                        ...bookingsAcc,
                        [uid]: newBooking,
                      };
                    }
                  ),
                {}
              );
              D2.S.INFO('bookings', {
                ...A,
                bookings,
              });

              if (!Object.keys(bookings).length) {
                return offersByDestinationTimesAcc;
              }

              const rejected_bookings = Object.keys(bookings).map(uid => ({
                uid,
                // assigned_vehicle_id: null,
                scheduled_dropoff_stop_id: null,
                scheduled_dropoff_time: null,
                scheduled_pickup_stop_id: null,
                scheduled_pickup_time: null,
              }));
              D2.S.INFO('rejected_bookings', {
                ...A,
                rejected_bookings,
              });

              const nodes = Object.keys(bookings).reduce((nodesAcc, uid) => {
                const booking = bookings[uid];
                const srcPoint = booking.$record.home.$buildings[0];
                const dstPoint = booking.$record.office.$buildings[0];

                const nodeBase = {
                  trip_cost: 0, // booking.$record.trip_cost,
                  weight: 0,
                  walking_distance_to_node: 0,
                  max_trip_duration: booking.$record.max_trip_duration,
                  booking_uid: booking.uid,
                  groups: booking.groups,
                  geofence_id: booking.geofence_id,
                };

                const pickNodeUid = human_readable_uids
                  ? `${booking.uid} #Pickup`
                  : uuidv4();

                // const specialDemandsPickup =
                //   areSpecialDemandsEnabled && booking.$record.special_demand ? {
                //     [booking.$record.special_demand]: 1
                //   } : {};

                // const pickupNodeDemands = {
                //   ...specialDemandsPickup,
                //   wheelchair: booking.$record.wheelchair ? 1 : undefined,
                //   passenger: 1
                // };

                const pickupNodeDemands = !booking.$record.wheelchair
                  ? ambulantOneDemand
                  : wheelchairOneDemand;

                const pickupNodeBase = {
                  ...nodeBase,
                  demand: generateDailyNodeDemands
                    ? commuteSchedule$GenerateDailyNodeDemands(
                        booking,
                        pickupNodeDemands
                      )
                    : pickupNodeDemands,
                  uid: pickNodeUid,
                };

                const $bookingTimes = isMorning
                  ? booking.$times.morning
                  : booking.$times.evening;

                if ($bookingTimes) {
                  const pickupNode = isMorning
                    ? {
                        ...pickupNodeBase,
                        lon: srcPoint.nearestPoint.lon,
                        lat: srcPoint.nearestPoint.lat,
                        location_name: srcPoint.nearestPoint.street,
                        stop_id: srcPoint.nearestPoint.id,
                        open_time_ts: booking.$times.morning.pickup.from,
                        close_time_ts: booking.$times.morning.pickup.till,
                        close_time_ts_dynamic:
                          booking.$times.morning.pickup.till,
                        penalty: booking.$times.morning.pickup.penalty,
                        service_time: booking.$record.home_service_time,
                        node_type: 'pickup',
                      }
                    : {
                        ...pickupNodeBase,
                        lon: dstPoint.nearestPoint.lon,
                        lat: dstPoint.nearestPoint.lat,
                        location_name: dstPoint.nearestPoint.street,
                        stop_id: dstPoint.nearestPoint.id,
                        open_time_ts: booking.$times.evening.pickup.from,
                        close_time_ts: booking.$times.evening.pickup.till,
                        close_time_ts_dynamic:
                          booking.$times.evening.pickup.till,
                        penalty: booking.$times.evening.pickup.penalty,
                        service_time: booking.$record.center_service_time,
                        node_type: 'pickup',
                        // max_slack: 0
                      };
                  nodesAcc.push(pickupNode);
                }

                const dropoffNodeUid = human_readable_uids
                  ? `${booking.uid} #Dropoff`
                  : uuidv4();

                // const specialDemandsDropoff =
                //   areSpecialDemandsEnabled && booking.$record.special_demand ? {
                //     [booking.$record.special_demand]: -1
                //   } : {};
                // const dropoffNodeDemands = {
                //   ...specialDemandsDropoff,
                //   wheelchair: booking.$record.wheelchair ? -1 : undefined,
                //   passenger: -1
                // };

                const dropoffNodeDemands = Object.keys(
                  pickupNodeDemands
                ).reduce(
                  (memo, key) => ({
                    ...memo,
                    [key]: -pickupNodeDemands[key],
                  }),
                  {}
                );

                const dropoffNodeBase = {
                  ...nodeBase,
                  demand: generateDailyNodeDemands
                    ? commuteSchedule$GenerateDailyNodeDemands(
                        booking,
                        dropoffNodeDemands
                      )
                    : dropoffNodeDemands,
                  uid: dropoffNodeUid,
                };

                if ($bookingTimes) {
                  const dropoffNode = isMorning
                    ? {
                        ...dropoffNodeBase,
                        lon: dstPoint.nearestPoint.lon,
                        lat: dstPoint.nearestPoint.lat,
                        location_name: dstPoint.nearestPoint.street,
                        stop_id: dstPoint.nearestPoint.id,
                        open_time_ts: booking.$times.morning.dropoff.from,
                        close_time_ts: booking.$times.morning.dropoff.till,
                        close_time_ts_dynamic:
                          booking.$times.morning.dropoff.till,
                        penalty: booking.$times.morning.dropoff.penalty,
                        service_time: booking.$record.center_service_time,
                        node_type: 'dropoff',
                        // max_slack: 0
                      }
                    : {
                        ...dropoffNodeBase,
                        lon: srcPoint.nearestPoint.lon,
                        lat: srcPoint.nearestPoint.lat,
                        location_name: srcPoint.nearestPoint.street,
                        stop_id: srcPoint.nearestPoint.id,
                        open_time_ts: booking.$times.evening.dropoff.from,
                        close_time_ts: booking.$times.evening.dropoff.till,
                        close_time_ts_dynamic:
                          booking.$times.evening.dropoff.till,
                        penalty: booking.$times.evening.dropoff.penalty,
                        service_time: booking.$record.home_service_time,
                        node_type: 'dropoff',
                      };
                  nodesAcc.push(dropoffNode);
                }
                return nodesAcc;
              }, []);
              D2.S.INFO('nodes', {
                ...A,
                nodes,
              });

              const todaySpecialDemandSet = dataForGeneration.reduce(
                (acc, person) => {
                  if (person.special_demand) {
                    acc.add(person.special_demand);
                  }
                  return acc;
                },
                new Set([undefined])
              );
              const todaySpecialDemands = [...todaySpecialDemandSet.values()];
              D2.S.INFO('todaySpecialDemands', {
                ...A,
                todaySpecialDemands,
              });

              const vehiclesForSpecialDemands =
                areSpecialDemandsEnabled && !externalOffer
                  ? todaySpecialDemands
                      .filter(x => x)
                      .map((specialDemand) => {
                        const currentSpecialDemandTag = `#SD:${specialDemand}`;

                        const newVehicleBase = JSON.parse(
                          silverayVehicleTemplateJSON
                        );
                        const newVehicleUID = human_readable_uids
                          ? [
                              moment(currentDate).format('YYYY-MM-DD #ddd'),
                              currentDeliveryTypeTag,
                              currentSpecialDemandTag,
                            ]
                              .filter(x => x)
                              .join(' ')
                          : uuidv4();
                        const newVehicle = isMorning
                          ? {
                              ...newVehicleBase,
                              start_time: formatForCurrentDate(
                                moment
                                  .tz('08:30 AM', 'LT', timezone)
                                  .add(-180, 'minutes')
                              ),
                              end_time: formatForCurrentDate(
                                moment.tz('12:30 PM', 'LT', timezone)
                              ),
                              agent_id: newVehicleUID,
                              vehicle_color: crc32(newVehicleUID) % 360,
                              routing_engine: {
                                ...newVehicleBase.routing_engine,
                                osrme_timestamp_mode: 'end_time',
                              },
                              capacity: {
                                ...newVehicleBase.capacity,
                                passenger: vehicle_passenger,
                                wheelchair: vehicle_wheelchair,
                              },
                            }
                          : {
                              ...newVehicleBase,
                              start_time: formatForCurrentDate(
                                moment.tz('12:30 PM', 'LT', timezone)
                              ),
                              end_time: formatForCurrentDate(
                                moment
                                  .tz('17:30 PM', 'LT', timezone)
                                  .add(180, 'minutes')
                              ),
                              agent_id: newVehicleUID,
                              vehicle_color: crc32(newVehicleUID) % 360,
                              routing_engine: {
                                ...newVehicleBase.routing_engine,
                                osrme_timestamp_mode: 'start_time',
                              },
                              capacity: {
                                ...newVehicleBase.capacity,
                                passenger: vehicle_passenger,
                                wheelchair: vehicle_wheelchair,
                              },
                            };
                        const resultVehicle = {
                          ...newVehicle,
                          capacity: {
                            ...newVehicle.capacity,
                            [specialDemand]: vehicle_passenger,
                          },
                        };
                        return resultVehicle;
                      })
                  : [];
              D2.S.INFO('vehiclesForSpecialDemands', {
                ...A,
                vehiclesForSpecialDemands,
              });

              const vehiclesForBookings = !externalOffer
                ? generateVehiclesForEachBooking(bookings, {
                    areSpecialDemandsEnabled,
                    human_readable_uids,
                    currentDate,
                    currentDestinationTimeName,
                    currentDeliveryTypeTag,
                    isMorning,
                    timezone,
                    vehicle_passenger,
                    vehicle_wheelchair,
                    unassigned_only,
                  })
                : [];
              D2.S.INFO('vehiclesForBookings', {
                ...A,
                vehiclesForBookings,
              });

              const vehicles = [
                ...vehiclesForBookings,
                ...vehiclesForSpecialDemands,
              ];
              D2.S.INFO('vehicles', {
                ...A,
                vehicles,
              });

              const tags = [
                `#${moment(currentDate).format('YYYY-MM-DD')}`,
                `#${moment(currentDate).format('ddd')}`,
                currentDeliveryTypeTag,
                currentDestinationTimeTag,
                currentGroupTag,
              ];

              const mutually_exclusive_groups =
                areMutuallyExclusiveGroupsEnabled
                  ? [
                      [
                        ...dataForGeneration
                          .reduce((acc, person) => {
                            D2.S.INFO('mutually_exclusive_groups:person', {
                              person,
                            });
                            if (generateGroupIDs && person.morning_dropoff_ts) {
                              acc.add(
                                moment
                                  .tz(person.morning_dropoff_ts, 'LT', timezone)
                                  .format('HH:mm')
                              );
                            }
                            if (generateGroupIDs && person.evening_pickup_ts) {
                              acc.add(
                                moment
                                  .tz(person.evening_pickup_ts, 'LT', timezone)
                                  .format('HH:mm')
                              );
                            }
                            if (person.morning_mutually_exclusive_group) {
                              acc.add(person.morning_mutually_exclusive_group);
                            }
                            if (person.evening_mutually_exclusive_group) {
                              acc.add(person.evening_mutually_exclusive_group);
                            }
                            return acc;
                          }, new Set())
                          .keys(),
                      ].filter(x => !!x),
                    ]
                  : undefined;
              D2.S.INFO('mutually_exclusive_groups', {
                mutually_exclusive_groups,
              });

              const newOfferBase = externalOffer
                ? deepmerge(JSON.parse(emptyCommuteOfferJSON), externalOffer)
                : JSON.parse(emptyCommuteOfferJSON);
              const newOffer = {
                ...newOfferBase,
                stateless_api_request_data: {
                  ...newOfferBase.stateless_api_request_data,
                  current_time: formatForCurrentDate(
                    moment.tz('00:00 AM', 'LT', timezone)
                  ),
                  engine_settings: {
                    ...engineSettingsBase,
                    model_parameters: {
                      ...engineSettingsBase.model_parameters,
                      max_slack,
                      optimize_quantity,
                      mutually_exclusive_groups,
                    },
                    solver_parameters: {
                      ...engineSettingsBase.solver_parameters,
                      time_limit_ms,
                      first_solution_strategy,
                    },
                  },
                  bookings,
                  nodes,
                  vehicles,
                },
                result: {
                  ...newOfferBase.result,
                  rejected_bookings,
                },
                tags,
                name: tags.join(' '),
              };
              D2.S.INFO('newOffer', {
                ...A,
                newOffer,
              });

              return [...offersByDestinationTimesAcc, newOffer];
            }, []);
            D2.S.INFO('offersByDestinationTimes', {
              ...A,
              offersByDestinationTimes,
            });

            return [...offersByDeliveryTypesAcc, ...offersByDestinationTimes];
          },
          []
        );
        D2.S.INFO('offersByDeliveryTypes', {
          ...A,
          offersByDeliveryTypes,
        });

        return [...offersByGroupsAcc, ...offersByDeliveryTypes];
      },
      []
    );
    D2.S.INFO('Success', { ...A, offersByGroups });

    return offersByGroups;
  } catch (error) {
    D2.S.INFO('Failure', { error });
    throw error;
  }
};

import { useWindowSize } from '../hooks';

const withWindowSize = (WrappedComponent) => {
  const WindowSize = (props) => {
    const { windowSize } = useWindowSize();

    return <WrappedComponent {...props} windowSize={windowSize} />;
  };

  return WindowSize;
};

export default withWindowSize;

import styled from 'styled-components';
import arrow from 'assets/arrow_login.svg';

const Container = styled.button`
  display: block;
  position: absolute;
  right: -90px;
  bottom: 150px;

  width: 45px;
  height: 45px;
  background: #f9603a url(${arrow}) center no-repeat;

  border: none;
  border-radius: 50px;

  font-size: 35px;
  color: #ffffff;
  outline: none;

  opacity: ${({ isValid }) => (isValid ? 1 : 0)};
  transition: 0.5s;

  &:hover {
    opacity: ${({ isValid }) => (isValid ? 0.8 : 0)};
    cursor: pointer;
  }
`;

export default Container;

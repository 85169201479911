import React from 'utils/react';
import { useState, useEffect, useCallback } from 'react';

import useTranslation from 'utils/react/useTranslation';
import moment from 'moment-timezone';

import { DatePicker } from 'baseui/datepicker';
import { SIZE } from 'baseui/input';
import { toaster } from 'baseui/toast';
import ja from 'date-fns/locale/ja';

import Container from './Container';
import { useHistory } from 'react-router';
import { LOGISTICS_ROUTE } from 'utils/constants';

import debug from 'utils/debug';
const D2 = debug('p:Logistics:DateSelector');

const resources = {
  jp: ja,
  ja,
};

const formatStringsData = {
  jp: 'y年 M月 d日',
  en: 'dd/MM/yyyy',
  th: 'dd/MM/yyyy',
};

const formatStrings = {
  ...formatStringsData,
  ja: formatStringsData.jp,
};

// This is a workaround to fix a chrome issue on macOS Sonoma
// https://support.google.com/chrome/thread/231926653?hl=en&sjid=15661418646126724117-AP
const date = new Date();
const timeZoneOffsetMinutes = date.getTimezoneOffset();
const timeZoneOffsetHours = timeZoneOffsetMinutes / 60;
const timeZone =
  'Etc/GMT' +
  (timeZoneOffsetHours > 0 ? '+' : '-') +
  Math.abs(timeZoneOffsetHours).toString();

// Note: DateSelector component only use date format string as input/ouput (DD/MM/YYYY). All timezone here are BROWSER_TIMEZONE
const BROWSER_TIMEZONE =
  Intl.DateTimeFormat().resolvedOptions().timeZone || timeZone;

const DateSelector = (props) => {
  const {
    isBookingsHidden,
    isServiceDataSource,
    serviceDate,
    setServiceDate,
    commuteOfferRequestUpdate,
    pageAddress,
    finishOfferLoading,
  } = props;

  const { t, i18n } = useTranslation();

  const { language } = i18n;

  const localeOverride = resources[language];
  const formatString = formatStrings[language];

  const [selectedDates, setSelectedDates] = useState(
    serviceDate ? [new Date(serviceDate)] : [new Date()]
  );

  const [orientation, setOrientation] = useState('vertical');

  const [isDateChanged, setIsDateChanged] = useState(false);

  const history = useHistory();
  const handleOnClick = useCallback(
    (date) => {
      return history.push(
        pageAddress({
          page: history?.location?.pathname,
          serviceDate: moment(date).tz(BROWSER_TIMEZONE).format('YYYY-MM-DD'),
          ...D2.CONTEXT,
        })
      );
    },
    [history]
  );

  useEffect(() => {
    if (finishOfferLoading && isDateChanged) {
      const selectedDate = selectedDates[0];
      toaster.info(
        <>
          {t('toaster.DateChanged', {
            date: moment(selectedDate)
              .tz(BROWSER_TIMEZONE)
              .format('DD MMM YYYY'),
          })}
        </>,
        {
          autoHideDuration: 4000,
          closeable: false,
        }
      );
      setIsDateChanged(false);
    }
  }, [finishOfferLoading]);

  useEffect(() => {
    const selectedDate = selectedDates[0];
    const date = selectedDate.toLocaleDateString('lt-LT');
    // console.log('*** Selected Date:', { date, selectedDate });
    setServiceDate(date);
    // TODO: Force update
    // commuteOfferRequestUpdate(null, { isInitRequired: false, ...D2.CONTEXT });
  }, [selectedDates, commuteOfferRequestUpdate, setServiceDate]);

  useEffect(() => {
    if (window.location.pathname !== `${LOGISTICS_ROUTE}/template`) {
      handleOnClick(selectedDates[0]);
    }
  }, [handleOnClick, selectedDates]);

  const onDateChanged = value =>
    D2.S.FUNCTION('onDateChanged', { value }, () => {
      setSelectedDates([value]);
      setIsDateChanged(true);
    });

  return isServiceDataSource ? (
    <Container isBookingsHidden={isBookingsHidden}>
      <DatePicker
        value={selectedDates}
        onChange={({ date }) =>
          Array.isArray(date) ? onDateChanged(date[0]) : onDateChanged(date)
        }
        locale={localeOverride}
        formatString={formatString}
        placeholder={formatString}
        mask={null}
        size={SIZE.compact}
        orientation={orientation}
        onMonthChange={() => {
          setOrientation('horizontal');
          setTimeout(() => setOrientation('vertical'));
        }}
      />
    </Container>
  ) : null;
};

export default DateSelector;

import React from 'react';
import styled from 'styled-components';

const StatusBar = styled.div`
  display: block;
  background-color: #080d14;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  z-index: 2;
`;

export default (props) => {
  return <StatusBar {...props} />;
};

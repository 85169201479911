import withTranslation from 'utils/react/withTranslation';

import { connect } from 'react-redux';
import { ActionCreators } from 'redux-undo';
import createImmutableSelector from 'utils/immutable-selector';
import {
  serialNumberSelector,
  filteredVehiclesSelector,
  commuteOfferIsReadOnlySelector,
  commuteOfferLoadedSimulationSelector,
  commuteOfferIsFilteringEnabledSelector,
} from 'modules/commuteOffer/selectors';
import {
  isDeliveryLayoutSelector,
  currentProjectConfigSelector,
  canManageVehiclesSelector,
} from 'modules/user/selectors';
import { commuteOffersIsServiceDataSourceSelector } from 'modules/ui/selectors';
import {
  newVehicle,
  commuteOfferRequestUpdate,
} from 'modules/commuteOffer/actions';

import { openPopup } from 'modules/ui/actions';

import Vehicles from './Vehicles';

const mapStateToProps = createImmutableSelector(
  serialNumberSelector,
  filteredVehiclesSelector,
  commuteOfferIsReadOnlySelector,
  isDeliveryLayoutSelector,
  commuteOfferLoadedSimulationSelector,
  currentProjectConfigSelector,
  canManageVehiclesSelector,
  commuteOffersIsServiceDataSourceSelector,
  commuteOfferIsFilteringEnabledSelector,
  (
    serialNumber,
    vehicles,
    commuteOfferIsReadOnly,
    isDeliveryLayout,
    simulation,
    projectConfig,
    canManageVehicles,
    isServiceDataSource,
    isFilteringEnabled
  ) => ({
    serialNumber,
    vehicles,
    commuteOfferIsReadOnly,
    isDeliveryLayout,
    simulation,
    projectConfig,
    canManageVehicles,
    isServiceDataSource,
    isFilteringEnabled,
  })
);

const mapDispatchToProps = {
  newVehicle,
  openPopup,
  commuteOfferRequestUpdate,
  back: ActionCreators.undo,
  next: ActionCreators.redo,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation()(withConnect(Vehicles));

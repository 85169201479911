import { styled } from 'baseui';

const Error = styled('span', ({ $theme }) => {
  const colors = $theme?.colors;

  return {
    margin: '2px 0 0 0',
    color: colors.negative,
    outline: 'none',
  };
});

export default Error;

const fetchObject = async (api, collection, id) => {
  try {
    const { fetch, address, prefix } = api;

    const fetchUrl = [[address, prefix].join(''), collection, id].join('/');

    const response = await fetch(fetchUrl);

    if (!response.ok) {
      const { status } = response;
      return { error: { status, response } };
    }

    const object = await response.json();

    return { object };
  } catch (exception) {
    if (global.GEODISC_DEBUG_ENABLED) {
      // eslint-disable-next-line
      console.log(exception);
    }
    return { error: { exception } };
  }
};

export default fetchObject;

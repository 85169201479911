import Immutable from 'immutable';
import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';

import debug from 'utils/debug';
import datasetsReducer from './datasets';
import geofencesReducer from './geofences';
import simulationsReducer from './simulations';
import commuteOfferReducer from './commuteOffer';
import entitiesReducer from './entities';
import uiReducer from './ui';
import userReducer from './user';
import mapsReducer from './maps';
import fleetReducer from './fleet';

const D2 = debug('m:index');

const initialStateDebug = Immutable.fromJS({});

const reducer = history =>
  combineReducers({
    debug: (state = initialStateDebug, { type, payload }) => {
      D2.S.DEBUG(`reducer:${type}`, payload, state);
      return state;
    },
    router: connectRouter(history),
    geofences: geofencesReducer,
    datasets: datasetsReducer,
    simulations: simulationsReducer,
    commuteOffer: commuteOfferReducer,
    entities: entitiesReducer,
    ui: uiReducer,
    user: userReducer,
    maps: mapsReducer,
    fleet: fleetReducer,
  });

export default reducer;

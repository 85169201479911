import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  position: fixed;
  left: 350px;
  bottom: 0;
  width: 470px;
  height: 70px;
  background: rgba(54, 61, 74, 0.5);
  align-items: center;
`;

export default Container;

import styled from 'styled-components';

const VehicleNameWrapper = styled.div`
  display: flex;
  white-space: nowrap;
  gap: 4px;
  align-items: center;
`;

VehicleNameWrapper.defaultProps = {
  'data-testid': 'VehicleNameWrapper',
};

export default VehicleNameWrapper;

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import createImmutableSelector from 'utils/immutable-selector';

import { fetchCommuteOffers } from 'modules/commuteOffer/actions';

import {
  toggleSelectedCommuteOffers,
  clearSelectedCommuteOffers,
} from 'modules/ui/actions';
import {
  commuteOfferListSelector,
  countSelector,
} from 'modules/commuteOffer/selectors';
import { routePageSelector } from 'modules/router/selectors';

import Table from './Table';

const mapStateToProps = createImmutableSelector(
  commuteOfferListSelector,
  countSelector,
  routePageSelector,
  (list, count, page) => ({
    list,
    count,
    page,
  })
);

const mapDispatchToProps = {
  toggleSelectedCommuteOffers,
  clearSelectedCommuteOffers,
  fetchCommuteOffers,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const enhance = compose(withConnect, withRouter);

export default enhance(Table);

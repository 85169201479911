import regexValidation from 'utils/forms';
import useTranslation from 'utils/react/useTranslation';

import HighestLoadPopoverImg from './PopoverImg/highest_load.png';
import AfterPickupLoadPopoverImg from './PopoverImg/after_pickup_load.png';
import AfterDropoffLoadPopoverImg from './PopoverImg/after_dropoff_load.png';
import PickupAddressOnlyPopoverImg from './PopoverImg/pickup_address_only.png';
import DropoffAddressOnlyPopoverImg from './PopoverImg/dropoff_address_only.png';
import BothAddressPopoverImg from './PopoverImg/both_pickup_dropoff_address.png';

const getSchema = () => {
  const { t } = useTranslation();
  return {
    properties: {
      display_highest_load_for_each_vehicle: {
        type: 'boolean',
        title: t(
          'p.advanceSettings.display_highest_load_for_each_vehicle.label'
        ),
        info: `<img src=${HighestLoadPopoverImg} alt="." />`,
      },
      display_vehicle_load_for_pickup: {
        type: 'boolean',
        title: t('p.advanceSettings.display_vehicle_load_for_pickup.label'),
        info: `<img src=${AfterPickupLoadPopoverImg} alt="." />`,
      },
      display_vehicle_load_for_dropoff: {
        type: 'boolean',
        title: t('p.advanceSettings.display_vehicle_load_for_dropoff.label'),
        info: `<img src=${AfterDropoffLoadPopoverImg} alt="." />`,
      },
      orders_display: {
        type: 'radio',
        title: t('p.advanceSettings.orders_display.label'),
        options: [
          {
            label: t('p.advanceSettings.orders_display.option.pickup'),
            label_sub: t('p.advanceSettings.orders_display.option_rec.pickup'),
            value: 'pickup_only',
            info: `<img src=${PickupAddressOnlyPopoverImg} alt="." />`,
          },
          {
            label: t('p.advanceSettings.orders_display.option.dropoff'),
            label_sub: t('p.advanceSettings.orders_display.option_rec.dropoff'),
            value: 'dropoff_only',
            info: `<img src=${DropoffAddressOnlyPopoverImg} alt="." />`,
          },
          {
            label: t(
              'p.advanceSettings.orders_display.option.pickup_and_dropoff'
            ),
            label_sub: t(
              'p.advanceSettings.orders_display.option_rec.pickup_and_dropoff'
            ),
            value: 'pickup_and_dropoff',
            info: `<img src=${BothAddressPopoverImg} alt="." />`,
          },
        ],
      },
      trip_cost: {
        type: 'string',
        title: t('p.advanceSettings.trip_cost.label'),
        info: t('p.advanceSettings.trip_cost.info'),
        validations: {
          maxLength: {
            value: 20,
            message: t('u.validation.maxLength', { count: 20 }),
          },
          pattern: {
            value: regexValidation?.decimalNumberRegex,
            message: t('u.validation.isPositiveNumber'),
          },
        },
      },
      algo_optimize_quantity: {
        type: 'string',
        title: t('p.advanceSettings.algo_optimize_quantity.label'),
        info: t('p.advanceSettings.algo_optimize_quantity.info'),
        options: [
          { id: null, label: '' },
          { id: 'total_time', label: 'total_time' },
          { id: 'total_distance', label: 'total_distance' },
        ],
        validations: {
          maxLength: {
            value: 20,
            message: t('u.validation.maxLength', { count: 20 }),
          },
        },
      },
      allow_upload_after_simulation_start_time: {
        type: 'boolean',
        title: t(
          'p.advanceSettings.allow_upload_after_simulation_start_time.label'
        ),
        info: t(
          'p.advanceSettings.allow_upload_after_simulation_start_time.info'
        ),
        validations: {
          maxLength: {
            value: 20,
            message: t('u.validation.maxLength', { count: 20 }),
          },
        },
      },
      first_solution_strategies: {
        type: 'string',
        title: t('p.advanceSettings.first_solution_strategies.label'),
        validations: {
          maxLength: {
            value: 20,
            message: t('u.validation.maxLength', { count: 20 }),
          },
          pattern: {
            value: regexValidation?.isNumberArray,
            message: t('u.validation.isNumberArray'),
          },
        },
      },
      geofence_definition_strategy: {
        type: 'string',
        title: t('p.advanceSettings.geofence_definition_strategy.label'),
        options: [
          { id: null, label: '' },
          { id: 'by_pickup', label: 'by_pickup' },
          { id: 'by_dropoff', label: 'by_dropoff' },
          { id: 'mixed', label: 'mixed' },
          { id: 'independent', label: 'independent' },
        ],
        info: t('p.advanceSettings.geofence_definition_strategy.info'),
        validations: {
          maxLength: {
            value: 20,
            message: t('u.validation.maxLength', { count: 20 }),
          },
        },
      },
      max_dropoff_slack: {
        type: 'number',
        title: t('p.advanceSettings.max_dropoff_slack.label'),
        info: t('p.advanceSettings.max_dropoff_slack.info'),
        validations: {
          maxLength: {
            value: 20,
            message: t('u.validation.maxLength', { count: 20 }),
          },
        },
      },
      max_pickup_slack: {
        type: 'number',
        title: t('p.advanceSettings.max_pickup_slack.label'),
        info: t('p.advanceSettings.max_pickup_slack.info'),
        validations: {
          maxLength: {
            value: 20,
            message: t('u.validation.maxLength', { count: 20 }),
          },
        },
      },
      mutually_exclusive_groups: {
        type: 'string',
        title: t('p.advanceSettings.mutually_exclusive_groups.label'),
        info: t('p.advanceSettings.mutually_exclusive_groups.info'),
        validations: {
          maxLength: {
            value: 50,
            message: t('u.validation.maxLength', { count: 50 }),
          },
          pattern: {
            value: regexValidation?.isNestedStringArray,
            message: t('u.validation.isNestedStringArray'),
          },
        },
      },
      pipeline_type: {
        type: 'string',
        title: t('p.advanceSettings.pipeline_type.label'),
        options: [
          { id: null, label: '' },
          { id: 'simple_one_stage', label: 'simple_one_stage' },
          { id: 'two_stages', label: 'two_stages' },
        ],
      },
      time_limit_ms: {
        type: 'number',
        title: t('p.advanceSettings.time_limit_ms.label'),
        info: t('p.advanceSettings.time_limit_ms.info'),
        validations: {
          valueAsNumber: true,
        },
      },
      use_local_search_metaheuristic: {
        type: 'boolean',
        title: t('p.advanceSettings.use_local_search_metaheuristic.label'),
      },
      vehicle_cost: {
        type: 'string',
        title: t('p.advanceSettings.vehicle_cost.label'),
        info: t('p.advanceSettings.vehicle_cost.info'),
        validations: {
          maxLength: {
            value: 20,
            message: t('u.validation.maxLength', { count: 20 }),
          },
          pattern: {
            value: regexValidation?.decimalNumberRegex,
            message: t('u.validation.isPositiveNumber'),
          },
        },
      },
      geofence_vehicle_allocation_strategy: {
        type: 'string',
        title: t(
          'p.advanceSettings.geofence_vehicle_allocation_strategy.label'
        ),
        info: t('p.advanceSettings.geofence_vehicle_allocation_strategy.info'),
        options: [
          { id: null, label: '' },
          { id: 'flexible', label: 'flexible' },
          { id: 'strict', label: 'strict' },
        ],
      },
    },
  };
};

export default getSchema;

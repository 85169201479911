import Moment from 'moment-timezone';

const fallbackTimezone = 'UTC';

const guessTimezone = () => {
  try {
    return window?.GEODISC_TIMEZONE || Moment.tz.guess() || fallbackTimezone;
  } catch (e) {
    return fallbackTimezone;
  }
};

// eslint-disable-next-line
export const applicationTimezone = guessTimezone();

Moment.tz.setDefault(applicationTimezone);

if (typeof window !== 'undefined') {
  window.GEODISC_MOMENT = Moment;
  window.GEODISC_TIMEZONE = applicationTimezone;
}

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { changeDatasetsOrdering } from 'modules/ui/actions';
import { clearList, fetchDatasets } from 'modules/datasets/actions';

import { datasetsOrderingSelector } from 'modules/ui/selectors';

import Header from './Header';

const mapStateToProps = createImmutableSelector(
  datasetsOrderingSelector,
  ordering => ({
    ordering,
  })
);

const mapDispatchToProps = {
  changeDatasetsOrdering,
  clearList,
  fetchDatasets,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Header);

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { authorization } from 'modules/user/actions';

import {
  userSelector,
  currentProjectSelector,
  currentProjectConfigSelector,
  currentProjectLayoutNameSelector,
  defaultPageSelector,
} from 'modules/user/selectors';
import { setServiceDate } from 'modules/ui/actions';

import App from './App';

const mapStateToProps = createImmutableSelector(
  userSelector,
  currentProjectSelector,
  currentProjectConfigSelector,
  currentProjectLayoutNameSelector,
  defaultPageSelector,
  (user, currentProject, currentProjectConfig, layoutName, defaultPage) => {
    const userName = user?.get('username');
    const isDriver = user?.get('isDriver');
    const authToken = global.localStorage.getItem(global.GEODISC_AUTH_LOGIN);
    const isAuthorized = !!(userName || authToken);

    return {
      isDriver,
      userName,
      authToken,
      isAuthorized,
      currentProject,
      currentProjectConfig,
      layoutName,
      defaultPage,
    };
  }
);

const mapDispatchToProps = {
  authorization,
  setServiceDate,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import {
  currentProjectSelector,
  projectsSelector,
} from 'modules/user/selectors';

import { commuteOffersServiceDateSelector } from 'modules/ui/selectors';
import { routePageAddressSelector } from 'modules/router/selectors';

import { changeCurrentProject } from 'modules/user/actions';

import ProjectMenu from './ProjectMenu';

const mapStateToProps = createImmutableSelector(
  projectsSelector,
  currentProjectSelector,
  commuteOffersServiceDateSelector,
  routePageAddressSelector,
  (projects, currentProject, serviceDate, logisticPageSelector) => ({
    projects,
    currentProject,
    serviceDate,
    logisticPageSelector,
  })
);

const mapDispatchToProps = {
  changeCurrentProject,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(ProjectMenu);

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';
import { datasetsSearchSelector, isSavingSelector } from 'modules/ui/selectors';

import {
  commuteOfferCurrentDataSelector,
  commuteOfferOriginalDataSelector,
  commuteOfferHistorySelector,
  availableBookingsSelector,
  commuteOfferLoadedSimulationSelector,
} from 'modules/commuteOffer/selectors';
import { confirmMatchedAddress } from 'modules/commuteOffer/actions';
import { updateCommuteOffer } from 'modules/commuteOffer/actions';
import Edit from './Edit';
import { commuteOfferViewportSelector } from 'modules/maps/selectors';
import { changeViewportCommuteOffer } from 'modules/maps/actions';
import { currentProjectSelector } from 'modules/user/selectors';
const mapStateToProps = createImmutableSelector(
  commuteOfferViewportSelector,
  datasetsSearchSelector,
  availableBookingsSelector,
  commuteOfferCurrentDataSelector,
  commuteOfferOriginalDataSelector,
  commuteOfferHistorySelector,
  isSavingSelector,
  currentProjectSelector,
  commuteOfferLoadedSimulationSelector,
  (
    viewport,
    text,
    orders,
    currentOffer,
    originalData,
    history,
    isSaving,
    currentProject,
    simulation
  ) => ({
    viewport,
    text,
    orders,
    currentOffer,
    originalData,
    history,
    isSaving,
    currentProject,
    simulation,
  })
);

const mapDispatchToProps = {
  onViewportChange: changeViewportCommuteOffer,
  confirmMatchedAddress,
  updateCommuteOffer,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Edit);

import { getHeadersObject } from 'api/headers';

import debug from 'utils/debug';

const D2 = debug('utils:api');

const cache = { data: {} };

const global$fetch = global.fetch;

const defaultTimeToLive = 0 * 1000;

const getAPI = () => {
  return {
    address: global.GEODISC_API_URL,
    prefix: '/api/v2',
    type: 'sgerp/v2',
    fetch: async (url, options = {}, ...otherFetchOpts) =>
      D2.A.FUNCTION(
        'fetch',
        { url, options, ...otherFetchOpts },
        async ({ $D2 }) => {
          const { method = 'GET' } = options;
          const current_ts = window.performance.now();
          const cached_data = cache.data[url];

          if (method === 'GET' && cached_data) {
            if (current_ts < cached_data.expiration_ts) {
              $D2.S.INFO('CACHE_HIT', { url });
              return cached_data.response;
            }
          }
          $D2.S.INFO('CACHE_MISS', { url });

          $D2.S.INFO(method, { url, options });
          $D2.S.TRACE($ => $(method, url, options));

          const response = await global$fetch(
            url,
            {
              ...options,
              headers: {
                ...getHeadersObject(),
                ...(options.headers ?? {}),
              },
            },
            ...otherFetchOpts
          );

          if (method === 'GET') {
            cache.data = {
              ...cache.data,
              [url]: {
                response,
                expiration_ts: current_ts + defaultTimeToLive,
              },
            };
          }

          return response;
        }
      ),
    logout: () => {
      global.localStorage.removeItem(global.GEODISC_AUTH_LOGIN);
      global.localStorage.removeItem(global.GEODISC_AUTH_TOKEN);
      const nextAddress = window.location.pathname + window.location.search;
      global.geodisc$setWindowLocation(
        `/login?next=${encodeURIComponent(nextAddress)}`
      );
    },
  };
};

global.geodisc$apiCache = () => cache.data;

export default getAPI;

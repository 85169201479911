import React from 'utils/react';
import md5 from 'blueimp-md5';
import { Layer, Source, MapContext } from '@urbica/react-map-gl';
import { getLayer, getMarkers, getSourceLine } from 'utils';
import debug from 'utils/debug';

const D2 = debug('c:MapSimulation:Route');

class Route extends React.PureComponent {
  state = {};

  componentWillUnmount() {
    D2.S.EVENT('componentWillUnmount');

    if (!this._map) {
      return;
    }
    const { id, i } = this.props;
    const layers = getLayer(id, i);
    layers.forEach((layer) => {
      try {
        this._map.removeLayer(layer.id);
      } catch (e) {
        D2.S.DEBUG(`Warning: Failed to remove layer "${layer.id}"`);
      }
    });
  }

  render() {
    D2.S.EVENT('render');

    const { vehicle, id, index } = this.props;
    const line = getSourceLine(vehicle);
    const sourceId = `current_route_${id}`;

    const result = line && (
      <MapContext.Consumer>
        {(map) => {
          this._map = map;

          D2.S.TRACE($ => $(`[${id}] Line:`, md5(JSON.stringify(line.data))));

          return [
            <Source key={`source_${sourceId}`} id={sourceId} {...line} />,
            ...getLayer(id, index).map(layer => (
              <Layer key={layer.id} {...layer} />
            )),
            ...getMarkers(index, vehicle),
          ];
        }}
      </MapContext.Consumer>
    );
    return result;
  }
}

export default Route;

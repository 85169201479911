import React from 'react';
import { styled } from 'baseui';
import { InputWrapper } from './FormStyles';
import FormFieldLabel from './FormLabel';
import Error from './Error';

export const InputField = styled('input', ({ $theme, $isError }) => {
  const colors = $theme?.colors;
  const border = $isError
    ? {
        border: `1px solid ${colors?.negative}`,
      }
    : { border: `1px solid ${colors?.inputBorder}` };
  return {
    width: '100%',
    height: '52px',
    borderRadius: '4px',
    background: 'none',
    padding: '0 0 0 20px',
    margin: '16px 0 0 0',
    color: '#ffffff',
    outline: 'none',
    ...border,
  };
});

const UnitTag = styled('p', ({ $theme }) => {
  const colors = $theme?.colors;

  return {
    marginLeft: 'auto',
    marginTop: '-35px',
    marginRight: '15px',
    color: colors?.inputBorder,
  };
});

export default ({
  label,
  type,
  name,
  info,
  register,
  errors,
  placeholder,
  width,
  style,
  unit,
}) => {
  const properties = {
    type,
    $isError: errors?.message,
    placeholder,
    ...register,
  };
  const error = errors?.message;

  return (
    <InputWrapper width={width} style={style}>
      <FormFieldLabel label={label} name={name} infoHover={info} />
      <InputField {...properties} />
      {unit && <UnitTag>{unit}</UnitTag>}
      {error && <Error>{error}</Error>}
    </InputWrapper>
  );
};

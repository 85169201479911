import styled from 'styled-components';

const Container = styled.div`
  flex: 1 1;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  * {
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
      border: transparent 1px solid;
      background-color: transparent;
    }
  }

  overflow: -moz-scrollbars-none;
`;

export default Container;

import styled from 'styled-components';

const Container = styled.div`
  margin: 10px;

  :hover {
    color: white;
  }
`;

export default Container;

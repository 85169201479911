import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';
import {
  projectsSelector,
  currentProjectSelector,
  currentProjectIdSelector,
  currentProjectVehicleModelsSelector,
} from 'modules/user/selectors';

import { geofencesSelector } from 'modules/geofences/selectors';

import VehicleEditor from './VehicleEditor';

const mapStateToProps = createImmutableSelector(
  currentProjectSelector,
  currentProjectIdSelector,
  projectsSelector,
  geofencesSelector,
  currentProjectVehicleModelsSelector,
  (
    currentProject,
    currentProjectId,
    projects,
    geofences,
    currentProjectVehicleModels
  ) => ({
    currentProject,
    currentProjectId,
    projects,
    geofences,
    currentProjectVehicleModels,
  })
);

const mapDispatchToProps = null;

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(VehicleEditor);

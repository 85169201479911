import React from 'utils/react';
import withTranslation from 'utils/react/withTranslation';

import Container from './Container';
import Header from './Header';
import Body from './Body';
import Buttons from './Buttons';
import Button from './Button';

class Item extends React.PureComponent {
  onSave = () => {
    const { editableGeofence, saveGeofence } = this.props;
    const { key, name, description, geometry } = editableGeofence;

    const body = JSON.stringify({
      name,
      description,
      geometry,
    });

    saveGeofence(key, body);
  };

  onCancelEdit = () => {
    this.props.setEditableGeofence(null);
  };

  onMouseEnter = () => {
    const { key } = this.props.geofence;
    this.props.changeGeofencesHover(key);
  };

  onMouseLeave = () => {
    this.props.changeGeofencesHover(false);
  };

  render() {
    const {
      t,
      isVisible,
      deleteGeofence,
      updateGeofence,
      toggleVisibilityGeofence,
      updateEditableGeofence,
      setEditableGeofence,
      editableGeofence,
      copyGeofence,
      style,
    } = this.props;

    const creationTypeConfig = ['A', 'F', 'M'].reduce(
      (memo, creation_type) => ({
        ...memo,
        [creation_type]: t(
          `p.Geofences.Panels.Geofence.CreationType.${creation_type}`
        ),
      })
    );

    const { id, creation_type } = this.props.geofence;
    const creationType = creationTypeConfig[creation_type];
    const isEditable = editableGeofence && editableGeofence.id === id;

    const geofence = isEditable ? editableGeofence : this.props.geofence;

    const { name, key, description, density, color, trips, tags } = geofence;

    return (
      <Container
        isEditable={isEditable}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onFocus={this.onMouseEnter}
        style={style}
      >
        <Header
          isVisible={isVisible}
          color={color}
          name={name}
          description={description}
          onChange={updateEditableGeofence}
          isEditable={isEditable}
        />
        <Body
          isVisible={isVisible}
          density={density}
          trips={trips}
          tags={tags}
          creationType={creationType}
        />
        <Buttons
          geofence={geofence}
          geofenceKey={key}
          isVisible={isVisible}
          bookmarked={this.props.geofence.bookmarked}
          deleteGeofence={deleteGeofence}
          updateGeofence={updateGeofence}
          toggleVisibilityGeofence={toggleVisibilityGeofence}
          setEditableGeofence={setEditableGeofence}
          copyGeofence={copyGeofence}
        />
        {isEditable && (
          <React.Fragment>
            <Button onClick={this.onSave}>
              {t('p.Geofences.Panels.Geofence.Editor.Save')}
            </Button>
            <Button onClick={this.onCancelEdit}>
              {t('p.Geofences.Panels.Geofence.Editor.Cancel')}
            </Button>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

export default withTranslation()(Item);

import styled from 'styled-components';

const Container = styled.input`
  display: block;
  position: relative;
  height: 40px;
  width: 100%;
  outline: none;
  border: none;
  font-size: 17px;
  color: rgb(54, 61, 74);
  padding: 10px;
  padding-left: 20px;

  border-radius: 10px;
  background-color: #e9eaec;

  &::placeholder {
    letter-spacing: 0px;
    color: #7a818f;
  }
`;

export default Container;

import { urls } from '../../config';

import { fetchData } from 'api/net';
import { getHeaders } from 'api/headers';

import debug from 'utils/debug';
const D2 = debug('api:CommuteOffer');

const invalidateAllBookings = async originalOffer =>
  D2.A.FUNCTION('invalidateAllBookings', { originalOffer }, async ({ $D2 }) => {
    let allBookingIds = Object.keys(
      originalOffer.stateless_api_request_data.bookings
    );
    const BOOKING_LIMIT = 500;
    const arraysOfBookingIds = [];

    while (allBookingIds.length > 0)
      arraysOfBookingIds.push(allBookingIds.splice(0, BOOKING_LIMIT));

    const bookingsResponses = await Promise.all(
      arraysOfBookingIds.map((bookingIds) => {
        return fetchData(urls.invalidateBookingsAPI, {
          method: 'POST',
          headers: getHeaders(),
          body: JSON.stringify({
            simulation_id: originalOffer.$source.id,
            booking_uids: bookingIds,
            bookings: [],
          }),
          maxAttempts: 1,
        });
      })
    );

    bookingsResponses.forEach((bookingsResponse) => {
      if (bookingsResponse?.error) {
        throw new Error(bookingsResponse.error);
      }
    });
  });

export default invalidateAllBookings;

import React from 'utils/react';

import Layout from 'components/Layout';
import Panel from 'components/Panel';
import Logo from 'components/Logo';

import Header from './Header';
import VisualizationPanel from './VisualizationPanel';
import TimeSlider from './TimeSlider';
import BackButton from './BackButton';
import { useEffect } from 'react';

const Dataset = (props) => {
  const { dataset, id, clearDataset, fetchDataset, fetchDatasetCsv } = props;

  useEffect(() => {
    if (!dataset || dataset.get('id') !== id) {
      clearDataset();
      fetchDataset(id);
      fetchDatasetCsv(id);
    }
  }, []);

  return (
    <Layout>
      <Panel>
        <BackButton to='/datasets'>
          <Logo />
        </BackButton>
        <Header />
        <VisualizationPanel />
        <TimeSlider />
      </Panel>
    </Layout>
  );
};

export default Dataset;

import React from 'utils/react';
import withTranslation from 'utils/react/withTranslation';
import { Formik } from 'formik';

import Input from 'components/Input';
import InputSelect from 'components/InputSelect';
import InputSelectTags from 'components/InputSelectTags';
import SubmitButton from 'components/SubmitButton';
import { datasetsToOptions } from 'utils';

import Row from './Row';
import ButtonsRow from './ButtonsRow';

const FromDataset = ({ t, datasets, projectUrl, addGeofence }) => {
  const onSubmit = (values) => {
    const {
      dataset,
      metric,
      number_of_polygons,
      polygon_vertex_number,
      polygon_type,
      optimization_algo,
      tags,
      ...otherValues
    } = values;

    if (dataset) {
      const body = JSON.stringify({
        dataset_id: dataset.value,
        metric: metric.value,
        tags: tags.map(item => item.value),
        number_of_polygons: number_of_polygons.value,
        polygon_type: polygon_type.value,
        polygon_vertex_number: parseInt(polygon_vertex_number, 10),
        optimization_algo: optimization_algo.value,
        solver_kwargs: {
          maxiter: 200,
          popsize: 10,
        },
        metric_kwargs: {
          min_area: 20 * 1e6,
        },
        project: projectUrl,
        ...otherValues,
      });

      addGeofence(body, true);
    }
  };

  const createOptimizationMetricValue = value => ({
    label: t(`c.ModalWindow.NewGeofence.FromDataset.Metric.${value}`),
    value,
  });

  const createOptimizationAlgoValue = value => ({
    label: t(`c.ModalWindow.NewGeofence.FromDataset.OptimizationAlgo.${value}`),
    value,
  });

  return (
    <Formik
      initialValues={{
        name: null,
        description: null,
        tags: [],
        dataset: null,
        metric: createOptimizationMetricValue('area_density_metric'),
        number_of_polygons: { label: 1, value: 1 },
        polygon_vertex_number: 4,
        polygon_type: { label: 'Regular', value: 0 },
        optimization_algo: createOptimizationAlgoValue(
          'differential_evolution'
        ),
      }}
      onSubmit={onSubmit}
    >
      {(propsFormik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          setFieldTouched,
        } = propsFormik;

        return (
          <form onSubmit={handleSubmit} autoComplete='off'>
            <Row>
              <Input
                name='name'
                type='text'
                label={t('c.ModalWindow.NewGeofence.FromDataset.Name.Label')}
                value={values.name}
                onChange={handleChange}
                handleBlur={handleBlur}
                autoComplete='off'
                required
              />
            </Row>
            <Row>
              <Input
                name='description'
                type='text'
                label={t(
                  'c.ModalWindow.NewGeofence.FromDataset.Description.Label'
                )}
                value={values.description}
                onChange={handleChange}
              />
              <InputSelectTags
                name='tags'
                label={t('c.ModalWindow.NewGeofence.FromDataset.Tags.Label')}
                isMulti
                value={values.tags}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </Row>
            <Row>
              <InputSelect
                id='dataset'
                name='dataset'
                label={t('c.ModalWindow.NewGeofence.FromDataset.Dataset.Label')}
                options={datasetsToOptions(datasets)}
                value={values.dataset}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
              <InputSelect
                id='metric'
                name='metric'
                label={t('c.ModalWindow.NewGeofence.FromDataset.Metric.Label')}
                value={values.metric}
                options={[
                  createOptimizationMetricValue('area_density_metric'),
                  createOptimizationMetricValue('mean_distance_density_metric'),
                ]}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </Row>
            <Row>
              <InputSelect
                id='number_of_polygons'
                name='number_of_polygons'
                type='text'
                label={t(
                  'c.ModalWindow.NewGeofence.FromDataset.Polygons.Label'
                )}
                value={values.number_of_polygons}
                options={[
                  { label: 1, value: 1 },
                  { label: 2, value: 2 },
                ]}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
              <Input
                name='polygon_vertex_number'
                type='text'
                label={t(
                  'c.ModalWindow.NewGeofence.FromDataset.PolygonVertexNumber.Label'
                )}
                value={values.polygon_vertex_number}
                onChange={handleChange}
              />
            </Row>
            <Row>
              <InputSelect
                id='polygon_type'
                name='polygon_type'
                type='text'
                label={t(
                  'c.ModalWindow.NewGeofence.FromDataset.PolygonType.Label'
                )}
                value={values.polygon_type}
                options={[
                  { label: 'Regular', value: 0 },
                  { label: 'Arbitrary', value: 1 },
                ]}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                placeholder=''
              />
              <InputSelect
                id='optimization_algo'
                name='optimization_algo'
                label={t(
                  'c.ModalWindow.NewGeofence.FromDataset.OptimizationAlgo.Label'
                )}
                options={[
                  createOptimizationAlgoValue('differential_evolution'),
                  createOptimizationAlgoValue('powell'),
                  createOptimizationAlgoValue(
                    'powell_and_differential_evolution'
                  ),
                  createOptimizationAlgoValue('simulated_dual_annealing'),
                ]}
                value={values.optimization_algo}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                placeholder=''
              />
            </Row>
            <ButtonsRow>
              <SubmitButton type='submit'>
                {t('c.ModalWindow.NewGeofence.Create')}
              </SubmitButton>
            </ButtonsRow>
          </form>
        );
      }}
    </Formik>
  );
};

export default withTranslation()(FromDataset);

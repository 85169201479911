const postalCodeFunctorsByCountry = {
  SG: (postalCode) => {
    return String(postalCode).padStart(6, '0');
  },
};

const normalizePostalCode = (postalCode, country) => {
  if (!postalCode) {
    return postalCode;
  }
  const functor = postalCodeFunctorsByCountry[country];
  return functor ? functor(postalCode) : postalCode;
};

export default normalizePostalCode;

import React from 'utils/react';

import Container from './Container';
import LoadingPanel from './LoadingPanel';

const Loading = () => (
  <Container>
    <LoadingPanel />
  </Container>
);

export default Loading;

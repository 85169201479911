import moment from 'moment-timezone';
import regexValidation from 'utils/forms';

const isFloat = (value) => {
  return value % 1 !== 0;
};

const isValidDateFormat = (date) => {
  return (
    moment(date, 'DD/MM/YYYY', true).isValid() ||
    moment(date, 'DD/MM/YY', true).isValid()
  );
};
const isValidTimeFormat = (time) => {
  return (
    moment(time, 'HH:mm', true).isValid() ||
    moment(time, 'H:mm', true).isValid() ||
    moment(time, 'HH:mm:ss', true).isValid() ||
    moment(time, 'H:mm:ss', true).isValid() ||
    (!Number.isNaN(Number(time)) && isFloat(Number(time)))
  );
};
const isValidLongitude = lon => Math.abs(lon) <= 180;
const isValidLatitude = lat => Math.abs(lat) <= 90;

const hasValue = field => field === 0 || !!field;

const getDateTime = (date, time) => {
  let datetime = date.clone();

  if (moment(time, 'HH:mm:ss', true).isValid()) {
    datetime = moment(
      datetime.format('DD/MM/YYYY') + ' ' + time,
      'DD/MM/YYYY HH:mm:ss'
    );
  }

  if (moment(time, 'H:mm:ss', true).isValid()) {
    datetime = moment(
      datetime.format('DD/MM/YYYY') + ' ' + time,
      'DD/MM/YYYY H:mm:ss'
    );
  }

  if (moment(time, 'HH:mm', true).isValid()) {
    datetime = moment(
      datetime.format('DD/MM/YYYY') + ' ' + time,
      'DD/MM/YYYY HH:mm'
    );
  }

  if (moment(time, 'H:mm', true).isValid()) {
    datetime = moment(
      datetime.format('DD/MM/YYYY') + ' ' + time,
      'DD/MM/YYYY H:mm'
    );
  }

  if (!Number.isNaN(Number(time)) && isFloat(Number(time))) {
    datetime = datetime.startOf('d').add(Number(time));
  }
  return datetime;
};

export const validateCSV = (
  jsData,
  existExternalIdList,
  simulation,
  timezone,
  vehicleModels
) => {
  // Validate results
  // Must have at least one column filled up for both pickup and addresses
  const importErrors = {
    missingIdErrors: [],
    duplicateIdCsvErrors: [],
    duplicateIdErrors: [],
    missingEarliestPickupTimeErrors: [],
    missingLastestPickupTimeErrors: [],
    missingEarliestDropoffTimeErrors: [],
    missingLastestDropoffTimeErrors: [],
    missingPickupAddressRowIndices: [],
    missingDropoffAddressRowIndices: [],
    earlyPickupDateErrors: [],
    earliestPickupDateFormatErrors: [],
    lastestPickupDateFormatErrors: [],
    earliestDropoffDateFormatErrors: [],
    lastestDropoffDateFormatErrors: [],
    earliestPickupTimeFormatErrors: [],
    lastestPickupTimeFormatErrors: [],
    earliestDropoffTimeFormatErrors: [],
    lastestDropoffTimeFormatErrors: [],
    earliestPickupTimeErrors: [],
    lastestPickupTimeErrors: [],
    lastestDropoffTimeErrors: [],
    pickupLatErrors: [],
    pickupLonErrors: [],
    dropoffLatErrors: [],
    dropoffLonErrors: [],
    vehicleLabelErrors: [],
    vehicleOperatorErrors: [],
    penaltyErrors: [],
    endOfTrip: [],
  };

  const jsDataWithDates = [];
  const tempExternalIdList = [];

  let allVehicleLabels = [];
  vehicleModels?.map((model) => {
    allVehicleLabels = [
      ...allVehicleLabels,
      ...(model?.vehicle_labels ? model?.vehicle_labels : []),
    ];
  });

  const vehicleLabelsSet = new Set(allVehicleLabels);

  jsData.forEach((data, index) => {
    const {
      external_id,
      pickup_address,
      pickup_address2,
      pickup_zip_code,
      dropoff_address,
      dropoff_address2,
      dropoff_zip_code,
      pickup_open_date_ts,
      pickup_close_date_ts,
      dropoff_open_date_ts,
      dropoff_close_date_ts,
      pickup_open_time_ts,
      pickup_close_time_ts,
      dropoff_open_time_ts,
      dropoff_close_time_ts,
      pickup_location_lat,
      pickup_location_lon,
      dropoff_location_lat,
      dropoff_location_lon,
      vehicle_labels,
      penalty,
      is_pickup_end_of_trip,
    } = data;
    let hasDateTimeError = false;
    const rowNumber = index + 2;
    const simulationDate = moment(simulation.start_time).tz(timezone);

    const earliestPickupDate = pickup_open_date_ts
      ? moment.tz(pickup_open_date_ts, 'DD/MM/YYYY', timezone)
      : simulationDate;
    const latestPickupDate = pickup_close_date_ts
      ? moment.tz(pickup_close_date_ts, 'DD/MM/YYYY', timezone)
      : simulationDate;
    const earliestDropoffDate = dropoff_open_date_ts
      ? moment.tz(dropoff_open_date_ts, 'DD/MM/YYYY', timezone)
      : simulationDate;
    const latestDropoffDate = dropoff_close_date_ts
      ? moment.tz(dropoff_close_date_ts, 'DD/MM/YYYY', timezone)
      : simulationDate;

    if (earliestPickupDate.isBefore(simulationDate)) {
      importErrors.earlyPickupDateErrors.push(rowNumber);
    }

    jsDataWithDates.push({
      ...data,
      pickup_open_date_ts: earliestPickupDate.format('YYYY-MM-DD'),
      pickup_close_date_ts: latestPickupDate.format('YYYY-MM-DD'),
      dropoff_open_date_ts: earliestDropoffDate.format('YYYY-MM-DD'),
      dropoff_close_date_ts: latestDropoffDate.format('YYYY-MM-DD'),
    });

    if (!pickup_address && !pickup_address2 && !pickup_zip_code) {
      importErrors.missingPickupAddressRowIndices.push(rowNumber);
    }
    if (!dropoff_address && !dropoff_address2 && !dropoff_zip_code) {
      importErrors.missingDropoffAddressRowIndices.push(rowNumber);
    }

    // mandatory column validations
    if (!external_id) {
      importErrors.missingIdErrors.push(rowNumber);
    }
    if (!pickup_open_time_ts) {
      importErrors.missingEarliestPickupTimeErrors.push(rowNumber);
    }
    if (!pickup_close_time_ts) {
      importErrors.missingLastestPickupTimeErrors.push(rowNumber);
    }
    if (!dropoff_open_time_ts) {
      importErrors.missingEarliestDropoffTimeErrors.push(rowNumber);
    }
    if (!dropoff_close_time_ts) {
      importErrors.missingLastestDropoffTimeErrors.push(rowNumber);
    }

    // duplicate ID validations
    if (external_id) {
      if (tempExternalIdList.some(id => id === external_id)) {
        importErrors.duplicateIdCsvErrors.push(external_id);
      }
      tempExternalIdList.push(external_id);
      if (existExternalIdList.some(id => id === external_id)) {
        importErrors.duplicateIdErrors.push(external_id);
      }
    }

    // date format validations
    if (pickup_open_date_ts && !isValidDateFormat(pickup_open_date_ts)) {
      importErrors.earliestPickupDateFormatErrors.push(rowNumber);
      hasDateTimeError = true;
    }
    if (pickup_close_date_ts && !isValidDateFormat(pickup_close_date_ts)) {
      importErrors.lastestPickupDateFormatErrors.push(rowNumber);
      hasDateTimeError = true;
    }
    if (dropoff_open_date_ts && !isValidDateFormat(dropoff_open_date_ts)) {
      importErrors.earliestDropoffDateFormatErrors.push(rowNumber);
      hasDateTimeError = true;
    }
    if (dropoff_close_date_ts && !isValidDateFormat(dropoff_close_date_ts)) {
      importErrors.lastestDropoffDateFormatErrors.push(rowNumber);
      hasDateTimeError = true;
    }

    // time format validations
    if (pickup_open_time_ts && !isValidTimeFormat(pickup_open_time_ts)) {
      importErrors.earliestPickupTimeFormatErrors.push(rowNumber);
      hasDateTimeError = true;
    }
    if (pickup_close_time_ts && !isValidTimeFormat(pickup_close_time_ts)) {
      importErrors.lastestPickupTimeFormatErrors.push(rowNumber);
      hasDateTimeError = true;
    }
    if (dropoff_open_time_ts && !isValidTimeFormat(dropoff_open_time_ts)) {
      importErrors.earliestDropoffTimeFormatErrors.push(rowNumber);
      hasDateTimeError = true;
    }
    if (dropoff_close_time_ts && !isValidTimeFormat(dropoff_close_time_ts)) {
      importErrors.lastestDropoffTimeFormatErrors.push(rowNumber);
      hasDateTimeError = true;
    }

    // latest datetime is earlier then the earliest datetime validations
    if (!hasDateTimeError) {
      const earliestPickupDateTime = getDateTime(
        earliestPickupDate,
        pickup_open_time_ts
      );
      const lastestPickupDateTime = getDateTime(
        latestPickupDate,
        pickup_close_time_ts
      );
      const earliestDropoffDateTime = getDateTime(
        earliestDropoffDate,
        dropoff_open_time_ts
      );
      const lastestDropoffDateTime = getDateTime(
        latestDropoffDate,
        dropoff_close_time_ts
      );
      if (earliestDropoffDateTime.isBefore(earliestPickupDateTime)) {
        importErrors.earliestPickupTimeErrors.push(rowNumber);
      }
      if (lastestPickupDateTime.isBefore(earliestPickupDateTime)) {
        importErrors.lastestPickupTimeErrors.push(rowNumber);
      }
      if (lastestDropoffDateTime.isBefore(earliestDropoffDateTime)) {
        importErrors.lastestDropoffTimeErrors.push(rowNumber);
      }
    }

    // lat long validations
    if (
      hasValue(pickup_location_lat) &&
      !isValidLatitude(pickup_location_lat)
    ) {
      importErrors.pickupLatErrors.push(rowNumber);
    }
    if (
      hasValue(pickup_location_lon) &&
      !isValidLongitude(pickup_location_lon)
    ) {
      importErrors.pickupLonErrors.push(rowNumber);
    }
    if (
      hasValue(dropoff_location_lat) &&
      !isValidLatitude(dropoff_location_lat)
    ) {
      importErrors.dropoffLatErrors.push(rowNumber);
    }
    if (
      hasValue(dropoff_location_lon) &&
      !isValidLongitude(dropoff_location_lon)
    ) {
      importErrors.dropoffLonErrors.push(rowNumber);
    }

    // vehicle label validation
    if (vehicle_labels) {
      const bookingLabels = Object.values(vehicle_labels);
      const vehicleOperator = Object.keys(vehicle_labels);

      const unAvailableLabels = [];
      bookingLabels[0].map((label) => {
        if (!vehicleLabelsSet.has(label)) {
          if (!importErrors.vehicleLabelErrors.find(data => data === label)) {
            importErrors.vehicleLabelErrors.push(label);
          }
        }
      });
      if (vehicleOperator[0] !== 'or' && vehicleOperator[0] !== 'and') {
        importErrors.vehicleOperatorErrors.push(rowNumber);
      }
    }

    if (penalty && !Number.isInteger(Number(penalty))) {
      importErrors.penaltyErrors.push(rowNumber);
    }

    const lowerCaseTripEnd = is_pickup_end_of_trip?.toLowerCase() || 'true';
    if (
      lowerCaseTripEnd &&
      lowerCaseTripEnd !== 'true' &&
      lowerCaseTripEnd !== 'false'
    ) {
      importErrors.endOfTrip.push(rowNumber);
    }
  });

  const hasImportError = Object.keys(importErrors).some(
    key => !!importErrors[key].length
  );

  return { hasImportError, importErrors, jsDataWithDates };
};

export const getImportErrorList = (importErrors, t) => {
  const mapErrorMessageKey = {
    missingIdErrors: 'p.Orders.importError.missingIdErrors',
    duplicateIdCsvErrors: 'p.Orders.importError.duplicateIdCsvErrors',
    duplicateIdErrors: 'p.Orders.importError.duplicateIdErrors',
    missingEarliestPickupTimeErrors:
      'p.Orders.importError.missingEarliestPickupTimeErrors',
    missingLastestPickupTimeErrors:
      'p.Orders.importError.missingLastestPickupTimeErrors',
    missingEarliestDropoffTimeErrors:
      'p.Orders.importError.missingEarliestDropoffTimeErrors',
    missingLastestDropoffTimeErrors:
      'p.Orders.importError.missingLastestDropoffTimeErrors',
    missingPickupAddressRowIndices: 'p.Orders.importError.missingPickup',
    missingDropoffAddressRowIndices: 'p.Orders.importError.missingDropoff',
    earlyPickupDateErrors: 'p.Orders.importError.earlyPickup',
    earliestPickupDateFormatErrors:
      'p.Orders.importError.earliestPickupDateFormatErrors',
    lastestPickupDateFormatErrors:
      'p.Orders.importError.lastestPickupDateFormatErrors',
    earliestDropoffDateFormatErrors:
      'p.Orders.importError.earliestDropoffDateFormatErrors',
    lastestDropoffDateFormatErrors:
      'p.Orders.importError.lastestDropoffDateFormatErrors',
    earliestPickupTimeFormatErrors:
      'p.Orders.importError.earliestPickupTimeFormatErrors',
    lastestPickupTimeFormatErrors:
      'p.Orders.importError.lastestPickupTimeFormatErrors',
    earliestDropoffTimeFormatErrors:
      'p.Orders.importError.earliestDropoffTimeFormatErrors',
    lastestDropoffTimeFormatErrors:
      'p.Orders.importError.lastestDropoffTimeFormatErrors',
    lastestPickupTimeErrors: 'p.Orders.importError.lastestPickupTimeErrors',
    earliestPickupTimeErrors: 'p.Orders.importError.earliestPickupTimeErrors',
    lastestDropoffTimeErrors: 'p.Orders.importError.lastestDropoffTimeErrors',
    pickupLatErrors: 'p.Orders.importError.pickupLatErrors',
    pickupLonErrors: 'p.Orders.importError.pickupLonErrors',
    dropoffLatErrors: 'p.Orders.importError.dropoffLatErrors',
    dropoffLonErrors: 'p.Orders.importError.dropoffLonErrors',
    vehicleLabelErrors: 'p.Orders.importError.vehicleLabelErrors',
    vehicleOperatorErrors: 'p.Orders.importError.vehicleOperatorErrors',
    penaltyErrors: 'p.Orders.importError.penaltyErrors',
    endOfTrip: 'p.Orders.importError.endOfTripErrors',
  };
  return Object.keys(mapErrorMessageKey).map(
    key =>
      importErrors[key]?.length > 0 && (
        <li key={key} style={{ whiteSpace: 'pre-wrap' }}>
          {t(mapErrorMessageKey[key], {
            rows:
              '\n' +
              importErrors[key].map(str => `  • ${str}`).join('\n') +
              '\n',
          })}
        </li>
      )
  );
};

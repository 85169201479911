import styled from 'styled-components';

const Title = styled.div`
  display: flex;
  font-size: 25px;
  font-weight: 600;
  color: #edece8;
  margin: 0;
  margin-right: auto;
  align-items: center;
`;

export default Title;

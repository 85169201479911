// Slightly modeled after RMWC's Avatar
// https://rmwc.io/avatars

import React from 'react';
import { styled } from 'baseui';

const Container = styled('div', {
  width: '36px',
  height: '36px',
  display: 'inline-block',
  borderRadius: '36px',
  overflow: 'hidden',
  lineHeight: '36px',
  backgroundColor: '#3c63ef',
  color: '#fff',
  fontSize: '14px',
  textAlign: 'center',
  textTransform: 'uppercase',
});

const getInitialsForName = (name) => {
  if (name) {
    const parts = name.split(' ');
    let letters = (parts[0] || '')[0];
    if (parts.length > 1) {
      const part = parts.pop();
      if (part) {
        letters += part[0];
      }
    }
    return letters;
  }
  return '';
};

export default ({ name }) => {
  return <Container>{getInitialsForName(name)}</Container>;
};

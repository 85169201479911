import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { walkingRoutingEngineSelector } from 'modules/ui/selectors';

import { toggleWalkingRoutingEngine } from 'modules/ui/actions';

import WalkingRoutingButtons from './WalkingRoutingButtons';

const mapStateToProps = createSelector(
  walkingRoutingEngineSelector,
  routingEngine => ({
    routingEngine,
  })
);

const mapDispatchToProps = {
  toggleWalkingRoutingEngine,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(WalkingRoutingButtons);

import styled from 'styled-components';

export default styled.div`
  position: relative;
  font-size: 12px;
  border-radius: 10px;
  margin: 5px 0;
  margin-left: 24px;
  transition: 0.3s;

  :last-child {
    :before {
      display: none;
    }
  }

  :after {
    position: absolute;
    left: -22px;
    top: 9px;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 50%;
    content: ${({ content }) => content};
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    color: #272c35;
    display: block;
    box-shadow: 0 0 0 ${({ isActive }) => (isActive ? '6px' : '0px')}
      ${({ color }) => `${color}50`};
    background-color: ${({ color }) => color};
    background-image: ${({ icon }) => icon};
    background-size: 70%;
    background-position: center;
    background-repeat: no-repeat;
  }

  :before {
    position: absolute;
    left: -13px;
    top: ${({ showRouteExtensionLine }) =>
      showRouteExtensionLine ? '0' : '20px'} 
    height: ${({ showRouteExtensionLine }) =>
      showRouteExtensionLine ? '120%' : '100%'}
    content: '';
    background-color: ${({ color }) => color};
    width: 2px;
  }
`;

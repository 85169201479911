import React from 'utils/react';

import Tag from 'components/Tag';

import Container from './Conteiner';
import Text from './Text';
import Tags from './Tags';

const Body = (props) => {
  const { density, trips, tags, isVisible, creationType } = props;
  return (
    <Container isVisible={isVisible}>
      <Text data-testid='Body-Text-creationType'>{creationType}</Text>
      {density && (
        <Text>{`Density: ${(density * 1e6).toFixed(1)} rides/km2`}</Text>
      )}
      {trips && <Text>{`Trips: ${trips} per sq. km`}</Text>}
      <Tags>
        {tags.map(tag => (
          <Tag key={tag} name={tag} />
        ))}
      </Tags>
    </Container>
  );
};

export default Body;

import styled from 'styled-components';
import plus from 'assets/plusWhite.svg';

const Close = styled.div`
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: url(${plus}) no-repeat center;
  margin-bottom: 5px;
  opacity: 1;
  border-radius: 50%;
  transform: rotate(45deg);
  margin-top: 5px;
  margin-left: 10px;

  transition: background 0.2s;

  &:hover {
    opacity: 0.5;
  }
`;

export default Close;

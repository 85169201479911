import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  flex: 1 1;
  background: ${({ isActive }) => (isActive ? '#4d5663' : '#3e4755')};
  cursor: pointer;

  &:after {
    content: '';
    background: url(${({ img }) => img}) no-repeat center;
    opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
  }

  &:hover {
    background: #454d5a;
  }
`;

export default Container;

import React from 'utils/react';

import Container from './Container';
import Body from './Body';

import Header from './Header';
import Overflow from './Overflow';

const Table = ({ list }) => (
  <React.Fragment>
    <Overflow>
      <Container isLoading={!list}>
        <Header />
        <Body />
      </Container>
    </Overflow>
  </React.Fragment>
);

export default Table;

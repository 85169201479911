import styled from 'styled-components';

const RowContainer = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  flex-grow: 1;
  color: #c5cede;
  flex-shrink: 0;
  margin: 3px 0;
  min-height: 75px;

  &:hover {
    background-color: #424a5a;
  }
`;

export default RowContainer;

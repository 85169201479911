import React from 'utils/react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';

import debug from 'utils/debug';
import Timeline from './Timeline';
import withTranslation from 'utils/react/withTranslation';

const D2 = debug('c:ModalWindows:TimelineViewer');

const TimelineViewer = (props) => {
  const { t } = props;

  D2.S.INFO('TimelineViewer', props);

  const [isOpen, setIsOpen] = React.useState(false);
  const [config, setConfig] = React.useState();
  const [title, setTitle] = React.useState('Timeline');
  const [options, setOptions] = React.useState({});
  const [timelineState, setTimelineState] = React.useState();

  React.useEffect(() => {
    D2.S.INFO('Update', { props });

    window.openTimelineViewer = ($config, $opts) => {
      D2.S.INFO('openTimelineViewer', { $config, $opts });

      const opts = $opts || {};

      const $title = options.title || t('Timeline');

      setTitle($title);
      setConfig($config);
      setOptions(opts);
      setIsOpen(true);
    };

    window.closeTimelineViewer = () => {
      D2.S.INFO('closeTimelineViewer', { isOpen, config });
      setIsOpen(false);
    };

    return () => {
      D2.S.INFO('Cleanup', { isOpen, config });
      window.closeTableViewer = null;
    };
  });

  function onClose() {
    D2.S.INFO('onClose', { isOpen, config });

    setIsOpen(false);
  }

  const onTimelineState = (newTimelineState) => {
    D2.S.INFO('onTimelineState', { newTimelineState });

    // setTimelineState(newTimelineState);
    setTimelineState(null);
  };

  const onZoomOut = () => {
    D2.S.INFO('onZoomOut');

    if (timelineState) {
      const zoom = timelineState.get('config.chart.time.zoom');
      timelineState.update('config.chart.time.zoom', () => {
        return zoom < 22 ? zoom + 1 : zoom;
      });
    }
  };

  const onZoomIn = () => {
    D2.S.INFO('onZoomIn');

    if (timelineState) {
      const zoom = timelineState.get('config.chart.time.zoom');
      timelineState.update('config.chart.time.zoom', () => {
        return zoom > 10 ? zoom - 1 : zoom;
      });
    }
  };

  const useCalendarScroll = false;

  return (
    <React.Fragment>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        overrides={{
          Dialog: {
            style: {
              width: '90vw',
              height: useCalendarScroll ? '90vh' : undefined,
              display: 'flex',
              flexDirection: 'column',
            },
          },
        }}
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <Timeline
            useCalendarScroll={useCalendarScroll}
            onTimelineState={onTimelineState}
            config={config}
          />
        </ModalBody>
        <ModalFooter>
          {timelineState && (
            <ModalButton onClick={onZoomOut}>{t('ZoomOut')}</ModalButton>
          )}
          {timelineState && (
            <ModalButton onClick={onZoomIn}>{t('ZoomIn')}</ModalButton>
          )}
          <ModalButton onClick={onClose}>{t('c.messages.Close')}</ModalButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(TimelineViewer);

import React from 'utils/react';
import CheckBox from 'components/CheckBox';

import Container from './Container';
import Buttons from './Buttons';
import DateComponent from './Date';
import Title from './Title';
import Rows from './Rows';
import Link from './Link';
import Format from './Format';

const Row = (props) => {
  const {
    url,
    deleteDataset,
    updateDataset,
    saveDataset,
    setEditableDataset,
    updateEditableDataset,
    editableDataset,
    downloadDatasetCsv,
    toggleSelectedDatasets,
    selectedIds,
  } = props;

  const date = new Date(props.dataset.get('created_at'));
  const bookmarked = props.dataset.get('bookmarked');
  const id = props.dataset.get('id');

  const isEditable = editableDataset && editableDataset.get('id') === id;
  const dataset = isEditable ? editableDataset : props.dataset;

  return (
    <Container>
      <CheckBox
        isActive={selectedIds.includes(id)}
        onClick={() => toggleSelectedDatasets(id)}
      />
      <Link to={url} as={isEditable && 'div'}>
        <Title
          dataset={dataset}
          isEditable={isEditable}
          onChange={updateEditableDataset}
          setEditableDataset={setEditableDataset}
          saveDataset={saveDataset}
        />
        <Format>{dataset.get('booking_data_format')}</Format>
        <Rows>{`${dataset.get('trips')} rows`}</Rows>
        <DateComponent>
          {date.toLocaleDateString('en-GB', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })}
        </DateComponent>
      </Link>
      <Buttons
        dataset={dataset}
        bookmarked={bookmarked}
        deleteDataset={deleteDataset}
        updateDataset={updateDataset}
        setEditableDataset={setEditableDataset}
        downloadDatasetCsv={downloadDatasetCsv}
      />
    </Container>
  );
};

export default Row;

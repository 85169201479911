import { is } from 'immutable';
import { createSelectorCreator, defaultMemoize } from 'reselect';

const createObjectSelector = ($D2, ...args) =>
  createSelectorCreator(
    $D2.S.$FN(defaultMemoize, 'memoize'),
    $D2.S.$FN(is, 'is')
  )(...args);

export default createObjectSelector;

export default {
  points: {
    pickup: {
      layerId: 'pickup',
      paramId: 'visible',
    },
    pickupColor: {
      layerId: 'pickup',
      paramId: 'getColor',
    },
    pickupRadius: {
      layerId: 'pickup',
      paramId: 'getRadius',
    },
    pickupOpacity: {
      layerId: 'pickup',
      paramId: 'opacity',
    },
    dropoff: {
      layerId: 'dropoff',
      paramId: 'visible',
    },
    dropoffColor: {
      layerId: 'dropoff',
      paramId: 'getColor',
    },
    dropoffRadius: {
      layerId: 'dropoff',
      paramId: 'getRadius',
    },
    dropoffOpacity: {
      layerId: 'dropoff',
      paramId: 'opacity',
    },
  },
  arcs: {
    pickupArcColor: {
      layerId: 'main',
      paramId: 'getSourceColor',
    },
    dropoffArcColor: {
      layerId: 'main',
      paramId: 'getTargetColor',
    },
    width: {
      layerId: 'main',
      paramId: 'getStrokeWidth',
    },
  },
  clusters: {
    pickup: {
      layerId: 'pickup',
      paramId: 'visible',
    },
    pickupColor: {
      layerId: 'pickup',
      paramId: 'getColor',
    },
    pickupOpacity: {
      layerId: 'pickup',
      paramId: 'opacity',
    },
    pickupRadiusMin: {
      layerId: 'pickup',
      paramId: 'radiusMinPixels',
    },
    pickupRadiusMax: {
      layerId: 'pickup',
      paramId: 'radiusMaxPixels',
    },
    dropoff: {
      layerId: 'dropoff',
      paramId: 'visible',
    },
    dropoffColor: {
      layerId: 'dropoff',
      paramId: 'getColor',
    },
    dropoffOpacity: {
      layerId: 'dropoff',
      paramId: 'opacity',
    },
    dropoffRadiusMin: {
      layerId: 'dropoff',
      paramId: 'radiusMinPixels',
    },
    dropoffRadiusMax: {
      layerId: 'dropoff',
      paramId: 'radiusMaxPixels',
    },
  },
  arcClusters: {
    pickupArcColor: {
      layerId: 'arc',
      paramId: 'getSourceColor',
    },
    dropoffArcColor: {
      layerId: 'arc',
      paramId: 'getTargetColor',
    },
    width: {
      layerId: 'arc',
      paramId: 'getStrokeWidth',
    },
    pickup: {
      layerId: 'pickup',
      paramId: 'visible',
    },
    pickupColor: {
      layerId: 'pickup',
      paramId: 'getColor',
    },
    pickupOpacity: {
      layerId: 'pickup',
      paramId: 'opacity',
    },
    pickupRadiusMin: {
      layerId: 'pickup',
      paramId: 'radiusMinPixels',
    },
    pickupRadiusMax: {
      layerId: 'pickup',
      paramId: 'radiusMaxPixels',
    },
    dropoff: {
      layerId: 'dropoff',
      paramId: 'visible',
    },
    dropoffColor: {
      layerId: 'dropoff',
      paramId: 'getColor',
    },
    dropoffOpacity: {
      layerId: 'dropoff',
      paramId: 'opacity',
    },
    dropoffRadiusMin: {
      layerId: 'dropoff',
      paramId: 'radiusMinPixels',
    },
    dropoffRadiusMax: {
      layerId: 'dropoff',
      paramId: 'radiusMaxPixels',
    },
  },
  hex_dropoff: {
    colorRange: {
      layerId: 'main',
      paramId: 'colorRange',
    },
    radius: {
      layerId: 'main',
      paramId: 'radius',
    },
    coverage: {
      layerId: 'main',
      paramId: 'coverage',
    },
    extruded: {
      layerId: 'main',
      paramId: 'extruded',
    },
    elevationScale: {
      layerId: 'main',
      paramId: 'elevationScale',
    },
    opacity: {
      layerId: 'main',
      paramId: 'opacity',
    },
  },
  hex_pickup: {
    colorRange: {
      layerId: 'main',
      paramId: 'colorRange',
    },
    radius: {
      layerId: 'main',
      paramId: 'radius',
    },
    coverage: {
      layerId: 'main',
      paramId: 'coverage',
    },
    extruded: {
      layerId: 'main',
      paramId: 'extruded',
    },
    elevationScale: {
      layerId: 'main',
      paramId: 'elevationScale',
    },
    opacity: {
      layerId: 'main',
      paramId: 'opacity',
    },
  },
};

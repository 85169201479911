import React from 'utils/react';
import { v4 as uuidv4 } from 'uuid';

import { crc32 } from 'utils/crc32';
import { addVehicleToSimulation } from 'api/simulations';
import { defaultVehicleRoutingEngineJSON } from 'utils/CommuteOffer/defaults';
import debug from 'utils/debug';
import PanelContainer from '../PanelContainer';
import PanelHeader from '../PanelHeader';
import PanelList from '../PanelList';
import Vehicle from './Vehicle';
import Button from './Button';
import Filter from './Filter';
import VehicleEditor from './VehiclesEditor';

const D2 = debug('p:CommuteOffer:Panels:Vehicles');

const defaultScrollToVehicle = newActiveVehicleId =>
  D2.S.FUNCTION(
    'geodisc$ScrollToVehicle<DOM>',
    { newActiveVehicleId },
    ({ $D2 }) => {
      const element = window.document.getElementById(newActiveVehicleId);
      $D2.S.INFO('element', {
        element,
        newActiveVehicleId,
      });
      if (element) {
        element.scrollIntoView();
      }

      return 'DOM';
    }
  );

global.geodisc$ScrollToVehicle = defaultScrollToVehicle;

const getDefaultVehicleModelsMap = (t) => {
  const defaultVehicleModelBase = {
    id: t('c.ModalWindow.VehicleEditor.VehicleModel.Default'),
    routing_engine_settings: {
      ...JSON.parse(defaultVehicleRoutingEngineJSON),
      routing_engine_name: 'osrme',
      road_network: 'alldriving',
    },
  };

  return {
    delivery: [
      {
        ...defaultVehicleModelBase,
        capacity: { passenger: 40 },
        routing_engine_settings: {
          ...defaultVehicleModelBase.routing_engine_settings,
          road_network: 'alldriving',
        },
      },
    ],
    default: [
      {
        defaultVehicleModelBase,
        capacity: { units: 40 },
        routing_engine_settings: {
          ...defaultVehicleModelBase.routing_engine_settings,
          road_network: 'alldriving',
        },
      },
    ],
  };
};

const Vehicles = props =>
  D2.S.FUNCTION('Vehicles', { props }, () => {
    const {
      t,
      vehicles,
      openPopup,
      isReadOnly,
      projectConfig,
      layoutName,
      isDeliveryLayout,
      commuteOfferRequestUpdate,
      simulation,
      canManageVehicles,
      isServiceDataSource,
      isFilteringEnabled,
    } = props;

    const onCommuteOfferAddVehicle = () =>
      D2.S.FUNCTION('onCommuteOfferAddVehicle', { props }, () => {
        const id = uuidv4();

        openPopup('CommuteOfferVehicleEditor', {
          mode: 'new',
          title: t('c.ModalWindow.VehicleEditor.Title.Add'),
          submitCaption: t('Create'),
          submittingCaption: t('Creating...'),
          initialValues: {
            id,
            capacity_passengers: '15',
            capacity_stops: '100',
            capacity_wheelchairs: '0',
            start_time: new Date(),
            routing_engine_name: { value: 'osrme', label: 'osrme' },
            road_network: 'driving',
            vehicle_color: crc32(id) % 360,
            readOnly: { label: 'No', value: false },
          },
        });
      });
    const onSimulationAddVehicle = () =>
      D2.S.FUNCTION(
        'onSimulationAddVehicle',
        { props, projectConfig },
        ({ $D2 }) => {
          const normalizeVehicleModels = models =>
            Array.isArray(models) && models.length > 0 ? models : undefined;

          const projectVehicleModels = normalizeVehicleModels(
            projectConfig?.vehicle_models
          );
          const simulationVehicleModels = normalizeVehicleModels(
            simulation.data?.vehicle_models
          );

          const defaultVehicleModels = normalizeVehicleModels(
            getDefaultVehicleModelsMap(t)[layoutName]
          );

          const vehicleModels =
            simulationVehicleModels ??
            projectVehicleModels ??
            defaultVehicleModels ??
            [];

          global.openVehicleEditor(
            {},
            {
              title: t('c.ModalWindow.VehicleEditor.Title.Add'),
              vehicleModels,
              timezone: global.GEODISC_TIMEZONE,
              date: simulation.start_time,
              onSubmit: async vehicle =>
                $D2.S.FUNCTION(
                  'onSubmit',
                  { vehicle, simulation },
                  async () => {
                    await addVehicleToSimulation(simulation.id, vehicle);
                    global.openInfoMessage(
                      t('c.ModalWindows.VehicleEditor.Insert.Success')
                    );
                    commuteOfferRequestUpdate(null, {
                      isInitRequired: false,
                      ...$D2.CONTEXT,
                    });
                  }
                ),
            }
          );
        }
      );

    const onAddVehicle = !isDeliveryLayout
      ? onCommuteOfferAddVehicle
      : onSimulationAddVehicle;

    const visibleVehicles =
      global.GEODISC_DEBUG_VEHICLES_PANEL_MAX_RECORDS_COUNT > 0
        ? vehicles.slice(
            0,
            global.GEODISC_DEBUG_VEHICLES_PANEL_MAX_RECORDS_COUNT
          )
        : vehicles;

    const canCreateVehicles =
      (!simulation && !isReadOnly) || (simulation && canManageVehicles);
    D2.S.INFO('canCreateVehicles', {
      canCreateVehicles,
      isReadOnly,
      isDeliveryLayout,
    });

    return (
      <PanelContainer isServiceDataSource={isServiceDataSource}>
        <PanelHeader>
          {t('p.Editor.Panels.Vehicles.Header.Vehicles', {
            count: vehicles.length,
          })}
          {canCreateVehicles && (
            <Button onClick={onAddVehicle} data-testid='Vehicles-Button-Add'>
              {t('p.Editor.Panels.Vehicles.Header.Vehicles.Add')}
            </Button>
          )}
        </PanelHeader>
        {isFilteringEnabled && <Filter />}
        <VehicleEditor />

        <PanelList isFilteringEnabled={isFilteringEnabled}>
          {visibleVehicles.map(vehicle => (
            <Vehicle key={vehicle.agent_id} vehicle={vehicle} />
          ))}
        </PanelList>
      </PanelContainer>
    );
  });

export default Vehicles;

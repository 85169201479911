import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';
import { commuteOfferEditVehicleRequest } from 'modules/commuteOffer/actions';
import { fleetAddVehicle, updateVehicleType } from 'modules/fleet/actions';
import { vehicleTypesSelector } from 'modules/fleet/selectors';
import VehicleTypes from './VehicleTypes';
import { currentProjectSelector } from 'modules/user/selectors';

const mapStateToProps = createImmutableSelector(
  vehicleTypesSelector,
  currentProjectSelector,
  (vehicleTypes, projectData) => ({
    vehicleTypes,
    projectData,
  })
);

const mapDispatchToProps = {
  fleetAddVehicle,
  updateVehicleType,
  commuteOfferEditVehicleRequest,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(VehicleTypes);

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import {
  serialNumberSelector,
  commuteOfferCurrentDataSelector,
  commuteOfferLoadedSimulationSelector,
  commuteOfferIsReadOnlySelector,
} from 'modules/commuteOffer/selectors';

import {
  projectsSelector,
  currentProjectSelector,
  currentProjectIdSelector,
  currentProjectLayoutNameSelector,
  isDeliveryLayoutSelector,
  defaultPageSelector,
} from 'modules/user/selectors';

import { stopsSelector } from 'modules/entities/selectors';
import { cleanCommuteOfferUi } from 'modules/ui/actions';

import {
  fetchCommuteOffer,
  commuteOfferRequestUpdate,
  clearCommuteOffer,
  incSerialNumber,
  incPermissionsSerialNumber,
  fetchTransitStopSets,
} from 'modules/commuteOffer/actions';
import { fetchStops } from 'modules/entities/actions';

import { geofencesSelector } from 'modules/geofences/selectors';
import { fetchGeofences } from 'modules/geofences/actions';

import { routeIdSelector } from 'modules/router/selectors';

// import { clearProjects, fetchProjects } from 'modules/user/actions';

import debug from 'utils/debug';
import CommuteOffer from './CommuteOffer';

const D2 = debug('p:CommuteOffer');

global.GEODISC_ALWAYS_FETCH_GEOFENCES_ON_OPEN = true;
global.GEODISC_ALWAYS_FETCH_COMMUTE_OFFER_ON_OPEN = true;

const mapStateToProps = createImmutableSelector(
  commuteOfferIsReadOnlySelector,
  serialNumberSelector,
  projectsSelector,
  currentProjectIdSelector,
  currentProjectSelector,
  commuteOfferCurrentDataSelector,
  stopsSelector,
  geofencesSelector,
  routeIdSelector,
  commuteOfferLoadedSimulationSelector,
  currentProjectLayoutNameSelector,
  isDeliveryLayoutSelector,
  defaultPageSelector,
  (
    isReadOnly,
    serialNumber,
    projects,
    projectId,
    projectData,
    data,
    stops,
    geofences,
    id,
    simulation,
    layoutName,
    isDeliveryLayout,
    defaultPage
  ) => ({
    isReadOnly,
    serialNumber,
    projects,
    projectId,
    projectData,
    data,
    stops,
    geofences,
    id,
    simulation,
    layoutName,
    isDeliveryLayout,
    defaultPage,
  })
);

const mapDispatchToProps = {
  // clearProjects,
  // fetchProjects,
  fetchCommuteOffer,
  commuteOfferRequestUpdate,
  clearCommuteOffer,
  fetchStops,
  fetchGeofences,
  fetchTransitStopSets,
  cleanCommuteOfferUi,
  incSerialNumber,
  incPermissionsSerialNumber,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(CommuteOffer);

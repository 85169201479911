const setPopoverHeight =
  ({ elementRef, windowHeight }) =>
  () => {
    setTimeout(() => {
      if (elementRef.current) {
        const rect = elementRef.current.getBoundingClientRect();

        if (windowHeight < elementRef.current.clientHeight + rect.y) {
          elementRef.current.style.height = `${Math.min(
            windowHeight - rect.y,
            windowHeight
          )}px`;
        }
      }
    }, 0);
  };

export default setPopoverHeight;

import styled from 'styled-components';

const Content = styled.div`
  position: absolute;
  top: 30px;
  left: 0;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  background: #3b4251;
  z-index: 1;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
`;

export default Content;

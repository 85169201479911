import styled from 'styled-components';

const Item = styled.div`
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.6;
  }
`;

export default Item;

import React from 'utils/react';
import { Formik } from 'formik';
import debug from 'utils/debug';

import Input from 'components/Input';
import ResetButton from 'components/ResetButton';
import SubmitButton from 'components/SubmitButton';

import Row from './Row';
import ButtonsRow from '../ButtonsRow';

const D2 = debug('c:ModalWindow:NewCommuteOffer:Preview');

const Preview = ({ file, onReset, uploadData, projectUrl }) => (
  <Formik
    initialValues={{
      name: file.name,
      project: projectUrl,
    }}
    onSubmit={(params) => {
      D2.S.INFO('onSubmit', params);

      uploadData(params);
    }}
  >
    {(props) => {
      const { values, handleChange, handleSubmit, handleBlur } = props;

      return (
        <form onSubmit={handleSubmit} autoComplete='off'>
          <Row>
            <Input
              data-testid='CommuteOffer-Name'
              name='name'
              type='text'
              label='Name'
              value={values.name}
              onChange={handleChange}
              handleBlur={handleBlur}
              autoComplete='off'
            />
          </Row>
          <ButtonsRow>
            <ResetButton
              data-testid='CommuteOffer-Button-Reset'
              onClick={onReset}
            >
              Choose a different file
            </ResetButton>
            <SubmitButton
              data-testid='CommuteOffer-Button-Submit'
              type='submit'
            >
              Create new commute offer
            </SubmitButton>
          </ButtonsRow>
        </form>
      );
    }}
  </Formik>
);

export default Preview;

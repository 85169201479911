import moment from 'moment-timezone';
import Immutable from 'immutable';

import { fetchData } from 'api/net';
import { getHeaders } from 'api/headers';
import { updateSimulation } from 'api/simulations';

import { removeInternalFieldsFromObject } from 'utils/object';

import { urls } from '../../config';

import { diffString } from 'json-diff';
import makeBulkUpdateRequest from './makeBulkUpdateRequest';

import debug from 'utils/debug';
import { BOOKING_STATUSES, NODE_STATUSES } from 'utils/constants';
import { isEqual, merge } from 'lodash';
import m from 'ace-builds/src-noconflict/mode-json';
const D2 = debug('api:CommuteOffer');

const formatTimeForUTC = ts => (ts ? moment(ts).tz('UTC').format() : null);

const updateSimulationHandler = async (currentOffer, originalOffer) =>
  D2.A.FUNCTION(
    'updateSimulation',
    { currentOffer, originalOffer },
    async ({ $D2 }) => {
      const makeBulkRequest = commuteOffer =>
        $D2.S.FUNCTION('makeBulkRequest', {}, () => {
          const simulation_id = commuteOffer.$source.simulation.id;

          const assignedObjects = Object.entries(
            commuteOffer.result.vehicles
          ).reduce(
            (memo, [agent_id, vehicle]) => {
              const requestVehicle =
                commuteOffer.stateless_api_request_data.vehicles.find(
                  item => item.agent_id === agent_id
                );
              const vehicleNodes = vehicle
                .map(node => ({
                  ...removeInternalFieldsFromObject(node),
                  assigned_vehicle: requestVehicle
                    ? `/api/v2/vehicle/${requestVehicle.id}`
                    : null,
                  status:
                    node?.status === NODE_STATUSES.NEW ||
                    node?.status === NODE_STATUSES.FAILED_TO_BOARD ||
                    node?.status === NODE_STATUSES.CANCELLED_BY_USER
                      ? NODE_STATUSES.ASSIGNED
                      : node?.status,
                  assigned_vehicle_id: undefined,
                }))
                .sort((a, b) =>
                  moment(a.scheduled_ts).diff(moment(b.scheduled_ts))
                )
                .map((node, index) => ({
                  ...node,
                }));
              const vehicleBookings = vehicleNodes.reduce(
                (bookingsMemo, { booking_uid }) => ({
                  ...bookingsMemo,
                  [booking_uid]: {
                    ...removeInternalFieldsFromObject(
                      commuteOffer.stateless_api_request_data.bookings[
                        booking_uid
                      ]
                    ),
                    state:
                      commuteOffer.stateless_api_request_data.bookings[
                        booking_uid
                      ]?.state === BOOKING_STATUSES.PREPARED
                        ? BOOKING_STATUSES.ASSIGNED
                        : commuteOffer.stateless_api_request_data.bookings[
                            booking_uid
                          ]?.state,
                    is_invalidated: undefined,
                  },
                }),
                {}
              );

              return {
                ...memo,
                objects: [...memo.objects, ...vehicleNodes],
                // SP-3059: include the previous booking from the other vehicles
                bookings: { ...memo.bookings, ...vehicleBookings },
                nodes: {
                  ...memo.nodes,
                  ...vehicleNodes.reduce(
                    (nodesMemo, vehicleNode) => ({
                      ...nodesMemo,
                      [vehicleNode.uid]: vehicleNode,
                    }),
                    {}
                  ),
                },
              };
            },
            { objects: [], bookings: {}, nodes: {} }
          );
          $D2.S.INFO('makeBulkRequest:assignedObjects', {
            assignedObjects,
            commuteOffer,
          });

          const unassignedNodes =
            commuteOffer.stateless_api_request_data.nodes.reduce(
              (memo, node) => {
                const assignedNode = assignedObjects.nodes[node.uid];
                return !assignedNode
                  ? {
                      ...memo,
                      objects: [
                        ...memo.objects,
                        {
                          ...removeInternalFieldsFromObject(node),
                          status:
                            node.status === NODE_STATUSES.NEW ||
                            node.status === NODE_STATUSES.REJECTED_BY_SYSTEM
                              ? node.status
                              : NODE_STATUSES.NEW,
                          assigned_vehicle: null,
                          estimated_arrival_time: null,
                          estimated_earliest_arrival_ts: null,
                          estimated_scheduled_ts: null,
                          assigned_vehicle_id: undefined,
                        },
                      ],
                    }
                  : memo;
              },
              { objects: [] }
            );
          $D2.S.INFO('makeBulkRequest:unassignedNodes', {
            unassignedNodes,
            assignedObjects,
            commuteOffer,
          });

          const nodes = [
            ...assignedObjects.objects,
            ...unassignedNodes.objects,
          ].reduce(
            (memo, node) => ({
              ...memo,
              [node.uid]: removeInternalFieldsFromObject({
                ...node,
                simulation: `/api/v2/simulation/${simulation_id}`,
                scheduled_ts: formatTimeForUTC(node.scheduled_ts),
                open_time_ts: formatTimeForUTC(node.open_time_ts),
                close_time_ts: formatTimeForUTC(node.close_time_ts),
                close_time_ts_dynamic: formatTimeForUTC(
                  node.close_time_ts_dynamic
                ),
                calculated_scheduled_ts: undefined,
                is_invalidated: undefined,
              }),
            }),
            {}
          );
          $D2.S.INFO('makeBulkRequest:nodes', {
            nodes,
          });

          const unassignedBookings = Object.values(
            commuteOffer.stateless_api_request_data.bookings
          ).reduce((memo, booking) => {
            return !assignedObjects.bookings[booking.uid]
              ? {
                  ...memo,
                  [booking.uid]: removeInternalFieldsFromObject({
                    ...booking,
                    state:
                      booking.state === BOOKING_STATUSES.NEW ||
                      booking.state === BOOKING_STATUSES.REJECTED_BY_SYSTEM ||
                      booking.state === BOOKING_STATUSES.PREPARED
                        ? booking.state
                        : BOOKING_STATUSES.NEW,
                    is_invalidated: undefined,
                  }),
                }
              : {
                  ...memo,
                  [booking.uid]: removeInternalFieldsFromObject({
                    ...booking,
                    fail_to_board_time: null,
                    cancellation_time: null,
                    // SP-3059: don't overwrite the state because it might completed
                    state:
                      booking.state === BOOKING_STATUSES.COMPLETED
                        ? booking.state
                        : BOOKING_STATUSES.ASSIGNED,
                    is_invalidated: false,
                  }),
                };
          }, {});

          const bookings = {
            ...assignedObjects.bookings,
            ...unassignedBookings,
          };
          $D2.S.INFO('makeBulkRequest:bookings', {
            bookings,
            assignedObjects,
            unassignedBookings,
          });

          const vehicles =
            commuteOffer.stateless_api_request_data.vehicles.reduce(
              (memo, vehicle) => ({
                ...memo,
                [vehicle.agent_id]: removeInternalFieldsFromObject({
                  ...vehicle,
                  simulation: `/api/v2/simulation/${simulation_id}`,
                  is_invalidated: undefined,
                }),
              }),
              {}
            );
          $D2.S.INFO('makeBulkRequest:vehicles', {
            vehicles,
          });

          return {
            nodes,
            bookings,
            vehicles,
          };
        });

      const originalRequest = makeBulkRequest(originalOffer);
      $D2.S.INFO('originalRequest', {
        originalRequest,
        originalOffer,
      });

      const currentRequest = makeBulkRequest(currentOffer);
      $D2.S.INFO('currentRequest', {
        currentRequest,
        currentOffer,
      });

      const iOriginalRequest = Immutable.fromJS(originalRequest);
      const iCurrentRequest = Immutable.fromJS(currentRequest);
      const iMergedRequest = iOriginalRequest.merge(iCurrentRequest);

      if (!Immutable.is(iOriginalRequest, iMergedRequest)) {
        const vehiclesRequest = makeBulkUpdateRequest(
          currentRequest.vehicles,
          originalRequest.vehicles,
          'VEHICLE',
          x => x.agent_id,
          x => !!x.id && !!x.resource_uri
        );

        const bookingsRequest = makeBulkUpdateRequest(
          currentRequest.bookings,
          originalRequest.bookings,
          'BOOKING'
        );

        const nodesRequest = makeBulkUpdateRequest(
          currentRequest.nodes,
          originalRequest.nodes,
          'NODE'
        );

        if (global.GEODISC_SIMULATION_SAVING_LOGS_ENABLED) {
          // eslint-disable-next-line
          console.log('===', 'NODES', nodesRequest);
        }

        if (
          nodesRequest.objects.length > 0 ||
          nodesRequest.deleted_objects.length > 0
        ) {
          const nodesResponse = await fetchData(urls.simulationNodeAPI, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(nodesRequest),
            maxAttempts: 1,
          });

          if (!nodesResponse.ok) {
            throw new Error(nodesResponse.status);
          }
        }

        if (global.GEODISC_SIMULATION_SAVING_LOGS_ENABLED) {
          // eslint-disable-next-line
          console.log('===', 'BOOKINGS', bookingsRequest);
        }

        if (
          bookingsRequest.objects.length > 0 ||
          bookingsRequest.deleted_objects.length > 0
        ) {
          const bookingsResponse = await fetchData(urls.simulationBookingAPI, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(bookingsRequest),
            maxAttempts: 1,
          });

          if (!bookingsResponse.ok) {
            throw new Error(bookingsResponse.status);
          }
        }

        if (global.GEODISC_SIMULATION_SAVING_LOGS_ENABLED) {
          // eslint-disable-next-line
          console.log('===', 'VEHICLES', vehiclesRequest);
        }

        if (
          vehiclesRequest.objects.length > 0 ||
          vehiclesRequest.deleted_objects.length > 0
        ) {
          const vehiclesResponse = await fetchData(urls.simulationVehicleAPI, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify(vehiclesRequest),
            maxAttempts: 1,
          });

          if (!vehiclesResponse.ok) {
            throw new Error(vehiclesResponse.status);
          }
        }
      }

      const simulation = currentOffer.$source?.simulation;
      const originalVehiclesFilterExpression =
        simulation?.data?.logistics_api_settings?.vehicles_filter_expression ||
        {};
      const vehiclesFilterExpression =
        currentOffer?.stateless_api_request_data?.logistics_api_settings
          ?.vehicles_filter_expression || {};

      if (
        simulation &&
        !isEqual(originalVehiclesFilterExpression, vehiclesFilterExpression)
      ) {
        const nextSimulation = merge(
          removeInternalFieldsFromObject(simulation),
          {
            data: {
              logistics_api_settings: {
                vehicles_filter_expression: {},
              },
            },
          }
        );

        nextSimulation.data.logistics_api_settings.vehicles_filter_expression =
          vehiclesFilterExpression;

        await updateSimulation(simulation.id, JSON.stringify(nextSimulation));
      }

      return null;
    }
  );

export default updateSimulationHandler;

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { geofencesFilteredSizeSelector } from 'modules/geofences/selectors';

import Panel from './Panel';

const mapStateToProps = createImmutableSelector(
  geofencesFilteredSizeSelector,
  size => ({
    size,
  })
);

const withConnect = connect(mapStateToProps, null);

export default withConnect(Panel);

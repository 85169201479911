import Immutable from 'immutable';
// import { getContrastRandomColor } from 'utils/color';

import * as actions from 'modules/geofences/actions';
import * as commuteOfferActions from 'modules/commuteOffer/actions';

import { normalizeGeofenceColor } from 'utils/color';

import debug from 'utils/debug';

const D2 = debug('m:geofences');

export const initialState = Immutable.Map({
  lastProcessedDataHash: null,
  geofences: null,
});
D2.S.INFO('initialState', { initialState });

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case commuteOfferActions.COMMUTE_OFFER_FETCH_REQUEST: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, () => {
        return state;
      });
    }
    case commuteOfferActions.COMMUTE_OFFER_FETCH_RESULTS: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, () => {
        const { error } = payload;
        if (error) {
          return state;
        }

        const { result } = payload;

        const lastProcessedDataHash = state.get('lastProcessedDataHash');
        if (result.loadedHash === lastProcessedDataHash) {
          return state;
        }

        const { geofences } = result;

        const newGeofences = geofences.map(geofence => ({
          ...geofence,
          color: normalizeGeofenceColor(geofence),
        }));

        return state.set('geofences', newGeofences);
      });
    }
    case actions.GEOFENCES_FETCH_SUCCESS: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, () => {
        const data = payload.objects;

        const newGeofences = data.map(geofence => ({
          ...geofence,
          color: normalizeGeofenceColor(geofence),
        }));

        return state.set('geofences', newGeofences);
      });
    }
    case actions.ADD_GEOFENCE_SUCCESS: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, () => {
        const data = payload;
        const id = payload.id;
        data.color = normalizeGeofenceColor(data);

        if (id) {
          return state.update('geofences', (currentGeofences) => {
            return [...currentGeofences, data];
          });
        }

        return state;
      });
    }
    case actions.UPDATE_GEOFENCE_SUCCESS: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, () => {
        const data = payload;
        data.color = normalizeGeofenceColor(data);
        const { id } = data;

        return state.update('geofences', (currentGeofences) => {
          const index = currentGeofences.findIndex(item => item.id === id);
          const newState = [...currentGeofences];
          newState[index] = data;

          return newState;
        });
      });
    }
    case actions.DELETE_GEOFENCE_SUCCESS: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, () => {
        const key = payload;

        return state.update('geofences', (currentGeofences) => {
          const index = currentGeofences.findIndex(item => item.key === key);
          const newState = [...currentGeofences];
          newState.splice(index, 1);
          return [...newState];
        });
      });
    }
    case actions.CLEAR_LIST: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, () => {
        return state.set('geofences', []);
      });
    }
    default:
      return state;
  }
};

export default reducer;

import styled from 'styled-components';

const Container = styled.div`
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
  margin-left: 10px;
  cursor: pointer;
  color: white;

  &:hover {
    opacity: 0.8;
  }
`;

export default Container;

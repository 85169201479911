import styled from 'styled-components';

const Container = styled.input`
  width: 100%;
  height: 70px;
  margin: 20px 0;
  font-size: 25px;
  background-color: #424a5a;
  color: rgba(227, 226, 235, 0.5);
  text-align: center;
  border: none;
  border-radius: 10px;
`;

export default Container;

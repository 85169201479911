import React from 'utils/react';
import PanelHeader from '../../PanelHeader';
import HiddenButton from './HiddenButton';

const Header = ({ t, size, toggleBookingsHidden, isHidden }) => (
  <PanelHeader
    isHidden={isHidden}
    hiddenWritingMode={t(
      'p.Editor.Panels.Bookings.Header.Bookings.Hidden.WritingMode'
    )}
    hiddenTransform={t(
      'p.Editor.Panels.Bookings.Header.Bookings.Hidden.Transform'
    )}
    hiddenTextAlign={t(
      'p.Editor.Panels.Bookings.Header.Bookings.Hidden.TextAlign'
    )}
  >
    {!isHidden && <HiddenButton onClick={toggleBookingsHidden} />}
    {t('p.Editor.Panels.Bookings.Header.Bookings', {
      count: size,
    })}
  </PanelHeader>
);

export default Header;

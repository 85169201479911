import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { changeSimulationsSearch } from 'modules/ui/actions';
import { fetchSimulations, clearList } from 'modules/simulations/actions';

import { datasetsSearchSelector } from 'modules/ui/selectors';

import Search from 'components/Search';

const mapStateToProps = createImmutableSelector(
  datasetsSearchSelector,
  text => ({
    text,
  })
);

const mapDispatchToProps = {
  onChange: changeSimulationsSearch,
  dataFetch: fetchSimulations,
  clearList,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Search);

import { put, select, call } from 'redux-saga/effects';

import debug from 'utils/debug';

import * as actions from 'modules/commuteOffer/actions';

import {
  allVehiclesSelector,
  commuteOfferLlastProcessedDataHashSelector,
} from 'modules/commuteOffer/selectors';
import { routingEngineSelector } from 'modules/ui/selectors';

import { fetchRoute } from 'utils/RoutingEngine';

const D2 = debug('m:CommuteOffer:saga:fetchAllRoutes');

export const fetchAllRoutesMethod = async (
  vehicles,
  selectedRoutingEngine = 'defined_by_vehicle_settings'
) =>
  D2.A.FUNCTION('Method', {}, async ({ $D2 }) => {
    return await Promise.all(
      vehicles
        .filter(vehicle => vehicle.route && vehicle.route.length >= 2)
        .map(async vehicle =>
          $D2.A.FUNCTION('vehicle', {}, async () => {
            try {
              const routingEngine =
                selectedRoutingEngine !== 'defined_by_vehicle_settings'
                  ? selectedRoutingEngine
                  : vehicle.routing_engine.routing_engine_name;

              const resultRoute = await fetchRoute(vehicle.route, {
                routing_engine: {
                  ...vehicle.routing_engine,
                  routing_engine_name: routingEngine,
                },
              });

              // console.log('--- resultRoute', { agent_id: vehicle.agent_id, resultRoute });
              return { agent_id: vehicle.agent_id, data: resultRoute };
            } catch (error) {
              // console.log('--- resultRoute', { agent_id: vehicle.agent_id, error });
              return { agent_id: vehicle.agent_id, error };
            }
          })
        )
    );
  });

// eslint-disable-next-line import/prefer-default-export
export function* fetchAllRoutesHandler({ type, payload }) {
  D2.S.INFO('Handler:Request');

  try {
    const vehicles = yield select(allVehiclesSelector);
    D2.S.INFO('Handler:vehicles', { vehicles });

    const selectedRoutingEngine = yield select(routingEngineSelector);
    D2.S.INFO('Handler:selectedRoutingEngine', { selectedRoutingEngine });

    const routes =
      selectedRoutingEngine !== 'euclidean' &&
      selectedRoutingEngine !== 'spheroid'
        ? yield call(fetchAllRoutesMethod, vehicles)
        : [];
    D2.S.INFO('Handler:routes', { routes });

    yield put({
      type: actions.FETCH_ALL_ROUTES_SUCCESS,
      payload: { routes },
    });

    const vehicleIds = routes.map(route => route.agent_id);
    D2.S.INFO('Handler:vehicleIds', { vehicleIds });

    if (global.GEODISC_RECALCULATE_TIMESTAMPS_AFTER_LOADING) {
      yield put({
        type: actions.RECALCULATE_VEHICLE_TIME,
        payload: { vehicleIds },
      });
    }

    D2.S.INFO('Handler:Success');
  } catch (error) {
    D2.S.INFO('Handler:Failure', { error });
    yield put({
      type: actions.FETCH_ALL_ROUTES_FAILURE,
      payload: error,
    });
    throw error;
  }
}

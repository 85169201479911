import { styled } from 'baseui';

const ContentV2 = styled('div', ({ $theme, width, isleft }) => {
  const menuContentPosition =
    isleft === 'true'
      ? {
          top: 0,
          right: '100%',
        }
      : {
          bottom: 0,
          left: 0,
          marginLeft: '24px',
        };
  return {
    position: 'absolute',
    padding: '8px 0',
    borderRadius: '10px',
    width: width ? width : '200px',
    background: $theme.colors.menuBackground,
    zIndex: 1,
    ...menuContentPosition,
  };
});

export default ContentV2;

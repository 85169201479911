import bookingsFilterChanged from './bookingsFilterChanged';
import vehiclesFilterChanged from './vehiclesFilterChanged';
import vehiclesLayerClick from './vehiclesLayerClick';
import bookingsLayerClick from './bookingsLayerClick';

export default {
  bookingsFilterChanged,
  vehiclesFilterChanged,
  vehiclesLayerClick,
  bookingsLayerClick,
};

import React from 'utils/react';
import styled from 'styled-components';

import useTranslation from 'utils/react/useTranslation';

const Container = styled.div`
  justify-content: start;
  align-items: center;
  display: flex;
  height: 100%;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 500;
`;

const TextOrders = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-left: 10px;
`;

const Statistics = ({ orders }) => {
  const { t } = useTranslation();
  const countCompletedOrders = orders.filter(
    order => order.state === 'completed'
  ).length;
  return (
    <Container>
      {`${t('statistics.completed')}`}
      <TextOrders>{`${countCompletedOrders}/${orders.length}`}</TextOrders>
    </Container>
  );
};

export default Statistics;

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import createImmutableSelector from 'utils/immutable-selector';

import { fetchSimulations, clearList } from 'modules/simulations/actions';
import {
  toggleSelectedSimulations,
  clearSelectedSimulations,
  toggleCompareMod,
} from 'modules/ui/actions';

import { countSelector, listSelector } from 'modules/simulations/selectors';
import { routePageSelector } from 'modules/router/selectors';

import Table from './Table';

const mapStateToProps = createImmutableSelector(
  listSelector,
  countSelector,
  routePageSelector,
  (list, count, page) => ({
    list,
    count,
    page,
  })
);

const mapDispatchToProps = {
  toggleSelectedSimulations,
  clearSelectedSimulations,
  fetchSimulations,
  toggleCompareMod,
  clearList,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const enhance = compose(withConnect, withRouter);

export default enhance(Table);

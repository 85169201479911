import styled from 'styled-components';

const Content = styled.div`
  position: absolute;
  top: 30px;
  right: 20px;
  padding: 5px 20px 5px 20px;
  border-radius: 10px;
  background: #3b4251;
  z-index: 1;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  overflow: scroll;
  max-height: 470px;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    border: transparent 1px solid;
    background-color: transparent;
  }

  overflow: -moz-scrollbars-none;
`;

export default Content;

const defaultVehicleSets = [
  {
    label: 'Set #1',
    value: 4601,
  },
  {
    label: 'Set #2',
    value: 4608,
  },
  {
    label: 'Set #3',
    value: 4609,
  },
  {
    label: 'Set #4',
    value: 4610,
  },
];

const hardcodedVehicleSets = {
  'https://sgerp-stage.d.gcdev.swatrider.com': {
    '/api/v2/project/139': [
      {
        label: 'Zestaw pojazdów nr 1',
        value: 12311,
      },
      {
        label: 'One vehicle',
        value: 12400,
      },
    ],
  },
};

const getVehicleSetsConfig = (projectUrl) => {
  const serverConfig = hardcodedVehicleSets[global.GEODISC_API_URL];
  const projectConfig =
    serverConfig && projectUrl ? serverConfig[projectUrl] : undefined;
  return projectConfig || defaultVehicleSets;
};

export default getVehicleSetsConfig;

import styled from 'styled-components';

const Container = styled.div`
  width: ${({ width }) => width || '16%'};
  margin: 5px 0;
  letter-spacing: 0;
  flex-shrink: 0;
  padding-right: 10px;
  word-wrap: break-word;
  max-height: 20px;
  overflow: hidden;
  padding-bottom: 2px;

  border-bottom: solid ${({ header }) => (header ? '1px' : '0')} #c8ced8;
  box-sizing: content-box;
`;

export default Container;

import styled from 'styled-components';

const Title = styled.div`
  font-size: 25px;
  font-weight: 600;
  color: #edece8;
  margin: 0;
`;

export default Title;

import React from 'utils/react';

import Menu from 'components/Menu';

import Button from './Button';
import withTranslation from 'utils/react/withTranslation';

const MenuBottom = ({
  t,
  duplicateCommuteOffer,
  exportCommuteOffer,
  deleteCommuteOffer,
}) => (
  <Menu>
    <Button onClick={duplicateCommuteOffer}>
      {t('c.MenuButtom.makeCopy')}
    </Button>
    {false && <Button onClick={exportCommuteOffer}>Export JSON</Button>}
    <Button onClick={deleteCommuteOffer}>{t('c.messages.Delete')}</Button>
  </Menu>
);

export default withTranslation()(MenuBottom);

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 350px;
  bottom: 0;
  padding: 14px 15px 20px;
  background: rgb(54, 61, 74);
  z-index: 20;

  h2 {
    margin: 0;
  }
`;

export default Container;

import styled from 'styled-components';

const Border = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  background: ${({ color }) => color};
  border-radius: 4px;
`;

export default Border;

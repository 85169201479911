import withTranslation from 'utils/react/withTranslation';
import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { isDeliveryLayoutSelector } from 'modules/user/selectors';
import { isBookingsHiddenSelector } from 'modules/ui/selectors';

import Statistics from './Statistics';

const mapStateToProps = createImmutableSelector(
  isBookingsHiddenSelector,
  isDeliveryLayoutSelector,
  (isBookingsHidden, isDeliveryLayout) => ({
    isBookingsHidden,
    isDeliveryLayout,
  })
);

const mapDispatchToProps = null;

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation()(withConnect(Statistics));

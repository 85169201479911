import React, { createContext, useEffect, useState } from 'react';

export const SelectedAddressContext = createContext();

const OrderSelectedDataContextProvider = (props) => {
  const [selectedData, setSelectedData] = useState({});

  return (
    <SelectedAddressContext.Provider value={{ selectedData, setSelectedData }}>
      {props.children}
    </SelectedAddressContext.Provider>
  );
};

export default OrderSelectedDataContextProvider;

/* eslint-disable */
import React from 'utils/react';
import useTranslation from 'utils/react/useTranslation';
import { DragSource, DropTarget } from 'react-dnd';
import moment from 'moment-timezone';
import Button from 'pages/CommuteOffer/TextButton';
import debug from 'utils/debug';

import Container from './Container';
import ButtonContainer from './ButtonsContainer';
import NodeType from './NodeType';
import Row from './Row';
import Info from './Info';
import Header from './Header';
import ArrivalTime from './ArrivalTime';
import DepartureTime from './DepartureTime';
import Location from './Location';
import IndexBadge from './IndexBadge';
import ServiceTimeTitle from './ServiceTimeTitle';
import ServiceTimeCell from './ServiceTimeCell';
import IdleTimeCell from './IdleTimeCell';

import formatDurationTime from 'utils/moment/formatDurationTime';
import withTranslation from 'utils/react/withTranslation';

const D2 = debug('p:CommuteOffer:Panels:Vehicles:Vehicle:Route');

export const Stop = React.forwardRef((props, ref) => {
  D2.S.INFO('Stop', { props });

  const { t, i18n } = useTranslation();

  const {
    index,
    isActive,
    hasNodesWithInvalidScheduledTime,
    isReadOnly,
    vehicleId,
    onClick,
    color,
    isHaveEditable,
    stop,
    isDragging,
    connectDragSource,
    connectDropTarget,
    deleteRoutePoint,
    cleanActiveRouteStop,
    isDeliveryLayout,
  } = props;

  const isCompleted =
    stop.status === 'completed' || !!stop.completed_service_at;

  const elementRef = React.useRef(null);
  if (connectDragSource) {
    connectDragSource(elementRef);
  }
  if (connectDropTarget) {
    connectDropTarget(elementRef);
  }
  const opacity = isDragging ? 0.3 : 1;
  React.useImperativeHandle(ref, () => ({
    getNode: () => elementRef.current,
  }));

  const readOnlyError = () => {
    // global.openInfoMessage('Vehicle is read-only', { title: 'Error' });
  };

  const onDeleteRoutePoint = () => {
    if (!isReadOnly && !isCompleted) {
      deleteRoutePoint(stop.uid, vehicleId);
      cleanActiveRouteStop();
    } else {
      readOnlyError();
    }
  };

  const {
    slack = 0,
    service_time = 0,
    scheduled_ts,
    estimated_scheduled_ts,
    completed_service_at,
    location_name,
    node_type,
  } = stop;

  const isPoint = node_type === 'point' || node_type === 'stop';

  const isRealtime = !!estimated_scheduled_ts && !isCompleted;
  const isScheduled = !isRealtime && !isCompleted;

  const scheduledTs = moment(scheduled_ts)
    .tz(global.GEODISC_TIMEZONE)
    .format('HH:mm');

  const nodeArrivalTs = moment(
    completed_service_at || estimated_scheduled_ts || scheduled_ts
  );
  const nodeArrivalTime = nodeArrivalTs
    .tz(global.GEODISC_TIMEZONE)
    .format('HH:mm');

  let nodeDepartureTs = moment(nodeArrivalTs);
  nodeDepartureTs.add(service_time + slack, 'seconds');
  const nodeDepartureTime = nodeDepartureTs
    .tz(global.GEODISC_TIMEZONE)
    .format('HH:mm');

  const hrPax =
    !isPoint && t(`u.capacity.type.${stop.pax_type}`, { count: stop.pax });

  const demandInfo = stop.bookings
    .filter((booking) => typeof booking.$node !== 'undefined')
    .reduce((demandInfoMemo, booking) => {
      return {
        ...demandInfoMemo,
        ...Object.entries(
          booking.$node?.demand || booking.node?.demand || {}
        ).reduce(
          (memo, [k, v]) => ({
            ...memo,
            [k]: demandInfoMemo[k]
              ? demandInfoMemo[k] + Math.abs(v)
              : Math.abs(v),
          }),
          {}
        ),
      };
    }, {});

  return (
    <Container
      isActive={isActive}
      color={color}
      isDropoff={node_type === 'dropoff'}
    >
      <div ref={elementRef} style={Object.assign({}, { opacity })}>
        <Header onClick={onClick}>
          <Location hasErrors={hasNodesWithInvalidScheduledTime}>
            <IndexBadge hasErrors={hasNodesWithInvalidScheduledTime}>
              {index + 1}
            </IndexBadge>
            &nbsp;-&nbsp;{location_name || '---'}
          </Location>
          <Row key='node-type'>
            <NodeType>{node_type}</NodeType>
          </Row>
          {Object.entries(demandInfo)
            .filter(([name, value]) => !!value)
            .sort(([a], [b]) => a - b)
            .map(([name, value]) => (
              <Row key={`demand-${name}`}>
                <NodeType>{name}</NodeType>
                {value}
              </Row>
            ))}
          {!isDeliveryLayout && (
            <Row>
              <NodeType>{t('geofence.Stop.Scheduled')}</NodeType>
              {scheduledTs}
            </Row>
          )}
          <table>
            <tbody>
              {!!(service_time && service_time >= 60) && (
                <tr>
                  <ServiceTimeTitle>
                    {t('c.messages.ServiceTime')}:&nbsp;
                  </ServiceTimeTitle>
                  <ServiceTimeCell>
                    {formatDurationTime(service_time, i18n.language)}
                  </ServiceTimeCell>
                </tr>
              )}
              {!!(slack && slack >= 60) && (
                <tr>
                  <IdleTimeCell>{t('c.messages.IdleTime')}:&nbsp;</IdleTimeCell>
                  <IdleTimeCell>
                    {formatDurationTime(slack, i18n.language)}
                  </IdleTimeCell>
                </tr>
              )}
            </tbody>
          </table>
          {isScheduled && <ArrivalTime>{nodeArrivalTime}</ArrivalTime>}
          {isScheduled && nodeDepartureTime !== nodeArrivalTime && (
            <DepartureTime>{nodeDepartureTime}</DepartureTime>
          )}
          {isRealtime && (
            <ArrivalTime>&#x231a;&nbsp;{nodeArrivalTime}</ArrivalTime>
          )}
          {isRealtime && nodeDepartureTime !== nodeArrivalTime && (
            <DepartureTime>&#x231a;&nbsp;{nodeDepartureTime}</DepartureTime>
          )}
          {isCompleted && (
            <ArrivalTime>&#10003;&nbsp;{nodeArrivalTime}</ArrivalTime>
          )}
        </Header>
        {isActive && !isPoint && (
          <Info
            isReadOnly={isReadOnly || isCompleted}
            stop={stop}
            bookings={stop.bookings}
            isHaveEditable={isHaveEditable}
          />
        )}
        {isActive && isPoint && (
          <ButtonContainer>
            {!isReadOnly && (
              <Button
                onClick={onDeleteRoutePoint}
                disabled={isReadOnly || isCompleted}
              >
                {t('c.messages.Delete')}
              </Button>
            )}
          </ButtonContainer>
        )}
      </div>
    </Container>
  );
});

export const FixedStop = DropTarget(
  'stop',
  {
    hover(props, monitor, component) {
      return null;
    },
  },
  (connect) => ({
    connectDropTarget: connect.dropTarget(),
  })
)(
  DragSource(
    'stop',
    {
      beginDrag: (props) => ({
        id: props.id,
        index: props.index,
      }),
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  )(Stop)
);

export const DraggableStop = withTranslation()(
  DropTarget(
    'stop',
    {
      hover(props, monitor, component) {
        if (!component) {
          return null;
        }
        // node = HTML Div element from imperative API
        const node = component.getNode();
        if (!node) {
          return null;
        }
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return null;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = node.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return null;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return null;
        }
        // Time to actually perform the action
        props.moveCard(dragIndex, hoverIndex);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
        return null;
      },
    },
    (connect) => ({
      connectDropTarget: connect.dropTarget(),
    })
  )(
    DragSource(
      'stop',
      {
        beginDrag: (props) => ({
          id: props.id,
          index: props.index,
        }),
      },
      (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
      })
    )(Stop)
  )
);

import styled from 'styled-components';

export default styled.div`
  position: fixed;
  bottom: 5px;
  left: 0px;
  height: 65px;

  width: ${({ isBookingsHidden }) => (isBookingsHidden ? '360px' : '610px')};

  padding: 10px;
  border-radius: 10px;
  margin: 10px 0px 10px 10px;

  background-color: rgb(54, 61, 74);
  cursor: pointer;
`;

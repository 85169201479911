import { removeInternalFieldsFromObject } from 'utils';

import debug from 'utils/debug';
import { normalizeCommuteOffer } from './normalizeCommuteOffer';

const D2 = debug('u:CommuteOffer:Validation');

export const normalizeCommuteOfferForExport = async commuteOffer =>
  D2.A.FUNCTION(
    'normalizeCommuteOfferForExport',
    { commuteOffer },
    async ({ $D2 }) => {
      const validatedOffer = await normalizeCommuteOffer(commuteOffer);
      $D2.S.INFO('validatedOffer', { validatedOffer });

      const publicCommuteOffer = removeInternalFieldsFromObject(validatedOffer);
      $D2.S.INFO('publicCommuteOffer', { publicCommuteOffer });

      return publicCommuteOffer;
    }
  );

export default normalizeCommuteOfferForExport;

import { styled } from 'baseui';
import React from 'react';
import arrow from 'assets/accordionArrow.svg';

const Arrow = styled('img', ({ $isActive }) => {
  return {
    width: '10px',
    marginTop: '5px',
    marginRight: '13px',
    transform: $isActive ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: 'transform 0.5s',
    float: 'left',
  };
});

Arrow.defaultProps = {
  src: arrow,
};

const AccordionHeader = styled('div', () => {
  return {
    width: '100%',
    margin: '16px 0',
  };
});

const AccordionBody = styled('div', () => {
  return {
    width: '100%',
  };
});

export default (props) => {
  const { title, children, accordionState, setAccordionState } = props;

  return (
    <React.Fragment>
      <AccordionHeader
        $isActive={accordionState}
        onClick={() => setAccordionState(act => !act)}
      >
        <Arrow $isActive={accordionState} />
        <h2
          style={{
            fontSize: '16px',
            display: 'inline-block',
            cursor: 'pointer',
            margin: '0',
            fontFamily: 'Montserrat',
          }}
        >
          {title}
        </h2>
      </AccordionHeader>
      <AccordionBody $isActive={accordionState}>
        {accordionState && children}
      </AccordionBody>
    </React.Fragment>
  );
};

import moment from 'moment-timezone';
import deepmerge from 'deepmerge';
import createImmutableSelector from 'utils/immutable-selector';
import cloneLocation from 'utils/location';

import projects from 'config/projects';

import { defaultVehicleRoutingEngineJSON } from 'utils/CommuteOffer/defaults';

import { commuteOffersServiceDateSelector } from 'modules/ui/selectors';
import { LOGISTICS_ROUTE } from 'utils/constants';

import debug from 'utils/debug';
const D2 = debug('m:user:selectors');

export const userState = state => state;

export const userSelector = createImmutableSelector(userState, (state) => {
  D2.S.COUNTER('userSelector');
  const user = state.get('user');
  return user;
});

export const windowLocationSelector = createImmutableSelector(
  userSelector,
  (user) => {
    const windowLocation = user?.get('location');
    return windowLocation ?? cloneLocation();
  }
);

export const userNameSelector = createImmutableSelector(
  userSelector,
  (user) => {
    return user?.get('username');
  }
);

export const isSuperuserSelector = createImmutableSelector(
  userSelector,
  user => user?.get('is_superuser') || false
);

export const isStaffSelector = createImmutableSelector(
  userSelector,
  user => user?.get('is_staff') || false
);

const baseUserConfig = {
  geodisc_settings: {
    pages: {
      datasets: true,
      geofences: true,
      simulations: true,
      commute_offers: true,
      logistics_services: true,
      logistics_template: false,
    },
    panels: {
      vehicles: {
        display_routing_engine: false,
      },
    },
  },
};

const regularUserConfig = {
  ...baseUserConfig,
};

const adminUserConfig = {
  ...baseUserConfig,
  geodisc_settings: {
    ...baseUserConfig.geodisc_settings,
    pages: {
      ...baseUserConfig.geodisc_settings.pages,
      logistics_template: true,
    },
    panels: {
      ...baseUserConfig.geodisc_settings.panels,
      vehicles: {
        ...baseUserConfig.geodisc_settings.panels.vehicles,
        display_routing_engine: true,
      },
    },
  },
};

const emptyUserConfig = {
  ...baseUserConfig,
  geodisc_settings: {
    ...baseUserConfig.geodisc_settings,
    pages: {
      ...baseUserConfig.geodisc_settings.pages,
      datasets: false,
      geofences: false,
      simulations: false,
      commute_offers: false,
      logistics_services: false,
      logistics_template: false,
    },
  },
};

export const currentUserConfigSelector = createImmutableSelector(
  userSelector,
  isSuperuserSelector,
  (user, isSuperuser) =>
    D2.S.SELECTOR('currentUserConfigSelector', { user, isSuperuser }, () => {
      if (user) {
        const config = user.get('options')?.toJS() || {};
        return deepmerge.all([
          {},
          isSuperuser ? adminUserConfig : regularUserConfig,
          config,
        ]);
      }
      return emptyUserConfig;
    })
);

export const projectsSelector = createImmutableSelector(userSelector, user =>
  user?.get('projects')
);

export const projectMembersSelector = createImmutableSelector(
  userSelector,
  user =>
    D2.S.SELECTOR('projectMembersSelector', { user }, () => {
      return user?.get('projectMembers');
    })
);

export const driverProjectsSelector = createImmutableSelector(
  userSelector,
  user => user?.get('driverProjects')
);

export const currentProjectSelector = createImmutableSelector(
  userSelector,
  (user) => {
    return D2.S.SELECTOR('currentProjectSelector', { user }, ({ $D2 }) => {
      const project = user && user.get('currentProject');
      const timezone = project?.get?.('timezone') ?? 'UTC';

      // moment.tz.setDefault(timezone);
      global.GEODISC_TIMEZONE = timezone;

      return project;
    });
  }
);

export const labelsOfCurrentProjectSelector = createImmutableSelector(
  currentProjectSelector,
  (project) => {
    return D2.S.SELECTOR(
      'labelsOfCurrentProjectSelector',
      { project },
      ({ $D2 }) => {
        const projectData =
          project && project.get('data') && project.get('data').toJS();

        const allVehicleLabels = projectData?.all_vehicle_labels || [];
        return allVehicleLabels;
      }
    );
  }
);

const defaultProjectMemberInfo = {
  allow_change: false,
  allow_manage: false,
};

export const currentProjectMemberInfoSelector = createImmutableSelector(
  userSelector,
  currentProjectSelector,
  projectMembersSelector,
  (user, currentProject, projectMembers) => {
    if (!user || !currentProject || !projectMembers) {
      return defaultProjectMemberInfo;
    }
    const currentProjectMember = projectMembers.find(
      projectMember => projectMember.project === currentProject.resource_uri
    );
    const currentProjectMemberInfo = currentProjectMember
      ? currentProjectMember.toJS()
      : defaultProjectMemberInfo;
    return currentProjectMemberInfo;
  }
);

export const currentUserAllowChangeSelector = createImmutableSelector(
  currentProjectMemberInfoSelector,
  isSuperuserSelector,
  (currentProjectMemberInfo, isSuperuser) => {
    const { allow_change } = currentProjectMemberInfo;
    return isSuperuser || allow_change;
  }
);

export const currentUserAllowManageSelector = createImmutableSelector(
  currentProjectMemberInfoSelector,
  isSuperuserSelector,
  (currentProjectMemberInfo, isSuperuser) => {
    const { allow_manage } = currentProjectMemberInfo;
    return isSuperuser || allow_manage;
  }
);

export const currentProjectIdSelector = createImmutableSelector(
  currentProjectSelector,
  (project) => {
    D2.S.INFO('currentProjectIdSelector', { project });
    return project && project.get('id');
  }
);

export const currentProjectUrlSelector = createImmutableSelector(
  currentProjectSelector,
  project => project && project.get('resource_uri')
);

export const currentProjectConfigSelector = createImmutableSelector(
  currentProjectSelector,
  currentUserConfigSelector,
  (project, currentUserConfig) => {
    return D2.S.SELECTOR(
      'currentProjectConfigSelector',
      { project, currentUserConfig },
      () => {
        const currentProjectConfig = project
          ? project.get('data')?.toJS() || projects[project.get('name')] || {}
          : {};
        return currentProjectConfig;
      }
    );
  }
);

const layoutNames = {
  default: 'default',
  generic: 'default',
  delivery: 'delivery',
  logistics: 'delivery',
};

export const currentProjectLayoutNameSelector = createImmutableSelector(
  currentProjectConfigSelector,
  config =>
    D2.S.SELECTOR(
      'currentProjectLayoutNameSelector',
      { config },
      () =>
        layoutNames[String(config.layout_name).toLowerCase().trim()] ||
        'default'
    )
);

export const currentProjectVehicleModelDefaultsSelector =
  createImmutableSelector(
    currentProjectConfigSelector,
    currentProjectLayoutNameSelector,
    (projectConfig, layoutName) =>
      D2.S.SELECTOR(
        'currentProjectVehicleModelDefaultsSelector',
        { projectConfig, layoutName },
        () => {
          const defaultVehicleModelBase = {
            routing_engine_settings: {
              ...JSON.parse(defaultVehicleRoutingEngineJSON),
              routing_engine_name: 'osrme',
              road_network: 'alldriving',
            },
          };

          const defaultVehicleModels = {
            delivery: {
              ...defaultVehicleModelBase,
              capacity: { units: 40 },
            },
            default: {
              ...defaultVehicleModelBase,
              capacity: { passenger: 40 },
            },
          };

          const vehicleModelBase =
            defaultVehicleModels[layoutName] ?? defaultVehicleModels.default;

          const vehicleModelDefaults =
            projectConfig.logistics_settings?.vehicle_model_defaults ?? {};

          const vehicleModel = deepmerge.all([
            {},
            vehicleModelBase,
            vehicleModelDefaults,
          ]);

          return vehicleModel;
        }
      )
  );

export const currentProjectVehicleModelsSelector = createImmutableSelector(
  currentProjectConfigSelector,
  currentProjectLayoutNameSelector,
  currentProjectVehicleModelDefaultsSelector,
  (projectConfig, layoutName, vehicleModelDefaults) =>
    D2.S.SELECTOR(
      'currentProjectVehicleModelsSelector',
      { projectConfig, layoutName, vehicleModelDefaults },
      () => {
        const normalizeVehicleModels = models =>
          Array.isArray(models) ? models : [];

        const vehicleModelOverrides =
          projectConfig.logistics_settings?.vehicle_model_overrides ?? {};

        const projectVehicleModels = normalizeVehicleModels(
          projectConfig?.vehicle_models
        ).map(projectVehicleModel => ({
          ...vehicleModelDefaults,
          ...deepmerge.all([{}, projectVehicleModel, vehicleModelOverrides]),
        }));
        const vehicleModels =
          projectVehicleModels.length > 0
            ? projectVehicleModels
            : [
                deepmerge.all([
                  {},
                  vehicleModelDefaults,
                  vehicleModelOverrides,
                ]),
              ];

        return vehicleModels;
      }
    )
);

const defaultCapacitySettingsConfig = {
  default: {
    field: 'passenger',
    type: 'passenger',
  },
  delivery: {
    field: 'passenger',
    type: 'unit',
  },
};

const getDefaultCapacitySettings = layoutName =>
  defaultCapacitySettingsConfig[layoutName] ??
  defaultCapacitySettingsConfig.default;

export const capacityFunctorSelector = createImmutableSelector(
  currentProjectConfigSelector,
  currentProjectLayoutNameSelector,
  (currentProjectConfig, currentLayoutName) =>
    D2.S.SELECTOR('capacityFunctorSelector', { currentProjectConfig }, () => {
      const defaultCapacitySettings =
        getDefaultCapacitySettings(currentLayoutName);

      const { capacity_settings = {} } = currentProjectConfig;

      const currentCapacitySettings = {
        ...defaultCapacitySettings,
        ...capacity_settings,
      };

      const capacityFieldName = currentCapacitySettings?.field || 'passenger';

      const defaultFieldType =
        capacityFieldName === 'passenger' ? 'passenger' : 'unit';
      const capacityFieldType =
        currentCapacitySettings?.type || defaultFieldType;

      const functor =
        capacityFieldName === 'passenger'
          ? demand =>
              demand.passenger || demand.passengers || demand.mon_passenger || 0
          : demand => demand[capacityFieldName] || 0;

      return {
        functor,
        type: capacityFieldType,
      };
    })
);

const defaultPages = {
  delivery: LOGISTICS_ROUTE,
};

export const defaultPageSelector = createImmutableSelector(
  currentProjectSelector,
  currentProjectLayoutNameSelector,
  (currentProject, layoutName) =>
    D2.S.SELECTOR('defaultPageSelector', { currentProject, layoutName }, () => {
      const defaultRedirectAddress =
        window.GEODISC_UI_DEFAULT_ROUTE || '/geofences';
      const serviceDate = global.geodisc$select(
        commuteOffersServiceDateSelector
      );
      const projectId = global.geodisc$select(currentProjectIdSelector);
      const logisticsServicesURL = `${defaultPages[layoutName]}?project-id=${projectId}&simulation-date=${serviceDate}`;
      return currentProject && defaultPages[layoutName]
        ? logisticsServicesURL || defaultRedirectAddress
        : defaultRedirectAddress;
    })
);

export const isDeliveryLayoutSelector = createImmutableSelector(
  currentProjectLayoutNameSelector,
  layoutName =>
    D2.S.SELECTOR(
      'isDeliveryLayoutSelector',
      { layoutName },
      () => layoutName === 'delivery' || layoutName === 'logistics'
    )
);

export const currentProjectLogisticsSettingsSelector = createImmutableSelector(
  currentProjectConfigSelector,
  currentProjectConfig =>
    D2.S.SELECTOR(
      'currentProjectLogisticsSettingsSelector',
      { currentProjectConfig },
      () => {
        return {
          ...(currentProjectConfig?.logistics ?? {}),
          ...(currentProjectConfig?.logistics_settings ?? {}),
          ...(currentProjectConfig?.logistics_api_settings ?? {}),
        };
      }
    )
);

export const isCurrentDateEditableSelector = createImmutableSelector(
  currentProjectMemberInfoSelector,
  isSuperuserSelector,
  (currentProjectMemberInfo, isSuperuser) =>
    D2.S.SELECTOR(
      'isCurrentDateEditableSelector',
      {
        currentProjectMemberInfo,
        isSuperuser,
      },
      () => {
        const serviceDate = global.geodisc$select(
          commuteOffersServiceDateSelector
        );

        const currentDate = moment().format('YYYY-MM-DD');
        const isPastDate = currentDate > serviceDate;

        const { allow_change } = currentProjectMemberInfo;

        const isCurrentDateEditable =
          (allow_change || isSuperuser) && !isPastDate;
        return isCurrentDateEditable;
      }
    )
);

export const canManageVehiclesSelector = createImmutableSelector(
  currentProjectLogisticsSettingsSelector,
  isDeliveryLayoutSelector,
  currentProjectMemberInfoSelector,
  isSuperuserSelector,
  (
    currentProjectLogisticsSettings,
    isDeliveryLayout,
    currentProjectMemberInfo,
    isSuperuser
  ) =>
    D2.S.SELECTOR(
      'canManageVehiclesSelector',
      {
        currentProjectLogisticsSettings,
        isDeliveryLayout,
        currentProjectMemberInfo,
      },
      () => {
        const { can_manage_vehicles = false } = currentProjectLogisticsSettings;
        const { allow_change } = currentProjectMemberInfo;
        const canManageVehicles = isDeliveryLayout
          ? !!can_manage_vehicles
          : true;
        return canManageVehicles && (allow_change || isSuperuser);
      }
    )
);

export const isAdminSelector = createImmutableSelector(
  userSelector,
  user => user && user.get('is_superuser')
);

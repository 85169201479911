import React from 'utils/react';
import Start from 'components/Star';
import Menu from 'components/Menu';

import Container from './Container';
import MenuButton from './MenuButton';
import withTranslation from 'utils/react/withTranslation';

const Buttons = (props) => {
  const {
    dataset,
    bookmarked,
    updateDataset,
    deleteDataset,
    setEditableDataset,
    downloadDatasetCsv,
    t,
  } = props;

  const id = dataset.get('id');

  const onClickStar = () => {
    const body = JSON.stringify({ bookmarked: !bookmarked });
    updateDataset(id, body);
  };

  return (
    <Container>
      <Start isActive={bookmarked} onClick={onClickStar} />
      <Menu>
        <MenuButton onClick={() => downloadDatasetCsv([id])}>
          {t('c.Table.Row.ExportCSV')}
        </MenuButton>
        <MenuButton onClick={() => setEditableDataset(dataset)}>
          {t('c.Table.Row.Edit')}
        </MenuButton>
        <MenuButton onClick={() => deleteDataset(id)}>
          {t('c.Table.Row.Delete')}
        </MenuButton>
      </Menu>
    </Container>
  );
};

export default withTranslation()(Buttons);

import React from 'react';
import debug from 'utils/debug';

import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as OrdersIcon } from 'assets/orders.svg';
import { ReactComponent as MapIcon } from 'assets/map.svg';
import { ReactComponent as TemplateIcon } from 'assets/template.svg';
import { ReactComponent as SettingsIcon } from 'assets/settings.svg';
import { ReactComponent as RoutesIcon } from 'assets/routes.svg';
import { ReactComponent as FleetSettingsIcon } from 'assets/fleetSettings.svg';
import AdvancedSettings from 'components/AdvancedSettings';
import Fleet from 'components/Fleet';
import { LOGISTICS_ROUTE } from 'utils/constants';
import { useHistory } from 'react-router-dom';

const D2 = debug('c:SideNavBar');

const navStyles = `
  display: block;
  color: #97a0c0;
  padding: 18px 16px;
  cursor: pointer;

  text-decoration: none;
  text-align: center;

  &.active {
    color: #fff;
    background-color: #1C232D;
    border-left: 2px solid #fff;
  }

  &:hover {
    color: #fff;
    background-color: #1C232D;
  }

  & svg {
    display: block;
    height: 20px;
    margin: 0 auto 6px;
  }
`;
const ALink = styled.a`
  ${navStyles}
`;

const NavLink = styled(Link)`
  ${navStyles}
`;

const TopButtonGroup = styled.div`
  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.6;
      pointer-events: none;
      > a.active {
        border-left: 0px;
        background-color: transparent;
        color: #97a0c0;
      }
    `}
`;

const Container = styled.div`
  position: fixed;

  left: 8px;
  width: 100px;
  top: 8px;
  bottom: 58px;

  background-color: rgba(12, 15, 20, 0.75);
  backdrop-filter: blur(4px);
  border-radius: 16px;
  font-size: 12px;
  padding: 24px 0px;
  display: flex;
  flex-direction: column;
`;

const Spacer = styled.div`
  flex: 1;
`;

const SideNavBar = (props) => {
  const { t } = useTranslation();

  const browserhistory = useHistory();

  const { pageAddress, commuteOfferRequestUpdate, disabled } = props;
  return (
    <Container>
      <TopButtonGroup disabled={disabled}>
        <NavLink to={pageAddress({ page: LOGISTICS_ROUTE })} exact>
          <OrdersIcon />
          {t('logistics.sidebar.orders')}
        </NavLink>
        <NavLink to={pageAddress({ page: `${LOGISTICS_ROUTE}/routes` })}>
          <RoutesIcon />
          {t('logistics.sidebar.routes')}
        </NavLink>
        <NavLink to={pageAddress({ page: `${LOGISTICS_ROUTE}/geofences` })}>
          <MapIcon />
          {t('logistics.sidebar.geofence')}
        </NavLink>
        <ALink
          onClick={() => {
            global.openFullScreen({ modalContent: <AdvancedSettings /> });
            const params = new URLSearchParams(window.location.search);
            params.set('page', 'advancedSetting');
            browserhistory.push(`${window.location.pathname}?${params}`);
          }}
          data-testid='Advanced settings'
        >
          <SettingsIcon />
          {t('c.logistics.sidebar.advancedSettings')}
        </ALink>
        <ALink
          to='/logistics'
          onClick={() => {
            global.openFullScreen({ modalContent: <Fleet /> });
            const params = new URLSearchParams(window.location.search);
            params.set('page', 'fleetSetting');
            browserhistory.push(`${window.location.pathname}?${params}`);
          }}
        >
          <FleetSettingsIcon data-testid='Fleet Settings' />
          {t('c.logistics.sidebar.fleet')}
        </ALink>
      </TopButtonGroup>
      <Spacer />

      <NavLink
        to={pageAddress({
          page: `${LOGISTICS_ROUTE}/template`,
          previousPage: window.location.pathname,
          ...D2.CONTEXT,
        })}
        onClick={() => {
          commuteOfferRequestUpdate();
        }}
      >
        <TemplateIcon />
        {t('logistics.sidebar.template')}
      </NavLink>
    </Container>
  );
};

export default SideNavBar;

import styled from 'styled-components';

const opts = { collapsed: 360, expanded: 610 };

export default styled.div`
  position: fixed;
  bottom: 5px;
  left: ${({ isBookingsHidden }) =>
    (isBookingsHidden ? opts.collapsed : opts.expanded) - 20}px;
  height: 65px;

  width: calc(
    100% -
      ${({ isBookingsHidden }) =>
        (isBookingsHidden ? opts.collapsed : opts.expanded) - 10}px
  );

  padding: 10px;
  border-radius: 10px;
  margin: 10px 0px 10px 10px;

  background-color: rgb(54, 61, 74);
  cursor: pointer;
`;

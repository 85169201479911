import styled from 'styled-components';

const Separator = styled.div`
  width: 1px;
  height: 27px;
  background: #a7aeb9;
  margin: 0 20px;
`;

export default Separator;

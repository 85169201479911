import { readXlsFileAsync } from 'utils/xls';
import { readCsvFileAsync } from 'utils/csv';
import logisticsCsvToJson from 'utils/Logistics/OrdersTable/Import/logisticsCsvToJson';
import getColumnLabelsForLanguage from 'utils/Logistics/OrdersTable/Export/getColumnLabelsForLanguage';
import { sendLogisticsOrders } from 'api/logistics';
import { validateCSV } from './csvValidations';

export const csvUpload = async (
  acceptedFiles,
  importColumnMap,
  simulation,
  updateSimulation,
  commuteOfferData,
  currentProjectConfig,
  timezone,
  errorHandler,
  successHandler,
  existExternalIdList = []
) => {
  const ACCEPTED_FILE_TYPES = [
    'text/csv',
    'text/plain',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];

  const filteredFiles = acceptedFiles.filter(file =>
    ACCEPTED_FILE_TYPES.includes(file.type)
  );

  if (!filteredFiles.length) {
    return;
  }

  try {
    // need to stop the auto poll
    global.GEODISC_SIMULATION_REFRESH_DISABLED = true;
    const csvResults = (
      await Promise.all(
        filteredFiles.map(async acceptedFile =>
          acceptedFile.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ? readXlsFileAsync(acceptedFile)
            : readCsvFileAsync(acceptedFile)
        )
      )
    ).filter(Boolean);

    const jsResults = await Promise.all(
      csvResults.map(csvData =>
        logisticsCsvToJson({ data: csvData, importColumnMap })
      )
    );

    const jsData = jsResults.reduce(
      (memo, jsResult) => [...memo, ...jsResult.objects],
      []
    );

    const optionalFields = jsResults[0]?.remarks;
    const columnNames = getColumnLabelsForLanguage();

    jsData.map((single, index) => {
      // Transform dynamic demand types and demand loads to a single object
      const demandFields = {};
      optionalFields[0].map((data, optionalIndex) => {
        if (
          data.indexOf(columnNames.demand_type_dynamic.toLowerCase()) === 0 ||
          data.indexOf(columnNames.demand_load_dynamic.toLowerCase()) === 0
        ) {
          demandFields[data] = optionalFields[index + 1][optionalIndex];
        }
      });

      Object.keys(demandFields).forEach((data) => {
        if (data.indexOf(columnNames.demand_type_dynamic.toLowerCase()) === 0) {
          const demandTypeNumber = data.split(
            columnNames.demand_type_dynamic.toLowerCase()
          )[1];
          jsData[index].demand_types = {
            ...jsData[index].demand_types,
            [demandFields[data]]:
              demandFields[
                columnNames.demand_load_dynamic.toLowerCase() + demandTypeNumber
              ],
          };
        }
      });
      //==
      if (single.vehicle_labels) {
        const lowercaseLabels = (jsData[index].vehicle_labels || [])
          .split(',')
          .map((label) => {
            return label?.toLowerCase().trim().replace(/ /g, '_');
          });

        jsData[index].vehicle_labels = {
          [jsData[index].label_operator?.toLowerCase() || 'or']: [
            ...new Set(lowercaseLabels),
          ],
        };
      }
    });
    const { hasImportError, importErrors, jsDataWithDates } = validateCSV(
      jsData,
      existExternalIdList,
      simulation,
      timezone,
      currentProjectConfig?.vehicle_models
    );
    if (hasImportError) {
      errorHandler(importErrors);
      global.GEODISC_SIMULATION_REFRESH_DISABLED = false;
      return;
    }

    // Select latest dropoff time
    /*
    Last dropoff time is the lst minute of the latest dropoff date
    */

    const { dropoff_close_date_ts, dropoff_close_time_ts } = commuteOfferData;

    const latestDropoffTimeStamp =
      dropoff_close_date_ts && dropoff_close_time_ts
        ? moment.tz(
            dropoff_close_date_ts + ' ' + '23:59:59',
            'DD/MM/YYYY HH:mm:ss',
            timezone
          )
        : moment(simulation.start_time).tz(timezone);

    let latestDropoffTimeOfSimulation = moment(simulation.end_time).tz(
      timezone
    );

    latestDropoffTimeOfSimulation =
      latestDropoffTimeOfSimulation > latestDropoffTimeStamp
        ? latestDropoffTimeOfSimulation
        : latestDropoffTimeStamp;

    const body = JSON.stringify({
      end_time: latestDropoffTimeOfSimulation.format(),
    });
    const result = await sendLogisticsOrders(simulation.id, jsDataWithDates, {
      defaultDate: moment(simulation.start_time)
        .tz(timezone)
        .format('YYYY-MM-DD'),
      timezone,
      currentProjectConfig,
    });

    if (result.response?.error || result.error) {
      throw new Error(result.error);
    }
    updateSimulation(simulation.id, body);
    successHandler(result);
    global.GEODISC_SIMULATION_REFRESH_DISABLED = false;
  } catch (e) {
    global.GEODISC_SIMULATION_REFRESH_DISABLED = false;
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

import styled from 'styled-components';

const Column = styled.div`
  display: flex;
  position: sticky;
  left: 0;

  align-items: center;
  width: 300px;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  padding-right: 10px;
  flex-shrink: 0;
  background: rgb(54, 61, 74);
  z-index: 1;
  padding-left: 45px;
`;

export default Column;

import React from 'utils/react';
import Layout from 'components/Layout';
import debug from 'utils/debug';

import InfoPanel from './InfoPanel';
import TopPanels from './TopPanels';
import EventListener from './EventListener';
import { useEffect } from 'react';

const D2 = debug('p:Simulation:Simulation');

const Simulation = ({
  websocketConnected,
  websocketDisonnected,
  simulationEvent,
  id,
  wsToken,
  stops,
  clearSimulation,
  simulation,
  fetchStops,
  fetchSimulation,
  fetchSimulationDataStart,
}) => {
  D2.S.DEBUG('render()');
  const url =
    window.GEODISC_API_SIMULATION_EVENTS_URL ||
    `${window.location.protocol === 'https:' ? 'wss:' : 'ws:'}//${
      window.location.host
    }`;
  const version = parseInt(window.SIMULATION_EVENTS_VER || '1', 10);

  const tokenStr = wsToken !== '0' ? `?token=${wsToken}` : '';

  useEffect(() => {
    const refetch = simulation && simulation.get('id') !== id;
    if (!stops) {
      fetchStops();
    }

    if (!simulation || refetch) {
      clearSimulation();
      fetchSimulation(id);
      fetchSimulationDataStart(id);
    }
  }, []);

  return (
    <Layout>
      <InfoPanel />
      <TopPanels />
      {!window.GEODISC_API_SIMULATION_EVENT_LISTENER_DISABLE && wsToken && (
        <EventListener
          url={`${url}/simulation_events/${id}${tokenStr}`}
          version={version}
          websocketConnected={websocketConnected}
          websocketDisonnected={websocketDisonnected}
          simulationEvent={simulationEvent}
        />
      )}
    </Layout>
  );
};

export default React.memo(Simulation);

import createImmutableSelector from 'utils/immutable-selector';
import { getSource } from 'utils/geofences';
import debug from 'utils/debug';
import {
  visibleGeofencesSelector,
  geofencesSearchSelector,
} from '../ui/selectors';

const D2 = debug('m:geofences:selectors');

export const geofencesStateSelector = state =>
  D2.S.FUNCTION(
    'geofencesStateSelector',
    { state, $fn: { state: () => state.toJS() } },
    () => {
      return state.get('geofences');
    }
  );

export const geofencesSelector = createImmutableSelector(
  geofencesStateSelector,
  state =>
    D2.S.SELECTOR(
      'geofencesSelector',
      { state, $fn: { state: () => state.toJS() } },
      () => {
        const geofences = state.get('geofences');
        /*
         * Filter and remove the default geo-fence which has the type `FeatureCollection`
         */
        const filteredGeofences = geofences?.filter(
          geofence => geofence?.geometry?.type !== 'FeatureCollection'
        );
        return filteredGeofences;
      }
    )
);

export const geofencesFilteredSelector = createImmutableSelector(
  geofencesSelector,
  geofencesSearchSelector,
  (geofences, search) =>
    D2.S.SELECTOR('geofencesFilteredSelector', { geofences, search }, () => {
      if (!geofences) {
        return null;
      }

      return geofences.filter((value) => {
        if (search === '') {
          return true;
        }

        if (search[0] === '#') {
          const tags = value.tags;
          return tags.includes(search.slice(1));
        }

        const name = value.name.toLowerCase();

        return name.indexOf(search.toLowerCase()) !== -1;
      });
    })
);

export const geofencesFilteredSizeSelector = createImmutableSelector(
  geofencesFilteredSelector,
  geofences => (geofences ? geofences.length : 0)
);

export const geofencesSourceSelector = createImmutableSelector(
  geofencesFilteredSelector,
  visibleGeofencesSelector,
  (geofences, visibleGeofences) =>
    D2.S.SELECTOR(
      'geofencesSourceSelector',
      { geofences, visibleGeofences },
      () => {
        if (!geofences) {
          return getSource([]);
        }

        const filteredGeofences = geofences.filter(value =>
          visibleGeofences.includes(value.id)
        );
        return getSource(filteredGeofences);
      }
    )
);

export const geofencesActiveSelector = createImmutableSelector(
  geofencesSelector,
  geofences =>
    D2.S.SELECTOR('geofencesActiveSelector', { geofences }, () => {
      return geofences
        ? geofences.filter(geofence => geofence.status === 'A')
        : null;
    })
);

export const geofencesActiveSourceSelector = createImmutableSelector(
  geofencesActiveSelector,
  visibleGeofencesSelector,
  (geofences, visibleGeofences) =>
    D2.S.SELECTOR(
      'geofencesActiveSourceSelector',
      { geofences, visibleGeofences },
      () => {
        if (!geofences) {
          return getSource([]);
        }

        const filteredGeofences = geofences.filter(value =>
          visibleGeofences.includes(value.id)
        );
        return getSource(filteredGeofences);
      }
    )
);

// export const geofencesActiveSourceSelector = createImmutableSelector(() =>
//   D2.S.SELECTOR('geofencesActiveSourceSelector', {}, () => {
//     return getSource([]);
//   })
// );

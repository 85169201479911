import { sync } from 'utils/async';

import moment from 'moment-timezone';
import { geoToH3 } from 'h3-js';
import debug from 'utils/debug';
import normalizeTime from 'utils/normalize-time';
import { normalizeVehicleColor } from 'utils/color';
import parseColor from 'parse-color';

import {
  defaultVehicleRoutingServer,
  defaultVehicleRoutingEngine,
} from 'utils/CommuteOffer/defaults';
import { NODE_STATUSES } from 'utils/constants';

const D2 = debug('u:CommuteOffer:Validation:Validators');

export const legacyValidator = async (commuteOffer, opts = {}) =>
  D2.A.FUNCTION('0000:Legacy', { commuteOffer, opts }, async ({ $D2 }) => {
    const options = opts || {};

    const timezone = options.tz || global.GEODISC_TIMEZONE || 'UTC';

    const { result, stateless_api_request_data } = commuteOffer;

    const readOnly = result.readOnly || false;

    const request__agent_id_set = stateless_api_request_data.vehicles.reduce(
      (memo, currVehicle) => {
        const prevVehicle = memo.vehicles[currVehicle.agent_id];

        if (prevVehicle) {
          return {
            ...memo,
            $errors: [
              ...memo.$errors,
              {
                type: 'request.vehicles.DuplicateAgentId',
                prevVehicle,
                currVehicle,
              },
            ],
          };
        }
        return {
          ...memo,
          vehicles: {
            ...memo.vehicles,
            [currVehicle.agent_id]: currVehicle,
          },
        };
      },
      { $errors: [], $warnings: [], vehicles: {} }
    );
    $D2.S.INFO('request__agent_id_set', {
      request__agent_id_set,
    });

    const stateless_api_request_data__vehicles =
      stateless_api_request_data.vehicles
        .map((vehicle) => {
          const vehicle_color = normalizeVehicleColor(vehicle);
          const vehicleInternals = {
            $start_time:
              vehicle.start_time && moment(vehicle.start_time).tz(timezone),
            $end_time:
              vehicle.end_time && moment(vehicle.end_time).tz(timezone),
          };
          return {
            ...vehicle,
            ...vehicleInternals,
            color: parseColor(vehicle_color).hex,
            vehicle_color,
            routing_engine: {
              ...defaultVehicleRoutingEngine,
              ...(vehicle.routing_engine || {}),
              ...defaultVehicleRoutingServer,
            },
            $activeColor: undefined,
            $draggableColor: undefined,
          };
        })
        .sort((a, b) => {
          if (a.$start_time && b.$end_time) {
            return a.$start_time.diff(b.$start_time);
          }
          if (a.$start_time) {
            return -1;
          }
          return 1;
        });
    $D2.S.INFO('stateless_api_request_data__vehicles', {
      stateless_api_request_data__vehicles,
    });

    const assignedNodeStates = new Set([
      NODE_STATUSES.ASSIGNED,
      NODE_STATUSES.COMPLETED,
      NODE_STATUSES.FAILED_TO_DELIVER,
    ]);

    const stateless_api_request_data__nodes__warnings =
      stateless_api_request_data.nodes.reduce((memo, node) => {
        const isStartOrEndNode =
          node.partial_route_index === 1 || node.partial_route_index === -1;
        if (
          typeof node.booking_uid === 'undefined' ||
          node.booking_uid === null ||
          typeof node.booking_uid === 'string'
        ) {
          if (
            !isStartOrEndNode &&
            node.status !== NODE_STATUSES.FAILED_TO_BOARD &&
            ((assignedNodeStates.has(node.status) &&
              !node.assigned_vehicle_id) ||
              (!assignedNodeStates.has(node.status) &&
                node.assigned_vehicle_id)) &&
            node.status !== NODE_STATUSES.CANCELLED_BY_USER //temporary change to fix issue that Lawson faces
          ) {
            return [
              ...memo,
              {
                type: 'node.node_status.InvalidType',
                node,
              },
            ];
          }
          return memo;
        }
        return [
          ...memo,
          {
            type: 'node.booking_uid.InvalidType',
            node,
          },
        ];
      }, []);

    const stateless_api_request_data__nodes =
      stateless_api_request_data.nodes.map(node => ({
        ...node,
        booking_uid: node.booking_uid ? node.booking_uid.toString() : null,
        h3: node.h3 ? node.h3 : geoToH3(node.lat, node.lon, 13),
      }));
    $D2.S.INFO('stateless_api_request_data__nodes', {
      stateless_api_request_data__nodes,
    });

    const result__vehicles = Object.keys(result.vehicles).reduce(
      (acc, vehicleId) => {
        const nodes = result.vehicles[vehicleId];
        return {
          ...acc,
          [vehicleId]: nodes
            ? nodes.map(node => ({
                ...node,
              }))
            : undefined,
        };
      },
      {}
    );
    $D2.S.INFO('result__vehicles', { result__vehicles });

    const processBookingsList = bookings =>
      $D2.S.FUNCTION('processBookingsList', { bookings }, () =>
        bookings.map((booking) => {
          return {
            ...booking,
          };
        }, {})
      );
    const result__assigned_bookings = processBookingsList(
      result.assigned_bookings
    );
    $D2.S.INFO('result__assigned_bookings', {
      result__assigned_bookings,
    });

    const result__rejected_bookings = processBookingsList(
      result.rejected_bookings
    );
    $D2.S.INFO('result__rejected_bookings', {
      result__rejected_bookings,
    });

    const stateless_api_request_data__bookings =
      result.assigned_bookings.reduce((memo, booking) => {
        const $display_name = '';
        const $display_name_info = [
          ['booking.display_name', booking.display_name],
          ['booking.data?.username', booking.data?.username],
          ['booking.uid', booking.uid],
          ['booking', booking],
        ];

        const requestBooking = stateless_api_request_data.bookings[booking.uid];

        return !requestBooking
          ? {
              ...memo,
              [booking.uid]: {
                uid: booking.uid,
                pickup_time: null,
                dropoff_time: null,
                pickup_location_lat: null,
                pickup_location_name: null,
                dropoff_location_lat: null,
                dropoff_location_name: null,
                $isPhantom: true,
                $display_name,
                $display_name_info,
              },
            }
          : {
              ...memo,
              [booking.uid]: {
                ...requestBooking,
                $isPhantom: false,
                $display_name,
                $display_name_info,
              },
            };
      }, {});
    $D2.S.INFO('stateless_api_request_data__bookings', {
      stateless_api_request_data__bookings,
    });

    const res = {
      ...commuteOffer,
      stateless_api_request_data: {
        ...stateless_api_request_data,
        nodes: stateless_api_request_data__nodes,
        vehicles: stateless_api_request_data__vehicles,
        bookings: {
          ...stateless_api_request_data.bookings,
          ...stateless_api_request_data__bookings,
        },
        timezone,
        readOnly,
        type: stateless_api_request_data.type || 'CommuteOffer',
      },
      result: {
        ...result,
        vehicles: result__vehicles,
        assigned_bookings: result__assigned_bookings,
        rejected_bookings: result__rejected_bookings,
        changelog: [],
        $errors: [...result.$errors, ...request__agent_id_set.$errors],
        $warnings: [
          ...result.$warnings,
          ...request__agent_id_set.$warnings,
          ...stateless_api_request_data__nodes__warnings,
        ],
      },
      $sn: 1,
    };

    await sync();
    return res;
  });

export default legacyValidator;

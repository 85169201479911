import React from 'utils/react';
import Immutable from 'immutable';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { ProgressBar } from 'baseui/progress-bar';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Select } from 'baseui/select';

import debug from 'utils/debug';

const D2 = debug('c:ModalWindows:NameAndProject');

export default props =>
  D2.S.FUNCTION('NameAndProject', { props }, ({ $D2 }) => {
    const { currentProject, currentProjectId, projects } = props;

    const projectsArray =
      projects && projects instanceof Immutable.List ? projects.toJS() : [];
    $D2.S.INFO('projectsArray', {
      currentProject,
      currentProjectId,
      projects,
      projectsArray,
    });

    const isDataValid = currentProject && projectsArray.length;

    const [isOpen, setIsOpen] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [windowTitle, setWindowTitle] = React.useState('');
    const [targetName, setTargetName] = React.useState('');
    const [targetProject, setTargetProject] = React.useState(null);
    const [isObjectSelectionAllowed, setObjectSelectionAllowed] =
      React.useState(false);
    const [objectSelection, setObjectSelection] = React.useState({
      label: 'All',
      id: 'all',
    });
    const [eventHandlers, setEventHandlers] = React.useState({});

    if (!targetProject && currentProjectId && projects) {
      const currentProjectInfo = projectsArray.find(
        x => x.id === currentProjectId
      );
      if (currentProjectInfo) {
        setTargetProject({
          label: currentProjectInfo.name,
          id: currentProjectInfo.id,
        });
      }
    }

    const disabled = targetName.trim() === '' || !targetProject || isSubmitting;
    $D2.S.INFO('disabled', {
      disabled,
      targetName,
      targetProject,
      isSubmitting,
    });

    React.useEffect(() =>
      D2.S.FUNCTION('Update', {}, () => {
        window.openNameAndProject = (name, opts) =>
          D2.S.FUNCTION('openNameAndProject', { name, opts }, () => {
            const {
              title = '',
              onSubmit = null,
              isSelectionAllowed = false,
            } = opts || {};
            setTargetName(name || '');
            setWindowTitle(title);
            setEventHandlers({ onSubmit });
            setObjectSelectionAllowed(!!isSelectionAllowed);
            setIsSubmitting(false);
            setIsOpen(true);
          });
        window.closeNameAndProject = () =>
          D2.S.FUNCTION('closeNameAndProject', {}, () => {
            setIsOpen(false);
          });
        return () =>
          D2.S.FUNCTION('Cleanup', {}, () => {
            window.openNameAndProject = () => {};
          });
      })
    );

    const onClose = () =>
      D2.S.FUNCTION('onClose', { isSubmitting }, () => {
        if (!isSubmitting) {
          setIsOpen(false);
        }
      });

    const onSumbit = () =>
      D2.S.FUNCTION(
        'onSumbit',
        { targetName, targetProject, eventHandlers, objectSelection },
        () => {
          if (eventHandlers.onSubmit) {
            const targetProjectId = Array.isArray(targetProject)
              ? targetProject[0].id
              : targetProject.id;
            const objectSelectionId = Array.isArray(objectSelection)
              ? objectSelection[0].id
              : objectSelection.id;
            const eventHandlerArgs = {
              name: targetName,
              project: targetProjectId,
              selection: isObjectSelectionAllowed ? objectSelectionId : 'all',
            };
            $D2.S.INFO('eventHandlerArgs', eventHandlerArgs);
            const result = eventHandlers.onSubmit(eventHandlerArgs);
            if (result instanceof Promise) {
              setIsSubmitting(true);
              result.then(
                ($result) => {
                  $D2.S.INFO('onSumbit:Done', { $result });
                  setIsOpen(false);
                },
                ($error) => {
                  $D2.S.INFO('onSumbit:Error', { $error });
                  setIsOpen(false);
                }
              );
            } else {
              setIsOpen(false);
            }
          } else {
            setIsOpen(false);
          }
        }
      );

    return (
      <React.Fragment>
        <Modal
          onClose={onClose}
          isOpen={isOpen}
          overrides={{
            Dialog: {
              style: {
                minWidth: '800px',
                maxWidth: '95vw',
                display: 'flex',
                flexDirection: 'column',
              },
            },
          }}
        >
          <ModalHeader>{windowTitle}</ModalHeader>
          {isDataValid && !isSubmitting ? (
            <ModalBody style={{ flex: '1 1 0' }}>
              <FormControl label={() => 'Name'}>
                <Input
                  value={targetName}
                  onChange={e => setTargetName(e.target.value)}
                  placeholder='Name'
                />
              </FormControl>
              <FormControl label={() => 'Project'}>
                <Select
                  clearable={false}
                  options={projectsArray.map(project => ({
                    label: project.name,
                    id: project.id,
                  }))}
                  value={
                    Array.isArray(targetProject)
                      ? targetProject
                      : [targetProject]
                  }
                  placeholder='Select a project'
                  onChange={params => setTargetProject(params.value)}
                />
              </FormControl>
              {isObjectSelectionAllowed && (
                <FormControl label={() => 'Objects'}>
                  <Select
                    clearable={false}
                    options={[
                      {
                        label: 'All',
                        id: 'all',
                      },
                      {
                        label: 'Selected only',
                        id: 'selected',
                      },
                      {
                        label: 'Visible only',
                        id: 'visible',
                      },
                    ]}
                    value={
                      Array.isArray(objectSelection)
                        ? objectSelection
                        : [objectSelection]
                    }
                    placeholder='Select'
                    onChange={params => setObjectSelection(params.value)}
                  />
                </FormControl>
              )}
            </ModalBody>
          ) : (
            <ModalBody>
              <ProgressBar
                getProgressLabel={() =>
                  isSubmitting ? 'Saving...' : 'Loading...'
                }
                showLabel
                infinite
              />
            </ModalBody>
          )}
          <ModalFooter>
            <ModalButton disabled={disabled} onClick={onSumbit}>
              Save
            </ModalButton>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  });

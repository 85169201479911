import React from 'utils/react';

import ButtonClose from './ButtonClose';

import Container from './Container';

const Selected = ({ count, clearSelectedSimulations }) => {
  if (!count) {
    return null;
  }

  return (
    <React.Fragment>
      <Container>{`${count} selected`}</Container>
      <ButtonClose onClick={clearSelectedSimulations} />
    </React.Fragment>
  );
};

export default Selected;

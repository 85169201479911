import React from 'utils/react';

import debug from 'utils/debug';
import Container from './Container';

const D2 = debug('p:CommuteOffer:Panels:Statistics');

const Statistics = props =>
  D2.S.FUNCTION('Statistics', { props }, () => {
    const { isBookingsHidden, isDeliveryLayout } = props;

    return (
      isDeliveryLayout && <Container isBookingsHidden={isBookingsHidden} />
    );
  });

export default Statistics;

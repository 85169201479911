import styled from 'styled-components';

export default styled.button`
  padding: 6px 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  margin-right: 10px;

  color: #edece8;
  font-size: 14px;
  font-weight: bold;

  background-color: ${({ isActive }) =>
    isActive ? 'rgb(54, 61, 74)' : '#2d343c'};
  cursor: ${({ isActive }) => (isActive ? 'pointer' : '')};
  outline: none;
`;

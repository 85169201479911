import styled from 'styled-components';

const Logo = styled.img`
  margin-right: 10px;
  width: 100px;
  object-fit: cover;
  margin-bottom: 100px;
`;

export default Logo;

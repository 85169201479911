import styled from 'styled-components';

const MenuLink = styled.a`
  padding: 20px 5px;
  line-height: 40px;
  color: #c5cede;
  text-decoration: none;

  &:hover {
    opacity: 0.5;
  }
`;

export default MenuLink;

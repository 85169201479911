import {
  delay,
  fork,
  put,
  cancel,
  select,
  takeLatest,
} from 'redux-saga/effects';
import debug from 'utils/debug';
import * as actions from './actions';
import * as commuteOfferActions from '../commuteOffer/actions';

import {
  editableBookingIdSelector,
  isBookingsHiddenSelector,
  busStopsVisibleSelector,
  activeRouteStopUidSelector,
} from './selectors';

// import { commuteOfferCurrentDataSelector } from 'modules/commuteOffer/selectors';

const D2 = debug('m:ui:saga');

function* datasetRun() {
  D2.S.INFO('datasetRun');

  while (true) {
    yield delay(300);
    yield put({ type: actions.DATASET_NEXT });
  }
}

let datasetTask;
function* datasetPlay() {
  D2.S.INFO('datasetPlay');

  datasetTask = yield fork(datasetRun);
}

function* datasetStop() {
  D2.S.INFO('datasetStop');

  if (datasetTask) {
    yield cancel(datasetTask);
  }
}

function* cleanActiveBooking() {
  D2.S.INFO('cleanActiveBooking');

  const editableBookingId = yield select(editableBookingIdSelector);

  if (editableBookingId) {
    yield put(actions.cleanEditableBookingId());
  }

  yield put(actions.cleanActiveBookingId());
  yield put(actions.removeDraggablePoint());
}

function* setActiveBooking({ payload }) {
  D2.S.INFO('setActiveBooking', { payload });

  const id = payload;

  const isBookingsHidden = yield select(isBookingsHiddenSelector);
  D2.S.INFO('setActiveBooking:isBookingsHidden', { isBookingsHidden });

  if (isBookingsHidden) {
    yield put(actions.toggleBookingsHidden());
  }

  if (global.geodisc$ScrollToBooking) {
    global.geodisc$ScrollToBooking(id);
  }
}

function* setNewEditableVehicle() {
  D2.S.INFO('setNewEditableVehicle');

  yield put(actions.setEditableVehicleId('veh_new'));
}

function* setEditableBookingId({ payload }) {
  D2.S.INFO('setEditableBookingId', { payload });

  const { id, notSetActiveBookingID } = payload;
  D2.S.INFO('setEditableBookingId:id', { id, payload });

  if (notSetActiveBookingID) return;
  yield put(actions.setActiveBookingId(id));
}

function* cleanActiveVehicle() {
  D2.S.INFO('cleanActiveVehicle');

  const activeRouteStopUid = yield select(activeRouteStopUidSelector);
  if (activeRouteStopUid) {
    yield put(actions.cleanActiveRouteStop());
  }
}

function* setActiveVehicle({ payload }) {
  D2.S.INFO('setActiveVehicle', { payload });

  const id = payload;
  const editableBookingId = yield select(editableBookingIdSelector);
  if (editableBookingId) {
    yield put(actions.cleanEditableBookingId());
  }

  const element = window.document.getElementById(id);
  if (element) {
    element.scrollIntoView();
  }
}

function* enableStopMode() {
  D2.S.INFO('enableStopMode');

  const busStopsVisible = yield select(busStopsVisibleSelector);
  if (!busStopsVisible.includes('bus_stop')) {
    yield put(actions.toggleActiveStopsType('bus_stop'));
  }
}

function* addStop() {
  D2.S.INFO('addStop');

  yield put(actions.setStopSearchQuery(''));
}

function* nodesLayerClick({ payload }) {
  const node = payload.properties;
  yield put(commuteOfferActions.setBookingNode([node]));
}

function* Saga() {
  yield takeLatest(actions.NODES_LAYER_CLICK, nodesLayerClick);
  yield takeLatest(actions.DATASET_PLAY, datasetPlay);
  yield takeLatest(actions.DATASET_STOP, datasetStop);
  yield takeLatest(
    commuteOfferActions.REMOVE_BOOKING_FROM_ROUTE,
    cleanActiveBooking
  );
  yield takeLatest(actions.SET_ACTIVE_BOOKING_ID, setActiveBooking);
  yield takeLatest(
    [actions.CLEAN_ACTIVE_ROUTE_STOP, actions.CLEAN_EDITABLE_BOOKING_ID],
    cleanActiveBooking
  );
  yield takeLatest(actions.CLEAN_ACTIVE_VEHICLE_ID, cleanActiveVehicle);
  yield takeLatest(actions.SET_ACTIVE_VEHICLE_ID, setActiveVehicle);
  yield takeLatest(commuteOfferActions.NEW_VEHICLE, setNewEditableVehicle);
  yield takeLatest(actions.SET_EDITABLE_BOOKING_ID, setEditableBookingId);
  yield takeLatest(actions.SET_ADD_STOP_MODE, enableStopMode);
  yield takeLatest(commuteOfferActions.SET_STOP_TO_ROUTE, addStop);
}

export default Saga;

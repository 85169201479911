import url from 'url';

import debug from 'utils/debug';

const D2 = debug('u:args');

const parseLocationArgs = str =>
  str ? new Map(str.split('&').map(v => v.split('='))) : new Map();

const locationUrl = url.parse(window.location.href);

const locationHashUrl = url.parse(
  window.location.hash && window.location.hash[0] === '#'
    ? window.location.hash.substr(1)
    : window.location.hash
);

// console.log(locationUrl);
// console.log(locationHashUrl);

const args = {
  search: parseLocationArgs(locationUrl.query),
  hash: parseLocationArgs(locationHashUrl.query),
};

const get = name =>
  D2.S.FUNCTION(
    'get',
    { name },
    () => args.hash.get(name) || args.search.get(name)
  );

const getIds = name =>
  D2.S.FUNCTION('getIds', { name }, () =>
    [
      ...new Set((get(name) || '').split(',').map(x => parseInt(x, 10))),
    ].filter(x => !Number.isNaN(x))
  );

// function locationHashChanged(e) {
//   console.log(window.location);
// }

// window.onhashchange = locationHashChanged;

// console.log(args);

export default { ...args, get, getIds };

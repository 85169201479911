import Immutable from 'immutable';

import * as actions from 'modules/ui/actions';

import debug from 'utils/debug';

const D2 = debug('m:ui:reducers:ui');

const bookingsLayerClick = (state, payload) =>
  D2.S.FUNCTION('bookingsLayerClick', { payload }, () => {
    const feature = payload;

    const { properties } = feature;

    if (global.geodisc$ScrollToBooking) {
      global.geodisc$ScrollToBooking(properties.uid);
    }

    const commonState = state
      .setIn(['commuteOffer', 'draggablePoint'], null)
      .setIn(['commuteOffer', 'isBookingsHidden'], false)
      .setIn(['commuteOffer', 'activeBookingId'], properties.uid);

    if (properties.status !== 'unassigned') {
      const newActiveVehicleId = properties.assigned_vehicle_id;

      if (global.geodisc$ScrollToVehicle) {
        global.geodisc$ScrollToVehicle(newActiveVehicleId);
      }
      return commonState
        .updateIn(['commuteOffer', 'activeVehicleIds'], () =>
          Immutable.fromJS([newActiveVehicleId])
        )
        .setIn(['commuteOffer', 'activeRouteStopId'], properties.$routeStopId)
        .setIn(['commuteOffer', 'activeRouteStopUid'], null)
        .setIn(['commuteOffer', 'editableBookingId'], properties.uid);
    }
    return commonState.setIn(['commuteOffer', 'editableBookingId'], null);
  });

bookingsLayerClick.type = actions.BOOKING_LAYER_CLICK;

export default bookingsLayerClick;

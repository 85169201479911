import styled from 'styled-components';
import { Link } from 'react-router-dom';
import arrow from 'assets/arrow.svg';

const BackButton = styled(Link)`
  display: flex;
  position: relative;
  text-decoration: none;
  margin-left: 35px;
  flex-shrink: 0;

  &:after {
    position: absolute;
    width: 25px;
    height: 25px;
    transform: rotate(90deg);
    cursor: pointer;
    left: -35px;
    bottom: 7px;

    background: url(${arrow}) no-repeat center;
    content: '';
  }
`;

export default BackButton;

import Immutable from 'immutable';

import * as actions from 'modules/ui/actions';

import debug from 'utils/debug';

const D2 = debug('m:ui:reducers:ui');

const vehiclesLayerClick = (state, payload) =>
  D2.S.FUNCTION('vehiclesLayerClick', { payload }, ({ $D2 }) => {
    const { feature } = payload;

    const newActiveVehicleId = feature.properties.agent_id;

    const newDraggablePort =
      feature.properties.resource_uri || feature.properties.readOnly
        ? null
        : {
            ...feature.properties,
            id: feature.properties.uid,
            color: feature.properties.$activeColor,
            node_type: feature.properties.node_type,
          };

    if (global.geodisc$ScrollToVehicle) {
      global.geodisc$ScrollToVehicle(newActiveVehicleId);
    }

    return state
      .setIn(['commuteOffer', 'editableBookingId'], null)
      .setIn(['commuteOffer', 'activeBookingId'], null)
      .setIn(['commuteOffer', 'activeRouteStopId'], feature.properties.id)
      .setIn(['commuteOffer', 'activeRouteStopUid'], feature.properties.uid)
      .updateIn(['commuteOffer', 'activeVehicleIds'], activeVehicleIds =>
        $D2.S.V(
          'activeVehicleIds',
          { activeVehicleIds },
          !Immutable.isList(activeVehicleIds) ||
            activeVehicleIds.size !== 1 ||
            activeVehicleIds.get(0) !== newActiveVehicleId
            ? Immutable.fromJS([newActiveVehicleId])
            : activeVehicleIds
        )
      )
      .updateIn(['commuteOffer', 'draggablePoint'], draggablePoint =>
        $D2.S.V(
          'draggablePoint',
          { draggablePoint, newDraggablePort },
          newDraggablePort
        )
      );
  });

vehiclesLayerClick.type = actions.VEHICLES_LAYER_CLICK;

export default vehiclesLayerClick;

import moment from 'moment-timezone';
import debug from 'utils/debug';

const D2 = debug('m:CommuteOffers:Trips');

// eslint-disable-next-line import/prefer-default-export
export const commuteOffers$Trips$TableFromCommuteOffer = commuteOffer =>
  D2.S.FUNCTION('TableFromCommuteOffer', { commuteOffer }, ({ $D2 }) => {
    const vehicles = commuteOffer.stateless_api_request_data.vehicles;
    $D2.S.INFO('vehicles', { vehicles });

    const hasPassengersSchedules = !!(
      commuteOffer.stateless_api_request_data.inbound &&
      commuteOffer.stateless_api_request_data.inbound.schedule
    );

    const daylyScheduleYesValue = record =>
      record.passenger?.wheelchair ? 'WC' : 'A';

    const passengersColumns = hasPassengersSchedules
      ? [
          {
            kind: 'BOOLEAN',
            label: 'Mon',
            name: 'mon',
            width: '60px',
            value: record =>
              record.passenger?.mon ? daylyScheduleYesValue(record) : '',
          },
          {
            kind: 'BOOLEAN',
            label: 'Tue',
            name: 'tue',
            width: '60px',
            value: record =>
              record.passenger?.tue ? daylyScheduleYesValue(record) : '',
          },
          {
            kind: 'BOOLEAN',
            label: 'Wed',
            name: 'wed',
            width: '60px',
            value: record =>
              record.passenger?.wed ? daylyScheduleYesValue(record) : '',
          },
          {
            kind: 'BOOLEAN',
            label: 'Thu',
            name: 'thu',
            width: '60px',
            value: record =>
              record.passenger?.thu ? daylyScheduleYesValue(record) : '',
          },
          {
            kind: 'BOOLEAN',
            label: 'Fri',
            name: 'fri',
            width: '60px',
            value: record =>
              record.passenger?.fri ? daylyScheduleYesValue(record) : '',
          },
          // {
          //   kind: 'BOOLEAN',
          //   label: 'Sat',
          //   name: 'sat',
          //   width: '60px',
          //   value: record => (record.passenger?.sat ? daylyScheduleYesValue(record) : '')
          // },
          // {
          //   kind: 'BOOLEAN',
          //   label: 'Sun',
          //   name: 'sun',
          //   width: '60px',
          //   value: record => (record.passenger?.sun ? daylyScheduleYesValue(record) : '')
          // }
        ]
      : [];

    const columns = [
      ...[
        {
          kind: 'STRING',
          name: 'trip_id',
          label: 'Trip',
          width: 'max-content',
          value: record => record.vehicle.$tripName,
        },
        {
          kind: 'STRING',
          name: 'scheduled_ts',
          label: 'Time',
          width: 'max-content',
          value: record =>
            moment(record.node.scheduled_ts)
              .tz(global.GEODISC_TIMEZONE)
              .format('HH:mm'),
        },
        {
          kind: 'STRING',
          name: 'node_type',
          label: 'Type',
          width: 'max-content',
          value: record => record.node.node_type,
        },
        {
          kind: 'STRING',
          name: 'name',
          label: 'Passenger name',
          width: 'max-content',
          externalId: 'name',
          value: record => record.booking.passenger_name || '',
        },
        {
          kind: 'STRING',
          name: 'zip_code',
          label: 'Zip code',
          width: 'max-content',
          externalId: 'zip_code',
          value: record => record.booking.passenger_zip_code || '',
        },
        {
          kind: 'STRING',
          name: 'location_name',
          label: 'Location',
          width: 'minmax(350px, max-content)',
          value: record => record.node.location_name || '',
        },
        {
          kind: 'BOOLEAN',
          name: 'wheelchair',
          label: 'Wheelchair',
          width: 'minmax(60px, max-content)',
          value: record => (record.passenger?.wheelchair ? 'Yes' : ''),
        },
      ],
      ...passengersColumns,
    ];
    $D2.S.INFO('resultTable:columns', { columns });

    const vehiclesWithTripNames = vehicles.map(vehicle => ({
      ...vehicle,
      $tripName: vehicle.name,
    }));
    $D2.S.INFO('resultTable:vehiclesWithTripNames', { vehiclesWithTripNames });

    const rows = vehiclesWithTripNames.reduce((resultTableMemo, vehicle) => {
      const route = commuteOffer.result.vehicles[vehicle.agent_id];
      $D2.S.INFO('resultTable:route', { vehicle, route });

      if (!route) {
        return resultTableMemo;
      }

      const routeSource = route;

      const tripRecords = routeSource.map((node) => {
        const booking =
          commuteOffer.stateless_api_request_data.bookings[node.booking_uid];
        const passenger =
          hasPassengersSchedules && booking
            ? commuteOffer.stateless_api_request_data.inbound.schedule[
                booking.passenger_name
              ]
            : null;

        const record = {
          vehicle,
          booking,
          passenger,
          node,
        };
        $D2.S.INFO('resultTable:record', {
          record,
        });

        const row = columns.map(column => column.value(record));
        $D2.S.INFO('resultTable:row', {
          row,
        });

        return row;
      });
      $D2.S.INFO('resultTable:tripRecords', { vehicle, route, tripRecords });

      return [...resultTableMemo, ...tripRecords];
    }, []);

    const resultTable = {
      columns,
      rows,
    };
    $D2.S.INFO('Success', {
      commuteOffer,
      resultTable,
    });

    return resultTable;
  });

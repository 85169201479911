import React from 'utils/react';
import useTranslation from 'utils/react/useTranslation';

import Dropdown from 'components/Dropdown';

import Button from './Button';
import MenuButton from './MenuButton';
import Content from './Content';

const ButtonPlus = ({ currentUserConfig, isDeliveryLayout, openPopup }) => {
  const { t } = useTranslation();

  const { pages = {} } = currentUserConfig?.geodisc_settings || {};
  const {
    datasets = true,
    geofences = true,
    simulations = true,
    commute_offers = true,
  } = pages;

  const datasetsMenuItems = datasets
    ? [{ id: 'dataset', title: t('p.Base.Plus.Dataset.Add') }]
    : [];
  const geofencesMenuItems = geofences
    ? [{ id: 'geofence', title: t('p.Base.Plus.Geofence.Add') }]
    : [];
  const simulationsMenuItems = simulations
    ? [{ id: 'simulation', title: t('p.Base.Plus.Simulation.Add') }]
    : [];
  const commuteOffersMenuItems = commute_offers
    ? [{ id: 'commuteoffer', title: t('p.Base.Plus.CommuteOffer.Add') }]
    : [];

  const config = isDeliveryLayout
    ? [...geofencesMenuItems]
    : [
        ...datasetsMenuItems,
        ...geofencesMenuItems,
        ...simulationsMenuItems,
        ...commuteOffersMenuItems,
      ];

  return config.length ? (
    <Dropdown
      renderHandler={({ toggle }) => {
        return <Button data-testid='Layout-ButtonPlus' onClick={toggle} />;
      }}
      renderContent={({ toggle }) => (
        <Content onClick={toggle}>
          {config.map((item) => {
            const { id, title } = item;

            const onClick = () => {
              openPopup(id, { title });
            };

            return (
              <MenuButton
                data-testid={`Layout-ButtonPlus-${id}`}
                key={id}
                onClick={onClick}
              >
                {title}
              </MenuButton>
            );
          })}
        </Content>
      )}
    />
  ) : null;
};

export default React.memo(ButtonPlus);

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import {
  simulationByVehicleSelector,
  simulationGeometrySelector,
  simulationsPlayerTimesSelector,
} from 'modules/simulations/selectors';
import { changeViewportSimulation } from 'modules/maps/actions';
import { simulationViewportSelector } from 'modules/maps/selectors';

import Map from './Map';

const mapStateToProps = createImmutableSelector(
  simulationByVehicleSelector,
  simulationsPlayerTimesSelector,
  simulationViewportSelector,
  simulationGeometrySelector,
  (data, times, viewport, geofenceGeom) => ({
    data,
    times,
    viewport,
    geofenceGeom,
  })
);

const mapDispatchToProps = {
  onViewportChange: changeViewportSimulation,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Map);

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { commuteOfferListSelector } from 'modules/commuteOffer/selectors';
import {
  currentProjectIdSelector,
  currentProjectLayoutNameSelector,
} from 'modules/user/selectors';

import { fetchCommuteOffers } from 'modules/commuteOffer/actions';

import CommuteOffers from './CommuteOffers';

const mapStateToProps = createImmutableSelector(
  commuteOfferListSelector,
  currentProjectIdSelector,
  currentProjectLayoutNameSelector,
  (list, projectId, layoutName) => ({ list, projectId, layoutName })
);

const mapDispatchToProps = {
  fetchCommuteOffers,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(CommuteOffers);

import styled from 'styled-components';

const Logout = styled.div`
  color: #edece8;
  font-size: 20px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.6;
  }
`;

export default Logout;

import moment from 'moment-timezone';

import debug from 'utils/debug';

const D2 = debug('u:moment:formatForDate');

const formatForDate = (
  currentDate,
  time,
  timezone,
  offsetValue = 0,
  offsetType = 'hours'
) =>
  D2.S.FUNCTION(
    'formatForDate',
    { currentDate, time, timezone, offsetValue, offsetType },
    () => {
      // console.log('===');
      // console.log('--- Browser Timezone:', moment.tz.guess());
      // console.log('--- Project Timezone:', timezone);
      // console.log('--- Date:', currentDate);
      // console.log('--- Source Time:', time, typeof time);
      // console.log('--- Offset:', offsetValue, offsetType);
      const result = (
        currentDate.length === 10
          ? moment(
              `${currentDate}T${moment
                .tz(time, 'LT', timezone)
                .format('HH:mm:ssZ')}`
            )
          : moment(
              `${moment(currentDate).tz(timezone).format('YYYY-MM-DD')}T${moment
                .tz(time, 'LT', timezone)
                .format('HH:mm:ssZ')}`
            )
      )
        .add(offsetValue, offsetType)
        .tz(timezone)
        .format();
      // console.log('--- Result Time:', result);
      return result;
    }
  );

export default formatForDate;

// moment.tz(time, 'LT', timezone).format('HH:mm:ssZ')

import styled from 'styled-components';

const getValidColor = isActive => (isActive ? 'white' : 'inherit');
const getInvalidColor = isActive => (isActive ? 'yellow' : 'yellow');

export default styled.div`
  position: relative;
  color: ${({ isActive, isScheduledTimeValid }) =>
    isScheduledTimeValid ? getValidColor(isActive) : getInvalidColor(isActive)};
`;

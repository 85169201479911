import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import createImmutableSelector from 'utils/immutable-selector';
import { compose } from 'redux';

import { userNameSelector, isAdminSelector } from 'modules/user/selectors';
import { deleteUser } from 'modules/user/actions';

import UserMenu from './UserMenu';

const mapStateToProps = createImmutableSelector(
  userNameSelector,
  isAdminSelector,
  (userName, isAdmin) => ({ userName, isAdmin })
);

const mapDispatchToProps = { deleteUser };

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const enhance = compose(withRouter, withConnect);

export default enhance(UserMenu);

import styled from 'styled-components';
import eye from './eye.svg';
import noEye from './noEye.svg';

const Eye = styled.div`
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-image: url(${({ isActive }) => (isActive ? eye : noEye)});
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 5px;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;

export default Eye;

import React from 'utils/react';
import moment from 'moment-timezone';
import debug from 'utils/debug';
import displayNames from 'utils/display-names';

import Menu from 'components/Menu';
import MenuButton from './MenuButton';

import Vehicle from './Vehicle';
import Title from './Title';
import Caption from './Caption';
import Route from './Route';
import Time from './Time';
import Stops from './Stops';
import RouteLine from './RouteLine';
import Container from './Container';
import Main from './Main';
import DropoffLocationTime from './DropoffLocationTime';
import PickupLocationTime from './PickupLocationTime';
import LocationName from './LocationName';

const D2 = debug('p:CommuteOffer:Panels:Bookings:Booking');

class Booking extends React.PureComponent {
  onFindInRoute = nodeType =>
    D2.S.FUNCTION(
      'onFindInRoute',
      { props: this.props, nodeType },
      ({ $D2 }) => {
        const { booking } = this.props;

        if (booking.$routeStops) {
          this.props.setActiveVehicleId(booking.assigned_vehicle_id);
          const routeStop = this.props.booking.$routeStops.find(
            node => node.node_type === nodeType
          );
          $D2.S.INFO('routeStop', { routeStop, booking, nodeType });
          if (routeStop) {
            this.props.setActiveRouteStop(routeStop.uid);
            this.props.flyToCommuteOfferActiveStop(routeStop.uid);
          }
        } else {
          this.props.flyToCommuteOfferActiveBooking(booking.uid);
        }
      }
    );

  onFindPickupNode = () => {
    return this.onFindInRoute('pickup');
  };

  onFindDropoffNode = () => {
    return this.onFindInRoute('dropoff');
  };

  onPickupClick;

  render() {
    return D2.S.FUNCTION('render', { props: this.props }, () => {
      const {
        t,
        booking,
        isDragging,
        connectDragSource,
        activeBookingId,
        isReadOnly,
      } = this.props;

      if (!booking) {
        return null;
      }

      const isActive = false;

      const vehicleDisplayValue = booking.$assignedVehicle
        ? booking.$assignedVehicle.display_name ||
          booking.$assignedVehicle.service_number ||
          booking.$assignedVehicle.agent_id
        : booking.assigned_vehicle_id;

      const vehicleDisplayNames = displayNames(
        vehicleDisplayValue || 'Unassigned'
      );
      D2.S.INFO('render:vehicleDisplayNames', { vehicleDisplayNames });

      const bookingDisplayNames = displayNames(
        booking.$display_name ||
          booking.display_name ||
          booking.data?.username ||
          booking.name ||
          booking.uid
      );
      D2.S.INFO('render:bookingDisplayNames', { bookingDisplayNames, booking });

      const opacity = isDragging ? 0.4 : 1;

      const pickupNode =
        booking.$routeStops &&
        booking.$routeStops.find(stop => stop.node_type === 'pickup');
      const dropoffNode =
        booking.$routeStops &&
        booking.$routeStops.find(stop => stop.node_type === 'dropoff');

      return (
        <Container
          id={`booking_${booking.uid}`}
          ref={connectDragSource}
          style={{ opacity }}
          isActive={isActive}
          isReadOnly={isReadOnly}
        >
          <Main>
            <Vehicle color={booking.color}>
              <Caption>
                {t('p.Editor.Panels.Bookings.Booking.Vehicle')}
                <br />
                {vehicleDisplayNames.map((x, i) => (
                  <span key={`vehicle-name-${i}`}>{x}</span>
                ))}
              </Caption>
              {true && (
                <Menu>
                  {booking.assigned_vehicle_id &&
                    booking.scheduled_pickup_stop_id && (
                      <MenuButton onClick={this.onFindPickupNode}>
                        {t(
                          'p.Editor.Panels.Bookings.Booking.Menu.FindPickupNode'
                        )}
                      </MenuButton>
                    )}
                  {booking.assigned_vehicle_id &&
                    booking.scheduled_dropoff_stop_id && (
                      <MenuButton onClick={this.onFindDropoffNode}>
                        {t(
                          'p.Editor.Panels.Bookings.Booking.Menu.FindDropoffNode'
                        )}
                      </MenuButton>
                    )}
                </Menu>
              )}
            </Vehicle>
            {booking.name ? (
              <Title>{booking.name}</Title>
            ) : (
              <Title isActive={activeBookingId === booking.uid}>
                {t('p.Editor.Panels.Bookings.Booking.Caption')}
                <br />
                {bookingDisplayNames.map((x, i) => (
                  <span key={`booking-name-${i}`}>{x}</span>
                ))}
              </Title>
            )}
            <Route>
              <Time>
                <PickupLocationTime>
                  {pickupNode
                    ? moment(pickupNode.scheduled_ts)
                        .tz(global.GEODISC_TIMEZONE)
                        .format('HH:mm')
                    : booking.scheduled_pickup_time}
                </PickupLocationTime>
                <DropoffLocationTime>
                  {dropoffNode
                    ? moment(dropoffNode.scheduled_ts)
                        .tz(global.GEODISC_TIMEZONE)
                        .format('HH:mm')
                    : booking.scheduled_dropoff_time}
                </DropoffLocationTime>
              </Time>
              <RouteLine />
              <Stops>
                <LocationName onClick={this.onFindPickupNode}>
                  {pickupNode
                    ? pickupNode.location_name
                    : booking.scheduled_pickup_stop_name}
                </LocationName>
                <LocationName onClick={this.onFindDropoffNode}>
                  {dropoffNode
                    ? dropoffNode.location_name
                    : booking.scheduled_dropoff_stop_name}
                </LocationName>
              </Stops>
            </Route>
          </Main>
        </Container>
      );
    });
  }
}

export default Booking;

import createImmutableSelector from 'utils/immutable-selector';

export const fleetSelector = (state) => {
  return state.get('fleet');
};

export const vehiclesSelector = createImmutableSelector(
  fleetSelector,
  fleet => fleet.get('vehicles')
);

export const vehicleTypesSelector = createImmutableSelector(
  fleetSelector,
  fleet => fleet.get('vehicleTypes')
);

export const dataOnSuccessSelector = createImmutableSelector(
  fleetSelector,
  fleet => fleet.get('success')
);

export const vehicleTypeLoadingSelector = createImmutableSelector(
  fleetSelector,
  fleet => fleet.get('loading')
);

export const vehicleTypeModifiedAtSelector = createImmutableSelector(
  fleetSelector,
  fleet => fleet.get('modified_at')
);

import styled from 'styled-components';

const Table = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: scroll;
  max-height: 100%;
  margin-top: 20px;
  padding-bottom: 150px;
`;

export default Table;

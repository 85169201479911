import styled from 'styled-components';

const Container = styled.div`
  padding-left: 10px;
  margin-top: 7px;
  min-width: 80px;
  text-align: right;
`;

export default Container;

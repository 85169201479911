import withTranslation from 'utils/react/withTranslation';

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import {
  filteredBookingsSelector,
  activeBookingIndexSelector,
  commuteOfferIsFilteringEnabledSelector,
  bookingReadySelector,
} from 'modules/commuteOffer/selectors';
import {
  currentProjectLayoutNameSelector,
  isDeliveryLayoutSelector,
} from 'modules/user/selectors';
import {
  isBookingsHiddenSelector,
  commuteOffersIsServiceDataSourceSelector,
  activeBookingIdSelector,
  commuteOffersServiceDateSelector,
} from 'modules/ui/selectors';

import {
  toggleBookingsHidden,
  changeBookingsFilter,
  cleanActiveBookingId,
} from 'modules/ui/actions';

import Bookings from './Bookings';

const mapStateToProps = createImmutableSelector(
  filteredBookingsSelector,
  isBookingsHiddenSelector,
  activeBookingIndexSelector,
  currentProjectLayoutNameSelector,
  isDeliveryLayoutSelector,
  commuteOffersIsServiceDataSourceSelector,
  commuteOfferIsFilteringEnabledSelector,
  activeBookingIdSelector,
  commuteOffersServiceDateSelector,
  bookingReadySelector,
  (
    bookings,
    isHidden,
    activeBookingIndex,
    layoutName,
    isDeliveryLayout,
    isServiceDataSource,
    isFilteringEnabled,
    activeBooking,
    serviceDate,
    isBookingReady
  ) => ({
    bookings,
    isHidden,
    activeBookingIndex,
    layoutName,
    isDeliveryLayout,
    isServiceDataSource,
    isFilteringEnabled,
    activeBooking,
    serviceDate,
    isBookingReady,
  })
);

const mapDispatchToProps = {
  toggleBookingsHidden,
  changeBookingsFilter,
  cleanActiveBookingId,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation()(withConnect(Bookings));

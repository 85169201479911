import styled from 'styled-components';
import arrow from 'assets/arrow.svg';

const BackButton = styled.div`
  display: block;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  margin-top: 15px;
  margin-left: 25px;

  &:after {
    content: '';
    position: absolute;
    left: -25px;
    top: -4px;
    width: 25px;
    height: 25px;
    transform: rotate(90deg);
    cursor: pointer;

    background: url(${arrow}) no-repeat center;
  }
`;

export default BackButton;

import styled from 'styled-components';

const Container = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: ${({ color }) => color || 'blue'};
  border: solid 2px white;
  color: black;
  text-align: center;
  font-weight: ${({ isActive = false }) => (isActive ? 'bold' : 'normal')};
  opacity: 0.9;
`;

export default Container;

import { fromJS } from 'immutable';
import {
  ADD_VEHICLE_TYPE_FAILURE,
  ADD_VEHICLE_TYPE_SUCCESS,
  START_ADD_VEHICLE_TYPE_REQUEST,
  SET_FLEET_DATA_SUCCESS,
  SET_VEHICLE_TYPES_SUCCESS,
  UPDATE_VEHICLE_TYPE_FAILURE,
  UPDATE_VEHICLE_TYPE_REQUEST,
  UPDATE_VEHICLE_TYPE_SUCCESS,
  FINISH_LOADING,
} from './types';
import moment from 'moment';
import { isEqual } from 'lodash';

export const initialState = fromJS({
  vehicles: [],
  vehicleTypes: [],
  loading: false,
  error: '',
  success: '',
  currentStorageIndex: 0,
  modified_at: undefined,
});

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_FLEET_DATA_SUCCESS: {
      if (!isEqual(state.get('vehicles'), payload)) {
        return state.merge({ vehicles: payload });
      } else {
        return state;
      }
    }

    case START_ADD_VEHICLE_TYPE_REQUEST: {
      return state.merge({ ...state, loading: true, error: '' });
    }

    case SET_VEHICLE_TYPES_SUCCESS: {
      return state.merge({ ...payload });
    }

    case ADD_VEHICLE_TYPE_SUCCESS: {
      return state.merge({
        ...state,
        vehicleTypes: payload,
        loading: true,
        error: '',
        // Africa/Abidjan is +00:00, since backend dateime is +00:00
        modified_at: moment().tz('Africa/Abidjan').format(),
      });
    }
    case ADD_VEHICLE_TYPE_FAILURE: {
      return state.merge({ ...state, loading: false, error: payload });
    }

    case UPDATE_VEHICLE_TYPE_REQUEST: {
      return state.merge({ ...state, loading: true, error: '' });
    }
    case UPDATE_VEHICLE_TYPE_SUCCESS: {
      return state.merge({
        ...state,
        vehicleTypes: payload,
        loading: true,
        error: '',
        // Africa/Abidjan is +00:00, since backend dateime is +00:00
        modified_at: moment().tz('Africa/Abidjan').format(),
      });
    }
    case UPDATE_VEHICLE_TYPE_FAILURE: {
      return state.merge({ ...state, loading: false, error: payload });
    }
    case FINISH_LOADING: {
      return state.merge({ ...state, loading: false });
    }
    default:
      return state;
  }
};

export default reducer;

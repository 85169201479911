import * as actions from 'modules/ui/actions';

import debug from 'utils/debug';

const D2 = debug('m:ui:reducers:ui');

const bookingsFilterChanged = (state, payload) =>
  D2.S.FUNCTION('bookingsFilterChanged', { payload }, () => {
    return state.setIn(['commuteOffer', 'bookingsFilter'], payload);
  });

bookingsFilterChanged.type = actions.BOOKINGS_FILTER_CHANGED;

export default bookingsFilterChanged;

import styled from 'styled-components';

export default styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 58px;
  background: linear-gradient(180deg, rgba(12, 15, 20, 0) 0%, #0c0f14 36.84%);
`;

// Order panels - right panele
import React, { useState, useEffect, useRef } from 'react';
import {
  List as VirtualizedList,
  AutoSizer,
  CellMeasurer, // A wrapper component to calculate height of cells for infinite loading
  CellMeasurerCache, // an array stores the height of cells
} from 'react-virtualized';
import { styled } from 'baseui';
import { memoHumanize } from 'utils/CommuteOffer';
import debug from 'utils/debug';
import PanelContainer from '../PanelContainer';
import Header from './Header';
import Booking from './Booking';
import AssignedBooking from './Booking/AssignedBooking';
import Filter from 'components/Filter';
import GradientBackground from '../Vehicles/SaveBottom/GradientBackground';
import PanelList from '../PanelList';
import { StyledSpinnerNext } from 'baseui/spinner';
import { LOGISTICS_ROUTE } from 'utils/constants';

const D2 = debug('p:Logistics:Panels:Bookings');

global.GEODISC_COMMUTE_OFFER_BOOKINGS_PANEL_USES_VIRTUALIZED_LIST = false;
const MAX_RECORDS = 10;

const Wrapper = styled('div', () => {
  return {
    overflow: 'scroll',
  };
});

const defaultScrollToBooking = uid =>
  D2.S.FUNCTION('geodisc$ScrollToBooking<DOM>', { uid }, ({ $D2 }) => {
    const elementId = `booking_${uid}`;
    const element = window.document.getElementById(elementId);
    $D2.S.INFO('element', { element, elementId, uid });

    if (element) {
      element.scrollIntoView();
    }

    return 'DOM';
  });

global.geodisc$ScrollToBooking = defaultScrollToBooking;

const Bookings = (props) => {
  const {
    t,
    bookings,
    isHidden,
    toggleBookingsHidden,
    cleanActiveBookingId,
    activeBookingIndex,
    isServiceDataSource,
    isFilteringEnabled,
    changeBookingsFilter,
    activeBooking,
    serviceDate,
    isBookingReady,
  } = props;

  const mounted = useRef();
  let theList;
  const cellHeightCache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 140,
  });
  const [renderedBookings, setRenderedBookings] = useState([]);
  const [id, setDisplayedId] = useState('');
  const [wrapperStyle, setWrapperStyle] = useState({
    height: 'calc(100% - 126px)',
  });
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (isHidden && activeBooking) {
      cleanActiveBookingId();
    }
  }, [isHidden]);

  useEffect(() => {
    if (!isHidden) {
      toggleBookingsHidden();
      cleanActiveBookingId();
    }
  }, [serviceDate]);

  useEffect(() => {
    if (isBookingReady) {
      setIsLoading(false);
    }
  }, [isBookingReady]);

  useEffect(() => {
    if (!mounted.current) {
      D2.S.INFO('componentDidMount');
      setScrollToBookingMethod();
    } else {
      D2.S.INFO('componentDidUpdate');
      if (theList) {
        theList.forceUpdateGrid();
      }
      setScrollToBookingMethod();
    }

    if (activeBooking) {
      const displayedBooking = bookings.find(
        booking => booking.uid === activeBooking
      );
      setDisplayedId(displayedBooking?.data?.external_id || '-');
      setVisibleBookings(displayedBooking);
      setWrapperStyle({ height: 'calc(100% - 48px)' });
    } else {
      setVisibleBookings();
      setWrapperStyle({ height: 'calc(100% - 126px)' });
    }

    return () => {
      D2.S.INFO('componentWillUnmount');
      theList = null;
      setScrollToBookingMethod();
    };
  }, [theList, bookings, activeBooking]);

  useEffect(() => {}, [isLoading]);

  const closeBookingDetails = () => {
    cleanActiveBookingId();
  };
  const setVisibleBookings = (booking) => {
    if (booking) {
      setRenderedBookings([booking]);
      return;
    }
    /**
     * filter out all unassigned bookings if the filter is enabled
     */
    const filteredUnassignedBookings = bookings.filter(
      booking => !booking?.$assignedVehicle || !booking?.assigned_vehicle_id
    );
    setRenderedBookings(filteredUnassignedBookings);
  };

  const setScrollToBookingMethod = () =>
    D2.S.FUNCTION('setScrollToBookingMethod', {}, () => {
      if (theList) {
        global.geodisc$ScrollToBooking = uid =>
          D2.S.FUNCTION(
            'geodisc$ScrollToBooking<VirtualizedList>',
            { uid, props: props },
            ({ $D2 }) => {
              const { bookings } = props;
              $D2.S.INFO('bookings', { bookings, uid });
              const index = bookings.findIndex(x => x.uid === uid);
              $D2.S.INFO('index', { index, bookings, uid });

              const elements = window.document.getElementsByClassName(
                'ReactVirtualized__Grid',
                'ReactVirtualized__List'
              );
              const element =
                elements && elements.length === 1 ? elements[0] : undefined;
              $D2.S.INFO('element', { element, elements });

              if (element) {
                element.scrollTo(0, index * 260);
              }
              return 'VirtualizedList';
            }
          );
      } else {
        global.geodisc$ScrollToBooking = defaultScrollToBooking;
      }
    });

  const renderBooking = ({ index, parent, key, style }) =>
    D2.S.FUNCTION(
      'renderBooking',
      { index, key, style, props: props },
      ({ $D2 }) => {
        const booking = bookings[index];
        $D2.S.INFO('booking', { booking, index, key });

        if (activeBooking) {
          return (
            <CellMeasurer
              key={key}
              cache={cellHeightCache}
              parent={parent}
              columnIndex={0}
              rowIndex={index}
            >
              <div style={style}>
                <AssignedBooking
                  key='display-booking'
                  booking={memoHumanize(renderedBookings[0])}
                />
              </div>
            </CellMeasurer>
          );
        }

        return global.GEODISC_COMMUTE_OFFER_BOOKINGS_PANEL_USES_VIRTUALIZED_LIST ? (
          <div key={key} style={style}>
            <Booking
              key={`booking-${index}`}
              booking={memoHumanize(renderedBookings[index])}
            />
          </div>
        ) : (
          // CellMeasurer component aims to recalculate the height of the cell
          // because the height of a booking could be changed based on expand status.
          <CellMeasurer
            key={key}
            cache={cellHeightCache}
            parent={parent}
            columnIndex={0}
            rowIndex={index}
          >
            <div style={style}>
              <Booking
                key={`booking-${index}`}
                booking={renderedBookings[index]}
                recalculateHeight={() => resetHeightCache(index)}
              />
            </div>
          </CellMeasurer>
        );
      }
    );

  const resetHeightCache = (index) => {
    cellHeightCache.clear(index, 0);
    if (theList) {
      theList.recomputeRowHeights(index);
    }
  };

  return D2.S.FUNCTION('render', { props: props }, () => {
    return (
      <>
        <PanelContainer
          isHidden={isHidden}
          isServiceDataSource={isServiceDataSource}
          onClick={isHidden ? toggleBookingsHidden : null}
          style={{ zIndex: 1 }}
        >
          <Header
            key='header'
            size={bookings.length}
            isHidden={isHidden || activeBooking}
            toggleBookingsHidden={toggleBookingsHidden}
            label={activeBooking ? id : null}
            closeBookingDetails={closeBookingDetails}
          />
          {isFilteringEnabled && !isHidden && !activeBooking && (
            <Filter
              changeFilter={changeBookingsFilter}
              placeholder={t('p.Editor.Bookings.Search.Id')}
            />
          )}
          <Wrapper style={wrapperStyle}>
            <PanelList
              key='list'
              isHidden={isHidden}
              isFilteringEnabled={isFilteringEnabled}
              style={{ height: '100%' }}
            >
              {global.GEODISC_COMMUTE_OFFER_BOOKINGS_PANEL_USES_VIRTUALIZED_LIST ? (
                <AutoSizer>
                  {({ height, width }) => (
                    <VirtualizedList
                      key='bookings-VirtualizedList'
                      // eslint-disable-next-line
                      ref={(ref) => (theList = ref)}
                      width={width}
                      height={height}
                      rowHeight={260}
                      rowRenderer={renderBooking}
                      scrollToIndex={activeBookingIndex}
                      rowCount={bookings.length}
                      overscanRowCount={10}
                    />
                  )}
                </AutoSizer>
              ) : (
                <AutoSizer>
                  {({ height, width }) => (
                    <VirtualizedList
                      key='bookings-VirtualizedList'
                      // eslint-disable-next-line
                      ref={(ref) => (theList = ref)}
                      width={width}
                      height={height}
                      deferredMeasurementCache={cellHeightCache}
                      rowHeight={cellHeightCache.rowHeight}
                      rowRenderer={renderBooking}
                      rowCount={renderedBookings.length}
                      overscanRowCount={10}
                      style={{ paddingBottom: '24px' }}
                    />
                  )}
                </AutoSizer>
              )}
            </PanelList>
          </Wrapper>
          <GradientBackground />
        </PanelContainer>
        {/* Loading animation - DISABLED FOR NOW */}
        {false && isLoading && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              zIndex: 1000,
              display: 'flex',
              flexDirection: 'column',
              width: '100vw',
              height: '100vh',
              background: '#000',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <StyledSpinnerNext $color='#fff' />
            <p>
              <b>{t('c.LoadingScreen.Loading')}</b>
            </p>
          </div>
        )}
      </>
    );
  });
};

Bookings.displayName = 'Bookings';

export default Bookings;

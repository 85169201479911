import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100px;
  align-items: center;
  flex-shrink: 0;
  margin-left: 50px;
  justify-content: space-between;
`;

export default Container;

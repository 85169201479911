import debug from 'utils/debug';

const D2 = debug('m:CommuteOffer:DeleteEmptyVehicles');

// eslint-disable-next-line import/prefer-default-export
export const commuteOffer$DeleteEmptyVehicles = (commuteOffers, opts) => {
  D2.S.INFO('Request', {
    commuteOffers,
    opts,
  });

  const options = opts || {};

  const keepRequestVehicles = options.keepRequestVehicles || false;

  const calculatedResults = commuteOffers.map((offer) => {
    D2.S.INFO('calculatedOffers:offer', {
      offer,
      vehicles: offer.result.vehicles,
    });

    const result__vehicles = Object.keys(offer.result.vehicles).reduce(
      (newVehiclesAcc, vehicleId) => {
        const vehicle = offer.result.vehicles[vehicleId];
        D2.S.INFO('calculatedOffers:offer:vehicles:vehicle', {
          offer,
          vehicleId,
          vehicle,
        });
        return vehicle.length
          ? { ...newVehiclesAcc, [vehicleId]: vehicle }
          : newVehiclesAcc;
      },
      {}
    );
    D2.S.INFO('calculatedOffers:offer:result__vehicles', {
      offer,
      vehicles: result__vehicles,
    });

    const stateless_api_request_data__vehicles = !keepRequestVehicles
      ? offer.stateless_api_request_data.vehicles.filter(
          vehicle => typeof result__vehicles[vehicle.agent_id] !== 'undefined'
        )
      : offer.stateless_api_request_data.vehicles;
    D2.S.INFO(
      'importBookings:calculatedOffers:offer:stateless_api_request_data__vehicles',
      {
        offer,
        vehicles: stateless_api_request_data__vehicles,
      }
    );

    return {
      ...offer,
      stateless_api_request_data: {
        ...offer.stateless_api_request_data,
        vehicles: stateless_api_request_data__vehicles,
      },
      result: {
        ...offer.result,
        vehicles: result__vehicles,
      },
    };
  });
  D2.S.INFO('Success', { calculatedResults });

  return calculatedResults;
};

window.geodisc$commuteOffer$DeleteEmptyVehicles =
  commuteOffer$DeleteEmptyVehicles;

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { downloadDatasetCsv } from 'modules/datasets/actions';
import { countSelector } from 'modules/datasets/selectors';

import { selectedIdsDatasetsSelector } from 'modules/ui/selectors';

import Panel from './Panel';

const mapStateToProps = createImmutableSelector(
  selectedIdsDatasetsSelector,
  countSelector,
  (selectedIds, count) => ({
    selectedIds,
    count,
  })
);

const mapDispatchToProps = { downloadDatasetCsv };

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Panel);

import styled from 'styled-components';
import Break from 'assets/break.svg';

const BreakMarker = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 20px;
  background: ${({ color }) => color || '#000'} url(${Break}) no-repeat center;
  border: 1px solid white;
`;

export default BreakMarker;

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  position: fixed;
  left: ${global.DISABLE_SIMULATION_MULTIPLE_PANELS ? 360 : 640}px;
  bottom: 10px;
  width: 470px;
  height: 70px;
  background: rgba(54, 61, 74, 0.5);
  align-items: center;
  border-radius: 10px;
`;

export default Container;

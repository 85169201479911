import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ClickOutside from './ClickOutside';
import Container from './Container';

const Dropdown = ({ renderHandler, renderContent }) => {
  const [toggledOn, setToggledOn] = useState(false);
  const show = () => () => setToggledOn(true);
  const hide = () => () => setToggledOn(false);
  const toggle = () => setToggledOn(!toggledOn);
  return (
    <ClickOutside onClickOutside={hide()}>
      <Container>
        {renderHandler({
          hide,
          show,
          toggle,
          toggledOn,
        })}
        {toggledOn &&
          renderContent({
            hide,
            show,
            toggle,
            toggledOn,
          })}
      </Container>
    </ClickOutside>
  );
};

Dropdown.propTypes = {
  renderContent: PropTypes.func.isRequired,
  renderHandler: PropTypes.func.isRequired,
};

export default Dropdown;

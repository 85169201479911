import { sleep } from 'utils/CommuteSchedule/utils';

import { api$Masstransit } from 'api';

import debug from 'utils/debug';

const D2 = debug('m:CommuteSchedule:ResolveZipCodes');

const resolveZipCodes = async (zipCodes, opts) => {
  const { setProgress = () => {}, ignoreErrors = false } = opts;

  const buildingRequestsResolved = [];
  const buildingRequestsRejected = [];

  const buildingRequests = Object.values(zipCodes).map(location =>
    api$Masstransit
      .buildingByZipCode(location.country, location.city, location.zip_code)
      .then(
        (result) => {
          buildingRequestsResolved.push(result);
        },
        (error) => {
          buildingRequestsRejected.push({
            zip_code: location.zip_code,
            error,
          });
        }
      )
  );
  D2.S.INFO('buildingRequests', { buildingRequests });

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const progress =
      (buildingRequestsResolved.length + buildingRequestsRejected.length) /
      buildingRequests.length;
    D2.S.INFO('buildingRequests:Progress', {
      buildingRequestsResolved,
      buildingRequestsRejected,
      buildingRequests,
      progress,
    });
    // eslint-disable-next-line no-await-in-loop
    await setProgress('Resolving zip codes', progress);
    if (
      buildingRequestsResolved.length + buildingRequestsRejected.length ===
      buildingRequests.length
    ) {
      break;
    }
    // eslint-disable-next-line no-await-in-loop
    await sleep(500);
  }
  D2.S.INFO('buildingRequestsResolved', {
    buildingRequestsResolved,
  });
  D2.S.INFO('buildingRequestsRejected', {
    buildingRequestsRejected,
  });

  const buildingsByZipCode = buildingRequestsResolved.reduce(
    (acc, record) => {
      const { result } = record;
      const id = `${record.country}:${record.city}:${record.zip_code}`;
      if (result.objects && result.objects.length) {
        acc.resolved[id] = result.objects.map(item => ({
          id,
          name: item.name,
          address: item.address,
          blk_no: item.blk_no,
          coordinates: item.point.coordinates,
          code: item.code,
          city: record.city,
          country: record.country,
        }));
      } else {
        acc.failed.push({
          id: record.id,
          zip_code: record.zip_code,
          error: new Error('No objects returned'),
        });
      }
      return acc;
    },
    { resolved: {}, failed: [] }
  );
  D2.S.INFO('buildingsByZipCode', {
    buildingsByZipCode,
  });

  if (!ignoreErrors) {
    const buildingRequestsFailed = [
      ...buildingRequestsRejected,
      ...buildingsByZipCode.failed,
    ];
    if (buildingRequestsFailed.length) {
      const message = `Failed to resolve zip codes: ${buildingRequestsFailed
        .map(item => item.zip_code)
        .join(', ')}`;
      D2.S.INFO('Throw', { message, ignoreErrors });
      throw new Error(message);
    }
  }

  return {
    buildingRequestsResolved,
    buildingRequestsRejected,
    buildingsByZipCode,
  };
};

const commuteSchedule$ResolveZipCodes = async (currentPassengersData, opts) => {
  const { setProgress = () => {}, ignoreErrors = false } = opts;

  const personZipCodes = currentPassengersData
    .reduce((acc, person) => {
      const locations = [person.home, person.office].map(
        location =>
          location.$type === 'zip_code' && {
            id: `${location.country}:${location.city}:${location.zip_code}`,
            zip_code: location.zip_code,
            city: location.city,
            country: location.country,
          }
      );
      return [...acc, ...locations];
    }, [])
    .filter(x => !!x);
  D2.S.INFO('personZipCodes', {
    personZipCodes,
  });

  const zipCodes = personZipCodes.reduce((acc, location) => {
    return {
      ...acc,
      [location.id]: location,
    };
  }, {});
  D2.S.INFO('zipCodes', {
    zipCodes,
  });

  const {
    buildingRequestsResolved,
    buildingRequestsRejected,
    buildingsByZipCode,
  } = await resolveZipCodes(zipCodes, {
    setProgress,
    ignoreErrors,
  });
  D2.S.INFO('buildingRequestsResolved', {
    buildingRequestsResolved,
  });
  D2.S.INFO('buildingRequestsRejected', {
    buildingRequestsRejected,
  });
  D2.S.INFO('buildingsByZipCode', { buildingsByZipCode });

  const dataWithBuildings = currentPassengersData.map((record) => {
    const resolveLocation = location =>
      location.$type === 'zip_code'
        ? {
            ...location,
            $buildings: buildingsByZipCode.resolved[location.id],
          }
        : location;
    return {
      ...record,
      home: resolveLocation(record.home),
      office: resolveLocation(record.office),
    };
  });
  D2.S.INFO('dataWithBuildings', { dataWithBuildings });

  return dataWithBuildings;
};

export default commuteSchedule$ResolveZipCodes;

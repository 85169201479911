export const getBasicAuth = () => {
  const token = global.localStorage.getItem(global.GEODISC_AUTH_TOKEN);

  if (!token) {
    global.localStorage.removeItem(global.GEODISC_AUTH_LOGIN);
    global.localStorage.removeItem(global.GEODISC_AUTH_TOKEN);
    const nextAddress = window.location.pathname + window.location.search;
    global.geodisc$setWindowLocation(
      `/login?next=${encodeURIComponent(nextAddress)}`
    );
  }

  return `Basic ${token}`;
};

export const getHeadersObject = () => ({
  Authorization: getBasicAuth(),
  'Content-Type': 'application/json',
});

export const getHeaders = () => new global.Headers(getHeadersObject());

export default getHeaders;

import React from 'utils/react';
import useTranslation from 'utils/react/useTranslation';

import Container from './Container';
import Button from './Button';

const Menu = ({ active, onChange }) => {
  const { t } = useTranslation();

  const config = ['file', 'fromdataset', 'draw'].reduce(
    (memo, item) => ({
      ...memo,
      [item]: t(`c.ModalWindow.NewGeofence.Tabs.${item}`),
    }),
    {}
  );

  const keys = Object.keys(config);

  return (
    <Container>
      {keys.map((key) => {
        const onClick = () => {
          onChange(key);
        };

        return (
          <Button key={key} isActive={active === key} onClick={onClick}>
            {config[key]}
          </Button>
        );
      })}
    </Container>
  );
};

export default Menu;

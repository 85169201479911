import React from 'utils/react';
import moment from 'moment-timezone';
import Container from './Container';

import StatusCircle from '../StatusCircle';

const formatTime = ({ time, isUnix }) => {
  if (isUnix) {
    return moment(time * 1000)
      .tz(global.GEODISC_TIMEZONE)
      .format('HH:mm.ss');
  }

  return time <= 12 ? `${time} AM` : `${time - 12} PM`;
};

const Loading = props => (
  <Container>
    &nbsp;&nbsp;&nbsp;
    <StatusCircle color='red' />
    &nbsp;&nbsp; Live&nbsp;&nbsp;
    {formatTime(props)}
  </Container>
);

export default Loading;

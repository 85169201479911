import { sync } from 'utils/async';

import debug from 'utils/debug';

const D2 = debug('u:CommuteOffer:Validation:Validators');

export const validator = async commuteOffer =>
  D2.A.FUNCTION('0020:AssignmentOrder', { commuteOffer }, async ({ $D2 }) => {
    // if (commuteOffer.$source?.type !== 'Simulation') {
    //   return commuteOffer;
    // }

    const { result } = commuteOffer;

    const newVehicles = Object.entries(result.vehicles).reduce(
      (vehiclesMemo, [agent_id, vehicle]) => {
        $D2.S.INFO('vehicle', { agent_id, vehicle });
        return {
          ...vehiclesMemo,
          [agent_id]: vehicle
            ? vehicle.map(node => ({
                ...node,
              }))
            : undefined,
        };
      },
      {}
    );

    await sync();
    return {
      ...commuteOffer,
      result: {
        ...commuteOffer.result,
        vehicles: newVehicles,
      },
    };
  });

export default validator;

import * as React from 'react';

import { Tag } from 'baseui/tag';

const Badge = props => (
  <Tag
    onClick={props.onClick}
    closeable={false}
    kind='custom'
    variant='light'
    color='rgb(35, 191, 154)'
    overrides={{
      Root: {
        style: ({ $isHovered }) => ({
          backgroundColor: $isHovered
            ? 'rgba(35, 191, 154, 0)'
            : 'rgba(35, 191, 154, 0)',
        }),
      },
      Text: {
        style: ({ $isHovered }) => ({
          color: $isHovered ? 'rgb(35, 191, 154)' : 'rgb(35, 191, 154)',
        }),
      },
    }}
  >
    Online
  </Tag>
);

export default Badge;

import styled from 'styled-components';

const Button = styled.div`
  width: 130px;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
  margin-left: 10px;
  padding: 5px 20px;
  color: #edece8;
  font-weight: bold;
  border: 1px solid gray;
  border-radius: 50px;

  &:hover {
    background-color: rgba(167, 174, 185, 0.1);
  }

  ${({ isDisable }) =>
    isDisable &&
    `
    pointer-events: none;
    opacity: 0.6;
  `}
`;

export default Button;

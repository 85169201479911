export const SET_FLEET_DATA_REQUEST = '@@fleet/SET_FLEET_DATA_REQUEST';
export const SET_FLEET_DATA_SUCCESS = '@@fleet/SET_FLEET_DATA_SUCCESS';
export const SET_FLEET_DATA_FAILURE = '@@fleet/SET_FLEET_DATA_FAILURE';

export const FLEET_ADD_VEHICLE_REQUEST = '@@fleet/FLEET_ADD_VEHICLE_REQUEST';
export const FLEET_START_ADD_VEHICLE_REQUEST =
  '@@fleet/FLEET_START_ADD_VEHICLE_REQUEST';
export const FLEET_ADD_VEHICLE_SUCCESS = '@@fleet/FLEET_ADD_VEHICLE_SUCCESS';
export const FLEET_ADD_VEHICLE_FAILURE = '@@fleet/FLEET_ADD_VEHICLE_FAILURE';

export const FLEET_UPDATE_VEHICLE_REQUEST =
  '@@fleet/FLEET_UPDATE_VEHICLE_REQUEST';
export const FLEET_START_UPDATE_VEHICLE_REQUEST =
  '@@fleet/FLEET_START_UPDATE_VEHICLE_REQUEST';
export const FLEET_UPDATE_VEHICLE_SUCCESS =
  '@@fleet/FLEET_UPDATE_VEHICLE_SUCCESS';
export const FLEET_UPDATE_VEHICLE_FAILURE =
  '@@fleet/FLEET_UPDATE_VEHICLE_FAILURE';

export const FLEET_DELETE_VEHICLE_REQUEST = '@@fleet/DELETE_VEHICLE_REQUEST';
export const FLEET_START_DELETE_VEHICLE_REQUEST =
  '@@fleet/START_DELETE_VEHICLE_REQUEST';
export const FLEET_DELETE_VEHICLE_SUCCESS = '@@fleet/DELETE_VEHICLE_SUCCESS';
export const FLEET_DELETE_VEHICLE_FAILURE = '@@fleet/DELETE_VEHICLE_FAILURE';

export const SET_VEHICLE_TYPES_REQUEST = '@@fleet/SET_VEHICLE_TYPES_REQUEST';
export const SET_VEHICLE_TYPES_SUCCESS = '@@fleet/SET_VEHICLE_TYPES_SUCCESS';
export const SET_VEHICLE_TYPES_FAILURE = '@@fleet/SET_VEHICLE_TYPES_FAILURE';

export const ADD_VEHICLE_TYPE_REQUEST = '@@fleet/ADD_VEHICLE_TYPE_REQUEST';
export const START_ADD_VEHICLE_TYPE_REQUEST =
  '@@fleet/START_ADD_VEHICLE_TYPE_REQUEST';
export const ADD_VEHICLE_TYPE_SUCCESS = '@@fleet/ADD_VEHICLE_TYPE_SUCCESS';
export const ADD_VEHICLE_TYPE_FAILURE = '@@fleet/ADD_VEHICLE_TYPE_FAILURE';

export const UPDATE_VEHICLE_TYPE_REQUEST =
  '@@fleet/UPDATE_VEHICLE_TYPE_REQUEST';
export const START_UPDATE_VEHICLE_TYPE_REQUEST =
  '@@fleet/START_UPDATE_VEHICLE_TYPE_REQUEST';
export const UPDATE_VEHICLE_TYPE_SUCCESS =
  '@@fleet/UPDATE_VEHICLE_TYPE_SUCCESS';
export const UPDATE_VEHICLE_TYPE_FAILURE =
  '@@fleet/UPDATE_VEHICLE_TYPE_FAILURE';
export const FINISH_LOADING = '@@fleet/FINISH_LOADING';

import React from 'utils/react';

import Selectable from 'components/Selectable';

import Container from './Container';
import Overflow from './Overflow';
import RowContainer from './RowContainer';
import withTranslation from 'utils/react/withTranslation';

const errorRenderers = {
  exception: (err) => {
    return <div>{err.message || (err.error && err.error.message)}</div>;
  },
  test: err => err.message,
  'request.vehicles.DuplicateAgentId': (record) => {
    return (
      <div>
        <h2>{t('c.Table.modal.errormessage.agent_id')}</h2>
        <br />
        Agent ID: <Selectable>{record.prevVehicle.agent_id}</Selectable>
        <br />
        {false && (
          <React.Fragment>
            Vehicle #1 ID: <Selectable>{record.prevVehicle.id}</Selectable>
            <br />
            Vehicle #2 ID: <Selectable>{record.currVehicle.id}</Selectable>
            <br />
          </React.Fragment>
        )}
      </div>
    );
  },
  'node.booking_uid.InvalidType': (record) => {
    return (
      <div>
        <h2>{t('c.Table.modal.errormessage.agent_id')}</h2>
        <br />
        Node: <Selectable>{record.node.uid}</Selectable>
        <br />
        Value: <Selectable>{record.node.booking_uid}</Selectable>
        <br />
        Type:{' '}
        <Selectable>
          {typeof record.node.booking_uid} (string expected)
        </Selectable>
      </div>
    );
  },
  'node.node_status.InvalidType': (record) => {
    return (
      <div>
        <h2>Node contains an invalid status</h2>
        <br />
        Node: <Selectable>{record.node.uid}</Selectable>
        <br />
        Value: <Selectable>{record.node.status}</Selectable>
        <br />
        Vehicle id: <Selectable>{record.node.assigned_vehicle_id}</Selectable>
        <br />
      </div>
    );
  },
  'node.stop_id.InvalidType': (record) => {
    return (
      <div>
        <h2>
          Node contains an invalid stop id (must be a valid Masstransit id)
        </h2>
        <br />
        Booking: <Selectable>{record.node.booking_uid}</Selectable>
        <br />
        Node: <Selectable>{record.node.uid}</Selectable>
        <br />
        Stop ID: <Selectable>{record.node.stop_id}</Selectable>
      </div>
    );
  },
  'result.assigned_bookings.stop_id.InvalidType': (record) => {
    return (
      <div>
        <h2>{t('c.Table.modal.errormessage.AssignedBooking.stopID')}</h2>
        <br />
        UID: <Selectable>{record.record.uid}</Selectable>
        <br />
        {t('commuteOffer.AssignedVehicle')}{' '}
        <Selectable>{record.record.assigned_vehicle_id}</Selectable>
        <br />
        {t('commuteOffer.PickupStopID')}{' '}
        <Selectable>{record.record.scheduled_pickup_stop_id}</Selectable>
        <br />
        {t('commuteOffer.DropoffStopID')}{' '}
        <Selectable>{record.record.scheduled_dropoff_stop_id}</Selectable>
      </div>
    );
  },
  'result.vehicles.stop_id.InvalidType': (record, t) => {
    return (
      <div>
        <h2>{t('c.Table.modal.errormessage.VehiclePath.stopID')}</h2>
        <br />
        {t('c.Table.Vehicle')}&nbsp;
        <Selectable>{record.agent_id}</Selectable>
        <br />
        {t('c.Table.Booking')}&nbsp;
        <Selectable>{record.node.booking_uid}</Selectable>
        <br />
        UID:&nbsp;
        <Selectable>{record.node.uid}</Selectable>
        <br />
        {t('c.Table.Type')}&nbsp;
        <Selectable>{record.node.node_type}</Selectable>
        <br />
        H3 Index:&nbsp;
        <Selectable>{record.node.h3}</Selectable>
        <br />
        {t('c.Table.StopID')}&nbsp;
        <Selectable>{record.node.stop_id}</Selectable>
        <br />
        {t('c.Table.LocationCode')}&nbsp;
        <Selectable>{record.node.location_code}</Selectable>
        <br />
        {t('c.Table.LocationName')}&nbsp;
        <Selectable>{record.node.location_name}</Selectable>
        <br />
        Lon:&nbsp;
        <Selectable>{record.node.lon}</Selectable>
        <br />
        Lat:&nbsp;
        <Selectable>{record.node.lat}</Selectable>
      </div>
    );
  },
};

const renderRecord = record =>
  errorRenderers[record.type]
    ? errorRenderers[record.type](record)
    : `Unknown Error: ${JSON.stringify(record)}`;

const Table = ({ errors, warnings, fixes }) => (
  <React.Fragment>
    <Overflow>
      <Container isLoading={!errors.length}>
        {[
          ...errors.map((record, i) => (
            <RowContainer key={`error-${i}`}>
              {renderRecord(record)}
            </RowContainer>
          )),
          ...warnings.map((record, i) => (
            <RowContainer key={`warning-${i}`}>
              {renderRecord(record)}
            </RowContainer>
          )),
          ...fixes.map((record, i) => (
            <RowContainer key={`fix-${i}`}>{renderRecord(record)}</RowContainer>
          )),
        ]}
      </Container>
    </Overflow>
  </React.Fragment>
);

export default withTranslation()(Table);

import React from 'react';
import { styled } from 'baseui';
import cross from 'assets/plusWhite.svg';
import arrow from 'assets/arrow.svg';

import CustomButton from 'components/Forms/Button';
import { useTranslation } from 'react-i18next';

const TopBar = styled('div', ({ $theme }) => {
  return {
    background: $theme?.colors?.containerBackground,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '73px',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: $theme?.colors?.tableHeader,
    position: 'sticky',
    zIndex: 10,
  };
});

const CloseBtn = styled('img', () => {
  return {
    transform: 'rotate(45deg)',
    width: '30px',
    height: '30px',
    margin: '21.25px 32px',
  };
});

CloseBtn.defaultProps = {
  src: cross,
};

const ArrowBtn = styled('img', () => {
  return {
    transform: 'rotate(90deg)',
    height: '9px',
    margin: '32.25px 6px 0 30px',
  };
});

ArrowBtn.defaultProps = {
  src: arrow,
};

const TitleWrapper = styled('div', () => {
  return {
    display: 'flex',
    flexDirection: 'row',
  };
});

const Title = styled('h2', ({ $theme }) => {
  return {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '140%',
    display: 'flex',
    alignItems: 'center',
    color: $theme?.colors?.white,
    paddingLeft: '25px',
    borderLeft: '1px solid #1C232D',
  };
});

export default ({
  pageTitle,
  handleClose,
  btnOneAction,
  btnTwoAction,
  btnOneText,
  btnTwoText,
  formId,
  disableSaveBtn,
  backBtn = false,
}) => {
  const { t } = useTranslation();

  return (
    <TopBar>
      <TitleWrapper>
        <span
          onKeyDown={handleClose}
          role='button'
          styling='link'
          onClick={handleClose}
          tabIndex={0}
        >
          {backBtn ? (
            <p
              style={{
                fontFamily: 'Montserrat',
                margin: '0 24px 0 0',
                fontWeight: '600',
                fontSize: '14px',
              }}
            >
              <ArrowBtn />
              {t('c.navigation.back')}
            </p>
          ) : (
            <CloseBtn />
          )}
        </span>
        <Title>{pageTitle}</Title>
      </TitleWrapper>
      <div>
        {btnOneText && (
          <CustomButton onClick={btnOneAction}>{btnOneText}</CustomButton>
        )}
        {btnTwoText && (
          <CustomButton
            $filled
            onClick={btnTwoAction}
            type='submit'
            form={formId}
            disabled={disableSaveBtn}
          >
            {btnTwoText}
          </CustomButton>
        )}
      </div>
    </TopBar>
  );
};

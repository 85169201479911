import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import {
  changeViewportCommuteOffer,
  onClickStopsLayer,
} from 'modules/maps/actions';

import {
  addPointToRoute,
  changeRoutePoint,
} from 'modules/commuteOffer/actions';
import {
  onClickNodesLayer,
  onClickBookingLayer,
  onClickVehiclesLayer,
  setDraggablePoint,
} from 'modules/ui/actions';
import { isDeliveryLayoutSelector } from 'modules/user/selectors';
import {
  commuteOfferViewportSelector,
  editableBookingsSourceSelector,
  vehiclesStopSourceSelector,
  nodesSourceSelector,
  bookingsSourceSelector,
  routeSourceSelector,
  routeLayerSelector,
  walkingRoutesSourceSelector,
} from 'modules/maps/selectors';

import { stopsSourceSelector } from 'modules/entities/selectors';
import {
  activeOnlineVehiclesSelector,
  activeOnlineVehiclesSourceSelector,
  commuteOfferLoadedSimulationSelector,
  commuteOfferIsReadOnlySelector,
} from 'modules/commuteOffer/selectors';
import {
  busStopsVisibleSelector,
  activeVehicleIdsSelector,
  addPointModeSelector,
  activeRouteStopUidSelector,
  draggablePointSelector,
  editableBookingIdSelector,
  transitstopTypesSelector,
  visibleTransitstopTypesSelector,
  visibleTransitstopSetsSelector,
  activeMapStyleSelector,
} from 'modules/ui/selectors';

import { geofencesActiveSourceSelector } from 'modules/geofences/selectors';

import Map from './Map';

const mapStateToProps = createImmutableSelector(
  commuteOfferViewportSelector,
  stopsSourceSelector,
  vehiclesStopSourceSelector,
  nodesSourceSelector,
  editableBookingsSourceSelector,
  bookingsSourceSelector,
  routeSourceSelector,
  routeLayerSelector,
  walkingRoutesSourceSelector,
  busStopsVisibleSelector,
  activeVehicleIdsSelector,
  addPointModeSelector,
  activeRouteStopUidSelector,
  draggablePointSelector,
  editableBookingIdSelector,
  geofencesActiveSourceSelector,
  transitstopTypesSelector,
  visibleTransitstopTypesSelector,
  visibleTransitstopSetsSelector,
  activeMapStyleSelector,
  activeOnlineVehiclesSelector,
  activeOnlineVehiclesSourceSelector,
  commuteOfferLoadedSimulationSelector,
  commuteOfferIsReadOnlySelector,
  isDeliveryLayoutSelector,
  (
    viewport,
    stops,
    vehicles,
    nodes,
    editableBookingsSource,
    bookings,
    routeSource,
    routeLayer,
    walkingRoutes,
    busStopsVisible,
    activeVehicleIds,
    pointEditing,
    activeRouteStopUid,
    draggablePoint,
    editableBookingId,
    geofencesSource,
    transitstopTypes,
    visibleTransitstopTypes,
    visibleTransitstopSets,
    activeMapStyle,
    activeOnlineVehicles,
    activeOnlineVehiclesSource,
    simulation,
    commuteOfferIsReadOnly,
    isDeliveryLayout
  ) => ({
    viewport,
    stops,
    vehicles,
    nodes,
    editableBookingsSource,
    bookings,
    routeSource,
    routeLayer,
    walkingRoutes,
    busStopsVisible,
    activeVehicleIds,
    pointEditing,
    activeRouteStopUid,
    draggablePoint,
    editableBookingId,
    geofencesSource,
    transitstopTypes,
    visibleTransitstopTypes,
    visibleTransitstopSets,
    activeMapStyle,
    activeOnlineVehicles,
    activeOnlineVehiclesSource,
    simulation,
    commuteOfferIsReadOnly,
    isDeliveryLayout,
  })
);

const mapDispatchToProps = {
  onViewportChange: changeViewportCommuteOffer,
  onClickNodesLayer,
  onClickVehiclesLayer,
  onClickBookingLayer,
  onClickStopsLayer,
  addPointToRoute,
  changeRoutePoint,
  setDraggablePoint,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Map);

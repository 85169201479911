import React from 'utils/react';

import useTranslation from 'utils/react/useTranslation';

import { LocaleProvider } from 'baseui';

const jp = {
  accordion: {
    collapse: '閉じる',
    expand: 'もっと見る',
  },
  breadcrumbs: {
    ariaLabel: 'パンくずリスト',
  },
  datepicker: {
    ariaLabel: '日付を選択してください。',
    ariaLabelRange: '期間を選択してください。',
    ariaLabelCalendar: 'カレンダー',
    ariaRoleDescriptionCalendarMonth: '月ごとのカレンダー',
    previousMonth: '前月',
    nextMonth: '次月',
    pastWeek: '前週',
    pastMonth: '前月',
    pastThreeMonths: '直近3ヶ月',
    pastSixMonths: '直近6ヶ月',
    pastYear: '直近1年',
    pastTwoYears: '直近2年',
    screenReaderMessageInput:
      '「↓」キーを押してカレンダーを表示し、日付を選択してください。選択後、「Esc」キーを押すとカレンダーが閉じます。', // eslint-disable-next-line
    selectedDate: '${date}が選択されました。', // eslint-disable-next-line
    selectedDateRange: '${startDate}～${endDate}が選択されました。',
    selectSecondDatePrompt: '2日目を選択してください。',
    quickSelectLabel: '期間を選択してください。',
    quickSelectAriaLabel: '期間を選択してください。',
    quickSelectPlaceholder: 'なし',
    timeSelectEndLabel: '終了時刻',
    timeSelectStartLabel: '開始時刻',
    timePickerAriaLabel12Hour: '時間を選択してください。（12時間制）',
    timePickerAriaLabel24Hour: '時間を選択してください。（24時間制）',
    timezonePickerAriaLabel: 'タイムゾーンを選択してください。',
    selectedStartDateLabel: '選択された開始日',
    selectedEndDateLabel: '選択された終了日',
    dateNotAvailableLabel: '日付が無効です。',
    dateAvailableLabel: '有効な日付が選択されました。',
    selectedLabel: '選択済み',
    chooseLabel: '選択',
  },
  datatable: {
    emptyState:
      '検索条件に合致するデータが見つかりませんでした。条件を変更して再度検索してください。',
    loadingState: 'ローディング中',
    searchAriaLabel: 'テキスト検索',
    filterAdd: 'フィルターを追加',
    filterExclude: '除外する',
    filterApply: '適用する',
    filterAppliedTo: 'フィルター適用先：',
    optionsLabel: 'フィルターする列を選択',
    optionsSearch: 'フィルターする列を検索',
    optionsEmpty: '合致する列がありません。',
    categoricalFilterSelectAll: 'すべて選択',
    categoricalFilterSelectClear: 'クリア',
    categoricalFilterEmpty: 'カテゴリが見つかりませんでした。',
    datetimeFilterRange: '期間',
    datetimeFilterRangeDatetime: '日付時刻',
    datetimeFilterRangeDate: '日付',
    datetimeFilterRangeTime: '時刻',
    datetimeFilterCategorical: 'カテゴリ',
    datetimeFilterCategoricalWeekday: '平日',
    datetimeFilterCategoricalMonth: '月',
    datetimeFilterCategoricalQuarter: '四半期',
    datetimeFilterCategoricalHalf: '半期',
    datetimeFilterCategoricalFirstHalf: '上半期',
    datetimeFilterCategoricalSecondHalf: '下半期',
    datetimeFilterCategoricalYear: '年',
    numericalFilterRange: '期間',
    numericalFilterSingleValue: '単一値',
    booleanFilterTrue: 'はい',
    booleanFilterFalse: 'いいえ',
    booleanColumnTrueShort: '○',
    booleanColumnFalseShort: '×',
  },
  buttongroup: {
    ariaLabel: 'ボタングループ',
  },
  fileuploader: {
    dropFilesToUpload:
      'アップロードするファイルをここにドラッグ＆ドロップしてください。',
    or: 'または',
    browseFiles: 'ファイルを検索',
    retry: '再アップロードする',
    cancel: 'キャンセル',
  },
  menu: {
    noResultsMsg: '結果なし',
    parentMenuItemAriaLabel:
      '親メニューを選択中です。「→」キーを押して子メニューを開いてください。「←」キーを押すと子メニューは閉じます。',
  },
  modal: {
    close: '閉じる',
  },
  drawer: {
    close: '閉じる',
  },
  pagination: {
    prev: '前ページ',
    next: '次ページ',
    preposition: 'のうち',
  },
  select: {
    noResultsMsg: '結果なし',
    placeholder: '選択',
    create: '作成',
  },
  toast: {
    close: '閉じる',
  },
};

const resources = {
  jp,
  ja: jp,
};

export default ({ children, ...otherProps }) => {
  const { i18n } = useTranslation();

  const { language } = i18n;

  const localeOverride = resources[language];

  return localeOverride ? (
    <LocaleProvider locale={localeOverride} {...otherProps}>
      {children}
    </LocaleProvider>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
};

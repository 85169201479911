import React from 'utils/react';
import Star from 'components/Star';

import Container from './Container';
import Menu from './Menu';

const HeaderButtons = ({ id, bookmarked, updateDataset }) => {
  const onClickStar = () => {
    const body = JSON.stringify({ bookmarked: !bookmarked });
    updateDataset(id, body);
  };

  return (
    <Container>
      <Menu />
      <Star isActive={bookmarked} onClick={onClickStar} />
    </Container>
  );
};

export default HeaderButtons;

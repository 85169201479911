import styled from 'styled-components';

const Label = styled.div`
  font-size: 17px;
  letter-spacing: 0;
  color: #edece8;
  overflow: auto;
  margin-right: 20px;
  flex-shrink: 0;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    border: transparent 1px solid;
    background-color: transparent;
  }

  overflow: -moz-scrollbars-none;
`;

export default Label;

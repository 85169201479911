import React from 'utils/react';

import useDetectBrowser from 'utils/react/useDetectBrowser';

import Container from './Container';
import Header from './Header';
import Message from './Message';

const Status = () => {
  const [browserName] = useDetectBrowser();

  const isChrome = browserName === 'chrome';

  return (
    <Container>
      {!isChrome && (
        <React.Fragment>
          <Header>This browser is not supported</Header>
          <Message>Use Google Chrome for a better browsing experience</Message>
        </React.Fragment>
      )}
    </Container>
  );
};

export default Status;

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import arrow from 'assets/arrow.svg';

const BackButton = styled(Link)`
  display: block;
  position: relative;
  text-decoration: none;
  height: 25px;
  color: #edece8;
  font-size: 20px;
  padding-left: 25px;
  padding-top: 3px;
  padding-bottom: 3px;

  cursor: pointer;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 25px;
    height: 25px;
    background: url(${arrow}) no-repeat center;
    transform: rotate(90deg);
    content: '';
  }
`;

export default BackButton;

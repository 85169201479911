import styled from 'styled-components';

const Format = styled.div`
  width: 50px;
  text-align: left;
  flex-shrink: 0;
  margin-right: 50px;
`;

export default Format;

import styled from 'styled-components';

export default styled.button`
  padding: 9px 24px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  margin-right: 10px;
  width: 90px;
  height: 34px;
  max-height: 34px;

  color: #edece8;
  font-size: 14px;
  font-weight: bold;

  background-color: ${({ isActive }) => (isActive ? '#9a3937' : '#2d343c')};
  cursor: ${({ isActive }) => (isActive ? 'pointer' : '')};
  outline: none;

  @keyframes colors {
    from {
      background-color: #9a3937;
    }
    50% {
      background-color: #9a6430;
    }
    to {
      background-color: #9a3937;
    }
  }

  ${({ isSaving }) => isSaving && 'animation: colors  2s infinite ease-in-out;'}
`;

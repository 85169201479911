import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  border: 1px solid gray;
  border-radius: 50px;
  padding: 5px 20px;
  margin-left: 30px;
`;

export default Container;

import React from 'utils/react';
import useTranslation from 'utils/react/useTranslation';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';

import debug from 'utils/debug';
import Table from './Table';

const D2 = debug('c:ModalWindows');

export default props =>
  D2.S.FUNCTION('DataTableViewer', { props }, ({ $D2 }) => {
    const { t } = useTranslation();
    const tt = (msg, ...opts) =>
      t(`c.ModalWindows.DataTableViewer.${msg}`, ...opts);

    const [isOpen, setIsOpen] = React.useState(false);
    const [headers, setHeaders] = React.useState([]);
    const [content, setContent] = React.useState([]);
    const [title, setTitle] = React.useState(t('c.messages.Info'));
    const [config, setConfig] = React.useState({});

    React.useEffect(() => {
      $D2.S.INFO('Update', { props });

      window.openDataTableViewer = ($headers, $content, opts) => {
        $D2.S.INFO('openDataTableViewer', { $headers, $content, opts });

        const options = opts || {};

        const $title = options.title || '';

        if (!$headers) {
          global.openInfoMessage(tt('Error.NothingToShow'), { title: $title });
          return;
        }

        setTitle($title);
        setHeaders($headers);
        setContent($content);
        setConfig(options);
        setIsOpen(true);
      };

      window.closeDataTableViewer = () => {
        $D2.S.INFO('closeDataTableViewer', { isOpen, config });
        setIsOpen(false);
      };

      return () => {
        D2.S.INFO('Cleanup', { isOpen, config });
        window.closeDataTableViewer = null;
      };
    });

    function onClose() {
      $D2.S.INFO('onClose', { isOpen, config });

      setIsOpen(false);
    }

    return (
      <React.Fragment>
        <Modal
          onClose={onClose}
          isOpen={isOpen}
          overrides={{
            Dialog: {
              style: {
                width: '90vw',
                height: '90vh',
                display: 'flex',
                flexDirection: 'column',
              },
            },
          }}
        >
          <ModalHeader>{title}</ModalHeader>
          <ModalBody style={{ flex: '1 1 0' }}>
            <Table columns={headers} rows={content} />
          </ModalBody>
          <ModalFooter>
            <ModalButton onClick={onClose}>{tt('Footer.Close')}</ModalButton>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  });

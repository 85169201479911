import React from 'utils/react';
import { v4 as uuidv4 } from 'uuid';

import Immutable from 'immutable';
import useTranslation from 'utils/react/useTranslation';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { ProgressBar } from 'baseui/progress-bar';
import { TimePicker } from 'baseui/timepicker';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Select, TYPE } from 'baseui/select';
import { defaultVehicleRoutingEngineJSON } from 'utils/CommuteOffer/defaults';

import formatForThisDate from 'utils/moment/formatForThisDate';

import moment from 'moment-timezone';

import debug from 'utils/debug';

const D2 = debug('c:ModalWindows:VehicleEditor');

export default props =>
  D2.S.FUNCTION('VehicleEditor', { props }, () => {
    const {
      currentProject,
      currentProjectId,
      projects,
      geofences,
      currentProjectVehicleModels,
    } = props;

    const { t } = useTranslation();

    const tt = (msg, ...opts) =>
      t(`c.ModalWindow.VehicleEditor.${msg}`, ...opts);

    const timezone = global.GEODISC_TIMEZONE;

    const projectsArray =
      projects && projects instanceof Immutable.List ? projects.toJS() : [];
    D2.S.INFO('projectsArray', {
      currentProject,
      currentProjectId,
      projects,
      projectsArray,
    });

    const isDataValid = currentProject && projectsArray.length;

    const [isOpen, setIsOpen] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [windowTitle, setWindowTitle] = React.useState('');

    const [selectedTimezone, setSelectedTimezone] = React.useState(null);
    const [selectedDate, setSelectedDate] = React.useState(null);

    const [originalVehicle, setOriginalVehicle] = React.useState({});

    const [vehicleId, setVehicleId] = React.useState(0);
    const [vehicleAgentId, setVehicleAgentId] = React.useState('');
    const [rawVehicleServiceNumber, setVehicleServiceNumber] =
      React.useState('');

    const updateVehicleServiceNumber = (value) => {
      const newServiceNumber = String(value ?? '');
      setVehicleServiceNumber(newServiceNumber);
    };

    const [vehicleProhibitedServiceNumber, setVehicleProhibitedServiceNumber] =
      React.useState('');

    const availableGeofences = geofences;
    const [selectedGeofences, setSelectedGeofences] = React.useState([]);

    const [availableVehicleModels, setAvailableVehicleModels] = React.useState(
      []
    );
    const [selectedVehicleModels, setSelectedVehicleModels] = React.useState(
      []
    );

    const [targetProject, setTargetProject] = React.useState(null);
    const [eventHandlers, setEventHandlers] = React.useState({});

    const [startTime, setStartTime] = React.useState(new Date());
    const [endTime, setEndTime] = React.useState(new Date());
    if (!targetProject && currentProjectId && projects) {
      const currentProjectInfo = projectsArray.find(
        x => x.id === currentProjectId
      );
      if (currentProjectInfo) {
        setTargetProject({
          label: currentProjectInfo.name,
          id: currentProjectInfo.id,
        });
      }
    }

    const vehicleServiceNumber = rawVehicleServiceNumber
      ? rawVehicleServiceNumber.trim()
      : '';

    const disabled =
      selectedVehicleModels.length !== 1 ||
      vehicleServiceNumber === '' ||
      vehicleServiceNumber === vehicleProhibitedServiceNumber ||
      !targetProject ||
      isSubmitting;
    D2.S.INFO('disabled', { disabled, vehicleServiceNumber, targetProject });

    const updateAvailableVehicleModels = value =>
      D2.S.FUNCTION('updateAvailableVehicleModels', { value }, () => {
        setAvailableVehicleModels(
          value.map(model => ({
            id: model.id,
            value: JSON.stringify(model),
          }))
        );
      });

    const updateSelectedVehicleModels = value =>
      D2.S.FUNCTION('updateSelectedVehicleModels', { value }, () => {
        return setSelectedVehicleModels(value);
      });

    const updateSelectedDate = value =>
      D2.S.FUNCTION('updateSelectedDate', { value }, () => {
        setSelectedDate(value);
      });

    const updateStartTime = value =>
      D2.S.FUNCTION('updateStartTime', { value }, () => setStartTime(value));

    const updateEndTime = value =>
      D2.S.FUNCTION('updateEndTime', { value }, () => setEndTime(value));

    React.useEffect(() =>
      D2.S.FUNCTION(
        'Update',
        {
          vehicleServiceNumber,
          startTime,
          endTime,
          selectedGeofences,
          availableGeofences,
          selectedVehicleModels,
          availableVehicleModels,
        },
        () => {
          window.openVehicleEditor = (vehicle, opts) =>
            D2.S.FUNCTION(
              'openVehicleEditor',
              { vehicle, geofences, opts },
              ({ $D2 }) => {
                const {
                  title = '',
                  vehicleModels = currentProjectVehicleModels,
                  prohibitedServiceNumber = '',
                  date = null,
                  onSubmit = null,
                } = opts || {};

                function* vehicleModelAutoIdGenerator() {
                  const tk = 'c.ModalWindow.VehicleEditor.VehicleModel.Default';
                  yield t(tk);
                  for (let i = 1; true; ++i) {
                    yield `${t(tk)} (${i})`;
                  }
                }

                const vehicleModelAutoId = vehicleModelAutoIdGenerator();

                const namedVehicleModels = vehicleModels.map(model => ({
                  ...model,
                  id: model.id ?? vehicleModelAutoId.next().value,
                }));

                setOriginalVehicle(vehicle);
                setVehicleProhibitedServiceNumber(
                  prohibitedServiceNumber.trim()
                );
                setSelectedTimezone(timezone);
                updateSelectedDate(
                  timezone ? moment(date).tz(timezone).format() : date
                );
                $D2.S.INFO('selectedDate', {
                  selectedDate,
                  date,
                  timezone,
                });
                setVehicleId(vehicle?.id || 0);
                setVehicleAgentId(vehicle?.agent_id || uuidv4());
                updateVehicleServiceNumber(vehicle?.service_number || '');
                setStartTime(
                  vehicle?.start_time
                    ? moment
                        .tz(
                          moment(vehicle?.start_time)
                            .tz(timezone)
                            .format('HH:mm'),
                          'LT',
                          moment.tz.guess()
                        )
                        .toDate()
                    : moment.tz('08:00', 'LT', moment.tz.guess()).toDate()
                );
                setEndTime(
                  vehicle?.end_time
                    ? moment
                        .tz(
                          moment(vehicle?.end_time)
                            .tz(timezone)
                            .format('HH:mm'),
                          'LT',
                          moment.tz.guess()
                        )
                        .toDate()
                    : moment.tz('20:00', 'LT', moment.tz.guess()).toDate()
                );
                if (
                  vehicle?.geofence_ids &&
                  Array.isArray(vehicle?.geofence_ids)
                ) {
                  const newSelectedGeofences = vehicle?.geofence_ids.reduce(
                    (memo, geofence_id) => {
                      const geofence = availableGeofences.find(
                        item => item.key === geofence_id
                      );
                      return geofence ? [...memo, geofence] : memo;
                    },
                    []
                  );
                  setSelectedGeofences(newSelectedGeofences);
                }
                updateAvailableVehicleModels(namedVehicleModels);

                const vehicleModelId = vehicle?.routing_engine?.vehicle_model;

                const originalVehicleModel = vehicleModelId
                  ? namedVehicleModels.find(
                      model => model.id === vehicleModelId
                    )
                  : null;

                if (originalVehicleModel) {
                  updateSelectedVehicleModels([
                    {
                      id: originalVehicleModel.id,
                      value: JSON.stringify(originalVehicleModel),
                    },
                  ]);
                } else {
                  updateSelectedVehicleModels([]);
                }

                setWindowTitle(title);
                setEventHandlers({ onSubmit });
                setIsSubmitting(false);

                setIsOpen(true);
              }
            );
          window.closeVehicleEditor = () =>
            D2.S.FUNCTION('closeVehicleEditor', {}, () => {
              setIsOpen(false);
            });
          return () =>
            D2.S.FUNCTION('Cleanup', {}, () => {
              window.openVehicleEditor = () => {};
            });
        }
      )
    );

    const onClose = () =>
      D2.S.FUNCTION('onClose', { isSubmitting }, () => {
        if (!isSubmitting) {
          setIsOpen(false);
        }
      });

    const onSumbit = () =>
      D2.S.FUNCTION(
        'onSumbit',
        {
          vehicleServiceNumber,
          targetProject,
          startTime,
          endTime,
          selectedGeofences,
          selectedVehicleModels,
          eventHandlers,
        },
        ({ $D2 }) => {
          if (eventHandlers.onSubmit) {
            const selectedVehicleModel =
              selectedVehicleModels[0]?.value &&
              typeof selectedVehicleModels[0]?.value === 'string'
                ? JSON.parse(selectedVehicleModels[0]?.value)
                : selectedVehicleModels[0];
            const selectedVehicleAgent = {
              ...selectedVehicleModel,
              id: undefined,
            };
            $D2.S.INFO('selectedVehicleModel', {
              selectedVehicleModel,
              selectedVehicleModels,
              availableVehicleModels,
            });
            const defaultVehicleRoutingEngine = {
              ...JSON.parse(defaultVehicleRoutingEngineJSON),
              routing_engine_name: 'osrme',
              road_network: 'alldriving',
            };

            const vehicle = {
              ...{ lon: 0, lat: 0, speed: 0 },
              ...(originalVehicle || {}),
              ...(selectedVehicleAgent || {}),
              id: vehicleId,
              agent_id: vehicleAgentId,
              service_number: vehicleServiceNumber,
              start_time: formatForThisDate(
                selectedDate,
                `${startTime.getHours()}:${startTime.getMinutes()}`,
                selectedTimezone
              ),
              end_time: formatForThisDate(
                selectedDate,
                `${endTime.getHours()}:${endTime.getMinutes()}`,
                selectedTimezone
              ),
              geofence_ids: selectedGeofences.map(geofence => geofence.key),
              routing_engine_settings: {
                ...(defaultVehicleRoutingEngine || {}),
                ...(originalVehicle.routing_engine || {}),
                ...(selectedVehicleModel?.routing_engine_settings || {}),
                vehicle_model: selectedVehicleModel?.id,
              },
              efficiency: {},
            };
            $D2.S.INFO('vehicle', {
              vehicle,
              defaultVehicleRoutingEngine,
              selectedVehicleModel,
            });
            const result = eventHandlers.onSubmit(vehicle);
            if (result instanceof Promise) {
              setIsSubmitting(true);
              result.then(
                ($result) => {
                  D2.S.INFO('onSumbit:Resolved', { $result });
                  setIsOpen(false);
                },
                ($error) => {
                  D2.S.INFO('onSumbit:Rejected', { $error });
                  global.openInfoMessage(
                    `Failed to add or modify a vehicle: ${$error.message}`,
                    {
                      title: 'Error',
                    }
                  );
                  setIsOpen(false);
                }
              );
            } else {
              setIsOpen(false);
            }
          } else {
            setIsOpen(false);
          }
        }
      );

    return (
      <React.Fragment>
        <Modal
          onClose={onClose}
          isOpen={isOpen}
          overrides={{
            Dialog: {
              style: {
                minWidth: '800px',
                maxWidth: '95vw',
                display: 'flex',
                flexDirection: 'column',
              },
            },
          }}
        >
          <ModalHeader>{windowTitle}</ModalHeader>
          {isDataValid && !isSubmitting ? (
            <ModalBody
              style={{ flex: '1 1 0' }}
              data-testid='VehicleEditor-ModalBody'
            >
              <div data-testid='VehicleEditor-FormControl-ServiceNumber'>
                <FormControl label={() => tt('Field.ServiceNumber')}>
                  <Input
                    value={rawVehicleServiceNumber}
                    onChange={e => updateVehicleServiceNumber(e.target.value)}
                    placeholder={tt('Field.ServiceNumber')}
                  />
                </FormControl>
              </div>
              <div data-testid='VehicleEditor-FormControl-StartTime'>
                <FormControl label={() => tt('Field.StartTime')}>
                  <TimePicker
                    data-testid='VehicleEditor-Input-StartTime'
                    placeholder={tt('Field.StartTime')}
                    value={startTime}
                    onChange={value => updateStartTime(value)}
                    maxTime={endTime}
                    step={300}
                  />
                </FormControl>
              </div>
              <div data-testid='VehicleEditor-FormControl-EndTime'>
                <FormControl label={() => tt('Field.EndTime')}>
                  <TimePicker
                    data-testid='VehicleEditor-Input-EndTime'
                    placeholder={tt('Field.EndTime')}
                    value={endTime}
                    onChange={value => updateEndTime(value)}
                    minTime={startTime}
                    step={300}
                  />
                </FormControl>
              </div>
              <div data-testid='VehicleEditor-FormControl-Geofences'>
                <FormControl label={() => tt('Field.Geofences')}>
                  <Select
                    data-testid='VehicleEditor-Input-Geofences'
                    options={availableGeofences}
                    labelKey='name'
                    valueKey='color'
                    placeholder={tt('Field.Geofences')}
                    maxDropdownHeight='300px'
                    type={TYPE.search}
                    multi
                    onChange={({ value }) => setSelectedGeofences(value)}
                    value={selectedGeofences}
                  />
                </FormControl>
              </div>
              <div data-testid='VehicleEditor-FormControl-Model'>
                <FormControl label={() => tt('Field.Model')}>
                  <Select
                    data-testid='VehicleEditor-Input-VehicleModel'
                    options={availableVehicleModels}
                    labelKey='id'
                    valueKey='value'
                    placeholder={tt('Field.Model')}
                    maxDropdownHeight='300px'
                    onChange={({ value }) => updateSelectedVehicleModels(value)}
                    multi={false}
                    value={selectedVehicleModels}
                  />
                </FormControl>
              </div>
            </ModalBody>
          ) : (
            <ModalBody>
              <ProgressBar
                getProgressLabel={() =>
                  isSubmitting
                    ? t('c.ProgressWindow.Saving')
                    : t('c.ProgressWindow.Loading')
                }
                showLabel
                infinite
              />
            </ModalBody>
          )}
          <ModalFooter data-testid='VehicleEditor-ModalFooter'>
            <ModalButton
              data-testid='VehicleEditor-Button-Save'
              disabled={disabled}
              onClick={onSumbit}
            >
              {tt('Button.Save')}
            </ModalButton>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  });

import withTranslation from 'utils/react/withTranslation';

import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { currentProjectConfigSelector } from 'modules/user/selectors';

import Header from './Header';

const mapStateToProps = createSelector(
  currentProjectConfigSelector,
  currentProjectConfig => ({
    currentProjectConfig,
  })
);

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation()(withConnect(Header));

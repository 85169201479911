import moment from 'moment-timezone';
import Immutable, { List, Map, fromJS } from 'immutable';
import layerPanelConfig from 'config/layerPanel';

import * as datasetsActions from 'modules/datasets/actions';
import * as geofencesActions from 'modules/geofences/actions';
import * as commuteOfferActions from 'modules/commuteOffer/actions';
import * as userActions from 'modules/user/actions';

import debug from 'utils/debug';
import cloneLocation from 'utils/location';
import reducers from './reducers';
import { map } from '../../config';
import * as actions from './actions';

const D2 = debug('m:ui:reducer');

D2.S.INFO('reducers', { reducers });

export const initialStateObject = {
  location: null,
  commuteOffer: {
    serviceDate: null,
    isBookingsHidden: true,
    activeVehicleIds: [],
    activeRouteStopUid: null,
    activeBookingId: null,
    editableBookingId: null,
    busStopsTypeVisible: [],
    editableVehicle: null,
    editableVehicleTime: false,
    searchQuery: '',
    bookingsFilter: window.GEODISC_DEBUG_DEFAULT_BOOKINGS_PANEL_FILTER || '',
    vehiclesFilter: window.GEODISC_DEBUG_DEFAULT_VEHICLES_PANEL_FILTER || '',
    routingEngine: window.GEODISC_ROUTING_ENGINE || 'euclidean',
    walkingRoutingEngine: window.GEODISC_WALKING_ROUTING_ENGINE || 'euclidean',
    draggablePoint: null,
    addPointMode: null,
    addStopMode: null,
    stopSearchQuery: '',
    isSaving: false,
    selected: [],
    fetchError: false,
    ordering: {
      id: 'name',
      sort: false, // true, false
    },
  },
  datasets: {
    ordering: {
      id: 'created_at',
      sort: false, // true, false
    },
    selectedIds: [],
    search: '',
    fetchError: false,
  },
  dataset: {
    editable: null,
    layerPanel: {
      // points, arcs, clusters, arcClusters, hexPickup, hexDropoff
      activeLayer: 'points',
      ...layerPanelConfig,
    },
    time: 0,
    allDay: false,
    running: false,
    fetchError: false,
    fetchCsvError: false,
  },
  geofences: {
    editable: null,
    hoverId: false,
    hidden: [],
    search: '',
    fetchError: false,
  },
  simulations: {
    ordering: {
      id: 'start_time',
      sort: false, // true, false
    },
    search: '',
    selected: [],
    compareMode: false,
    fetchError: false,
  },
  newDataset: {
    progress: 0,
    status: null,
    error: null,
  },
  importBookings: {
    progress: 0,
    status: null,
    error: null,
  },
  progressWindow: {
    progress: 0,
    status: null,
  },
  login: {
    waiting: false,
    error: null,
  },
  transitstopTypes: [
    'taxi_stand',
    'virtual_stop',
    'lrt_station',
    'bus_stop',
    'mrt_station',
    'building',
    'lobby',
    'lamp_post',
    'petrol_station',
    'other',
  ],
  visibleTransitstopTypes: [],
  visibleTransitstopSets: [],
  mapStyles: [
    {
      name: 'Route Editor (Dark)',
      mapStyle: map.mapStyle,
    },
    // {
    //   name: 'Route Editor (Japanese Dark)',
    //   mapStyle: 'mapbox://styles/njachowski/cks6znwnyekf817pld5bkevfd',
    // },
    {
      name: 'Morpheus (Light)',
      mapStyle: 'mapbox://styles/njachowski/ck7yfctgx0uer1ir03rqh3sgh',
    },
  ],
  activeMapStyle: map.mapStyle,
  modalWindow: null,
  modalNewUI: null,
};

export const initialState = Immutable.fromJS(D2.S.COPY(initialStateObject));

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case '@@router/LOCATION_CHANGE': {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, ({ $D2 }) => {
        return state.set('location', cloneLocation());
      });
    }
    case actions.BOOKINGS_FILTER_CHANGED: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const filter = payload;

      return state.setIn(['commuteOffer', 'bookingsFilter'], filter);
    }
    case actions.SET_SERVICE_DATE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { date } = payload;

      return state
        .setIn(['commuteOffer', 'activeVehicleIds'], Immutable.List())
        .setIn(['commuteOffer', 'activeRouteStopUid'], null)
        .setIn(['commuteOffer', 'draggablePoint'], null)
        .setIn(
          ['commuteOffer', 'serviceDate'],
          moment(date).format('YYYY-MM-DD')
        );
    }
    case commuteOfferActions.COMMUTE_OFFER_UNDO:
    case commuteOfferActions.COMMUTE_OFFER_REDO: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state
        .setIn(['commuteOffer', 'activeRouteStopUid'], null)
        .setIn(['commuteOffer', 'draggablePoint'], null);
    }
    case actions.MODAL_WINDOW_SET_PROGRESS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { progress, status } = payload;

      return state
        .setIn(['progressWindow', 'progress'], progress)
        .setIn(['progressWindow', 'status'], status);
    }
    case actions.SEARCH_QUERY_CHANGED: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['commuteOffer', 'searchQuery'], payload);
    }
    case commuteOfferActions.COMMUTE_OFFER_ADD_VEHICLE_SUCCESS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.set('modalWindow', null);
    }
    case commuteOfferActions.COMMUTE_OFFER_EDIT_VEHICLE_SUCCESS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.set('modalWindow', null);
    }
    case actions.SET_IS_SAVING: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['commuteOffer', 'isSaving'], payload);
    }
    case actions.SWITCH_MAPSTYLE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.set('activeMapStyle', payload);
    }
    case actions.TOGGLE_BOOKINGS_HIDDEN: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.updateIn(
        ['commuteOffer', 'isBookingsHidden'],
        isBookingsHidden => !isBookingsHidden
      );
    }
    case actions.SET_BOOKINGS_HIDDEN: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.updateIn(['commuteOffer', 'isBookingsHidden'], () => true);
    }
    case actions.SET_BOOKINGS_VISIBLE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.updateIn(['commuteOffer', 'isBookingsHidden'], () => false);
    }
    case actions.TOGGLE_EDITABLE_TIME_MODE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.updateIn(
        ['commuteOffer', 'editableVehicleTime'],
        editableVehicleTime => !editableVehicleTime
      );
    }
    case actions.SET_ADD_STOP_MODE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['commuteOffer', 'addStopMode'], payload);
    }
    case actions.CLEAN_ADD_STOP_MODE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['commuteOffer', 'addStopMode'], null);
    }
    case actions.SET_STOP_SEARCH_QUERY: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['commuteOffer', 'stopSearchQuery'], payload);
    }
    case actions.CLEAN_STOP_SEARCH_QUERY: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['commuteOffer', 'stopSearchQuery'], '');
    }
    case actions.SET_DRAGGABLE_POINT: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, () => {
        return state.updateIn(
          ['commuteOffer', 'draggablePoint'],
          draggablePoint =>
            draggablePoint ? { ...draggablePoint, ...payload } : payload
        );
      });
    }
    case actions.REMOVE_DRAGGABLE_POINT: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['commuteOffer', 'draggablePoint'], null);
    }
    case actions.SET_ACTIVE_VEHICLE_ID: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const id = payload;
      const index = state
        .getIn(['commuteOffer', 'activeVehicleIds'])
        .findIndex(item => item === id);

      if (index !== -1) {
        return state;
      }

      return state
        .updateIn(['commuteOffer', 'activeVehicleIds'], activeVehicleIds =>
          activeVehicleIds.push(id)
        )
        .setIn(['commuteOffer', 'activeRouteStopUid'], null)
        .setIn(['commuteOffer', 'draggablePoint'], null);
    }
    case actions.VEHICLES_LAYER_CLICK: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { properties = {} } = payload.feature;

      const { agent_id, assigned_vehicle_id, $isReadOnly = true } = properties;

      return state
        .setIn(
          ['commuteOffer', 'activeVehicleIds'],
          Immutable.List([assigned_vehicle_id || agent_id])
        )
        .setIn(['commuteOffer', 'activeRouteStopUid'], properties.uid)
        .setIn(
          ['commuteOffer', 'draggablePoint'],
          !$isReadOnly ? properties : null
        );
    }
    case actions.CLEAN_ACTIVE_VEHICLE_ID: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const id = payload;
      const index = state
        .getIn(['commuteOffer', 'activeVehicleIds'])
        .findIndex(item => item === id);

      return state
        .updateIn(['commuteOffer', 'activeVehicleIds'], activeVehicleIds =>
          activeVehicleIds.delete(index)
        )
        .setIn(['commuteOffer', 'addPointMode'], null);
    }
    case actions.CLEAN_ALL_ACTIVE_VEHICLE_IDS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['commuteOffer', 'activeVehicleIds'], List);
    }
    case actions.SET_ACTIVE_BOOKING_ID: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const id = payload;
      return state
        .setIn(['commuteOffer', 'activeBookingId'], id)
        .setIn(['commuteOffer', 'addPointMode'], null);
    }
    case actions.CLEAN_ACTIVE_BOOKING_ID: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state
        .setIn(['commuteOffer', 'activeBookingId'], null)
        .setIn(['commuteOffer', 'addPointMode'], null);
    }
    case actions.SET_EDITABLE_BOOKING_ID: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { id } = payload;
      return state.setIn(['commuteOffer', 'editableBookingId'], id);

      // return state.setIn(['commuteOffer', 'editableBookingId'], null);
    }
    case actions.CLEAN_EDITABLE_BOOKING_ID: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['commuteOffer', 'editableBookingId'], null);
    }
    case actions.SET_ACTIVE_ROUTE_STOP: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { uid } = payload;
      return state
        .setIn(['commuteOffer', 'activeRouteStopUid'], uid)
        .setIn(['commuteOffer', 'addPointMode'], null);
    }
    case actions.CLEAN_ACTIVE_ROUTE_STOP: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state
        .setIn(['commuteOffer', 'activeRouteStopUid'], null)
        .setIn(['commuteOffer', 'addPointMode'], null);
    }
    case actions.SET_EDITABLE_VEHICLE_ID: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const id = payload;
      return state.setIn(['commuteOffer', 'editableVehicleId'], id);
    }
    case actions.CLEAR_EDITABLE_VEHICLE_ID: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['commuteOffer', 'editableVehicleId'], null);
    }
    case actions.TOGGLE_ACTIVE_STOPS_TYPE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const id = payload;
      return state.updateIn(
        ['commuteOffer', 'busStopsTypeVisible'],
        (busStopsTypeVisible) => {
          const index = busStopsTypeVisible.findIndex(item => item === id);

          if (index !== -1) {
            return busStopsTypeVisible
              .slice(0, index)
              .concat(
                busStopsTypeVisible.slice(index + 1, busStopsTypeVisible.length)
              );
          }

          return [...busStopsTypeVisible, id];
        }
      );
    }
    case actions.CLEAR_ACTIVE_STOPS_TYPE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['commuteOffer', 'busStopsTypeVisible'], []);
    }
    case actions.TOGGLE_ROUTING_ENGINE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const id = payload;
      return state.setIn(['commuteOffer', 'routingEngine'], id);
    }
    case actions.TOGGLE_WALKING_ROUTING_ENGINE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const id = payload;
      global.GEODISC_WALKING_ROUTING_ENGINE = id;
      global.WALKING_ROUTES = {};
      global.WALKING_ROUTES_BY_IDS = {};
      return state.setIn(['commuteOffer', 'walkingRoutingEngine'], id);
    }
    case actions.SET_ADD_POINT_MODE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const id = payload;

      return state
        .updateIn(['commuteOffer', 'activeVehicleIds'], () =>
          Immutable.fromJS([id])
        )
        .setIn(['commuteOffer', 'activeRouteStopUid'], null)
        .setIn(['commuteOffer', 'activeBookingId'], null)
        .setIn(['commuteOffer', 'editableBookingId'], null)
        .setIn(['commuteOffer', 'addPointMode'], id);
    }
    case actions.CLEAN_ADD_POINT_MODE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['commuteOffer', 'addPointMode'], null);
    }
    case actions.CLEAN_COMMUTE_OFFER_UI: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.set('commuteOffer', initialState.get('commuteOffer'));
    }
    // datasets
    case actions.CHANGE_DATASETS_ORDERING: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const newId = payload;

      const id = state.getIn(['datasets', 'ordering', 'id']);

      if (id === newId) {
        return state.updateIn(
          ['datasets', 'ordering', 'sort'],
          sortOrdering => !sortOrdering
        );
      }

      return state.setIn(
        ['datasets', 'ordering'],
        Map({
          id: newId,
          sort: false,
        })
      );
    }
    case actions.CHANGE_DATASETS_SEARCH: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const text = payload;

      return state.setIn(['datasets', 'search'], text);
    }
    case datasetsActions.DATASETS_FETCH_FAILURE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['datasets', 'fetchError'], true);
    }
    case actions.TOGGLE_SELECTED_DATASET: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const id = payload;
      return state.updateIn(['datasets', 'selectedIds'], (selected) => {
        const index = selected.indexOf(id);
        if (index === -1) {
          return selected.push(id);
        }

        return selected.delete(index);
      });
    }
    case actions.CLEAR_SELECTED_DATASETS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['datasets', 'selectedIds'], List());
    }
    // dataset
    case actions.LAYER_PANEL_CHANGE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { paramId, value } = payload;
      const layerId = state.getIn(['dataset', 'layerPanel', 'activeLayer']);
      return state.setIn(
        ['dataset', 'layerPanel', layerId, paramId],
        fromJS(value)
      );
    }
    case actions.LAYER_TOGGLE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const layerId = payload;
      return state.setIn(['dataset', 'layerPanel', 'activeLayer'], layerId);
    }
    case actions.SET_EDITABLE_DATASET: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const dataset = payload;

      return state.setIn(['dataset', 'editable'], dataset);
    }
    case actions.UPDATE_EDITABLE_DATASET: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { property, value } = payload;

      return state.setIn(['dataset', 'editable', property], fromJS(value));
    }
    case actions.CHANGE_DATASET_TIME: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const time = payload;

      return state.setIn(['dataset', 'time'], parseInt(time, 10));
    }
    case datasetsActions.CLEAR_DATASET: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['dataset', 'time'], 0);
    }
    case datasetsActions.DATASET_FETCH_FAILURE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['dataset', 'fetchError'], true);
    }
    case datasetsActions.CSV_FETCH_FAILURE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['dataset', 'fetchCsvError'], true);
    }
    case actions.DATASET_TOGGLE_ALLDAY: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.updateIn(['dataset', 'allDay'], allDay => !allDay);
    }
    case actions.DATASET_PLAY: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['dataset', 'running'], true);
    }
    case actions.DATASET_STOP: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['dataset', 'running'], false);
    }
    case actions.DATASET_NEXT: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.updateIn(['dataset', 'time'], time =>
        time >= 23 ? 0 : time + 1
      );
    }
    // TransitstopTypes
    case actions.TOGGLE_VISIBILITY_TRANSITSTOPTYPE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const id = payload;

      return state.updateIn(
        ['visibleTransitstopTypes'],
        (visibleTransitstopTypes) => {
          const index = visibleTransitstopTypes.indexOf(id);

          if (index === -1) {
            return visibleTransitstopTypes.push(id);
          }

          return visibleTransitstopTypes.delete(index);
        }
      );
    }
    // TransitstopSets
    case actions.TOGGLE_VISIBILITY_TRANSITSTOPSET: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const id = payload;

      return state.updateIn(
        ['visibleTransitstopSets'],
        (visibleTransitstopSets) => {
          const index = visibleTransitstopSets.indexOf(id);

          if (index === -1) {
            return visibleTransitstopSets.push(id);
          }

          return visibleTransitstopSets.delete(index);
        }
      );
    }
    // geofences
    case actions.TOGGLE_VISIBILITY_GEOFENCE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const id = payload;

      return state.updateIn(['geofences', 'hidden'], (visibleGeofences) => {
        const index = visibleGeofences.indexOf(id);

        if (index === -1) {
          return visibleGeofences.push(id);
        }

        return visibleGeofences.delete(index);
      });
    }
    case actions.SET_EDITABLE_GEOFENCE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const geofence = payload;

      return state.setIn(['geofences', 'editable'], geofence);
    }
    case actions.UPDATE_EDITABLE_GEOFENCE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { property, value } = payload;

      return state.setIn(['geofences', 'editable', property], fromJS(value));
    }
    case actions.CHANGE_GEOFENCES_SEARCH: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const text = payload;

      return state.setIn(['geofences', 'search'], text);
    }
    case actions.CHANGE_GEOFENCES_HOVER: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const id = payload;

      return state.setIn(['geofences', 'hoverId'], id);
    }
    case geofencesActions.GEOFENCES_FETCH_FAILURE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['geofences', 'fetchError'], true);
    }
    // simulations
    case actions.CHANGE_SIMULATIONS_ORDERING: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const newId = payload;

      const id = state.getIn(['simulations', 'ordering', 'id']);

      if (id === newId) {
        return state.updateIn(
          ['simulations', 'ordering', 'sort'],
          sortOrdering => !sortOrdering
        );
      }

      return state.setIn(
        ['simulations', 'ordering'],
        Map({
          id: newId,
          sort: false,
        })
      );
    }
    case actions.CHANGE_SIMULATIONS_SEARCH: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const text = payload;

      return state.setIn(['simulations', 'search'], text);
    }
    case actions.TOGGLE_SELECTED_SIMULATION: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const simulation = payload;
      return state.updateIn(['simulations', 'selected'], (selected) => {
        const index = selected.indexOf(simulation);
        if (index === -1) {
          return selected.push(simulation);
        }

        return selected.delete(index);
      });
    }
    case actions.CLEAR_SELECTED_SIMULATIONS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['simulations', 'selected'], List());
    }
    case actions.TOGGLE_COMPARE_MOD_SIMULATIONS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.updateIn(
        ['simulations', 'compareMode'],
        compareMode => !compareMode
      );
    }
    // modalWindow
    case actions.OPEN_MODAL_WINDOW: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state
        .set('modalWindow', payload)
        .setIn(['newDataset', 'error'], null)
        .setIn(['newDataset', 'status'], null)
        .setIn(['newDataset', 'progress'], 0)
        .setIn(['importBookings', 'error'], null)
        .setIn(['importBookings', 'status'], null)
        .setIn(['importBookings', 'progress'], 0)
        .setIn(['progressWindow', 'status'], 'Preparing')
        .setIn(['progressWindow', 'progress'], 1);
    }
    case actions.CLOSE_MODAL_WINDOW: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state
        .set('modalWindow', null)
        .setIn(['newDataset', 'error'], null)
        .setIn(['newDataset', 'status'], null)
        .setIn(['newDataset', 'progress'], 0)
        .setIn(['importBookings', 'error'], null)
        .setIn(['importBookings', 'status'], null)
        .setIn(['importBookings', 'progress'], 0)
        .setIn(['progressWindow', 'status'], null)
        .setIn(['progressWindow', 'progress'], 0);
    }
    // modalNewUI
    case actions.OPEN_NEW_MODAL_WINDOW: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);
      return state
        .set('modalNewUI', payload)
        .setIn(['newDataset', 'error'], null)
        .setIn(['newDataset', 'status'], null)
        .setIn(['newDataset', 'progress'], 0)
        .setIn(['importBookings', 'error'], null)
        .setIn(['importBookings', 'status'], null)
        .setIn(['importBookings', 'progress'], 0)
        .setIn(['progressWindow', 'status'], 'Preparing')
        .setIn(['progressWindow', 'progress'], 1);
    }
    case actions.CLOSE_NEW_MODAL_WINDOW: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state
        .set('modalNewUI', null)
        .setIn(['newDataset', 'error'], null)
        .setIn(['newDataset', 'status'], null)
        .setIn(['newDataset', 'progress'], 0)
        .setIn(['importBookings', 'error'], null)
        .setIn(['importBookings', 'status'], null)
        .setIn(['importBookings', 'progress'], 0)
        .setIn(['progressWindow', 'status'], null)
        .setIn(['progressWindow', 'progress'], 0);
    }
    // newDataset
    case datasetsActions.UPLOAD_DATASET_PROGRESS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const progress = payload;
      return state.setIn(['newDataset', 'progress'], progress);
    }
    case datasetsActions.UPLOAD_DATASET_REQUEST: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['newDataset', 'progress'], 0.01);
    }
    case datasetsActions.UPLOAD_DATASET_SUCCESS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['newDataset', 'status'], 'success');
    }
    case datasetsActions.UPLOAD_DATASET_FAILURE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const error = payload;
      return state
        .setIn(['newDataset', 'error'], error.message)
        .setIn(['newDataset', 'status'], null)
        .setIn(['newDataset', 'progress'], 0);
    }
    // Login
    case userActions.AUTHORIZATION_REQUEST: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state
        .setIn(['login', 'error'], null)
        .setIn(['login', 'waiting'], true);
    }
    case userActions.AUTHORIZATION_SUCCESS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['login', 'waiting'], false);
    }
    case userActions.AUTHORIZATION_FAILURE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const error = payload;
      return state
        .setIn(['login', 'waiting'], false)
        .setIn(['login', 'error'], error.message);
    }
    case commuteOfferActions.COMMUTE_OFFER_IMPORT_BOOKINGS_REQUEST: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state
        .setIn(['importBookings', 'error'], null)
        .setIn(['importBookings', 'status'], 'Processing')
        .setIn(['importBookings', 'progress'], 0.01);
    }
    case commuteOfferActions.COMMUTE_OFFER_IMPORT_BOOKINGS_PROGRESS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { status, progress } = payload;

      return state
        .setIn(['importBookings', 'error'], null)
        .setIn(['importBookings', 'status'], status)
        .setIn(['importBookings', 'progress'], progress);
    }
    case commuteOfferActions.COMMUTE_OFFER_IMPORT_BOOKINGS_SUCCESS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state
        .setIn(['importBookings', 'error'], null)
        .setIn(['importBookings', 'status'], null)
        .setIn(['importBookings', 'progress'], 0)
        .set('modalWindow', null);
    }
    case commuteOfferActions.SET_RESULT_OFFER_SUCCESS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case commuteOfferActions.COMMUTE_OFFER_IMPORT_BOOKINGS_FAILURE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const error = payload;

      return state
        .setIn(['importBookings', 'error'], error.message)
        .setIn(['importBookings', 'status'], null)
        .setIn(['importBookings', 'progress'], 0);
    }
    case commuteOfferActions.COMMUTE_OFFER_FETCH_REQUEST: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, ({ $D2 }) => {
        const { isInitRequired = true } = payload;
        $D2.S.INFO('isInitRequired', { isInitRequired });

        if (!isInitRequired) {
          return state;
        }

        $D2.S.INFO('Init');

        return state
          .set('commuteOffer', initialState.get('commuteOffer'))
          .set(
            'visibleTransitstopTypes',
            initialState.get('visibleTransitstopTypes')
          )
          .set(
            'visibleTransitstopSets',
            initialState.get('visibleTransitstopSets')
          )
          .set('activeMapStyle', initialState.get('activeMapStyle'))
          .set('modalWindow', null);
      });
    }
    default: {
      const handler = reducers[type];
      if (handler) {
        return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, ({ $D2 }) =>
          handler(state, payload, { type, D2, $D2 })
        );
      }
      return state;
    }
  }
};

export default reducer;

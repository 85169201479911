import React from 'utils/react';
import Background from './Background';
import Container from './Container';
import Title from './Title';
import Close from './Close';

class Modal extends React.PureComponent {
  onClickBackground = (e) => {
    const { isCloseDisabled } = this.props;
    if (isCloseDisabled) {
      e.stopPropagation();
    } else {
      this.props.onClose();
    }
  };

  onStopPropagation = (e) => {
    e.stopPropagation();
  };

  render() {
    const { children, title, isCloseDisabled } = this.props;

    return (
      <Background onClick={this.onClickBackground}>
        <Container onClick={this.onStopPropagation}>
          <Title>{title}</Title>
          {!isCloseDisabled && <Close onClick={this.props.onClose} />}
          {children}
        </Container>
      </Background>
    );
  }
}

export default Modal;

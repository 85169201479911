import styled from 'styled-components';

const NodeMarker = styled.div`
  width: ${({ passenger }) => (passenger < 0 ? '12px' : '10px')};
  height: ${({ passenger }) => (passenger < 0 ? '12px' : '10px')};
  border-radius: ${({ passenger }) => (passenger < 0 ? '2px' : '10px')};
  background: ${({ color }) => color || '#000'};
  border: 1px solid white;
  transform: rotate(45deg);
`;

export default NodeMarker;

import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { currentUserConfigSelector } from 'modules/user/selectors';
import { routePageAddressSelector } from 'modules/router/selectors';
import { commuteOfferRequestUpdate } from 'modules/commuteOffer/actions';

import SideNavBar from './SideNavBar';

const mapStateToProps = createSelector(
  currentUserConfigSelector,
  routePageAddressSelector,
  (currentUserConfig, pageAddress) => ({
    currentUserConfig,
    pageAddress,
  })
);

const mapDispatchToProps = {
  commuteOfferRequestUpdate,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(SideNavBar);

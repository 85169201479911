import React from 'utils/react';
import Websocket from 'react-websocket';

import debug from 'utils/debug';

const D2 = debug('m:pages:Simulation:EventListener');

class EventListener extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: props.url,
      websocketConnected: props.websocketConnected,
      websocketDisconnected: props.websocketDisconnected,
      simulationEvent: props.simulationEvent,
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleData = this.handleData.bind(this);
    this.sendMessage = this.handleData.bind(this);
  }

  componentDidMount() {
    const { url } = this.props;
    D2.S.DEBUG('ADDR:', url);
  }

  handleOpen() {
    D2.S.DEBUG('WSCONNECT');
    if (this.state.websocketConnected) {
      this.state.websocketConnected();
    }
  }

  handleClose() {
    D2.S.DEBUG('WSDISCONNECT');
    if (this.state.websocketDisconnected) {
      this.state.websocketDisconnected();
    }
  }

  handleData(data) {
    const result = JSON.parse(data);
    D2.S.DEBUG('RECV:', result);
    this.refWebSocket.sendMessage('{}');
    if (this.state.simulationEvent) {
      this.state.simulationD2.S.EVENT({
        kind: 'simulation_event',
        data: result,
      });
    }
  }

  sendMessage(message) {
    D2.S.DEBUG('SEND:', message);
    this.refWebSocket.sendMessage(message);
  }

  render() {
    const { url } = this.state;
    return (
      <Websocket
        url={url}
        onMessage={this.handleData}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        reconnect={true} // eslint-disable-line
        debug={true} // eslint-disable-line
        ref={(websocket) => {
          this.refWebSocket = websocket;
        }}
      />
    );
  }
}

export default React.memo(EventListener);

import React from 'utils/react';
import Object$fromEntries from 'fromentries';

import { Redirect } from 'react-router';

const parseLocationArgs = str =>
  str
    ? Object$fromEntries(
        str
          .substring(1)
          .split('&')
          .map(v => v.split('='))
      )
    : {};

export default ({ defaultPage }) => {
  const { next } = parseLocationArgs(window.location.search);

  return <Redirect to={next ? decodeURIComponent(next) : defaultPage} />;
};

import React from 'utils/react';
import useTranslation from 'utils/react/useTranslation';

import CSV from 'comma-separated-values';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { exportFile } from 'utils/file';

import debug from 'utils/debug';
import Table from './Table';

const D2 = debug('c:ModalWindows:TableViewer');

export default (props) => {
  D2.S.INFO('TableViewer', props);

  const { t } = useTranslation();
  const tt = (msg, ...opts) => t(`c.ModalWindows.TableViewer.${msg}`, ...opts);

  const [isOpen, setIsOpen] = React.useState(false);
  const [headers, setHeaders] = React.useState([]);
  const [content, setContent] = React.useState([]);
  const [title, setTitle] = React.useState(t('c.messages.Info'));
  const [fileName, setFileName] = React.useState();
  const [config, setConfig] = React.useState({});

  React.useEffect(() => {
    D2.S.INFO('Update', { props });

    window.openTableViewer = ($headers, $content, opts) => {
      D2.S.INFO('openTableViewer', { $headers, $content, opts });

      const options = opts || {};

      // const $title = options.title || tt('Title.Default');
      const $title = tt('Title.Default');

      const $fileName = options.fileName;

      if (!$headers || !$content) {
        global.openInfoMessage(tt('Error.NothingToShow'), { title: $title });
        return;
      }

      if (Array.isArray($content) && $content.length === 0) {
        global.openInfoMessage(tt('Error.NothingToShow'), { title: $title });
      }

      setTitle($title);
      setFileName($fileName);
      setHeaders($headers);
      setContent($content);
      setConfig(options);
      setIsOpen(true);
    };

    window.closeTableViewer = () => {
      D2.S.INFO('closeTableViewer', { isOpen, config });
      setIsOpen(false);
    };

    return () => {
      D2.S.INFO('Cleanup', { isOpen, config });
      window.closeTableViewer = null;
    };
  });

  function onClose() {
    D2.S.INFO('onClose', { isOpen, config });

    setIsOpen(false);
  }

  function onExport() {
    D2.S.INFO('onExport', { isOpen, config });

    const csvHeader = [headers.map(column => column.name)];

    const csvData = [...csvHeader, ...content];
    D2.S.INFO('onExport:csvData', { csvData });

    const csv = new CSV(csvData);
    const csvBody = csv.encode();

    // global.openSourceEditor(csvBody, { title: 'Source' });
    exportFile(csvBody, fileName);
  }

  return (
    <React.Fragment>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        overrides={{
          Dialog: {
            style: {
              width: '90vw',
              height: '90vh',
              display: 'flex',
              flexDirection: 'column',
            },
          },
        }}
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <Table headers={headers} content={content} />
        </ModalBody>
        <ModalFooter>
          {fileName && (
            <ModalButton onClick={onExport}>{tt('Footer.Export')}</ModalButton>
          )}
          <ModalButton onClick={onClose}>{tt('Footer.Close')}</ModalButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

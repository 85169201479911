import React from 'utils/react';

import { useStyletron } from 'baseui';
import { StyledTable, StyledHeadCell, StyledBodyCell } from 'baseui/table-grid';

import debug from 'utils/debug';

const D2 = debug('c:ModalWindows:PassengersTableViewer');

export default function (props) {
  D2.S.INFO('Table', { props });

  const [css] = useStyletron();

  const { content } = props;

  D2.S.INFO('Content', { content });

  const $gridTemplateColumns = content.columns
    .map(col => col.width || 'max-content')
    .join(' ');
  return (
    <div className={css({ height: 'calc(90vh - 210px)', width: 'auto' })}>
      {content.columns.length && (
        <StyledTable $gridTemplateColumns={$gridTemplateColumns}>
          {content.columns.map((col, colIndex) => (
            <StyledHeadCell key={`head-cell-${colIndex}`}>
              {col.label || `Column ${colIndex}`}
            </StyledHeadCell>
          ))}
          {content.rows.map((row, rowIndex) => {
            return (
              <React.Fragment key={`row-${rowIndex}`}>
                {row.map((value, colIndex) => (
                  <StyledBodyCell
                    $striped={rowIndex % 2 === 0}
                    key={`body-cell-${colIndex}-${rowIndex}`}
                  >
                    {value}
                  </StyledBodyCell>
                ))}
              </React.Fragment>
            );
          })}
        </StyledTable>
      )}
    </div>
  );
}

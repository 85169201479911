import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { fetchGeofences } from 'modules/geofences/actions';
import { geofencesFilteredSelector } from 'modules/geofences/selectors';
import { currentProjectIdSelector } from 'modules/user/selectors';

import Geofences from './Geofences';

const mapStateToProps = createImmutableSelector(
  geofencesFilteredSelector,
  currentProjectIdSelector,
  (geofences, projectId) => ({ geofences, projectId })
);

const mapDispatchToProps = { fetchGeofences };

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Geofences);

import styled from 'styled-components';

export default styled.div`
  position: relative;

  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
`;

// @flow
import withTranslation from 'utils/react/withTranslation';

import createImmutableSelector from 'utils/immutable-selector';
import { connect } from 'react-redux';
import {
  vehiclesFilteredBySearchSelector,
  bookingsFilteredBySearchSelector,
} from 'modules/commuteOffer/selectors';

import Filter from './Filter';

const mapStateToProps = createImmutableSelector(
  vehiclesFilteredBySearchSelector,
  bookingsFilteredBySearchSelector,
  (vehicles, bookings) => ({ vehicles, bookings })
);
const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation()(withConnect(Filter));

import { connect } from 'react-redux';

import {
  removeBookingFromRoute,
  removeBookingNode,
} from 'modules/commuteOffer/actions';
import { cleanEditableBookingId } from 'modules/ui/actions';

import BookingStopsEditor from './BookingStopsEditor';

const mapStateToProps = null;

const mapDispatchToProps = {
  removeBookingFromRoute,
  cleanEditableBookingId,
  removeBookingNode,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(BookingStopsEditor);

import { put, take, select } from 'redux-saga/effects';
import { nearestFeature } from 'utils/nearest-feature';
import { point } from '@turf/helpers';
import { sleep } from 'utils/async';

import debug from 'utils/debug';

import * as actions from 'modules/commuteOffer/actions';

import {
  commuteOfferCurrentDataSelector,
  allVehiclesSelector,
  filteredVehiclesSelector,
} from 'modules/commuteOffer/selectors';
import { detailedRouteSourceSelector } from 'modules/maps/selectors';
import {
  addPointModeSelector,
  // routingEngineSelector,
} from 'modules/ui/selectors';

// import { fetchAllRoutesHandler } from './fetchAllRoutes';

const D2 = debug('m:CommuteOffer:saga:addPointToRoute');

const isVehicleReadOnly = (currentOffer, vehicleId) =>
  D2.S.FUNCTION('isVehicleReadOnly', { currentOffer, vehicleId }, ({ $D2 }) => {
    const vehicle = currentOffer.stateless_api_request_data.vehicles.find(
      x => x.agent_id === vehicleId
    );
    $D2.S.INFO('vehicle', { vehicle, vehicleId });
    return (
      !vehicle || vehicle.readOnly || vehicle.readonly || vehicle.isReadOnly
    );
  });

// eslint-disable-next-line import/prefer-default-export
export function* addPointToRouteHandler({ payload }) {
  D2.S.INFO('Handler:Request', payload);

  try {
    const { lon, lat } = payload;
    const currentPoint = point([lon, lat]);

    const currentOffer = yield select(commuteOfferCurrentDataSelector);

    const vehicleId = yield select(addPointModeSelector);
    D2.S.INFO('Handler:vehicleId', vehicleId);

    if (isVehicleReadOnly(currentOffer, vehicleId)) {
      // global.openInfoMessage('Vehicle is read-only', { title: 'Error' });
      return;
    }

    const vehicles = yield select(filteredVehiclesSelector);
    const vehicle = vehicles.find(item => item.agent_id === vehicleId);
    D2.S.INFO('Handler:vehicle', { vehicle });

    const allSegments = yield select(detailedRouteSourceSelector);
    D2.S.INFO('Handler:allSegments', { allSegments });

    const vehicleSegments = {
      type: 'FeatureCollection',
      features: allSegments.features.filter(route =>
        D2.S.V(
          'Handler:allSegments.features:route',
          { route },
          route.properties.agent_id === vehicleId
        )
      ),
    };
    D2.S.INFO('Handler:vehicleSegments', vehicleSegments);

    if (vehicleSegments.features.length >= 2) {
      const segment = nearestFeature(currentPoint, vehicleSegments);
      D2.S.INFO('Handler:segment', segment);

      const { position } = segment.properties;

      D2.S.INFO('Handler:Success', {
        lon,
        lat,
        vehicleId,
        segment,
        position: position + 1,
      });
      yield put(
        actions.setPointToRoute(
          lon,
          lat,
          vehicleId,
          vehicle.route,
          position + 1
        )
      );
    } else {
      D2.S.INFO('Handler:Success', {
        lon,
        lat,
        vehicleId,
        position: vehicleSegments.features.length,
      });
      yield put(
        actions.setPointToRoute(
          lon,
          lat,
          vehicleId,
          vehicle.route,
          vehicleSegments.features.length
        )
      );
    }

    // const routingEngine = yield select(routingEngineSelector);
    // D2.S.INFO('fetchAllRoutes:routingEngine', { routingEngine });

    // yield put(actions.fetchRoute(vehicle, routingEngine));

    const newVehicles = yield select(allVehiclesSelector);
    const newVehicle = newVehicles.find(item => item.agent_id === vehicleId);

    yield put(actions.fetchRoute(newVehicle));
    yield take(actions.ROUTE_FETCH_RESULTS);

    yield put(actions.recalculateVehicleTime([vehicleId], 'set'));

    // yield fetchAllRoutesHandler();

    yield sleep(1000);
  } catch (error) {
    D2.S.INFO('Handler:Failure', { error });
  }
}

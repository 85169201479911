import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { toggleVisibilityGeofence } from 'modules/ui/actions';
import { visibleGeofencesSelector } from 'modules/ui/selectors';
import {
  currentProjectLayoutNameSelector,
  isDeliveryLayoutSelector,
} from 'modules/user/selectors';

import { geofencesActiveSelector } from 'modules/geofences/selectors';

import GeofencesMenu from './GeofencesMenu';

const mapStateToProps = createSelector(
  geofencesActiveSelector,
  visibleGeofencesSelector,
  currentProjectLayoutNameSelector,
  isDeliveryLayoutSelector,
  (geofences, visibleGeofences, layoutName, isDeliveryLayout) => ({
    geofences,
    visibleGeofences,
    layoutName,
    isDeliveryLayout,
  })
);

const mapDispatchToProps = {
  toggleVisibilityGeofence,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(GeofencesMenu);

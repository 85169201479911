import styled from 'styled-components';

export default styled.div`
  position: fixed;
  display: flex;
  top: 8px;
  left: 108px;
  bottom: 58px;
  z-index: 3;
  pointer-events: none;
`;

import styled from 'styled-components';

export default styled.div`
  display: flex;
  position: relative;
  margin-top: 10px;
  background: ${({ isActive }) => (isActive ? '#3b4251' : 'inherit')};
  padding: 5px;
  border-radius: 10px;
`;

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import {
  commuteOfferOriginalDataSelector,
  commuteOfferCurrentDataSelector,
} from 'modules/commuteOffer/selectors';

import CommuteOfferVehicleUpdates from './CommuteOfferVehicleUpdates';

const mapDispatchToProps = null;

const mapStateToProps = createImmutableSelector(
  commuteOfferOriginalDataSelector,
  commuteOfferCurrentDataSelector,
  (originalOffer, currentOffer) => ({ originalOffer, currentOffer })
);

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(CommuteOfferVehicleUpdates);

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { fetchSimulations } from 'modules/simulations/actions';
import { listSelector } from 'modules/simulations/selectors';
import { routePageSelector } from 'modules/router/selectors';
import {
  currentProjectIdSelector,
  currentProjectLayoutNameSelector,
} from 'modules/user/selectors';

import Simulations from './Simulations';

const mapStateToProps = createImmutableSelector(
  listSelector,
  routePageSelector,
  currentProjectIdSelector,
  currentProjectLayoutNameSelector,
  (list, page, projectId, layoutName) => ({
    list,
    page,
    projectId,
    layoutName,
  })
);

const mapDispatchToProps = { fetchSimulations };

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Simulations);

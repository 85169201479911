import styled from 'styled-components';

export default styled.div`
  position: absolute;
  right: 5px;
  top: 15px;
  width: 20px;
  height: 20px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 8px;
    width: 20px;
    height: 2px;
    background: #fffdf6;
  }
`;

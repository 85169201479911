import styled from 'styled-components';

export default styled.a`
  text-decoration: none;
  border-radius: 4px;
  padding: 12px 20px;
  background-color: #1235b2;
  cursor: pointer;
  color: white;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  width: 100%;

  &:hover {
    box-shadow: 0px 12px 40px rgba(47, 148, 255, 0.2);
  }

  ${({ isDisable }) =>
    isDisable &&
    `
    pointer-events: none;
    background-color: #424963;
    color: #97A0C0;
  `}
`;

import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  width: 100%;
  padding-left: 15px;
  margin-bottom: 10px;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0.5)};
  line-height: 25px;
`;

export default Container;

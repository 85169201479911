import styled from 'styled-components';

const DepartureTime = styled.div`
  position: absolute;
  top: 25px;
  right: 7px;
  color: #edece8;
  font-size: 12px;
`;

DepartureTime.displayName = 'DepartureTime';

export default DepartureTime;

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  position: absolute;
  font-size: 12px;
  width: 50%;
  height: 25px;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
`;

export default Container;

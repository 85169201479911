import React from 'utils/react';

import { useStyletron } from 'baseui';
import { Unstable_StatefulDataTable } from 'baseui/data-table';
import debug from 'utils/debug';

const D2 = debug('c:ModalWindows:DataTableViewer');

export default function (props) {
  D2.S.INFO('Table', { props });

  const [css] = useStyletron();

  const { columns, rows } = props;

  D2.S.INFO('Content', { columns, rows });

  return (
    <div className={css({ height: 'calc(90vh - 210px)', width: 'auto' })}>
      {columns.length && (
        // eslint-disable-next-line
        <Unstable_StatefulDataTable
          columns={columns}
          rows={rows}
          emptyMessage='Nothing to show'
        />
      )}
    </div>
  );
}

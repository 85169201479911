import React from 'react';
import { styled } from 'baseui';
import OrderDetails from 'pages/Logistics/OrderDetails';

const Wrapper = styled('div', ({ $isExpanded }) => {
  return {
    height: 'auto',
    display: $isExpanded ? 'visible' : 'none',
    padding: 0,
  };
});

const BookingDetails = (props) => {
  const { t, booking } = props;
  const { $routeStops, $routeStopId } = booking;
  const stop = $routeStops?.find((stopData) => {
    return stopData.id === $routeStopId;
  });

  const formatTime = (time, dateFormat) => {
    if (time && time !== '--:--') {
      return moment(time)
        .tz(global.GEODISC_TIMEZONE)
        .format(dateFormat || 'YYYY-MM-DD HH:mm');
    }
    return '--:--';
  };

  const parseTimeWindow = (minTime, maxTime) => {
    const format = 'HH:mm';
    if (maxTime === minTime) {
      return formatTime(maxTime, format);
    }
    return `${formatTime(minTime, format)} - ${formatTime(maxTime, format)}`;
  };

  const customerPickupName = [
    booking?.data?.pickup_customer_name,
    booking?.data?.pickup_customer_name2,
  ]
    .filter(text => !!text)
    .join(' ');

  const customerDropoffName = [
    booking?.data?.dropoff_customer_name,
    booking?.data?.dropoff_customer_name2,
  ]
    .filter(text => !!text)
    .join(' ');

  return (
    <Wrapper $isExpanded={props.expanded}>
      <OrderDetails
        style={{
          padding: '40px 0 0 0',
          margin: 0,
          background: 'none',
          color: '#fff',
        }}
        orderDetails={booking}
      />
    </Wrapper>
  );
};

export default BookingDetails;

import React from 'react';
import upArrow from 'assets/upArrow.svg';
import downArrow from 'assets/downArrow.svg';

const PickupDropoffCard = (props) => {
  const icon = props.isPickup ? upArrow : downArrow;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'baseline',
        marginTop: '4px',
        marginBottom: '4px',
      }}
    >
      <img src={icon} alt='icon' style={{ marginRight: '10px' }} />
      {props.content}
    </div>
  );
};

export default PickupDropoffCard;

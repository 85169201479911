import React from 'utils/react';
import PropTypes from 'prop-types';

import Container from './Container';
import Logo from '../Logo';
import Navigation from './Navigation';
import ProjectMenu from './ProjectMenu';
import UserMenu from './UserMenu';
import Separator from './Separator';

const Header = (props) => {
  if (window.GEODISC_UI_HEADER_DISABLE) {
    return null;
  }

  const {
    isLogo,
    hasBackground,
    isShadow,
    isProjectSelectionDisabled,
    projectLabelTarget,
    hideNav,
  } = props;

  return (
    <Container hasBackground={hasBackground} isShadow={isShadow}>
      {isLogo && <Logo />}
      <UserMenu />
      {hideNav ? (
        <div style={{ flex: 1 }} />
      ) : (
        <React.Fragment>
          <Navigation />
          <Separator />
        </React.Fragment>
      )}
      <ProjectMenu
        isProjectSelectionDisabled={isProjectSelectionDisabled}
        projectLabelTarget={projectLabelTarget}
      />
    </Container>
  );
};

Header.propTypes = {
  isLogo: PropTypes.bool,
  hasBackground: PropTypes.bool,
  isShadow: PropTypes.bool,
  isProjectSelectionDisabled: PropTypes.bool,
  projectLabelTarget: PropTypes.string,
  hideNav: PropTypes.bool,
};

Header.defaultProps = {
  isLogo: true,
  hasBackground: false,
  isShadow: false,
  isProjectSelectionDisabled: false,
  projectLabelTarget: '/',
  hideNav: false,
};

export default React.memo(Header);

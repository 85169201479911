import React from 'react';
import { useStyletron, styled } from 'baseui';

const PaginationButton = styled('div', ({ $theme, $backgroundColor }) => {
  return {
    margin: '5px 2px',
    width: '28px',
    height: '28px',
    borderRadius: '50px',
    textAlign: 'center',
    padding: '7px 0',
    fontSize: '12px',
    fontFamily: 'Montserrat',
    background: $backgroundColor || '#fff',
    cursor: 'pointer',
  };
});

const Pagination = ({ pageNumber, pageSize, totalRecords, setPageNumber }) => {
  const totalPages = Math.ceil(totalRecords / pageSize);
  const numberOfPageButtonsToShow = totalPages <= 5 ? totalPages - 1 : 5;
  const clickPage = ({ page }) => {
    setPageNumber(page);
  };

  const nextPage = () => {
    const nextValue = pageNumber + 1;
    setPageNumber(nextValue);
  };

  const previousPage = () => {
    const nextValue = pageNumber - 1;
    setPageNumber(nextValue < 1 ? 1 : nextValue);
  };

  const [css, theme] = useStyletron();

  const pageArray = [...Array(numberOfPageButtonsToShow)];
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'row',
      })}
    >
      {1 !== totalPages && (
        <>
          {1 !== totalPages && pageNumber !== 1 && (
            <PaginationButton $backgroundColor='none' onClick={previousPage}>
              {'<'}
            </PaginationButton>
          )}
          {pageArray.map((data, index) => {
            const buttonValue =
              pageNumber + numberOfPageButtonsToShow > totalPages
                ? totalPages + (index - numberOfPageButtonsToShow)
                : pageNumber + index;

            return (
              <PaginationButton
                onClick={() => clickPage({ page: buttonValue })}
                $backgroundColor={
                  pageNumber === buttonValue
                    ? theme?.colors?.buttonBorder
                    : theme?.colors?.menuBackground
                }
                key={index}
              >
                {buttonValue}
              </PaginationButton>
            );
          })}

          <PaginationButton $backgroundColor={theme?.colors?.menuBackground}>
            ...
          </PaginationButton>
          <PaginationButton
            $backgroundColor={
              pageNumber === totalPages
                ? theme?.colors?.buttonBorder
                : theme?.colors?.menuBackground
            }
            onClick={() => clickPage({ page: totalPages })}
          >
            {totalPages}
          </PaginationButton>
          {pageNumber !== totalPages && (
            <PaginationButton $backgroundColor='none' onClick={nextPage}>
              {' '}
              {'>'}
            </PaginationButton>
          )}
        </>
      )}
    </div>
  );
};

export default Pagination;

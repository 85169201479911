import React from 'utils/react';
import Container from './Container';
import Button from './Button';

const Settings = ({ speed, setSpeed }) => (
  <Container>
    <Button isActive={speed === 1} onClick={() => setSpeed(1)}>
      1x
    </Button>
    <Button isActive={speed === 3} onClick={() => setSpeed(3)}>
      3x
    </Button>
    <Button isActive={speed === 5} onClick={() => setSpeed(5)}>
      5x
    </Button>
  </Container>
);

export default Settings;

import React from 'utils/react';
import debug from 'utils/debug';

import Row from './Row';

const D2 = debug('c:ModalWindow:TableView:Window');

class ModalWindow extends React.PureComponent {
  state = {};

  render() {
    D2.S.INFO('render', { props: this.props });

    return (
      <React.Fragment>
        <Row />
      </React.Fragment>
    );
  }
}

export default ModalWindow;

import React from 'utils/react';
import { connect } from 'react-redux';
import withTranslation from 'utils/react/withTranslation';
import createImmutableSelector from 'utils/immutable-selector';

import { addBookingToRoute } from 'modules/commuteOffer/actions';
import {
  commuteOfferIsReadOnlySelector,
  commuteOfferLoadedSimulationSelector,
} from 'modules/commuteOffer/selectors';
import {
  flyToCommuteOfferActiveStop,
  flyToCommuteOfferActiveBooking,
} from 'modules/maps/actions';
import { currentProjectLayoutNameSelector } from 'modules/user/selectors';
import { activeBookingIdSelector } from 'modules/ui/selectors';

import {
  setActiveVehicleId,
  setActiveRouteStop,
  setActiveBookingId,
  setEditableBookingId,
  cleanActiveBookingId,
  changeBookingsFilter,
} from 'modules/ui/actions';

import BookingV2 from './BookingV2';
import DraggableBooking from './DraggableBooking';

const mapStateToProps = createImmutableSelector(
  activeBookingIdSelector,
  commuteOfferIsReadOnlySelector,
  currentProjectLayoutNameSelector,
  commuteOfferLoadedSimulationSelector,
  (activeBookingId, isReadOnly, layoutName, simulation) => ({
    activeBookingId,
    isReadOnly,
    layoutName,
    simulation,
  })
);

const mapDispatchToProps = {
  addBookingToRoute,
  setActiveVehicleId,
  setActiveRouteStop,
  setActiveBookingId,
  setEditableBookingId,
  flyToCommuteOfferActiveStop,
  flyToCommuteOfferActiveBooking,
  cleanActiveBookingId,
  changeBookingsFilter,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const Component = props =>
  props.isReadOnly ? <BookingV2 {...props} /> : <DraggableBooking {...props} />;

export default withConnect(withTranslation()(Component));

import debug from 'utils/debug';

const D2 = debug('m:CommuteOffer:RenameVehicles');

// eslint-disable-next-line import/prefer-default-export
export const commuteOffer$RenameVehicles = (commuteOffer, newIDs) => {
  D2.S.INFO('Request', {
    commuteOffer,
    newIDs,
  });

  const newVehicleIDs = newIDs instanceof Map ? newIDs : new Map(newIDs);

  const resultOffer = {
    ...commuteOffer,
    stateless_api_request_data: {
      ...commuteOffer.stateless_api_request_data,
      vehicles: commuteOffer.stateless_api_request_data.vehicles.map(
        vehicle => ({
          ...vehicle,
          agent_id: newVehicleIDs.get(vehicle.agent_id),
          vehicle_color: undefined,
        })
      ),
    },
    result: {
      ...commuteOffer.result,
      vehicles: Object.keys(commuteOffer.result.vehicles).reduce(
        (memo, uid) => ({
          ...memo,
          [newVehicleIDs.get(uid)]: commuteOffer.result.vehicles[uid],
        }),
        {}
      ),
      assigned_bookings: commuteOffer.result.assigned_bookings.map(
        booking => ({
          ...booking,
          assigned_vehicle_id: newVehicleIDs.get(booking.assigned_vehicle_id),
        })
      ),
    },
  };

  D2.S.INFO('Success', {
    commuteOffer,
    newVehicleIDs,
    resultOffer,
  });
  return resultOffer;
};

import withTranslation from 'utils/react/withTranslation';

import { connect } from 'react-redux';
import { ActionCreators } from 'redux-undo';
import createImmutableSelector from 'utils/immutable-selector';
import {
  serialNumberSelector,
  filteredVehiclesSelector,
  commuteOfferIsReadOnlySelector,
  commuteOfferLoadedSimulationSelector,
  commuteOfferIsFilteringEnabledSelector,
  filteredAssignedVehicles,
  filteredUnassignedVehicles,
  filteredBookingsSelector,
  resolvedAddressSelector,
} from 'modules/commuteOffer/selectors';
import {
  isDeliveryLayoutSelector,
  currentProjectConfigSelector,
  canManageVehiclesSelector,
} from 'modules/user/selectors';
import {
  commuteOffersIsServiceDataSourceSelector,
  activeBookingIdSelector,
} from 'modules/ui/selectors';
import {
  newVehicle,
  commuteOfferRequestUpdate,
} from 'modules/commuteOffer/actions';
import {
  openPopup,
  toggleBookingsHidden,
  changeVehiclesFilter,
  cleanActiveBookingId,
} from 'modules/ui/actions';

import Vehicles from './Vehicles';

const mapStateToProps = createImmutableSelector(
  serialNumberSelector,
  filteredVehiclesSelector,
  commuteOfferIsReadOnlySelector,
  isDeliveryLayoutSelector,
  commuteOfferLoadedSimulationSelector,
  currentProjectConfigSelector,
  canManageVehiclesSelector,
  commuteOffersIsServiceDataSourceSelector,
  commuteOfferIsFilteringEnabledSelector,
  filteredAssignedVehicles,
  filteredUnassignedVehicles,
  filteredBookingsSelector,
  activeBookingIdSelector,
  resolvedAddressSelector,
  (
    serialNumber,
    vehicles,
    commuteOfferIsReadOnly,
    isDeliveryLayout,
    simulation,
    projectConfig,
    canManageVehicles,
    isServiceDataSource,
    isFilteringEnabled,
    assignedVehicles,
    unassignedVehicles,
    bookings,
    activeBookingId,
    resolvedAddress
  ) => ({
    serialNumber,
    vehicles,
    commuteOfferIsReadOnly,
    isDeliveryLayout,
    simulation,
    projectConfig,
    canManageVehicles,
    isServiceDataSource,
    isFilteringEnabled,
    assignedVehicles,
    unassignedVehicles,
    bookings,
    activeBookingId,
    resolvedAddress,
  })
);

const mapDispatchToProps = {
  newVehicle,
  openPopup,
  commuteOfferRequestUpdate,
  back: ActionCreators.undo,
  next: ActionCreators.redo,
  toggleBookingsHidden,
  changeVehiclesFilter,
  cleanActiveBookingId,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation()(withConnect(Vehicles));

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import {
  changeDatasetTime,
  datasetPlay,
  datasetStop,
  datasetToggleAllDay,
} from 'modules/ui/actions';

import {
  datasetTimeSelector,
  csvErrorSelector,
  datasetRunningSelector,
  datasetAlldaySelector,
} from 'modules/ui/selectors';

import {
  hasTimeDatasetSelector,
  dataLoadingSelector,
} from 'modules/datasets/selectors';

import TimeSlider from 'components/TimeSlider';

const mapStateToProps = createImmutableSelector(
  dataLoadingSelector,
  datasetTimeSelector,
  csvErrorSelector,
  hasTimeDatasetSelector,
  datasetRunningSelector,
  datasetAlldaySelector,
  (loading, time, error, hasTime, isRunning, isAllDay) => ({
    loading,
    time,
    error,
    hasTime,
    isRunning,
    type: 'dataset',
    isAllDay,
  })
);

const mapDispatchToProps = {
  onChange: changeDatasetTime,
  onPlay: datasetPlay,
  onStop: datasetStop,
  toggleAllday: datasetToggleAllDay,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(TimeSlider);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { useTranslation } from 'react-i18next';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useStyletron, styled } from 'baseui';
import { toaster } from 'baseui/toast';

import {
  currentProjectSelector,
  projectsSelector,
  driverProjectsSelector,
} from 'modules/user/selectors';
import { changeCurrentProject } from 'modules/user/actions';
import { routePageAddressSelector } from 'modules/router/selectors';
import { bookingReadySelector } from 'modules/commuteOffer/selectors';
import { LOGISTICS_ROUTE, GEODISC_PREV_PROJECT_KEY } from 'utils/constants';

import arrow from 'assets/arrow.svg';
import check from 'assets/check.svg';

const FILTERS_FIELD_SHOW_LIMIT = 7;

const Container = styled('div', () => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  maxWidth: 240,
}));

const Handle = styled('div', () => ({
  padding: '14px 20px 14px 14px',
  cursor: 'pointer',
  backgroundImage: `url(${arrow})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right center',
  overflow: 'auto',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

const Dropdown = styled('div', ({ color }) => ({
  position: 'absolute',
  right: 0,
  background: `${color || '#1C232D'}`,
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
  borderRadius: 4,
  bottom: '50px',
  maxHeight: 'min(28em, 80vh)',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  width: '240px',
  overflow: 'hidden',
}));

const DropdownScrollable = styled('div', () => ({
  overflowY: 'auto',
  '::-webkit-scrollbar': {
    width: '8px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: 'transparent',
    border: '1px solid #97a0c040',
    borderRadius: '20px',
  },
  ':hover::-webkit-scrollbar-thumb': {
    borderColor: '#97a0c0',
    backgroundColor: '#97a0c0',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  ':hover::-webkit-scrollbar-track': {
    backgroundColor: '#97a0c040',
  },
}));

const DropdownItem = styled('div', ({ selected, onClick }) => ({
  padding: `16px ${selected ? 48 : 20}px 16px 20px`,
  backgroundImage: `${selected ? `url(${check})` : ''}`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center right 20px',
  cursor: `${onClick ? 'pointer' : ''}`,
  ':hover': {
    backgroundColor: `${onClick ? '#00000040' : ''}`,
  },
  wordBreak: 'break-all',
  overflowWrap: 'anywhere',
}));

const FilterField = styled('input', () => ({
  width: '100%',
  border: 'none',
  outline: 'none',
  backgroundColor: '#080d14',
  borderRadius: 4,
  appearance: 'none',
  padding: '12px 8px',
  margin: 0,
  color: '#fff',
  fontSize: 14,
}));

const ProjectsMenu = ({
  projects,
  driverProjects,
  currentProject,
  changeProject,
  logisticPageSelector,
  finishOfferLoading,
}) => {
  const { t } = useTranslation();
  const [, theme] = useStyletron();
  const colors = theme?.colors;

  const filterFieldRef = React.useRef(null);
  const [filterText, setFilterText] = React.useState('');
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const selectedDropdownItemRef = React.useRef();

  const clickOutsideRef = useOnclickOutside(() => {
    setOpenDropdown(false);
  });

  useEffect(() => {
    if (openDropdown) {
      if (selectedDropdownItemRef.current) {
        selectedDropdownItemRef.current.scrollIntoView({
          block: 'center',
        });
      }
      if (filterFieldRef.current) {
        filterFieldRef.current.focus();
        filterFieldRef.current.select();
      }
    }
  }, [openDropdown]);

  const currentProjectName = currentProject
    ? currentProject.get('name')
    : '...';

  useEffect(() => {
    if (
      finishOfferLoading &&
      !!sessionStorage.getItem(GEODISC_PREV_PROJECT_KEY)
    ) {
      toaster.info(
        <>{t('toaster.ProjectChanged', { projectName: currentProjectName })}</>,
        {
          autoHideDuration: 4000,
          closeable: false,
        }
      );
      sessionStorage.removeItem(GEODISC_PREV_PROJECT_KEY);
    }
  }, [finishOfferLoading]);

  useEffect(() => {
    if (filterText) {
      if (selectedDropdownItemRef.current) {
        selectedDropdownItemRef.current.scrollTo(0, 0);
      }
    }
  }, [filterText]);

  const projectsWithName = React.useMemo(() => {
    return projects.filter((project) => {
      return (
        !!project &&
        project.get &&
        project.get('name') &&
        !driverProjects.includes(project.get('resource_uri'))
      );
    });
  }, [projects]);
  const filteredProjects = React.useMemo(() => {
    return projectsWithName.filter((project) => {
      if (!filterText) {
        return true;
      }
      return project
        .get('name')
        .toLowerCase()
        .includes(filterText.toLowerCase());
    });
  }, [projectsWithName, filterText]);

  return (
    <Container ref={clickOutsideRef} data-testid='ProjectsMenu-Container'>
      <Handle onClick={() => setOpenDropdown(!openDropdown)}>
        {currentProjectName}
      </Handle>
      {openDropdown && (
        <Dropdown color={colors?.menuBackground}>
          <DropdownScrollable>
            {!filteredProjects.size && (
              <DropdownItem style={{ textAlign: 'center' }}>
                {t('projectsmenu.noResults')}
              </DropdownItem>
            )}
            {filteredProjects.map((project) => {
              return (
                <DropdownItem
                  key={project.get('id')}
                  selected={project.get('id') === currentProject.get('id')}
                  ref={
                    project.get('id') === currentProject.get('id')
                      ? selectedDropdownItemRef
                      : null
                  }
                  onClick={() => {
                    const nextPage = logisticPageSelector({
                      page: LOGISTICS_ROUTE,
                      projectId: project.get('id'),
                    });
                    changeProject(project, currentProject, nextPage);
                    sessionStorage.setItem(GEODISC_PREV_PROJECT_KEY, true);
                    setOpenDropdown(false);
                    setFilterText('');
                  }}
                >
                  {project.get('name')}
                </DropdownItem>
              );
            })}
          </DropdownScrollable>
          {projectsWithName.size > FILTERS_FIELD_SHOW_LIMIT && (
            <div style={{ padding: 8, borderTop: '1px solid #080D14' }}>
              <FilterField
                ref={filterFieldRef}
                value={filterText}
                onChange={(e) => {
                  setFilterText(e.target.value);
                }}
              />
            </div>
          )}
        </Dropdown>
      )}
    </Container>
  );
};

const mapStateToProps = createSelector(
  projectsSelector,
  driverProjectsSelector,
  currentProjectSelector,
  routePageAddressSelector,
  bookingReadySelector,
  (
    projects,
    driverProjects,
    currentProject,
    logisticPageSelector,
    finishOfferLoading
  ) => ({
    projects,
    driverProjects: driverProjects?.toJS ? driverProjects.toJS() : [],
    currentProject,
    logisticPageSelector,
    finishOfferLoading,
  })
);

const mapDispatchToProps = {
  changeProject: changeCurrentProject,
};

const connectProjects = connect(mapStateToProps, mapDispatchToProps);

export default connectProjects(ProjectsMenu);

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { datasetDataSelector } from 'modules/datasets/selectors';

import {
  fetchDataset,
  fetchDatasetCsv,
  clearDataset,
} from 'modules/datasets/actions';
import { routeIdSelector } from 'modules/router/selectors';

import Dataset from './Dataset';

const mapStateToProps = createImmutableSelector(
  datasetDataSelector,
  routeIdSelector,
  (data, id) => ({ data, id })
);

const mapDispatchToProps = {
  fetchDataset,
  fetchDatasetCsv,
  clearDataset,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Dataset);

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 37px;
`;

export default Container;

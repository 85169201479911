import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { stopsSelector } from 'modules/entities/selectors';

import {
  simulationSelector,
  simulationDataSelector,
  simulationsWsTokenSelector,
} from 'modules/simulations/selectors';

import {
  fetchSimulationDataStart,
  fetchSimulation,
  clearSimulation,
  websocketConnected,
  websocketDisconnected,
  simulationEvent,
} from 'modules/simulations/actions';

import { routeIdSelector } from 'modules/router/selectors';
import { fetchStops } from 'modules/entities/actions';

import Simulation from './Simulation';

const mapStateToProps = createImmutableSelector(
  stopsSelector,
  routeIdSelector,
  simulationSelector,
  simulationDataSelector,
  simulationsWsTokenSelector,
  (stops, id, simulation, data, wsToken) => ({
    stops,
    id,
    simulation,
    data,
    wsToken,
  })
);

const mapDispatchToProps = {
  fetchStops,
  fetchSimulation,
  fetchSimulationDataStart,
  clearSimulation,
  websocketConnected,
  websocketDisconnected,
  simulationEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Simulation);

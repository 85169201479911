import styled from 'styled-components';

export default styled.div`
  font-weight: 600;
  margin-right: 10px;
  font-size: 12px;

  cursor: pointer;

  :hover {
    opacity: 0.5;
  }
`;

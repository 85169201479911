import debug from 'utils/debug';
import { getDefaultrPageSize } from './defaults';

const D2 = debug('utils:sgerp-api');

const fetchCollectionPage = async (
  api,
  collection,
  offset = 0,
  limit = getDefaultrPageSize(),
  filter = {}
) =>
  D2.A.FUNCTION(
    'fetchCollectionPage',
    { api, collection, offset, limit, filter },
    async () => {
      try {
        const { fetch, address, prefix } = api;

        const query = {
          ...filter,
          offset,
          limit,
        };

        const queryString = Object.entries(query)
          .reduce((memo, [k, v]) => {
            return [...memo, [k, encodeURIComponent(v)].join('=')];
          }, [])
          .join('&');

        const objectAddress = [[address, prefix].join(''), collection].join(
          '/'
        );

        const fetchUrl =
          queryString !== ''
            ? [objectAddress, queryString].join('?')
            : objectAddress;

        // console.log('=== GET', fetchUrl);
        const response = await fetch(fetchUrl);

        if (!response.ok) {
          const { status } = response;
          return { error: { status, response } };
        }

        const json = await response.json();

        const { meta, objects } = json;

        return { meta, objects };
      } catch (exception) {
        if (global.GEODISC_DEBUG_ENABLED) {
          // eslint-disable-next-line
          console.log(exception);
        }
        return { error: { exception } };
      }
    }
  );

export default fetchCollectionPage;

import { Map } from 'immutable';

export function unique(values) {
  const results = [];
  values.forEach((v) => {
    if (!results.includes(v) && v !== null && v !== undefined) {
      results.push(v);
    }
  });

  return results;
}

export const cutIntoHours = async (json) => {
  const pickup = await json.reduce((acc, value) => {
    const datePickup = new Date(value.pickup_time);
    const hourPickup = datePickup.getHours();

    if (acc.get(hourPickup)) {
      return acc.update(hourPickup, (data) => {
        data.pickup.push(value);
        return data;
      });
    }

    return acc.set(hourPickup, { pickup: [value], dropoff: [] });
  }, Map());

  const result = await json.reduce((acc, value) => {
    const dateDropoff = new Date(value.dropoff_time);
    const hourDropoff = dateDropoff.getHours();

    if (acc.get(hourDropoff)) {
      return acc.update(hourDropoff, (data) => {
        data.dropoff.push(value);
        return data;
      });
    }

    return acc.set(hourDropoff, { pickup: [], dropoff: [value] });
  }, pickup);

  return result;
};

export const getGeoJson = (data, getPosition) => {
  if (!data) {
    return null;
  }

  return data.map(value => ({
    type: 'Feature',
    properties: {
      ...value,
    },
    geometry: {
      coordinates: getPosition(value),
    },
  }));
};

import React from 'utils/react';

import { useTranslation } from 'react-i18next';

import untranslatable from './untranslatable';

const withTranslationWrapper = () => {
  return (Component) => {
    return ({ children, ...otherProps }) => {
      const { t, i18n } = useTranslation();
      return (
        <Component t={t} i18n={i18n} d={untranslatable} {...otherProps}>
          {children}
        </Component>
      );
    };
  };
};

export default withTranslationWrapper;

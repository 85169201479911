import React from 'utils/react';
import withTranslation from 'utils/react/withTranslation';
import moment from 'moment-timezone';
import debug from 'utils/debug';
import withDragDropContext from '../../../withDragDropContext';

import Container from './Container';
import { Stop, DraggableStop } from './Stop';

const D2 = debug('p:CommuteOffer:Panels:Vehicles:Vehicle:Route');

class Route extends React.PureComponent {
  moveCard = (dragIndex, hoverIndex) => {
    const { route, vehicleId } = this.props;
    this.props.changeRouteOrder(dragIndex, hoverIndex, route, vehicleId);
  };

  render() {
    return D2.S.FUNCTION('render', { props: this.props }, ({ $D2 }) => {
      const {
        t,
        bookings,
        isReadOnly,
        vehicleId,
        route,
        color,
        activeRouteStopUid,
        setActiveRouteStop,
        cleanActiveRouteStop,
        removeBookingFromRoute,
        flyToCommuteOfferActiveStop,
        isHaveEditable,
        togglePointEditing,
        deleteRoutePoint,
        pointEditing,
        activeVehicleIds,
        commuteOfferIsReadOnly,
      } = this.props;

      if (!route.length) {
        return (
          <Container>
            {t(
              'p.CommuteOffer.Panels.Vehicles.Vehicle.Route.NoAssignedBookings'
            )}
          </Container>
        );
      }

      return (
        <Container>
          {route.map((stop, i) =>
            $D2.S.FUNCTION('route.map', { stop }, () => {
              const isActive = stop.uid === activeRouteStopUid;

              const nodeWithInvalidScheduledTime = stop.bookings.find(
                (booking) => {
                  const { open_time_ts, close_time_ts, scheduled_ts } =
                    booking.$node || booking.node;

                  const open_time_mts = moment(open_time_ts);
                  const close_time_mts = moment(close_time_ts);
                  const scheduled_mts = moment(scheduled_ts);

                  return (
                    scheduled_mts.isBefore(open_time_mts) ||
                    scheduled_mts.isAfter(close_time_mts)
                  );
                }
              );

              const hasNodesWithInvalidScheduledTime =
                !!nodeWithInvalidScheduledTime;

              const isCompletedStop = stop.status === 'completed';

              const onClick = () =>
                D2.S.FUNCTION('onClick', { stop, isActive }, () => {
                  if (isActive) {
                    cleanActiveRouteStop();
                  } else {
                    setActiveRouteStop(stop.uid);
                    flyToCommuteOfferActiveStop(stop.uid);
                  }
                });

              const StopComponent =
                commuteOfferIsReadOnly || isCompletedStop
                  ? Stop
                  : DraggableStop;

              return (
                <StopComponent
                  isActive={isActive}
                  stop={stop}
                  bookings={bookings}
                  index={i}
                  id={i}
                  key={i}
                  color={color}
                  hasNodesWithInvalidScheduledTime={
                    hasNodesWithInvalidScheduledTime
                  }
                  isReadOnly={isReadOnly}
                  vehicleId={vehicleId}
                  removeBookingFromRoute={removeBookingFromRoute}
                  isHaveEditable={isHaveEditable}
                  onClick={onClick}
                  moveCard={this.moveCard}
                  togglePointEditing={togglePointEditing}
                  pointEditing={pointEditing}
                  deleteRoutePoint={deleteRoutePoint}
                  activeVehicleIds={activeVehicleIds}
                  cleanActiveRouteStop={cleanActiveRouteStop}
                />
              );
            })
          )}
        </Container>
      );
    });
  }
}

export default withDragDropContext(withTranslation()(Route));

import styled from 'styled-components';

const Container = styled.button`
  line-height: 44px;
  text-align: center;
  cursor: pointer;
  border-radius: 50px;
  background-color: #f9603a;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  padding: 0 20px;
  margin: auto;
  border: solid 1px transparent;

  &:hover {
    background-color: rgba(249, 96, 58, 0.74);
  }

  &[type='submit'] {
    -webkit-appearance: none;
  }
`;

export default Container;

const regexValidation = {
  positiveFloat: /(^[1-9]\d*(\.\d+)?$)|(^0\.\d*[1-9]$)/,
  positiveInteger: /^[1-9]\d*$/,
  decimalNumberRegex: /^\d+(\.\d+)?$/,
  latitudeRegex:
    /^(\+|-)?(?:90(?:(?:\.0{1,20})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,20})?))$/,
  longitudeRegex:
    /^(\+|-)?(?:180(?:(?:\.0{1,20})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,20})?))$/,
  notZero: /^(?!0$).*/,
  isNumberArray: /^(\[)([0-9\,]*)(\])$/,
  isNestedStringArray:
    /\[\]|\[\[\]\]|^\[(\[("[\w]*")\])\]$|^\[(\[("[\w]*",){0,}("[\w]*")\],*){0,}(\[("[\w]*",){0,}("[\w]*")\])\]$/,
  hasSpecialCharacters: /[^\w\s]/,
};
export default regexValidation;

import MapGL, { Marker } from '@urbica/react-map-gl';
import { styled, useStyletron } from 'baseui';
import { map as mapConfig } from 'config';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Delete } from 'baseui/icon';
import { useTranslation } from 'react-i18next';
import Verification from '../Verification';
import Open from 'assets/open.svg';
import pin from 'assets/pin-stop.svg';
import DataTable from 'components/DataTable';
import { locationUrl, parseLocationArgs } from 'utils/args';
import moment from 'moment-timezone';
import { debounce } from 'debounce';
import {
  getAutocompleteResults,
  getGeocodingResult,
  reverseGeocodeAddress,
} from 'api/simulations';
import { useHistory, useLocation } from 'react-router-dom';
import { SelectedAddressContext } from 'pages/Logistics/OrderSelectedDataContextProvider';

const HeaderWrapper = styled('div', {
  paddingTop: '24px',
  paddingBottom: '24px',
  display: 'flex',
  justifyContent: 'space-between',
});

const Container = styled('div', ({ $theme }) => {
  return {
    position: 'fixed',
    top: '8px',
    left: '8px',
    bottom: '8px',
    zIndex: 1,
    display: 'flex',
    pointerEvents: 'none',
  };
});

const Panel = styled('div', ({ hidden }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
    backgroundColor: '#080d14bf',
    backgroundImage:
      'linear-gradient(180deg, #0c0f14 40px, rgba(0, 0, 0, 0) 70px), linear-gradient(0deg, #0c0f14 40px, rgba(0, 0, 0, 0) 70px)',
    backdropFilter: 'blur(8px)',
    width: '300px',
    color: '#fff',
    transition: hidden ? 'opacity 0.3s ease-out' : 'opacity 0.5s ease-out',
    opacity: hidden ? 0 : 1,
    pointerEvents: hidden ? 'none' : 'auto',
    paddingLeft: '24px',
    paddingRight: '24px',
  };
});

const Footer = styled('div', {
  padding: '10px 0',
  display: 'flex',
  alignItems: 'center',
});

const Button = styled('button', ({ disabled }) => ({
  background: disabled ? '#424963' : '#1235B2',
  borderRadius: '4px',
  fontSize: '14px',
  color: disabled ? '#97a0c0' : '#fff',
  border: 0,
  margin: 0,
  padding: '12px',
  appearance: 'none',
  fontFamily: 'inherit',
  width: '100%',
  cursor: disabled ? '' : 'pointer',
}));

const Form = styled('div', () => {
  return {
    position: 'relative',
    width: '100%',
    display: 'inline-block',
    marginRight: '10px',
    marginBottom: '10px',
    marginTop: '10px',
    background:
      'linear-gradient(180deg, #0C0F14 84.78%, rgba(12, 15, 20, 0) 100%)',
  };
});

const Input = styled('input', ({ $theme }) => {
  const colors = $theme?.colors;
  return {
    display: 'inline-block',
    width: '100%',
    padding: '8px 16px 8px 16px',
    border: 'none',
    borderRadius: '4px',
    fontSize: '14px',
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontStyle: 'normal',
    color: colors.inputBorder,
    backgroundColor: colors.menuBackground,
    lineHeight: '140%',
  };
});

const Edit = ({
  viewport,
  onViewportChange,
  currentProject,
  selectedRow,
  number,
  contactsOfAddress,
  confirmMatchedAddress,
  currentOffer,
}) => {
  const mapRef = useRef();
  const hasUnsavedChangesRef = useRef();
  const [cursorStyle, setCursorStyle] = useState(null);
  const [css, theme] = useStyletron();
  const { t, i18n } = useTranslation();
  const [position, setPosition] = useState({
    longitude: null,
    latitude: null,
  });

  useEffect(() => {
    if (position.latitude !== null && position.longitude !== null) {
      onViewportChange({
        ...position,
        pitch: 0,
        zoom: 13,
      });
    }
  }, [position]);

  const [searchKeyword, setSearchKeyword] = useState('');
  const [autocompleteList, setAutocompleteList] = useState([]);
  const [addressesData, setAddressesData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState('');

  const projectSettings = currentProject.get('data').toJS();

  const { language } = i18n;

  useEffect(() => {
    hasUnsavedChangesRef.current = hasUnsavedChanges;
  }, [hasUnsavedChanges]);

  const searchQuery = parseLocationArgs(locationUrl().query);
  let simulationDate = moment().format('DD MMM YYYY');
  if (searchQuery.get('simulation-date')) {
    simulationDate = moment(searchQuery.get('simulation-date')).format(
      'DD MMM YYYY'
    );
  }

  const history = useHistory();

  const { setSelectedData } = useContext(SelectedAddressContext);

  useEffect(() => {
    changeEditBrowserHistory();
    if (selectedRow !== undefined) {
      setSelectedData({
        selectedRow: selectedRow,
        numberOfRows: number,
      });
    }
    let backListener = history.listen((location, action) => {
      if (action === 'POP') {
        const params = new URLSearchParams(window.location.search);
        if (params.get('page') === 'verification') {
          // when user click browser back
          goBack();
        }
      }
    });
    return () => {
      backListener();
    };
  }, []);

  const onMapClick = async (event) => {
    setPosition({ longitude: event.lngLat.lng, latitude: event.lngLat.lat });
    try {
      const response = await reverseGeocodeAddress(
        [{ lat: event.lngLat.lat, lon: event.lngLat.lng }],
        'GoogleV3',
        language,
        projectSettings?.logistics_settings?.country
      );

      setSearchKeyword(response);
      setSelectedAddress(response);
      setAutocompleteList([]);
      // setSelectedLocation(jsonResponse?.result?.[searchTerm]);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  };

  const handleAutocompleteResponses = async (searchTerm) => {
    try {
      const response = await getAutocompleteResults(
        searchTerm,
        'GoogleV3',
        language,
        projectSettings?.logistics_settings?.country
      );
      setAutocompleteList(response);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce(async (searchTerm) => {
      if (searchTerm) {
        handleAutocompleteResponses(searchTerm);
      }
    }, 1000), // 1000ms debounce time
    []
  );

  const onChangeSearchKeyword = (e) => {
    setSearchKeyword(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleSelectAddress = async (address) => {
    try {
      const { latitude, longitude } = await getGeocodingResult(
        address,
        'GoogleV3',
        language,
        projectSettings?.logistics_settings?.country
      );

      setPosition({
        longitude: longitude,
        latitude: latitude,
      });
      setSearchKeyword(address);
      setSelectedAddress(address);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  };

  const columnName = [
    {
      name: t('c.Geocoding.editor.address.table.orderId'),
      key: 'orderID',
      width: '25%',
    },
    {
      name: t('c.Geocoding.editor.address.table.name'),
      key: 'name',
      width: '25%',
    },
    {
      name: t('c.Geocoding.editor.address.table.number'),
      key: 'number',
      width: '50%',
    },
  ];

  useEffect(() => {
    if (position?.latitude || position.longitude) {
      setHasUnsavedChanges(true);
    }
  }, [position]);

  const changeEditBrowserHistory = () => {
    const params = new URLSearchParams(window.location.search);
    params.set('page', 'edit1');
    window.history.replaceState(
      {},
      null,
      `${window.location.pathname}?${params}`
    );
  };

  const goBack = () => {
    global.closeTableViewerV2();
    if (hasUnsavedChangesRef.current) {
      global.openWarningMessage({
        title: t('c.Geocoding.editor.address.warning.title'),
        message: t('c.Geocoding.editor.address.warning.message'),
        buttons: [
          {
            text: t('c.messages.Cancel'),
            fill: false,
            action: () => {
              history.goForward();
              global.closeWarningMessage();
            },
          },
          {
            text: t('c.Geocoding.editor.address.discard'),
            action: () => {
              global.closeWarningMessage();
              global.closeFullScreen();
              global.openFullScreen({
                modalContent: <Verification />,
              });
            },
            fill: true,
          },
        ],
      });
    } else {
      global.closeWarningMessage();
      global.closeFullScreen();
      global.openFullScreen({
        modalContent: <Verification />,
      });
    }
  };

  const goBackBrowserHistory = () => {
    // click on cross
    // make cross and browser back to be the same behavior
    history.goBack();
  };

  const replaceBrowserHistoryToVerification = () => {
    const params = new URLSearchParams(window.location.search);
    params.set('page', 'edit');
    window.history.replaceState(
      {},
      null,
      `${window.location.pathname}?${params}`
    );
    params.delete('page');
    history.push(`${window.location.pathname}?${params}&page=verification`);
  };

  return (
    <>
      <Container>
        <Panel>
          <HeaderWrapper>
            <h1
              className={css({
                ...theme.typography.panelSmall600,
              })}
            >
              {t('c.Geocoding.editor.title')}
            </h1>
            <Delete
              size={30}
              className={css({ marginTop: '5px' })}
              onClick={goBackBrowserHistory}
            />
          </HeaderWrapper>
          <div
            className={css({
              borderStyle: 'solid',
              borderWidth: '1px',
              padding: '16px',
              borderColor: theme.colors.accent,
              borderRadius: '4px',
              backgroundColor: 'rgba(47, 148, 255, 0.05)',
            })}
          >
            <p
              className={css({
                margin: 0,
                ...theme.typography.panelTitle500,
              })}
            >
              {`${t('c.Geocoding.editor.info.text')} `}
              {/* SP-4791: bold the address */}
              <span
                className={css({
                  ...theme.typography.panelTitle700,
                })}
              >
                {selectedRow?.enteredAddress}
              </span>
            </p>
            <div
              className={css({
                ...theme.typography.panelTitle600,
                color: theme.colors.accent,
                marginTop: '12px',
                flexDirection: 'row',
                display: 'flex',
              })}
              onClick={() => {
                global.openTableViewerV2({
                  title: t('c.Geocoding.editor.address.table.title', {
                    number: contactsOfAddress.length,
                  }),
                  message: (
                    <>
                      <p>
                        {/* SP-4791: bold and italic the address */}
                        {t('c.Geocoding.editor.address.table.subtitle', {
                          address: '',
                          date: simulationDate,
                        })}
                        <span
                          className={css({
                            ...theme.typography.panelTitle700,
                            ...theme.typography.panelItalic,
                          })}
                        >
                          {selectedRow?.enteredAddress}
                        </span>
                      </p>
                      <DataTable
                        columnName={columnName}
                        menuItems={[]}
                        menuActions={() => {}}
                        data={contactsOfAddress}
                        checkbox={false}
                        // SP-4791: remove the square icon at the end of the modal
                        successMessage={undefined}
                        isReadOnly={true}
                        pageSize={50}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        totalRecords={addressesData?.length}
                        isFixedHeader={true}
                      />
                    </>
                  ),
                  buttons: [],
                });
              }}
            >
              <p
                className={css({
                  display: 'block',
                  marginBottom: 0,
                  marginTop: 0,
                })}
              >
                {`${t('c.Geocoding.editor.info.orderNumber', {
                  number: number,
                })} `}
              </p>
              <img src={Open} className={css({ marginLeft: '6px' })} />
            </div>
          </div>
          <p
            className={css({
              ...theme.typography.panelTitle500,
              marginTop: '20px',
              paddingTop: 0,
            })}
          >
            {t('c.Geocoding.editor.newAddresses')}
          </p>
          <Form>
            <Input
              name='searchKeyword'
              type='text'
              value={searchKeyword}
              onChange={onChangeSearchKeyword}
              defaultValue={
                window.GEODISC_DEBUG_DEFAULT_VEHICLES_PANEL_FILTER || ''
              }
              placeholder={t('c.Geocoding.editor.address.placeholder')}
            />
          </Form>
          {autocompleteList?.length > 0 && (
            <div
              className={css({
                backgroundColor: theme.colors.menuBackground,
                paddingTop: '4px',
                paddingBottom: '4px',
                paddingLeft: '20px',
                paddingRight: '20px',
                borderRadius: '4px',
              })}
            >
              {autocompleteList.map(({ address }, key) => (
                <p
                  key={key}
                  onClick={() => {
                    handleSelectAddress(address);
                    setAutocompleteList([]);
                  }}
                  className={css({
                    ...theme.typography.panelTitle500,
                    paddingTop: '16px',
                    paddingBottom: '16px',
                    margin: 0,
                  })}
                >
                  {address}
                </p>
              ))}
            </div>
          )}
          <Footer>
            <Button
              type='button'
              disabled={!hasUnsavedChanges}
              onClick={(e) => {
                // const newCommuteOffer = { ...currentOffer };
                const newCommuteOffer = JSON.parse(
                  JSON.stringify(currentOffer)
                );

                selectedRow?.AddressTypesList?.map((data) => {
                  const bookings = {
                    ...newCommuteOffer.stateless_api_request_data.bookings,
                  };

                  const isPickup = data?.type === 'pickup';

                  const verifiedAddressTypeKey = isPickup
                    ? 'confirm_pickup_address'
                    : 'confirm_dropoff_address';

                  const bookingData = bookings?.[data?.uid]?.data;

                  const confirmPickupLocation = {
                    pickup_location_lat: position?.latitude,
                    pickup_location_lon: position?.longitude,
                    pickup_location_name: selectedAddress,
                  };
                  const confirmDropOffLocation = {
                    dropoff_location_lat: position?.latitude,
                    dropoff_location_lon: position?.longitude,
                    dropoff_location_name: selectedAddress,
                  };
                  const confirmedBooking = {
                    ...bookings?.[data?.uid],
                    data: {
                      ...bookingData,
                      [verifiedAddressTypeKey]: true,
                    },
                    ...(isPickup
                      ? confirmPickupLocation
                      : confirmDropOffLocation),
                    state: 'prepared',
                  };

                  newCommuteOffer.stateless_api_request_data.bookings = {
                    ...newCommuteOffer.stateless_api_request_data.bookings,
                    [data?.uid]: confirmedBooking,
                  };
                });

                confirmMatchedAddress({
                  commuteOffer: {
                    ...newCommuteOffer,
                  },
                });

                replaceBrowserHistoryToVerification();
                global.closeFullScreen();
                global.openFullScreen({
                  modalContent: <Verification />,
                });
              }}
              className={css({
                position: 'fixed',
                bottom: '8px',
                width: '258px',
              })}
            >
              {t('c.Geocoding.editor.address.confirm')}
            </Button>
          </Footer>
        </Panel>
      </Container>

      <MapGL
        ref={mapRef}
        style={{ width: '100%', height: '100vh' }}
        accessToken={mapConfig.token}
        mapStyle={mapConfig.mapStyle}
        onViewportChange={onViewportChange}
        logoPosition='bottom-right'
        boxZoom={false}
        cursorStyle={cursorStyle}
        viewportChangeMethod='flyTo'
        onClick={onMapClick}
        {...viewport}
      >
        {position.longitude !== null && position.latitude !== null && (
          <Marker longitude={position.longitude} latitude={position.latitude}>
            <img src={pin} />
          </Marker>
        )}
      </MapGL>
    </>
  );
};

export default Edit;

import turfBbox from '@turf/bbox';
import geoViewport from '@mapbox/geo-viewport';
import debug from 'utils/debug';

const D2 = debug('u:maps');

export const getViewportFromGeometry = (geometry) => {
  D2.S.EVENT('getViewportFromGeometry');
  D2.S.TRACE($ => $('Geometry:', geometry));

  const bbox = turfBbox(geometry);
  const { center, zoom } = geoViewport.viewport(bbox, [300, 200]);
  const [longitude, latitude] = center;

  return {
    zoom,
    latitude,
    longitude,
  };
};

export default getViewportFromGeometry;

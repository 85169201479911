import styled from 'styled-components';

const Container = styled.input`
  font-size: 25px;
  font-weight: 600;
  color: #edece8;
  background: transparent;
  border: 1px;
  border-radius: 5px;
  outline: none;
  pointer-events: ${({ isEditable }) => (isEditable ? 'auto' : 'none')};
  padding: 2px 5px 0px;
  border-style: solid;
  border-color: ${({ isEditable }) => (isEditable ? '#58616d' : 'transparent')};
  width: 250px;
  margin-bottom: 10px;
`;

export default Container;

import styled from 'styled-components';

const Container = styled.div`
  cursor: pointer;
  padding: 5px;

  &:hover {
    opacity: 0.5;
  }
`;

export default Container;

import moment from 'moment-timezone';
import debug from 'utils/debug';

const D2 = debug('m:CommuteSchedule');

// eslint-disable-next-line import/prefer-default-export
export const commuteSchedule$FixedScheduleFromOffers = (
  calculatedOffers,
  timezone
) =>
  D2.S.FUNCTION(
    'FixedScheduleFromOffers',
    { calculatedOffers, timezone },
    ({ $D2 }) => {
      const fixedScheduleTable = calculatedOffers.reduce(
        (fixedScheduleAcc, commuteOffer) => {
          $D2.S.INFO('commuteOffer', {
            commuteOffer,
          });
          const { result, stateless_api_request_data } = commuteOffer;
          const assignedBookings = result.assigned_bookings.reduce(
            (assignedBookingsAcc, assignedBooking) => {
              const { uid, scheduled_pickup_time, scheduled_dropoff_time } =
                assignedBooking;
              const booking = stateless_api_request_data.bookings[uid];
              const { passenger_uid, passenger_name, tags } = booking;
              const isMorning = !!tags.find(x => x === 'Morning');
              return [
                ...assignedBookingsAcc,
                {
                  passenger_uid,
                  passenger_name,
                  isMorning,
                  scheduled_pickup_time,
                  scheduled_dropoff_time,
                  booking: { ...booking, ...assignedBooking },
                  commuteOffer,
                },
              ];
            },
            []
          );
          return {
            ...fixedScheduleAcc,
            resolved: [...fixedScheduleAcc.resolved, ...assignedBookings],
          };
        },
        { resolved: [], rejected: [] }
      );
      $D2.S.INFO('fixedScheduleTable', {
        fixedScheduleTable,
      });

      const fixedSchedule = fixedScheduleTable.resolved.reduce(
        (fixedScheduleAcc, record) => {
          const prevRecord = fixedScheduleAcc[record.passenger_name] || {};
          const { booking } = record;
          return {
            ...fixedScheduleAcc,
            [record.passenger_name]: record.isMorning
              ? {
                  ...prevRecord,
                  morning_pickup_ts: moment(record.scheduled_pickup_time)
                    .tz(timezone)
                    .format('hh:mm A'),
                  morning_duration: moment(record.scheduled_dropoff_time).diff(
                    moment(record.scheduled_pickup_time)
                  ),
                  morning: {
                    pickup_ts: moment(record.scheduled_pickup_time)
                      .tz(timezone)
                      .format('hh:mm A'),
                    duration: moment(record.scheduled_dropoff_time).diff(
                      moment(record.scheduled_pickup_time)
                    ),
                    booking,
                  },
                }
              : {
                  ...prevRecord,
                  evening_dropoff_ts: moment(record.scheduled_dropoff_time)
                    .tz(timezone)
                    .format('hh:mm A'),
                  evening_duration: moment(record.scheduled_dropoff_time).diff(
                    moment(record.scheduled_pickup_time)
                  ),
                  evening: {
                    dropoff_ts: moment(record.scheduled_dropoff_time)
                      .tz(timezone)
                      .format('hh:mm A'),
                    duration: moment(record.scheduled_dropoff_time).diff(
                      moment(record.scheduled_pickup_time)
                    ),
                    booking,
                  },
                },
          };
        },
        {}
      );

      return fixedSchedule;
    }
  );

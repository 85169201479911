import React from 'utils/react';
import Dropdown from 'components/Dropdown';

import Button from './Button';
import Content from './Content';
import ContentV2 from './ContentV2';

const variants = {
  default: (width, toggle, children, opts) => (
    <Content
      width={width}
      onClick={toggle}
      data-testid='Menu-Context'
      {...opts}
    >
      {typeof children === 'function' ? children(toggle) : children}
    </Content>
  ),
  logistics: (width, toggle, children, opts) => (
    <ContentV2
      width={width}
      onClick={toggle}
      data-testid='Menu-Context'
      {...opts}
    >
      {typeof children === 'function' ? children(toggle) : children}
    </ContentV2>
  ),
};
const Menu = ({ width, icon, iconHover, children, type, position }) => (
  <Dropdown
    renderHandler={({ toggle, toggledOn }) => (
      <Button
        onClick={(e) => {
          e.preventDefault();
          toggle();
        }}
        toggledOn={toggledOn}
        icon={icon}
        iconHover={iconHover || icon}
        data-testid='Menu-Button'
      />
    )}
    renderContent={({ toggle }) => {
      const contentType =
        type === 'logistics' ? variants[type] : variants.default;
      return contentType(width, toggle, children, {
        isleft: (position !== 'right').toString(),
      });
    }}
  />
);

export default Menu;

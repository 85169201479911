import React from 'utils/react';
import PanelHeader from '../../PanelHeader';
import HiddenButton from './HiddenButton';
import CloseBooking from './CloseBooking';

const Header = ({
  t,
  size,
  toggleBookingsHidden,
  isHidden,
  label,
  closeBookingDetails,
}) => (
  <PanelHeader>
    {!isHidden && <HiddenButton onClick={toggleBookingsHidden} />}
    {label && <CloseBooking onClick={closeBookingDetails} />}
    {!label ? (
      t('panel.header.unassigned.orders')
    ) : (
      <span style={{ paddingLeft: '22px' }}>{label}</span>
    )}
  </PanelHeader>
);

export default Header;

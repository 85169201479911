import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 50px;
  margin: 10px 0;
  border-radius: 10px;
  background-color: #3e4755;
  overflow: hidden;
  flex-shrink: 0;
`;

export default Container;

import withTranslation from 'utils/react/withTranslation';
import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { clearSelectedSimulations, toggleCompareMod } from 'modules/ui/actions';

import { countSelector } from 'modules/simulations/selectors';
import { selectedSimulationIdsSelector } from 'modules/ui/selectors';
import { currentProjectLayoutNameSelector } from 'modules/user/selectors';

import Panel from './Panel';

const mapStateToProps = createImmutableSelector(
  countSelector,
  selectedSimulationIdsSelector,
  currentProjectLayoutNameSelector,
  (count, selectedIds, layoutName) => ({
    count,
    selectedIds,
    layoutName,
  })
);

const mapDispatchToProps = {
  clearSelectedSimulations,
  toggleCompareMod,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(withTranslation()(Panel));

import React from 'utils/react';
import useTranslation from 'utils/react/useTranslation';
import { Formik } from 'formik';

import Input from 'components/Input';
import ResetButton from 'components/ResetButton';
import SubmitButton from 'components/SubmitButton';

import Row from '../Row';
import ButtonsRow from '../ButtonsRow';

const Form = ({ file, onSubmit, onReset }) => {
  const { t } = useTranslation();
  const tt = (msg, ...opts) =>
    t(`c.ModalWindow.NewGeofence.ChooseFile.${msg}`, ...opts);
  return (
    <Formik
      initialValues={{
        name: file.name,
        description: '',
        tags: '',
      }}
      onSubmit={onSubmit}
    >
      {(props) => {
        const { values, handleChange, handleSubmit, handleBlur } = props;

        return (
          <form onSubmit={handleSubmit} autoComplete='off'>
            <Row>
              <Input
                name='name'
                type='text'
                label={tt('Form.Name')}
                value={values.name}
                onChange={handleChange}
                handleBlur={handleBlur}
                autoComplete='off'
              />
            </Row>
            <Row>
              <Input
                name='description'
                type='text'
                label={tt('Form.Description')}
                value={values.description}
                onChange={handleChange}
              />
              <Input
                name='tags'
                type='text'
                label={tt('Form.Tags')}
                value={values.tags}
                onChange={handleChange}
              />
            </Row>
            <ButtonsRow>
              <ResetButton onClick={onReset}>{tt('ResetButton')}</ResetButton>
              <SubmitButton type='submit'>{tt('SubmitButton')}</SubmitButton>
            </ButtonsRow>
          </form>
        );
      }}
    </Formik>
  );
};

export default Form;

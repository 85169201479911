import RadioField from 'components/Forms/RadioWithButtons';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Fleet from 'components/Fleet';
import edit from 'assets/edit.svg';
import { styled } from 'baseui';
import { useHistory } from 'react-router-dom';

const EditBtn = styled('img', () => {
  return {
    width: '15px',
    height: '15px',
    margin: '21.25px 22px',
  };
});

EditBtn.defaultProps = {
  src: edit,
};

const MasterFleet = () => {
  const { t } = useTranslation();
  const { control } = useForm({
    criteriaMode: 'firstError',
    mode: 'onChange',
    defaultValues: {
      template: 'default',
    },
  });
  const browserhistory = useHistory();
  return (
    <>
      <h1 style={{ margin: 0 }}>{t('c.MasterSettings.content.fleet.title')}</h1>
      <p>{t('c.MasterSettings.content.fleet.description')}</p>
      <RadioField
        name='fleet'
        options={[
          {
            value: 'default',
            label: t('c.MasterSettings.content.fleet.option.fullFleet'),
          },
        ]}
        selected='default'
        control={control}
        buttonActions={[
          {
            icon: <EditBtn />,
            action: (data) => {
              const params = new URLSearchParams(window.location.search);
              params.set('page', 'fleetSetting');
              browserhistory.push(`${window.location.pathname}?${params}`);
              global.openFullScreen({
                modalContent: <Fleet title={t(data?.label[0])} />,
              });
            },
          },
        ]}
      />
    </>
  );
};

export default MasterFleet;

import React from 'utils/react';
import Button from 'pages/CommuteOffer/TextButton';

import Container from './Conteiner';
import FormContainer from './FormContainer';
import ButtonsContainer from './ButtonsContainer';
import Input from './Input';

class VehiclesEditor extends React.PureComponent {
  state = {
    ...this.props.editableVehicle,
  };

  onChangeParam = (e) => {
    const { id, value } = e.currentTarget;
    this.setState({ [id]: value });
  };

  onChangeCapacityParam = (e) => {
    const { id, value } = e.currentTarget;
    this.setState(({ capacity }) => ({
      capacity: { ...capacity, [id]: value },
    }));
  };

  onSave = () => {
    const { editableVehicleId } = this.props;
    this.props.updateVehicle(editableVehicleId, this.state);
    this.props.clearEditableVehicleId();
  };

  onDelete = () => {
    const { editableVehicleId } = this.props;
    this.props.deleteVehicle(editableVehicleId);
  };

  render() {
    if (!this.props.editableVehicle) {
      return null;
    }
    return (
      <Container>
        <FormContainer>
          <Input
            id='agent_id'
            label='Agent ID'
            value={this.state.agent_id}
            onChange={this.onChangeParam}
          />
          <Input
            type='number'
            id='passengers'
            label='Passengers'
            value={
              this.state.capacity.passenger || this.state.capacity.passengers
            }
            onChange={this.onChangeCapacityParam}
          />
          <Input
            type='number'
            id='stop'
            label='Stop'
            value={this.state.capacity.stop}
            onChange={this.onChangeCapacityParam}
          />
        </FormContainer>
        <ButtonsContainer>
          <Button onClick={this.onSave}>Save</Button>
          <Button onClick={this.onDelete}>Delete</Button>
          <Button onClick={this.props.clearEditableVehicleId}>Close</Button>
        </ButtonsContainer>
      </Container>
    );
  }
}

export default VehiclesEditor;

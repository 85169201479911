import styled from 'styled-components';

export default styled.div`
  padding: 15px;
  border-radius: 5px;
  margin-top: 6px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);

  background-color: rgb(54, 61, 74);
`;

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
  margin-bottom: 5px;
  font-size: 12px;

  &:last-child {
    margin-right: 0;
  }
`;

export default Container;

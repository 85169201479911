import styled from 'styled-components';

const Overflow = styled.div`
  position: relative;
  overflow: scroll;

  max-height: calc(90vh - 200px);
`;

export default Overflow;

import React from 'utils/react';

import useTranslation from 'utils/react/useTranslation';

import { LanguageControl } from '@urbica/react-map-gl';

const Component = () => {
  const { i18n } = useTranslation();

  const { language } = i18n;

  const getMapLanguage = (lang) => {
    const languagesMap = {
      jp: 'ja',
      pl: 'en',
      tt: 'en',
      th: 'en',
    };
    return languagesMap[lang] || lang;
  };

  const mapLanguage = getMapLanguage(language);

  return (
    <LanguageControl language={mapLanguage} defaultLanguage={mapLanguage} />
  );
};

export default Component;

import React from 'utils/react';
import useTranslation from 'utils/react/useTranslation';

import FileInput from './FileInput';
import Container from './Container';
import Text from './Text';

const DropZone = (props) => {
  const { onDragOver, onDrop, onChange } = props;

  const { t } = useTranslation();

  return (
    <Container onDragOver={onDragOver} onDrop={onDrop}>
      <Text>{t('baseui.fileuploader.dropFilesToUpload')}</Text>
      <div>
        <FileInput onChange={onChange} />
      </div>
    </Container>
  );
};

export default DropZone;

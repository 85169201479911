import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';
import {
  deleteGeofence,
  updateGeofence,
  saveGeofence,
  fetchGeofences,
  copyGeofence,
} from 'modules/geofences/actions';

import {
  toggleVisibilityGeofence,
  setEditableGeofence,
  updateEditableGeofence,
  changeGeofencesHover,
} from 'modules/ui/actions';

import { geofencesFilteredSelector } from 'modules/geofences/selectors';

import {
  editableGeofenceSelector,
  geofencesErrorSelector,
  visibleGeofencesSelector,
  geofencesHoverSelector,
} from 'modules/ui/selectors';

import List from './List';

const mapDispatchToProps = {
  deleteGeofence,
  updateGeofence,
  toggleVisibilityGeofence,
  setEditableGeofence,
  updateEditableGeofence,
  saveGeofence,
  changeGeofencesHover,
  fetchGeofences,
  copyGeofence,
};

const mapStateToProps = createImmutableSelector(
  geofencesFilteredSelector,
  visibleGeofencesSelector,
  editableGeofenceSelector,
  geofencesHoverSelector,
  geofencesErrorSelector,
  (geofences, visibleGeofences, editableGeofence, geofencesHover, error) => ({
    geofences,
    visibleGeofences,
    editableGeofence,
    geofencesHover,
    error,
  })
);

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(List);

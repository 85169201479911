const defaultTimezones = {
  label: 'Singapore',
  value: 'Singapore',
};

const hardcodedTimezones = {
  'https://sgerp-stage.d.gcdev.swatrider.com': {
    '/api/v2/project/139': {
      label: 'Europe/Warsaw',
      value: 'Europe/Warsaw',
    },
  },
};

const getTimezonesConfig = (projectUrl) => {
  const serverConfig = hardcodedTimezones[global.GEODISC_API_URL];
  const projectConfig =
    serverConfig && projectUrl ? serverConfig[projectUrl] : undefined;
  return projectConfig || defaultTimezones;
};

export default getTimezonesConfig;

import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';

const ProjectLabel = styled(Link)`
  display: flex;
  align-items: right;

  color: #c5cede;
  padding: 6px 0px;
  margin-right: 10px;

  text-decoration: none;
  border-bottom: solid transparent 0.2rem;
  border-top: solid transparent 0.2rem;
  transition: color 0.3s;

  font-size: 14px;
  font-weight: bold;

  &:hover {
    color: #edece8;
  }
`;

export default ProjectLabel;

import styled from 'styled-components';

const WaypointMarker = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${({ color = '#000' }) => color};
  border: 1px solid white;
  color: black;
  text-align: center;
  font-weight: ${({ isActive = false }) => (isActive ? 'bold' : 'normal')};
`;

export default WaypointMarker;

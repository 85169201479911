import nearestPoint from '@turf/nearest-point';

import debug from 'utils/debug';

const D2 = debug('u:nearest-point');

export default function find(target, points) {
  return D2.S.PROFILER('find', () => {
    return nearestPoint(target, points);
  });
}

import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { commuteOfferCurrentDataSelector } from 'modules/commuteOffer/selectors';

import TopPanels from './TopPanels';

const mapStateToProps = createSelector(
  commuteOfferCurrentDataSelector,
  data => ({ data })
);

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(TopPanels);

import React from 'utils/react';

import { Block } from 'baseui/block';
import { Button, KIND, SIZE } from 'baseui/button';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { StatefulMenu } from 'baseui/menu';

import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';

import debug from 'utils/debug';
import MenuIcon from './MenuIcon';

import ButtonContainer from './ButtonContainer';
import { withWindowSize } from 'utils/hocs';
import setPopoverHeight from 'utils/popover-height';

const D2 = debug('p:CommuteOffer:Panels:TransitstopSetsMenu');

class TransitstopSetsMenu extends React.PureComponent {
  state = {};
  popoverContent = React.createRef();

  render() {
    D2.S.INFO('render', { props: this.props, MenuIcon });

    const {
      transitstopSets,
      visibleTransitstopSets,
      toggleVisibilityTransitstopSet,
      windowSize: { height },
    } = this.props;

    const visibleTransitstopSetsSet = new Set(visibleTransitstopSets.toJS());
    D2.S.INFO('visibleTransitstopSetsSet', { visibleTransitstopSetsSet });

    const setChecked = (transitstopSet, value) => {
      D2.S.INFO('setChecked', { transitstopSet, value });

      toggleVisibilityTransitstopSet(transitstopSet);
    };

    return (
      <StatefulPopover
        placement={PLACEMENT.leftTop}
        content={() =>
          !transitstopSets || !transitstopSets.length ? (
            <StatefulMenu
              overrides={{
                Option: {
                  props: {
                    size: 'large',
                  },
                },
              }}
              items={[]}
            />
          ) : (
            <Block
              ref={this.popoverContent}
              padding='10px'
              backgroundColor='menuFill'
              overflow='auto'
            >
              {transitstopSets &&
                transitstopSets.map(item => (
                  <Checkbox
                    key={item.id}
                    checked={visibleTransitstopSetsSet.has(item.id)}
                    onChange={e => setChecked(item.id, e.target.checked)}
                    labelPlacement={LABEL_PLACEMENT.right}
                  >
                    {item.name}
                  </Checkbox>
                ))}
            </Block>
          )
        }
        onOpen={setPopoverHeight({
          elementRef: this.popoverContent,
          windowHeight: height,
        })}
      >
        <ButtonContainer>
          <Button
            size={SIZE.compact}
            kind={KIND.minimal}
            disabled={!transitstopSets}
          >
            <MenuIcon />
          </Button>
        </ButtonContainer>
      </StatefulPopover>
    );
  }
}

export default withWindowSize(TransitstopSetsMenu);

import React from 'utils/react';

import { Block } from 'baseui/block';
import { Button, KIND, SIZE } from 'baseui/button';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { StatefulMenu } from 'baseui/menu';

import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';

import debug from 'utils/debug';
import MenuIcon from './MenuIcon';

import ButtonContainer from './ButtonContainer';
import { withWindowSize } from 'utils/hocs';
import setPopoverHeight from 'utils/popover-height';

const D2 = debug('p:Logistics:Panels:GeofencesMenu');

class GeofencesMenu extends React.PureComponent {
  state = {};
  popoverContent = React.createRef();

  render() {
    D2.S.INFO('render', { props: this.props, MenuIcon });

    const {
      geofences,
      visibleGeofences,
      toggleVisibilityGeofence,
      layoutName,
      windowSize: { height },
    } = this.props;
    const visibleGeofencesSet = new Set(visibleGeofences.toJS());
    D2.S.INFO('visibleGeofencesSet', { visibleGeofencesSet });

    const setChecked = (geofence, value) => {
      D2.S.INFO('setChecked', { geofence, value });

      toggleVisibilityGeofence(geofence.id);
    };

    /*
     * Filter Active geofences
     */
    const filteredGeofences = geofences?.filter(
      geofence => geofence?.status === 'A'
    );

    return (
      <StatefulPopover
        placement={
          layoutName === 'delivery' ? PLACEMENT.top : PLACEMENT.leftTop
        }
        content={() =>
          !filteredGeofences?.length ? (
            <StatefulMenu
              overrides={{
                Option: {
                  props: {
                    size: 'large',
                  },
                },
              }}
              items={[]}
            />
          ) : (
            <Block
              ref={this.popoverContent}
              padding='10px'
              backgroundColor='menuFill'
              overflow='auto'
            >
              {filteredGeofences?.map(geofence => (
                <Checkbox
                  key={geofence.id}
                  checked={visibleGeofencesSet.has(geofence.id)}
                  onChange={e => setChecked(geofence, e.target.checked)}
                  labelPlacement={LABEL_PLACEMENT.right}
                >
                  {geofence.name}
                </Checkbox>
              ))}
            </Block>
          )
        }
        onOpen={setPopoverHeight({
          elementRef: this.popoverContent,
          windowHeight: height,
        })}
      >
        <ButtonContainer
          layoutName={layoutName}
          data-testid='GeofencesMenu-ButtonContainer-Geofences'
        >
          <Button size={SIZE.compact} kind={KIND.minimal} disabled={!geofences}>
            <MenuIcon />
          </Button>
        </ButtonContainer>
      </StatefulPopover>
    );
  }
}

export default withWindowSize(GeofencesMenu);

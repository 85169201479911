import React from 'react';
import styled from 'styled-components';

import useTranslation from 'utils/react/useTranslation';
import ErrorImg from 'assets/error.png';

const Container = styled.div`
  width: 100vw;
  background: #080d14;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
`;

const TextHeader = styled.div`
  padding: 16px 20px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
  > div:first-child {
    color: #ffffff;
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 8px;
    text-align: center;
  }
`;

const ImgWrapper = styled.div`
  margin: 48px 0;
`;

const ErrorPage = ({ type = 'other' }) => {
  const { t } = useTranslation();
  const messages = {
    access_denied: {
      title: t('accessDenied.title'),
      description: t('accessDenied.description'),
    },
    other: {
      title: t('somethingWrong.title'),
      description: t('somethingWrong.description'),
    },
  };
  return (
    <Container>
      <ImgWrapper>
        <img src={ErrorImg} />
      </ImgWrapper>
      <Header>
        <TextHeader>
          <div>{messages[type].title}</div>
          <div>{messages[type].description}</div>
        </TextHeader>
      </Header>
    </Container>
  );
};

export default ErrorPage;

import React from 'react';
import { Checkbox, STYLE_TYPE } from 'baseui/checkbox';
import styled from 'styled-components';
import { InputWrapper } from './FormStyles';
import FormLabel from './FormLabel';
import { Controller } from 'react-hook-form';

const CheckboxWrapper = styled.div`
  margin: 24px 0 0 0;
`;

const ToggleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ToggleIcon = styled.div`
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  margin-left: ${({ checked }) => (checked ? '32px' : '4px')};
  transition: all 1s linear;
`;

const ToggleText = styled.div`
  position: absolute;
  top: 6px;
  left: ${({ checked }) => (checked ? '5px' : '28px')};
  transition: all 1s linear;
`;

export default function CheckboxField({
  label,
  type,
  name,
  value,
  info,
  onChange,
  control,
  rules,
}) {
  return (
    <React.Fragment>
      <InputWrapper>
        <FormLabel label={label} name={name} infoHover={info} />
        <CheckboxWrapper>
          <Controller
            rules={rules}
            control={control}
            name={name}
            render={({ field: { onChange, value, ref } }) => {
              return (
                <Checkbox
                  name={name}
                  overrides={{
                    Toggle: () => (
                      <ToggleWrapper>
                        <ToggleIcon checked={value} />
                        <ToggleText checked={value}>
                          {value ? 'On' : 'Off'}
                        </ToggleText>
                      </ToggleWrapper>
                    ),
                    ToggleTrack: {
                      style: ({ $theme }) => ({
                        outline: '#142162',
                        backgroundColor: value
                          ? $theme.colors.buttonBorder
                          : $theme.colors.buttonBorderDisabled,
                        height: '28px',
                        width: '56px',
                        borderRadius: '28px',
                        position: 'relative',
                        margin: '0px',
                      }),
                    },
                    Label: () => null,
                  }}
                  checked={value}
                  inputRef={ref}
                  onChange={onChange}
                  checkmarkType={type === 'toggle' && STYLE_TYPE.toggle_round}
                />
              );
            }}
          />
        </CheckboxWrapper>
      </InputWrapper>
    </React.Fragment>
  );
}

import deepmerge from 'deepmerge';

import { emptyCommuteOfferJSON } from 'utils/CommuteOffer/defaults';

import debug from 'utils/debug';
import { validateCommuteOffer } from './validateCommuteOffer';

const D2 = debug('u:CommuteOffer:Validation');

export const normalizeCommuteOffer = async (
  commuteOffer = JSON.parse(emptyCommuteOfferJSON),
  opts = {}
) =>
  D2.A.FUNCTION('normalizeCommuteOffer', { commuteOffer, opts }, async () => {
    const validatedOffer = await validateCommuteOffer(
      deepmerge.all([
        JSON.parse(emptyCommuteOfferJSON),
        commuteOffer,
        {
          stateless_api_request_data: {
            engine_settings: {
              calculation_parameters: {
                calculations_mode: 'async',
              },
            },
          },
          result: {
            $errors: [],
            $warnings: [],
            $fixes: [],
          },
        },
      ]),
      opts
    );
    // const originalOffer = commuteOffer;
    // console.log('=== Validated data', { validatedOffer, originalOffer, opts });
    return validatedOffer;
  });

export default normalizeCommuteOffer;

import { sleep } from 'utils/CommuteSchedule/utils';

import { api$Masstransit } from 'api';

import debug from 'utils/debug';

const D2 = debug('m:CommuteSchedule:ResolveAddresses');

const resolveAddresses = async (addresses, opts) => {
  const { setProgress = () => {}, ignoreErrors = false } = opts;

  const buildingRequestsResolved = [];
  const buildingRequestsRejected = [];

  const buildingRequests = Object.values(addresses).map(location =>
    api$Masstransit
      .buildingByAddress(location.country, location.city, location.address)
      .then(
        (result) => {
          buildingRequestsResolved.push(result);
        },
        (error) => {
          buildingRequestsRejected.push({
            address: location.address,
            error,
          });
        }
      )
  );
  D2.S.INFO('buildingRequests', { buildingRequests });

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const progress =
      (buildingRequestsResolved.length + buildingRequestsRejected.length) /
      buildingRequests.length;
    D2.S.INFO('buildingRequests:Progress', {
      buildingRequestsResolved,
      buildingRequestsRejected,
      buildingRequests,
      progress,
    });
    // eslint-disable-next-line no-await-in-loop
    await setProgress('Resolving addresses', progress);
    if (
      buildingRequestsResolved.length + buildingRequestsRejected.length ===
      buildingRequests.length
    ) {
      break;
    }
    // eslint-disable-next-line no-await-in-loop
    await sleep(500);
  }
  D2.S.INFO('buildingRequestsResolved', {
    buildingRequestsResolved,
  });
  D2.S.INFO('buildingRequestsRejected', {
    buildingRequestsRejected,
  });

  const buildingsByAddress = buildingRequestsResolved.reduce(
    (acc, record) => {
      const { result } = record;
      const id = [record.address, record.city, record.country].join(', ');
      if (result.objects && result.objects.length) {
        acc.resolved[id] = result.objects.map(item => ({
          id,
          name: item.name,
          address: item.address,
          blk_no: item.blk_no,
          coordinates: item.point.coordinates,
          code: item.code,
          city: record.city,
          country: record.country,
        }));
      } else {
        acc.failed.push({
          id: record.id,
          address: record.address,
          error: new Error('No objects returned'),
        });
      }
      return acc;
    },
    { resolved: {}, failed: [] }
  );
  D2.S.INFO('buildingsByAddress', {
    buildingsByAddress,
  });

  if (!ignoreErrors) {
    const buildingRequestsFailed = [
      ...buildingRequestsRejected,
      ...buildingsByAddress.failed,
    ];
    if (buildingRequestsFailed.length) {
      const message = `Failed to resolve addresses: ${buildingRequestsFailed
        .map(item => item.address)
        .join(', ')}`;
      D2.S.INFO('Throw', { message, ignoreErrors });
      throw new Error(message);
    }
  }

  return {
    buildingRequestsResolved,
    buildingRequestsRejected,
    buildingsByAddress,
  };
};

const commuteSchedule$ResolveAddresses = async (
  currentPassengersData,
  opts
) => {
  const { setProgress = () => {}, ignoreErrors = false } = opts;

  const personAddresses = currentPassengersData
    .reduce((acc, person) => {
      const locations = [person.home, person.office].map(
        location =>
          location.$type === 'address' && {
            id: `${location.country}:${location.city}:${location.address}`,
            address: location.address,
            city: location.city,
            country: location.country,
          }
      );
      return [...acc, ...locations];
    }, [])
    .filter(x => !!x);
  D2.S.INFO('personAddresses', {
    personAddresses,
  });

  const addresses = personAddresses.reduce((acc, location) => {
    return {
      ...acc,
      [location.id]: location,
    };
  }, {});
  D2.S.INFO('addresses', {
    addresses,
  });

  const {
    buildingRequestsResolved,
    buildingRequestsRejected,
    buildingsByAddress,
  } = await resolveAddresses(addresses, {
    setProgress,
    ignoreErrors,
  });
  D2.S.INFO('buildingRequestsResolved', {
    buildingRequestsResolved,
  });
  D2.S.INFO('buildingRequestsRejected', {
    buildingRequestsRejected,
  });
  D2.S.INFO('buildingsByAddress', { buildingsByAddress });

  const dataWithBuildings = currentPassengersData.map((record) => {
    const resolveLocation = location =>
      location.$type === 'address'
        ? {
            ...location,
            $buildings: buildingsByAddress.resolved[location.id],
          }
        : location;
    return {
      ...record,
      home: resolveLocation(record.home),
      office: resolveLocation(record.office),
    };
  });
  D2.S.INFO('dataWithBuildings', { dataWithBuildings });

  return dataWithBuildings;
};

export default commuteSchedule$ResolveAddresses;

import styled from 'styled-components';

const Column = styled.div`
  display: flex;
  font-size: 12px;
  width: 100px;
  text-align: left;
  flex-shrink: 0;
  margin-right: ${({ marginRight }) => marginRight || 0};
  color: #c5cede;
  opacity: 0.5;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    color: ${({ disable }) => (disable ? '#c5cede' : '#7b828f')};

    &:before {
      border-top: 8px solid
        ${({ disable }) => (disable ? '#c5cede' : '#7B828F')};
    }
  }

  &:before {
    content: '';

    margin-top: 2px;
    margin-right: 5px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;

    border-top: 8px solid #a7aeb9;
    opacity: ${({ disable, isActive }) => (disable || !isActive ? 0 : 1)};
    transform: rotate(${({ sort }) => (sort ? '180deg' : '0deg')});
  }
`;

export default Column;

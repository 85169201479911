import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';
import { datasetsSearchSelector, isSavingSelector } from 'modules/ui/selectors';

import Verification from './Verification';
import {
  commuteOfferCurrentDataSelector,
  commuteOfferOriginalDataSelector,
  commuteOfferHistorySelector,
  availableBookingsSelector,
} from 'modules/commuteOffer/selectors';
import { confirmMatchedAddress } from 'modules/commuteOffer/actions';
import { updateCommuteOffer } from 'modules/commuteOffer/actions';
const mapStateToProps = createImmutableSelector(
  datasetsSearchSelector,
  availableBookingsSelector,
  commuteOfferCurrentDataSelector,
  commuteOfferOriginalDataSelector,
  commuteOfferHistorySelector,
  isSavingSelector,
  (text, orders, currentOffer, originalData, history, isSaving) => ({
    text,
    orders,
    currentOffer,
    originalData,
    history,
    isSaving,
  })
);

const mapDispatchToProps = {
  confirmMatchedAddress,
  updateCommuteOffer,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Verification);

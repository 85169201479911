import Tag from 'components/Tag2';
import useTranslation from 'utils/react/useTranslation';

const OrderStatus = ({ status }) => {
  const { t } = useTranslation();
  const bookingStatues = {
    completed: {
      kind: 'success',
      text: t('c.messages.Success'),
    },
    fail_to_deliver: {
      kind: 'danger',
      text: t('c.messages.FailedToDeliver'),
    },
  };
  const bookingStatus = bookingStatues[status];

  return <Tag kind={bookingStatus.kind}>{bookingStatus.text}</Tag>;
};

export default OrderStatus;

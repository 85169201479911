import React from 'utils/react';
import { Formik } from 'formik';
import moment from 'moment-timezone';

import Input from 'components/Input';
import InputSelect from 'components/InputSelect';
import DateTimePicker from 'components/DateTimePicker';

import SubmitButton from 'components/SubmitButton';
import { datasetsToOptions, geofencesToOptions, getMultiBody } from 'utils';

import debug from 'utils/debug';
import Row from './Row';
import ButtonsRow from './ButtonsRow';

const D2 = debug('c:ModalWindow:NewSimulation');

const drivingProfilesByServers = {
  'https://sgerp-stage.d.gcdev.swatrider.com': [
    {
      label: 'alldriving',
      value: 'alldriving',
    },
    {
      label: 'sgvan',
      value: 'sgvan',
    },
  ],
  'https://sgerp.d.gcprod.swatrider.com': [
    {
      label: 'alldriving',
      value: 'alldriving',
    },
    {
      label: 'sgvan',
      value: 'sgvan',
    },
  ],
};

const walkingProfilesByServers = {
  'https://sgerp-stage.d.gcdev.swatrider.com': [
    {
      label: 'walking',
      value: '/api/v2/routingprofile/32',
    },
  ],
  'https://sgerp.d.gcprod.swatrider.com': [
    {
      label: 'walking',
      value: '/api/v2/routingprofile/20',
    },
  ],
};

class NewSimulation extends React.PureComponent {
  state = {
    waiting: false,
  };

  handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  componentDidMount() {
    D2.S.INFO('componentDidMount', { props: this.props });

    const { datasets, geofences } = this.props;

    if (!datasets) {
      this.props.fetchDatasets();
    }

    if (!geofences) {
      this.props.fetchGeofences();
    }
  }

  onSubmit = (values) => {
    const { addSimulation, projectUrl } = this.props;
    const { dataset, geofence, road_network, walking_profile, ...otherValues } =
      values;
    this.setState({ waiting: true });

    if (dataset && geofence) {
      const arrayValues = getMultiBody(otherValues);

      arrayValues.forEach((item) => {
        const number_of_vehicles = parseInt(item.number_of_vehicles, 10);
        const vehicle_capacity = parseInt(item.vehicle_capacity, 10);
        const vehicle_lon = parseFloat(item.vehicle_lon);
        const vehicle_lat = parseFloat(item.vehicle_lat);

        const vehicle_configuration = [];
        for (let i = 0; i < number_of_vehicles; i++) {
          vehicle_configuration.push({
            capacity: { passenger: vehicle_capacity },
            lon: vehicle_lon,
            lat: vehicle_lat,
            breaks: [],
            can_be_shifted: true,
          });
        }

        const body = JSON.stringify({
          simulation_mode: item.simulation_mode.value,
          dataset: `/api/v2/dataset/${dataset.value}`,
          geofence_id: geofence.value,
          number_of_vehicles: parseInt(item.number_of_vehicles, 10),
          vehicle_capacity: parseInt(item.vehicle_capacity, 10),
          acceptable_waiting_time:
            parseInt(item.acceptable_waiting_time, 10) * 60,
          max_additional_journey_time:
            parseInt(item.max_additional_journey_time, 10) * 60,
          min_advance_booking_window:
            parseInt(item.min_advance_booking_window, 10) * 60,
          max_advance_booking_window:
            parseInt(item.max_advance_booking_window, 10) * 60,
          road_network: road_network.value,
          walking_profile:
            walking_profile.value !== '' ? walking_profile.value : null,
          write_events_log: item.write_events_log.value,
          start_time: moment(item.start_time)
            .tz(global.GEODISC_TIMEZONE)
            .format(),
          end_time: moment(item.end_time).tz(global.GEODISC_TIMEZONE).format(),
          conversion_rate: parseInt(item.conversion_rate, 10),
          name: item.name,
          description: item.name,
          allow_jump: false,
          adjust_driver_breaks_enabled: true,
          mixed_fleet: true,
          offer_generation_enabled: false,
          driver_prep_time: 5 * 60,
          min_driver_rest_time: 5 * 60,
          odbs_trip_duration: 90 * 60,
          percentage_driver_rest_time: 10,
          max_walking_distance: 200,
          vehicle_ordering_in_one_by_one_stage: 1,
          journey_duration_source: 'public_transit',
          project: projectUrl,
          data: {
            simulation_step_pause_scheduler_seconds: 30,
            use_websocket_for_frontend: item.use_websocket_for_frontend.value,
            vehicle_driving_side: item.vehicle_driving_side,
            vehicle_shift_distance: parseInt(item.vehicle_shift_distance, 10),
            vehicle_configuration,
          },
        });

        addSimulation(body);
      });
    }
  };

  render() {
    const { datasets, geofences } = this.props;

    const drivingProfilesForServer =
      drivingProfilesByServers[global.GEODISC_API_URL];
    const drivingProfiles = drivingProfilesForServer || {
      label: 'alldriving',
      value: 'alldriving',
    };

    const walkingProfilesForServer =
      walkingProfilesByServers[global.GEODISC_API_URL];
    const walkingProfiles = walkingProfilesForServer || {
      label: 'None',
      value: null,
    };

    return (
      <Formik
        initialValues={{
          name: '',
          simulation_mode: { label: 'Fast', value: 'fast_simulation' },
          dataset: null,
          geofence: null,
          number_of_vehicles: 5,
          vehicle_capacity: 18,
          acceptable_waiting_time: 10,
          max_additional_journey_time: 15,
          min_advance_booking_window: 0,
          max_advance_booking_window: 0,
          conversion_rate: 100,
          road_network: drivingProfiles[0],
          walking_profile: walkingProfiles[0],
          vehicle_driving_side: 'left',
          vehicle_shift_distance: 3,
          write_events_log: { label: 'Yes', value: true },
          use_websocket_for_frontend: { label: 'No', value: false },
          vehicle_lon: 103.84687388890413,
          vehicle_lat: 1.2740788888387,
          start_time: moment('2018-12-04T11:00+08:00').toDate(),
          end_time: moment('2018-12-04T15:00+08:00').toDate(),
        }}
        onSubmit={this.onSubmit}
      >
        {(formikProps) => {
          const {
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            setFieldTouched,
          } = formikProps;

          const localHandleChange = (...args) => {
            D2.S.INFO('localHandleChange', { args });
            return handleChange(...args);
          };

          const { waiting } = this.state;

          return (
            <form onSubmit={handleSubmit} autoComplete='off'>
              <Row>
                <Input
                  name='name'
                  type='text'
                  label='Name'
                  value={values.name}
                  onKeyDown={this.handleKeyDown}
                  onChange={handleChange}
                  handleBlur={handleBlur}
                  autoComplete='off'
                  required
                />
              </Row>
              <Row>
                <InputSelect
                  id='simulation_mode'
                  name='simulation_mode'
                  label='Simulation mode'
                  options={[
                    { label: 'Fast', value: 'fast_simulation' },
                    { label: 'Real-time', value: 'real_time' },
                    { label: 'Real-operations', value: 'real_operations' },
                  ]}
                  value={values.simulation_mode}
                  onKeyDown={this.handleKeyDown}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </Row>
              <Row>
                <DateTimePicker
                  name='start_time'
                  label='Start time'
                  format='YYYY-MM-DDTHH:mmZ'
                  value={values.start_time}
                  onKeyDown={this.handleKeyDown}
                  onChange={setFieldValue}
                />
                <DateTimePicker
                  name='end_time'
                  label='End time'
                  format='YYYY-MM-DDTHH:mmZ'
                  value={values.end_time}
                  onKeyDown={this.handleKeyDown}
                  onChange={setFieldValue}
                />
              </Row>
              <Row>
                <InputSelect
                  id='dataset'
                  name='dataset'
                  label='Dataset'
                  options={datasetsToOptions(datasets)}
                  value={values.dataset}
                  onKeyDown={this.handleKeyDown}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
                <InputSelect
                  id='geofence'
                  name='geofence'
                  label='Geofence'
                  options={geofencesToOptions(geofences)}
                  value={values.geofence}
                  onKeyDown={this.handleKeyDown}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </Row>
              <Row>
                <Input
                  name='number_of_vehicles'
                  type='text'
                  label='Number of vehicles'
                  value={values.number_of_vehicles}
                  onKeyDown={this.handleKeyDown}
                  onChange={handleChange}
                  required
                />
                <Input
                  name='vehicle_capacity'
                  type='text'
                  label='Vehicle capacity'
                  value={values.vehicle_capacity}
                  onKeyDown={this.handleKeyDown}
                  onChange={handleChange}
                  required
                />
              </Row>
              <Row>
                <Input
                  name='acceptable_waiting_time'
                  type='text'
                  label='Acceptable waiting time, min'
                  value={values.acceptable_waiting_time}
                  onKeyDown={this.handleKeyDown}
                  onChange={handleChange}
                  required
                />
                <Input
                  name='max_additional_journey_time'
                  type='text'
                  label='Max additional journey time, min'
                  value={values.max_additional_journey_time}
                  onKeyDown={this.handleKeyDown}
                  onChange={handleChange}
                  required
                />
              </Row>
              <Row>
                <Input
                  name='min_advance_booking_window'
                  type='text'
                  label='Min advance booking time window, min'
                  value={values.min_advance_booking_window}
                  onKeyDown={this.handleKeyDown}
                  onChange={handleChange}
                  required
                />
                <Input
                  name='max_advance_booking_window'
                  type='text'
                  label='Max advance booking time window, min'
                  value={values.max_advance_booking_window}
                  onKeyDown={this.handleKeyDown}
                  onChange={handleChange}
                  required
                />
              </Row>
              <Row>
                <InputSelect
                  id='road_network'
                  name='road_network'
                  label='Road network'
                  options={drivingProfiles}
                  value={values.road_network}
                  onKeyDown={this.handleKeyDown}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
                <InputSelect
                  id='walking_profile'
                  name='walking_profile'
                  label='Walking profile'
                  options={walkingProfiles}
                  value={values.walking_profile}
                  onKeyDown={this.handleKeyDown}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </Row>
              <Row>
                <Input
                  name='vehicle_driving_side '
                  type='text'
                  label='Vehicle driving side '
                  value={values.vehicle_driving_side}
                  onKeyDown={this.handleKeyDown}
                  onChange={handleChange}
                  required
                />
                <Input
                  name='conversion_rate'
                  label='Conversion rate'
                  value={values.conversion_rate}
                  onKeyDown={this.handleKeyDown}
                  onChange={handleChange}
                  required
                />
              </Row>
              {false && (
                <Row>
                  <Input
                    name='vehicle_shift_distance '
                    type='text'
                    label='Vehicle shift distance '
                    value={values.vehicle_shift_distance}
                    onKeyDown={this.handleKeyDown}
                    onChange={handleChange}
                    required
                  />
                </Row>
              )}
              <Row>
                <InputSelect
                  id='write_events_log'
                  name='write_events_log'
                  label='Record data for playback'
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  value={values.write_events_log}
                  onKeyDown={this.handleKeyDown}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
                <InputSelect
                  id='use_websocket_for_frontend'
                  name='use_websocket_for_frontend'
                  label='Use Websockets'
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  value={values.use_websocket_for_frontend}
                  onKeyDown={this.handleKeyDown}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </Row>
              <Row>
                <Input
                  name='vehicle_lon'
                  type='text'
                  label='Initial vehicle longitude'
                  value={values.vehicle_lon}
                  onKeyDown={this.handleKeyDown}
                  onChange={localHandleChange}
                  required
                />
                <Input
                  name='vehicle_lat'
                  type='text'
                  label='Initial vehicle latitude '
                  value={values.vehicle_lat}
                  onKeyDown={this.handleKeyDown}
                  onChange={handleChange}
                  required
                />
              </Row>
              <ButtonsRow>
                <SubmitButton
                  type='submit'
                  disabled={waiting}
                  style={{ width: 320 }}
                >
                  {waiting ? 'Creating...' : 'Create new simulation'}
                </SubmitButton>
              </ButtonsRow>
            </form>
          );
        }}
      </Formik>
    );
  }
}

export default NewSimulation;

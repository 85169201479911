import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { styled } from 'baseui';
import { get } from 'lodash';

import Container from './Container';
import DataInfo from './DataInfo';
import BookingDetails from './BookingDetails';
import chevron from 'assets/chevron.svg';
import unassignedPinIcon from 'assets/unassigned-pin.png';
import { getGeoCodedLocationName } from 'pages/Logistics/Geocoding/Verification/utils';

const Header = styled('div', () => {
  return {
    display: 'flex',
    justifyItems: 'center',
  };
});

const ExpandButton = styled('button', ({ $isExpanded }) => {
  return {
    position: 'absolute',
    top: '22px',
    right: '20px',
    cursor: 'pointer',
    height: '12px',
    width: '8px',
    border: 'none',
    opacity: 0.5,
    background: `url(${chevron}) no-repeat center`,
    transform: $isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)',
    transitionDuration: '0.3s',
    ':hover': {
      opacity: 1,
    },
  };
});

const PinIcon = styled('img', () => ({
  marginLeft: '9px',
  transform: 'translateY(2px)',
}));

const BookingV2 = (props) => {
  const {
    t,
    connectDragSource,
    isDragging,
    booking,
    recalculateHeight,
    simulation,
  } = props;
  const [isExpanded, setExpandDetails] = useState(false);

  const ordersDisplay =
    simulation?.data.logistics_api_settings.orders_display || 'dropoff_only';

  const onExpandButtonClicked = () => {
    setExpandDetails(!isExpanded);
    recalculateHeight && recalculateHeight();
  };

  useEffect(() => {
    if (isExpanded) {
      recalculateHeight && recalculateHeight();
      return () => {
        recalculateHeight && recalculateHeight();
      };
    }
  }, [isExpanded]);

  return (
    <Container ref={connectDragSource} data-testid='Bookings-BookingV2'>
      <ExpandButton onClick={onExpandButtonClicked} $isExpanded={isExpanded} />
      <DataInfo
        title={`${t('p.booking.card.title.id')}:`}
        value={get(booking, ['data', 'external_id']) || '-'}
        extraElements={<PinIcon src={unassignedPinIcon} />}
      />
      {(ordersDisplay === 'pickup_and_dropoff' ||
        ordersDisplay === 'pickup_only') && (
        <DataInfo
          title={`${t('p.booking.card.title.pickup')}:`}
          value={getGeoCodedLocationName({
            type: 'pickup',
            addressData: booking,
          })}
        />
      )}
      {(ordersDisplay === 'pickup_and_dropoff' ||
        ordersDisplay === 'dropoff_only') && (
        <DataInfo
          title={`${t('p.booking.card.title.dropoff')}:`}
          value={getGeoCodedLocationName({
            type: 'dropoff',
            addressData: booking,
          })}
        />
      )}
      <BookingDetails expanded={isExpanded} booking={booking} {...props} />
    </Container>
  );
};

export default BookingV2;

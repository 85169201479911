import withTranslation from 'utils/react/withTranslation';
import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import {
  canManageVehiclesSelector,
  currentUserConfigSelector,
  currentProjectConfigSelector,
  isDeliveryLayoutSelector,
  isSuperuserSelector,
  isStaffSelector,
  capacityFunctorSelector,
} from 'modules/user/selectors';
import {
  editableBookingsSelector,
  commuteOfferIsReadOnlySelector,
  commuteOfferLoadedSimulationSelector,
  operationStatisticsSelector,
  commuteOfferCurrentDataSelector,
} from 'modules/commuteOffer/selectors';
import {
  clonedVehicle,
  deleteVehicle,
  addPointToRoute,
  addStopToRoute,
  setVehicleStartTime,
  recalculateVehicleTime,
  showVehicleUpdates,
  showVehicleSource,
  commuteOfferRequestUpdate,
  removeAllBookings,
  lockVehicle,
  unlockVehicle,
} from 'modules/commuteOffer/actions';
import {
  filteredStopsSelector,
  driversSelector,
} from 'modules/entities/selectors';

import {
  activeVehicleIdsSelector,
  activeRouteStopUidSelector,
  addPointModeSelector,
  addStopModeSelector,
  stopSearchQuerySelector,
} from 'modules/ui/selectors';
import {
  setActiveVehicleId,
  cleanActiveVehicleId,
  setEditableVehicleId,
  cleanActiveRouteStop,
  setAddPointMode,
  cleanAddPointMode,
  setAddStopMode,
  setStopSearchQuery,
  cleanAddStopMode,
  toggleEditableTimeMode,
  openPopup,
} from 'modules/ui/actions';

import { openModal } from 'modules/ui/actions';

import Vehicle from './Vehicle';
import { deleteVehicle as deleteLogisticsVehicle } from 'modules/fleet/actions';

const mapStateToProps = createImmutableSelector(
  editableBookingsSelector,
  activeVehicleIdsSelector,
  activeRouteStopUidSelector,
  addPointModeSelector,
  addStopModeSelector,
  filteredStopsSelector,
  stopSearchQuerySelector,
  commuteOfferIsReadOnlySelector,
  canManageVehiclesSelector,
  commuteOfferLoadedSimulationSelector,
  isDeliveryLayoutSelector,
  isStaffSelector,
  isSuperuserSelector,
  currentUserConfigSelector,
  currentProjectConfigSelector,
  capacityFunctorSelector,
  operationStatisticsSelector,
  commuteOfferCurrentDataSelector,
  driversSelector,
  (
    editableBooking,
    activeVehicleIds,
    activeRouteStopUid,
    pointEditing,
    addStopMode,
    filteredStops,
    stopSearchQuery,
    isReadOnly,
    canManageVehicles,
    simulation,
    isDeliveryLayout,
    isStaff,
    isSuperuser,
    currentUserConfig,
    projectConfig,
    capacityFunctor,
    operationStatistics,
    currentCommuteOffer,
    drivers
  ) => ({
    editableBooking,
    activeVehicleIds,
    activeRouteStopUid,
    pointEditing,
    addStopMode,
    filteredStops,
    stopSearchQuery,
    isReadOnly,
    canManageVehicles,
    simulation,
    isDeliveryLayout,
    isStaff,
    isSuperuser,
    currentUserConfig,
    projectConfig,
    capacityFunctor,
    operationStatistics,
    currentCommuteOffer,
    drivers,
  })
);

const mapDispatchToProps = {
  setActiveVehicleId,
  cleanActiveVehicleId,
  setEditableVehicleId,
  clonedVehicle,
  deleteVehicle,
  addPointToRoute,
  cleanActiveRouteStop,
  setAddPointMode,
  cleanAddPointMode,
  setAddStopMode,
  cleanAddStopMode,
  setStopSearchQuery,
  addStopToRoute,
  setVehicleStartTime,
  recalculateVehicleTime,
  toggleEditableTimeMode,
  openPopup,
  showVehicleUpdates,
  showVehicleSource,
  commuteOfferRequestUpdate,
  openModal,
  removeAllBookings,
  deleteLogisticsVehicle,
  lockVehicle,
  unlockVehicle,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(withTranslation()(Vehicle));

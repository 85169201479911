import { v4 as uuidv4 } from 'uuid';

import debug from 'utils/debug';
import { commuteOffer$RenameVehicles } from './RenameVehicles';

const D2 = debug('m:CommuteOffer');

// eslint-disable-next-line import/prefer-default-export
export const commuteOffer$RegenerateVehicleUIDs = commuteOffer =>
  D2.S.FUNCTION('RegenerateVehicleUIDs', { commuteOffer }, () => {
    const newUIDs = commuteOffer.stateless_api_request_data.vehicles.map(
      vehicle => [vehicle.agent_id, uuidv4()]
    );

    return commuteOffer$RenameVehicles(commuteOffer, new Map(newUIDs));
  });

import styled from 'styled-components';
import info from 'assets/info.svg';

export const InputWrapper = styled.div`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  ${({ width }) => (width ? `width:${width}` : 'width: 100%;')}
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Info = styled.img.attrs({ src: `${info}` })`
  width: 15px;
  height: 15px;
`;

export const Divider = styled.div`
  height: 1px;
  background: #97a0c0;
  width: 100%;
`;

import { styled } from 'baseui';

const Icon = styled('img', () => {
  return {
    width: '21px',
    margin: '0 10px 0 0',
  };
});
const PointerEventWrapper = styled('div', ({ $theme, disabled }) => {
  return {
    cursor: disabled ? 'not-allowed' : 'auto',
  };
});

const ButtonWrapper = styled('div', ({ $theme, disabled }) => {
  return {
    borderStyle: 'solid',
    borderColor: $theme.colors.inputBorder,
    borderWidth: '1px',
    padding: '15px',
    borderRadius: '10px',
    margin: '25px 0',
    pointerEvents: disabled ? 'none' : 'auto',
  };
});

const TitleWrapper = styled('div', () => {
  return {
    display: 'flex',
    flexDirection: 'row',
  };
});

const Title = styled('h1', ({ $theme }) => {
  return {
    ...$theme.typography.montserrat,
    fontSize: '18px',
    fontWeight: 600,
    color: '#fff',
  };
});

const Description = styled('p', ({ $theme }) => {
  return {
    margin: '5px 0 0 0',
    ...$theme.typography.panelTitle,
    color: $theme.colors.placeholder,
  };
});

const MethodBtn = ({ title, description, img, onClick, disabled }) => {
  return (
    <PointerEventWrapper disabled={disabled}>
      <ButtonWrapper onClick={onClick} disabled={disabled}>
        <TitleWrapper>
          <Icon src={img} />
          <Title>{title}</Title>
        </TitleWrapper>
        <Description>{description}</Description>
      </ButtonWrapper>
    </PointerEventWrapper>
  );
};

export default MethodBtn;

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  margin-bottom: 50px;
  min-height: calc(100vh - 250px);

  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    border: transparent 1px solid;
    background-color: transparent;
  }

  overflow: -moz-scrollbars-none;
`;

export default Container;

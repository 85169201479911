import styled from 'styled-components';

const Value = styled.div`
  display: inline-block;
  fontsize: 12px;
  fontweight: 500;
  color: #97a0c0;
  fontfamily: Montserrat;
  opacity: ${({ $isActive = true }) => ($isActive ? 1.0 : 0.2)};
  > span {
    color: ${({ $isOverLoad = false }) =>
      $isOverLoad ? '#F9C03A' : '#97a0c0'};
  }
`;

export default Value;

import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  width: 300px;
  height: 4px;
  background: #848c98;
  margin-top: 10px;
  margin-bottom: 20px;

  &:after {
    position: absolute;
    content: '';
    background: #f9603a;
    height: 100%;
    left: 0;
    width: ${({ progress }) => `calc(${progress} * 100%)`};
  }
`;

export default Container;

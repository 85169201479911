import React from 'utils/react';
import useTranslation from 'utils/react/useTranslation';

import Logo from 'components/Logo';
import Search from './Search';

import Container from './Container';
import HeaderWrap from './HeaderWrap';
import Title from './Title';
import List from './List';

const Panel = ({ size }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Logo />
      <HeaderWrap>
        <Title>{t('p.Geofences.Panels.Header.Title', { count: size })}</Title>
        <Search
          placeholder={t('p.Geofences.Panels.Search')}
          style={{ marginLeft: 0 }}
        />
      </HeaderWrap>
      <List />
    </Container>
  );
};

export default React.memo(Panel);

import withTranslation from 'utils/react/withTranslation';

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import {
  filteredBookingsSelector,
  activeBookingIndexSelector,
  commuteOfferIsFilteringEnabledSelector,
} from 'modules/commuteOffer/selectors';
import {
  currentProjectLayoutNameSelector,
  isDeliveryLayoutSelector,
} from 'modules/user/selectors';
import {
  isBookingsHiddenSelector,
  commuteOffersIsServiceDataSourceSelector,
} from 'modules/ui/selectors';

import { toggleBookingsHidden } from 'modules/ui/actions';

import Bookings from './Bookings';

const mapStateToProps = createImmutableSelector(
  filteredBookingsSelector,
  isBookingsHiddenSelector,
  activeBookingIndexSelector,
  currentProjectLayoutNameSelector,
  isDeliveryLayoutSelector,
  commuteOffersIsServiceDataSourceSelector,
  commuteOfferIsFilteringEnabledSelector,
  (
    bookings,
    isHidden,
    activeBookingIndex,
    layoutName,
    isDeliveryLayout,
    isServiceDataSource,
    isFilteringEnabled
  ) => ({
    bookings,
    isHidden,
    activeBookingIndex,
    layoutName,
    isDeliveryLayout,
    isServiceDataSource,
    isFilteringEnabled,
  })
);

const mapDispatchToProps = {
  toggleBookingsHidden,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation()(withConnect(Bookings));

import moment from 'moment-timezone';
import debug from 'utils/debug';
import { api$CommuteOffer as api } from 'api';
import {
  commuteOffer$MergeCommuteOffers,
  commuteOffer$DeleteEmptyVehicles,
  commuteOffer$RegenerateVehicleUIDs,
} from 'utils/CommuteOffer';
import weekdays from 'utils/weekdays';
import { commuteSchedule$GenerateOffersForDate } from './GenerateOffersForDate';
import { commuteSchedule$FixedScheduleFromOffers } from './FixedScheduleFromOffers';

const D2 = debug('m:CommuteSchedule:FixedScheduleGenerators');

const commuteSchedule$GenerateDailyVehicleCapacity = (capacity) => {
  return Object.keys(capacity).reduce((nodeCapacityMemo, key) => {
    const value = capacity[key];
    const weeklyCapacity = Object.keys(weekdays).reduce((memo, weekday) => {
      return {
        ...memo,
        [`${weekday}_${key}`]: value,
      };
    }, {});
    return { ...nodeCapacityMemo, ...weeklyCapacity };
  }, {});
};

// eslint-disable-next-line import/prefer-default-export
export const commuteSchedule$FixedScheduleGenerators = {
  'Every passenger takes a taxi': async (data, timezone, opts) => {
    D2.S.INFO('TaxiRides:Request', { data, timezone, opts });

    const options = opts || {};

    const {
      human_readable_uids,
      vehicle_passenger,
      vehicle_wheelchair,
      unassigned_only,
      engineSettingsBase,
      time_limit_ms,
      first_solution_strategy,
      max_slack,
      optimize_quantity,
      setProgress,
    } = options;

    const currentDate = moment('2020-03-30');
    const currentDay = currentDate.format('ddd').toLowerCase();

    const commuteOffersForPassengers =
      await commuteSchedule$GenerateOffersForDate(
        data.map(item => ({
          ...item,
          calculation_group: item.name,
          [currentDay]: true,
        })),
        currentDate,
        timezone,
        {
          areCalculationGroupsEnabled: true,
          areMutuallyExclusiveGroupsEnabled: false,
          areSpecialDemandsEnabled: false,
          human_readable_uids,
          vehicle_passenger,
          vehicle_wheelchair,
          unassigned_only,
          engineSettingsBase,
          max_slack,
          optimize_quantity,
          time_limit_ms,
          first_solution_strategy,
          maxTripDurationInMinutes: 90,
          morningDestinationTimeWindowDuration: 0,
          eveningDestinationTimeWindowDuration: 0,
        }
      );
    D2.S.INFO('TaxiRides:commuteOffersForPassengers', {
      commuteOffersForPassengers,
    });

    const calculatedOffersForPassengers = await api.scheduleCommuteOffers(
      commuteOffersForPassengers,
      {
        ignoreErrors: false,
        setProgress,
      }
    );
    D2.S.INFO('TaxiRides:calculatedOffersForPassengers', {
      calculatedOffersForPassengers,
    });

    const fixedSchedule = commuteSchedule$FixedScheduleFromOffers(
      calculatedOffersForPassengers,
      timezone
    );
    D2.S.INFO('TaxiRides:Success', {
      calculatedOffersForPassengers,
      fixedSchedule,
    });

    return {
      schedule: fixedSchedule,
      offers: calculatedOffersForPassengers,
    };
  },
  'Sample day with all passengers (predefined vehicles)': async (
    data,
    timezone,
    opts
  ) => {
    const M = 'SingleDayWithAllPassengers:PredefinedVehicles';

    D2.S.INFO(`${M}:Request`, {
      data,
      timezone,
      opts,
    });

    const options = opts || {};

    const {
      human_readable_uids,
      vehicle_passenger,
      vehicle_wheelchair,
      unassigned_only,
      engineSettingsBase,
      time_limit_ms,
      first_solution_strategy,
      max_slack,
      optimize_quantity,
      areCalculationGroupsEnabled,
      areMutuallyExclusiveGroupsEnabled,
      areSpecialDemandsEnabled,
      maxTripDurationInMinutes,
      morningDestinationTimeWindowDuration,
      eveningDestinationTimeWindowDuration,
      vehiclesSourceOffer,
    } = options;

    const generateDailyNodeDemands = true;
    const currentDate = moment('2020-03-30');
    const currentDay = currentDate.format('ddd').toLowerCase();

    const commuteOffersForPassengers =
      await commuteSchedule$GenerateOffersForDate(
        data.map(item => ({
          ...item,
          [currentDay]: true,
        })),
        currentDate,
        timezone,
        {
          areCalculationGroupsEnabled,
          areMutuallyExclusiveGroupsEnabled,
          areSpecialDemandsEnabled,
          human_readable_uids,
          vehicle_passenger,
          vehicle_wheelchair,
          unassigned_only,
          engineSettingsBase,
          max_slack,
          optimize_quantity,
          time_limit_ms,
          first_solution_strategy,
          maxTripDurationInMinutes,
          morningDestinationTimeWindowDuration,
          eveningDestinationTimeWindowDuration,
          generateDailyNodeDemands,
        }
      );
    D2.S.INFO(`${M}:commuteOffersForPassengers`, {
      commuteOffersForPassengers,
    });

    const sampleDayGeneratedOffersAllInOne = commuteOffer$MergeCommuteOffers(
      commuteOffersForPassengers
    );
    D2.S.INFO(`${M}:sampleDayGeneratedOffersAllInOne`, {
      sampleDayGeneratedOffersAllInOne,
    });

    const mutually_exclusive_groups = areMutuallyExclusiveGroupsEnabled
      ? [
          [
            ...data
              .reduce((acc, person) => {
                D2.S.INFO(`${M}:mutually_exclusive_groups:person`, {
                  person,
                });
                if (person.morning_dropoff_ts) {
                  acc.add(
                    moment
                      .tz(person.morning_dropoff_ts, 'LT', timezone)
                      .format('HH:mm')
                  );
                }
                if (person.evening_pickup_ts) {
                  acc.add(
                    moment
                      .tz(person.evening_pickup_ts, 'LT', timezone)
                      .format('HH:mm')
                  );
                }
                if (person.morning_mutually_exclusive_group) {
                  acc.add(person.morning_mutually_exclusive_group);
                }
                if (person.evening_mutually_exclusive_group) {
                  acc.add(person.evening_mutually_exclusive_group);
                }
                return acc;
              }, new Set())
              .keys(),
          ].filter(x => !!x),
        ]
      : undefined;
    D2.S.INFO(`${M}:mutually_exclusive_groups`, {
      mutually_exclusive_groups,
    });

    const sampleDayGeneratedOffer = {
      ...sampleDayGeneratedOffersAllInOne,
      name: 'Day',
      stateless_api_request_data: {
        ...sampleDayGeneratedOffersAllInOne.stateless_api_request_data,
        current_time: `${currentDate.format('YYYY-MM-DD')}T${moment
          .tz('00:00 AM', 'LT', timezone)
          .format('HH:mm:ssZ')}`,
        engine_settings: {
          ...sampleDayGeneratedOffersAllInOne.stateless_api_request_data
            .engine_settings,
          model_parameters: {
            ...sampleDayGeneratedOffersAllInOne.stateless_api_request_data
              .engine_settings.model_parameters,
            mutually_exclusive_groups,
          },
        },
        vehicles: commuteOffer$RegenerateVehicleUIDs(
          vehiclesSourceOffer
        ).stateless_api_request_data.vehicles.map((vehicle) => {
          const start_time =
            vehicle.start_time &&
            `${currentDate.format('YYYY-MM-DD')}T${moment(
              vehicle.start_time
            ).format('HH:mm:ssZ')}`;
          const end_time =
            vehicle.end_time &&
            `${currentDate.format('YYYY-MM-DD')}T${moment(
              vehicle.end_time
            ).format('HH:mm:ssZ')}`;
          const capacity = generateDailyNodeDemands
            ? commuteSchedule$GenerateDailyVehicleCapacity(vehicle.capacity)
            : {
                ...vehicle.capacity,
                passenger: vehicle.capacity.passenger * 2,
                wheelchair: vehicle.capacity.wheelchair * 2,
              };
          return {
            ...vehicle,
            start_time,
            end_time,
            capacity,
          };
        }),
      },
    };
    D2.S.INFO(`${M}:sampleDayGeneratedOffer`, {
      currentDate,
      sampleDayGeneratedOffer,
    });

    const sampleDayCalculatedOffer = await api.scheduleCommuteOffer(
      sampleDayGeneratedOffer
    );
    D2.S.INFO(`${M}:sampleDayGeneratedOffer`, {
      sampleDayGeneratedOffer,
    });

    const deleteEmptyVehiclesEnabled = false;

    const deleteEmptyVehicles = deleteEmptyVehiclesEnabled
      ? commuteOffer$DeleteEmptyVehicles
      : x => x;

    const calculatedOffersForPassengers = deleteEmptyVehicles([
      // morningCalculatedOffer,
      // eveningCalculatedOffer,
      sampleDayCalculatedOffer,
    ]);
    D2.S.INFO(`${M}:calculatedOffersForPassengers`, {
      calculatedOffersForPassengers,
    });

    const fixedSchedule = await commuteSchedule$FixedScheduleFromOffers(
      calculatedOffersForPassengers,
      timezone
    );
    D2.S.INFO(`${M}:Success`, {
      fixedSchedule,
    });

    return {
      schedule: fixedSchedule,
      offers: calculatedOffersForPassengers,
    };
  },
  'Sample day with all passengers (autogenerated vehicles)': async (
    data,
    timezone,
    opts
  ) => {
    D2.S.INFO('SingleDayWithAllPassengersDynamicVehicles:Request', {
      data,
      timezone,
      opts,
    });

    const options = opts || {};

    const {
      human_readable_uids,
      vehicle_passenger,
      vehicle_wheelchair,
      unassigned_only,
      engineSettingsBase,
      time_limit_ms,
      first_solution_strategy,
      max_slack,
      optimize_quantity,
      setProgress,
      areCalculationGroupsEnabled,
      areMutuallyExclusiveGroupsEnabled,
      areSpecialDemandsEnabled,
      maxTripDurationInMinutes,
    } = options;

    const currentDate = moment();
    const currentDay = currentDate.format('ddd').toLowerCase();

    const commuteOffersForPassengers =
      await commuteSchedule$GenerateOffersForDate(
        data.map(item => ({
          ...item,
          [currentDay]: true,
        })),
        currentDate,
        timezone,
        {
          areCalculationGroupsEnabled,
          areMutuallyExclusiveGroupsEnabled,
          areSpecialDemandsEnabled,
          human_readable_uids,
          vehicle_passenger,
          vehicle_wheelchair,
          unassigned_only,
          engineSettingsBase,
          max_slack,
          optimize_quantity,
          time_limit_ms,
          first_solution_strategy,
          maxTripDurationInMinutes,
          morningDestinationTimeWindowDuration: 0,
          eveningDestinationTimeWindowDuration: 0,
        }
      );
    D2.S.INFO('SingleDayWithAllPassengers:commuteOffersForPassengers', {
      commuteOffersForPassengers,
    });

    const calculatedOffersForPassengers = await api.scheduleCommuteOffers(
      commuteOffersForPassengers,
      {
        ignoreErrors: false,
        setProgress,
      }
    );
    D2.S.INFO('SingleDayWithAllPassengers:calculatedOffersForPassengers', {
      calculatedOffersForPassengers,
    });

    const fixedSchedule = await commuteSchedule$FixedScheduleFromOffers(
      calculatedOffersForPassengers,
      timezone
    );
    D2.S.INFO('SingleDayWithAllPassengers:Success', {
      fixedSchedule,
    });

    return {
      schedule: fixedSchedule,
      offers: calculatedOffersForPassengers,
    };
  },
  Disabled: async () => {
    D2.S.INFO('Disabled');

    return null;
  },
};

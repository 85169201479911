import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { datasetDataSelector } from 'modules/datasets/selectors';

import { activeLayerSelector, layerStyleSelector } from 'modules/ui/selectors';

import { datasetViewportSelector } from 'modules/maps/selectors';

import { currentProjectIdSelector } from 'modules/user/selectors';
import { changeViewportDataset } from 'modules/maps/actions';

import Map from './Map';

const mapStateToProps = createImmutableSelector(
  datasetDataSelector,
  activeLayerSelector,
  layerStyleSelector,
  datasetViewportSelector,
  currentProjectIdSelector,
  (data, activeLayer, layerStyle, viewport, projectId) => ({
    data,
    activeLayer,
    layerStyle,
    viewport,
    projectId,
  })
);

const mapDispatchToProps = {
  onViewportChange: changeViewportDataset,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Map);

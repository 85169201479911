import React from 'utils/react';

import Header from 'components/Header';
import Layout from 'components/Layout';

import Container from './Container';
import Panel from './Panel';
import Table from './Table';

const ErrorsReport = props => (
  <Layout hasBackground>
    <Container>
      <Header />
      <Panel {...props} />
      <Table {...props} />
    </Container>
  </Layout>
);

export default ErrorsReport;

import styled from 'styled-components';

export default styled.div`
  display: flex;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 80px;
  height: 36px;
  border-radius: 10px;
  background: rgb(40, 45, 55);

  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;

  :hover {
    background: #333741;
  }
`;

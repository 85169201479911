import { api$CommuteOffer as api, api$Masstransit } from 'api';

import moment from 'moment-timezone';
import debug from 'utils/debug';
// import weekdays from 'utils/weekdays';

import { removeInternalFieldsFromObject } from 'utils';

import {
  silverayCsvToJson,
  silverayNormalizeData,
} from 'utils/CommuteOffer/silveray';

import { makeScheduleDates, sleep } from 'utils/CommuteSchedule/utils';
import {
  //  commuteOffer$DeleteEmptyVehicles,
  commuteOffer$MergeCommuteOffers,
  //  commuteOffer$RegenerateVehicleUIDs,
  normalizeCommuteOffer,
} from 'utils/CommuteOffer';
import {
  //  commuteSchedule$GenerateOffersForDate,
  commuteSchedule$FixedScheduleGenerators,
  //  commuteSchedule$FixedScheduleFromOffers
} from 'utils/CommuteSchedule';

import commuteSchedule$ResolveBuildingIds from 'utils/CommuteSchedule/ResolveBuildingIds';
import commuteSchedule$ResolveBuildingCodes from 'utils/CommuteSchedule/ResolveBuildingCodes';
import commuteSchedule$ResolveZipCodes from 'utils/CommuteSchedule/ResolveZipCodes';
import commuteSchedule$ResolveAddresses from 'utils/CommuteSchedule/ResolveAddresses';

const D2 = debug('m:CommuteSchedule:ImportPassengersSchedule');

const markResultVehiclesAsReadOnly = true;

// eslint-disable-next-line import/prefer-default-export
export const commuteSchedule$ImportPassengersSchedule = async (
  currentOffer,
  data,
  params,
  opts
) => {
  D2.S.INFO('Request', { currentOffer, data, params, opts });

  const savedTimezone = moment.tz.guess();

  try {
    const originalPassengersData =
      currentOffer.stateless_api_request_data.inbound &&
      currentOffer.stateless_api_request_data.inbound.schedule
        ? currentOffer.stateless_api_request_data.inbound.schedule
        : {};
    D2.S.INFO('originalPassengersData', { originalPassengersData });

    const timezone = params.source_file_timezone.value;
    moment.tz.setDefault(timezone);

    // const fromDate = moment('2020-03-16');
    const fromDate = moment(new Date(params.from_date).setHours(0, 0, 0, 0));
    D2.S.INFO('fromDate', { fromDate });

    // const tillDate = moment('2020-03-16');
    const tillDate = moment(new Date(params.till_date).setHours(0, 0, 0, 0));
    D2.S.INFO('tillDate', { tillDate });

    const scheduleDates = [...makeScheduleDates(fromDate, tillDate)];
    D2.S.INFO('scheduleDates', { scheduleDates });

    const majorStepCount = 6;
    const majorStepSize = 1 / majorStepCount;

    let currentMajorStep = 0;

    const options = opts || {};

    const currentProject = options.currentProject
      ? options.currentProject.toJS()
      : null;

    const projects = options.projects ? options.projects.toJS() : [];

    const logsProject = projects.find(
      project => project.name === 'urbica_silveray_testdev'
    );

    D2.S.INFO('projects', { projects, currentProject, logsProject });

    let currentProgress = {
      status: null,
      progress: null,
    };

    const setProgress = async (status, progress) => {
      const newProgress = {
        status,
        progress: Math.max(
          0.01,
          Math.min(
            progress / majorStepCount + currentMajorStep * majorStepSize,
            1
          )
        ),
      };
      D2.S.INFO('setProgress', { status, progress, newProgress });
      if (
        currentProgress.status !== newProgress.status ||
        currentProgress.progress !== newProgress.progress
      ) {
        if (newProgress.progress < currentProgress.progress) {
          return;
          // throw new Error(
          //   `${status}: Progress error: ${newProgress.progress} < ${currentProgress.progress}`
          // );
        }
        currentProgress = newProgress;
        // console.log(`${status} (${Math.floor(newProgress.progress * 100)}%)`);
        if (options.setProgress) {
          await options.setProgress(newProgress.status, newProgress.progress);
          await sleep(100);
        }
      }
    };

    const nextMajorStep = async (status) => {
      currentMajorStep += 1;
      return setProgress(status, 0);
    };

    const ignoreErrors = params.ignore_errors.value;
    const human_readable_uids = params.human_readable_uids.value;
    const fixedScheduleGeneratorType = params.fixedScheduleGeneratorType.value;
    const areCalculationGroupsEnabled =
      params.areCalculationGroupsEnabled.value;
    const areMutuallyExclusiveGroupsEnabled =
      params.areMutuallyExclusiveGroupsEnabled.value;
    const areSpecialDemandsEnabled = params.areSpecialDemandsEnabled.value;
    const unassigned_only =
      areSpecialDemandsEnabled && params.unassigned_only.value;
    const vehiclesSource = params.vehiclesSource.value;

    // const max_slack = parseInt(params.max_slack, 10);
    // const max_slack_ph = parseInt(params.max_slack_ph, 10);

    const optimize_quantity = params.optimize_quantity.value;

    const centerServiceTime = parseInt(params.center_service_time, 10);
    const homeServiceTime = parseInt(params.home_service_time, 10);

    const time_limit_ms = params.time_limit_ms.value;
    const first_solution_strategy = params.first_solution_strategy.value;

    const fixedScheduleMaxSlack = parseInt(params.fixedScheduleMaxSlack, 10);
    const timewindowBeforeScheduledTime = parseInt(
      params.timewindowBeforeScheduledTime,
      10
    );
    const timewindowAfterScheduledTime = parseInt(
      params.timewindowAfterScheduledTime,
      10
    );
    const morningDestinationTimeWindowDuration = parseInt(
      params.morningDestinationTimeWindowDuration,
      10
    );
    const eveningDestinationTimeWindowDuration = parseInt(
      params.eveningDestinationTimeWindowDuration,
      10
    );

    const maxTripDurationInSeconds = parseInt(
      params.maxTripDurationInSeconds,
      10
    );
    const maxTripDurationInMinutes = Math.round(maxTripDurationInSeconds / 60);

    const vehicle_passenger = parseInt(params.vehicle_passenger, 10);
    if (!vehicle_passenger) {
      throw new Error('Vehicle capacity cannot be zero');
    }
    const vehicle_wheelchair = parseInt(params.vehicle_wheelchair, 10);
    if (vehicle_wheelchair > vehicle_passenger) {
      throw new Error(
        'Number of wheelchair passengers must be less than a vehicle capacity'
      );
    }

    await setProgress('Downloading dependencies', 0);

    const vehiclesSourceOffer = await api.getCommuteOffer(vehiclesSource);
    D2.S.INFO('vehiclesSourceOffer', { vehiclesSourceOffer });

    const engineSettingsBase = JSON.parse(
      JSON.stringify(
        vehiclesSourceOffer.stateless_api_request_data.engine_settings
      )
    );
    D2.S.INFO('engineSettingsBase', { engineSettingsBase });

    await nextMajorStep('Parsing');

    const jsonData = silverayCsvToJson(data);
    D2.S.INFO('jsonData', jsonData);

    const normalizedData0 = await silverayNormalizeData(jsonData.objects, {
      ignoreErrors,
      timezone,
      centerServiceTime,
      homeServiceTime,
    });
    D2.S.INFO('normalizedData0', { jsonData, normalizedData0 });

    const normalizedData1 = normalizedData0.map((record) => {
      const calculation_group = areCalculationGroupsEnabled
        ? record.calculation_group
        : 'None';
      const mutually_exclusive_group = areMutuallyExclusiveGroupsEnabled
        ? record.mutually_exclusive_group
        : undefined;
      const special_demand = areSpecialDemandsEnabled
        ? record.special_demand
        : undefined;
      return {
        ...record,
        calculation_group,
        mutually_exclusive_group,
        special_demand,
      };
    });
    D2.S.INFO('normalizedData1', { jsonData, normalizedData1 });

    const currentPassengersData = normalizedData1.map((currentRecord) => {
      const originalRecord = originalPassengersData[currentRecord.name];
      if (originalRecord) {
        const morningPreferredPickupTimeChanged =
          originalRecord.morning_pickup_from_ts !==
            currentRecord.morning_pickup_from_ts ||
          originalRecord.morning_pickup_till_ts !==
            currentRecord.morning_pickup_till_ts;
        const eveningPreferredDropoffTimeChanged =
          originalRecord.evening_dropoff_from_ts !==
            currentRecord.evening_dropoff_from_ts ||
          originalRecord.evening_dropoff_till_ts !==
            currentRecord.evening_dropoff_till_ts;
        return {
          ...currentRecord,
          morning_pickup_scheduled_open_time: !morningPreferredPickupTimeChanged
            ? originalRecord.morning_pickup_scheduled_open_time
            : '',
          morning_pickup_scheduled_close_time:
            !morningPreferredPickupTimeChanged
              ? originalRecord.morning_pickup_scheduled_close_time
              : '',
          morning_pickup_scheduled_time: !morningPreferredPickupTimeChanged
            ? originalRecord.morning_pickup_scheduled_time
            : '',
          evening_dropoff_scheduled_open_time:
            !eveningPreferredDropoffTimeChanged
              ? originalRecord.evening_dropoff_scheduled_open_time
              : '',
          evening_dropoff_scheduled_close_time:
            !eveningPreferredDropoffTimeChanged
              ? originalRecord.evening_dropoff_scheduled_close_time
              : '',
          evening_dropoff_scheduled_time: !eveningPreferredDropoffTimeChanged
            ? originalRecord.evening_dropoff_scheduled_time
            : '',
        };
      }
      return currentRecord;
    });
    D2.S.INFO('currentPassengersData', {
      normalizedData1,
      currentPassengersData,
    });

    const specialDemandSet = currentPassengersData.reduce((acc, person) => {
      if (person.special_demand) {
        acc.add(person.special_demand);
      }
      return acc;
    }, new Set());
    const specialDemands = [...specialDemandSet.values()];
    D2.S.INFO('specialDemands', {
      specialDemands,
    });

    await nextMajorStep('Resolving locations');

    const dataWithBuildingsByBuildingIds =
      await commuteSchedule$ResolveBuildingIds(currentPassengersData, {
        setProgress: async () => {},
        ignoreErrors,
      });
    D2.S.INFO('dataWithBuildingsByBuildingIds', {
      dataWithBuildingsByBuildingIds,
    });

    const dataWithBuildingsByBuildingCodes =
      await commuteSchedule$ResolveBuildingCodes(
        dataWithBuildingsByBuildingIds,
        {
          setProgress: async () => {},
          ignoreErrors,
        }
      );
    D2.S.INFO('dataWithBuildingsByBuildingCodes', {
      dataWithBuildingsByBuildingCodes,
    });

    const dataWithBuildingsByAddresses = await commuteSchedule$ResolveAddresses(
      dataWithBuildingsByBuildingCodes,
      {
        setProgress: async () => {},
        ignoreErrors,
      }
    );
    D2.S.INFO('dataWithBuildingsByAddresses', { dataWithBuildingsByAddresses });

    const dataWithBuildingsByZipCodes = await commuteSchedule$ResolveZipCodes(
      dataWithBuildingsByAddresses,
      {
        setProgress: async () => {},
        ignoreErrors,
      }
    );
    D2.S.INFO('dataWithBuildingsByZipCodes', { dataWithBuildingsByZipCodes });

    const dataWithBuildings = dataWithBuildingsByZipCodes;
    D2.S.INFO('dataWithBuildings', { dataWithBuildings });

    const coordinates = dataWithBuildings.reduce((acc, record) => {
      D2.S.INFO('coordinates:record', { record });
      record.home.$buildings.forEach((item) => {
        acc.push(item.coordinates);
      });
      record.office.$buildings.forEach((item) => {
        acc.push(item.coordinates);
      });
      return acc;
    }, []);
    D2.S.INFO('coordinates', { coordinates });

    await nextMajorStep('Searching for stops');

    const stopsByCoordinates = await api$Masstransit.findStopsByCoordinates(
      coordinates,
      {
        ignoreErrors,
        setProgress: async (progress) => {
          await setProgress('Searching for stops', progress);
        },
        stopTypes: 'building',
        // stopTypes: [
        //   'bus_stop',
        //   'mrt_station',
        //   'lrt_station',
        //   'virtual_stop',
        //   'lobby'
        // ],
        // transitstopSets: [90],
        // searchRadius: 1000
      }
    );
    D2.S.INFO('stopsByCoordinates', { stopsByCoordinates });

    const dataWithStops = dataWithBuildings.map((record) => {
      const resolveLocationBuildings = location =>
        location.$buildings.map((building) => {
          const lon = building.coordinates[0];
          const lat = building.coordinates[1];
          const ident = JSON.stringify({ lon, lat });
          const nearestPoint = stopsByCoordinates[ident].result.objects[0];
          return {
            ...building,
            nearestPoint,
          };
        });
      return {
        ...record,
        home: {
          ...record.home,
          $buildings: resolveLocationBuildings(record.home),
        },
        office: {
          ...record.office,
          $buildings: resolveLocationBuildings(record.office),
        },
      };
    });
    D2.S.INFO('dataWithStops', { dataWithStops });

    const resultData = dataWithStops;
    D2.S.INFO('resultData', { resultData });

    const currentExternalSource =
      currentOffer.stateless_api_request_data.inbound &&
      currentOffer.stateless_api_request_data.inbound.external &&
      currentOffer.stateless_api_request_data.inbound.external.source;

    const externalOffer =
      currentExternalSource || JSON.parse(JSON.stringify(currentOffer));
    D2.S.INFO('importBookings:externalOffer', { externalOffer });

    if (externalOffer.result.rejected_bookings.length) {
      throw new Error(
        'External Commute Offer cannot contain any rejected bookings'
      );
    }

    const dataWithoutErrors = resultData.filter(
      record => record.$errors.length === 0
      // && !!Object.keys(weekdays).find(x => record[x])
    );
    D2.S.INFO('importBookings:dataWithoutErrors', { dataWithoutErrors });

    await nextMajorStep('Calculating');

    const fixedScheduleGenerator =
      commuteSchedule$FixedScheduleGenerators[fixedScheduleGeneratorType] ||
      (async () => {
        return null;
      });

    const dataForScheduleRecalculation = dataWithoutErrors;
    // dataWithoutErrors.filter(
    //   currentRecord =>
    //     currentRecord.morning_pickup_scheduled_open_time === '' ||
    //     currentRecord.morning_pickup_scheduled_close_time === '' ||
    //     currentRecord.evening_dropoff_scheduled_open_time === '' ||
    //     currentRecord.evening_dropoff_scheduled_close_time === ''
    // );
    // D2.S.INFO('dataForScheduleRecalculation', {
    //   dataForScheduleRecalculation
    // });

    const fixedSchedule = dataForScheduleRecalculation.length
      ? await fixedScheduleGenerator(dataForScheduleRecalculation, timezone, {
          human_readable_uids,
          vehicle_passenger,
          vehicle_wheelchair,
          unassigned_only,
          engineSettingsBase,
          max_slack: fixedScheduleMaxSlack,
          optimize_quantity,
          time_limit_ms,
          first_solution_strategy,
          areCalculationGroupsEnabled,
          areMutuallyExclusiveGroupsEnabled,
          areSpecialDemandsEnabled,
          maxTripDurationInMinutes,
          morningDestinationTimeWindowDuration,
          eveningDestinationTimeWindowDuration,
          vehiclesSourceOffer,
          setProgress: async (progress) => {
            await setProgress('Calculating', progress);
          },
        })
      : null;
    D2.S.INFO('fixedSchedule', {
      fixedSchedule,
    });

    const newPassengersData = fixedSchedule
      ? dataWithoutErrors.map((currentRecord) => {
          const scheduleRecord = fixedSchedule.schedule[currentRecord.name];
          D2.S.INFO('newPassengersData:currentRecord', {
            currentRecord,
            scheduleRecord,
          });
          if (
            scheduleRecord // &&
            // (currentRecord.morning_pickup_scheduled_open_time === '' ||
            //   currentRecord.morning_pickup_scheduled_close_time === '' ||
            //   currentRecord.evening_dropoff_scheduled_open_time === '' ||
            //   currentRecord.evening_dropoff_scheduled_close_time === '')
          ) {
            // const FIVE_MUNUTES_UNIXTIME = 1000 * 60 * 5;

            const morningRecord = !scheduleRecord.morning_pickup_ts
              ? {}
              : (() => {
                  const morning_pickup_ts = moment.tz(
                    scheduleRecord.morning_pickup_ts,
                    'LT',
                    timezone
                  );
                  // const morning_pickup_ts = moment(
                  //   Math.round(
                  //     moment
                  //       .tz(scheduleRecord.morning_pickup_ts, 'LT', timezone)
                  //       .valueOf() / FIVE_MUNUTES_UNIXTIME
                  //   ) * FIVE_MUNUTES_UNIXTIME
                  // );
                  const morning_dropoff_ts = moment.tz(
                    currentRecord.morning_dropoff_ts,
                    'LT',
                    timezone
                  );

                  const morningOpenTimes0 = [
                    morning_dropoff_ts
                      .clone()
                      .subtract(maxTripDurationInMinutes, 'minutes'),
                    morning_pickup_ts
                      .clone()
                      .subtract(timewindowBeforeScheduledTime, 'seconds'),
                  ];

                  const morningOpenTimes1 =
                    currentRecord.morning_pickup_from_ts !== ''
                      ? [
                          ...morningOpenTimes0,
                          moment.tz(
                            currentRecord.morning_pickup_from_ts,
                            'LT',
                            timezone
                          ),
                        ]
                      : morningOpenTimes0;

                  const morning_pickup_scheduled_open_time = morningOpenTimes1
                    .map(x => [x.valueOf(), x.format('HH:mm'), x])
                    .sort((a, b) => b[0] - a[0])[0][2]
                    .format('hh:mm A');

                  const morningCloseTimes0 = [
                    morning_dropoff_ts.clone().subtract(5, 'minutes'),
                    morning_pickup_ts
                      .clone()
                      .add(timewindowAfterScheduledTime, 'seconds'),
                  ];

                  const morningCloseTimes1 =
                    currentRecord.morning_pickup_till_ts !== ''
                      ? [
                          ...morningCloseTimes0,
                          moment.tz(
                            currentRecord.morning_pickup_till_ts,
                            'LT',
                            timezone
                          ),
                        ]
                      : morningCloseTimes0;

                  const morning_pickup_scheduled_close_time = morningCloseTimes1
                    .map(x => [x.valueOf(), x.format('HH:mm'), x])
                    .sort((a, b) => a[0] - b[0])[0][2]
                    .format('hh:mm A');

                  const $morning_pickup_scheduled_duration =
                    moment
                      .tz(morning_pickup_scheduled_close_time, 'LT', timezone)
                      .valueOf() -
                    moment
                      .tz(morning_pickup_scheduled_open_time, 'LT', timezone)
                      .valueOf();

                  if ($morning_pickup_scheduled_duration < 0) {
                    throw Error(
                      // eslint-disable-next-line
                      `Morning time-window error: ${morning_pickup_scheduled_open_time} must be before ${morning_pickup_scheduled_close_time}`
                    );
                  }

                  return {
                    $morningOpenTimes: morningOpenTimes1.map(x =>
                      x.format('hh:mm A')
                    ),
                    $morningCloseTimes: morningCloseTimes1.map(x =>
                      x.format('hh:mm A')
                    ),
                    $original_morning_pickup_ts:
                      scheduleRecord.morning_pickup_ts,
                    $rounded_morning_pickup_ts:
                      morning_pickup_ts.format('hh:mm A'),
                    $morning_pickup_scheduled_duration,
                    morning_pickup_scheduled_open_time,
                    morning_pickup_scheduled_close_time,
                    morning_pickup_scheduled_time:
                      morning_pickup_ts.format('hh:mm A'),
                  };
                })();
            D2.S.INFO('newPassengersData:morningRecord', {
              morningRecord,
            });

            const eveningRecord = !scheduleRecord.evening_dropoff_ts
              ? {}
              : (() => {
                  const evening_pickup_ts = moment.tz(
                    currentRecord.evening_pickup_ts,
                    'LT',
                    timezone
                  );
                  const evening_dropoff_ts = moment.tz(
                    scheduleRecord.evening_dropoff_ts,
                    'LT',
                    timezone
                  );
                  // const evening_dropoff_ts = moment(
                  //   Math.round(
                  //     moment
                  //       .tz(scheduleRecord.evening_dropoff_ts, 'LT', timezone)
                  //       .valueOf() / FIVE_MUNUTES_UNIXTIME
                  //   ) * FIVE_MUNUTES_UNIXTIME
                  // );

                  const eveningOpenTimes0 = [
                    evening_pickup_ts.clone().add(5, 'minutes'),
                    evening_dropoff_ts
                      .clone()
                      .subtract(timewindowBeforeScheduledTime, 'seconds'),
                  ];

                  const eveningOpenTimes1 =
                    currentRecord.evening_dropoff_from_ts !== ''
                      ? [
                          ...eveningOpenTimes0,
                          moment.tz(
                            currentRecord.evening_dropoff_from_ts,
                            'LT',
                            timezone
                          ),
                        ]
                      : eveningOpenTimes0;

                  const evening_dropoff_scheduled_open_time = eveningOpenTimes1
                    .map(x => [x.valueOf(), x.format('HH:mm'), x])
                    .sort((a, b) => b[0] - a[0])[0][2]
                    .format('hh:mm A');

                  const eveningCloseTimes0 = [
                    evening_pickup_ts
                      .clone()
                      .add(maxTripDurationInMinutes, 'minutes'),
                    evening_dropoff_ts
                      .clone()
                      .add(timewindowAfterScheduledTime, 'seconds'),
                  ];

                  const eveningCloseTimes1 =
                    currentRecord.evening_dropoff_till_ts !== ''
                      ? [
                          ...eveningCloseTimes0,
                          moment.tz(
                            currentRecord.evening_dropoff_till_ts,
                            'LT',
                            timezone
                          ),
                        ]
                      : eveningCloseTimes0;

                  const evening_dropoff_scheduled_close_time =
                    eveningCloseTimes1
                      .map(x => [x.valueOf(), x.format('HH:mm'), x])
                      .sort((a, b) => a[0] - b[0])[0][2]
                      .format('hh:mm A');

                  const $evening_dropoff_scheduled_duration =
                    moment
                      .tz(evening_dropoff_scheduled_close_time, 'LT', timezone)
                      .valueOf() -
                    moment
                      .tz(evening_dropoff_scheduled_open_time, 'LT', timezone)
                      .valueOf();

                  if ($evening_dropoff_scheduled_duration < 0) {
                    throw Error(
                      // eslint-disable-next-line
                      `Evening time-window error: ${evening_dropoff_scheduled_open_time} must be before ${evening_dropoff_scheduled_close_time}`
                    );
                  }

                  return {
                    $eveningOpenTimes: eveningOpenTimes1.map(x =>
                      x.format('hh:mm A')
                    ),
                    $eveningCloseTimes: eveningCloseTimes1.map(x =>
                      x.format('hh:mm A')
                    ),
                    $original_evening_dropoff_ts:
                      scheduleRecord.evening_dropoff_ts,
                    $rounded_evening_dropoff_ts:
                      evening_dropoff_ts.format('hh:mm A'),
                    $evening_dropoff_scheduled_duration,
                    evening_dropoff_scheduled_open_time,
                    evening_dropoff_scheduled_close_time,
                    evening_dropoff_scheduled_time:
                      evening_dropoff_ts.format('hh:mm A'),
                  };
                })();
            D2.S.INFO('newPassengersData:eveningRecord', {
              eveningRecord,
            });

            return {
              ...currentRecord,
              ...morningRecord,
              ...eveningRecord,
            };
          }
          return currentRecord;
        })
      : dataWithoutErrors;
    D2.S.INFO('newPassengersData', {
      newPassengersData,
    });

    const currentOfferName = currentOffer.name || '<Empty>';

    if (logsProject) {
      await Promise.all(
        fixedSchedule
          ? fixedSchedule.offers.map(offer =>
              api.addCommuteOffer({
                ...offer,
                name: `${currentOfferName} - FixedSchedule - ${offer.name}`,
                project: logsProject.resource_uri,
              })
            )
          : []
      );
    }

    const fixedSchedule$Merged =
      fixedSchedule && fixedSchedule.offers
        ? commuteOffer$MergeCommuteOffers(fixedSchedule.offers)
        : null;

    if (logsProject) {
      await api.addCommuteOffer({
        ...fixedSchedule$Merged,
        name: `${currentOfferName} - FixedSchedule - All In One`,
        project: logsProject.resource_uri,
      });
    }

    const publicPassengerSchedule = newPassengersData.reduce(
      (publicPassengerScheduleAcc, record) => {
        return {
          ...publicPassengerScheduleAcc,
          [record.name]: removeInternalFieldsFromObject(record),
        };
      },
      {}
    );
    D2.S.INFO('publicPassengerSchedule', { publicPassengerSchedule });

    const scheduleSections = {
      schedule: publicPassengerSchedule,
      fixed_schedule: fixedSchedule,
    };
    D2.S.INFO('scheduleSections', { scheduleSections });

    const offersToMeMerged = fixedSchedule ? fixedSchedule.offers : [];
    const isRootReadonly = false;

    const mergedOffer = commuteOffer$MergeCommuteOffers(offersToMeMerged);
    D2.S.INFO('mergedOffer', { mergedOffer });

    const isMergedOfferEmpty = false;
    //  mergedOffer.result.assigned_bookings.length === 0;
    D2.S.INFO('isMergedOfferEmpty', { isMergedOfferEmpty });

    const generatedNewOffer = isMergedOfferEmpty
      ? await normalizeCommuteOffer(currentOffer)
      : await normalizeCommuteOffer({
          ...mergedOffer,
          result: {
            ...mergedOffer.result,
            readOnly: isRootReadonly,
          },
          stateless_api_request_data: {
            ...mergedOffer.stateless_api_request_data,
            vehicles: mergedOffer.stateless_api_request_data.vehicles.map(
              vehicle => ({
                ...vehicle,
                readOnly: markResultVehiclesAsReadOnly,
              })
            ),
            inbound: {
              version: 1,
              schedule: publicPassengerSchedule,
              $remarks: jsonData.remarks,
              vehicles: {
                source: vehiclesSource,
              },
              external: {
                source: externalOffer,
                result: [],
              },
              fixed_schedule: fixedSchedule,
              generation_parameters: params,
            },
            current_time: `${moment(fromDate).format('YYYY-MM-DD')}T${moment
              .tz('00:00 AM', 'LT', timezone)
              .format('HH:mm:ssZ')}`,
            type: 'CommuteSchedule',
          },
          tags: [],
          id: currentOffer.id,
          name: currentOffer.name,
          project: currentOffer.project,
          resource_uri: currentOffer.resource_uri,
        });
    D2.S.INFO('generatedNewOffer', { generatedNewOffer });

    if (generatedNewOffer.result.$errors.length > 0) {
      throw new Error('The generated offer contains errors');
    }

    await nextMajorStep('Optimizing');

    const secondPassOffer = {
      ...generatedNewOffer,
      stateless_api_request_data: {
        ...generatedNewOffer.stateless_api_request_data,
        nodes: generatedNewOffer.stateless_api_request_data.nodes.map(
          node => ({
            ...node,
            trip_cost: 0.01,
          })
        ),
        vehicles: generatedNewOffer.stateless_api_request_data.vehicles.map(
          (vehicle) => {
            const resultVehicle =
              generatedNewOffer.result.vehicles[vehicle.agent_id];
            return !resultVehicle
              ? vehicle
              : {
                  ...vehicle,
                  partial_route: resultVehicle.map(item => item.uid),
                };
          }
        ),
      },
    };

    const calculatedNewOffer = await api.scheduleCommuteOffer(secondPassOffer);
    D2.S.INFO('calculatedNewOffer', {
      calculatedNewOffer,
      secondPassOffer,
      generatedNewOffer,
    });

    await nextMajorStep('Completed');

    const newOffer = await normalizeCommuteOffer({
      ...generatedNewOffer,
      stateless_api_request_data: {
        ...generatedNewOffer.stateless_api_request_data,
      },
      result: {
        ...calculatedNewOffer.result,
      },
      id: currentOffer.id,
      name: currentOffer.name,
      project: currentOffer.project,
      resource_uri: currentOffer.resource_uri,
    });
    D2.S.INFO('newOffer', {
      newOffer,
      calculatedNewOffer,
      // generatedNewOffer
    });

    if (newOffer.result.$errors.length > 0) {
      throw new Error('The calculated offer contains errors');
    }

    D2.S.INFO('Success', { newOffer });
    moment.tz.setDefault(savedTimezone);
    return newOffer;
  } catch (error) {
    D2.S.INFO('Failure', { error });
    moment.tz.setDefault(savedTimezone);
    throw error;
  }
};

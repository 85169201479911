import React from 'utils/react';
import debug from 'utils/debug';

import { Formik } from 'formik';

import Input from 'components/Input';
import InputSelect from 'components/InputSelect';
import DateTimePicker from 'components/DateTimePicker';

import SubmitButton from 'components/SubmitButton';

import Row from './Row';
import ButtonsRow from './ButtonsRow';

const D2 = debug('c:ModalWindow:CommuteOfferVehicleEditor');

class CommuteOfferVehicleEditor extends React.PureComponent {
  state = {
    waiting: false,
  };

  componentDidMount = () => {
    D2.S.INFO('componentDidMount', { props: this.props });
  };

  handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  onAddVehicle = (values) => {
    D2.S.INFO('onAddVehicle', { props: this.props, values });

    const { data } = this.props;

    this.props.commuteOfferAddVehicleRequest(data, values);
  };

  onEditVehicle = (values) => {
    D2.S.INFO('onEditVehicle', { props: this.props, values });

    const { data, vehicle } = this.props;

    this.props.commuteOfferEditVehicleRequest(data, vehicle.agent_id, values);
  };

  onSubmit = (values) => {
    D2.S.INFO('onSubmit', { props: this.props, values });

    this.setState({ waiting: true });

    const { mode, isDisabled } = this.props;

    if (isDisabled) {
      return;
    }

    if (mode === 'new') {
      this.onAddVehicle(values);
    } else if (mode === 'edit') {
      this.onEditVehicle(values);
    }
  };

  render() {
    D2.S.INFO('render', { props: this.props, state: this.state });

    const { mode } = this.props;

    return (
      <Formik
        initialValues={{ ...this.props.initialValues }}
        onSubmit={this.onSubmit}
      >
        {(formikProps) => {
          const {
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            setFieldTouched,
          } = formikProps;

          const { waiting } = this.state;

          const isTimeVisible = mode === 'edit';

          return (
            <form
              onSubmit={handleSubmit}
              autoComplete='off'
              data-testid='CommuteOfferVehicleEditor-form'
            >
              <Row>
                <Input
                  name='id'
                  type='text'
                  label='Id'
                  value={values.id}
                  onKeyDown={this.handleKeyDown}
                  onChange={handleChange}
                  handleBlur={handleBlur}
                  autoComplete='off'
                  readonly
                />
              </Row>
              {isTimeVisible && values.start_time && (
                <Row>
                  <DateTimePicker
                    name='start_time'
                    label='Start time'
                    format='YYYY-MM-DDTHH:mmZ'
                    value={values.start_time}
                    onKeyDown={this.handleKeyDown}
                    onChange={setFieldValue}
                  />
                </Row>
              )}
              {isTimeVisible && values.end_time && (
                <Row>
                  <DateTimePicker
                    name='end_time'
                    label='End time'
                    format='YYYY-MM-DDTHH:mmZ'
                    value={values.end_time}
                    onKeyDown={this.handleKeyDown}
                    onChange={setFieldValue}
                  />
                </Row>
              )}
              <Row>
                <Input
                  name='capacity_passengers'
                  type='text'
                  label='Passengers'
                  value={values.capacity_passengers}
                  onKeyDown={this.handleKeyDown}
                  onChange={handleChange}
                  required
                />
                <Input
                  name='capacity_wheelchair'
                  type='text'
                  label='Wheelchairs'
                  value={values.capacity_wheelchairs}
                  onKeyDown={this.handleKeyDown}
                  onChange={handleChange}
                  required
                />
                <Input
                  name='capacity_stop'
                  type='text'
                  label='Stops'
                  value={values.capacity_stops}
                  onKeyDown={this.handleKeyDown}
                  onChange={handleChange}
                  required
                />
              </Row>
              {true && (
                <Row>
                  <Input
                    name='vehicle_color'
                    type='text'
                    label='Color'
                    value={values.vehicle_color}
                    onKeyDown={this.handleKeyDown}
                    onChange={handleChange}
                    required
                  />
                </Row>
              )}
              {true && (
                <Row>
                  <InputSelect
                    id='routing_engine_name'
                    name='routing_engine_name'
                    label='Routing engine'
                    options={[
                      { label: 'osrme', value: 'osrme' },
                      { label: 'osrm', value: 'osrm' },
                      { label: 'mapbox', value: 'mapbox' },
                      { label: 'asteria', value: 'asteria' },
                      { label: 'here', value: 'here' },
                    ]}
                    defaultValue={values.routing_engine_name}
                    onKeyDown={this.handleKeyDown}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                  <Input
                    name='road_network'
                    type='text'
                    label='Road network'
                    value={values.road_network}
                    onKeyDown={this.handleKeyDown}
                    onChange={handleChange}
                    required
                  />
                </Row>
              )}
              <Row>
                <InputSelect
                  id='readOnly'
                  name='readOnly'
                  label='Is read-only'
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  defaultValue={values.readOnly}
                  onKeyDown={this.handleKeyDown}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </Row>
              <ButtonsRow>
                <SubmitButton
                  type='submit'
                  disabled={waiting}
                  style={{ width: 320 }}
                >
                  {waiting
                    ? this.props.submittingCaption || 'Saving...'
                    : this.props.submitCaption || 'Save'}
                </SubmitButton>
              </ButtonsRow>
            </form>
          );
        }}
      </Formik>
    );
  }
}

export default CommuteOfferVehicleEditor;

import React from 'utils/react';
import styled from 'styled-components';

const NodeType = styled.div`
  opacity: 0.5;
  text-transform: uppercase;
  margin-rigth: 5px;
`;

export default ({ children, ...otherProps }) => (
  <NodeType {...otherProps}>
    {children}
    :&nbsp;
  </NodeType>
);

import React from 'utils/react';
import useTranslation from 'utils/react/useTranslation';
import { styled } from 'baseui';
import debug from 'utils/debug';
import displayNames from 'utils/display-names';
import moment from 'moment-timezone';
import { getRouteCoordinates } from 'utils/CommuteOffer';
import Container from './Container';
import Booking from './Booking';
import Open from './Open';
import Row from './Row';
import Wrap from './Wrap';
import PickupDropoffCard from '../PickupDropoffCard';
import OrderStatus from 'components/OrderStatus';

const D2 = debug('p:Logistics:Panels:Vehicles:Vehicle:Route:Stop');

export default (props) => {
  const { t, i18n } = useTranslation();
  return D2.S.FUNCTION('Info', { props }, () => {
    const {
      stop,
      bookings,
      isReadOnly,
      removeBookingFromRoute,
      setEditableBookingId,
      activeBookingId,
      layoutName,
      isTemplate,
      commuteOfferIsReadOnly,
    } = props;

    const isDeliveryLayout = layoutName === 'delivery';
    const timezone = global.GEODISC_TIMEZONE;
    return (
      <Container>
        {stop.bookings.map((booking) => {
          const { id, pax } = booking;
          const { booking_uid, slack, service_time } = booking.node;

          D2.S.INFO('Info:Booking', {
            id,
            pax,
            booking,
            slack,
            slack$type: typeof slack,
          });
          const sourceBooking = bookings.find(
            item => item.uid === booking_uid
          );

          const routeId = `${id}:${stop.uid}`;

          const coordinates =
            stop.node_type === 'pickup'
              ? [
                  {
                    lon: sourceBooking?.pickup_location_lon,
                    lat: sourceBooking?.pickup_location_lat,
                  },
                  {
                    lon: booking.node.lon,
                    lat: booking.node.lat,
                  },
                ]
              : [
                  {
                    lon: sourceBooking?.dropoff_location_lon,
                    lat: sourceBooking?.dropoff_location_lat,
                  },
                  {
                    lon: booking.node.lon,
                    lat: booking.node.lat,
                  },
                ];

          const isArrived = false;
          // stop.lon === booking.node.lon && stop.lat === booking.node.lat;

          const routeCoordinates = getRouteCoordinates(
            coordinates,
            true,
            false
          );

          const walkingRoute =
            global.WALKING_ROUTES &&
            global.WALKING_ROUTES[routeCoordinates] &&
            global.WALKING_ROUTES[routeCoordinates].route;
          const legs =
            walkingRoute &&
            walkingRoute.routes &&
            walkingRoute.routes[0] &&
            walkingRoute.routes[0].legs;

          const distance = isArrived
            ? 0
            : legs &&
              legs.reduce((acc, leg) => {
                return acc + (leg?.distance ?? 0);
              }, 0);
          const duration = isArrived
            ? 0
            : legs &&
              legs.reduce((acc, leg) => {
                return acc + leg.duration;
              }, 0);
          D2.S.INFO('Info:WalkingRoute', {
            bookings_id: booking.id,
            isArrived,
            booking,
            stop,
            coordinates,
            routeId,
            routeCoordinates,
            walkingRoute,
            legs,
            distance,
            duration,
          });

          const setActive = () => {
            setEditableBookingId(
              id,
              'p:CommuteOffer:Panels:Vehicles:Vehicle:Route:Stop:setActive'
            );
          };

          const isEucledianOrSpheroid =
            window.GEODISC_WALKING_ROUTING_ENGINE !== 'euclidean' ||
            window.GEODISC_WALKING_ROUTING_ENGINE !== 'spheroid';

          const isDistanceEnabled =
            !window.GEODISC_UI_COMMUTE_OFFER_WALKING_DISTANCE_DISABLE &&
            isEucledianOrSpheroid;
          const isDurationEnabled =
            !window.GEODISC_UI_COMMUTE_OFFER_WALKING_DURATION_DISABLE &&
            isEucledianOrSpheroid;

          const { open_time_ts, close_time_ts, scheduled_ts } =
            booking.$node || booking.node;

          const open_time_mts = moment(open_time_ts);
          const close_time_mts = moment(close_time_ts);
          const scheduled_mts = moment(scheduled_ts);
          const isScheduledTimeValid =
            !scheduled_mts.isBefore(open_time_mts) &&
            !scheduled_mts.isAfter(close_time_mts);

          const bookingDropoffDisplayNames = displayNames(
            sourceBooking?.$display_name ||
              sourceBooking?.display_name ||
              sourceBooking?.data?.username ||
              sourceBooking?.data?.dropoff_customer_name ||
              sourceBooking?.data?.dropoff_customer_name2 ||
              sourceBooking?.name ||
              `Booking ${id}`
          ).join('');

          const bookingPickupDisplayNames = displayNames(
            sourceBooking?.data?.pickup_customer_name ||
              sourceBooking?.data?.pickup_customer_name2 ||
              '-'
          ).join('');

          const getDropoffPhoneNumber =
            sourceBooking?.data?.dropoff_customer_phone ||
            sourceBooking?.data?.phone;

          D2.S.INFO('Info:bookingDropoffDisplayNames', {
            bookingDropoffDisplayNames,
            sourceBooking,
          });

          // Only show booking status (Success, Failed to deliver)
          // for Logistics, not in a template, and for dropoffs
          // Other statuses (enroute, assigned, etc) are not shown for this case
          const isOrderFailedToDeliver =
            !!booking?.node?.failed_to_deliver_at_ts;

          const showBookingStatus =
            (isDeliveryLayout &&
              !isTemplate &&
              // stop.node_type !== 'pickup' &&
              /(completed|fail_to_deliver)/i.test(booking.node.status)) ||
            isOrderFailedToDeliver;

          const getDeliveryStatus = () => {
            if (isOrderFailedToDeliver) {
              if (booking.node.status !== 'completed') {
                //When delivery failed
                return 'fail_to_deliver';
              } else {
                //When the delivery failed once and redelivered successfully
                return booking.node.status;
              }
            }
            return booking.node.status;
          };

          const bookingComponent = () => (
            <Booking key={id}>
              <Wrap
                onClick={setActive}
                isScheduledTimeValid={isScheduledTimeValid}
                isActive={activeBookingId === id}
              >
                <Row key='booking-names'>
                  <PickupDropoffCard
                    isPickup={booking.node?.node_type === 'pickup'}
                    content={
                      booking.node?.node_type === 'pickup' ? (
                        <span>
                          {bookingPickupDisplayNames}
                          {stop.data?.pickup_customer_phone && (
                            <>
                              <br />
                              {stop.data?.pickup_customer_phone}
                            </>
                          )}
                        </span>
                      ) : (
                        <span>
                          {bookingDropoffDisplayNames}
                          {getDropoffPhoneNumber && (
                            <>
                              <br />
                              {getDropoffPhoneNumber}
                            </>
                          )}
                        </span>
                      )
                    }
                  />
                </Row>
                {showBookingStatus && (
                  <div style={{ margin: '4px 0' }}>
                    <OrderStatus status={getDeliveryStatus()} />
                  </div>
                )}
              </Wrap>
              <Open onClick={setActive} />
              {/* {!commuteOfferIsReadOnly ? (
                <Open onClick={setActive} />
              ) : (
                <Open disabled />
              )} */}
            </Booking>
          );

          return (
            <React.Fragment key={`booking-fragment-${id}`}>
              {bookingComponent()}
            </React.Fragment>
          );
        })}
      </Container>
    );
  });
};

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import returnImg from './return.svg';

export default styled(Link)`
  padding: 9px 24px;
  height: 34px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  margin-right: 10px;

  color: #edece8;
  font-size: 14px;
  font-weight: bold;

  background-color: rgb(54, 61, 74);
  background-image: url(${returnImg});
  background-repeat: no-repeat;
  cursor: pointer;
  outline: none;
  background-size: auto;
  background-position: center;
  background-position-y: 8px;
`;

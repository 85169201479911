import React from 'utils/react';

import useTranslation from 'utils/react/useTranslation';

import base64 from 'base-64';
import Object$fromEntries from 'fromentries';
import { Formik } from 'formik';
import logo from 'assets/SWAT-Icon-Orange.png';

import debug from 'utils/debug';
import Container from './Container';
import Input from './Input';
import Submit from './Submit';
import Logo from './Logo';
import Status from './Status';
import BrowserStatus from './BrowserStatus';

const D2 = debug('p:Login:LoginPanel');

const parseLocationArgs = str =>
  str
    ? Object$fromEntries(
        str
          .substring(1)
          .split('&')
          .map(v => v.split('='))
      )
    : {};

const LoginPanel = ({ authorization, authorizationFailure, loginState }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Logo src={logo} />
      <Formik
        initialValues={{
          login: '',
          password: '',
        }}
        onSubmit={values =>
          D2.S.FUNCTION('onSubmit', { values }, () => {
            try {
              const { next } = parseLocationArgs(window.location.search);

              global.localStorage.setItem(
                'next',
                next ? decodeURIComponent(next) : ''
              );

              global.localStorage.setItem(
                global.GEODISC_AUTH_TOKEN,
                base64.encode(`${values.login}:${values.password}`)
              );
              authorization(values.login);
            } catch (e) {
              authorizationFailure(
                new Error(`${t('p.Login.InternalError')}: ${e.message}`)
              );
            }
          })
        }
      >
        {(formProps) => {
          const { values, handleChange, handleSubmit, isValid } = formProps;

          return (
            <form onSubmit={handleSubmit}>
              <Input
                id='login'
                type='text'
                placeholder={t('p.Login.Username')}
                onChange={handleChange}
                value={values.login}
                required
                autoComplete='off'
              />
              <Input
                id='password'
                type='password'
                placeholder={t('p.Login.Password')}
                onChange={handleChange}
                value={values.password}
                required
                autoComplete='off'
              />
              <Submit type='submit' isValid={isValid} />
            </form>
          );
        }}
      </Formik>
      <Status
        error={loginState.get('error')}
        waiting={loginState.get('waiting')}
      />
      <BrowserStatus />
    </Container>
  );
};

export default LoginPanel;

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { addDataset } from 'modules/datasets/actions';

import {
  uploadProgressSelector,
  uploadStatusSelector,
  newDatasetErrorSelector,
} from 'modules/ui/selectors';

import { currentProjectUrlSelector } from 'modules/user/selectors';

import NewDataset from './NewDataset';

const mapDispatchToProps = { addDataset };

const mapStateToProps = createImmutableSelector(
  uploadProgressSelector,
  uploadStatusSelector,
  newDatasetErrorSelector,
  currentProjectUrlSelector,
  (progress, status, error, projectUrl) => ({
    progress,
    status,
    error,
    projectUrl,
  })
);

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(NewDataset);

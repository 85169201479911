import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  position: fixed;
  padding-left: 15px;

  top: 0;
  left: 0;
  right: 0;

  flex-direction: row;
  align-items: center;

  font-family: 'Montserrat', Arial, sans-serif;
  font-size: 20px;
  letter-spacing: 0;

  height: 65px;
  z-index: 10;

  ${({ hasBackground, isShadow }) =>
    hasBackground &&
    (isShadow
      ? 'background: linear-gradient(to bottom, #0d0e11, rgba(43, 48, 58, 0));'
      : 'background: rgb(54,61,74)')};

  @media screen and (max-width: 1400px) {
    ${window.HEADER_PANEL_SCALE_ENABLED && 'zoom: 0.8'};
  }
`;

export default Container;

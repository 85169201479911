export function* makeScheduleDates(fromDate, tillDate) {
  const lastDate = tillDate.clone().add(24, 'hours');
  for (
    let currDate = fromDate.clone();
    lastDate.diff(currDate) > 0;
    currDate.add(24, 'hours')
  ) {
    yield currDate.clone();
  }
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

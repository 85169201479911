import { put } from 'redux-saga/effects';

import invalidateAllBookings from 'api/CommuteOffer/invalidateAllBookings';

import * as actions from 'modules/commuteOffer/actions';
import * as uiActions from 'modules/ui/actions';

import debug from 'utils/debug';
const D2 = debug('m:CommuteOffer:saga:invalidateAllBookings');

export const invalidateAllBookingsMethod = async originalData =>
  D2.A.FUNCTION('Method', { originalData }, async ({ $D2 }) => {
    return invalidateAllBookings(originalData);
  });

// eslint-disable-next-line
export function* invalidateAllBookingsHandler({ payload }) {
  D2.S.INFO('Handler:Request', { payload });
  const { originalData } = payload;

  try {
    yield put(uiActions.setIsSaving(true));

    const response = yield invalidateAllBookingsMethod(originalData);

    D2.S.INFO('Handler:Success', { response, payload });
    yield put(uiActions.setIsSaving(false));
    const now = window.performance.now();

    // trigger the re-poll
    const isUpdating =
      global.GEODISC_COMMUTE_OFFER_FETCH_RESULTS_TS &&
      global.GEODISC_COMMUTE_OFFER_FETCH_REQUEST_TS
        ? global.GEODISC_COMMUTE_OFFER_FETCH_REQUEST_TS >
          global.GEODISC_COMMUTE_OFFER_FETCH_RESULTS_TS
        : false;

    if (!isUpdating) {
      global.GEODISC_COMMUTE_OFFER_FETCH_RESULTS_TS =
        now - global.GEODISC_DATA_MONITORING_REFRESH_INTERVAL * 1000;
      global.GEODISC_COMMUTE_OFFER_FETCH_REQUEST_TS =
        global.GEODISC_COMMUTE_OFFER_FETCH_RESULTS_TS - 1000;
    }
    yield put({
      type: actions.INVALIDATE_ALL_BOOKINGS_RESULTS,
      payload: { response },
    });
  } catch (error) {
    D2.S.INFO('Handler:Failure', { error, payload });
    yield put(uiActions.setIsSaving(false));
    yield put({
      type: actions.INVALIDATE_ALL_BOOKINGS_RESULTS,
      payload: { error },
    });
  }
}

export function* finishInvalidateAllBooking({ payload }) {
  yield put({
    type: actions.INVALIDATE_ALL_BOOKINGS_RESULTS,
    payload: { payload },
  });
}

import React from 'utils/react';
import settings from 'config/settings';
import debug from 'utils/debug';
import { LOGISTICS_ROUTE } from 'utils/constants';
import moment from 'moment-timezone';
import { Route, Switch } from 'react-router-dom';

import ModalWindow from 'components/ModalWindow';
import Map from 'components/MapLogistics';
import LoadingWithSpinner from 'components/LoadingWithSpinner';
import SideNavBar from 'components/SideNavBar';
import StatusBar from 'components/StatusBar';
import LanguageSelect from 'components/LanguageSelect';
import ProjectsMenu from 'components/ProjectsMenu';
import UserMenu from 'components/UserMenu';
import LoadingScreen from 'pages/Loading';
import LogisticsGeofence from 'pages/Logistics/Geofences';
import MapLogisticsGeofences from 'components/MapLogisticsGeofences';

import Panels from './Panels';
import TopButtons from './TopButtons';
import GeofencesMenu from './GeofencesMenu';
import MapStylesMenu from './MapStylesMenu';
import ErrorsReport from './ErrorsReport';
import OrdersPanel from './OrdersPanel';
import DateSelector from './DateSelector';
import Statistics from './Statistics';
import NewSimulation from './NewSimulation';

const D2 = debug('p:Logistics');

global.GEODISC_LOADING_SCREEN_DISABLED = true;

class Logistics extends React.PureComponent {
  state = {
    walkingRoutesSerialNumber: 0,
    drivingTimestampsSerialNumber: 0,
  };

  componentDidMount() {
    const { state, props } = this;
    D2.S.INFO('componentDidMount', { state, props });

    const { incPermissionsSerialNumber, projectId } = props;

    const startTs = moment().unix();

    global.GEODISC_INC_SERIAL_NUMBER_TS = startTs;

    if (!global.GEODISC_PERMISSIONS_MONITORING_DISABLED) {
      this.permissionsCheckerTimerId = setInterval(() => {
        incPermissionsSerialNumber();
      }, 5 * 1000);
    } else {
      // eslint-disable-next-line
      // console.log('*** Warning: Permissions monitoring is turned off.');
    }

    this.refreshTimerId = setInterval(
      () =>
        D2.S.FUNCTION('refreshTimer', { props: this.props }, ({ $D2 }) => {
          const now = window.performance.now();

          const { projectData } = this.props;

          const isDisabled = global.GEODISC_SIMULATION_REFRESH_DISABLED;
          if (isDisabled) {
            return;
          }

          const hasNeverBeenUpdated =
            typeof global.GEODISC_COMMUTE_OFFER_FETCH_REQUEST_TS ===
            'undefined';
          if (hasNeverBeenUpdated && projectData) {
            global.GEODISC_COMMUTE_OFFER_FETCH_RESULTS_TS =
              now - global.GEODISC_DATA_MONITORING_REFRESH_INTERVAL * 1000;
            global.GEODISC_COMMUTE_OFFER_FETCH_REQUEST_TS =
              global.GEODISC_COMMUTE_OFFER_FETCH_RESULTS_TS - 1000;
          }

          const lastUpdateDuration =
            global.GEODISC_COMMUTE_OFFER_FETCH_RESULTS_TS &&
            global.GEODISC_COMMUTE_OFFER_FETCH_REQUEST_TS &&
            global.GEODISC_COMMUTE_OFFER_FETCH_RESULTS_TS -
              global.GEODISC_COMMUTE_OFFER_FETCH_REQUEST_TS;
          const canBeUpdated =
            lastUpdateDuration && lastUpdateDuration >= 0 && !document.hidden;

          if (canBeUpdated) {
            const timeDiff =
              now - global.GEODISC_COMMUTE_OFFER_FETCH_RESULTS_TS;
            $D2.S.INFO('timeDiff', { timeDiff, now });
            if (
              timeDiff >=
              global.GEODISC_DATA_MONITORING_REFRESH_INTERVAL * 1000
            ) {
              this.props.fetchCommuteOffer(null, {
                isInitRequired: false,
                ...$D2.CONTEXT,
              });
            }
          }
        }),
      500
    );

    this.timerId = setInterval(() => {
      const { walkingRoutesSerialNumber, drivingTimestampsSerialNumber } =
        this.state;

      if (walkingRoutesSerialNumber !== global.WALKING_ROUTES_SN) {
        this.setState({
          walkingRoutesSerialNumber: global.WALKING_ROUTES_SN,
          drivingTimestampsSerialNumber,
        });
        this.props.incSerialNumber();
      }

      if (drivingTimestampsSerialNumber !== global.DRIVING_TIMESTAMPS_SN) {
        this.setState({
          walkingRoutesSerialNumber,
          drivingTimestampsSerialNumber: global.DRIVING_TIMESTAMPS_SN,
        });
        this.props.incSerialNumber();
      }
    }, 1000);
    // this.props.fetchCommuteOffer(id);
    this.props.fetchDrivers(projectId);
    this.props.setFleetData(this.props.vehicles);

    const vehicleTypesList =
      this.props.projectData?.toJS()?.data?.vehicle_models;
    this.props.setVehicleTypes(
      vehicleTypesList,
      this.props.projectData?.toJS().modified_at
    );
  }

  componentDidUpdate(prevProps) {
    const { state, props } = this;

    D2.S.INFO('componentDidUpdate', {
      state,
      prevProps,
      props,
    });

    // const { projectId, commuteOfferRequestUpdate } = props;

    // if (prevProps.projectId && prevProps.projectId !== projectId) {
    //   commuteOfferRequestUpdate();
    // }

    this.props.setFleetData(this.props.vehicles);

    const vehicleTypesList =
      this.props.projectData?.toJS()?.data?.vehicle_models;

    const projectDataModifiedAt = Date.parse(
      this.props.projectData?.toJS().modified_at
    );
    const vehicleTypeModifiedAt = Date.parse(this.props.modified_at);

    if (
      this.props.modified_at !== undefined &&
      projectDataModifiedAt > vehicleTypeModifiedAt
    ) {
      this.props.setVehicleTypes(
        vehicleTypesList,
        this.props.projectData?.toJS().modified_at
      );
    } else if (this.props.modified_at === undefined) {
      this.props.setVehicleTypes(
        vehicleTypesList,
        this.props.projectData?.toJS().modified_at
      );
    }
  }

  componentWillUnmount() {
    D2.S.INFO('componentWillUnmount');

    if (this.permissionsCheckerTimerId) {
      clearInterval(this.permissionsCheckerTimerId);
    }

    if (this.refreshTimerId) {
      clearInterval(this.refreshTimerId);
    }

    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }

  render() {
    return D2.S.FUNCTION('render', { props: this.props }, () => {
      const { t, data, projectId, finishOfferLoading, simulation, loading } =
        this.props;

      if (!global.GEODISC_LOADING_SCREEN_DISABLED) {
        if (!data || !projectId) {
          return (
            <React.Fragment>
              <LoadingScreen />
              <ModalWindow />
            </React.Fragment>
          );
        }
      }

      const {
        $errors = [],
        $warnings = [],
        $fixes = [],
      } = data ? data.result : {};
      D2.S.INFO('render:$errors', { $errors });
      D2.S.INFO('render:$warnings', { $warnings });

      const hasErrors =
        ($errors && $errors.length > 0) ||
        ($warnings && $warnings.length > 0) ||
        ($fixes && $fixes.length > 0);

      if (hasErrors) {
        return (
          <ErrorsReport errors={$errors} warnings={$warnings} fixes={$fixes} />
        );
      }

      return (
        <React.Fragment>
          {simulation && (
            <Switch>
              <Route path={`${LOGISTICS_ROUTE}/geofences`}>
                <MapLogisticsGeofences />
                <LogisticsGeofence />
              </Route>
              <Route path={`${LOGISTICS_ROUTE}/routes`}>
                <Map />
                <Panels />
                <TopButtons />
                <MapStylesMenu />
                <GeofencesMenu />
              </Route>
              <Route path={LOGISTICS_ROUTE}>
                <OrdersPanel />
              </Route>
            </Switch>
          )}
          {!simulation && finishOfferLoading && <NewSimulation />}
          <SideNavBar disabled={!simulation} />
          <StatusBar>
            <DateSelector />
            {/* marginLeft is to avoid the datepicker */}
            <div style={{ flexGrow: 1, marginLeft: 360 }}>
              <Statistics />
            </div>
            <LanguageSelect />
            <ProjectsMenu />
            <UserMenu />
          </StatusBar>
          <ModalWindow />
          {(!finishOfferLoading || loading) && (
            <LoadingWithSpinner message={''} />
          )}
        </React.Fragment>
      );
    });
  }
}

export default Logistics;

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import createImmutableSelector from 'utils/immutable-selector';

import { openPopup, toggleSelectedCommuteOffers } from 'modules/ui/actions';

import {
  updateCommuteOffer,
  deleteCommuteOffer,
} from 'modules/commuteOffer/actions';

import { commuteOfferListSelector } from 'modules/commuteOffer/selectors';
import {
  selectedCommuteOfferIdsSelector,
  commuteOffersErrorSelector,
} from 'modules/ui/selectors';
import { currentUserAllowChangeSelector } from 'modules/user/selectors';

import Body from './Body';

const mapStateToProps = createImmutableSelector(
  commuteOfferListSelector,
  selectedCommuteOfferIdsSelector,
  commuteOffersErrorSelector,
  currentUserAllowChangeSelector,
  (list, selectedIds, error, currentUserAllowChange) => ({
    list,
    selectedIds,
    error,
    currentUserAllowChange,
  })
);

const mapDispatchToProps = {
  openPopup,
  toggleSelectedCommuteOffers,
  updateCommuteOffer,
  deleteCommuteOffer,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const enhance = compose(withConnect, withRouter);

export default enhance(Body);

import { getNodeAction } from 'api/logistics';
import InsignificantText from 'pages/Logistics/Common/InsignificantText';
import { styled } from 'baseui';
import useTranslation from 'utils/react/useTranslation';
import { useEffect, useState } from 'react';
import { useInterval } from './PODSection';

const DataWrapper = styled('div', ({ $theme }) => ({
  margin: '8px 0 0 0',
  ...$theme.typography.montserrat,
}));

function FTDSection({ node }) {
  const [nodeAction, setNodeAction] = useState(null);
  const { t } = useTranslation();

  const fetchData = () => {
    if (node?.id) {
      getNodeAction(node.id).then((nodeAct) => {
        setNodeAction(nodeAct);
      });
    }
  };

  useEffect(fetchData, []);

  // Poll every minute
  // TODO: use websocket in the future
  useInterval(fetchData, nodeAction?.objects?.length ? null : 60_000);

  if (nodeAction?.objects?.length) {
    const numberOfReasons = nodeAction?.objects;
    return (
      <>
        <DataWrapper>
          <InsignificantText>
            {t('p.Orders.order.status.reason')}
          </InsignificantText>
          <br />
          {numberOfReasons[0]?.data?.comment}
        </DataWrapper>
      </>
    );
  }

  return null;
}

export default FTDSection;

import React from 'utils/react';
import useTranslation from 'utils/react/useTranslation';
import Container from './Container';

const Error = ({ error, waiting }) => {
  const { t } = useTranslation();

  const message = error === '401' ? t('p.Login.LoginFailed') : error;

  return (
    <Container isWaiting={waiting}>
      {waiting && t('p.Login.LoggingIn')}
      {message}
    </Container>
  );
};

export default Error;

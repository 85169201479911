const {
  GEODISC_DATA_MONITORING_REFRESH_INTERVAL = 3,
  GEODISC_LOGISTICS_API_UPLOAD_STRATEGY = 'keep_assigned',
} = global;

const settings = {
  GEODISC_DATA_MONITORING_REFRESH_INTERVAL,
  logistics: {
    api: {
      upload_strategy: GEODISC_LOGISTICS_API_UPLOAD_STRATEGY,
    },
  },
};

export default settings;

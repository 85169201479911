import styled from 'styled-components';

export default styled.div`
  margin: 5px;
  border-radius: 5px;
  background-color: ${({ isActive }) => (isActive ? '#424a5a' : 'transparent')};
  padding: 0 10px;
  min-width: 70px;
  height: 26px;
  line-height: 28px;
  font-size: 12px;
  cursor: pointer;
  text-align: center;

  :hover {
    background-color: #525a69;
  }
`;

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: rgb(54, 61, 74);
  min-width: 1140px;
  height: 100vh;
  padding: 40px;
`;

export default Container;

import React from 'utils/react';
import { List as VirtualizedList, AutoSizer } from 'react-virtualized';
import { memoHumanize, humanize } from 'utils/CommuteOffer';
import debug from 'utils/debug';
import PanelContainer from '../PanelContainer';
import Header from './Header';
import Booking from './Booking';
import Filter from './Filter';

import PanelList from '../PanelList';

const D2 = debug('p:CommuteOffer:Panels:Bookings');

global.GEODISC_COMMUTE_OFFER_BOOKINGS_PANEL_USES_VIRTUALIZED_LIST = true;

const defaultScrollToBooking = uid =>
  D2.S.FUNCTION('geodisc$ScrollToBooking<DOM>', { uid }, ({ $D2 }) => {
    const elementId = `booking_${uid}`;
    const element = window.document.getElementById(elementId);
    $D2.S.INFO('element', { element, elementId, uid });

    if (element) {
      element.scrollIntoView();
    }

    return 'DOM';
  });

global.geodisc$ScrollToBooking = defaultScrollToBooking;

class Bookings extends React.PureComponent {
  componentDidMount() {
    D2.S.INFO('componentDidMount');

    this.setScrollToBookingMethod();
  }

  componentDidUpdate() {
    D2.S.INFO('componentDidUpdate');

    if (this.theList) {
      this.theList.forceUpdateGrid();
    }

    this.setScrollToBookingMethod();
  }

  componentWillUnmount() {
    D2.S.INFO('componentWillUnmount');

    this.theList = null;
    this.setScrollToBookingMethod();
  }

  setScrollToBookingMethod = () =>
    D2.S.FUNCTION('setScrollToBookingMethod', {}, () => {
      if (this.theList) {
        global.geodisc$ScrollToBooking = uid =>
          D2.S.FUNCTION(
            'geodisc$ScrollToBooking<VirtualizedList>',
            { uid, props: this.props },
            ({ $D2 }) => {
              const { bookings } = this.props;
              $D2.S.INFO('bookings', { bookings, uid });
              const index = bookings.findIndex(x => x.uid === uid);
              $D2.S.INFO('index', { index, bookings, uid });

              const elements = window.document.getElementsByClassName(
                'ReactVirtualized__Grid',
                'ReactVirtualized__List'
              );
              const element =
                elements && elements.length === 1 ? elements[0] : undefined;
              $D2.S.INFO('element', { element, elements });

              if (element) {
                element.scrollTo(0, index * 260);
              }
              return 'VirtualizedList';
            }
          );
      } else {
        global.geodisc$ScrollToBooking = defaultScrollToBooking;
      }
    });

  renderBooking = ({ index, key, style }) =>
    D2.S.FUNCTION(
      'renderBooking',
      { index, key, style, props: this.props },
      ({ $D2 }) => {
        const { bookings } = this.props;

        const booking = bookings[index];
        $D2.S.INFO('booking', { booking, index, key });

        return (
          <div key={key} style={style}>
            <Booking
              key={bookings[index].uid}
              booking={memoHumanize(bookings[index])}
            />
          </div>
        );
      }
    );

  render() {
    return D2.S.FUNCTION('render', { props: this.props }, () => {
      const {
        bookings,
        isHidden,
        toggleBookingsHidden,
        activeBookingIndex,
        isServiceDataSource,
        isFilteringEnabled,
      } = this.props;

      const visibleBookings =
        global.GEODISC_DEBUG_BOOKINGS_PANEL_MAX_RECORDS_COUNT > 0
          ? bookings.slice(
              0,
              global.GEODISC_DEBUG_BOOKINGS_PANEL_MAX_RECORDS_COUNT
            )
          : bookings;

      return (
        <PanelContainer
          isHidden={isHidden}
          isServiceDataSource={isServiceDataSource}
          onClick={isHidden ? toggleBookingsHidden : null}
        >
          <Header
            key='header'
            size={bookings.length}
            isHidden={isHidden}
            toggleBookingsHidden={toggleBookingsHidden}
          />
          {isFilteringEnabled && !isHidden && <Filter />}
          <PanelList
            key='list'
            isHidden={isHidden}
            isFilteringEnabled={isFilteringEnabled}
          >
            {global.GEODISC_COMMUTE_OFFER_BOOKINGS_PANEL_USES_VIRTUALIZED_LIST ? (
              <AutoSizer>
                {({ height, width }) => (
                  <VirtualizedList
                    key='bookings-VirtualizedList'
                    // eslint-disable-next-line
                    ref={(ref) => (this.theList = ref)}
                    width={width}
                    height={height}
                    rowHeight={260}
                    rowRenderer={this.renderBooking}
                    scrollToIndex={activeBookingIndex}
                    rowCount={bookings.length}
                    overscanRowCount={10}
                  />
                )}
              </AutoSizer>
            ) : (
              visibleBookings.map(booking => (
                <Booking key={booking.uid} booking={humanize(booking)} />
              ))
            )}
          </PanelList>
        </PanelContainer>
      );
    });
  }
}

export default Bookings;

import styled from 'styled-components';

export default styled.div`
  position: relative;
  width: 1px;
  border-left: 1px dashed gray;
  margin: 10px 5px;
  margin-bottom: 13px;

  &:after {
    position: absolute;
    content: '';

    top: 0;
    left: -5px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    border: 1px solid gray;
    background: #3b4251;
  }

  &:before {
    position: absolute;
    content: '';

    bottom: 0;
    left: -5px;
    width: 7px;
    height: 7px;
    border: 1px solid gray;
    background: #3b4251;
    transform: rotate(45deg);
  }
`;

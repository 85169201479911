import styled from 'styled-components';

export default styled.div`
  position: relative;
  font-size: 12px;
  border-radius: 10px;
  margin: 5px 0;
  margin-left: 24px;
  transition: 0.3s;
  ${({ isActive }) => isActive && 'background: #3b4251;'} :hover {
    background: #3b4251;
  }

  :last-child {
    :before {
      display: none;
    }
  }

  :after {
    position: absolute;
    left: -22px;
    top: 9px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transform: rotate(45deg);
    border: 1px solid ${({ color, isDropoff }) => (isDropoff ? color : '#fff')};
    content: '';
    display: block;
    box-shadow: 0 0 0 ${({ isActive }) => (isActive ? '6px' : '0px')}
      ${({ color }) => `${color}50`};
    background-color: ${({ color, isDropoff }) => (isDropoff ? '#fff' : color)};
  }

  :before {
    position: absolute;
    left: -17px;
    top: 20px;
    height: 100%;
    content: '';
    background-color: ${({ color }) => color};
    width: 2px;
  }
`;

import styled from 'styled-components';

const Container = styled.pre`
  display: block;
  color: black;
  margin: 0px;
  color: ${({ fontColor }) => fontColor};
`;

export default Container;

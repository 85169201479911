import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { addSimulation } from 'modules/simulations/actions';
import { fetchDatasets } from 'modules/datasets/actions';
import { fetchGeofences } from 'modules/geofences/actions';

import { listSelector as datasetsListSelector } from 'modules/datasets/selectors';
import { geofencesSelector } from 'modules/geofences/selectors';
import { currentProjectUrlSelector } from 'modules/user/selectors';

import NewSimulation from './NewSimulation';

const mapDispatchToProps = {
  addSimulation,
  fetchDatasets,
  fetchGeofences,
};

const mapStateToProps = createImmutableSelector(
  datasetsListSelector,
  geofencesSelector,
  currentProjectUrlSelector,
  (datasets, geofences, projectUrl) => ({ datasets, geofences, projectUrl })
);

export default connect(mapStateToProps, mapDispatchToProps)(NewSimulation);

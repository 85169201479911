import React from 'utils/react';

import debug from 'utils/debug';
import DefaultNavigation from './DefaultNavigation';
import DeliveryNavigation from './DeliveryNavigation';

const D2 = debug('c:Header:Navigation');

const layouts = {
  default: opts => <DefaultNavigation {...opts} />,
  delivery: opts => <DeliveryNavigation {...opts} />,
};

const Navigation = props =>
  D2.S.FUNCTION('Navigation', { props }, () => {
    const { layoutName } = props;

    const layoutFn = layouts[layoutName] || layouts.default;

    return layoutFn(props);
  });

export default React.memo(Navigation);

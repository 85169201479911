import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { addCommuteOffer } from 'modules/commuteOffer/actions';

import {
  uploadProgressSelector,
  uploadStatusSelector,
  newDatasetErrorSelector,
} from 'modules/ui/selectors';

import {
  currentProjectSelector,
  currentProjectUrlSelector,
} from 'modules/user/selectors';

import NewCommuteOffer from './NewCommuteOffer';
import withTranslation from 'utils/react/withTranslation';

const mapDispatchToProps = { addCommuteOffer };

const mapStateToProps = createImmutableSelector(
  uploadProgressSelector,
  uploadStatusSelector,
  newDatasetErrorSelector,
  currentProjectSelector,
  currentProjectUrlSelector,
  (progress, status, error, project, projectUrl) => ({
    progress,
    status,
    error,
    project,
    projectUrl,
  })
);

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(withTranslation()(NewCommuteOffer));

import { all, take, call, select, put, takeLatest } from 'redux-saga/effects';
import { api$Geofence as api } from 'api';
import * as uiActions from 'modules/ui/actions';
import * as actions from './actions';
import { currentProjectIdSelector } from '../user/selectors';

function* fetchGeofences() {
  try {
    const projectId = yield select(currentProjectIdSelector);
    const data = yield call(api.getGeofences, projectId);
    yield put({
      type: actions.GEOFENCES_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: actions.GEOFENCES_FETCH_FAILURE, payload: error });
  }
}

function* addGeofence({ payload }) {
  try {
    const { body, fromDataset } = payload;
    const data = yield call(api.addGeofence, body, fromDataset);
    yield put({
      type: actions.ADD_GEOFENCE_SUCCESS,
      payload: data,
    });
    yield put(uiActions.closePopup());
  } catch (error) {
    yield put({ type: actions.ADD_GEOFENCE_FAILURE, payload: error });
  }
}

function* deleteGeofence({ payload }) {
  try {
    const id = payload;
    yield call(api.deleteGeofence, id);
    yield put({
      type: actions.DELETE_GEOFENCE_SUCCESS,
      payload: id,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_GEOFENCE_FAILURE, payload: error });
  }
}

function* updateGeofence({ payload: { id, body } }) {
  try {
    const data = yield call(api.updateGeofence, id, body);
    yield put({
      type: actions.UPDATE_GEOFENCE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_GEOFENCE_FAILURE, payload: error });
  }
}

function* saveGeofence({ payload: { id, body } }) {
  yield put(actions.updateGeofence(id, body));

  yield take(actions.UPDATE_GEOFENCE_SUCCESS);

  yield put({
    type: uiActions.SET_EDITABLE_GEOFENCE,
    payload: null,
  });
}

function* copyGeofence({ payload }) {
  try {
    const id = payload;
    yield call(api.copyGeofence, id);
    yield put({ type: actions.COPY_GEOFENCE_SUCCESS });
    yield put(actions.fetchGeofences());
  } catch (error) {
    yield put({ type: actions.COPY_GEOFENCE_FAILURE, payload: error });
  }
}

function* Saga() {
  all([
    yield takeLatest(actions.GEOFENCES_FETCH_REQUEST, fetchGeofences),
    yield takeLatest(actions.ADD_GEOFENCE_REQUEST, addGeofence),
    yield takeLatest(actions.DELETE_GEOFENCE_REQUEST, deleteGeofence),
    yield takeLatest(actions.UPDATE_GEOFENCE_REQUEST, updateGeofence),
    yield takeLatest(actions.SAVE_GEOFENCE, saveGeofence),
    yield takeLatest(actions.COPY_GEOFENCE_REQUEST, copyGeofence),
  ]);
}

export default Saga;

import { connect } from 'react-redux';
import { compose } from 'redux';
import createImmutableSelector from 'utils/immutable-selector';

import { defaultPageSelector } from 'modules/user/selectors';

import Root from './Root';

const mapStateToProps = createImmutableSelector(
  defaultPageSelector,
  defaultPage => ({
    defaultPage,
  })
);

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const enhance = compose(withConnect);

export default enhance(Root);

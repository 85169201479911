import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  color: rgb(40, 45, 55);
  font-size: 17px;
  padding: 5px;
  border-bottom: 1px solid
    ${({ isActive }) => (isActive ? 'rgb(40, 45, 55)' : 'transparent')};
  margin-right: 50px;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
`;

export default Container;

import styled from 'styled-components';

export default styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: left;
  line-height: normal;

  padding: 5px 0;
  transition: 0.3s;
  margin-left: 0px;
  border-top: 1px solid rgba(90, 100, 119, 0.4);

  :first-child {
    border: none;
  }

  > div:last-child {
    opacity: 0;
  }

  :hover {
    color: white;

    > div:last-child {
      opacity: 1;

      :hover {
        opacity: 0.5;
      }
    }
  }
`;

import React from 'utils/react';

import points from 'assets/points.svg';
import arcs from 'assets/arcs.svg';
import clusters from 'assets/clusters.svg';
import arcClusters from 'assets/clustersArc.svg';
import hexPickup from 'assets/hex.svg';

import Container from './Container';
import Button from './Button';

const config = {
  points,
  arcs,
  clusters,
  arcClusters,
  hex_pickup: hexPickup,
};

const LayerTypePanel = ({ value, onChange }) => {
  const activeValue = value === 'hex_dropoff' ? 'hex_pickup' : value;
  return (
    <Container>
      {Object.keys(config).map((key) => {
        const handleClick = () => {
          onChange(key);
        };

        return (
          <Button
            key={key}
            isActive={key === activeValue}
            img={config[key]}
            onClick={handleClick}
          />
        );
      })}
    </Container>
  );
};

export default LayerTypePanel;

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { isDeliveryLayoutSelector } from 'modules/user/selectors';

import {
  Stop as StopComponent,
  FixedStop as FixedStopComponent,
  DraggableStop as DraggableStopComponent,
} from './Stop';

const mapStateToProps = createImmutableSelector(
  isDeliveryLayoutSelector,
  isDeliveryLayout => ({
    isDeliveryLayout,
  })
);

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export const Stop = withConnect(StopComponent);
export const FixedStop = withConnect(FixedStopComponent);
export const DraggableStop = withConnect(DraggableStopComponent);

import createImmutableSelector from 'utils/immutable-selector';
import { datasetTimeSelector, datasetAlldaySelector } from '../ui/selectors';

export const datasetsSelector = state => state.get('datasets');

export const countSelector = createImmutableSelector(
  datasetsSelector,
  datasets => datasets.get('count')
);

export const listSelector = createImmutableSelector(
  datasetsSelector,
  datasets => datasets.get('list')
);

export const datasetSelector = createImmutableSelector(
  datasetsSelector,
  datasets => datasets.get('dataset')
);

export const hasTimeDatasetSelector = createImmutableSelector(
  datasetsSelector,
  (datasets) => {
    if (!datasets.get('datasetData')) {
      return 'loading';
    }

    return datasets.get('datasetData').size > 1;
  }
);

export const datasetDataSelector = createImmutableSelector(
  datasetsSelector,
  datasetTimeSelector,
  datasetAlldaySelector,
  (datasets, time, isAllDay) => {
    if (!datasets.get('datasetData')) {
      return { dropoff: [], pickup: [] };
    }

    if (isAllDay) {
      return datasets.get('datasetData').reduce(
        (acc, value) => {
          const { dropoff, pickup } = value;
          acc.dropoff.push(...dropoff);
          acc.pickup.push(...pickup);

          return acc;
        },
        { dropoff: [], pickup: [] }
      );
    }

    return datasets.getIn(['datasetData', time]);
  }
);

export const dataLoadingSelector = createImmutableSelector(
  datasetsSelector,
  datasets => !datasets.get('datasetData')
);

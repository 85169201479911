import styled from 'styled-components';

const TableHeader = styled.div`
  position: sticky;
  left: 0;
  top: 0;
  height: 20px;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  padding-bottom: 100px;

  z-index: 1;
  background: rgb(54, 61, 74);
`;

export default TableHeader;

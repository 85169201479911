import styled from 'styled-components';

export default styled.div`
  width: 100%;
  margin-left: 10px;

  > div {
    padding: 10px 0;

    &:first-child {
      border-bottom: 1px solid gray;
    }
  }
`;

import styled from 'styled-components';

import ButtonClose from 'components/ButtonClose';

const Close = styled(ButtonClose)`
  position: absolute;
  margin-right: 30px;
  right: 0;
  top: 20px;
`;

export default Close;

import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled(Link)`
  display: flex;
  height: 100%;
  color: #c5cede;
  padding-right: 0;
  flex-shrink: 0;
  flex-grow: 12;
  text-decoration: none;
  justify-content: space-between;
  z-index: 0;
`;

export default Container;

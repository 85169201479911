import styled from 'styled-components';

export default styled.div`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: flex-start;

  padding: 10px;
`;

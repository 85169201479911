import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: 15px;
  margin-bottom: 10px;
`;

export default Container;

import React, { useEffect, useState } from 'react';
import ModalHeader from 'components/ModalHeader';
import { styled } from 'baseui';
import { useTranslation } from 'react-i18next';
import Templates from './Templates';
import Fleet from './Fleet';
import VehicleTypes from './VehicleTypes';
import { useHistory } from 'react-router-dom';
import { LOGISTICS_ROUTE } from 'utils/constants';
import { locationUrl, parseLocationArgs } from '../../utils/args';

export const Sidebar = styled('div', ({ $theme }) => {
  return {
    width: '256px',
    minWidth: '256px',
    margin: '0 0 0 0px',
    padding: '40px 20px 40px 0',
    borderRight: `solid 1px ${$theme.colors.menuBackground}`,
    display: 'flex',
    flexDirection: 'column',
  };
});

export const SidebarItem = styled('div', ({ $theme, $isSelected }) => {
  const selectedStyles = {
    marginLeft: '29px',
    borderLeft: `solid 5px ${$theme.colors.tableHeader}`,
  };
  return {
    marginLeft: '34px',
    marginTop: '0',
    marginRight: 'auto',
    marginBottom: '35px',
    padding: '0px 0px 0px 26px',
    ...($isSelected && selectedStyles),
    ':hover': selectedStyles,
  };
});

export const ContainerWrapper = styled('div', ({ $theme }) => {
  return {
    width: '100%',
    background: $theme.colors.containerBackground,
    height: '100vh',
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'row',
  };
});

const ContentWrapper = styled('div', ({ $theme }) => {
  return {
    width: '100%',
    maxWidth: '856px',
    background: $theme.colors.containerBackground,
    height: '100vh',
    overflow: 'scroll',
    margin: '40px 40px 0 164px',
    display: 'flex',
    flexDirection: 'column',
  };
});
export const pageTitles = {
  templates: 'templates',
  fleet: 'fleet',
  vehicleTypes: 'vehicleTypes',
};

const MasterSettings = (props) => {
  const {
    finishOfferLoading,
    commuteOfferData,
    isSuperUser,
    commuteOfferRequestUpdate,
    pageAddress,
  } = props;
  const [selectedTab, setSelectedTab] = useState(pageTitles.templates);
  const { t } = useTranslation();
  const history = useHistory();

  const handleClose = () => {
    goBackBrowserHistory();
  };

  const goBackBrowserHistory = () => {
    history.goBack();
  };

  const handleReset = () => {};

  useEffect(() => {
    // to skip the global style we used in the app
    document
      .getElementsByTagName('body')[0]
      .setAttribute('style', 'overflow:scroll');

    // update links on the component by requesting template data
    commuteOfferRequestUpdate();

    const backListener = history.listen((location, action) => {
      if (action === 'POP') {
        const params = new URLSearchParams(window.location.search);
        if (params.get('page') === null) {
          // when user click browser back
          // if (window.location.pathname === `${LOGISTICS_ROUTE}/template`) {
          //   const search = parseLocationArgs(locationUrl().query);
          //   const previousPage = search.get('previousPage') || LOGISTICS_ROUTE;
          //   history.push(pageAddress({ page: previousPage }));
          //   global.closeMasterSettings();
          // }
          global.closeMasterSettings();
        }
      }
    });

    return () => {
      backListener();
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    params.set('page', selectedTab);
    window.history.replaceState(
      {},
      null,
      `${window.location.pathname}?${params}`
    );
  }, [selectedTab]);

  return (
    <React.Fragment>
      <ModalHeader
        // disableSaveBtn={isSimilar(simulationSettingsData, settingsFormData)}
        handleClose={handleClose}
        btnOneAction={handleReset}
        pageTitle={t('c.MasterSettings.content.default.title')}
        btnOneText=''
        btnTwoText=''
        formId='settings-form'
      />
      <ContainerWrapper>
        <Sidebar>
          <SidebarItem
            $isSelected={selectedTab === pageTitles.templates}
            onClick={() => setSelectedTab(pageTitles.templates)}
          >
            {t('c.MasterSettings.sidebar.template')}
          </SidebarItem>
          <SidebarItem
            $isSelected={selectedTab === pageTitles.fleet}
            onClick={() => setSelectedTab(pageTitles.fleet)}
          >
            {t('c.MasterSettings.sidebar.fleet')}
          </SidebarItem>
          <SidebarItem
            $isSelected={selectedTab === pageTitles.vehicleTypes}
            onClick={() => setSelectedTab(pageTitles.vehicleTypes)}
          >
            {t('c.MasterSettings.sidebar.vehicleType')}
          </SidebarItem>
        </Sidebar>
        <ContentWrapper>
          {selectedTab === pageTitles.templates && (
            <Templates
              finishOfferLoading={finishOfferLoading}
              commuteOfferData={commuteOfferData}
              isSuperUser={isSuperUser}
            />
          )}
          {selectedTab === pageTitles.fleet && <Fleet />}
          {selectedTab === pageTitles.vehicleTypes && <VehicleTypes />}
        </ContentWrapper>
      </ContainerWrapper>
    </React.Fragment>
  );
};

export default MasterSettings;

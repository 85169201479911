import styled from 'styled-components';

const ArrivalTime = styled.div`
  position: absolute;
  top: 10px;
  right: 7px;
  color: #edece8;
  font-size: 12px;
`;

ArrivalTime.displayName = 'ArrivalTime';

export default ArrivalTime;

import createImmutableSelector from 'utils/immutable-selector';

import debug from 'utils/debug';

import { capacityFunctorSelector } from 'modules/user/selectors';

const D2 = debug('m:simulation:selectors');

export const simulationsSelector = (state) => {
  D2.S.COUNTER('simulationsSelector');
  return state.get('simulations');
};

export const stateSelector = createImmutableSelector(
  simulationsSelector,
  simulations => simulations.get('state')
);

export const countSelector = createImmutableSelector(
  simulationsSelector,
  simulations => simulations.get('count')
);

export const templateIdSelector = createImmutableSelector(
  simulationsSelector,
  simulations => simulations.get('templateId')
);

export const templateDataSelector = createImmutableSelector(
  simulationsSelector,
  simulations => simulations.get('templateSimulation')
);

export const listSelector = createImmutableSelector(
  simulationsSelector,
  simulations => simulations.get('list')
);

export const simulationsWsTokenSelector = createImmutableSelector(
  simulationsSelector,
  simulations => simulations.get('wsToken')
);

export const simulationSelector = createImmutableSelector(
  simulationsSelector,
  simulations => simulations.get('simulation')
);

// export const simulationDataSelector = createImmutableSelector(
//   simulationsSelector,
//   simulations => simulations.get('simulationData')
// );

export const simulationDataSelector = () => global.SIMULATION_DATA_RAW;

// export const simulationDataCountSelector = createImmutableSelector(
//   simulationsSelector,
//   (simulations) => {
//     const data = simulations.get('simulationData');
//     return data ? data.length : 0;
//   }
// );

export const simulationDataCountSelector = () =>
  global.SIMULATION_DATA_RAW.length;

export const vehiclesSelector = createImmutableSelector(
  simulationsSelector,
  capacityFunctorSelector,
  (simulations, capacityFunctor) =>
    D2.S.FUNCTION('vehiclesSelector', { simulations, capacityFunctor }, () => {
      const vehicles = simulations.get('simulationVehicles');

      const res = vehicles
        ? vehicles.map((vehicle) => {
            const completed_nodes = vehicle.completed_nodes
              ? vehicle.completed_nodes
              : [];
            const assigned_nodes = vehicle.assigned_nodes
              ? vehicle.assigned_nodes
              : [];
            const nodes = [...completed_nodes, ...assigned_nodes];

            const route = nodes.reduce((acc, node) => {
              let demand = node.demand
                ? capacityFunctor.functor(node.demand)
                : 0;

              if (node.node_type === 'dropoff') {
                demand = -demand;
              }

              const newItem = {
                ...node,
                pax: demand,
                pax_type: capacityFunctor.type,
              };

              if (!acc.length) {
                return [newItem];
              }

              return [...acc, newItem];
            }, []);

            return {
              ...vehicle,
              route,
            };
          })
        : [];

      return res;
    })
);

export const bookingsSelector = createImmutableSelector(
  simulationsSelector,
  vehiclesSelector,
  (simulations, vehicles) => {
    D2.S.EVENT('bookingsSelector');

    const bookings = simulations.get('simulationBookings');

    const res = D2.S.PROFILER('bookingsSelector', () => {
      return bookings
        ? bookings.map((item) => {
            const currentVehicle = vehicles.find(
              vehicle => item.assigned_vehicle_id === vehicle.agent_id
            );

            const routeStop =
              currentVehicle &&
              currentVehicle.route.find(
                stop =>
                  stop.bookings &&
                  stop.bookings.find(booking => booking.id === item.uid)
              );

            return {
              ...item,
              color: currentVehicle && currentVehicle.$activeColor,
              $routeStopId: routeStop && routeStop.id,
            };
          })
        : [];
    });

    D2.S.DEBUG('Result:', res);
    return res;
  }
);

// export const simulationByVehicleSelector = createSelector(
//   simulationDataSelector,
//   (data) => {
//     const res = D2.S.PROFILER('simulationByVehicleSelector', () => {
//       return data && data.reduce(
//         (acc, item) => {

//           if (!item.agent_id) {
//             return acc;
//           }

//           // round to 10sec
//           const unixtime =
//             Math.ceil(moment(item.current_sim_ts).unix() / 10) * 10;

//           // get min/max time
//           if (!acc.minTime) {
//             acc.minTime = unixtime;
//             acc.maxTime = unixtime;
//           }

//           if (acc.minTime > unixtime) {
//             acc.minTime = unixtime;
//           }

//           if (acc.maxTime < unixtime) {
//             acc.maxTime = unixtime;
//           }
//           // ----- //

//           if (acc.vehicles[item.agent_id]) {
//             acc.vehicles[item.agent_id] = {
//               ...acc.vehicles[item.agent_id],
//               [unixtime]: item
//             };
//           } else {
//             acc.vehicles[item.agent_id] = {
//               [unixtime]: item
//             };
//           }

//           return acc;
//         },
//         {
//           minTime: 0,
//           maxTime: 0,
//           vehicles: {}
//         }
//       );
//     });

//     return res;
//   }
// );

export const simulationByVehicleSelector = () =>
  global.SIMULATION_DATA_TIMELINE;

export const progressSelector = createImmutableSelector(
  simulationsSelector,
  simulations =>
    D2.S.FUNCTION('progressSelector', { simulations }, () =>
      simulations.get('progress')
    )
);

export const simulationGeometrySelector = createImmutableSelector(
  simulationSelector,
  (simulation) => {
    const geomentry = simulation?.get('geometry');
    return geomentry?.toJS();
  }
);

// export const simulationIsLiveSelector = createImmutableSelector(
//   simulationSelector,
//   simulation => (simulation ? simulation.get('isLive') : false)
// );

export const simulationIsLiveSelector = createImmutableSelector(
  simulationSelector,
  () => false
);

export const isDataLoadedSelector = createImmutableSelector(
  progressSelector,
  progress =>
    D2.S.FUNCTION('isDataLoadedSelector', { progress }, () => progress >= 1)
);

// export const minMaxTimeSelector = createSelector(
//   simulationByVehicleSelector,
//   (data) => {
//     D2.S.EVENT('minMaxTimeSelector');
//     if (data) {
//       const { minTime, maxTime } = data;
//       D2.S.DEBUG('Result:', { minTime, maxTime });
//       return { minTime, maxTime };
//     }

//     D2.S.DEBUG('Result:', { minTime: 0, maxTime: 0 });
//     return { minTime: 0, maxTime: 0 };
//   }
// );

export const minMaxTimeSelector = () =>
  global.SIMULATION_DATA_TIMELINE
    ? {
        minTime: global.SIMULATION_DATA_TIMELINE.minTime,
        maxTime: global.SIMULATION_DATA_TIMELINE.maxTime,
      }
    : { minTime: 0, maxTime: 0 };

// Player

export const simulationPlayerSelector = createImmutableSelector(
  simulationsSelector,
  simulations => simulations.get('player')
);

export const simulationsPlayerTimeSelector = createImmutableSelector(
  simulationPlayerSelector,
  player => (player ? player.get('time') : 0)
);

export const simulationsPlayerTimesSelector = createImmutableSelector(
  simulationByVehicleSelector,
  simulationsPlayerTimeSelector,
  (data, time) => {
    if (!data || !time) {
      return { time, index: time };
    }
    const { minTime } = data;
    for (let index = time; index >= minTime; index -= 10) {
      if (data.data[index]) {
        return { time, index };
      }
    }
    return { time, index: time };
  }
);

export const simulationRunningSelector = createImmutableSelector(
  simulationPlayerSelector,
  player => player.get('running')
);

export const simulationSpeedSelector = createImmutableSelector(
  simulationPlayerSelector,
  player => player.get('speed')
);

export const simulationTimeSelector = createImmutableSelector(
  simulationPlayerSelector,
  player => player.get('time')
);

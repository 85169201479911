import debug from 'utils/debug';
import LoadingWithSpinner from 'components/LoadingWithSpinner';
import { useEffect, useState } from 'react';

const D2 = debug('c:ModalWindows:ProgressWindow');

export default props =>
  D2.S.FUNCTION('ProgressWindow', { props }, () => {
    const [isOpen, setIsOpen] = useState(false);
    const [progressMessage, setProgressMessage] = useState('');

    useEffect(() => {
      D2.S.INFO('Update');
      window.openProgressWindow = (message, opts = {}) =>
        D2.S.FUNCTION('openProgressWindow', { message, opts }, () => {
          setProgressMessage(message);
          setIsOpen(true);
        });
      window.closeProgressWindow = () => {
        D2.S.INFO('closeProgressWindow');
        setIsOpen(false);
      };
      return () => {
        D2.S.INFO('Cleanup');
        window.openProgressWindow = () => {};
      };
    });

    return <>{isOpen && <LoadingWithSpinner message={progressMessage} />}</>;
  });

window.openProgressWindow = () => {};
window.closeProgressWindow = () => {};

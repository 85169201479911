import styled from 'styled-components';
import plus from 'assets/plus.svg';

const Add = styled.div`
  display: block;
  position: fixed;
  bottom: 20px;
  right: 30px;

  width: 45px;
  height: 45px;
  cursor: pointer;
  background: #e9eaec url(${plus}) no-repeat center;
  margin-bottom: 5px;
  opacity: 1;
  border-radius: 50%;
  transform: rotate(45deg);

  transition: background 0.2s;

  &:hover {
    background: #c9cacc url(${plus}) no-repeat center;
  }
`;

export default Add;

import styled from 'styled-components';
import close from 'assets/close.svg';

export default styled.div`
  position: absolute;
  right: 5px;
  top: 15px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: url(${close}) no-repeat center;
  background-size: 12px 12px;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { clearSelectedDatasets } from 'modules/ui/actions';

import { selectedIdsDatasetsSelector } from 'modules/ui/selectors';

import Panel from './Selected';

const mapStateToProps = createImmutableSelector(
  selectedIdsDatasetsSelector,
  selectedIds => ({
    selectedIds,
  })
);

const mapDispatchToProps = {
  clearSelectedDatasets,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Panel);

import React from 'utils/react';
import moment from 'moment-timezone';
import { getTimeInMinutes } from 'utils/simulations';
import CheckBox from 'components/CheckBox';

import debug from 'utils/debug';
import Container from './Container';
import RowContainer from './RowContainer';
import Link from './Link';
import Column from './Column';
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';
import MenuButton from './MenuButtom';
import Status from './Status';

const D2 = debug('p:Simulations:Table:Row');

const Row = ({ simulation, selectedIds, ...props }) =>
  D2.S.FUNCTION('Row', { simulation, selectedIds, ...props }, () => {
    const now = moment();
    const start_time = moment(simulation.get('start_time'));
    const end_time = moment(simulation.get('end_time'));
    const date = moment(simulation.get('start_time'))
      .tz(global.GEODISC_TIMEZONE)
      .format('ddd MMM DD, YYYY');
    const bookmarked = simulation.get('bookmarked');
    const transfer_rate = simulation.get('transfer_rate');
    const id = simulation.get('id');

    const onSelected = () => {
      props.toggleSelectedSimulations(simulation);
    };

    const playSimulation = () => {
      global.geodisc$setWindowLocation(`/simulation/${id}`);
    };

    const exportSimulation = () => {
      window.open(
        `${window.GEODISC_API_URL}/api/v2/simulation/${id}/export`,
        '_blank'
      );
    };

    const deleteSimulation = () => {
      props.updateSimulation(id, JSON.stringify({ deleted: true }));
    };

    const onClickStar = () => {
      const body = JSON.stringify({ bookmarked: !bookmarked });
      props.updateSimulation(id, body);
    };

    const isDeleted = simulation.get('deleted') ? 'deleted' : undefined;
    const isCompleted =
      !isDeleted && now.isAfter(end_time) ? 'completed' : undefined;
    const isOperation =
      !isCompleted && now.isSameOrAfter(start_time) ? 'running' : undefined;
    const isActive = isOperation;

    const state = isDeleted || isOperation || isCompleted || 'created';

    return (
      <Container isActive={isActive}>
        <LeftColumn style={{ paddingLeft: 20 }}>
          <CheckBox
            isActive={selectedIds.includes(id)}
            onClick={() => onSelected(id)}
            style={{ marginRight: 20 }}
          />
        </LeftColumn>
        <RowContainer>
          <Link to={`/map?simulation=${simulation.get('id')}`}>
            {false && <Column width='70px'>{simulation.get('id')}</Column>}
            <Column width='400px'>{simulation.get('name')}</Column>
            <Column width='150px'>
              <Status
                state={state}
                onClickStar={onClickStar}
                bookmarked={bookmarked}
              />
            </Column>
            {false && (
              <Column style={{ paddingLeft: 20 }} width='200px'>
                {simulation.get('dataset_name')}
              </Column>
            )}
            {false && (
              <Column width='200px'>{simulation.get('geofence_name')}</Column>
            )}
            {false && <Column>{simulation.get('number_of_vehicles')}</Column>}
            {false && (
              <Column>
                {getTimeInMinutes(simulation.get('min_waiting_time'))}
              </Column>
            )}
            {false && (
              <Column>
                {getTimeInMinutes(simulation.get('max_waiting_time'))}
              </Column>
            )}
            {false && <Column>{simulation.get('bookings_count')}</Column>}
            {false && <Column>{simulation.get('vehicle_capacity')}</Column>}
            {false && (
              <Column width='150px'>
                {getTimeInMinutes(
                  simulation.get('max_additional_journey_time')
                )}
              </Column>
            )}
            {false && (
              <Column width='120px'>
                {getTimeInMinutes(simulation.get('min_advance_booking_window'))}
              </Column>
            )}
            {false && (
              <Column width='150px'>
                {getTimeInMinutes(simulation.get('max_advance_booking_window'))}
              </Column>
            )}
            <Column width='200px'>{date}</Column>
            {false && (
              <Column>{transfer_rate && transfer_rate.toFixed(0)}</Column>
            )}
          </Link>
          <RightColumn>
            <MenuButton
              playSimulation={playSimulation}
              exportSimulation={exportSimulation}
              deleteSimulation={deleteSimulation}
            />
          </RightColumn>
        </RowContainer>
      </Container>
    );
  });

export default Row;

import React from 'utils/react';
import { ProgressBar } from 'baseui/progress-bar';

import styled from 'styled-components';

const OuterContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  text-align: center;
  color: #848c98;

  align-items: center;
  justify-content: center;
`;

const InnerContainer = styled.div`
  position: relative;
  width: 400px;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 20px;
`;

export default ({ progress, status }) => {
  const overrides = {
    BarProgress: {
      style: ({ $theme }) => ({
        ...$theme.typography.font350,
        backgroundColor: $theme.colors.negative,
      }),
    },
  };

  return (
    <OuterContainer>
      <InnerContainer>
        <ProgressBar
          overrides={overrides}
          value={progress * 100}
          getProgressLabel={
            progress >= 1
              ? () => `${status}...`
              : (value) => {
                  return `${status} (${value}%)`;
                }
          }
          infinite={progress >= 1}
          showLabel
        />
      </InnerContainer>
    </OuterContainer>
  );
};

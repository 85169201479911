import styled from 'styled-components';
import menuHover from './more-menu-hover.svg';
import menu from './more-menu.svg';

const Menu = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  background: url(${({ icon }) => icon || menu}) no-repeat center;
  opacity: ${({ toggledOn }) => (toggledOn ? 1 : 0.5)};
  cursor: pointer;

  &:hover {
    background-image: url(${({ iconHover }) => iconHover || menuHover});
    opacity: 1;
  }
`;

export default Menu;

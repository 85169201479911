import styled from 'styled-components';

const Column = styled.div`
  display: flex;
  position: sticky;
  left: 0;

  align-items: center;
  width: 10px;
  min-width: 10px;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  min-height: 70px;
  padding-right: 20px;
  flex-shrink: 0;
  z-index: 1;

  &:hover {
    + div {
      background-color: #424a5a;
    }
  }
`;

export default Column;

import styled from 'styled-components';
import arrow from 'assets/arrow.svg';

const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin: 0;

  cursor: pointer;
  padding: 0 0.5rem;

  &:after {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 1px;
    right: -25px;
    content: ' ';
    background: url(${arrow}) no-repeat center;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export default Container;

import styled from 'styled-components';

const Column = styled.div`
  display: flex;
  position: sticky;
  left: 0;

  align-items: center;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  padding-right: 10px;
  flex-shrink: 0;
  z-index: 1;
  padding-left: 25px;
`;

export default Column;

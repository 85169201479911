import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import {
  selectedSimulationsSelector,
  compareModSelector,
} from 'modules/ui/selectors';
import { toggleCompareMod } from 'modules/ui/actions';

import Compare from './Compare';

const mapStateToProps = createImmutableSelector(
  selectedSimulationsSelector,
  compareModSelector,
  (simulations, compareMod) => ({
    simulations,
    compareMod,
  })
);

const mapDispatchToProps = {
  onClose: toggleCompareMod,
};

export default connect(mapStateToProps, mapDispatchToProps)(Compare);

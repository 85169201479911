import { Promise as BlueBirdPromise } from 'bluebird';

import CSV from 'comma-separated-values';

import debug from 'utils/debug';

const D2 = debug('u:csv');

export const csvToMap = (csv, opts) => {
  const [firstLine, ...lines] = csv;

  const params = opts || {};
  const columns = params.columns || new Map();

  const header = firstLine.map((column) => {
    const key = column.trim();
    return columns.has(key) ? columns.get(key) : key;
  });

  const result = lines.map(line =>
    header.reduce(
      (curr, next, index) => ({
        ...curr,
        [next]: line[index],
      }),
      {}
    )
  );

  return result;
};

export const csvToJson = async (csv) => {
  const [firstLine, ...lines] = await csv.split('\n');

  const json = await lines.map(line =>
    firstLine.split(',').reduce(
      (curr, next, index) => ({
        ...curr,
        [next]: line.split(',')[index],
      }),
      {}
    )
  );

  const result = await json.map((value, i) => {
    const newValue = value;
    newValue.id = i;
    return newValue;
  });

  return result;
};

export const readCsvFile = (file, callback) => {
  D2.S.INFO('readCsvFile', file);

  const reader = new FileReader();

  reader.onloadend = (e) => {
    if (e.target.readyState === FileReader.DONE) {
      const fileData = e.target.result;
      D2.S.INFO('readCsvFile:Done', { fileData });

      const data = new CSV(fileData).parse();
      D2.S.INFO('readCsvFile:Data', { data, fileData });

      callback(null, data);
    }
  };

  const blob = file.slice(0, 1024 ** 3);
  reader.readAsText(blob, 'UTF-8');
};

export const readCsvFileAsync = BlueBirdPromise.promisify(readCsvFile);

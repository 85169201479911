import moment from 'moment-timezone';
import debug from 'utils/debug';

const D2 = debug('m:CommuteOffers:Trips:ConsolidatedTableFromCommuteOffer');

// eslint-disable-next-line import/prefer-default-export
export const commuteOffers$Trips$ConsolidatedTableColumns = () => [
  ...[
    {
      kind: 'STRING',
      name: 'trip_id',
      label: 'Trip',
      width: '100px',
    },
    {
      kind: 'STRING',
      name: 'scheduled_ts',
      label: 'Time',
      width: '100px',
    },
    {
      kind: 'STRING',
      name: 'location_name',
      label: 'Location',
      width: 'minmax(350px, max-content)',
    },
    {
      kind: 'STRING',
      name: 'pickup_passengers',
      label: 'Passengers to be picked up',
      width: 'minmax(350px, max-content)',
    },
    {
      kind: 'STRING',
      name: 'dropoff_passengers',
      label: 'Passengers to be dropped off',
      width: 'minmax(350px, max-content)',
    },
  ],
];

// eslint-disable-next-line import/prefer-default-export
export const commuteOffers$Trips$ConsolidatedTableFromCommuteOffer = commuteOffer =>
  D2.S.FUNCTION('Request', { commuteOffer }, ({ $D2 }) => {
    const { vehicles, bookings } = commuteOffer.stateless_api_request_data;
    $D2.S.INFO('vehicles', { vehicles });
    $D2.S.INFO('bookings', { bookings });

    const vehiclesWithTripNames = vehicles.map((vehicle, index) => ({
      ...vehicle,
      $tripName: `Trip #${index + 1}`,
    }));

    const resultTable = vehiclesWithTripNames.reduce(
      (resultTableMemo, vehicle) => {
        const route = commuteOffer.result.vehicles[vehicle.agent_id];
        $D2.S.INFO('resultTable:route', { vehicle, route });

        if (!route) {
          return resultTableMemo;
        }

        const routeStops0 = route.reduce(
          (routeStopsMemo, node) => {
            const { lastStop } = routeStopsMemo;

            const booking = bookings[node.booking_uid];
            $D2.S.INFO('resultTable:route:booking', {
              booking,
              bookings,
              node,
              vehicle,
              route,
            });

            if (!booking) {
              return routeStopsMemo;
            }

            if (!routeStopsMemo.lastStop || lastStop.stop_id !== node.stop_id) {
              const {
                service_time,
                slack,
                stop_id,
                scheduled_ts,
                location_code,
                location_name,
                lon,
                lat,
              } = node;
              const newStops = routeStopsMemo.lastStop
                ? [...routeStopsMemo.stops, routeStopsMemo.lastStop]
                : [];
              return {
                stops: newStops,
                lastStop: {
                  nodes: [node],
                  pickup_passengers:
                    node.node_type === 'pickup'
                      ? [booking.name || node.uid]
                      : [],
                  dropoff_passengers:
                    node.node_type === 'dropoff'
                      ? [booking.name || node.uid]
                      : [],
                  service_time,
                  slack,
                  stop_id,
                  scheduled_ts,
                  location_code,
                  location_name,
                  lon,
                  lat,
                },
              };
            }

            const {
              nodes,
              service_time,
              slack,
              pickup_passengers,
              dropoff_passengers,
              stop_id,
              scheduled_ts,
              location_code,
              location_name,
              lon,
              lat,
            } = routeStopsMemo.lastStop;

            return {
              ...routeStopsMemo,
              lastStop: {
                ...routeStopsMemo.lastStop,
                nodes: [...nodes, node],
                service_time: service_time + node.service_time,
                slack: slack + node.slack,
                pickup_passengers:
                  node.node_type === 'pickup'
                    ? [...pickup_passengers, booking.name || node.uid]
                    : pickup_passengers,
                dropoff_passengers:
                  node.node_type === 'dropoff_passengers'
                    ? [...dropoff_passengers, booking.name || node.uid]
                    : dropoff_passengers,
                stop_id,
                scheduled_ts,
                location_code,
                location_name,
                lon,
                lat,
              },
            };
          },
          { stops: [], lastNode: null }
        );
        $D2.S.INFO('routeStops:routeStops0', {
          vehicle,
          route,
          routeStops0,
        });

        const routeStops = routeStops0.lastStop
          ? [...routeStops0.stops, routeStops0.lastStop]
          : [];

        const routeSource = routeStops;

        const tripRecords = routeSource.map((node) => {
          D2.S.INFO('resultTable:node', {
            vehicle,
            route,
            node,
          });

          return [
            vehicle.$tripName,
            moment(node.scheduled_ts)
              .tz(global.GEODISC_TIMEZONE)
              .format('HH:mm'),
            node.location_name || '',
            node.pickup_passengers.join(', '),
            node.dropoff_passengers.join(', '),
          ];
        });
        $D2.S.INFO('resultTable:tripRecords', { vehicle, route, tripRecords });

        return [...resultTableMemo, ...tripRecords];
      },
      []
    );

    $D2.S.INFO('resultTable', {
      commuteOffer,
      resultTable,
    });

    return resultTable;
  });

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import {
  changeSimulationTime,
  simulationPlay,
  simulationStop,
  setSimulationSpeed,
} from 'modules/simulations/actions';

import {
  simulationTimeSelector,
  simulationRunningSelector,
  simulationSpeedSelector,
  isDataLoadedSelector,
  progressSelector,
  minMaxTimeSelector,
  simulationIsLiveSelector,
} from 'modules/simulations/selectors';

import TimeSlider from './TimeSlider';

const mapStateToProps = createImmutableSelector(
  simulationTimeSelector,
  isDataLoadedSelector,
  simulationRunningSelector,
  minMaxTimeSelector,
  simulationSpeedSelector,
  progressSelector,
  simulationIsLiveSelector,
  (time, isData, isRunning, minMax, speed, progress, isLive) => ({
    time,
    loading: !isData,
    isRunning,
    minMax,
    speed,
    type: 'simulation',
    progress,
    isLive,
    isUnix: true,
    step: 60,
  })
);

const mapDispatchToProps = {
  onChange: changeSimulationTime,
  onPlay: simulationPlay,
  onStop: simulationStop,
  setSpeed: setSimulationSpeed,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(TimeSlider);

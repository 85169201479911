import withTranslation from 'utils/react/withTranslation';
import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { addGeofence } from 'modules/geofences/actions';
import { fetchDatasets } from 'modules/datasets/actions';
import { listSelector as datasetsListSelector } from 'modules/datasets/selectors';
import { currentProjectUrlSelector } from 'modules/user/selectors';

import NewGeofence from './NewGeofence';

const mapStateToProps = createImmutableSelector(
  datasetsListSelector,
  currentProjectUrlSelector,
  (datasets, projectUrl) => ({ datasets, projectUrl })
);

const mapDispatchToProps = {
  addGeofence,
  fetchDatasets,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(withTranslation()(NewGeofence));

import React from 'utils/react';
import { exportFile, readBinaryFileAsync } from 'utils/file';
import { sleep } from 'utils/async';
import XLSX from 'xlsx';
import Immutable from 'immutable';
import moment from 'moment-timezone';
// import CSV from 'comma-separated-values';
// import normalizeTime from 'utils/normalize-time';
// import { removeInternalFieldsFromObject } from 'utils/object';

// import Menu from 'components/Menu';
import {
  // commuteOffer$GanttScheduleTimeline,
  commuteOffers$Trips$TableFromCommuteOffer,
  commuteOffers$Trips$ConsolidatedTableColumns,
  commuteOffers$Trips$ConsolidatedTableFromCommuteOffer,
  commuteOffer$FilterVehicles,
} from 'utils/CommuteOffer';
import { commuteSchedule$PassengersTableFromOffer } from 'utils/CommuteSchedule';
import { api$CommuteOffer as api } from 'api';
import { useStyletron } from 'baseui';
import { Button, KIND, SIZE } from 'baseui/button';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { StatefulMenu } from 'baseui/menu';

import { readCsvFileAsync } from 'utils/csv';
import logisticsCsvToJson from 'utils/Logistics/OrdersTable/Import/logisticsCsvToJson';
import exportLogisticsOrders from 'utils/Logistics/OrdersTable/Export/exportLogisticsOrders';
import { importLogisticsOrders } from 'api/logistics';
import {
  waitSimulationProcessor,
  waitSimulationUncalculatedBookings,
} from 'api/simulations';
import * as Icons from 'baseui/icon/icon-exports';
import MenuIcon from 'components/MenuIcon';
import debug from 'utils/debug';
import Fleet from 'components/Fleet';
import AdvancedSettings from 'components/AdvancedSettings';
import Container from './Container';
import Search from './Search';
import ButtonAction from './ButtonAction';
import ButtonHistory from './ButtonHistory';
import ButtonSave from './ButtonSave';
import ProjectLabel from './ProjectLabel';
import NameLabel from './NameLabel';
import MenuContainer from './MenuContainer';

// import layersIcon from './layers-enabled.svg';

import undoImgEnabled from './undo-enabled.svg';
import undoImgDisabled from './undo-disabled.svg';

import redoImgEnabled from './redo-enabled.svg';
import redoImgDisabled from './redo-disabled.svg';
import savingImg from './saving.svg';

// import MenuButton from './MenuButton';

const D2 = debug('p:CommuteOffer:Panels:TopButtons');

const TopButtons = (props) => {
  D2.S.INFO('TopButtons', { props });

  const [css, theme] = useStyletron();
  D2.S.INFO('theme', { css, theme });
  const {
    t,
    i18n,
    back,
    next,
    commuteOfferRequestUpdate,
    enableForceReload,
    isReadOnly,
    currentProject,
    currentProjectConfig,
    projects,
    data,
    originalData,
    updateCommuteOffer,
    changeCurrentProject,
    commuteOfferStatelessNodeScheduler,
    openPopup,
    closePopup,
    history,
    isSaving,
    isBookingsHidden,
    filteredVehicleIds,
    activeVehicleIds,
    simulation,
    routes,
    isDeliveryLayout,
    isFilteringEnabled,
    isSuperuser,
    isTemplate,
    isCurrentDateEditable,
    canBeSaved,
    invalidateAllBookings,
    hasOnlineVehicles,
    unfilteredOrders,
  } = props;

  const totalOrdersCount = unfilteredOrders?.length ?? 0;

  const isDataValid = !!data;
  const isDataTransient = isDataValid && !data.id;

  const currentProjectId = currentProject ? currentProject.get('id') : 0;
  D2.S.INFO('currentProjectId', { currentProjectId, currentProject });

  const projectId =
    data && data.id
      ? (() => {
          const re = /\/.*\/([0-9]*)$/;
          return parseInt(data.project.match(re)[1], 10);
        })()
      : currentProjectId;
  D2.S.INFO('projectId', { projectId, currentProject, data });

  const projectInfo =
    projectId &&
    projects &&
    Immutable.isImmutable(projects) &&
    projects.find(item => item.get('id') === projectId);

  D2.S.INFO('projectInfo', { projectInfo, projectId, currentProject, data });

  const projectName = projectInfo ? projectInfo.get('name') : undefined;

  const onImportPassengersSchedule = () => {
    D2.S.INFO('onImportPassengersSchedule', { data });

    openPopup('CommuteScheduleImportPassengers', {
      title: t('p.Editor.Menu.Silveray.ImportPassengersSchedule'),
      data,
    });
  };

  const errorFormatters = {
    'Some postal codes not found': response =>
      `${response.error}: ${response.details.join(',')}`,
  };

  const onImportOrders = () => {
    global.R();
    return D2.S.FUNCTION('onImportOrders', { simulation }, ({ $D2 }) => {
      global.openFileUploaderDialog({
        title: t('p.Editor.Menu.Orders.UploadFile'),
        onDrop: async ({ acceptedFiles, rejectedFiles }) => {
          $D2.S.INFO('onDrop', {
            acceptedFiles,
            rejectedFiles,
            simulation,
            currentProject,
          });

          global.openProgressWindow(t('c.messages.Processing'));

          const timezone = currentProject.get('timezone');

          const reasXlsFileAsync = async (file) => {
            try {
              const fileData = await readBinaryFileAsync(file);

              const workbook = XLSX.read(fileData, { type: 'binary' });

              const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]];

              const worksheet =
                workbook.SheetNames.length > 0 && workbook?.Sheets.Sheet1
                  ? workbook.Sheets.Sheet1
                  : firstWorksheet;

              return worksheet
                ? XLSX.utils
                    .sheet_to_json(worksheet, { header: 1 })
                    .filter(row => row.length > 0)
                : null;
            } catch (error) {
              return null;
            }
          };

          try {
            // console.log('*** acceptedFiles:', acceptedFiles);
            // const csvResults = await Promise.all(
            //   acceptedFiles.map(acceptedFile => readCsvFileAsync(acceptedFile))
            // );
            const csvResultsRaw = await Promise.all(
              acceptedFiles.map(async acceptedFile =>
                acceptedFile.type ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  ? reasXlsFileAsync(acceptedFile)
                  : readCsvFileAsync(acceptedFile)
              )
            );

            const csvResults = csvResultsRaw.filter(x => !!x);
            $D2.S.INFO('csvResults', { csvResults });
            const importColumnMap = projectInfo
              ?.get('data')
              ?.get('import_column_map');

            const jsResults = await Promise.all(
              csvResults.map(csvData =>
                logisticsCsvToJson({ data: csvData, importColumnMap })
              )
            );

            $D2.S.INFO('jsResults', { jsResults, csvResults });

            const jsData = jsResults.reduce(
              (memo, jsResult) => [...memo, ...jsResult.objects],
              []
            );
            $D2.S.INFO('jsData', { jsData, jsResults, csvResults });

            const result = await importLogisticsOrders(simulation.id, jsData, {
              defaultDate: moment(simulation.start_time)
                .tz(timezone)
                .format('YYYY-MM-DD'),
              timezone,
              currentProjectConfig,
            });
            $D2.S.INFO('result', { result, jsData, jsResults, csvResults });

            if (result.response?.error) {
              const { response } = result;
              const errorFormatter = errorFormatters[response.error];
              throw new Error(
                `${t('p.Editor.Menu.Logistics.ImportError')}: ${
                  errorFormatter ? errorFormatter(response) : response.error
                }`
              );
            }

            if (result.error) {
              throw new Error(result.error);
            }

            await waitSimulationProcessor(result.response?.processor_id);

            await waitSimulationUncalculatedBookings(simulation.id);
            await sleep(5000);

            global.closeProgressWindow();
            global.openInfoMessage(
              t('delivery.orders.import.success', {
                count: result.request.bookings.length,
              }),
              {
                title: t('info'),
              }
            );

            enableForceReload();
            commuteOfferRequestUpdate(null, {
              isInitRequired: false,
              forceReload: true,
              ...$D2.CONTEXT,
            });
          } catch (e) {
            global.closeProgressWindow();
            global.openInfoMessage(e.message, { title: t('error') });
            return null;
          }

          return null;
        },
      });
    });
  };

  const onExportOrders = () => {
    global.R();
    const exportColumnMap = projectInfo?.get('data')?.get('export_column_map');

    return exportLogisticsOrders(data, simulation, {
      t,
      language: i18n.language,
      projectName,
      exportColumnMap,
    });
  };

  const onExportAsJson = () => {
    D2.S.INFO('onExportAsJson', { data });

    const { name, result, commute_preferences, stateless_api_request_data } =
      data;

    const dataForExport = {
      name,
      result,
      commute_preferences,
      stateless_api_request_data,
    };

    exportFile(JSON.stringify(dataForExport, null, '\t'), `${name}.json`);
  };

  const onExportProtocolFile = () => {
    global.R();

    D2.A.FUNCTION('onExportProtocolFile', { data, routes }, async () => {
      const timezoneArg = [
        'timezone',
        encodeURIComponent(global.GEODISC_TIMEZONE),
      ].join('=');
      const args = [timezoneArg].join('&');

      if (!global.GEODISC_COMMUTE_OFFER_USE_BINARY_PROTOCOL) {
        window.open(
          `${window.GEODISC_API_URL}/api/v2/commuteoffer/${data.id}/protocol?${args}`,
          '_blank'
        );
      } else if (isDataValid) {
        try {
          const protocol = await api.fetchProtocolFile(
            data.id,
            global.GEODISC_TIMEZONE
          );
          if (protocol.data) {
            global.geodisc$exportFile(protocol.data, 'protocol.xlsx');
            return global.openInfoMessage(
              'Protocol file was generated successfully',
              {
                title: t('info'),
              }
            );
          }
        } catch (e) {
          return global.openInfoMessage(
            `${t('p.Editor.Menu.ProtocolFile.FailedToExport')}: ${e.message}`,
            { title: t('error') }
          );
        }
      }
      return null;
    });
  };

  const onCommuteOfferAdmin = () => {
    D2.S.INFO('onCommuteOfferAdmin', { data });

    if (isDataValid) {
      window.open(
        `${window.GEODISC_API_URL}/admin/commute/commuteoffer/${data.id}/change/`,
        '_blank'
      );
    }
  };

  const onSimulationAdmin = () => {
    D2.S.INFO('onSimulationAdmin', { data });

    if (isDataValid) {
      window.open(
        `${window.GEODISC_API_URL}/admin/simulation/simulation/${simulation.id}/change/`,
        '_blank'
      );
    }
  };

  const onSimulationPipelineLog = () => {
    D2.S.INFO('onSimulationPipelineLog', { data });

    if (isDataValid) {
      window.open(
        `${window.GEODISC_API_URL}/api/v2/pipelinelog/?simulation=${simulation.id}`,
        '_blank'
      );
    }
  };

  const doSaveAs = () => {
    global.R();
    D2.S.INFO('doSaveAs', { data, filteredVehicleIds, activeVehicleIds });

    const existingActiveVehicleIds = activeVehicleIds.filter(
      id => id !== 'DEADBEEF'
    );

    global.openNameAndProject('', {
      title: 'Save as...',
      isSelectionAllowed:
        existingActiveVehicleIds.size || filteredVehicleIds.size,
      onSubmit: async ({ name, project, selection }) => {
        D2.S.INFO('doSaveAs:onSubmit', {
          name,
          project,
          selection,
          data,
          originalData,
          filteredVehicleIds,
          existingActiveVehicleIds,
        });
        try {
          const vehicleSelectors = {
            all: () => data,
            selected: () =>
              commuteOffer$FilterVehicles(
                data,
                existingActiveVehicleIds.toJS()
              ),
            visible: () =>
              commuteOffer$FilterVehicles(data, filteredVehicleIds.toJS()),
          };
          const selectedVehicles = vehicleSelectors[selection]();
          D2.S.INFO('doSaveAs:onSubmit:selectedVehicles', {
            selection,
            selectedVehicles,
          });
          const commuteOffer = {
            ...selectedVehicles,
            name,
            project: `/api/v2/project/${project}`,
            resource_uri: undefined,
            id: undefined,
          };
          D2.S.INFO('doSaveAs:onSubmit:commuteOffer', { commuteOffer });
          const { id } = await api.addCommuteOffer(commuteOffer);
          D2.S.INFO('doSaveAs:onSubmit:Saved', { id });
          global.openInfoMessage(t('p.Editor.CommuteOffer.SavedSuccessfully'), {
            title: t('info'),
          });
        } catch (error) {
          global.openInfoMessage(error.message, {
            title: t('error'),
          });
        }
      },
    });
  };

  const onSave = () =>
    D2.S.FUNCTION('onSave', { data, id: data.id, isDataValid }, () => {
      if ((isDataValid && data.id) || data.$source?.simulation) {
        global.openProgressWindow(t('c.ProgressWindow.Validating'), {
          title: t('info'),
        });

        setTimeout(() => {
          updateCommuteOffer(data.id, data, originalData, true, t);
        }, 0);
      } else if (!isDataValid) {
        global.openInfoMessage(t('p.Editor.CommuteOffer.CannotBeSaved'), {
          title: t('error'),
        });
      } else {
        doSaveAs();
      }
    });

  const onSaveAs = () => {
    D2.S.INFO('onSaveAs', { data });

    if (!isDataValid) {
      global.openInfoMessage(t('p.Editor.CommuteOffer.CannotBeSaved'), {
        title: t('error'),
      });
    } else {
      doSaveAs();
    }
  };

  const onRecalculate = () => {
    D2.S.INFO('onRecalculate', { data });

    if (isDataValid) {
      const { stateless_api_request_data } = data;

      openPopup('ProgressWindow', {
        title: t('info'),
        isCloseDisabled: true,
        status: 'Calculating',
        progress: 1,
      });

      const { nodes, vehicles, engine_settings, current_time } =
        stateless_api_request_data;
      commuteOfferStatelessNodeScheduler(
        {
          nodes,
          vehicles,
          engine_settings,
          current_time,
        },
        {
          openPopup,
          closePopup,
        }
      );
    }
  };

  const onInvalidateAllBookings = () => {
    global.openWarningMessage({
      title: t('p.Orders.footer.menu.removeAllOrders'),
      message: t('p.Orders.removeAllOrders.dialog.message'),
      buttons: [
        {
          text: t('c.messages.Cancel'),
          fill: false,
        },
        {
          text: t('p.Orders.footer.menu.removeAllOrders'),
          action: () => {
            invalidateAllBookings(data);
            global.closeWarningMessage();
            global.closeFullScreen();
          },
          fill: true,
        },
      ],
    });
  };

  const onViewPassengers = () => {
    D2.S.INFO('onViewPassengers', { data });

    if (isDataValid) {
      const { $remarks } = data.stateless_api_request_data.inbound;
      global.openPassengersTableViewer(
        commuteSchedule$PassengersTableFromOffer(data),
        {
          title: 'Passengers schedule',
          fileName: `${data.name ? `${data.name} - ` : ''}Passengers.csv`,
          additionalData: $remarks ? [$remarks] : [],
        }
      );
    }
  };

  const onViewTrips = () => {
    D2.S.INFO('onViewTrips', { data });

    if (isDataValid) {
      const { $remarks } = data.stateless_api_request_data.inbound;
      global.openPassengersTableViewer(
        commuteOffers$Trips$TableFromCommuteOffer(
          commuteOffer$FilterVehicles(data, filteredVehicleIds)
        ),
        {
          title: 'Trips',
          fileName: `${data.name ? `${data.name} - ` : ''}Trips.csv`,
          additionalData: $remarks ? [$remarks] : [],
        }
      );
    }
  };

  const onViewConsolidatedTrips = () => {
    D2.S.INFO('onViewConsolidatedTrips', { data, filteredVehicleIds });

    if (isDataValid) {
      global.openTableViewer(
        commuteOffers$Trips$ConsolidatedTableColumns(),
        commuteOffers$Trips$ConsolidatedTableFromCommuteOffer(
          commuteOffer$FilterVehicles(data, filteredVehicleIds)
        ),
        {
          title: 'Trips',
          fileName: `${
            data.name ? `${data.name} - ` : ''
          }Consolidated Trips.csv`,
        }
      );
    }
  };

  // const onViewTimeline = () => {
  //   D2.S.INFO('onViewTimeline', { data });

  //   if (isDataValid) {
  //     global.R();
  //     const config = commuteOffer$GanttScheduleTimeline(
  //       data,
  //       global.GEODISC_TIMEZONE
  //     );
  //     D2.S.INFO('onViewTimeline:config', { config });

  //     global.openTimelineViewer(config, {
  //       title: 'Vehicles timeline'
  //     });
  //   }
  // };

  const onViewRequestSource = () => {
    D2.S.INFO('onViewRequestSource', { data });

    if (isDataValid) {
      global.openSourceEditor(data.stateless_api_request_data, {
        title: 'Request source',
        readOnly: true,
      });
    }
  };

  const onViewSource = () => {
    D2.S.INFO('onViewSource', { data });

    if (isDataValid) {
      global.openSourceEditor(data, { title: 'Commute Offer', readOnly: true });
    }
  };

  const onMenuItemSelect = (args) => {
    D2.S.INFO('onMenuItemSelect', { args });

    const { item, event } = args;

    const { fn = () => {} } = item;

    return fn(event);
  };

  if (
    currentProject &&
    projects.size &&
    projectId &&
    projectId !== currentProjectId
  ) {
    setTimeout(() => {
      changeCurrentProject(projectInfo);
    }, 0);
  }

  const isUrbicaTestDev =
    isDataValid &&
    (projectName === 'urbica_testdev' ||
      projectName === 'urbica_silveray_testdev' ||
      projectName === 'Plum' ||
      projectName === 'Silveray' ||
      projectName === 'Silveray Project');
  D2.S.INFO('isUrbicaTestDev', {
    isDataValid,
    projectId,
    projectInfo,
    isUrbicaTestDev,
  });

  const isPassengersTableExists =
    isDataValid &&
    data.stateless_api_request_data.inbound &&
    data.stateless_api_request_data.inbound.schedule &&
    !!Object.keys(data.stateless_api_request_data.inbound.schedule).length;
  D2.S.INFO('isPassengersTableExists', {
    isDataValid,
    data,
    isPassengersTableExists,
  });

  const menuAdminItems =
    isDataValid && data.id && !isReadOnly && !isDeliveryLayout
      ? [{ label: t('p.Editor.Menu.Admin'), fn: onCommuteOfferAdmin }]
      : [];
  const menuSaveItems =
    isDataValid && !isDeliveryLayout
      ? [
          { label: t('p.Editor.Menu.SaveAs'), fn: onSaveAs },
          { label: t('p.Editor.Menu.CommuteOffer.Export'), fn: onExportAsJson },
          {
            label: t('p.Editor.Menu.ProtocolFile.Export'),
            fn: onExportProtocolFile,
          },
        ]
      : [
          { label: t('p.Editor.Menu.SaveAs'), fn: onSaveAs },
          { label: t('p.Editor.Menu.CommuteOffer.Export'), fn: onExportAsJson },
        ];
  const menuImportPassengersItems =
    !global.GEODISC_STATELESS_API_DISABLE &&
    (isUrbicaTestDev || isDataTransient) &&
    isDataValid &&
    !isReadOnly &&
    !isDeliveryLayout
      ? [
          {
            label: t('p.Editor.Menu.Silveray.ImportPassengers'),
            fn: onImportPassengersSchedule,
          },
        ]
      : [];
  // console.log('*** simulation', { simulation, isDataValid, isSuperuser });
  const menuOrdersPrivateItems =
    simulation &&
    isDataValid &&
    isSuperuser &&
    global.GEODISC_LOGISTICS_PRIVATE_FUNCTIONS_ENABLED
      ? [
          { label: t('p.Editor.Menu.Admin'), fn: onSimulationAdmin },
          {
            label: t('p.Editor.Menu.PipelineLog'),
            fn: onSimulationPipelineLog,
          },
          { label: t('p.Editor.Menu.Silveray.ViewTrips'), fn: onViewTrips },
          {
            label: t('p.Editor.Menu.Silveray.ViewConsolidatedTrips'),
            fn: onViewConsolidatedTrips,
          },
        ]
      : [];
  const menuOrdersPublicItems =
    simulation && isDataValid && isDeliveryLayout
      ? [
          {
            label: t('p.Editor.Menu.Logistics.ExportResults'),
            fn: onExportOrders,
          },
        ]
      : [];
  const menuViewPassengersItems =
    !global.GEODISC_STATELESS_API_DISABLE &&
    (isUrbicaTestDev || isDataTransient) &&
    isPassengersTableExists &&
    isDataValid &&
    !isReadOnly &&
    !isDeliveryLayout &&
    global.openTableViewer
      ? [
          {
            label: t('p.Editor.Menu.Silveray.ViewPassengers'),
            fn: onViewPassengers,
          },
        ]
      : [];
  const menuViewTripsItems =
    isDataValid && global.openTableViewer && !isReadOnly
      ? [
          { label: t('p.Editor.Menu.Silveray.ViewTrips'), fn: onViewTrips },
          {
            label: t('p.Editor.Menu.Silveray.ViewConsolidatedTrips'),
            fn: onViewConsolidatedTrips,
          },
        ]
      : [];
  // const menuViewTimelineItems =
  //   isDataValid && global.openTimelineViewer
  //     ? [{ label: t('p.Editor.Menu.ViewTimeline'), fn: onViewTimeline }]
  //     : [];
  const menuViewSourceItems =
    isDataValid && global.openSourceEditor && !isReadOnly && !isDeliveryLayout
      ? [
          { label: t('p.Editor.Menu.ViewRequest'), fn: onViewRequestSource },
          { label: t('p.Editor.Menu.ViewSource'), fn: onViewSource },
        ]
      : [];

  const menuRemoveAllItems =
    isDataValid &&
    !isReadOnly &&
    isDeliveryLayout &&
    !isTemplate &&
    totalOrdersCount > 0 &&
    !hasOnlineVehicles
      ? [
          {
            label: t('p.Orders.footer.menu.removeAllOrders'),
            fn: onInvalidateAllBookings,
          },
        ]
      : [];

  const menuRecalculateItems =
    !global.GEODISC_STATELESS_API_DISABLE &&
    isDataValid &&
    !isReadOnly &&
    !isDeliveryLayout
      ? [{ label: t('p.Editor.Menu.Recalculate'), fn: onRecalculate }]
      : [];

  const isExperimentalFunctionalityEnabled =
    global.GEODISC_EXPERIMENTAL_FUNCTIONS_ENABLED && simulation && isDataValid;

  const menuExperimentalEditingItems =
    isExperimentalFunctionalityEnabled && isCurrentDateEditable
      ? [
          {
            label: 'Fleet...',
            fn: () => global.openFullScreen({ modalContent: <Fleet /> }),
          },
        ]
      : [];

  const menuExperimentalCommonItems = isExperimentalFunctionalityEnabled
    ? [
        {
          label: 'Settings...',
          fn: () =>
            global.openFullScreen({ modalContent: <AdvancedSettings /> }),
        },
      ]
    : [];

  const enabledMenuItems = [
    ...menuAdminItems,
    ...menuSaveItems,
    ...menuImportPassengersItems,
    ...menuViewPassengersItems,
    ...menuOrdersPrivateItems,
    ...menuOrdersPublicItems,
    ...menuViewTripsItems,
    // ...menuViewTimelineItems,
    ...menuViewSourceItems,
    ...menuRemoveAllItems,
    ...menuRecalculateItems,
    ...menuExperimentalEditingItems,
    ...menuExperimentalCommonItems,
  ];

  const menuItems = enabledMenuItems.reduce(
    (memo, item) => {
      if (!memo.keys[item.label]) {
        return {
          ...memo,
          items: [...memo.items, item],
          keys: { ...memo.keys, [item.label]: item },
        };
      }
      return memo;
    },
    { items: [], keys: {} }
  );
  D2.S.INFO('menuItems', {
    menuItems,
    enabledMenuItems,
  });

  const isImportOrdersEnabled =
    simulation &&
    isDataValid &&
    isDeliveryLayout &&
    !isTemplate &&
    isCurrentDateEditable;

  const isSaveButtonActive = !!(
    window.GEODISC_UI_COE_SAVE_BUTTON_ALWAYS_ACTIVE ||
    (!!history.past.size && canBeSaved)
  );

  return (
    <Container data-testid='TopButtons-Container'>
      {!isDeliveryLayout && (
        <NameLabel isBookingsHidden={isBookingsHidden}>
          {data ? `${data.name} #${data.id}` : 'Loading...'}
        </NameLabel>
      )}
      {!global.GEODISC_UI_COMMUTE_OFFER_HEADER_ENABLED && (
        <ProjectLabel to='/commuteoffers'>
          {projectId // eslint-disable-line
            ? projectInfo
              ? projectInfo.get('name').trim() || ' '
              : 'Loading...'
            : ''}
        </ProjectLabel>
      )}
      {isFilteringEnabled && false && <Search />}
      {isImportOrdersEnabled && (
        <ButtonAction type='button' onClick={onImportOrders}>
          {t('p.Editor.Panels.Import')}
        </ButtonAction>
      )}
      {!isReadOnly && (
        <ButtonHistory
          type='button'
          isActive={!!history.past.size}
          onClick={history.past.size ? back : null}
        >
          {history.past.size ? (
            <img alt='' src={undoImgEnabled} />
          ) : (
            <img alt='' src={undoImgDisabled} />
          )}
        </ButtonHistory>
      )}
      {!isReadOnly && (
        <ButtonHistory
          type='button'
          isActive={!!history.future.size}
          onClick={history.future.size ? next : null}
        >
          {history.future.size ? (
            <img alt='' src={redoImgEnabled} />
          ) : (
            <img alt='' src={redoImgDisabled} />
          )}
        </ButtonHistory>
      )}
      {!isReadOnly && (
        <ButtonSave
          isActive={isSaveButtonActive}
          isSaving={isSaving}
          type='button'
          onClick={onSave}
        >
          {isSaving ? (
            <img alt='' src={savingImg} />
          ) : (
            t('p.Editor.Panels.Save')
          )}
        </ButtonSave>
      )}
      <StatefulPopover
        placement={PLACEMENT.bottomRight}
        content={({ close }) => (
          <StatefulMenu
            overrides={{
              Option: {
                props: {
                  getItemLabel: item => item.label,
                  size: 'large',
                },
              },
            }}
            onItemSelect={(args) => {
              close();
              onMenuItemSelect(args);
            }}
            items={menuItems.items}
          />
        )}
      >
        <MenuContainer>
          <Button size={SIZE.compact} kind={KIND.minimal}>
            <Icons.Menu size={24} />
            {false && <MenuIcon />}
          </Button>
        </MenuContainer>
      </StatefulPopover>
    </Container>
  );
};

export default React.memo(TopButtons);

import React from 'react';
import { styled } from 'baseui';

const Label = styled('div', () => {
  return {
    color: '#97A0C0',
  };
});
const Value = styled('span', () => {
  return {
    color: 'white',
  };
});

const Wrapper = styled('div', () => {
  return {
    fontSize: '14px',
    fontFamily: 'Montserrat',
    fontWeight: 500,
    textAlign: 'justify',
    marginBottom: '8px',
  };
});

export default (props) => {
  return (
    <Wrapper>
      <Label>{props.title}</Label>
      <Value>{props.value}</Value>
      {props.extraElements}
    </Wrapper>
  );
};

import styled from 'styled-components';

const Input = styled.input`
  display: block;
  position: relative;
  height: 30px;
  outline: none;
  background: transparent;
  border: none;
  font-size: 17px;
  color: #edece8;
  padding: 10px;
  width: 200px;

  &::placeholder {
    letter-spacing: 0px;
    color: #7a818f;
  }
`;

export default Input;

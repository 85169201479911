import React from 'utils/react';
import Loading from 'components/Loading';
import Menu from './Menu';

import FromDataset from './FromDataset';
import Draw from './Draw';
import ChooseFile from './ChooseFile';
// const FromDataset = React.lazy(() => import('./FromDataset'));
// const Draw = React.lazy(() => import('./Draw'));
// const ChooseFile = React.lazy(() => import('./ChooseFile'));

const Component = ({ activeTab, ...otherProps }) => {
  switch (activeTab) {
    case 'fromdataset':
      return <FromDataset {...otherProps} />;
    case 'file':
      return <ChooseFile {...otherProps} />;
    case 'draw':
      return <Draw {...otherProps} />;
    default:
      return <ChooseFile {...otherProps} />;
  }
};

class NewGeofence extends React.PureComponent {
  state = {
    activeTab: 'file',
  };
  componentDidMount() {
    const { datasets } = this.props;
    if (!datasets) {
      this.props.fetchDatasets();
    }
  }

  onChangeTab = (id) => {
    this.setState({ activeTab: id });
  };

  render() {
    const { activeTab } = this.state;

    return (
      <React.Fragment>
        <Menu active={activeTab} onChange={this.onChangeTab} />
        <React.Suspense fallback={<Loading />}>
          <Component activeTab={activeTab} {...this.props} />
        </React.Suspense>
      </React.Fragment>
    );
  }
}

export default NewGeofence;

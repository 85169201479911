import styled from 'styled-components';

export default styled.div`
  scrollbar-width: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
`;

import pointToLineDistance from '@turf/point-to-line-distance';

import debug from 'utils/debug';

const D2 = debug('u:turf');

export default (point, line, opts) =>
  D2.S.FUNCTION('pointToLineDistance', { point, line, opts }, () =>
    pointToLineDistance(point, line, opts)
  );

import styled from 'styled-components';

export default styled.button`
  display: block;
  width: 100%;
  border: none;
  background: transparent;
  text-align: left;
  color: #999999;
  cursor: pointer;

  :hover {
    color: #ffffff;
  }
`;

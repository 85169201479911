import debug from 'utils/debug';
import { getDefaultrPageSize } from './defaults';
import fetchCollectionPage from './fetchCollectionPage';

const D2 = debug('utils:sgerp-api');

function* generatePageOffsets(offset, total, pageSize = getDefaultrPageSize()) {
  for (let i = offset; i < total; i += pageSize) {
    yield i;
  }
}

const fetchCollection = async (api, collection, filter = {}, opts = {}) =>
  D2.A.FUNCTION(
    'fetchCollection',
    { api, collection, filter, opts },
    async ({ $D2 }) => {
      const { pageSize = getDefaultrPageSize() } = opts;

      const firstPage = await fetchCollectionPage(
        api,
        collection,
        0,
        pageSize,
        filter
      );

      if (typeof firstPage.error !== 'undefined') {
        return firstPage;
      }

      if (firstPage.meta.total_count === firstPage.objects.length) {
        return { objects: firstPage.objects };
      }

      const pageOffsets = [
        ...generatePageOffsets(
          firstPage.objects.length,
          firstPage.meta.total_count,
          pageSize
        ),
      ];
      $D2.S.INFO('pageOffsets', { pageOffsets, firstPage });

      const nextPages = await Promise.all(
        pageOffsets.map((offset) => {
          return fetchCollectionPage(api, collection, offset, pageSize, filter);
        })
      );

      const failedPage = nextPages.find(
        page => typeof page.error !== 'undefined'
      );

      if (failedPage) {
        return failedPage;
      }

      return nextPages.reduce(
        (memo, page) => ({
          ...memo,
          objects: [...memo.objects, ...page.objects],
        }),
        { objects: firstPage.objects }
      );
    }
  );

export default fetchCollection;

import { connect } from 'react-redux';

import createImmutableSelector from 'utils/immutable-selector';
import withTranslation from 'utils/react/withTranslation';
import { commuteOffersServiceDateSelector } from 'modules/ui/selectors';
import { currentProjectIdSelector } from 'modules/user/selectors';

import { commuteOfferRequestUpdate } from 'modules/commuteOffer/actions';

import NewSimulation from './NewSimulation';

const mapStateToProps = createImmutableSelector(
  commuteOffersServiceDateSelector,
  currentProjectIdSelector,
  (serviceDate, projectId) => ({
    serviceDate,
    projectId,
  })
);

const mapDispatchToProps = {
  commuteOfferRequestUpdate,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation()(withConnect(NewSimulation));

import React from 'react';
import { InputWrapper } from './FormStyles';
import { RadioGroup, Radio, ALIGN } from 'baseui/radio';
import { Controller } from 'react-hook-form';
import { styled } from 'baseui';
import edit from 'assets/edit.svg';

import FormLabel from './FormLabel';

const RadioRow = styled('div', ({ $theme }) => {
  return {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  };
});

const EditBtn = styled('img', () => {
  return {
    width: '15px',
    height: '15px',
    margin: '21.25px 32px',
  };
});

EditBtn.defaultProps = {
  src: edit,
};

export default function RadioField({
  name,
  options,
  rules,
  control,
  editAction,
  selected,
  buttonActions = [],
  color = 'accent',
  label,
  info,
  labelMargin,
  radioButtonSize,
}) {
  const Buttons = ({ props }) => {
    return buttonActions.map((data, index) => {
      return (
        <span
          key={index}
          onClick={(e) => {
            e.stopPropagation();
            data?.action({ value: props?.$value, label: props?.children });
          }}
        >
          {data?.icon}
        </span>
      );
    });
  };

  const radioOverrides = {
    Root: {
      style: ({ $theme }) => ({
        width: '100%',
      }),
    },
    Label: (props) => {
      return (
        <RadioRow>
          <div
            style={{
              display: 'flex',
              margin: labelMargin ? labelMargin : '21.25px 32px',
            }}
          >
            {props?.children}
          </div>
          <div>
            <Buttons props={props} />
          </div>
        </RadioRow>
      );
    },
    RadioMarkOuter: {
      style: ({ $theme }) => ({
        backgroundColor:
          color === 'accent' ? $theme.colors.accent : $theme.colors.white,
        height: radioButtonSize ? radioButtonSize : '20px',
        width: radioButtonSize ? radioButtonSize : '20px',
      }),
    },
    Description: {
      style: ({ $theme }) => ({
        maxWidth: '100%',
      }),
    },
  };
  return (
    <React.Fragment>
      <InputWrapper>
        {label && (
          <div style={{ marginBottom: 16 }}>
            <FormLabel label={label} name={name} infoHover={info} />
          </div>
        )}
        <Controller
          rules={rules}
          control={control}
          name={name}
          render={({ field: { onChange, value = selected, ref } }) => {
            return (
              <RadioGroup
                name={name}
                value={value}
                onChange={onChange}
                align={ALIGN.vertical}
                inputRef={ref}
              >
                {options.map(data => (
                  <Radio
                    key={data?.label}
                    value={data?.value}
                    overrides={radioOverrides}
                    description={data?.label_sub}
                  >
                    {data?.label}
                    <FormLabel infoHover={data?.info} />
                  </Radio>
                ))}
              </RadioGroup>
            );
          }}
        />
      </InputWrapper>
    </React.Fragment>
  );
}

import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { styled } from 'baseui';
import { StatefulMenu } from 'baseui/menu';
import { useTranslation } from 'react-i18next';

import { userNameSelector, isAdminSelector } from 'modules/user/selectors';
import { deleteUser } from 'modules/user/actions';

import Avatar from '../Avatar';

const Container = styled('div', {
  padding: '0 14px',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  cursor: 'pointer',
});
const UserName = styled('div', ({ $theme }) => {
  return {
    color: $theme.colors.menuFontSelected,
    ...$theme.typography.LabelSmall,
  };
});

const UserMenu = ({ userName, isAdmin, logout }) => {
  const { t } = useTranslation();
  return (
    <StatefulPopover
      placement={PLACEMENT.topRight}
      popoverMargin={0}
      overrides={{
        Body: {
          style: () => ({
            minWidth: '240px',
          }),
        },
      }}
      content={({ close }) => (
        <StatefulMenu
          items={[
            {
              label: (
                <div
                  style={{
                    paddingBottom: '10px',
                    borderBottom: '1px solid #97A0C0',
                  }}
                >
                  <UserName style={{ marginBottom: 8 }}>{userName}</UserName>
                  <span style={{ color: '#97A0C0' }}>
                    {isAdmin
                      ? t('usermenu.type.admin')
                      : t('usermenu.type.user')}
                  </span>
                </div>
              ),
              disabled: true,
            },
            {
              label: t('usermenu.logout'),
              action: 'logout',
            },
          ]}
          overrides={{
            Option: {
              props: {
                overrides: {
                  List: {
                    style: () => ({
                      outline: 'none',
                    }),
                  },
                  ListItem: {
                    style: ({ $disabled }) => ({
                      cursor: $disabled ? '' : 'pointer',
                    }),
                  },
                },
              },
            },
          }}
          onItemSelect={({ item }) => {
            if (item.action === 'logout') {
              logout();
            }
            close();
          }}
        />
      )}
    >
      <Container data-testid='UserMenu-Container'>
        <Avatar name={userName} />
      </Container>
    </StatefulPopover>
  );
};

const mapStateToProps = createSelector(
  userNameSelector,
  isAdminSelector,
  (userName, isAdmin) => ({ userName, isAdmin })
);

const mapDispatchToProps = { logout: deleteUser };

const connectUser = connect(mapStateToProps, mapDispatchToProps);

export default connectUser(UserMenu);

import styled from 'styled-components';

const Column = styled.div`
  display: flex;
  align-items: center;
  width: ${({ width }) => width || '100px'};
  min-width: 50px;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  min-height: 70px;
  padding: 5px 20px 5px 0;
  flex-shrink: 0;
  word-break: break-all;
`;

export default Column;

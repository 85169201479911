import { connect } from 'react-redux';
import { openPopup } from 'modules/ui/actions';
import createImmutableSelector from 'utils/immutable-selector';

import {
  currentUserConfigSelector,
  isDeliveryLayoutSelector,
} from 'modules/user/selectors';

import ButtonPlus from './ButtonPlus';

const mapStateToProps = createImmutableSelector(
  currentUserConfigSelector,
  isDeliveryLayoutSelector,
  (currentUserConfig, isDeliveryLayout) => ({
    currentUserConfig,
    isDeliveryLayout,
  })
);

const mapDispatchToProps = { openPopup };

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(ButtonPlus);

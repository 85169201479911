import { connect } from 'react-redux';

import createImmutableSelector from 'utils/immutable-selector';
import withTranslation from 'utils/react/withTranslation';
import { filteredBookingsSelector } from 'modules/commuteOffer/selectors';

import Statistics from './Statistics';

const mapStateToProps = createImmutableSelector(
  filteredBookingsSelector,
  orders => ({
    orders,
  })
);

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation()(withConnect(Statistics));

import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';

const ProjectLabel = styled(Link)`
  display: flex;
  align-items: right;

  color: #c5cede;
  padding: 0 0.5rem;
  margin: 0 3rem 0 1rem;

  text-decoration: none;
  border-bottom: solid transparent 0.2rem;
  border-top: solid transparent 0.2rem;
  transition: color 0.3s;

  &.active {
    color: #edece8;
    border-top: solid #f56249 0.2rem;
  }

  &:hover {
    color: #edece8;
  }
`;

export default ProjectLabel;

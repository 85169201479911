import styled from 'styled-components';

const Main = styled.div`
  display: flex;
  margin-left: -10px;
  flex-shrink: 0;
  flex-grow: 12;
  justify-content: space-between;
  background: rgb(54, 61, 74);
`;

export default Main;

import styled from 'styled-components';

const Container = styled.div`
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 50px; // Statusbar height

  z-index: -1;
`;

export default Container;

import { styled } from 'baseui';

export const ContainerWrapper = styled('div', ({ $theme }) => {
  return {
    width: '100%',
    background: $theme.colors.containerBackground,
    height: '100vh',
    overflow: 'scroll',
  };
});
export const Container = styled('div', ({ $theme }) => {
  return {
    width: '856px',
    margin: '0 auto',
    padding: '40px 0',
    background: $theme.colors.containerBackground,
  };
});

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  color: #c5cede;
  flex-shrink: 0;
  background-color: #3b4251;
  border-radius: 10px;
  margin: 3px 0;
  padding: 0 20px;
  min-height: 75px;

  &:hover {
    box-shadow: 0 0 10px 0 rgba(43, 48, 58, 0.1);
    background-color: rgba(77, 87, 106, 0.5);
  }
`;

export default Container;

import { call, put, take, select } from 'redux-saga/effects';
import { api$CommuteOffer as api } from 'api';
import { sleep } from 'utils/async';

import { normalizeCommuteOffer } from 'utils/CommuteOffer';
import { commuteOffer$FilterVehicles } from 'utils/CommuteOffer';

import * as actions from 'modules/commuteOffer/actions';
import * as uiActions from 'modules/ui/actions';

import {
  commuteOfferCurrentDataSelector,
  allVehiclesSelector,
} from 'modules/commuteOffer/selectors';

// import { fetchAllRoutesHandler } from './fetchAllRoutes';

import debug from 'utils/debug';

const D2 = debug('m:CommuteOffer:saga:recalculateRoute');

const isVehicleReadOnly = (currentOffer, vehicleId) =>
  D2.S.FUNCTION('isVehicleReadOnly', { currentOffer, vehicleId }, ({ $D2 }) => {
    const vehicle = currentOffer.stateless_api_request_data.vehicles.find(
      x => x.agent_id === vehicleId
    );
    $D2.S.INFO('vehicle', { vehicle, vehicleId });
    return (
      !vehicle || vehicle.readOnly || vehicle.readonly || vehicle.isReadOnly
    );
  });

export const recalculateRoute2 = async (currentOffer, vehicleId) =>
  D2.A.FUNCTION('Method2', { currentOffer, vehicleId }, async ({ $D2 }) => {
    if (isVehicleReadOnly(currentOffer, vehicleId)) {
      // global.openInfoMessage('Vehicle is read-only', { title: 'Error' });
      return null;
    }

    const filteredVehiclesOffer = commuteOffer$FilterVehicles(currentOffer, [
      vehicleId,
    ]);
    $D2.S.INFO('filteredVehiclesOffer', {
      filteredVehiclesOffer,
      currentOffer,
      vehicleId,
    });

    const vehicleAssignedBookings =
      filteredVehiclesOffer.result.assigned_bookings.filter(
        booking => booking.assigned_vehicle_id === vehicleId
      );
    $D2.S.INFO('vehicleAssignedBookings', {
      vehicleAssignedBookings,
      vehicleId,
    });

    const vehicleAssignedBookingsList = vehicleAssignedBookings.map(
      booking => booking.uid
    );
    $D2.S.INFO('vehicleAssignedBookingsList', {
      vehicleAssignedBookingsList,
      vehicleId,
    });

    const vehicleAssignedBookingsSet = new Set(vehicleAssignedBookingsList);

    const filteredVehiclesOfferWithBookings = {
      ...filteredVehiclesOffer,
      stateless_api_request_data: {
        ...filteredVehiclesOffer.stateless_api_request_data,
        bookings: Object.keys(
          currentOffer.stateless_api_request_data.bookings
        ).reduce((memo, requestBookingId) => {
          const booking =
            currentOffer.stateless_api_request_data.bookings[requestBookingId];
          if (vehicleAssignedBookingsSet.has(requestBookingId)) {
            return {
              ...memo,
              [requestBookingId]: booking,
            };
          }
          return memo;
        }, {}),
        nodes: currentOffer.stateless_api_request_data.nodes.filter(node =>
          vehicleAssignedBookingsSet.has(node.booking_uid)
        ),
      },
      result: {
        ...filteredVehiclesOffer.result,
        assigned_bookings: vehicleAssignedBookings,
        rejected_bookings: [],
      },
    };
    $D2.S.INFO('filteredVehiclesOfferWithBookings', {
      filteredVehiclesOfferWithBookings,
      filteredVehiclesOffer,
      vehicleAssignedBookingsList,
    });

    const requestOffer = {
      ...filteredVehiclesOfferWithBookings,
      stateless_api_request_data: {
        ...filteredVehiclesOfferWithBookings.stateless_api_request_data,
        engine_settings: {
          ...filteredVehiclesOfferWithBookings.stateless_api_request_data
            .engine_settings,
          solver_parameters: {
            ...filteredVehiclesOfferWithBookings.stateless_api_request_data
              .engine_settings.solver_parameters,
            time_limit_ms: 5000,
          },
        },
      },
    };

    const requestOffers = [requestOffer];
    $D2.S.INFO('requestOffers', {
      requestOffers,
    });
    const calculatedOffers = await api.scheduleCommuteOffers(requestOffers, {
      ignoreErrors: false,
      setProgress: () => {},
    });
    $D2.S.INFO('calculatedOffers', {
      calculatedOffers,
      requestOffers,
    });

    const calculatedOffer = calculatedOffers[0];

    const resultOffer = {
      ...currentOffer,
      result: {
        ...currentOffer.result,
        assigned_bookings: [
          ...currentOffer.result.assigned_bookings.filter(
            booking => !vehicleAssignedBookingsSet.has(booking.uid)
          ),
          ...calculatedOffer.result.assigned_bookings,
        ],
        rejected_bookings: [
          ...currentOffer.result.rejected_bookings.filter(
            booking => !vehicleAssignedBookingsSet.has(booking.uid)
          ),
          ...calculatedOffer.result.rejected_bookings,
        ],
        vehicles: {
          ...currentOffer.result.vehicles,
          ...calculatedOffer.result.vehicles,
        },
      },
    };

    const newOffer = await normalizeCommuteOffer(resultOffer);

    return { newOffer };
  });

export function* recalculateRouteHandler({ payload }) {
  D2.S.INFO('Handler:Request', payload);

  const { vehicleId } = payload;

  const currentOffer = yield select(commuteOfferCurrentDataSelector);

  try {
    if (isVehicleReadOnly(currentOffer, vehicleId)) {
      const message = 'Vehicle is read-only';
      global.openInfoMessage(message, { title: 'Error' });
      const error = new Error(message);
      yield put({ type: actions.RECALCULATE_ROUTE_FAILURE, payload: error });
      return;
    }

    const calculationMethod = recalculateRoute2;

    const { newOffer } = yield call(calculationMethod, currentOffer, vehicleId);
    D2.S.INFO('Handler:newOffer', { newOffer });

    yield put({
      type: uiActions.SET_EDITABLE_BOOKING_ID,
      payload: {
        id: null,
        caller: 'recalculateRouteHandler',
      },
    });

    D2.S.INFO('Handler:Success', {
      newOffer,
      currentOffer,
      vehicleId,
    });
    yield put({
      type: actions.RECALCULATE_ROUTE_SUCCESS,
      payload: { newOffer, currentOffer, vehicleId },
    });

    if (newOffer) {
      const vehicles = yield select(allVehiclesSelector);
      const vehicle = vehicles.find(item => item.agent_id === vehicleId);

      const newVehicle = newOffer.stateless_api_request_data.vehicles.find(
        item => item.agent_id === vehicleId
      );
      D2.S.INFO('Handler:newVehicle', { newVehicle, vehicle });

      yield put(actions.fetchRoute(vehicle));
      yield take(actions.ROUTE_FETCH_RESULTS);

      yield put(actions.recalculateVehicleTime([vehicleId], 'set'));
    }
    D2.S.INFO('Handler:Success', { newOffer });
    yield sleep(1000);
  } catch (error) {
    // eslint-disable-next-line
    console.log(error);
    D2.S.INFO('Handler:Failure', { error });
    yield put({ type: actions.RECALCULATE_ROUTE_FAILURE, payload: error });
  }
}

import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { switchMapStyle } from 'modules/ui/actions';
import {
  mapStylesSelector,
  activeMapStyleSelector,
} from 'modules/ui/selectors';

import { currentProjectLayoutNameSelector } from 'modules/user/selectors';

import MapStylesMenu from './MapStylesMenu';

const mapStateToProps = createSelector(
  mapStylesSelector,
  activeMapStyleSelector,
  currentProjectLayoutNameSelector,
  (mapStyles, activeMapStyle, layoutName) => ({
    mapStyles,
    activeMapStyle,
    layoutName,
  })
);

const mapDispatchToProps = {
  switchMapStyle,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(MapStylesMenu);

import withTranslation from 'utils/react/withTranslation';

import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import {
  currentProjectLayoutNameSelector,
  currentUserAllowChangeSelector,
} from 'modules/user/selectors';

import Layout from './Layout';

const mapStateToProps = createSelector(
  currentProjectLayoutNameSelector,
  currentUserAllowChangeSelector,
  (layoutName, currentUserAllowChange) => ({
    layoutName,
    currentUserAllowChange,
  })
);

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation()(withConnect(Layout));

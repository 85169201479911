import debug from 'utils/debug';

import Immutable from 'immutable';
import { diffString } from 'json-diff';

const D2 = debug('api:CommuteOffer:makeBulkUpdateRequest');

const makeBulkUpdateRequest = (
  currentObjects,
  originalObjects,
  objectType,
  getItemId = x => x.uid,
  isValid = () => true
) => {
  const currentObjectValues = Object.values(currentObjects);

  const invalidObject = currentObjectValues.find((currentItem) => {
    const originalItem = originalObjects[getItemId(currentItem)];
    if (!originalItem) {
      return false;
    }
    if (!isValid(originalItem)) {
      D2.S.INFO(`*** Invalid original ${objectType}`, originalItem);
      return true;
    }
    if (!isValid(currentItem)) {
      D2.S.INFO(`*** Invalid current ${objectType}`, currentItem);
      return true;
    }
    return false;
  });

  if (invalidObject) {
    return { objects: [], deleted_objects: [] };
  }

  return {
    objects: currentObjectValues.reduce((memo, currentItem) => {
      const originalItem = originalObjects[getItemId(currentItem)];
      if (!originalItem) {
        return memo;
      }
      const originalItemIm = Immutable.fromJS(originalItem);
      const currentItemIm = Immutable.fromJS(currentItem);
      const mergedItemIm = originalItemIm.merge(currentItemIm);

      if (!Immutable.is(originalItemIm, mergedItemIm)) {
        if (global.GEODISC_SIMULATION_SAVING_LOGS_ENABLED) {
          // eslint-disable-next-line
          console.log('===', objectType, getItemId(currentItem));
          // eslint-disable-next-line
          console.log(diffString(originalItem, currentItem, {}));
        }
        return [...memo, currentItem];
      }

      return memo;
    }, []),
    deleted_objects: [],
  };
};

export default makeBulkUpdateRequest;

import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 50px; // Statusbar height

  .mapboxgl-popup-tip,
  .mapboxgl-popup-content {
    background: transparent;
    border: none;
    padding: 0;
  }
`;

export default Container;

import styled from 'styled-components';

const Label = styled.div`
  letter-spacing: 0;
  color: #c5cede;
  font-size: 17px;
  opacity: 0.5;
  margin-bottom: 15px;
  text-transform: uppercase;
`;

export default Label;

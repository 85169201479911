import React from 'utils/react';

import Header from 'components/Header';
import Layout from 'components/Layout';

import debug from 'utils/debug';
import Panel from './Panel';
import Table from './Table';

import Container from './Container';
import { useEffect } from 'react';

const D2 = debug('p:CommuteOffers');

const CommuteOffers = ({ layoutName, list, projectId, fetchCommuteOffers }) => {
  useEffect(() => {
    if (!list && projectId) {
      fetchCommuteOffers();
    } else if (projectId) {
      fetchCommuteOffers();
    }
  }, [projectId]);

  return (
    <Layout hasBackground>
      <Container>
        <Header />
        <Panel />
        <Table />
      </Container>
    </Layout>
  );
};

export default CommuteOffers;

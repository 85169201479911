import React from 'utils/react';

import Button from 'components/Button';

import Title from './Title';
import Container from './Container';
import Search from './Search';
import Selected from './Selected';

const Panel = ({ count, selectedIds, downloadDatasetCsv }) => (
  <Container>
    <Title>
      {`${count || '--'} datasets`}
      <Selected />
    </Title>
    <Button
      onClick={() => {
        if (selectedIds.size) {
          downloadDatasetCsv(selectedIds.toJS());
        }
      }}
    >
      Export
    </Button>
    <Search placeholder='Search datasets' />
  </Container>
);

export default Panel;

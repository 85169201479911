import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  min-height: 140px;
  padding: 16px;
  margin: 10px 0;
  border-radius: 10px;
  color: #edece8;
  background-color: ${({ isEditable }) =>
    isEditable ? 'rgba(77, 87, 106, 0.5)' : 'rgb(54, 61, 74)'};

  &:hover {
    box-shadow: 0 0 10px 0 rgba(43, 48, 58, 0.1);
    background-color: rgba(77, 87, 106, 0.5);
  }
`;

export default Container;

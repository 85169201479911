import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';
import {
  saveSimulationSettings,
  setTemplateSimulationId,
  fetchTemplateSimulationData,
} from 'modules/simulations/actions';
import { commuteOfferCurrentDataSelector } from 'modules/commuteOffer/selectors';
import withTranslationWrapper from 'utils/react/withTranslation';
import SettingsForm from './SettingsForm';
import { currentProjectSelector } from 'modules/user/selectors';
import {
  templateDataSelector,
  templateIdSelector,
} from 'modules/simulations/selectors';

const mapStateToProps = createImmutableSelector(
  commuteOfferCurrentDataSelector,
  currentProjectSelector,
  templateIdSelector,
  templateDataSelector,
  (commuteOfferData, project, templateId, templateData) => ({
    commuteOfferData,
    project,
    templateId,
    templateData,
  })
);

const mapDispatchToProps = {
  saveSimulationSettings,
  setTemplateSimulationId,
  fetchTemplateSimulationData,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslationWrapper()(withConnect(SettingsForm));

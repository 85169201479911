// Since our backend is using integer programming we have send integer demand loads to the backend
// Most of the time kg and cbm values come with decimal values.
// Below function is being used to convert kg and cbm values to grams and cbcm
export const getConvertedDemandType = (demandType) => {
  if (demandType === 'kg') {
    return { multiplier: 1000, demandType: 'g' };
  } else if (demandType === 'cbm') {
    return { multiplier: 1000000, demandType: 'cbcm' };
  } else {
    return { multiplier: 1, demandType: demandType };
  }
};

// According to the requirement user should see the demand values in cbm and kilograms
// This function converts the gram to kg and cbcm to cbm
export const convertDemandLoadsToDisplay = (type = '', value = '') => {
  if (type === 'g') {
    return { type: 'kg', value: value / 1000 };
  } else if (type === 'cbcm') {
    return { type: 'cbm', value: value / 1000000 };
  } else {
    return { type, value };
  }
};

export const capacityConversionFunctor = {
  cbcm: (name, { occupied, transferred, capacity, ...otherProps }) => ({
    name: 'cbm',
    info: {
      occupied: occupied / 1000000,
      transferred: transferred / 1000000,
      capacity: capacity / 1000000,
      ...otherProps,
    },
  }),
  g: (name, { occupied, transferred, capacity, ...otherProps }) => ({
    name: 'kg',
    info: {
      occupied: occupied / 1000,
      transferred: transferred / 1000,
      capacity: capacity / 1000,
      ...otherProps,
    },
  }),
  $default: (name, info) => ({
    name,
    info,
  }),
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useStyletron, styled } from 'baseui';

import arrow from 'assets/arrow.svg';
import check from 'assets/check.svg';
import { changeLocale } from 'utils/locale';

const Container = styled('div', () => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  maxWidth: '240px',
  marginRight: '10px',
}));

const Handle = styled('div', ({ $theme }) => ({
  padding: '14px 30px 14px 14px',
  cursor: 'pointer',
  backgroundImage: `url(${arrow})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right center',
  overflow: 'auto',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  color: $theme.colors.buttonTextDisabled,
}));

const Dropdown = styled('div', ({ color }) => ({
  position: 'absolute',
  right: 0,
  background: `${color || '#1C232D'}`,
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.5)',
  borderRadius: '4px',
  bottom: '50px',
  maxHeight: 'min(28em, 80vh)',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  width: '160px',
  overflow: 'hidden',
}));

const DropdownScrollable = styled('div', ({ $theme }) => ({
  overflowY: 'auto',
  '::-webkit-scrollbar': {
    width: '8px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: 'transparent',
    border: `1px solid ${$theme.colors.scrollbarBackground}`,
    borderRadius: '20px',
  },
  ':hover::-webkit-scrollbar-thumb': {
    borderColor: $theme.colors.inputBorder,
    backgroundColor: $theme.colors.inputBorder,
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  ':hover::-webkit-scrollbar-track': {
    backgroundColor: $theme.colors.scrollbarBackground,
  },
}));

const DropdownItem = styled('div', ({ selected, onClick }) => ({
  padding: '16px 38px 16px 20px',
  backgroundImage: `${selected ? `url(${check})` : ''}`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center right 20px',
  cursor: `${onClick ? 'pointer' : ''}`,
  ':hover': {
    backgroundColor: `${onClick ? '#00000040' : ''}`,
  },
  wordBreak: 'break-all',
  overflowWrap: 'anywhere',
  fontSize: '14px',
  fontWeight: '500',
  textAlign: 'center',
}));

const availableLangs = ['en', 'jp', 'th'];

const LanguageSelect = () => {
  const { t, i18n } = useTranslation();
  const [, theme] = useStyletron();
  const colors = theme?.colors;

  const [openDropdown, setOpenDropdown] = React.useState(false);

  const clickOutsideRef = useOnclickOutside(() => {
    setOpenDropdown(false);
  });

  return (
    <Container ref={clickOutsideRef} data-testid='LanguageSelect-Container'>
      <Handle onClick={() => setOpenDropdown(!openDropdown)}>
        {i18n.language.toUpperCase()}
      </Handle>
      {openDropdown && (
        <Dropdown color={colors?.menuBackground}>
          <DropdownScrollable>
            {availableLangs.map((lang) => {
              return (
                <DropdownItem
                  key={lang}
                  selected={lang === i18n.language}
                  onClick={() => {
                    changeLocale(lang);
                    i18n.changeLanguage(lang);
                    global.localStorage.setItem('user:language', lang);
                  }}
                >
                  {t(`langName.${lang}`)}
                </DropdownItem>
              );
            })}
          </DropdownScrollable>
        </Dropdown>
      )}
    </Container>
  );
};

export default LanguageSelect;

/* eslint-disable no-underscore-dangle */
import React from 'utils/react';
import settings from 'config/settings';
import { I18nextProvider } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactDOM from 'react-dom';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore, compose } from 'redux';
import uuidByString from 'uuid-by-string';
import {
  routerMiddleware as createRouterMiddleware,
  ConnectedRouter,
} from 'connected-react-router/immutable';
import { Provider } from 'react-redux';

import Immutable from 'immutable';
import Moment from 'moment-timezone';
import momentLocalizer from 'react-widgets-moment';

import debug from 'debug';
import GlobalStyle from 'components/GlobalStyle';
import App from 'components/App';

import Saga from 'modules/saga';
import createRootReducer from 'modules';
import { ident } from 'config/ident';

// import { Promise as BlueBirdPromise } from 'bluebird';
import deepmerge from 'deepmerge';
import thunk from 'redux-thunk';
import args from './args';
import i18n from './i18n';
import OrderSelectedDataContextProvider from 'pages/Logistics/OrderSelectedDataContextProvider';

// global.Promise = BlueBirdPromise;

Moment.relativeTimeThreshold('s', 60);
Moment.relativeTimeThreshold('m', 60);
Moment.relativeTimeThreshold('h', 24);
Moment.relativeTimeThreshold('d', 31);
Moment.relativeTimeThreshold('M', 12);
Moment.relativeTimeThreshold('y', 365);

// eslint-disable-next-line
// if (typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope) {
if (typeof window === 'undefined') {
  // I'm a worker...

  // eslint-disable-next-line
  self.onmessage = (event) => {
    postMessage(event.data);
  };
} else {
  // I'm not a worker...

  // eslint-disable-next-line
  console.log('Version:', { ...ident, settings });

  if (
    typeof global.GEODISC_TITLE !== 'undefined' &&
    global.GEODISC_TITLE !== ''
  ) {
    document.title = global.GEODISC_TITLE;
  } else {
    document.title = 'SWAT Route Generator - Development';
  }

  global.GEODISC_AUTH_LOGIN = `login:${global.GEODISC_API_URL}`;
  global.GEODISC_AUTH_TOKEN = `token:${global.GEODISC_API_URL}`;

  global.GEODISC_LOCATION_SEARCH_OPTS = args.search;
  global.GEODISC_LOCATION_HASH_OPTS = args.hash;

  if (window.SENTRY_URL) {
    Sentry.init({
      dsn: window.SENTRY_URL,
      integrations: [new Integrations.BrowserTracing()],
      environment: window.SENTRY_ENV || 'development',
    });
  }

  momentLocalizer();

  window.GEODISC_IMMUTABLE = Immutable;

  window.GEODISC_MOMENT = Moment;
  window.GEODISC_TIMEZONE = window.GEODISC_TIMEZONE || Moment.tz.guess();

  window.moment = Moment;

  window.GEODISC_DEEPMERGE = deepmerge;

  window.uuidByString = uuidByString;

  const history = createBrowserHistory();
  const routerMiddleware = createRouterMiddleware(history);
  const sagaMiddleware = createSagaMiddleware();

  let composeEnhancers = compose;
  const middlewares = [routerMiddleware, sagaMiddleware, thunk];

  window.DEBUG = window.DEBUG || '-*';
  debug.enable(window.DEBUG);

  if (process.env.NODE_ENV === 'development') {
    composeEnhancers =
      (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(
          window.REDUX_DEVTOOLS_EXTENSION_CONFIG || {}
        )) ||
      compose;
    if (window.REDUX_LOGGER_CONFIG) {
      middlewares.push(createLogger(window.REDUX_LOGGER_CONFIG));
    }
  }

  const store = createStore(
    createRootReducer(history),
    composeEnhancers(applyMiddleware(...middlewares))
  );

  global.geodisc$store = store;
  global.geodisc$state = () => global.geodisc$store.getState();

  global.geodisc$select = (selector, ...options) =>
    selector(global.geodisc$state(), ...options);

  window.GEODISC_STORE = store;

  sagaMiddleware.run(Saga);

  const root = document.getElementById('root');

  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <OrderSelectedDataContextProvider>
            <GlobalStyle />
            <App />
          </OrderSelectedDataContextProvider>
        </ConnectedRouter>
      </Provider>
    </I18nextProvider>,
    root
  );
  if (module.hot) {
    module.hot.accept('./modules', () => {
      // eslint-disable-next-line
      const nextRootReducer = require('./modules');
      store.replaceReducer(nextRootReducer);
    });

    module.hot.accept('./components/App', () => {
      // eslint-disable-next-line
      const NextApp = require('./components/App').default;
      ReactDOM.render(<NextApp history={history} store={store} />, root);
    });
  }
}

import React from 'utils/react';
import { Modal } from 'baseui/modal';
import debug from 'utils/debug';
import { useEffect, useState } from 'react';

const D2 = debug('c:ModalWindows:MasterSettings');

const MasterSettings = (props) => {
  D2.S.INFO('MasterSettings', props);

  const [isOpen, setIsOpen] = useState(false);
  const [contentComponent, setContentComponent] = useState(<React.Fragment />);
  useEffect(() => {
    window.openMasterSettings = (opts) => {
      const options = opts || {};
      setContentComponent(options?.modalContent);
      setIsOpen(true);
    };
    window.closeMasterSettings = () => {
      setIsOpen(false);
    };
  }, []);

  return (
    <React.Fragment>
      <Modal
        overrides={{
          Root: { style: { zIndex: 21 } },
          Dialog: {
            style: {
              marginTop: '0px',
              marginRight: '0px',
              marginLeft: '0px',
              marginBottom: '0px',
              minHeight: '100vh',
            },
          },
        }}
        size='100%'
        isOpen={isOpen}
        closeable={false}
      >
        {contentComponent}
      </Modal>
    </React.Fragment>
  );
};
window.openMasterSettings = () => {};
window.closeMasterSettings = () => {};

export default MasterSettings;

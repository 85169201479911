import { connect } from 'react-redux';
import withTranslation from 'utils/react/withTranslation';
import createImmutableSelector from 'utils/immutable-selector';
import {
  filteredBookingsSelector,
  commuteOfferHistorySelector,
  commuteOfferLoadedSimulationSelector,
  commuteOfferHasOnlineVehiclesSelector,
  isEditingInProgressSelector,
} from 'modules/commuteOffer/selectors';
import { isSavingSelector } from 'modules/ui/selectors';
import { updateCommuteOffer } from 'modules/commuteOffer/actions';
import {
  commuteOfferCurrentDataSelector,
  commuteOfferOriginalDataSelector,
  commuteOfferIsReadOnlySelector,
  commuteOfferCanBeSavedSelector,
} from 'modules/commuteOffer/selectors';
import { openModal } from 'modules/ui/actions';
import SaveBottom from './SaveBottom';
import { fetchCommuteOffer } from 'modules/commuteOffer/actions';

const mapStateToProps = createImmutableSelector(
  filteredBookingsSelector,
  commuteOfferHistorySelector,
  isSavingSelector,
  commuteOfferCurrentDataSelector,
  commuteOfferOriginalDataSelector,
  commuteOfferIsReadOnlySelector,
  commuteOfferCanBeSavedSelector,
  commuteOfferLoadedSimulationSelector,
  commuteOfferHasOnlineVehiclesSelector,
  isEditingInProgressSelector,
  (
    bookings,
    history,
    isSaving,
    data,
    originalData,
    isReadOnly,
    canBeSaved,
    simulation,
    hasOnlineVehicles,
    isEditingInProgress
  ) => ({
    bookings,
    history,
    isSaving,
    data,
    originalData,
    isReadOnly,
    canBeSaved,
    simulation,
    hasOnlineVehicles,
    isEditingInProgress,
  })
);

const mapDispatchToProps = {
  openModal,
  updateCommuteOffer,
  fetchCommuteOffer,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation()(withConnect(SaveBottom));

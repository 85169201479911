import styled from 'styled-components';

export default styled.input`
  display: inline-block;
  width: 100%;
  padding: 9px 30px 9px 15px;
  border: none;
  border-radius: 5px;
  margin-bottom: 5px;

  font-size: 14px;
  color: #edece8;

  background-color: rgb(54, 61, 74);
`;

import React from 'react';
import { Controller } from 'react-hook-form';
import Error from './Error';
import FormFieldLabel from './FormLabel';
import { InputWrapper } from './FormStyles';

const { TimePicker } = require('baseui/timepicker');

const TimePickerField = ({
  name,
  style,
  label,
  width,
  control,
  maxTime,
  minTime,
  errors,
  infoHover,
  rules,
  creatable,
  nullable,
  afterOnChange,
}) => {
  const properties = {
    name,
    step: 300,
    placeholder: '00:00',
    maxTime,
    minTime,
    creatable,
    nullable,
  };
  const error = errors?.message;

  return (
    <InputWrapper width={width} style={style}>
      <FormFieldLabel label={label} name={name} {...infoHover} />
      <Controller
        rules={rules}
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          return (
            <TimePicker
              format='24'
              onChange={(val) => {
                onChange(val);
                afterOnChange && afterOnChange();
              }}
              {...properties}
              value={value}
              overrides={{
                Select: {
                  props: {
                    overrides: {
                      Root: {
                        style: {
                          height: '52px',
                          border: 'none',
                          marginTop: '16px',
                        },
                      },
                      DropDown: {
                        style: ({ $theme }) => ({
                          backgroundColor: `${$theme?.colors.menuBackground}`,
                        }),
                      },
                      OptionContent: {
                        style: {
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                        },
                      },
                      IconsContainer: {
                        style: ({ $theme }) => ({
                          backgroundColor: `${$theme?.colors.containerBackground}`,
                        }),
                      },
                      ValueContainer: {
                        style: ({ $theme }) => ({
                          fontFamily: 'Montserrat',
                          backgroundColor: `${$theme?.colors.containerBackground}`,
                          lineHeight: '30px',
                        }),
                      },
                      ControlContainer: {
                        style: ({ $theme }) => {
                          const borderColor = error
                            ? $theme?.colors?.negative
                            : $theme?.colors?.inputBorder;
                          return {
                            borderLeftStyle: 'solid',
                            borderRightStyle: 'solid',
                            borderTopStyle: 'solid',
                            borderBottomStyle: 'solid',
                            borderLeftWidth: '1px',
                            borderRightWidth: '1px',
                            borderTopWidth: '1px',
                            borderBottomWidth: '1px',

                            borderLeftColor: borderColor,
                            borderRightColor: borderColor,
                            borderTopColor: borderColor,
                            borderBottomColor: borderColor,
                            borderBottomLeftRadius: '4px',
                            borderBottomRightRadius: '4px',
                            borderTopLeftRadius: '4px',
                            borderTopRightRadius: '4px',
                          };
                        },
                      },
                    },
                  },
                },
              }}
            />
          );
        }}
      />
      {error && <Error>{error}</Error>}
    </InputWrapper>
  );
};
export default TimePickerField;

import { takeLatest, put, call, delay } from 'redux-saga/effects';
import * as types from './types';
import * as api from 'api/simulations';

function* setFleetData({ payload }) {
  yield put({ type: types.SET_FLEET_DATA_SUCCESS, payload: payload });
}

function* fleetAddVehicle({ payload }) {
  yield put({ type: types.FLEET_ADD_VEHICLE_REQUEST });
  yield delay(0);
  const { data, values, startPoint, endPoint } = payload;
  const simulationId = data?.sim_id || undefined;

  if (simulationId === undefined) {
    yield put({
      type: types.FLEET_ADD_VEHICLE_FAILURE,
      payload: { error: 'u.Api.error.simulationId.notFound' },
    });
  } else {
    const response = yield call(
      api.addVehicleToSimulation,
      simulationId,
      values
    );

    const responseId = response?.id || undefined;
    if (responseId === undefined) {
      yield put({
        type: types.FLEET_ADD_VEHICLE_FAILURE,
        payload: { error: 'u.Api.error.responseId.notFound' },
      });
    } else {
      try {
        yield call(
          api.addOrUpdateStartAndEndNodesForVehicle,
          responseId,
          startPoint,
          endPoint
        );
        yield put({
          type: types.FLEET_ADD_VEHICLE_SUCCESS,
          payload: { ...values, id: response.id },
        });
      } catch (error) {
        yield put({
          type: types.FLEET_ADD_VEHICLE_FAILURE,
          payload: error,
        });
      }
    }
  }
}

function* updateVehicle({ payload }) {
  const { data, values, startPoint, endPoint } = payload;
  yield put({ type: types.FLEET_UPDATE_VEHICLE_REQUEST });
  yield delay(0);
  const updatedVehicle = values;
  const otherVehicles = [...data?.vehicles].filter((obj) => {
    return obj.id !== values?.id;
  });
  const simulationId = data?.sim_id;

  if (simulationId === undefined) {
    yield put({
      type: types.FLEET_UPDATE_VEHICLE_FAILURE,
      payload: { error: 'u.Api.error.simulationId.notFound' },
    });
  } else {
    yield call(api.updateVehicleInSimulation, simulationId, values);

    try {
      const valuesId = values.id;
      yield call(
        api.addOrUpdateStartAndEndNodesForVehicle,
        valuesId,
        startPoint,
        endPoint
      );
      yield put({
        type: types.FLEET_UPDATE_VEHICLE_SUCCESS,
        payload: { otherVehicles, updatedVehicle },
      });
    } catch (error) {
      yield put({
        type: types.FLEET_UPDATE_VEHICLE_FAILURE,
        payload: error,
      });
    }
  }
}

function* deleteVehicle({ payload }) {
  const { vehicle, commuteOffer } = payload;

  yield put({ type: types.FLEET_DELETE_VEHICLE_REQUEST });

  try {
    yield delay(0);
    const id = vehicle.id;
    const vehicles = commuteOffer?.stateless_api_request_data?.vehicles;
    const assignedBookings = commuteOffer?.result?.assigned_bookings;
    const rejectedBookings = commuteOffer?.result?.rejected_bookings;
    const updatedAssignedBookings = assignedBookings.filter(
      booking => booking.assigned_vehicle_id !== vehicle.agent_id
    );
    const unassignedBookingUids = [];
    assignedBookings.map((booking) => {
      if (booking.assigned_vehicle_id === vehicle.agent_id) {
        unassignedBookingUids.push(booking.uid);
      }
    });
    const vehicleResults = { ...commuteOffer?.result?.vehicles };

    delete vehicleResults?.[vehicle.agent_id];

    const newVehicles = [...vehicles].filter((obj) => {
      return obj.id !== id;
    });

    const response = yield call(api.unassignAllOrders, { commuteOffer, id });
    yield call(api.deleteVehicleFromSimulation, id);

    const newRejectedBookings = unassignedBookingUids.map((uid) => {
      return {
        scheduled_dropoff_time: null,
        scheduled_pickup_time: null,
        uid,
      };
    });
    yield put({
      type: types.FLEET_DELETE_VEHICLE_SUCCESS,
      payload: {
        vehicles: newVehicles,
        assignedBookings: updatedAssignedBookings,
        rejectedBookings: [...rejectedBookings, ...newRejectedBookings],
        vehicleResults,
        ...response,
      },
    });
  } catch (error) {
    yield put({ type: types.FLEET_DELETE_VEHICLE_FAILURE, payload: error });
  }
}

function* setVehicleTypes({ payload }) {
  yield put({ type: types.SET_VEHICLE_TYPES_SUCCESS, payload });
}

function* addVehicleType({ payload }) {
  const { values, data } = payload;
  try {
    yield put({ type: types.ADD_VEHICLE_TYPE_REQUEST });
    yield delay(0);
    const newVehicleType = values;
    // Ticket : https://swatmobile.atlassian.net/browse/SP-1289
    // Since we save vehicle types inside the project data JSON field,
    // We have to send whole JSON every time we update or add new Vehicle Type
    // It makes concurrent writing issues
    // To minimize this issue we are using the following temporary solution
    const projectDataFromServer = yield call(api.getProject, {
      data,
    });
    const project = { ...projectDataFromServer };

    project.data.vehicle_models = [
      ...(projectDataFromServer?.data?.vehicle_models || []),
      newVehicleType,
    ];
    project.data = {
      ...project.data,
      all_vehicle_labels: [
        ...new Set([
          ...project?.data?.all_vehicle_labels,
          ...values?.vehicle_labels,
        ]),
      ],
    };

    yield call(api.updateProject, {
      data: project,
    });
    yield put({
      type: types.ADD_VEHICLE_TYPE_SUCCESS,
      payload: project?.data?.vehicle_models,
    });
    yield delay(2000);
    yield put({
      type: types.FINISH_LOADING,
    });
  } catch (error) {
    yield put({ type: types.ADD_VEHICLE_TYPE_FAILURE, payload: error });
  }
}

function* updateVehicleType({ payload }) {
  const { data, deleteId, updateId, vehicleType } = payload;
  try {
    yield put({ type: types.UPDATE_VEHICLE_TYPE_REQUEST });
    // Ticket : https://swatmobile.atlassian.net/browse/SP-1289
    // Since we save vehicle types inside the project data JSON field,
    // We have to send whole JSON every time we update or add new Vehicle Type
    // It makes concurrent writing issues
    // To minimize this issue we are using the following temporary solution
    yield delay(0);
    const projectDataFromServer = yield call(api.getProject, {
      data,
    });
    let project;
    const modelsFromServer = projectDataFromServer?.data?.vehicle_models;

    if (deleteId) {
      project = {
        ...projectDataFromServer,
        data: {
          ...projectDataFromServer?.data,
          vehicle_models: (modelsFromServer || []).filter(
            model => model?.id !== deleteId
          ),
        },
      };
    } else {
      const updatedModel = vehicleType;

      let newModels = [];
      modelsFromServer.map((model, index) => {
        if (model.id === updateId) {
          newModels = [...modelsFromServer.slice(0, index)]
            .concat([updatedModel])
            .concat(modelsFromServer.slice(index + 1, modelsFromServer.length));
        }
      });

      // if (newModels.length === 0) {
      //   newModels = modelsFromServer;
      // }

      const newVehicleTypesObj = newModels.reduce((memo, model) => {
        return { ...memo, [model.id]: model };
      }, {});

      const newVehiclesArray = Object.values(newVehicleTypesObj);
      const currentLabels =
        projectDataFromServer?.data?.all_vehicle_labels || [];

      project = {
        ...projectDataFromServer,
        data: {
          ...projectDataFromServer.data,
          vehicle_models: newVehiclesArray,
          all_vehicle_labels: [
            ...new Set([...currentLabels, ...vehicleType?.vehicle_labels]),
          ],
        },
      };
    }

    yield call(api.updateProject, {
      data: project,
    });
    yield put({
      type: types.UPDATE_VEHICLE_TYPE_SUCCESS,
      payload: project?.data?.vehicle_models,
    });
    yield delay(2000);
    yield put({
      type: types.FINISH_LOADING,
    });
  } catch (error) {
    yield put({ type: types.UPDATE_VEHICLE_TYPE_FAILURE, payload: { error } });
  }
}

function* Saga() {
  yield takeLatest(types.SET_FLEET_DATA_REQUEST, setFleetData);
  yield takeLatest(types.FLEET_START_ADD_VEHICLE_REQUEST, fleetAddVehicle);
  yield takeLatest(types.FLEET_START_UPDATE_VEHICLE_REQUEST, updateVehicle);
  yield takeLatest(types.FLEET_START_DELETE_VEHICLE_REQUEST, deleteVehicle);
  yield takeLatest(types.SET_VEHICLE_TYPES_REQUEST, setVehicleTypes);
  yield takeLatest(types.START_ADD_VEHICLE_TYPE_REQUEST, addVehicleType);
  yield takeLatest(types.START_UPDATE_VEHICLE_TYPE_REQUEST, updateVehicleType);
}

export default Saga;

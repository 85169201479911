import React from 'utils/react';

import debug from 'utils/debug';

const D0 = debug('_');

class ErrorReporter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { D2: props.D2 || props.d2 };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
    const { D2 } = this.state;
    [D0, D2].map($D2 => $D2.S.INFO('error', { error, errorInfo }));
  }

  render() {
    if (this.state.error && this.state.errorInfo) {
      return null;
    }
    return this.props.children;
  }
}

export default ErrorReporter;

import styled from 'styled-components';

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 25px;
  letter-spacing: -0.1px;
  color: #edece8;
  font-weight: bold;
`;

export default Title;

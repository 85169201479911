export const ver = '2022110201';

export const gid = '$Id: 4cc3bd029c7f135bd70ddbc5b41a503a4ba8759c $';

export const version = window.GEODISC_COMMIT_ID
  ? {
      ver: window.GEODISC_COMMIT_ID,
      cid: window.GEODISC_COMMIT_ID,
      url: window.GEODISC_COMMIT_URL,
    }
  : { ver, gid };

export const ident = {
  ...version,
  api: window.GEODISC_API_URL,
};

global.GEODISC_VERSION = ident;

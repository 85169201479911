import React from 'utils/react';
import StatusCircle from 'components/StatusCircle';
import { getStatusText } from 'config/simulations';

import Text from './StatusText';
import Container from './Container';

const Index = ({ state }) => {
  const { color, text } = getStatusText(state);

  return (
    <Container>
      <StatusCircle color={color} />
      <Text color={color}>{text}</Text>
    </Container>
  );
};

export default Index;

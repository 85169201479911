import { Promise as BlueBirdPromise } from 'bluebird';

import debug from 'utils/debug';

const D2 = debug('u:file');

export const validateCustomFile = (file, type, ext) => {
  D2.S.INFO('validateCustomFile', { file, type, ext });

  if (file.type === type) {
    return true;
  }

  if (!file.type || file.type === '') {
    const extension = file.name.split('.').pop().toLowerCase();
    if (extension === ext) {
      return true;
    }
  }

  return null;
};

export const validateCsvFile = file =>
  validateCustomFile(file, 'text/csv', 'csv');
export const validateJsonFile = file =>
  validateCustomFile(file, 'application/json', 'json');

export const exportFile = (data, filename) => {
  const exportedFilename = filename || 'export.zip';
  let blob = '';
  if (exportedFilename?.split('.')?.pop() === 'csv') {
    /*
     * Create a blob with Character set UTF-8
     * This displays the japanese csv files correctly on excel
     * Without manually changing the Character set
     */
    blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), data], {
      type: 'text/csv;charset=utf-8',
    });
  } else {
    blob = new Blob([data]);
  }

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
global.geodisc$exportFile = exportFile;
global.geodisc$exportJson = (data, filename) =>
  exportFile(JSON.stringify(data), filename || 'export.json');

export const exportGeofence = (data, fileTitle) => {
  const exportedFilename = `${fileTitle}.geojson` || 'export.geojson';

  const blob = new Blob([data], { type: 'application/geo+json' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const readFile = (file, callback) => {
  D2.S.INFO('readFile', file);

  const reader = new FileReader();

  reader.onloadend = (e) => {
    if (e.target.readyState === FileReader.DONE) {
      D2.S.INFO('readFile:Done', e.target.result);

      callback(null, e.target.result);
    }
  };

  const blob = file.slice(0, 1024 ** 3);
  reader.readAsText(blob, 'UTF-8');
};

export const readFileAsync = BlueBirdPromise.promisify(readFile);

export const readBinaryFile = (file, callback) => {
  D2.S.INFO('readBinaryFile', file);

  const reader = new FileReader();

  reader.onloadend = (e) => {
    if (e.target.readyState === FileReader.DONE) {
      D2.S.INFO('readBinaryFile:Done', e.target.result);

      callback(null, e.target.result);
    }
  };

  const blob = file.slice(0, 1024 ** 3);
  reader.readAsBinaryString(blob);
};

export const readBinaryFileAsync = BlueBirdPromise.promisify(readBinaryFile);

import Error from 'components/Error';
import Loading from 'components/Loading';
import React from 'utils/react';

import Container from './Container';
import Row from './Row';

const Table = (props) => {
  const { error, list, count, page, clearList, history, ...otherProps } = props;
  if (error) {
    return <Error />;
  }
  if (!list) {
    return <Loading />;
  }
  return (
    <React.Fragment>
      <Container>
        {list.valueSeq().map((dataset) => {
          const id = dataset.get('id');

          return (
            <Row
              key={id}
              url={`/dataset/${id}`}
              dataset={dataset}
              {...otherProps}
            />
          );
        })}
      </Container>
    </React.Fragment>
  );
};

export default Table;

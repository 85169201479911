/* eslint-disable */
import distance from '@turf/distance';
import bearing from '@turf/bearing';
import { point } from '@turf/helpers';
import pointToLineDistance from 'utils/pointToLineDistance';

import debug from 'utils/debug';
const D2 = debug('u:nearest-feature');

function toRad(degree) {
  return (degree * Math.PI) / 180;
}

function crosstrack(lineStart, lineEnd, point, units) {
  var R = 0;

  switch (units) {
    case 'miles':
      R = 3960;
      break;
    case 'kilometers':
      R = 6373;
      break;
    case 'degrees':
      R = 57.2957795;
      break;
    case 'radians':
    default:
      R = 1;
      break;
  }

  var c = distance(lineStart, point, units);

  var bearing1 = toRad(bearing(lineStart, point)),
    bearing2 = toRad(bearing(lineStart, lineEnd));

  return Math.abs(
    Math.asin(Math.sin(c / R)) * Math.sin(bearing1 - bearing2) * R
  );
}

function flatten(coordinates) {
  return Array.prototype.concat.apply([], coordinates);
}

const pair = (geometry) =>
  D2.S.FUNCTION('pair', { geometry }, () => {
    function line(coordinates) {
      var c = coordinates.map(function (d, i, a) {
        if (i + 1 < a.length) {
          return [d, a[i + 1]];
        }
      });
      c.splice(-1, 1);
      return c;
    }

    function polygon(coordinates) {
      return flatten(coordinates.map(line));
    }

    function multipolygon(coordinates) {
      return flatten(coordinates.map(polygon));
    }

    switch (geometry.type) {
      case 'Point':
        return [geometry.coordinates];
      case 'MultiPoint':
        return geometry.coordinates;
      case 'LineString':
        return line(geometry.coordinates);
      case 'Polygon':
      case 'MultiLineString':
        return polygon(geometry.coordinates);
      case 'MultiPolygon':
        return multipolygon(geometry.coordinates);
      default:
        throw new Error('Invalid geometry');
    }
  });

function makePoint(coordinates) {
  return point(coordinates);
}

export const nearestFeatureEx = (point, features) =>
  D2.S.FUNCTION('nearestFeatureEx', { point, features }, ({ $D2 }) => {
    var nearest,
      minDist = Infinity;

    features.features.forEach(function (feature, i) {
      $D2.S.INFO('feature', { feature, i });

      if (feature.geometry.type === 'Point') {
        var dist = distance(point, makePoint(j), 'kilometers');
        if (dist < minDist) {
          minDist = dist;
          nearest = feature;
        }
      } else if (feature.geometry.type === 'LineString') {
        const dist = pointToLineDistance(point, feature, {
          units: 'kilometers',
        });
        if (dist < minDist) {
          minDist = dist;
          nearest = feature;
        }
      } else {
        const dist = feature.geometry.coordinates.reduce(
          (memo, item) =>
            Math.min(memo, distance(point, makePoint(item), 'kilometers')),
          Infinity
        );
        if (dist < minDist) {
          minDist = dist;
          nearest = feature;
        }
      }
    });

    return { nearest, distance: minDist };
  });

export const nearestFeature = (point, features) =>
  D2.S.FUNCTION('nearestFeature', { point, features }, () => {
    const { nearest } = nearestFeatureEx(point, features);
    return nearest;
  });

export default nearestFeature;

import { sleep } from 'utils/CommuteSchedule/utils';

import { api$Masstransit } from 'api';

import debug from 'utils/debug';

const D2 = debug('m:CommuteSchedule:ResolveBuildingIds');

const resolveBuildingIds = async (buildingIds, opts) => {
  const { setProgress = () => {}, ignoreErrors = false } = opts;

  const buildingRequestsResolved = [];
  const buildingRequestsRejected = [];

  const buildingRequests = Object.values(buildingIds).map(location =>
    api$Masstransit.buildingById(location.building_id).then(
      (result) => {
        buildingRequestsResolved.push(result);
      },
      (error) => {
        buildingRequestsRejected.push({
          building_id: location.building_id,
          error,
        });
      }
    )
  );
  D2.S.INFO('buildingRequests', { buildingRequests });

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const progress =
      (buildingRequestsResolved.length + buildingRequestsRejected.length) /
      buildingRequests.length;
    D2.S.INFO('buildingRequests:Progress', {
      buildingRequestsResolved,
      buildingRequestsRejected,
      buildingRequests,
      progress,
    });
    // eslint-disable-next-line no-await-in-loop
    await setProgress('Resolving building identifiers', progress);
    if (
      buildingRequestsResolved.length + buildingRequestsRejected.length ===
      buildingRequests.length
    ) {
      break;
    }
    // eslint-disable-next-line no-await-in-loop
    await sleep(500);
  }
  D2.S.INFO('buildingRequestsResolved', {
    buildingRequestsResolved,
  });
  D2.S.INFO('buildingRequestsRejected', {
    buildingRequestsRejected,
  });

  const buildingsByBuildingId = buildingRequestsResolved.reduce(
    (acc, record) => {
      const { result } = record;
      const id = `${record.building_id}`;
      if (result.objects && result.objects.length) {
        acc.resolved[id] = result.objects.map(item => ({
          id,
          name: item.name,
          address: item.address,
          blk_no: item.blk_no,
          coordinates: item.point.coordinates,
          code: item.code,
          city: record.city,
          country: record.country,
        }));
      } else {
        acc.failed.push({
          id: record.id,
          building_id: record.building_id,
          error: new Error('No objects returned'),
        });
      }
      return acc;
    },
    { resolved: {}, failed: [] }
  );
  D2.S.INFO('buildingsByBuildingId', {
    buildingsByBuildingId,
  });

  if (!ignoreErrors) {
    const buildingRequestsFailed = [
      ...buildingRequestsRejected,
      ...buildingsByBuildingId.failed,
    ];
    if (buildingRequestsFailed.length) {
      const message = `Failed to resolve zip codes: ${buildingRequestsFailed
        .map(item => item.building_id)
        .join(', ')}`;
      D2.S.INFO('Throw', { message, ignoreErrors });
      throw new Error(message);
    }
  }

  return {
    buildingRequestsResolved,
    buildingRequestsRejected,
    buildingsByBuildingId,
  };
};

const commuteSchedule$ResolveBuildingIds = async (
  currentPassengersData,
  opts
) => {
  const { setProgress = () => {}, ignoreErrors = false } = opts;

  const personBuildingIds = currentPassengersData
    .reduce((acc, person) => {
      const locations = [person.home, person.office].map(
        location =>
          location.$type === 'building_id' && {
            id: `${location.country}:${location.city}:${location.building_id}`,
            building_id: location.building_id,
          }
      );
      return [...acc, ...locations];
    }, [])
    .filter(x => !!x);
  D2.S.INFO('personBuildingIds', {
    personBuildingIds,
  });

  const buildingIds = personBuildingIds.reduce((acc, location) => {
    return {
      ...acc,
      [location.id]: location,
    };
  }, {});
  D2.S.INFO('buildingIds', {
    buildingIds,
  });

  const {
    buildingRequestsResolved,
    buildingRequestsRejected,
    buildingsByBuildingId,
  } = await resolveBuildingIds(buildingIds, {
    setProgress,
    ignoreErrors,
  });
  D2.S.INFO('buildingRequestsResolved', {
    buildingRequestsResolved,
  });
  D2.S.INFO('buildingRequestsRejected', {
    buildingRequestsRejected,
  });
  D2.S.INFO('buildingsByBuildingId', { buildingsByBuildingId });

  const dataWithBuildings = currentPassengersData.map((record) => {
    const resolveLocation = location =>
      location.$type === 'building_id'
        ? {
            ...location,
            $buildings: buildingsByBuildingId.resolved[location.id],
          }
        : location;
    return {
      ...record,
      home: resolveLocation(record.home),
      office: resolveLocation(record.office),
    };
  });
  D2.S.INFO('dataWithBuildings', { dataWithBuildings });

  return dataWithBuildings;
};

export default commuteSchedule$ResolveBuildingIds;

import styled from 'styled-components';

const calcProps = (layoutName) => {
  const layouts = {
    default: () => ({
      bottom: '280px',
      right: '5px',
    }),
    delivery: () => ({
      bottom: '30px',
      right: '50px',
    }),
  };
  const fn = layouts[layoutName] || layouts.default;

  const result = fn();

  return result;
};

export default styled.div`
  position: fixed;
  bottom: ${({ layoutName }) => calcProps(layoutName).bottom};
  right: ${({ layoutName }) => calcProps(layoutName).right};
  background-color: rgb(54, 61, 74);
  border-radius: 2px;
`;

import debug from 'utils/debug';

const D2 = debug('u');

const displayNames = value =>
  D2.S.FUNCTION('displayNames', { value }, ({ $D2 }) => {
    if (typeof value === 'undefined') {
      return ['undefined'];
    }
    if (typeof value === 'number') {
      return displayNames(value.toString());
    }
    if (typeof value !== 'string') {
      $D2.S.INFO('InvalidType', { value, $value: typeof value });
      return displayNames(JSON.stringify(value));
    }
    return value
      .split('_')
      .join(' ')
      .split('$')
      .join(' ')
      .split('|')
      .join(' ')
      .split(' ')
      .reduce((a, x, i) => (i > 0 ? [...a, ' ', x] : [...a, x]), []);
  });

export default displayNames;

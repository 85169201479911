import React from 'utils/react';
import debug from 'utils/debug';
import Container from './Container';
import Column from './Column';
import LeftColumn from './LeftColumn';
import Main from './Main';
import RightColumn from './RightColumn';
import withTranslation from 'utils/react/withTranslation';

const D2 = debug('p:CommuteOffers:Table:Header');

const config = [];

const Header = props =>
  D2.S.FUNCTION('Header', { props }, () => {
    const onSort = (value) => {
      props.changeCommuteOffersOrdering(value);
    };

    const { t } = props;

    return (
      <Container>
        <LeftColumn>
          <Column
            isActive={props.ordering.get('id') === 'id'}
            sort={
              props.ordering.get('id') === 'id' && props.ordering.get('sort')
            }
            onClick={() => onSort('id')}
            width='70px'
            style={{ marginLeft: -10 }}
          >
            ID
          </Column>
          <Column
            isActive={props.ordering.get('id') === 'name'}
            sort={
              props.ordering.get('id') === 'name' && props.ordering.get('sort')
            }
            onClick={() => onSort('name')}
          >
            {t('c.Header.Name')}
          </Column>
        </LeftColumn>
        <Main>
          {config.map(({ label, ...properties }) => {
            const isActive =
              properties.id && props.ordering.get('id') === properties.id;

            const sort = isActive && props.ordering.get('sort');

            const onChange = () => {
              // refactoring
              props.changeCommuteOffersOrdering(properties.id);
              props.clearList();
              props.fetchCommuteOffers();
            };

            return (
              <Column
                isActive={isActive}
                onClick={onChange}
                {...properties}
                sort={sort}
              >
                {label}
              </Column>
            );
          })}
        </Main>
        <RightColumn>
          <Column>{t('c.Header.content')}</Column>
        </RightColumn>
      </Container>
    );
  });

export default withTranslation()(Header);

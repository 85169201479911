import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: ${({ isLoading }) => (isLoading ? 'calc(100vh - 230px)' : 'auto')};
  @media screen and (max-width: 1400px) {
    zoom: 0.8;
    height: ${({ isLoading }) =>
      isLoading ? 'calc((100vh * 1.2) - 230px)' : 'auto'};
  }
`;

export default Container;

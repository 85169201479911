import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  cursor: pointer;
  position: sticky;
  top: -3px;
  align-items: center;
  color: #c5cede;
  flex-shrink: 0;
  border-radius: 10px;
  margin: 0;
  min-height: 30px;
  z-index: 5;
  background: rgb(54, 61, 74);
`;

export default Container;

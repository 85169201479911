import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { toggleVisibilityTransitstopType } from 'modules/ui/actions';
import {
  transitstopTypesSelector,
  visibleTransitstopTypesSelector,
} from 'modules/ui/selectors';

import TransitstopTypesMenu from './TransitstopTypesMenu';

const mapStateToProps = createSelector(
  transitstopTypesSelector,
  visibleTransitstopTypesSelector,
  (transitstopTypes, visibleTransitstopTypes) => ({
    transitstopTypes,
    visibleTransitstopTypes,
  })
);

const mapDispatchToProps = {
  toggleVisibilityTransitstopType,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(TransitstopTypesMenu);

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { newGeofenceViewportSelector } from 'modules/maps/selectors';

import { changeViewportNewGeofence } from 'modules/maps/actions';

import Draw from './Draw';

const mapStateToProps = createImmutableSelector(
  newGeofenceViewportSelector,
  viewport => ({
    viewport,
  })
);

const mapDispatchToProps = {
  onViewportChange: changeViewportNewGeofence,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Draw);

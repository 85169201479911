import styled from 'styled-components';
import chevron from 'assets/chevron.svg';

export default styled.div`
  position: absolute;
  left: 0px;
  top: 12px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: url(${chevron}) no-repeat center;
  transform: rotate(90deg);
  background-size: 12px 12px;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;

import { diffString } from 'json-diff';

const diffStringSafe = (...args) => {
  try {
    return diffString(...args);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export default diffStringSafe;

// import url from 'url';
import { normalizeCommuteOffer } from 'utils/CommuteOffer';
// import $async from 'promise-async';
import { removeInternalFieldsFromObject } from 'utils/object';
import debug from 'utils/debug';
import { urls } from '../config';
import { fetchData, fetchJSON } from './net';
import { getHeaders } from './headers';

const D2 = debug('api:commuteOffer');

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const getTransitStopsBySet = async (transitstopset_id) => {
  D2.S.INFO('getTransitStopsBySet:Request', { transitstopset_id });

  try {
    const response = await fetchData(
      urls.transitStopsBySet(transitstopset_id),
      {
        headers: getHeaders(),
      }
    );

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();

    D2.S.INFO('getTransitStopsBySet:Success', {
      transitstopset_id,
      response: responseJSON,
    });
    return responseJSON;
  } catch (error) {
    D2.S.INFO('getTransitStopsBySet:Failure', { transitstopset_id, error });
    throw error;
  }
};

export const getTransitStopSets = async (projectId) => {
  D2.S.INFO('getTransitStopSets:Request', { projectId });

  try {
    const requestUrl = urls.transitstopsets(projectId);
    // D2.S.INFO('getTransitStopSets:url', { url: url.parse(requestUrl) });

    const response = await fetchData(requestUrl, {
      headers: getHeaders(),
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();

    // const stops = await Promise.all(
    //   responseJSON.objects.map(transitstopset =>
    //     getTransitStopsBySet(transitstopset.id)
    //   )
    // );

    D2.S.INFO('getTransitStopSets:Success', {
      projectId,
      response: responseJSON,
      stops: [],
    });
    return responseJSON;
  } catch (error) {
    D2.S.INFO('getTransitStopSets:Failure', { projectId, error });
    throw error;
  }
};

export const jobResult = async (id) => {
  D2.S.INFO('jobResult:Request', { id });

  try {
    const response = await fetchData(urls.jobResult(id), {
      headers: getHeaders(),
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();

    D2.S.INFO('jobResult:Success', { id, response: responseJSON });
    return responseJSON;
  } catch (error) {
    D2.S.INFO('jobResult:Failure', { id, error });
    throw error;
  }
};

export const statelessNodeScheduler = async (data) => {
  D2.S.INFO('statelessNodeScheduler:Request', { data });

  try {
    const body = JSON.stringify(removeInternalFieldsFromObject(data));

    const response = await fetchData(urls.statelessNodeScheduler, {
      method: 'POST',
      headers: getHeaders(),
      body,
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();

    D2.S.INFO('statelessNodeScheduler:Success', { response: responseJSON });
    return responseJSON;
  } catch (error) {
    D2.S.INFO('statelessNodeScheduler:Failure', { data, error });
    throw error;
  }
};

export const scheduleCommuteOffer = async (commuteOffer) => {
  D2.S.INFO('scheduleCommuteOffer:Request', commuteOffer);

  let statelessRequest = null;

  try {
    const { engine_settings, nodes, vehicles, current_time } =
      commuteOffer.stateless_api_request_data;
    statelessRequest = { engine_settings, nodes, vehicles, current_time };
    D2.S.INFO('scheduleCommuteOffer:statelessRequest', { statelessRequest });

    if (
      !statelessRequest.nodes ||
      !statelessRequest.vehicles ||
      !statelessRequest.current_time
    ) {
      return new Error('Invalid request structure');
    }

    if (!statelessRequest.nodes.length) {
      D2.S.INFO('scheduleCommuteOffer:Success', {
        commuteOffer,
        $reason: 'Empty nodes list',
      });
      return commuteOffer;
    }

    const normalizedRequest = {
      ...statelessRequest,
      engine_settings: {
        ...statelessRequest.engine_settings,
        routing_engine: {
          ...statelessRequest.engine_settings.routing_engine,
          url:
            statelessRequest.engine_settings.routing_engine.url ||
            global.GEODISC_INTERNAL_OSRM_URL,
          key:
            statelessRequest.engine_settings.routing_engine.key ||
            global.GEODISC_INTERNAL_OSRM_TOKEN,
        },
      },
      vehicles: statelessRequest.vehicles.map(vehicle => ({
        ...vehicle,
        routing_engine: {
          ...vehicle.routing_engine,
          url: vehicle.routing_engine.url || global.GEODISC_INTERNAL_OSRM_URL,
          key: vehicle.routing_engine.key || global.GEODISC_INTERNAL_OSRM_TOKEN,
        },
      })),
    };

    // eslint-disable-next-line
    // console.log({ normalizedRequest });
    D2.S.INFO('scheduleCommuteOffer:statelessRequest', { normalizedRequest });
    const job = await statelessNodeScheduler(normalizedRequest);

    // eslint-disable-next-line
    // console.log({ job });
    D2.S.INFO('scheduleCommuteOffer:Job', { job });

    if (!job.job_id) {
      throw new Error('Failed to get job_id');
    }

    // eslint-disable-next-line no-constant-condition
    for (let attempt = 1; attempt <= 60 * 60 * 12; ++attempt) {
      // eslint-disable-next-line no-await-in-loop
      const serverResponse = await jobResult(job.job_id);
      // eslint-disable-next-line
      // console.log({
      //   id: job.job_id,
      //   status: serverResponse && serverResponse.status,
      //   normalizedRequest,
      //   response: serverResponse
      // });

      if (serverResponse.status && serverResponse.status === 'FAILURE') {
        throw new Error(`Scheduler Failure: ${serverResponse.result}`);
      }

      if (serverResponse.status && serverResponse.status === 'SUCCESS') {
        if (serverResponse.result && serverResponse.result.error) {
          throw new Error(`Scheduler Error: ${serverResponse.result.error}`);
        }

        D2.S.INFO('scheduleCommuteOffer:Response', { serverResponse });
        // eslint-disable-next-line no-await-in-loop
        const validatedResult = await normalizeCommuteOffer({
          ...commuteOffer,
          result: serverResponse.result,
        });

        D2.S.INFO('scheduleCommuteOffer:Success', {
          commuteOffer,
          serverResponse,
          validatedResult,
        });

        return validatedResult;
      }

      // eslint-disable-next-line no-await-in-loop
      await sleep(2000 + Math.floor(Math.random() * 3000));
    }

    throw new Error('Timed out');
  } catch (error) {
    D2.S.INFO('scheduleCommuteOffer:Failure', {
      commuteOffer,
      statelessRequest,
      error,
    });
    throw error;
  }
};

// export const scheduleCommuteOffers = async commuteOffers => {
//   D2.S.INFO('scheduleCommuteOffers:Calculate:Request', { commuteOffers });
//   const results = await Promise.all(
//     commuteOffers.map(offer => scheduleCommuteOffer(offer))
//   );
//   D2.S.INFO('scheduleCommuteOffers:Calculate:Results', { results });
//   return results;
// };

export const scheduleCommuteOffers = async (commuteOffers, opts) => {
  D2.S.INFO('scheduleCommuteOffers:Request', { commuteOffers, opts });

  const options = opts || {};

  const ignoreErrors = options.ignoreErrors || false;
  const setProgress = options.setProgress || (async () => {});

  setProgress(0);

  const resolved = [];
  const rejected = [];

  const requests = commuteOffers.map((commuteOffer) => {
    return scheduleCommuteOffer(commuteOffer).then(
      (result) => {
        resolved.push(result);
      },
      (error) => {
        rejected.push({ commuteOffer, error });
      }
    );
  });
  D2.S.INFO('scheduleCommuteOffers:requests', { requests });

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const progress = (resolved.length + rejected.length) / requests.length;
    D2.S.INFO('scheduleCommuteOffers:scheduleCommuteOffers:Progress', {
      resolved,
      rejected,
      requests,
      progress,
    });

    // eslint-disable-next-line no-await-in-loop
    await setProgress(progress);

    if (resolved.length + rejected.length === requests.length) {
      break;
    }
    // eslint-disable-next-line no-await-in-loop
    await sleep(500);
  }

  setProgress(1);

  if (!ignoreErrors) {
    if (rejected.length) {
      const error = rejected[0].error;

      D2.S.INFO('scheduleCommuteOffers:Failure', { rejected });
      throw error;
    }
  }

  D2.S.INFO('scheduleCommuteOffers:Success', { resolved });
  return resolved;
};

export const getCommuteOffers = async projectId =>
  fetchJSON(urls.commuteOffersByProject(projectId), {
    headers: getHeaders(),
  });

export const getCommuteOffer = async id =>
  fetchJSON(urls.commuteOffer(id), {
    headers: getHeaders(),
  });

export const deleteCommuteOffer = async (id) => {
  try {
    const response = await fetchData(urls.commuteOffer(id), {
      method: 'DELETE',
      headers: getHeaders(),
    });
    if (!response.ok) {
      throw new Error(response.status);
    }

    return null;
  } catch (error) {
    throw error;
  }
};

export const getRoute = async (url) => {
  D2.S.INFO('getRoute:Request', { url });

  try {
    const response = await fetchData(url);

    if (!response.ok) {
      D2.S.INFO('getRoute:Failure', { url, response });
      if (response.status === 400) {
        D2.S.INFO('Error: Failed to load a route.', { url });
        return {
          routes: [],
          waypoints: [],
          code: 'Ok',
        };
      }
      throw new Error(response.status);
    }

    const responseJSON = await response.json();

    D2.S.INFO('getRoute:Result', { url, response: responseJSON });
    return responseJSON;
  } catch (error) {
    throw error;
  }
};

export const addCommuteOffer = async (data) => {
  D2.S.INFO('addCommuteOffer:Request', { data });

  try {
    const body = JSON.stringify(
      removeInternalFieldsFromObject({
        ...data,
        resource_uri: undefined,
        id: undefined,
        modified_at: undefined,
        deleted: false,
      })
    );

    const response = await fetchData(urls.commuteOffers, {
      method: 'POST',
      headers: getHeaders(),
      body,
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();

    D2.S.INFO('addCommuteOffer:Success', responseJSON);
    return responseJSON;
  } catch (error) {
    D2.S.INFO('addCommuteOffer:Failure', { error });
    throw error;
  }
};

export const updateCommuteOffer = async (id, data) => {
  const url = urls.commuteOffer(id);

  try {
    const body = JSON.stringify(removeInternalFieldsFromObject(data));

    const response = await fetchData(url, {
      method: 'PUT',
      headers: getHeaders(),
      body,
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();

    return responseJSON;
  } catch (error) {
    throw error;
  }
};

export const fetchProtocolFile = async (id, timezone) => {
  const url = urls.commuteOfferBinaryProtocol(id, timezone);

  const response = await fetchData(url, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    throw new Error(response.status);
  }

  const responseData = await response.blob();

  return { data: responseData };
};

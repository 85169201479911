import debug from 'utils/debug';
import uiReducers from './ui';
import commuteOfferReducers from './commuteOffer';

const D2 = debug('_:ui:reducers');

const reducers = {
  ...uiReducers,
  ...commuteOfferReducers,
};

D2.S.INFO('reducers', { reducers, uiReducers, commuteOfferReducers });

export default Object.keys(reducers).reduce(
  (memo, item) => ({ ...memo, [reducers[item].type]: reducers[item] }),
  {}
);

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { editableVehicleIdSelector } from 'modules/ui/selectors';
import { clearEditableVehicleId } from 'modules/ui/actions';

import { editableVehicleSelector } from 'modules/commuteOffer/selectors';
import { updateVehicle, deleteVehicle } from 'modules/commuteOffer/actions';

import VehiclesEditor from './VehiclesEditor';

const mapStateToProps = createImmutableSelector(
  editableVehicleSelector,
  editableVehicleIdSelector,
  (editableVehicle, editableVehicleId) => ({
    editableVehicle,
    editableVehicleId,
  })
);

const mapDispatchToProps = {
  clearEditableVehicleId,
  updateVehicle,
  deleteVehicle,
};

export default connect(mapStateToProps, mapDispatchToProps)(VehiclesEditor);

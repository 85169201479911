import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import {
  fetchDatasets,
  downloadDatasetCsv,
  clearList,
} from 'modules/datasets/actions';

import { listSelector } from 'modules/datasets/selectors';
import {
  datasetsErrorSelector,
  selectedIdsDatasetsSelector,
} from 'modules/ui/selectors';
import { currentProjectIdSelector } from 'modules/user/selectors';

import Datasets from './Datasets';

const mapStateToProps = createImmutableSelector(
  listSelector,
  datasetsErrorSelector,
  selectedIdsDatasetsSelector,
  currentProjectIdSelector,
  (list, error, selectedIds, projectId) => ({
    list,
    error,
    selectedIds,
    projectId,
  })
);

const mapDispatchToProps = {
  downloadDatasetCsv,
  fetchDatasets,
  clearList,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Datasets);

import parseColor from 'parse-color';
import getContrastRatio from 'get-contrast-ratio';

import { crc32 } from './crc32';

export const getObjectColor = (object, opts = {}) => {
  const {
    uidField = 'uid',
    colorField = 'color',
    S = '100%',
    L = '70%',
  } = opts;

  const objectUid = String(object[uidField]);

  const autogeneratedHue = () => Math.round(crc32(objectUid) % 360);

  const objectColor =
    typeof colorField === 'function' ? colorField(object) : object[colorField];

  const normalizeColor = (color, opts = { objectColor, objectUid, object }) => {
    // console.log('*** Color:', { color, ...opts });
    return color;
  };

  if (objectColor) {
    if (typeof vehicle_color === 'number') {
      const hue = Math.abs(Math.round(objectColor % 360));
      return normalizeColor(`hsl(${hue}, ${S}, ${L})`);
    }
    if (typeof objectColor === 'string') {
      const hue =
        objectColor === '#000000'
          ? autogeneratedHue()
          : parseColor(objectColor)?.hsl?.[0] ?? autogeneratedHue();
      return normalizeColor(`hsl(${hue}, ${S}, ${L})`);
    }
  }

  const hue = autogeneratedHue();
  return normalizeColor(`hsl(${hue}, ${S}, ${L})`);
};

export const normalizeGeofenceColor = geofence =>
  getObjectColor(geofence, { uidField: 'id' });

export const normalizeVehicleColor = (vehicle, opts) =>
  getObjectColor(vehicle, {
    ...opts,
    uidField: 'agent_id',
    colorField: x => x.color ?? x.vehicle_color,
  });

export const colorByVehicle = (vehicle, opts = {}) => {
  // const isEnabled = vehicle.in_use !== 'disabled';
  const isEnabled = true;
  return {
    $activeColor: normalizeVehicleColor(vehicle, {
      ...opts,
      L: isEnabled ? '70%' : '40%',
    }),
    $draggableColor: normalizeVehicleColor(vehicle, {
      ...opts,
      L: isEnabled ? '40%' : '40%',
    }),
  };
};

// export const normalizeVehicleColor = (vehicle) => {
//   const autogeneratedHue = () => Math.round(crc32(vehicle.agent_id) % 360);

//   const vehicle_color = vehicle.color ?? vehicle.vehicle_color;

//   if (vehicle_color) {
//     if (typeof vehicle_color === 'number') {
//       const hue = Math.abs(Math.round(vehicle_color % 360));
//       return `hsl(${hue}, 100%, 70%)`;
//     }
//     if (typeof vehicle_color === 'string') {
//       const hue =
//         vehicle_color === '#000000'
//           ? autogeneratedHue()
//           : parseColor(vehicle_color)?.hsl?.[0] ?? autogeneratedHue();
//       return `hsl(${hue}, 100%, 70%)`;
//     }
//   }

//   const hue = autogeneratedHue();
//   return `hsl(${hue}, 100%, 70%)`;
// };

export const normalizeVehicleColorForExport = (vehicle) => {
  const hex = parseColor(normalizeVehicleColor(vehicle))
    .rgb.map(x => x.toString(16).padStart(2, '0'))
    .join('');
  return `#${hex}`;
};

export const getContrastRandomColor = (background) => {
  const color = `#${Math.random().toString(16).substr(-6)}`;
  if (getContrastRatio(background, color) > 5) {
    return color;
  }

  return getContrastRandomColor(background);
};

export const rgbaToDeckColor = (rgba) => {
  const { r, g, b, a } = rgba;

  return [r, g, b, a * 255];
};

export const hexToDeckColor = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
};

export const deckColorToRgba = (color) => {
  const [r, g, b, alpha] = color;
  const a = alpha >= 1 && alpha <= 255 ? alpha / 255 : 1;
  return { r, g, b, a };
};

export const deckColorToHex = (color) => {
  const [r, g, b] = color;

  let hexR = r.toString(16);
  let hexG = g.toString(16);
  let hexB = b.toString(16);

  hexR = hexR.length === 1 ? `0${hexR}` : hexR;
  hexG = hexG.length === 1 ? `0${hexG}` : hexG;
  hexB = hexB.length === 1 ? `0${hexB}` : hexB;

  return `#${hexR}${hexG}${hexB}`;
};

const defaultColor = '#FF0000';

export const colorByValue = (value = defaultColor) => {
  if (typeof value === 'number') {
    const hue = Math.round(value % 360);
    return {
      $draggableColor: `hsl(${hue}, 100%, 40%)`,
      $activeColor: `hsl(${hue}, 100%, 70%)`,
      // color: `hsl(${hue}, 100%, 50%)`
    };
  }

  const color = parseColor(value) ?? parseColor(defaultColor);
  const hue = color.hsl[0];

  return {
    $draggableColor: `hsl(${hue}, 100%, 40%)`,
    $activeColor: `hsl(${hue}, 100%, 70%)`,
    // color: `hsl(${hue}, 100%, 50%)`
  };
};

export const colorById = id => colorByValue(crc32(id));

export default rgbaToDeckColor;

import React from 'react';
import { useFieldArray } from 'react-hook-form';
import removeBtn from 'assets/removeBtn.svg';
import CustomButton from './Button';
import { InputField } from './FormField';
import { InputWrapper } from './FormStyles';
import FormFieldLabel from './FormLabel';
import { useStyletron } from 'baseui';
import Error from './Error';

const ArrayField = (props) => {
  const { register, errors, control, fieldName, subFields, addButtonText } =
    props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  });
  const [css] = useStyletron();

  const getField = ({ index, subIndex, type }) => {
    return type === 'data'
      ? [fieldName]?.[index]?.[subFields[subIndex]?.name]
      : `${fieldName}.${index}.${subFields[subIndex]?.name}`;
  };
  const getError = ({ index, subIndex }) => {
    return errors?.[fieldName]?.[index]?.[subFields[subIndex]?.name];
  };

  const emptyRow = subFields?.reduce((memory, value) => {
    return { ...memory, [value?.name]: '' };
  }, {});
  return (
    <React.Fragment>
      <div>
        <div
          className={css({
            display: 'flex',
            flexDirection: 'row',
          })}
        >
          {subFields?.map((data, index) => {
            return (
              <InputWrapper
                key={index}
                width={data?.width}
                className={css(index === 0 ? {} : { marginLeft: '20px' })}
              >
                <FormFieldLabel label={data?.label} />
              </InputWrapper>
            );
          })}
        </div>

        <div className={css({ display: 'flex', flexDirection: 'column' })}>
          {fields.map((item, index) => (
            <span
              key={item.id}
              className={css({ display: 'flex', flexDirection: 'row' })}
            >
              {subFields?.map((subField, subIndex) => {
                return (
                  <InputWrapper
                    key={subIndex}
                    width={subField?.width}
                    className={css(
                      subIndex === 0 ? {} : { marginLeft: '20px' }
                    )}
                  >
                    <InputField
                      placeholder={subField?.placeholder}
                      type='string'
                      {...register(getField({ index, subIndex }), {
                        ...subField?.validate,
                      })}
                      $isError={!!getError({ index, subIndex })}
                    />
                    {getError({ index, subIndex })?.message && (
                      <Error>{getError({ index, subIndex })?.message}</Error>
                    )}
                  </InputWrapper>
                );
              })}
              {fields?.length !== 1 && (
                <div
                  role='button'
                  styling='link'
                  tabIndex={0}
                  onClick={() => remove(index)}
                  onKeyDown={() => remove(index)}
                  className={css({
                    background: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    height: '50px',
                    position: 'relative',
                    top: '16px',
                    left: '20px',
                    cursor: 'pointer',
                  })}
                >
                  <img src={removeBtn} alt='remove button' />
                </div>
              )}
              <div className={css({ clear: 'both' })} />
            </span>
          ))}
        </div>
        <CustomButton
          type='button'
          onClick={(e) => {
            e.stopPropagation();
            append(emptyRow);
          }}
          className={css({ marginTop: '12px', marginLeft: '0px !important' })}
        >
          {addButtonText}
        </CustomButton>
      </div>
    </React.Fragment>
  );
};

export default ArrayField;

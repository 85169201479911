import MethodWindow from './MethodWindow';

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';
import withTranslation from 'utils/react/withTranslation';
import { commuteOfferHasOnlineVehiclesSelector } from 'modules/commuteOffer/selectors';

const mapStateToProps = createImmutableSelector(
  commuteOfferHasOnlineVehiclesSelector,
  hasOnlineVehicles => ({
    hasOnlineVehicles,
  })
);

const withConnect = connect(mapStateToProps);

export default withTranslation()(withConnect(MethodWindow));

export const AUTHORIZATION_REQUEST = '@@user/AUTHORIZATION_REQUEST';
export const AUTHORIZATION_SUCCESS = '@@user/AUTHORIZATION_SUCCESS';
export const AUTHORIZATION_FAILURE = '@@user/AUTHORIZATION_FAILURE';
export const authorization = (username, isAuto) => ({
  type: AUTHORIZATION_REQUEST,
  payload: { username, isAuto },
});
export const authorizationFailure = error => ({
  type: AUTHORIZATION_REQUEST,
  payload: error,
});

export const PROJECTS_CLEAR_REQUEST = '@@user/PROJECTS_CLEAR_REQUEST';
export const clearProjects = () => ({
  type: PROJECTS_CLEAR_REQUEST,
});

export const PROJECTS_FETCH_REQUEST = '@@user/PROJECTS_FETCH_REQUEST';
export const PROJECTS_FETCH_SUCCESS = '@@user/PROJECTS_FETCH_SUCCESS';
export const PROJECTS_FETCH_FAILURE = '@@user/PROJECTS_FETCH_FAILURE';
export const fetchProjects = () => ({
  type: PROJECTS_FETCH_REQUEST,
});

export const CURRENT_PROJECT_CHANGE = '@@user/CURRENT_PROJECT_CHANGE';
export const changeCurrentProject = (newProject, oldProject, nextPage) => ({
  type: CURRENT_PROJECT_CHANGE,
  payload: { newProject, oldProject, nextPage },
});

export const DELETE_USER = '@@user/DELETE_USER';
export const deleteUser = () => ({ type: DELETE_USER });

export const GEOFENCES_FETCH_REQUEST = '@@geofences/GEOFENCES_FETCH_REQUEST';
export const GEOFENCES_FETCH_SUCCESS = '@@geofences/GEOFENCES_FETCH_SUCCESS';
export const GEOFENCES_FETCH_FAILURE = '@@geofences/GEOFENCES_FETCH_FAILURE';
export const fetchGeofences = () => ({ type: GEOFENCES_FETCH_REQUEST });

export const ADD_GEOFENCE_REQUEST = '@@geofences/ADD_GEOFENCE_REQUEST';
export const ADD_GEOFENCE_SUCCESS = '@@geofences/ADD_GEOFENCE_SUCCESS';
export const ADD_GEOFENCE_FAILURE = '@@geofences/ADD_GEOFENCE_FAILURE';
export const addGeofence = (body, fromDataset) => ({
  type: ADD_GEOFENCE_REQUEST,
  payload: { body, fromDataset },
});

export const DELETE_GEOFENCE_REQUEST = '@@geofences/DELETE_GEOFENCE_REQUEST';
export const DELETE_GEOFENCE_SUCCESS = '@@geofences/DELETE_GEOFENCE_SUCCESS';
export const DELETE_GEOFENCE_FAILURE = '@@geofences/DELETE_GEOFENCE_FAILURE';
export const deleteGeofence = id => ({
  type: DELETE_GEOFENCE_REQUEST,
  payload: id,
});

export const UPDATE_GEOFENCE_REQUEST = '@@geofences/UPDATE_GEOFENCE_REQUEST';
export const UPDATE_GEOFENCE_SUCCESS = '@@geofences/UPDATE_GEOFENCE_SUCCESS';
export const UPDATE_GEOFENCE_FAILURE = '@@geofences/UPDATE_GEOFENCE_FAILURE';
export const updateGeofence = (id, body) => ({
  type: UPDATE_GEOFENCE_REQUEST,
  payload: { id, body },
});

export const COPY_GEOFENCE_REQUEST = '@@geofences/COPY_GEOFENCE_REQUEST';
export const COPY_GEOFENCE_SUCCESS = '@@geofences/COPY_GEOFENCE_SUCCESS';
export const COPY_GEOFENCE_FAILURE = '@@geofences/COPY_GEOFENCE_FAILURE';
export const copyGeofence = id => ({
  type: COPY_GEOFENCE_REQUEST,
  payload: id,
});

export const SAVE_GEOFENCE = '@@geofences/SAVE_GEOFENCE';
export const saveGeofence = (id, body) => ({
  type: SAVE_GEOFENCE,
  payload: { id, body },
});

export const CLEAR_LIST = '@@geofences/CLEAR_LIST';
export const clearList = () => ({
  type: CLEAR_LIST,
});

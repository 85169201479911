import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.6)};
`;

export default Container;

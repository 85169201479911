import React from 'utils/react';
import moment from 'moment-timezone';
import debug from 'utils/debug';
import { LOGISTICS_SERVICES_ROUTE } from 'utils/constants';
import { commuteOffersServiceDateSelector } from 'modules/ui/selectors';
import { currentProjectSelector } from 'modules/user/selectors';
import Container from './Container';
import NavLink from './NavLink';

const D2 = debug('c:Header:Navigation:Navigation');

const Delivery = props =>
  D2.S.FUNCTION('Delivery', { props }, () => {
    const { t, currentUserConfig, pageAddress } = props;
    const simulationDate = moment(
      global.geodisc$select(commuteOffersServiceDateSelector)
    ).format('YYYY-MM-DD');
    const projectId = global.geodisc$select(currentProjectSelector)?.get('id');
    const { pages = {} } = currentUserConfig?.geodisc_settings || {};
    const {
      geofences = true,
      logistics_services = true,
      logistics_template = false,
    } = pages;
    const logisticServiceRoute = pageAddress({
      page: LOGISTICS_SERVICES_ROUTE,
      ...D2.CONTEXT,
    });
    return (
      <Container>
        {!window.GEODISC_UI_GEOFENCES_DISABLE && geofences && (
          <NavLink to='/geofences'>{t('header.default.Geofences')}</NavLink>
        )}
        {!window.GEODISC_UI_SIMULATIONS_DISABLE && logistics_services && (
          <NavLink to={logisticServiceRoute}>
            {t('header.delivery.RoutePlanning')}
          </NavLink>
        )}
        {!window.GEODISC_UI_SIMULATIONS_DISABLE && logistics_template && (
          <NavLink to='/logistics-template'>
            {t('header.delivery.LogisticsTemplate')}
          </NavLink>
        )}
      </Container>
    );
  });

export default React.memo(Delivery);

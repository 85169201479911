// @flow

import createImmutableSelector from 'utils/immutable-selector';
import { connect } from 'react-redux';
import {
  vehiclesFilteredBySearchSelector,
  bookingsFilteredBySearchSelector,
} from 'modules/commuteOffer/selectors';
import {
  changeSearchQuery,
  setActiveVehicleId,
  setActiveBookingId,
} from 'modules/ui/actions';

import {
  flyToCommuteOfferActiveBooking,
  flyToCommuteOfferVehicle,
} from 'modules/maps/actions';

import Search from './Search';

const mapStateToProps = createImmutableSelector(
  vehiclesFilteredBySearchSelector,
  bookingsFilteredBySearchSelector,
  (vehicles, bookings) => ({ vehicles, bookings })
);
const mapDispatchToProps = {
  changeSearchQuery,
  setActiveVehicleId,
  setActiveBookingId,
  flyToCommuteOfferActiveBooking,
  flyToCommuteOfferVehicle,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Search);

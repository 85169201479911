import React from 'utils/react';
import useTranslation from 'utils/react/useTranslation';
import { useState } from 'react';

import CSV from 'comma-separated-values';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { exportFile } from 'utils/file';
import { readCsvFileAsync } from 'utils/csv';

import debug from 'utils/debug';
import { silverayScheduleInboundColumns } from 'utils/CommuteOffer/silveray';
import Table from './Table';

const D2 = debug('c:ModalWindows:PassengersTableViewer');

// const defaultColumnsMap = [
//   ['RESIDENT', 'name'],
//   ['POST CODE', 'zip_code']
// ];

// eslint-disable-next-line import/prefer-default-export
export const importRemarksTable = (targetTable, sourceTable, opts) =>
  D2.S.FUNCTION(
    'ImportRemarksTable',
    { targetTable, sourceTable, opts },
    ({ $D2 }) => {
      // const { columnsMap = defaultColumnsMap } = opts || {};

      const remarksColumnsMap = silverayScheduleInboundColumns; // new Map(columnsMap);

      const [header, ...lines] = sourceTable;
      $D2.S.INFO('data', { header, lines });

      const actualHeader = header.map((column) => {
        const normalizedColumn = column.trim().toLowerCase();
        return remarksColumnsMap.has(normalizedColumn)
          ? {
              name: remarksColumnsMap.get(normalizedColumn),
              label: column,
              isRemark: false,
            }
          : { name: column, label: column, isRemark: true };
      });
      $D2.S.INFO('actualHeader', {
        silverayScheduleInboundColumns,
        actualHeader,
      });

      const sourceColumns = actualHeader
        .map((column, index) => ({
          kind: 'STRING',
          name: column.name,
          label: column.label,
          isRemark: column.isRemark,
          index,
          width: 'max-content',
        }))
        // .filter(x => x.name !== 'name' && x.name !== 'zip_code')
        .filter(x => x.isRemark);
      $D2.S.INFO('sourceColumns', { sourceColumns });

      const sourceEmptyRow = sourceColumns.map(() => '');

      const targetKeyFields = [
        targetTable.columns.findIndex(x => x.name === 'name'),
        targetTable.columns.findIndex(x => x.name === 'zip_code'),
      ];
      $D2.S.INFO('targetKeyFields', { targetKeyFields });

      const sourceKeyFields = [
        actualHeader.findIndex(x => x.name === 'name'),
        actualHeader.findIndex(x => x.name === 'zip_code'),
      ];
      $D2.S.INFO('sourceKeyFields', { sourceKeyFields });

      const sourceRowsIndex = lines.reduce((memo, line) => {
        const key = sourceKeyFields.map(index => line[index]).join(' ');
        return {
          ...memo,
          [key]: { newRow: sourceColumns.map(column => line[column.index]) },
        };
      }, {});
      $D2.S.INFO('sourceRowsIndex', { sourceRowsIndex });

      const newTargetColumns = [...targetTable.columns, ...sourceColumns];
      $D2.S.INFO('newTargetColumns', { newTargetColumns });

      const newTargetRows = targetTable.rows.map((targetRow) => {
        const key = targetKeyFields.map(index => targetRow[index]).join(' ');
        $D2.S.INFO('newTargetRows:key', { targetRow, targetKeyFields, key });
        const sourceRow = sourceRowsIndex[key]
          ? sourceRowsIndex[key].newRow
          : sourceEmptyRow;
        return [...targetRow, ...sourceRow];
      });
      $D2.S.INFO('newTargetRows', { newTargetRows });

      return { columns: newTargetColumns, rows: newTargetRows };
    }
  );

const PassengersTableViewer = (props) => {
  D2.S.INFO('PassengersTableViewer', props);

  const { t } = useTranslation();
  const tt = (msg, ...opts) => t(`c.ModalWindows.TableViewer.${msg}`, ...opts);

  const [isOpen, setIsOpen] = useState(false);
  const [tableContent, setTableContent] = useState(null);
  const [windowTitle, setWindowTitle] = useState(t('c.messages.Info'));
  const [exportFileName, setExportFileName] = useState();

  React.useEffect(() => {
    D2.S.INFO('Update', { props });

    global.openPassengersTableViewer = (content, opts) => {
      D2.S.INFO('openPassengersTableViewer', { content, opts });

      const {
        title = tt('Title.Default'),
        fileName = null,
        additionalData = [],
      } = opts || {};

      const { columns, rows } = content;

      if (
        !columns ||
        !rows ||
        (rows && Array.isArray(rows) && rows.length === 0)
      ) {
        global.openInfoMessage(tt('Error.NothingToShow'), {
          title: windowTitle,
        });
      }

      const newContent = additionalData.reduce((memo, data) => {
        return importRemarksTable(memo, data);
      }, content);

      setWindowTitle(title);
      setExportFileName(fileName);
      setTableContent(newContent);
      setIsOpen(true);
    };

    global.closePassengersTableViewer = () => {
      D2.S.INFO('closePassengersTableViewer', { isOpen });
      setIsOpen(false);
    };

    return () => {
      D2.S.INFO('Cleanup', { isOpen });
      global.closePassengersTableViewer = null;
    };
  });

  const onClose = () => {
    D2.S.INFO('onClose', { isOpen });

    setIsOpen(false);
  };

  const onExport = () => {
    D2.S.INFO('onExport', { isOpen });

    const csvHeader = [tableContent.columns.map(column => column.name)];
    const csvData = [...csvHeader, ...tableContent.rows];
    D2.S.INFO('onExport:csvData', { csvData });

    const csv = new CSV(csvData);
    const csvBody = csv.encode();

    exportFile(csvBody, exportFileName);
  };

  const onImportRemarks = () =>
    D2.S.FUNCTION('onImportRemarks', { isOpen }, ({ $D2 }) => {
      global.R();
      global.openFileUploaderDialog({
        title: tt('Title.ImportRemarksFile'),
        onDrop: async ({ acceptedFiles, rejectedFiles }) => {
          $D2.S.INFO('onDrop', { acceptedFiles, rejectedFiles });

          const csvResults = await Promise.all(
            acceptedFiles.map(acceptedFile => readCsvFileAsync(acceptedFile))
          );
          $D2.S.INFO('csvResults', { csvResults });

          const newContent = csvResults.reduce((memo, data) => {
            return importRemarksTable(memo, data);
          }, tableContent);
          $D2.S.INFO('newContent', { newContent });

          setTableContent(newContent);
        },
      });
    });

  return (
    <React.Fragment>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        overrides={{
          Dialog: {
            style: {
              width: '90vw',
              height: '90vh',
              display: 'flex',
              flexDirection: 'column',
            },
          },
        }}
      >
        <ModalHeader>{windowTitle}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <Table content={tableContent} />
        </ModalBody>
        <ModalFooter>
          <ModalButton onClick={onImportRemarks}>
            {tt('Footer.ImportRemarks')}
          </ModalButton>
          {exportFileName && (
            <ModalButton onClick={onExport}>{tt('Footer.Export')}</ModalButton>
          )}
          <ModalButton onClick={onClose}>{tt('Footer.Close')}</ModalButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default PassengersTableViewer;

import React from 'utils/react';
import withTranslation from 'utils/react/withTranslation';
import { withRouter } from 'react-router-dom';
import mapbox from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import MapGL from '@urbica/react-map-gl';
import LanguageControl from 'components/Map/LanguageControl';

import { Formik } from 'formik';

import Input from 'components/Input';
import SubmitButton from 'components/SubmitButton';
import InputSelectTags from 'components/InputSelectTags';

import Row from '../Row';
import ButtonsRow from '../ButtonsRow';
import MapWrap from './MapWrap';

const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1Ijoic3dhdG1hcCIsImEiOiJjam1ocG1pdG8yZmF0M3FsaG9jcjBvOG80In0.QYTtfAhYukUHVGS73_1U6w'; // eslint-disable-line

const initialValues = {
  name: '',
  description: '',
  tags: [],
};

// Refactoring

class Draw extends React.PureComponent {
  state = {
    ...initialValues,
  };

  componentDidMount() {
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
    });

    this.map = this.mapRef.current.getMap();
    this.map.addControl(new mapbox.FullscreenControl());
    this.map.addControl(this.draw);
  }

  componentWillUnmount() {
    this.map.removeControl(this.draw);
  }

  onSubmit = (values) => {
    const geometry = this.draw.getAll().features[0].geometry;
    if (!geometry) {
      // no geometry
      return null;
    }

    const { tags, ...otherValues } = values;

    const body = JSON.stringify({
      ...otherValues,
      tags: tags.map(item => item.value),
      geometry,
      creation_type: 'M',
      project: this.props.projectUrl,
    });

    this.props.addGeofence(body);

    return null;
  };

  mapRef = React.createRef();

  render() {
    const { t } = this.props;
    const tt = (msg, ...opts) =>
      t(`c.ModalWindow.NewGeofence.Draw.${msg}`, ...opts);

    return (
      <React.Fragment>
        <Formik initialValues={initialValues} onSubmit={this.onSubmit}>
          {(props) => {
            const {
              values,
              handleChange,
              handleSubmit,
              handleBlur,
              setFieldValue,
              setFieldTouched,
            } = props;

            return (
              <form onSubmit={handleSubmit} autoComplete='off'>
                <Row>
                  <Input
                    name='name'
                    type='text'
                    label={tt('Form.Name')}
                    value={values.name}
                    onChange={handleChange}
                    handleBlur={handleBlur}
                    autoComplete='off'
                    required
                  />
                </Row>
                <Row>
                  <Input
                    name='description'
                    type='text'
                    label={tt('Form.Description')}
                    value={values.description}
                    onChange={handleChange}
                  />
                  <InputSelectTags
                    name='tags'
                    label={tt('Form.Tags')}
                    isMulti
                    value={values.tags}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </Row>
                <MapWrap>
                  <MapGL
                    ref={this.mapRef}
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    mapStyle='mapbox://styles/mapbox/light-v9'
                    accessToken={MAPBOX_ACCESS_TOKEN}
                    {...this.props.viewport}
                    onViewportChange={this.props.onViewportChange}
                  >
                    <LanguageControl />
                  </MapGL>
                </MapWrap>
                <ButtonsRow>
                  <SubmitButton type='submit'>
                    {t('c.ModalWindow.NewGeofence.Create')}
                  </SubmitButton>
                </ButtonsRow>
              </form>
            );
          }}
        </Formik>
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(Draw));

import styled from 'styled-components';

const Container = styled.div`
  display: inline-block;
  border: 1px solid #58616d;
  border-radius: 5px;
  padding: 2px 10px;
  cursor: pointer;
  margin-right: 5px;

  &:hover {
    background-color: #484e5d;
  }
`;

export default Container;

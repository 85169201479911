// @flow

import * as React from 'react';
import { debounce } from 'debounce';

import Form from './Form';
import Input from './Input';
import SearchResults from './SearchResults';

type Props = {
  changeSearchQuery: Function,
  vehicles: Array<Vehicle>,
  setActiveVehicleId: Function,
  bookings: Array<Booking>,
  setActiveBookingId: Function,
  flyToSimulationActiveBooking: Function,
  flyToSimulationVehicle: Function,
};

const Search = (props: Props) => {
  const {
    changeSearchQuery,
    vehicles,
    setActiveVehicleId,
    bookings,
    setActiveBookingId,
    flyToSimulationActiveBooking,
    flyToSimulationVehicle,
  } = props;

  const delayedChangeSearchQuery = debounce(changeSearchQuery, 150);

  const onChange = (event) => {
    event.persist();
    delayedChangeSearchQuery(event.target.value.toLowerCase());
  };

  return (
    <Form>
      <Input
        type='text'
        onChange={onChange}
        placeholder='Search bookings or vehicles'
      />
      {/* TODO: rewrite this render */}
      {((vehicles && vehicles.length > 0) ||
        (bookings && bookings.length > 0)) && (
        <SearchResults
          vehicles={vehicles || []}
          bookings={bookings || []}
          setActiveVehicleId={setActiveVehicleId}
          setActiveBookingId={setActiveBookingId}
          flyToSimulationActiveBooking={flyToSimulationActiveBooking}
          flyToSimulationVehicle={flyToSimulationVehicle}
        />
      )}
    </Form>
  );
};

export default React.memo<Props>(Search);

import React from 'utils/react';

import Menu from 'components/Menu';

import Container from './Container';
import Button from './Button';

const MenuBottom = ({ playSimulation, exportSimulation, deleteSimulation }) => (
  <Container>
    <Menu>
      <Button onClick={playSimulation}>Play</Button>
      <Button onClick={exportSimulation}>Export</Button>
      <Button onClick={deleteSimulation}>Delete</Button>
    </Menu>
  </Container>
);

export default MenuBottom;

import React from 'utils/react';
import { useStyletron } from 'baseui';
import styled from 'styled-components';

const Tag = styled.div`
  color: ${({ color }) => color};
  background-color: ${({ color }) => `${color}1a`};
  border-radius: 999px;
  padding: 2px 8px;
  display: inline-block;
  font-weight: 600;
`;

const Tag2 = (props) => {
  const { kind, ...restProps } = props;
  const theme = useStyletron()[1];
  const color = {
    success: theme.colors.positive,
    danger: theme.colors.negative,
    info: theme.colors.accent,
    warning: theme.colors.warning,
  }[kind || 'info'];

  return <Tag color={color} {...restProps} />;
};

export default Tag2;

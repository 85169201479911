import { transitstopByCode } from 'api/masstransit';

import { sync } from 'utils/async';

import debug from 'utils/debug';

const D2 = debug('u:CommuteOffer:Validation:Validators');

const normalizeFloat = n => parseFloat(parseFloat(n).toFixed(10));
const normalizeLocation = (lon, lat) =>
  `${normalizeFloat(lon)};${normalizeFloat(lat)}`;

const handrodedTransitstopReplacements = {
  'https://sgerp-stage.d.gcdev.swatrider.com': {
    [normalizeLocation(103.639707, 1.299069)]: {
      stop_id: 1186213,
      lon: 103.639519,
      lat: 1.298644,
      name: 'Lonza Biologics #Lonza Biologics',
      code: 'Lonza Biologics',
    },
    [normalizeLocation(103.930021994, 1.32421391900004)]: {
      stop_id: 2248,
      lat: 1.32421391900004,
      lon: 103.930021994,
      name: 'Bedok Stn Exit B #84031',
      code: '84031',
    },
  },
  'https://sgerp.d.gcprod.swatrider.com': {
    [normalizeLocation(103.639707, 1.299069)]: {
      stop_id: 1357050,
      lon: 103.639519,
      lat: 1.298644,
      name: 'Lonza Biologics #Lonza Biologics',
      code: 'Lonza Biologics',
    },
    [normalizeLocation(103.930021994, 1.32421391900004)]: {
      stop_id: 2248,
      lat: 1.32421391900004,
      lon: 103.930021994,
      name: 'Bedok Stn Exit B #84031',
      code: '84031',
    },
  },
};

D2.S.INFO('handrodedTransitstopReplacements', {
  handrodedTransitstopReplacements,
});

const isValidInteger = id => !Number.isNaN(parseInt(id, 10));

const isValidId = global.GEODISC_COMMUTE_OFFER_STOPID_VALIDATION_DISABLED
  ? () => true
  : (id, h3) => h3 || isValidInteger(id);

// const isValidId = (id, h3) =>
//   h3 || isValidInteger(id) || (typeof id === 'string' && id !== '');

export const validator = async commuteOffer =>
  D2.A.FUNCTION('0010:TransitStops', { commuteOffer }, async ({ $D2 }) => {
    if (global.COMMUTE_OFFER_STOPID_VALIDATION_DISABLED) {
      return commuteOffer;
    }

    const { stateless_api_request_data, result } = commuteOffer;

    const { nodes } = stateless_api_request_data;

    const replacements = [];

    const testsRequestNodes = nodes.reduce(
      (acc, node) => {
        $D2.S.INFO('nodes.reduce:node', { node });

        const { h3, stop_id, lon, lat } = node;

        if (!isValidId(stop_id, h3)) {
          const replacement =
            handrodedTransitstopReplacements[global.GEODISC_API_URL][
              normalizeLocation(lon, lat)
            ];
          if (replacement) {
            replacements[stop_id] = replacement;
            return {
              ...acc,
              replacedIds: { ...acc.replacedIds, [stop_id]: replacement },
              $fixes: [
                ...acc.$fixes,
                { type: 'node.stop_id.InvalidType', node, stop_id },
              ],
              nodes: [
                ...acc.nodes,
                {
                  ...node,
                  stop_id: replacement.stop_id,
                  lon: replacement.lon,
                  lat: replacement.lat,
                  $fixed: true,
                },
              ],
            };
          }
          return {
            ...acc,
            $errors: [
              ...acc.$errors,
              { type: 'node.stop_id.InvalidType', node, stop_id },
            ],
            nodes: [...acc.nodes, node],
          };
        }

        return { ...acc, nodes: [...acc.nodes, node] };
      },
      { $errors: [], $warnings: [], $fixes: [], replacedIds: {}, nodes: [] }
    );
    $D2.S.INFO('testsRequestNodes', { testsRequestNodes, nodes });

    const testsResultVehicles1 = Object.keys(result.vehicles).reduce(
      (acc, agent_id) => {
        const vehicle = result.vehicles[agent_id] ?? [];
        $D2.S.INFO('result.vehicles.reduce:vehicle', { vehicle });
        const vehicle__nodes = vehicle.reduce(
          (nacc, node) => {
            const isValidNodeId = isValidId(node.stop_id, node.h3);
            $D2.S.INFO('result.vehicles.reduce:isValidNodeId', {
              isValidNodeId,
              node_type: node.node_type,
              node,
              vehicle,
            });
            if (node.node_type !== 'point' && !isValidNodeId) {
              const replacedStop = testsRequestNodes.replacedIds[node.stop_id];
              $D2.S.INFO('result.vehicles.reduce:replacedStop', {
                replacedStop,
                node,
                vehicle,
              });
              const newNode = replacedStop
                ? {
                    ...node,
                    stop_id: replacedStop.stop_id,
                    lon: replacedStop.lon,
                    lat: replacedStop.lat,
                    location_name: replacedStop.name,
                    location_code: replacedStop.code,
                    $fixed: true,
                  }
                : node;
              if (!isValidId(newNode.stop_id, newNode.h3)) {
                const replacement =
                  handrodedTransitstopReplacements[global.GEODISC_API_URL][
                    normalizeLocation(newNode.lon, newNode.lat)
                  ];
                if (replacement) {
                  return {
                    ...nacc,
                    $fixes: [
                      ...nacc.$fixes,
                      {
                        type: 'result.vehicles.stop_id.InvalidType',
                        node,
                        agent_id,
                      },
                    ],
                    nodes: [
                      ...nacc.nodes,
                      {
                        ...newNode,
                        stop_id: replacement.stop_id,
                        lon: replacement.lon,
                        lat: replacement.lat,
                        location_name: replacement.name,
                        location_code: replacement.code,
                        $fixed: true,
                      },
                    ],
                  };
                }
                const $fixes = newNode.$fixed
                  ? [
                      ...nacc.$fixes,
                      {
                        type: 'result.vehicles.stop_id.InvalidType',
                        node,
                        agent_id,
                      },
                    ]
                  : nacc.$fixes;
                return {
                  ...nacc,
                  $fixes,
                  nodes: [...nacc.nodes, newNode],
                  requiredLocationCodes: {
                    ...nacc.requiredLocationCodes,
                    [newNode.location_code]: 42,
                  },
                };
              }
              return {
                ...nacc,
                nodes: [...nacc.nodes, newNode],
              };
            }
            return { ...nacc, nodes: [...nacc.nodes, node] };
          },
          {
            $errors: [],
            $warnings: [],
            $fixes: [],
            nodes: [],
            requiredLocationCodes: {},
          }
        );
        return {
          ...acc,
          $errors: [...acc.$errors, ...vehicle__nodes.$errors],
          $warnings: [...acc.$warnings, ...vehicle__nodes.$warnings],
          $fixes: [...acc.$fixes, ...vehicle__nodes.$fixes],
          vehicles: { ...acc.vehicles, [agent_id]: vehicle__nodes.nodes },
          requiredLocationCodes: {
            ...acc.requiredLocationCodes,
            ...vehicle__nodes.requiredLocationCodes,
          },
        };
      },
      {
        $errors: [],
        $warnings: [],
        $fixes: [],
        vehicles: {},
        requiredLocationCodes: {},
      }
    );
    $D2.S.INFO('testsResultVehicles1', { testsResultVehicles1 });

    const transitstops = await Promise.all(
      Object.keys(testsResultVehicles1.requiredLocationCodes).map(
        locationCode => transitstopByCode(locationCode)
      )
    );
    $D2.S.INFO('transitstops', { transitstops });

    const testsResultVehicles2 = Object.keys(
      testsResultVehicles1.vehicles
    ).reduce(
      (acc, agent_id) => {
        const vehicle = testsResultVehicles1.vehicles[agent_id];
        $D2.S.INFO('testsResultVehicles1.reduce:vehicle', { acc, vehicle });
        const vehicle__nodes = vehicle.reduce(
          (nacc, node) => {
            $D2.S.INFO('testsResultVehicles1.reduce:vehicle.reduce:node', {
              nacc,
              node,
            });
            const assignedBooking = {
              uid: node.booking_uid,
              assigned_vehicle_id: agent_id,
            };
            if (node.node_type === 'point') {
              return {
                ...nacc,
                nodes: [...nacc.nodes, node],
              };
            }
            const isValidNodeId = isValidId(node.stop_id, node.h3);
            $D2.S.INFO(
              'testsResultVehicles1.reduce:vehicle.reduce:isValidNodeId',
              {
                isValidNodeId,
                nacc,
                node,
              }
            );
            if (!isValidNodeId) {
              const transitstopResult = transitstops.find(
                x => x.code === node.location_code
              );
              const transitstop =
                transitstopResult &&
                transitstopResult.result &&
                transitstopResult.result.objects &&
                transitstopResult.result.objects.length
                  ? transitstopResult.result.objects[0]
                  : undefined;
              const newNode = transitstop
                ? {
                    ...node,
                    stop_id: transitstop.id,
                    lon: transitstop.lon,
                    lat: transitstop.lat,
                    location_name: transitstop.display_name,
                    location_code: transitstop.code,
                    $fixed: true,
                  }
                : node;

              if (!isValidId(newNode.stop_id, newNode.h3)) {
                $D2.S.INFO(
                  'testsResultVehicles1.reduce:vehicle.reduce:node:1',
                  {
                    nacc,
                    node,
                  }
                );
                return {
                  ...nacc,
                  $errors: [
                    ...nacc.$errors,
                    {
                      type: 'result.vehicles.stop_id.InvalidType',
                      node,
                      agent_id,
                    },
                  ],
                  nodes: [...nacc.nodes, newNode],
                  assigned_bookings: {
                    ...nacc.assigned_bookings,
                    [node.booking_uid]: assignedBooking,
                  },
                };
              }

              const $fixes = newNode.$fixed
                ? [
                    ...nacc.$fixes,
                    {
                      type: 'result.vehicles.stop_id.InvalidType',
                      node,
                      agent_id,
                    },
                  ]
                : nacc.$fixes;

              $D2.S.INFO('testsResultVehicles1.reduce:vehicle.reduce:node:2', {
                nacc,
                node,
              });
              return {
                ...nacc,
                $fixes,
                nodes: [...nacc.nodes, newNode],
                assigned_bookings: {
                  ...nacc.assigned_bookings,
                  [newNode.booking_uid]: assignedBooking,
                },
              };
            }
            $D2.S.INFO('testsResultVehicles1.reduce:vehicle.reduce:node:3', {
              nacc,
              node,
            });
            return {
              ...nacc,
              nodes: [...nacc.nodes, node],
              assigned_bookings: {
                ...nacc.assigned_bookings,
                [node.booking_uid]: assignedBooking,
              },
            };
          },
          {
            $errors: [],
            $warnings: [],
            $fixes: [],
            nodes: [],
            assigned_bookings: {},
          }
        );
        return {
          ...acc,
          $errors: [...acc.$errors, ...vehicle__nodes.$errors],
          $warnings: [...acc.$warnings, ...vehicle__nodes.$warnings],
          $fixes: [...acc.$fixes, ...vehicle__nodes.$fixes],
          vehicles: { ...acc.vehicles, [agent_id]: vehicle__nodes.nodes },
          assigned_bookings: [
            ...acc.assigned_bookings,
            ...Object.values(vehicle__nodes.assigned_bookings),
          ],
        };
      },
      {
        $errors: [],
        $warnings: [],
        $fixes: [],
        vehicles: {},
        assigned_bookings: [],
      }
    );
    $D2.S.INFO('testsResultVehicles2', { testsResultVehicles2 });

    const testsAssignedBookings = testsResultVehicles2.assigned_bookings.reduce(
      (acc, assignedBookingsRecord) => {
        const vehiclePath =
          testsResultVehicles2.vehicles[
            assignedBookingsRecord.assigned_vehicle_id
          ];

        const pickupNode = vehiclePath.find(
          x =>
            x.booking_uid === assignedBookingsRecord.uid &&
            x.node_type === 'pickup'
        );
        const pickupRecordPart = pickupNode
          ? {
              scheduled_pickup_stop_id: pickupNode.stop_id,
              scheduled_pickup_time: pickupNode.scheduled_ts,
            }
          : {};

        const dropoffNode = vehiclePath.find(
          x =>
            x.booking_uid === assignedBookingsRecord.uid &&
            x.node_type === 'dropoff'
        );
        const dropoffRecordPart = dropoffNode
          ? {
              scheduled_dropoff_stop_id: dropoffNode.stop_id,
              scheduled_dropoff_time: dropoffNode.scheduled_ts,
            }
          : {};

        const record = {
          ...assignedBookingsRecord,
          ...pickupRecordPart,
          ...dropoffRecordPart,
        };

        // if (
        //   !isValidId(record.scheduled_pickup_stop_id) ||
        //   !isValidId(record.scheduled_dropoff_stop_id)
        // ) {
        //   const replacedPickupStop =
        //     testsRequestNodes.replacedIds[record.scheduled_pickup_stop_id];
        //   const replacedDropoffStop =
        //     testsRequestNodes.replacedIds[record.scheduled_dropoff_stop_id];
        //   const newRecord = {
        //     ...record,
        //     scheduled_pickup_stop_id: replacedPickupStop
        //       ? replacedPickupStop.stop_id
        //       : record.scheduled_pickup_stop_id,
        //     scheduled_dropoff_stop_id: replacedDropoffStop
        //       ? replacedDropoffStop.stop_id
        //       : record.scheduled_dropoff_stop_id,
        //   };
        //   if (
        //     !isValidId(newRecord.scheduled_pickup_stop_id) ||
        //     !isValidId(newRecord.scheduled_dropoff_stop_id)
        //   ) {
        //     return {
        //       ...acc,
        //       $errors: [
        //         ...acc.$errors,
        //         // {
        //         //   type: 'result.assigned_bookings.stop_id.InvalidType',
        //         //   record
        //         // }
        //       ],
        //     };
        //   }
        //   return {
        //     ...acc,
        //     assigned_bookings: [...acc.assigned_bookings, newRecord],
        //   };
        // }

        return {
          ...acc,
          assigned_bookings: [...acc.assigned_bookings, record],
        };
      },
      { $errors: [], $warnings: [], $fixes: [], assigned_bookings: [] }
    );
    $D2.S.INFO('testsAssignedBookings', { testsAssignedBookings });

    await sync();
    return {
      ...commuteOffer,
      stateless_api_request_data: {
        ...commuteOffer.stateless_api_request_data,
        nodes: testsRequestNodes.nodes,
      },
      result: {
        ...commuteOffer.result,
        assigned_bookings: testsAssignedBookings.assigned_bookings,
        vehicles: testsResultVehicles2.vehicles,
        $errors: [
          ...commuteOffer.result.$errors,
          ...testsRequestNodes.$errors,
          ...testsAssignedBookings.$errors,
          ...testsResultVehicles2.$errors,
        ],
        $warnings: [
          ...commuteOffer.result.$warnings,
          ...testsRequestNodes.$warnings,
          ...testsAssignedBookings.$warnings,
          ...testsResultVehicles2.$warnings,
        ],
        $fixes: [
          ...commuteOffer.result.$fixes,
          ...testsRequestNodes.$fixes,
          ...testsAssignedBookings.$fixes,
          ...testsResultVehicles2.$fixes,
        ],
      },
    };
  });

export default validator;

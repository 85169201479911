import styled from 'styled-components';
import play from 'assets/Play.svg';
import pause from 'assets/Pause.svg';

const Container = styled.div`
  width: 30px;
  height: 30px;
  padding: 20px 20px 10px;
  background: url(${({ isRunning }) => (isRunning ? pause : play)}) no-repeat
    center;
  margin-top: 1px;

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  pointer-events: ${({ isDisable }) => (isDisable ? 'none' : 'auto')};
`;

export default Container;

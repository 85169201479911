import React from 'utils/react';
import useTranslation from 'utils/react/useTranslation';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';

import { diff as DiffEditor } from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';

import ReactDiffViewer from 'react-diff-viewer';
import debug from 'utils/debug';
import Overflow from './Overflow';

const D2 = debug('c:ModalWindows:DiffViewer');

export default (props) => {
  D2.S.INFO('DiffViewer', props);

  const { t } = useTranslation();
  const tt = (msg, ...opts) => t(`c.ModalWindow.DiffViewer.${msg}`, ...opts);

  const [isOpen, setIsOpen] = React.useState(false);
  const [content, setContent] = React.useState(['', '']);
  const [title, setTitle] = React.useState(tt('Title.Default'));
  const [config, setConfig] = React.useState({});

  React.useEffect(() => {
    D2.S.INFO('Update', { props });
    window.openDiffEditor = (src, dst, opts) => {
      D2.S.INFO('openDiffEditor', { src, dst, opts });
      const options = opts || {};

      const $src = typeof src === 'string' ? src : JSON.stringify(src, '\n', 4);
      const $dst = typeof dst === 'string' ? dst : JSON.stringify(dst, '\n', 4);
      const sources = [$src, $dst];
      D2.S.INFO('sources', { sources });

      if (options.title) {
        // setTitle(t(options.title));
        setTitle(tt('Title.Default'));
      }

      setContent(sources);
      setConfig(options);
      setIsOpen(true);
    };
    window.closeDiffEditor = () => {
      D2.S.INFO('closeDiffEditor', {
        isOpen,
        title,
        content,
        config,
      });
      setIsOpen(false);
    };
    return () => {
      D2.S.INFO('Cleanup');
      window.closeDiffEditor = null;
    };
  });

  function onClose() {
    D2.S.INFO('onClonse', { isOpen, title, content, config });

    setIsOpen(false);
  }

  function onSave() {
    D2.S.INFO('onSave', { isOpen, title, content, config });

    if (config.onSave) {
      setConfig({});
      config.onSave(content);
    }

    setIsOpen(false);
  }

  return (
    <React.Fragment>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        overrides={{
          Dialog: {
            style: {
              width: '90vw',
              display: 'flex',
              flexDirection: 'column',
            },
          },
        }}
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          {false && (
            <DiffEditor
              mode='json'
              theme='github'
              value={content}
              defaultValue={content}
              onChange={setContent}
              name='ace'
              width='100%'
              height='100%'
              setOptions={{
                useWorker: false,
              }}
              editorProps={{ $blockScrolling: true }}
              readOnly
            />
          )}
          <Overflow>
            <ReactDiffViewer
              oldValue={content[0]}
              newValue={content[1]}
              splitView
            />
          </Overflow>
        </ModalBody>
        <ModalFooter>
          <ModalButton onClick={onClose}>{tt('Footer.Close')}</ModalButton>
          {false && (
            <ModalButton onClick={onSave}>{tt('Footer.Save')}</ModalButton>
          )}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 10px 10px 15px;
  border-bottom: solid 2px rgba(197, 206, 222, 0.2);
  flex-shrink: ${({ flexShrink }) => flexShrink || 0};
  overflow: hidden;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
`;

export default Container;

import styled from 'styled-components';

const Container = styled.button`
  line-height: 44px;
  text-align: center;
  cursor: pointer;
  border-radius: 50px;
  background-color: transparent;
  color: #7a818f;
  font-size: 20px;
  font-weight: bold;
  padding: 0 20px;
  margin: auto;
  border: solid 1px #7a818f;

  &:hover {
    background-color: #e8e1e8;
  }

  &[type='submit'] {
    -webkit-appearance: none;
  }
`;

export default Container;

import React from 'utils/react';
import { fromJS } from 'immutable';
import MapGL, { Layer, Source } from '@urbica/react-map-gl';
import LanguageControl from 'components/Map/LanguageControl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import geoViewport from '@mapbox/geo-viewport';
import turfBbox from '@turf/bbox';

import { map } from 'config';
import { getLayers } from 'utils/geofences';

import Container from './Container';

const style = { position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 };

class Map extends React.Component {
  componentDidMount() {
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        trash: false,
      },
    });

    this.map = this.mapRef.current.getMap();
    this.map.addControl(this.draw);
    this.map.on('draw.create', this.updateGeom);
    this.map.on('draw.delete', this.updateGeom);
    this.map.on('draw.update', this.updateGeom);
  }

  componentDidUpdate(prevProps) {
    const { editableGeofence } = this.props;
    const geometry = editableGeofence && editableGeofence.geometry;

    if (editableGeofence) {
      const { id } = editableGeofence;
      const prevId =
        prevProps.editableGeofence && prevProps.editableGeofence.id;

      if (id !== prevId) {
        this.updateDraw(geometry);
        this.zoomToGeofence(geometry);
      }
    }

    if (!editableGeofence && prevProps.editableGeofence) {
      this.draw.deleteAll();
    }
  }

  componentWillUnmount() {
    this.map.removeControl(this.draw);
  }

  updateDraw = (geometry) => {
    this.draw.deleteAll();

    const featureIds = this.draw.add(geometry);
    this.draw.changeMode('direct_select', { featureId: featureIds[0] });
  };

  updateGeom = (e) => {
    const { updateEditableGeofence } = this.props;
    updateEditableGeofence('geometry', fromJS(e.features[0].geometry));
  };

  zoomToGeofence = (geometry) => {
    const bbox = turfBbox(geometry);
    const { center, zoom } = geoViewport.viewport(bbox, [300, 200]);
    const [longitude, latitude] = center;

    const { viewport } = this.props;
    viewport.zoom = zoom;
    viewport.latitude = latitude;
    viewport.longitude = longitude;

    this.props.onViewportChange(viewport);
  };

  mapRef = React.createRef();

  render() {
    const { source, hoverId, viewport } = this.props;
    const layers = getLayers(hoverId);

    return (
      <Container>
        <MapGL
          ref={this.mapRef}
          style={style}
          mapStyle={map.mapStyle}
          accessToken={map.token}
          onViewportChange={this.props.onViewportChange}
          viewportChangeMethod='flyTo'
          {...viewport}
        >
          <LanguageControl />
          {source && [
            <Source key='source' id='geofences' type='geojson' data={source} />,
            ...layers.map(layer => <Layer key={layer.id} {...layer} />),
          ]}
        </MapGL>
      </Container>
    );
  }
}

export default Map;

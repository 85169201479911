import { styled } from 'baseui';

const DisabledWrapper = styled('div', {
  opacity: '0.6',
  cursor: 'not-allowed !important',
});
const Button = styled('div', ({ $theme, $divider, disabled }) => {
  const isDivider = $divider && {
    borderBottom: '1px solid ' + $theme.colors.buttonTextDisabled,
  };
  const isDisabled = disabled && {
    pointerEvents: 'none',
  };
  return {
    cursor: 'pointer',
    padding: '16px 20px',
    fontSize: '14px',
    fontWeight: 500,
    color: $theme.colors.buttonTextDisabled,
    ...$theme.typography.panelTitle500,
    ...isDivider,
    ...isDisabled,
    ':hover': {
      color: 'white',
    },
  };
});
const Container = props =>
  props.disabled ? (
    <DisabledWrapper>
      <Button {...props} />
    </DisabledWrapper>
  ) : (
    <Button {...props} />
  );

export default Container;

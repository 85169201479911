import fetchObject from './fetchObject';
import fetchCollection from './fetchCollection';
import loadCollection from './loadCollection';

const functions = {
  fetchObject,
  fetchCollection,
  loadCollection,
};

export default functions;

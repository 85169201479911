import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import createImmutableSelector from 'utils/immutable-selector';

import { updateSimulation } from 'modules/simulations/actions';
import { toggleSelectedSimulations } from 'modules/ui/actions';

import { listSelector } from 'modules/simulations/selectors';
import {
  selectedSimulationIdsSelector,
  simulationsErrorSelector,
} from 'modules/ui/selectors';

import Body from './Body';

const mapStateToProps = createImmutableSelector(
  listSelector,
  selectedSimulationIdsSelector,
  simulationsErrorSelector,
  (list, selectedIds, error) => ({
    list,
    selectedIds,
    error,
  })
);

const mapDispatchToProps = {
  toggleSelectedSimulations,
  updateSimulation,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const enhance = compose(withConnect, withRouter);

export default enhance(Body);

import {
  FLEET_START_ADD_VEHICLE_REQUEST,
  SET_FLEET_DATA_REQUEST,
  FLEET_START_UPDATE_VEHICLE_REQUEST,
  FLEET_START_DELETE_VEHICLE_REQUEST,
  START_ADD_VEHICLE_TYPE_REQUEST,
  SET_VEHICLE_TYPES_REQUEST,
  START_UPDATE_VEHICLE_TYPE_REQUEST,
} from './types';

export function setFleetData(vehicles) {
  return {
    type: SET_FLEET_DATA_REQUEST,
    payload: vehicles,
  };
}

export const fleetAddVehicle = ({ data, values, startPoint, endPoint }) => {
  return {
    type: FLEET_START_ADD_VEHICLE_REQUEST,
    payload: { data, values, startPoint, endPoint },
  };
};

export const updateVehicle = ({ data, values, startPoint, endPoint }) => {
  return {
    type: FLEET_START_UPDATE_VEHICLE_REQUEST,
    payload: { data, values, startPoint, endPoint },
  };
};

export const deleteVehicle = ({ vehicle, commuteOffer }) => {
  return {
    type: FLEET_START_DELETE_VEHICLE_REQUEST,
    payload: { vehicle, commuteOffer },
  };
};

export function setVehicleTypes(types, modified_at) {
  return {
    type: SET_VEHICLE_TYPES_REQUEST,
    payload: { vehicleTypes: types, modified_at: modified_at },
  };
}

export const addVehicleType = ({ values, data }) => {
  return {
    type: START_ADD_VEHICLE_TYPE_REQUEST,
    payload: { values, data },
  };
};

export const updateVehicleType = (payload) => {
  return {
    type: START_UPDATE_VEHICLE_TYPE_REQUEST,
    // payload: { data, deleteId, updateId, vehicleType },
    payload,
  };
};

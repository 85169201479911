import React, { useEffect, useState } from 'react';
import DataTable from 'components/DataTable';
import ModalHeader from 'components/ModalHeader';
import {
  ContainerWrapper,
  Container,
} from 'components/AdvancedSettings/styles';
import moment from 'moment-timezone';
import useTranslation from 'utils/react/useTranslation';
import { TEMPLATE_ROUTE } from '../../utils/constants';
import { getVehicleOnlineStatus } from 'utils/CommuteOffer';
import { useHistory } from 'react-router-dom';
import { pageTitles } from 'components/MasterSettings/MasterSettings';

const Fleet = (props) => {
  const {
    vehicles,
    deleteVehicle,
    isReadOnly,
    commuteOffer,
    title,
    isSuperUser,
    drivers,
  } = props;

  const browserhistory = useHistory();

  const pageURL = window.location.href;
  const isTemplate = pageURL.includes(TEMPLATE_ROUTE);
  const timezone = global.GEODISC_TIMEZONE;
  const [vehiclesData, setVehiclesData] = useState([]);
  const { t } = useTranslation();
  const updateVehicleData = (data, origin) => {
    // block out any unknown call
    if (origin !== undefined) {
      setVehiclesData(data);
    }
  };

  useEffect(() => {
    const backListener = browserhistory.listen((location, action) => {
      if (action === 'POP') {
        const params = new URLSearchParams(window.location.search);
        if (params.get('page') === null) {
          // when user click browser back
          global.closeFullScreen();
        } else if (params.get('page') === pageTitles.fleet) {
          global.closeFullScreen();
        }
      }
    });

    return () => {
      backListener();
    };
  }, []);

  const goBackBrowserHistory = () => {
    browserhistory.goBack();
  };

  useEffect(() => {
    const filteredVehicles = () => {
      return vehicles?.map((vehicle) => {
        const { isOnline } = getVehicleOnlineStatus(vehicle);
        // Only these fields pass into the vehicle editor modal when user edit clone
        return {
          isReadOnly: vehicle?.$isReadOnly,
          name: vehicle?.service_number,
          typeLabel:
            vehicle?.routing_engine?.vehicle_model ||
            vehicle?.routing_engine_settings?.vehicle_model,
          //these values shows on the fleet table
          startTime: vehicle?.start_time
            ? moment(vehicle?.start_time).tz(timezone).format('HH:mm')
            : '-',
          endTime: vehicle?.end_time
            ? moment(vehicle?.end_time).tz(timezone).format('HH:mm')
            : '-',
          //these values use in vehicle editor form
          startTimeStamp: vehicle?.start_time,
          endTimeStamp: vehicle?.end_time,
          geofences: vehicle.geofence_ids,
          selected: false,
          lat: vehicle?.lat,
          lon: vehicle?.lon,
          id: vehicle?.id,
          agent_id: vehicle?.agent_id,
          route: vehicle?.route || [],
          driver: vehicle.driver,
          isOnline,
          isCompleted: vehicle?.$isOperatedVehicle,
          earliestBreakStartTime: vehicle?.dynamic_break_start_time,
          latestBreakEndTime: vehicle?.dynamic_break_end_time,
          breakDuration: vehicle?.dynamic_break_duration,
        };
      });
    };
    // setVehiclesData(filteredVehicles);
    updateVehicleData(filteredVehicles, 'useEffectVehicles');
  }, [vehicles, timezone]);
  const columnName = [
    {
      name: t('p.fleet.table.vehicleName'),
      key: 'name',
    },
    {
      name: t('p.fleet.table.vehicleType'),
      key: 'typeLabel',
    },
    {
      name: t('Driver'),
      key: 'driver',
      cell: (row) => {
        const driver = drivers?.find?.(
          driver => driver?.resource_uri === row?.driver
        );

        if (!driver) {
          return '-';
        }

        const { external_id, first_name, last_name } = driver;
        const fullName = `${first_name || ''} ${last_name || ''}`.trim();

        return `${external_id}${fullName ? ` (${fullName})` : ''}`;
      },
    },
    {
      name: t('p.fleet.table.startTime'),
      key: 'startTime',
    },
    {
      name: t('p.fleet.table.endTime'),
      key: 'endTime',
    },
  ];

  const getMenuItem = ({ selectedRow }) => {
    const items = [];

    if (
      (!selectedRow?.isReadOnly || isTemplate) &&
      !selectedRow?.isOnline &&
      !selectedRow?.isCompleted
    ) {
      items.push({ label: t('p.fleet.table.edit') });
    }

    if (!isReadOnly || isTemplate) {
      items.push({ label: t('p.fleet.table.clone') });
    }

    if (
      (!selectedRow?.isReadOnly || isTemplate) &&
      !selectedRow?.isOnline &&
      !selectedRow?.isCompleted
    ) {
      items.push({ label: t('p.fleet.table.remove') });
    }

    if (isSuperUser) {
      items.push({ label: t('p.Editor.Menu.Admin') });
    }
    return items;
  };

  const menuActions = ({ item, selectedRow, setData }) => {
    if (item?.label === t('p.fleet.table.remove')) {
      global.openWarningMessage({
        title: t('p.fleet.table.remove.warning.title'),
        message: t('p.fleet.table.remove.warning.message', {
          vehicle: selectedRow?.name,
        }),
        buttons: [
          {
            text: t('c.messages.Cancel'),
            fill: false,
          },
          {
            text: t('p.fleet.table.remove.warning.remove'),
            action: () => {
              global.closeWarningMessage();
              deleteVehicle({
                commuteOffer,
                vehicle: selectedRow,
              });
            },
            fill: true,
          },
        ],
      });
    } else if (item?.label === t('p.Editor.Menu.Admin')) {
      window.open(
        `${window.GEODISC_API_URL}/admin/simulation/vehicle/${selectedRow.id}/change/`,
        '_blank'
      );
    } else {
      global.openVehicleEditorV2({
        size: '648px',
        data: selectedRow,
        setData,
        isClone: item?.label === t('p.fleet.table.clone'),
      });
    }
  };

  const addAction = () => {
    global.openVehicleEditorV2({ size: '648px' });
  };

  const handleClose = () => {
    goBackBrowserHistory();
  };

  const bulkRemoveAction = () => {
    const vehiclesToRemove = [...vehiclesData];
    const newVehicles = vehiclesToRemove.filter(row => !row.selected);
    // setVehiclesData(newVehicles);
    updateVehicleData(newVehicles, 'bulkRemoveAction');
  };

  const successMessage = () => {
    const success = commuteOffer?.result?.success;
    if (success?.action === 'add') {
      return `${success?.vehicle} ${t('p.fleet.vehicle.add.success')}`;
    }
    if (success?.action === 'update') {
      return t('p.fleet.vehicle.edit.success');
    }
    return '';
  };

  return (
    <React.Fragment>
      <ModalHeader
        handleClose={handleClose}
        pageTitle={title || t('c.Fleet.Title')}
        backBtn={!!title}
      />
      <ContainerWrapper>
        <Container>
          <DataTable
            columnName={columnName}
            menuItems={getMenuItem}
            menuActions={menuActions}
            data={vehiclesData}
            // SP-4448: setData is called and set undefined to the vehicles data so far, rewrite another function to control by origin
            // setData={setVehiclesData}
            setData={updateVehicleData}
            actions={{
              add: { text: t('p.fleet.table.add'), action: addAction },
              bulkRemove: {
                text: t('p.fleet.table.remove.bulk'),
                action: bulkRemoveAction,
              },
            }}
            bulkRemove={false}
            checkbox={false}
            successMessage={successMessage()}
            isReadOnly={isReadOnly && !isTemplate}
          />
        </Container>
      </ContainerWrapper>
    </React.Fragment>
  );
};

export default Fleet;

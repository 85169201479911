import React from 'utils/react';
import debug from 'utils/debug';
import { crc32 } from 'utils/crc32';
import parseColor from 'parse-color';
import displayNames from 'utils/display-names';
import { removeInternalFieldsFromObject } from 'utils/object';

import { DropTarget } from 'react-dnd';
import Button from 'pages/CommuteOffer/TextButton';
import moment from 'moment-timezone';
import Menu from 'components/Menu';
import {
  addVehicleToSimulation,
  updateVehicleInSimulation,
  deleteVehicleFromSimulation,
} from 'api/simulations';
import MenuButton from './MenuButton';

import Title from './Title';
import VehicleName from './VehicleName';
import Caption from './Caption';
import Border from './Border';
import Container from './Container';
import Header from './Header';
import Route from './Route';
import BookingStopsEditor from './BookingStopsEditor';
import Label from './Label';
import Value from './Value';
import ButtonsContainer from './ButtonsContainer';
import Input from './Input';
import SearchResult from './SearchResults';
import UpdatedBadge from './UpdatedBadge';
import IdleBadge from './IdleBadge';
import OnlineBadge from './OnlineBadge';
import ProgressIndicator from '../../ProgressIndicator';

const D2 = debug('p:CommuteOffer:Panels:Vehicles:Vehicle');

const defaultRoutingProfile = (engine) => {
  if (engine === 'asteria') {
    return window.ASTERIA_ROUTING_PROFILE;
  }
  return window.OSRM_ROUTING_PROFILE;
};

class Vehicle extends React.PureComponent {
  onClickHeader = () => {
    D2.S.INFO('onClickHeader');

    const { activeVehicleIds = [], vehicle } = this.props;

    if (!activeVehicleIds.includes(vehicle.agent_id)) {
      this.props.setActiveVehicleId(vehicle.agent_id);
    } else {
      this.props.cleanActiveVehicleId(vehicle.agent_id);
    }
  };

  onAddPoint = () => {
    D2.S.INFO('onAddPoint');

    if (this.props.activeRouteStop) {
      this.props.cleanActiveRouteStop();
    } else {
      this.props.setAddPointMode(this.props.vehicle.agent_id);
    }
  };

  onAddStop = () => {
    D2.S.INFO('onAddStop');

    this.props.setAddStopMode(this.props.vehicle.agent_id);
  };

  onRecalculateRoute = () => {
    D2.S.INFO('onRecalculateRoute');

    this.props.recalculateRoute(this.props.vehicle.agent_id);
  };

  onEditCommuteOfferVehicle = () =>
    D2.S.FUNCTION(
      'onEditCommuteOfferVehicle',
      { props: this.props },
      ({ $D2 }) => {
        const { t, vehicle } = this.props;
        const { agent_id, capacity, route, vehicle_color, color, readOnly } =
          vehicle;
        const { routing_engine_name, road_network, osrme_timestamp_mode } =
          vehicle.routing_engine
            ? vehicle.routing_engine
            : {
                routing_engine_name: 'osrm',
                road_network: 'driving',
              };

        const timestampMode = osrme_timestamp_mode || 'start_time';

        const start_time =
          timestampMode !== 'end_time' && route.length
            ? moment(route[0].scheduled_ts).toDate()
            : undefined;
        const end_time =
          timestampMode === 'end_time' && route.length
            ? moment(route[route.length - 1].scheduled_ts).toDate()
            : undefined;

        const parsedVehicleColor = vehicle_color
          ? parseColor(vehicle_color)?.hsl?.[0]
          : parseColor(color)?.hsl?.[0];

        $D2.S.INFO('parsedVehicleColor', {
          parsedVehicleColor,
          vehicle_color,
          color,
        });

        const editorOptions = {
          mode: 'edit',
          title: t('c.ModalWindows.VehicleEditor.Title.Edit'),
          submitCaption: t('c.ModalWindows.VehicleEditor.Button.Save'),
          submittingCaption: t('c.ModalWindows.VehicleEditor.Progress.Saving'),
          vehicle,
          initialValues: {
            id: agent_id,
            capacity_passengers:
              capacity.passenger || capacity.passengers || '0',
            capacity_stops: capacity.stop || '0',
            capacity_wheelchairs: capacity.wheelchair || '0',
            start_time,
            end_time,
            routing_engine_name: {
              value: routing_engine_name,
              label: routing_engine_name,
            },
            road_network,
            vehicle_color: parsedVehicleColor ?? crc32(agent_id) % 360,
            readOnly: readOnly
              ? { label: t('Yes'), value: true }
              : { label: t('No'), value: false },
          },
        };
        $D2.S.INFO('editorOptions', {
          editorOptions,
        });

        this.props.openPopup('CommuteOfferVehicleEditor', editorOptions);
      }
    );

  onEditSimulationVehicle = () =>
    D2.S.FUNCTION(
      'onEditSimulationVehicle',
      { props: this.props },
      ({ $D2 }) => {
        const { t, vehicle, simulation, commuteOfferRequestUpdate } =
          this.props;

        global.openVehicleEditor(vehicle, {
          title: t('c.ModalWindows.VehicleEditor.Title.Edit'),
          timezone: global.GEODISC_TIMEZONE,
          date: simulation.start_time,
          onSubmit: async newVehicle =>
            $D2.S.FUNCTION(
              'onSubmit',
              { newVehicle, vehicle, simulation },
              async () => {
                await updateVehicleInSimulation(simulation.id, {
                  ...newVehicle,
                  resource_uri: undefined,
                });
                global.openInfoMessage(
                  t('c.ModalWindows.VehicleEditor.Update.Success')
                );
                commuteOfferRequestUpdate(null, {
                  isInitRequired: false,
                  ...$D2.CONTEXT,
                });
              }
            ),
        });
      }
    );

  onClone = () =>
    D2.S.FUNCTION('onClone', { props: this.props }, ({ $D2 }) => {
      const { t, vehicle, simulation, commuteOfferRequestUpdate } = this.props;

      global.openVehicleEditor(
        {
          ...removeInternalFieldsFromObject(vehicle),
          agent_id: undefined,
          id: undefined,
        },
        {
          title: t('c.ModalWindows.VehicleEditor.Title.Clone'),
          timezone: global.GEODISC_TIMEZONE,
          date: simulation.start_time,
          prohibitedServiceNumber: vehicle?.service_number ?? '',
          onSubmit: async newVehicle =>
            $D2.S.FUNCTION(
              'onSubmit',
              { newVehicle, vehicle, simulation },
              async () => {
                await addVehicleToSimulation(simulation.id, newVehicle);
                global.openInfoMessage(
                  t('c.ModalWindows.VehicleEditor.Insert.Success')
                );
                commuteOfferRequestUpdate(null, {
                  isInitRequired: false,
                  ...$D2.CONTEXT,
                });
              }
            ),
        }
      );
    });

  onDeleteCommuteOfferVehicle = () => {
    D2.S.INFO('onDeleteCommuteOfferVehicle');

    const { vehicle } = this.props;
    this.props.deleteVehicle(vehicle.agent_id);
  };

  onDeleteSimulationVehicle = () => {
    D2.S.INFO('onDeleteSimulationVehicle');

    const { t, vehicle, commuteOfferRequestUpdate } = this.props;

    (async () => {
      try {
        await deleteVehicleFromSimulation(vehicle.id);
        global.openInfoMessage(
          t('c.ModalWindows.VehicleEditor.Delete.Success')
        );
        commuteOfferRequestUpdate(null, {
          isInitRequired: false,
          ...D2.CONTEXT,
        });
      } catch (e) {
        global.openInfoMessage(
          t('c.ModalWindows.VehicleEditor.Delete.Failure')
        );
      }
    })();
  };

  onShowVehicleSource = () => {
    D2.S.INFO('onShowVehicleSource');

    const { vehicle } = this.props;

    this.props.showVehicleSource(vehicle.agent_id);
  };

  onShowVehicleUpdates = () => {
    D2.S.INFO('onShowVehicleUpdates');
    const { vehicle } = this.props;

    this.props.showVehicleUpdates(vehicle.agent_id);

    // this.props.openPopup('CommuteOfferVehicleUpdates', {
    //   title: 'c.ModalWindow.DiffViewer.Title.VehicleUpdates',
    //   id: vehicle.agent_id
    // });
  };

  onSearchInputChange = (e) => {
    D2.S.INFO('onSearchInputChange');

    this.props.setStopSearchQuery(e.target.value);
  };

  onChangeStartTime = (value) => {
    D2.S.INFO('onChangeStartTime');

    const { vehicle } = this.props;
    if (value instanceof moment) {
      this.props.setVehicleStartTime(
        vehicle.agent_id,
        value.tz(global.GEODISC_TIMEZONE).format()
      );
    }
  };

  onVehicleAdmin = () => {
    D2.S.INFO('onVehicleAdmin', { props: this.props });

    const { vehicle } = this.props;

    window.open(
      `${window.GEODISC_API_URL}/admin/simulation/vehicle/${vehicle.id}/change/`,
      '_blank'
    );
  };

  render() {
    return D2.S.FUNCTION('render', { props: this.props }, ({ $D2 }) => {
      const {
        t,
        d,
        vehicle,
        activeVehicleIds,
        isOver,
        connectDropTarget,
        setActiveRouteStop,
        activeRouteStopUid,
        editableBooking,
        pointEditing,
        addStopMode,
        filteredStops,
        stopSearchQuery,
        cleanAddStopMode,
        cleanAddPointMode,
        addStopToRoute,
        isReadOnly,
        canManageVehicles,
        loadedCommuteOffer,
        simulation,
        isDeliveryLayout,
        isStaff,
        isSuperuser,
        capacityFunctor,
        currentUserConfig,
        isTemplate,
      } = this.props;
      const onEdit = simulation
        ? this.onEditSimulationVehicle
        : this.onEditCommuteOfferVehicle;
      const onDelete = simulation
        ? this.onDeleteSimulationVehicle
        : this.onDeleteCommuteOfferVehicle;

      const { current_sim_ts, $capacity_info } = vehicle;

      const positionAgeMs = current_sim_ts
        ? moment().diff(moment(current_sim_ts))
        : undefined;

      const isActive = activeVehicleIds.includes(vehicle.agent_id);

      const { occupied, transferred } = vehicle.passengers;

      const capacity = capacityFunctor.functor(vehicle.capacity);

      const routing_engine = vehicle.routing_engine || {};

      const routingEngine = routing_engine.routing_engine_name || 'osrme';
      const routingProfile =
        routing_engine.road_network ||
        defaultRoutingProfile(routing_engine.routing_engine_name);

      const isProcessing = false;

      const vehicleDisplayNames = displayNames(
        vehicle.display_name || vehicle.service_number || vehicle.agent_id
      );
      $D2.S.INFO('render:vehicleDisplayNames', { vehicleDisplayNames });

      const isAssignedToDriver = vehicle.driver;
      const isIdle =
        isAssignedToDriver && (!positionAgeMs || positionAgeMs / 1000 > 3 * 60);
      const isOnline =
        isAssignedToDriver && !isIdle && vehicle.lon && vehicle.lat;

      const hasPermissionsToChangeVehicles =
        !simulation || (simulation && canManageVehicles);
      const canEditVehicles = hasPermissionsToChangeVehicles && !isReadOnly;

      const canAddStop = false;
      // const canAddStop = !isReadOnly && !isDeliveryLayout;
      const canAddPoint = !isReadOnly && !isDeliveryLayout;
      // const canEdit = !isReadOnly && canManageVehicles;
      const canClone = !loadedCommuteOffer && simulation && canEditVehicles;
      // const canDelete = !isReadOnly && canManageVehicles;
      const canViewSource = !isDeliveryLayout;

      const canAccessAdminInterface = isStaff || isSuperuser;

      const isMenuVisible =
        canAccessAdminInterface ||
        canAddStop ||
        canAddPoint ||
        canEditVehicles ||
        canViewSource;

      const isRoutingEngineVisible =
        currentUserConfig.geodisc_settings?.panels?.vehicles
          ?.display_routing_engine || false;

      const canRecalculateRoutes = !isTemplate && canEditVehicles;

      return (
        <Container
          id={vehicle.agent_id}
          ref={connectDropTarget}
          isOver={isOver}
          isActive={isActive}
        >
          <Header>
            <Title color={vehicle.$activeColor} data-testid='Vehicle-Title'>
              <Border color={vehicle.$activeColor} />
              <Caption onClick={this.onClickHeader}>
                {t('p.Editor.Panels.Vehicles.Vehicle.Caption')}
                <br />
                {vehicleDisplayNames.map((x, i) => (
                  <VehicleName key={`vehicle-name-${i}`}>{d(x)}</VehicleName>
                ))}
              </Caption>
              {vehicle.isChanged && (
                <UpdatedBadge onClick={this.onShowVehicleUpdates} />
              )}
              {isIdle && <IdleBadge />}
              {isOnline && <OnlineBadge />}
              {isProcessing ? (
                <ProgressIndicator />
              ) : (
                isMenuVisible && (
                  <Menu>
                    {canAccessAdminInterface && vehicle.id && (
                      <MenuButton onClick={this.onVehicleAdmin}>
                        {t('p.Editor.Panels.Vehicles.Vehicle.Menu.Admin')}
                      </MenuButton>
                    )}
                    {canAddStop && (
                      <MenuButton onClick={this.onAddStop}>
                        {t('p.Editor.Panels.Vehicles.Vehicle.Menu.AddStop')}
                      </MenuButton>
                    )}
                    {canAddPoint && (
                      <MenuButton onClick={this.onAddPoint}>
                        {t('p.Editor.Panels.Vehicles.Vehicle.Menu.AddPoint')}
                      </MenuButton>
                    )}
                    {canEditVehicles && (
                      <MenuButton onClick={onEdit}>
                        {t('p.Editor.Panels.Vehicles.Vehicle.Menu.Edit')}
                      </MenuButton>
                    )}
                    {canClone && (
                      <MenuButton onClick={this.onClone}>
                        {t('p.Editor.Panels.Vehicles.Vehicle.Menu.Clone')}
                      </MenuButton>
                    )}
                    {canViewSource && (
                      <MenuButton onClick={this.onShowVehicleSource}>
                        {t('p.Editor.Panels.Vehicles.Vehicle.Menu.ViewSource')}
                      </MenuButton>
                    )}
                    {canRecalculateRoutes && (
                      <MenuButton onClick={this.onRecalculateRoute}>
                        {t('p.Editor.Panels.Vehicles.Vehicle.Menu.Recalculate')}
                      </MenuButton>
                    )}
                    {canEditVehicles && (
                      <MenuButton onClick={onDelete}>
                        {t('p.Editor.Panels.Vehicles.Vehicle.Menu.Delete')}
                      </MenuButton>
                    )}
                  </Menu>
                )
              )}
            </Title>
            {!window.GEODISC_UI_COMMUTE_OFFER_TRANSFERRED_DISABLE &&
              !global.GEODISC_COMMUTE_OFFER_SHOW_FULL_CAPACITY_INFO && (
                <div>
                  <Label>
                    {t('p.Editor.Panels.Vehicles.Vehicle.Transferred')}
                  </Label>
                  <Value>{d(transferred)}</Value>
                </div>
              )}
            {!global.GEODISC_COMMUTE_OFFER_SHOW_FULL_CAPACITY_INFO && (
              <div>
                <Label>{t('p.Editor.Panels.Vehicles.Vehicle.Occupied')}</Label>
                <Value>{d(`${occupied} / ${capacity}`)}</Value>
              </div>
            )}
            {global.GEODISC_COMMUTE_OFFER_SHOW_FULL_CAPACITY_INFO &&
              Object.entries($capacity_info)
                // .filter(
                //   ([, info]) => info.occupied > 0 || !vehicle.route.length
                // )
                .sort(([a], [b]) => a - b)
                .map(([key, info]) => {
                  const hasRoute = !!vehicle.route.length;
                  const hasActiveTransfers =
                    info.capacity > 0 && (info.occupied > 0 || !hasRoute);
                  return (
                    <div
                      key={`capacity-info-${key}`}
                      data-testid='Vehicle-Label-Capacity'
                    >
                      <Label isActive={hasActiveTransfers}>{`${key}:`}</Label>
                      <Value isActive={hasActiveTransfers}>
                        {d(`${info.occupied} / ${info.capacity}`)}
                      </Value>
                    </div>
                  );
                })}
            {isRoutingEngineVisible && (
              <div>
                <Label data-testid='Vehicle-Label-Routing'>
                  {t('p.Editor.Panels.Vehicles.Vehicle.Routing')}
                </Label>
                {`${routingEngine} - ${routingProfile}`}
              </div>
            )}
          </Header>
          <div>
            {editableBooking &&
              editableBooking[vehicle.agent_id] &&
              isActive && (
                <BookingStopsEditor nodes={editableBooking[vehicle.agent_id]} />
              )}
          </div>
          {isActive && (
            <React.Fragment>
              <Route
                vehicleId={vehicle.agent_id}
                isReadOnly={
                  vehicle.readOnly || vehicle.readonly || vehicle.isReadOnly
                }
                route={vehicle.route}
                setActiveRouteStop={setActiveRouteStop}
                activeRouteStopUid={activeRouteStopUid}
                color={vehicle.$activeColor}
                isHaveEditable={editableBooking}
              />
              <i>
                {pointEditing &&
                  t('p.Editor.Panels.Vehicles.PointEditor.SelectOnMap')}
              </i>
              {addStopMode === vehicle.agent_id && (
                <React.Fragment>
                  <Input
                    onChange={this.onSearchInputChange}
                    placeholder={t(
                      'p.Editor.Panels.Vehicles.PointEditor.ClickOnMap'
                    )}
                    value={stopSearchQuery || ''}
                  />
                  <SearchResult
                    stops={filteredStops}
                    addStopToRoute={addStopToRoute}
                    vehicleId={vehicle.agent_id}
                  />
                </React.Fragment>
              )}
              {addStopMode === vehicle.agent_id && (
                <ButtonsContainer>
                  <Button onClick={cleanAddStopMode}>
                    {t('p.Editor.Panels.Vehicles.StopEditor.Finish')}
                  </Button>
                </ButtonsContainer>
              )}
              {pointEditing && (
                <ButtonsContainer>
                  <Button onClick={cleanAddPointMode}>
                    {t('p.Editor.Panels.Vehicles.StopEditor.Finish')}
                  </Button>
                </ButtonsContainer>
              )}
            </React.Fragment>
          )}
        </Container>
      );
    });
  }
}

export default DropTarget(
  'booking',
  {
    drop: props => ({
      ...props.vehicle,
      isHaveEditable: props.editableBooking,
    }),
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  })
)(Vehicle);

import { sync } from 'utils/async';

import moment from 'moment-timezone';
import debug from 'utils/debug';

import { emptyCommuteOfferJSON } from 'utils/CommuteOffer/defaults';

const D2 = debug('m:CommuteOffer:MergeCommuteOffer');

// eslint-disable-next-line
export const commuteOffer$MergeCommuteOffers = (commuteOffers, opts) => {
  D2.S.INFO('Request', {
    commuteOffers,
  });

  const options = opts || {};

  const mergeBase = options.mergeBase || JSON.parse(emptyCommuteOfferJSON);

  if (commuteOffers.length === 0) {
    return mergeBase;
  }

  const baseCommuteOffer = {
    ...mergeBase,
    stateless_api_request_data: {
      ...mergeBase.stateless_api_request_data,
      current_time: commuteOffers[0].stateless_api_request_data.current_time,
    },
  };

  const mergedOffer = commuteOffers.reduce((dst, src) => {
    D2.S.INFO('Merge', { dst, src });

    const current_time =
      moment(src.stateless_api_request_data.current_time).diff(
        moment(dst.stateless_api_request_data.current_time)
      ) < 0
        ? src.stateless_api_request_data.current_time
        : dst.stateless_api_request_data.current_time;

    return {
      ...dst,
      stateless_api_request_data: {
        ...dst.stateless_api_request_data,
        current_time,
        bookings: {
          ...dst.stateless_api_request_data.bookings,
          ...src.stateless_api_request_data.bookings,
        },
        nodes: [
          ...dst.stateless_api_request_data.nodes,
          ...src.stateless_api_request_data.nodes,
        ],
        vehicles: [
          ...dst.stateless_api_request_data.vehicles,
          ...src.stateless_api_request_data.vehicles,
        ],
        engine_settings: {
          ...dst.stateless_api_request_data.engine_settings,
          ...src.stateless_api_request_data.engine_settings,
        },
      },
      result: {
        ...dst.result,
        cost: src.result.cost + dst.result.cost,
        vehicles: {
          ...dst.result.vehicles,
          ...src.result.vehicles,
        },
        assigned_bookings: [
          ...dst.result.assigned_bookings,
          ...src.result.assigned_bookings,
        ],
        rejected_bookings: [
          ...dst.result.rejected_bookings,
          ...src.result.rejected_bookings,
        ],
        $errors: [...dst.result.$errors, ...src.result.$errors],
        $warnings: [...dst.result.$warnings, ...src.result.$warnings],
        $fixes: [...dst.result.$fixes, ...src.result.$fixes],
      },
    };
  }, baseCommuteOffer);
  D2.S.INFO('Success', { mergedOffer });

  return mergedOffer;
};

export const commuteOffer$MergeCommuteOffersAsync = async (
  commuteOffers,
  opts
) => {
  const result = commuteOffer$MergeCommuteOffers(commuteOffers, opts);

  await sync();
  return result;
};

import styled from 'styled-components';

const Text = styled.div`
  font-size: 16px;
  color: #edece8;
  opacity: 0.5;
  margin-left: 7px;
`;

export default Text;

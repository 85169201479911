import React, { useEffect, useRef, useState } from 'react';
import FormField from 'components/Forms/FormField';
import Select from 'components/Forms/Select';
import CheckboxField from 'components/Forms/Checkbox';
import ModalHeader from 'components/ModalHeader';
import { Container, ContainerWrapper } from './styles';
import getSchema from './schema';
import { useForm } from 'react-hook-form';
import useTranslation from 'utils/react/useTranslation';
import compare from 'just-compare';

import RadioField from 'components/Forms/RadioWithButtons';
import { useHistory } from 'react-router-dom';
import { pageTitles } from 'components/MasterSettings/MasterSettings';

export const restructureData = (data) => {
  return {
    ...data,
    orders_display: data?.orders_display || 'dropoff_only',
    display_highest_load_for_each_vehicle:
      data?.display_highest_load_for_each_vehicle ?? true,
    display_vehicle_load_for_pickup:
      data?.display_vehicle_load_for_pickup ?? true,
    display_vehicle_load_for_dropoff:
      data?.display_vehicle_load_for_dropoff ?? true,
    algo_optimize_quantity: [
      {
        id: data?.algo_optimize_quantity ?? null,
        label: data?.algo_optimize_quantity ?? '',
      },
    ],
    geofence_definition_strategy: [
      {
        id: data?.geofence_definition_strategy ?? null,
        label: data?.geofence_definition_strategy ?? '',
      },
    ],
    pipeline_type: [
      {
        id: data?.pipeline_type ?? null,
        label: data?.pipeline_type ?? '',
      },
    ],
    geofence_vehicle_allocation_strategy: [
      {
        id: data?.geofence_vehicle_allocation_strategy ?? null,
        label: data?.geofence_vehicle_allocation_strategy ?? '',
      },
    ],
    mutually_exclusive_groups: JSON.stringify(data?.mutually_exclusive_groups),
    first_solution_strategies: JSON.stringify(data?.first_solution_strategies),
  };
};

const SettingsForm = (props) => {
  const {
    saveSimulationSettings,
    commuteOfferData = {},
    title,
    setTemplateSimulationId,
    project,
    templateId,
    templateData,
    fetchTemplateSimulationData,
  } = props;
  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    getValues,
    control,
    reset,
  } = useForm({
    criteriaMode: 'firstError',
    mode: 'onChange',
    defaultValues: restructureData(templateData?.data?.logistics_api_settings),
  });
  const [settingsData, setSettingsData] = useState({});
  const properties = getSchema()?.properties;
  const { t } = useTranslation();
  const browserhistory = useHistory();

  const settingsDataRef = useRef();

  useEffect(() => {
    const projectId = project.get('id');
    if (projectId) {
      setTemplateSimulationId({ id: projectId });
    }

    const backListener = browserhistory.listen((location, action) => {
      if (action === 'POP') {
        const params = new URLSearchParams(window.location.search);
        if (params.get('page') === null) {
          // when user click browser back
          handleCloseWithForward();
        } else if (params.get('page') === pageTitles.templates) {
          handleCloseWithForward();
        }
      }
    });

    return () => {
      backListener();
    };
  }, []);

  useEffect(() => {
    if (templateId) {
      fetchTemplateSimulationData({ id: templateId });
    }
  }, [templateId]);

  useEffect(() => {
    const logisticApiSettings =
      commuteOfferData?.$source?.simulation?.data?.logistics_api_settings;
    const processedData = restructureData(logisticApiSettings);
    setSettingsData(processedData);
    reset(processedData);
  }, [commuteOfferData]);

  useEffect(() => {
    settingsDataRef.current = settingsData;
  }, [settingsData]);

  const handleCloseWithForward = () => {
    handleClose(
      () => {
        goForwardBrowserHistory();
        global.closeWarningMessage();
      },
      () => {
        global.closeWarningMessage();
        global.closeFullScreen();
      },
      () => {
        global.closeFullScreen();
      }
    );
  };

  const goForwardBrowserHistory = () => {
    window.history.forward();
  };

  const goBackBrowserHistory = () => {
    browserhistory.goBack();
  };

  const onSubmit = (formData) => {
    saveSimulationSettings({
      ...commuteOfferData,
      $source: {
        ...commuteOfferData?.$source,
        simulation: {
          ...commuteOfferData?.$source?.simulation,
          data: {
            logistics_api_settings: {
              ...formData,
              trip_cost: Number(formData?.trip_cost),
              vehicle_cost: Number(formData?.vehicle_cost),
              algo_optimize_quantity: formData?.algo_optimize_quantity[0]?.id,
              geofence_definition_strategy:
                formData?.geofence_definition_strategy[0]?.id,
              max_dropoff_slack: formData?.max_dropoff_slack
                ? Number(formData?.max_dropoff_slack)
                : null,
              max_pickup_slack: formData?.max_pickup_slack
                ? Number(formData?.max_pickup_slack)
                : null,
              pipeline_type: formData?.pipeline_type[0]?.id,
              geofence_vehicle_allocation_strategy:
                formData?.geofence_vehicle_allocation_strategy[0]?.id,
              mutually_exclusive_groups: JSON.parse(
                formData?.mutually_exclusive_groups
              ),
              first_solution_strategies: JSON.parse(
                formData?.first_solution_strategies
              ),
            },
          },
        },
      },
    });
  };

  const handleClose = (noAction, yesAction, noChangeAction) => {
    if (!compare(settingsDataRef.current, getValues())) {
      global.openWarningMessage({
        title: t('p.advanceSettings.warning.close.title'),
        message: t('p.advanceSettings.warning.close.message'),
        buttons: [
          {
            text: t('p.advanceSettings.warning.close.btn.no'),
            action: noAction,
            fill: false,
          },
          {
            text: t('p.advanceSettings.warning.close.btn.yes'),
            action: yesAction,
            fill: true,
          },
        ],
      });
    } else {
      noChangeAction();
    }
  };

  const handleReset = () => {
    global.openWarningMessage({
      title: t('p.advanceSettings.warning.reset.title'),
      message: t('p.advanceSettings.warning.reset.message'),
      buttons: [
        {
          text: t('c.messages.Cancel'),
          fill: false,
        },
        {
          text: t('p.advanceSettings.warning.reset.btn.yes'),
          action: () => {
            reset(restructureData(templateData?.data?.logistics_api_settings));
            global.closeWarningMessage();
          },
          fill: true,
        },
      ],
    });
  };
  const values = getValues();
  const numberOfErrors = Object.keys(errors)?.length;

  return (
    <>
      <ModalHeader
        disableSaveBtn={compare(settingsData, values) || numberOfErrors > 0}
        handleClose={goBackBrowserHistory}
        btnOneAction={handleReset}
        pageTitle={title ? title : t('c.AdvancedSettings.Title')}
        btnOneText={t('c.AdvancedSettings.Button.Reset')}
        btnTwoText={t('c.AdvancedSettings.Button.Save')}
        formId='settings-form'
      />
      <ContainerWrapper>
        <Container>
          <div className='form-group' />
          <form onSubmit={handleSubmit(onSubmit)} id='settings-form'>
            <div className='form-group'>
              <div>
                {Object.keys(properties)?.map((key) => {
                  const {
                    title: label,
                    type,
                    validations,
                    info,
                    options,
                  } = properties[key];
                  const isStingOrNumber =
                    type === 'number' || type === 'string';
                  return (
                    <div
                      key={key}
                      style={
                        key !== 'display_highest_load_for_each_vehicle'
                          ? { marginTop: '40px' }
                          : {}
                      }
                    >
                      {isStingOrNumber && options && (
                        <Select
                          info={info}
                          type={type}
                          label={label}
                          name={key}
                          control={control}
                          errors={errors[key]}
                          options={[...options]}
                          labelKey='label'
                          valueKey='id'
                          width='288px'
                        />
                      )}
                      {isStingOrNumber && !options && (
                        <FormField
                          info={info}
                          type={type}
                          label={label}
                          name={key}
                          value={values[key]}
                          register={register(key, { ...validations })}
                          errors={errors[key]}
                          width='288px'
                        />
                      )}
                      {type === 'boolean' && (
                        <CheckboxField
                          label={label}
                          name={key}
                          info={info}
                          type='toggle'
                          value={values[key]}
                          control={control}
                        />
                      )}
                      {type === 'radio' && options && (
                        <RadioField
                          label={label}
                          color='white'
                          labelMargin='8px 14px'
                          name={key}
                          radioButtonSize='18px'
                          options={options}
                          selected='single'
                          control={control}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </form>
        </Container>
      </ContainerWrapper>
    </>
  );
};

export default SettingsForm;

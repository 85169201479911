import styled from 'styled-components';

const LinkButton = styled.div`
  display: inline-block;
  color: rgb(40, 45, 55);
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: rgb(40, 45, 55);
  }
`;

export default LinkButton;

import OriginalReact from 'react';
// import useTranslation from 'utils/react/useTranslation';

import md5 from 'blueimp-md5';

const textBreakpoints = new Set([]);

global.GEODISC_UNTRANSLATED_MESSAGES = {};

const createElement = (...args) => {
  if (global.GEODISC_I18N_DEBUG_ENABLED) {
    const text = args[args.length - 1];
    if (typeof text === 'string') {
      if (text.indexOf('🗎') === 0) {
        return OriginalReact.createElement(...args);
      }
      if (text.indexOf('🌍') < 0) {
        const messageKey = `c.messages.${md5(text)}`;
        global.GEODISC_UNTRANSLATED_MESSAGES[messageKey] = text;
        if (textBreakpoints.has(text)) {
          throw Error(`Untranslated string: ${text}`);
        }
        // eslint-disable-next-line no-console
        console.log('*** Text:', { text });
        return OriginalReact.createElement(...args.slice(0, -1), `🛑${text}`);
      }
    }
  }
  return OriginalReact.createElement(...args);
};

export default {
  ...OriginalReact,
  createElement,
};

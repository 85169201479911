import React from 'utils/react';
import routing from 'config/routing';

import Container from './Container';
import Button from './Button';

class RoutingButtons extends React.PureComponent {
  state = {
    isOpen: false,
  };

  togglePanel = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  render() {
    const { routingEngine } = this.props;

    const { isOpen } = this.state;

    return (
      <Container>
        {isOpen &&
          Object.keys(routing).map((key) => {
            const onClick = () => {
              this.props.toggleRoutingEngine(key);
            };

            return (
              <Button
                key={key}
                onClick={onClick}
                isActive={routingEngine === key}
              >
                {routing[key].name}
              </Button>
            );
          })}
        <Button onClick={this.togglePanel}>
          {isOpen ? 'Close' : 'Driving'}
        </Button>
      </Container>
    );
  }
}

export default RoutingButtons;

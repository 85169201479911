import React from 'utils/react';
import Container from './Container';
import Overflow from './Overflow';
import Label from './Label';

const Group = ({ name, flexShrink, children }) => (
  <Container flexShrink={flexShrink}>
    <Label>{name}</Label>
    <Overflow>{children}</Overflow>
  </Container>
);

export default Group;

import {
  call,
  put,
  take,
  takeLatest,
  takeEvery,
  select,
} from 'redux-saga/effects';
import { api$CommuteOffer as api } from 'api';
import { sleep } from 'utils/async';

// import Immutable from 'immutable';
import moment from 'moment-timezone';
import debug from 'utils/debug';
import deepmerge from 'deepmerge';
import { removeInternalFieldsFromObject } from 'utils';

import {
  defaultVehicleRoutingEngineJSON,
  validateCommuteOffer,
  filterCommuteOfferByVehicle,
  diffCommuteOffers,
  normalizeCommuteOfferForExport,
} from 'utils/CommuteOffer';

import { commuteSchedule$ImportPassengersSchedule } from 'utils/CommuteSchedule';

import { fetchRoute } from 'utils/RoutingEngine';

import * as actions from 'modules/commuteOffer/actions';
import * as uiActions from 'modules/ui/actions';

import {
  filteredVehiclesSelector,
  commuteOfferOriginalDataSelector,
  commuteOfferCurrentDataSelector,
  allVehiclesSelector,
} from 'modules/commuteOffer/selectors';
import { stopsSelector } from 'modules/entities/selectors';
import {
  currentProjectIdSelector,
  currentProjectSelector,
  projectsSelector,
} from 'modules/user/selectors';
import { routePageSelector } from 'modules/router/selectors';
import {
  activeRouteStopUidSelector,
  addStopModeSelector,
  draggablePointSelector,
  routingEngineSelector,
} from 'modules/ui/selectors';
import { vehiclesStopsSelector } from 'modules/maps/selectors';

import {
  fetchCommuteOfferHandler,
  updateCommuteOfferHandler,
  fetchAllRoutesHandler,
  recalculateRouteHandler,
  addBookingToRouteHandler,
  addPointToRouteHandler,
  recalculateVehicleTimeHandler,
  removeBookingFromRouteHandler,
  invalidateAllBookingsHandler,
  finishInvalidateAllBooking,
} from './handlers';
import { invalidateBooking } from 'api/simulations';

const D2 = debug('m:CommuteOffer:saga');

function* fetchCommuteOffers() {
  D2.S.INFO('fetchCommuteOffers');

  try {
    const page = yield select(routePageSelector);
    const projectId = yield select(currentProjectIdSelector);
    D2.S.TRACE($ => $('Page:', page));

    const data = yield call(api.getCommuteOffers, projectId);

    yield put({
      type: actions.COMMUTE_OFFERS_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: actions.COMMUTE_OFFERS_FETCH_FAILURE, payload: error });
  }
}

function* deleteCommuteOffer({ payload }) {
  D2.S.INFO('deleteCommuteOffer', payload);

  try {
    const id = payload;
    yield call(api.deleteCommuteOffer, id);
    yield put({
      type: actions.DELETE_COMMUTE_OFFER_SUCCESS,
      payload: id,
    });
    yield put(actions.fetchCommuteOffers());
  } catch (error) {
    yield put({ type: actions.DELETE_COMMUTE_OFFER_FAILURE, payload: error });
  }
}

function* fetchAllWalkingRoutes() {
  D2.S.DEBUG('fetchAllWalkingRoutes()');

  global.WALKING_ROUTES = {};
  global.WALKING_ROUTES_BY_IDS = {};

  yield;
}

function* changeRouteStop({ payload }) {
  D2.S.INFO('changeRouteStop', payload);

  const vehicleId = payload.agent_id;

  const draggablePoint = yield select(draggablePointSelector);
  D2.S.INFO('changeRouteStop:draggablePoint', draggablePoint);

  const { id } = draggablePoint;

  if (payload.node_type === 'point') {
    const { lon, lat } = payload;

    D2.S.INFO('changeRouteStop:changeRoutePoint', { lon, lat, id, vehicleId });
    yield put(actions.changeRoutePoint(lon, lat, id, vehicleId));

    const vehicles = yield select(allVehiclesSelector);
    const vehicle = vehicles.find(item => item.agent_id === vehicleId);

    yield put(actions.fetchRoute(vehicle));
    yield take(actions.ROUTE_FETCH_RESULTS);

    yield put(actions.recalculateVehicleTime([vehicleId], 'set'));

    return;
  }

  const { node_type, keepAddress = false } = payload;
  const stop = payload.nearestPoint;

  const { properties, geometry } = stop;
  const { coordinates } = geometry;

  if (node_type === 'stop') {
    const newEmptyStop = {
      stop_id: String(properties.id),
      location_code: properties.code,
      location_name: `${properties.name} #${properties.code}`,
      lon: coordinates[0],
      lat: coordinates[1],
      node_type,
      $agent_id: vehicleId,
    };

    D2.S.INFO('changeRouteStop:newEmptyStop', newEmptyStop);
    yield put(actions.changeRouteEmptyStop(id, newEmptyStop, vehicleId));

    const vehicles = yield select(allVehiclesSelector);
    const vehicle = vehicles.find(item => item.agent_id === vehicleId);

    yield put(actions.fetchRoute(vehicle));
    yield take(actions.ROUTE_FETCH_RESULTS);

    yield put(actions.recalculateVehicleTime([vehicleId], 'set'));

    return;
  }

  const activeStopUid = yield select(activeRouteStopUidSelector);
  D2.S.INFO('changeRouteStop:activeStopId', { activeStopUid });

  const vehicleStops = yield select(vehiclesStopsSelector);
  D2.S.INFO('changeRouteStop:vehicleStops', vehicleStops);

  const changedStop = vehicleStops.find(item => item.uid === activeStopUid);
  D2.S.INFO('changeRouteStop:changedStop', changedStop);

  const newNodes = changedStop.bookings.map((booking) => {
    const { node } = booking;
    const locationProperties = !keepAddress
      ? {
          stop_id: String(properties.id),
          location_code: properties.code,
          location_name: `${properties.name} #${properties.code}`,
        }
      : {};
    return {
      ...node,
      ...locationProperties,
      lon: coordinates[0],
      lat: coordinates[1],
      node_type: node_type || 'pickup',
      $agent_id: vehicleId,
    };
  });
  D2.S.INFO('changeRouteStop:newNodes', newNodes);

  yield put(actions.setBookingNode(newNodes));

  const vehicles = yield select(allVehiclesSelector);
  const vehicle = vehicles.find(item => item.agent_id === vehicleId);

  yield put(actions.fetchRoute(vehicle));
  yield take(actions.ROUTE_FETCH_RESULTS);

  yield put(actions.recalculateVehicleTime([vehicleId], 'set'));
}

function* changeRouteOrder({ payload }) {
  D2.S.INFO('changeRouteOrder', { payload });

  const { vehicleId } = payload;

  const vehicles = yield select(allVehiclesSelector);
  const vehicle = vehicles.find(item => item.agent_id === vehicleId);

  yield put(actions.fetchRoute(vehicle));
  yield take(actions.ROUTE_FETCH_RESULTS);

  yield put(actions.recalculateVehicleTime([vehicleId], 'set'));
}

function* addStopToRoute({ payload }) {
  D2.S.INFO('addStopToRoute', payload);

  const stopId = payload;
  const vehicleId = yield select(addStopModeSelector);
  const stops = yield select(stopsSelector);
  const vehicles = yield select(filteredVehiclesSelector);
  const vehicle = vehicles.find(item => item.agent_id === vehicleId);
  const { route } = vehicle;

  const stop = stops[stopId];
  yield put(actions.setStopToRoute(stop, vehicleId, route));
}

function* fetchRouteHandler({ payload }) {
  D2.S.INFO('fetchRoute:Request', payload);

  try {
    const { vehicle } = payload;
    const {
      agent_id,
      route,
      routing_engine = {
        routing_engine_name: 'osrme',
      },
    } = vehicle;

    const selectedRoutingEngine = yield select(routingEngineSelector);
    D2.S.INFO('fetchRoute:selectedRoutingEngine', { selectedRoutingEngine });

    const allVehicles = yield select(allVehiclesSelector);
    const selectedVehicle = allVehicles.find(x => x.agent_id === agent_id);
    D2.S.INFO('fetchRoute:selectedVehicle', { selectedVehicle });

    const vehicleRoute = route || selectedVehicle.route;
    D2.S.INFO('fetchRoute:vehicleRoute', { vehicleRoute });

    const routingEngine =
      selectedRoutingEngine !== 'defined_by_vehicle_settings'
        ? selectedRoutingEngine
        : routing_engine.routing_engine_name;
    D2.S.INFO('fetchRoute:routingEngine', {
      routingEngine,
      selectedRoutingEngine,
    });

    const data = yield call(fetchRoute, vehicleRoute, {
      routing_engine: {
        ...routing_engine,
        routing_engine_name: routingEngine,
      },
    });

    D2.S.INFO('fetchRoute:Success', { payload, data, agent_id });
    yield put({
      type: actions.ROUTE_FETCH_RESULTS,
      payload: { data, agent_id },
    });
  } catch (error) {
    D2.S.INFO('fetchRoute:Failure', { error });
    yield put({ type: actions.ROUTE_FETCH_RESULTS, payload: { error } });
  }
}

function* setActiveVehicleId({ payload }) {
  D2.S.INFO('setActiveVehicleId', payload);

  yield;
}

function* routeFetchSuccess({ payload }) {
  D2.S.INFO('routeFetchSuccess', payload);

  yield;
}

function* deleteVehicle({ payload }) {
  D2.S.INFO('deleteVehicle', payload);

  const id = payload;
  yield put(uiActions.cleanActiveVehicleId(id));
}

function* addCommuteOffer({ payload }) {
  D2.S.INFO('addCommuteOffer:Request', payload);

  try {
    const { params, data } = payload;
    const { name, project } = params;

    const normalizedCommuteOffer = yield call(normalizeCommuteOfferForExport, {
      ...data,
      name,
      project,
    });
    D2.S.INFO('addCommuteOffer:normalizedCommuteOffer', {
      normalizedCommuteOffer,
    });

    const { id } = yield call(api.addCommuteOffer, {
      ...normalizedCommuteOffer,
      name,
      project,
    });

    D2.S.INFO('addCommuteOffer:Success', { id });
    yield put({ type: actions.ADD_COMMUTE_OFFER_SUCCESS, payload: { id } });
    yield put(actions.fetchCommuteOffers());
    yield put(uiActions.closePopup());
  } catch (error) {
    D2.S.INFO('addCommuteOffer:Failure', { error });
    yield put({ type: actions.ADD_COMMUTE_OFFER_FAILURE, payload: error });
  }
}

function* duplicateCommuteOffer({ payload }) {
  D2.S.INFO('duplicateCommuteOffer:Request', payload);

  try {
    const { params } = payload;
    const { name } = params;

    const data = yield call(api.getCommuteOffer, payload.id);
    D2.S.INFO('duplicateCommuteOffer:Data', { data });

    const { project, result, stateless_api_request_data } = data;

    const { id } = yield call(api.addCommuteOffer, {
      project,
      result,
      stateless_api_request_data,
      name,
    });

    D2.S.INFO('duplicateCommuteOffer:Success', { id });
    yield put({
      type: actions.DUPLICATE_COMMUTE_OFFER_SUCCESS,
      payload: { id },
    });
    yield put(actions.fetchCommuteOffers());
    yield put(uiActions.closePopup());
  } catch (error) {
    D2.S.INFO('duplicateCommuteOffer:Failure', { error });
    yield put({
      type: actions.DUPLICATE_COMMUTE_OFFER_FAILURE,
      payload: error,
    });
  }
}

function* incSerialNumber() {
  D2.S.INFO('incSerialNumber');

  yield;
}

function* addVehicleRequest({ payload }) {
  D2.S.INFO('addVehicleRequest:Request', payload);
  try {
    const { data, values } = payload;

    const newData = { ...data };

    const newVehicle = {
      agent_id: values.id,
      capacity: {
        passenger: parseInt(values.capacity_passengers, 10),
        stop: parseInt(values.capacity_stops, 10),
        wheelchair: parseInt(values.capacity_wheelchairs, 10),
      },
      routing_engine: deepmerge(JSON.parse(defaultVehicleRoutingEngineJSON), {
        routing_engine_name: values.routing_engine_name.value,
        road_network: values.road_network,
      }),
      readOnly: values.readOnly.value,
      assigned_nodes: [],
      completed_nodes: [],
      geofence_ids: [],
      partial_route: [],
      vehicle_cost: 100000,
      lat: 0.0,
      lon: 0.0,
    };

    newData.stateless_api_request_data.vehicles = [
      ...newData.stateless_api_request_data.vehicles,
      newVehicle,
    ];

    newData.result.vehicles[values.id] = [];

    D2.S.INFO('addVehicleRequest:Success', { newData });
    yield put({
      type: actions.COMMUTE_OFFER_ADD_VEHICLE_SUCCESS,
      payload: newData,
    });
  } catch (error) {
    D2.S.INFO('addVehicleRequest:Failure', { error });
    yield put({
      type: actions.COMMUTE_OFFER_ADD_VEHICLE_FAILURE,
      payload: error,
    });
  }
}

function* editVehicleRequest({ payload }) {
  D2.S.INFO('editVehicleRequest', payload);

  try {
    const { data, id, values } = payload;

    const newData = { ...data };

    const otherVehicles = data.stateless_api_request_data.vehicles.filter(
      item => item.agent_id !== id
    );
    D2.S.INFO('editVehicleRequest:otherVehicles', otherVehicles);

    const originalVehicle = data.stateless_api_request_data.vehicles.find(
      item => item.agent_id === id
    );
    D2.S.INFO('editVehicleRequest:originalVehicle', {
      originalVehicle,
      values,
    });

    const { routing_engine } = originalVehicle;

    const newVehicle = {
      ...originalVehicle,
      agent_id: values.id,
      capacity: {
        passenger: parseInt(values.capacity_passengers, 10),
        stop: parseInt(values.capacity_stops, 10),
        wheelchair: parseInt(values.capacity_wheelchairs, 10),
      },
      routing_engine: {
        ...routing_engine,
        routing_engine_name: values.routing_engine_name.value,
        road_network: values.road_network,
      },
      vehicle_color: parseInt(values.vehicle_color, 10),
      readOnly: values.readOnly.value,
    };
    D2.S.INFO('editVehicleRequest:newVehicle', { originalVehicle, newVehicle });

    newData.stateless_api_request_data.vehicles = [
      ...otherVehicles,
      newVehicle,
    ];
    D2.S.INFO('editVehicleRequest:Result', newData);

    const newOffer = yield validateCommuteOffer(newData);

    yield put({
      type: actions.COMMUTE_OFFER_EDIT_VEHICLE_SUCCESS,
      payload: newOffer,
    });

    if (values.start_time) {
      yield put({
        type: actions.SET_VEHICLE_START_TIME,
        payload: {
          vehicleId: newVehicle.agent_id,
          time: moment(values.start_time).tz(global.GEODISC_TIMEZONE).format(),
        },
      });
    }
    if (values.end_time) {
      yield put({
        type: actions.SET_VEHICLE_END_TIME,
        payload: {
          vehicleId: newVehicle.agent_id,
          time: moment(values.end_time).tz(global.GEODISC_TIMEZONE).format(),
        },
      });
    }

    yield put({
      type: actions.RECALCULATE_VEHICLE_TIME,
      payload: { vehicleIds: [newVehicle.agent_id] },
    });
  } catch (error) {
    yield put({
      type: actions.COMMUTE_OFFER_EDIT_VEHICLE_FAILURE,
      payload: error,
    });
  }
}

function* showVehicleSource({ payload }) {
  D2.S.INFO('showVehicleSource:Request', payload);

  const id = payload;

  const currentOffer = filterCommuteOfferByVehicle(
    yield select(commuteOfferCurrentDataSelector),
    id
  );

  const { stateless_api_request_data, result } = currentOffer;

  global.openSourceEditor(
    {
      stateless_api_request_data,
      result,
    },
    { title: `Vehicle ${id}`, readOnly: true }
  );
}

function* showVehicleUpdates({ payload }) {
  D2.S.INFO('showVehicleUpdates:Enter', payload);

  try {
    const id = payload;

    console.log(`${id || '---'} Calculating vehicle updates...`); // eslint-disable-line

    const originalOffer = removeInternalFieldsFromObject(
      filterCommuteOfferByVehicle(
        yield select(commuteOfferOriginalDataSelector),
        id
      )
    );
    const currentOffer = removeInternalFieldsFromObject(
      filterCommuteOfferByVehicle(
        yield select(commuteOfferCurrentDataSelector),
        id
      )
    );

    D2.S.INFO('showVehicleUpdates:Offers', {
      originalOffer,
      currentOffer,
    });

    global.openDiffEditor(originalOffer, currentOffer, {
      title: 'c.ModalWindow.DiffViewer.Title.VehicleUpdates',
    });

    console.log(diffCommuteOffers(id || '---', originalOffer, currentOffer)); // eslint-disable-line
    console.log(`${id || '---'} Done.`); // eslint-disable-line
  } catch (e) {
    console.log(e); // eslint-disable-line
  }
}

function* statelessNodeScheduler({ payload }) {
  D2.S.INFO('statelessNodeScheduler:Enter', payload);

  const { request, opts } = payload;

  const newRequest = {
    ...request,
    engine_settings: {
      ...request.engine_settings,
      solver_parameters: {
        ...request.engine_settings.solver_parameters,
        time_limit_ms: 15 * 1000,
      },
    },
  };

  try {
    // eslint-disable-next-line
    console.log({ newRequest, request });
    D2.S.INFO('statelessNodeScheduler:Request', { newRequest });
    const job = yield api.statelessNodeScheduler(newRequest);

    // eslint-disable-next-line
    console.log({ job });
    D2.S.INFO('statelessNodeScheduler:Job', { job });

    if (!job.job_id) {
      throw new Error('Failed to get job_id');
    }

    // eslint-disable-next-line no-constant-condition
    for (let attempt = 1; attempt <= 360; ++attempt) {
      const serverResponse = yield api.jobResult(job.job_id);
      // eslint-disable-next-line
      console.log({ serverResponse });

      if (serverResponse.status && serverResponse.status === 'FAILURE') {
        throw new Error(serverResponse.result || 'Unknown scheduler failure');
      }

      if (serverResponse.status && serverResponse.status === 'SUCCESS') {
        if (serverResponse.result && serverResponse.result.error) {
          throw new Error(serverResponse.result.error);
        }

        // D2.S.INFO('statelessNodeScheduler:Response', { serverResponse });
        // const validatedResult = yield normalizeCommuteOffer({
        //   result: serverResponse.result,
        // });

        D2.S.INFO('statelessNodeScheduler:Success', {
          serverResponse,
          // validatedResult,
        });
        yield opts.closePopup();
        yield put({
          type: actions.COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_SUCCESS,
          payload: serverResponse.result,
        });

        yield* fetchAllRoutesHandler();
        yield* fetchAllWalkingRoutes();

        yield sleep(500);
        return;
      }

      // eslint-disable-next-line no-await-in-loop
      yield sleep(1000);
    }
  } catch (error) {
    D2.S.INFO('statelessNodeScheduler:Error', { error });
    yield opts.closePopup();
    yield put({
      type: actions.COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_FAILURE,
      payload: error,
    });
    yield sleep(500);
  }
}

function* importBookings({ payload }) {
  D2.S.INFO('importBookings:Request', payload);

  try {
    const { currentOffer, data, params, opts } = payload;

    const projects = yield select(projectsSelector);
    const currentProject = yield select(currentProjectSelector);

    const newOffer = yield commuteSchedule$ImportPassengersSchedule(
      currentOffer,
      data,
      params,
      {
        ...opts,
        currentProject,
        projects,
      }
    );

    D2.S.INFO('importBookings:Success');
    yield put({
      type: actions.COMMUTE_OFFER_IMPORT_BOOKINGS_SUCCESS,
      payload: { newOffer },
    });

    yield fetchAllRoutesHandler();
    yield fetchAllWalkingRoutes();

    yield sleep(1);
  } catch (error) {
    D2.S.INFO('importBookings:Failure', { error });
    yield put({
      type: actions.COMMUTE_OFFER_IMPORT_BOOKINGS_FAILURE,
      payload: error,
    });
    throw error;
  }
}

function* fetchTransitStopSets() {
  D2.S.INFO('fetchTransitStopSets:Request');
  try {
    const projectId = yield select(currentProjectIdSelector);
    const data = yield call(api.getTransitStopSets, projectId);
    D2.S.INFO('fetchTransitStopSets:Success', data);
    yield put({
      type: actions.TRANSITSTOPSETS_FETCH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    D2.S.INFO('fetchTransitStopSets:Failure', error);
    yield put({ type: actions.TRANSITSTOPSETS_FETCH_FAILURE, payload: error });
  }
}

function* setResultOfferHandler({ payload }) {
  D2.S.INFO('setResultOfferHandler:Request', { payload });
  try {
    const { commuteOffer, mode } = payload;
    const newOffer = yield call(validateCommuteOffer, commuteOffer);

    D2.S.INFO('setResultOfferHandler:Success', { newOffer, payload });
    yield put({
      type: actions.SET_RESULT_OFFER_RESULTS,
      payload: { commuteOffer: newOffer, mode },
    });
  } catch (error) {
    D2.S.INFO('setResultOfferHandler:Failure', { error });
    yield put({ type: actions.SET_RESULT_OFFER_RESULTS, payload: { error } });
  }
}

function* confirmMatchedAddressHandler({ payload }) {
  D2.S.INFO('confirmMatchedAddressHandler:Request', { payload });
  try {
    const { commuteOffer, mode } = payload;
    const newOffer = yield call(validateCommuteOffer, commuteOffer);
    D2.S.INFO('confirmMatchedAddressHandler:Success', { newOffer, payload });
    yield put({
      type: actions.COMMUTE_OFFER_CONFIRM_MATCHED_ADDRESS_SUCCESS,
      payload: { newOffer, mode },
    });
  } catch (error) {
    D2.S.INFO('confirmMatchedAddressHandler:Failure', { error });
    yield put({
      type: actions.COMMUTE_OFFER_CONFIRM_MATCHED_ADDRESS_SUCCESS,
      payload: { error },
    });
  }
}

function* removeOrderHandler({ payload }) {
  D2.S.INFO('removeOrderHandler:Request', { payload });
  try {
    const { commuteOffer, mode, id, uid } = payload;
    const rejectedBookings = commuteOffer.result.rejected_bookings;
    const updatedBookings = rejectedBookings.filter(
      booking => booking.uid !== uid
    );
    const updatedCommuteOffer = {
      ...commuteOffer,
      result: {
        ...commuteOffer.result,
        rejected_bookings: updatedBookings,
      },
      stateless_api_request_data: {
        ...commuteOffer?.stateless_api_request_data,
        bookings: {
          ...commuteOffer?.stateless_api_request_data?.bookings,
          [uid]: {
            ...commuteOffer?.stateless_api_request_data.bookings[uid],
            is_invalidated: true,
          },
        },
      },
    };
    const newOffer = yield call(validateCommuteOffer, updatedCommuteOffer);
    yield call(invalidateBooking, {
      originalOffer: commuteOffer,
      id,
      booking_uid: uid,
    });

    D2.S.INFO('removeOrderHandler:Success', { newOffer, payload });
    yield put({
      type: actions.REMOVE_ORDER_SUCCESS,
      payload: { newOffer, mode },
    });
  } catch (error) {
    D2.S.INFO('removeOrderHandler:Failure', { error });
    yield put({
      type: actions.REMOVE_ORDER_FAILURE,
      payload: { error },
    });
  }
}

/* prettier-ignore-start */
function* Saga() {
  yield takeLatest(actions.SET_RESULT_OFFER_REQUEST, setResultOfferHandler);
  yield takeLatest(
    actions.COMMUTE_OFFER_FETCH_REQUEST,
    fetchCommuteOfferHandler
  );
  yield takeLatest(actions.COMMUTE_OFFERS_FETCH_REQUEST, fetchCommuteOffers);
  yield takeLatest(
    actions.UPDATE_COMMUTE_OFFER_REQUEST,
    updateCommuteOfferHandler
  );
  yield takeLatest(
    actions.INVALIDATE_ALL_BOOKINGS_REQUEST,
    invalidateAllBookingsHandler
  );
  yield takeLatest(
    actions.FINISH_INVALIDATE_ALL_BOOKINGS_REQUEST,
    finishInvalidateAllBooking
  );
  yield takeLatest(actions.DELETE_COMMUTE_OFFER_REQUEST, deleteCommuteOffer);
  yield takeEvery(actions.ROUTE_FETCH_REQUEST, fetchRouteHandler);
  yield takeEvery(actions.ROUTE_FETCH_SUCCESS, routeFetchSuccess);
  yield takeEvery(uiActions.SET_ACTIVE_VEHICLE_ID, setActiveVehicleId);
  yield takeLatest(uiActions.SET_DRAGGABLE_POINT, changeRouteStop);
  yield takeLatest(actions.CHANGE_ROUTE_ORDER, changeRouteOrder);
  yield takeLatest(actions.RECALCULATE_ROUTE_REQUEST, recalculateRouteHandler);
  yield takeLatest(
    actions.REMOVE_BOOKING_FROM_ROUTE,
    removeBookingFromRouteHandler
  );
  yield takeLatest(actions.REMOVE_ORDER_REQUEST, removeOrderHandler);
  yield takeEvery(
    actions.ADD_BOOKING_TO_ROUTE_REQUEST,
    addBookingToRouteHandler
  );
  yield takeLatest(actions.ADD_POINT_TO_ROUTE, addPointToRouteHandler);
  yield takeLatest(actions.ADD_STOP_TO_ROUTE, addStopToRoute);
  yield takeEvery(
    actions.RECALCULATE_VEHICLE_TIME,
    recalculateVehicleTimeHandler
  );
  // yield takeLatest(actions.SET_BOOKING_NODE, fetchCurrentRoute);
  // yield takeLatest(actions.SET_POINT_TO_ROUTE, fetchCurrentRoute);
  // yield takeLatest(actions.SET_STOP_TO_ROUTE, fetchCurrentRoute);
  // yield takeLatest(actions.CHANGE_ROUTE_POINT, fetchCurrentRoute);
  // yield takeLatest(actions.DELETE_ROUTE_POINT, fetchCurrentRoute);
  // yield takeLatest(actions.CHANGE_ROUTE_EMPTY_STOP, fetchCurrentRoute);
  yield takeLatest(uiActions.TOGGLE_ROUTING_ENGINE, fetchAllRoutesHandler);
  yield takeLatest(
    uiActions.TOGGLE_WALKING_ROUTING_ENGINE,
    fetchAllWalkingRoutes
  );
  yield takeLatest(actions.FETCH_ALL_ROUTES_REQUEST, fetchAllRoutesHandler);
  yield takeLatest(actions.DELETE_VEHICLE, deleteVehicle);
  yield takeLatest(actions.ADD_COMMUTE_OFFER_REQUEST, addCommuteOffer);
  yield takeLatest(
    actions.DUPLICATE_COMMUTE_OFFER_REQUEST,
    duplicateCommuteOffer
  );
  yield takeLatest(
    actions.COMMUTE_OFFER_ADD_VEHICLE_REQUEST,
    addVehicleRequest
  );
  yield takeLatest(
    actions.COMMUTE_OFFER_EDIT_VEHICLE_REQUEST,
    editVehicleRequest
  );
  yield takeLatest(actions.INC_SERIAL_NUMBER, incSerialNumber);
  yield takeLatest(
    actions.COMMUTE_OFFER_SHOW_VEHICLE_UPDATES,
    showVehicleUpdates
  );
  yield takeLatest(
    actions.COMMUTE_OFFER_SHOW_VEHICLE_SOURCE,
    showVehicleSource
  );
  yield takeLatest(
    actions.COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_REQUEST,
    statelessNodeScheduler
  );
  yield takeLatest(
    actions.COMMUTE_OFFER_IMPORT_BOOKINGS_REQUEST,
    importBookings
  );
  yield takeLatest(actions.TRANSITSTOPSETS_FETCH_REQUEST, fetchTransitStopSets);
  yield takeLatest(
    actions.COMMUTE_OFFER_CONFIRM_MATCHED_ADDRESS_REQUEST,
    confirmMatchedAddressHandler
  );
}
/* prettier-ignore-end */

export default Saga;

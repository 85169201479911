import { useTranslation } from 'react-i18next';
import editIcon from 'assets/edit.svg';
import newIcon from 'assets/new.svg';
import deleteIcon from 'assets/delete.svg';
import plusIcon from 'assets/plusCircle.svg';
import { styled } from 'baseui';
import CustomButton from 'components/Forms/Button';

const EditBtn = styled('img', () => {
  return {
    width: '15px',
    height: '15px',
    margin: '0px 10px',
  };
});

EditBtn.defaultProps = {
  src: editIcon,
};

const NewBtn = styled('img', () => {
  return {
    width: '15px',
    height: '15px',
    margin: '0px 10px',
  };
});

NewBtn.defaultProps = {
  src: newIcon,
};

const DeleteBtn = styled('img', () => {
  return {
    width: '15px',
    height: '15px',
    margin: '0px 10px',
  };
});

DeleteBtn.defaultProps = {
  src: deleteIcon,
};

const AddBtn = styled('img', () => {
  return {
    width: '15px',
    height: '15px',
    margin: '0 10px',
  };
});

AddBtn.defaultProps = {
  src: plusIcon,
};

const VehicleTypeWrapper = styled('div', ({ $theme }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '24px',
    paddingTop: '24px',
    borderBottom: `solid 1px ${$theme.colors.inputBorder}`,
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 700,
    color: $theme.colors.menuFontHighlighted,
  };
});

const VehicleTypes = (props) => {
  const { t } = useTranslation();
  const { vehicleTypes = [], projectData, updateVehicleType } = props;

  const vehicleTypesWithId = vehicleTypes.map((vehicleType, index) => {
    return { ...vehicleType, unique_id: index };
  });
  const deleteVehicleType = ({ vehicleType }) => {
    const projectDataJS = projectData.toJS();

    global.openWarningMessage({
      title: t('c.MasterSettings.content.vehicleTypes.warning.title'),
      message: t('c.MasterSettings.content.vehicleTypes.warning.message', {
        vehicleType: vehicleType?.id,
      }),
      buttons: [
        {
          text: t('c.messages.Cancel'),
          fill: false,
        },
        {
          text: t('p.Editor.Panels.Vehicles.Vehicle.Menu.Delete'),
          action: () => {
            global.closeWarningMessage();
            updateVehicleType({
              data: projectDataJS,
              deleteId: vehicleType?.id,
            });
          },
          fill: true,
        },
      ],
    });
  };

  return (
    <>
      <h1 style={{ margin: 0 }}>
        {t('c.MasterSettings.content.vehicleTypes.title')}
      </h1>
      <p>{t('c.MasterSettings.content.vehicleTypes.description')}</p>
      <div>
        {vehicleTypesWithId.map((vehicleType) => {
          return (
            <VehicleTypeWrapper key={vehicleType?.unique_id}>
              <span>{vehicleType?.id}</span>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span
                  onClick={() => {
                    global.openVehicleTypeEditor({
                      data: {
                        ...vehicleType,
                      },
                      mode: 'edit',
                    });
                  }}
                >
                  <EditBtn />
                </span>
                <span
                  onClick={() => {
                    global.openVehicleTypeEditor({
                      data: {
                        ...vehicleType,
                        id: `${vehicleType?.id || ''} Copy`,
                      },

                      mode: 'clone',
                    });
                  }}
                >
                  <NewBtn />
                </span>
                <span onClick={() => deleteVehicleType({ vehicleType })}>
                  <DeleteBtn />
                </span>
              </div>
            </VehicleTypeWrapper>
          );
        })}
      </div>
      <CustomButton
        onClick={() => {
          global.openVehicleTypeEditor();
        }}
        style={{
          marginTop: '35px',
          marginLeft: '0',
          width: '210px',
          padding: '10px 0',
          fontWeight: 500,
        }}
      >
        <AddBtn /> {t('c.MasterSettings.content.vehicleTypes.addButton')}
      </CustomButton>
    </>
  );
};

export default VehicleTypes;

import * as actions from 'modules/ui/actions';

import debug from 'utils/debug';

const D2 = debug('m:ui:reducers:ui');

const vehiclesFilterChanged = (state, payload) =>
  D2.S.FUNCTION('vehiclesFilterChanged', { payload }, () => {
    return state.setIn(['commuteOffer', 'vehiclesFilter'], payload);
  });

vehiclesFilterChanged.type = actions.VEHICLES_FILTER_CHANGED;

export default vehiclesFilterChanged;

import functions from './functions';

const sgerp = (api) => {
  return Object.entries(functions).reduce(
    (memo, [name, func]) => ({
      ...memo,
      [name]: api
        ? (...args) => func(api, ...args)
        : () => ({ error: { status: 403 } }),
    }),
    {}
  );
};

export default sgerp;

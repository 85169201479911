import React from 'utils/react';

import Selectable from 'components/Selectable';

import Container from './Container';
import Overflow from './Overflow';
import RowContainer from './RowContainer';
import withTranslation from 'utils/react/withTranslation';

const errorRenderers = {
  exception: (err) => {
    return <div>{err.message || (err.error && err.error.message)}</div>;
  },
  test: err => err.message,
  'request.vehicles.DuplicateAgentId': (record) => {
    return (
      <div>
        <h2>Two vehicles have the same agent_id (must be unique)</h2>
        <br />
        Agent ID: <Selectable>{record.prevVehicle.agent_id}</Selectable>
        <br />
        {false && (
          <React.Fragment>
            Vehicle #1 ID: <Selectable>{record.prevVehicle.id}</Selectable>
            <br />
            Vehicle #2 ID: <Selectable>{record.currVehicle.id}</Selectable>
            <br />
          </React.Fragment>
        )}
      </div>
    );
  },
  'node.booking_uid.InvalidType': (record) => {
    return (
      <div>
        <h2>Node contains an invalid booking_uid (must be a string)</h2>
        <br />
        Node: <Selectable>{record.node.uid}</Selectable>
        <br />
        Value: <Selectable>{record.node.booking_uid}</Selectable>
        <br />
        Type:{' '}
        <Selectable>
          {typeof record.node.booking_uid} (string expected)
        </Selectable>
      </div>
    );
  },
  'node.node_status.InvalidType': (record) => {
    return (
      <div>
        <h2>Node contains an invalid status</h2>
        <br />
        Node: <Selectable>{record.node.uid}</Selectable>
        <br />
        Value: <Selectable>{record.node.status}</Selectable>
        <br />
        Vehicle id: <Selectable>{record.node.assigned_vehicle_id}</Selectable>
        <br />
      </div>
    );
  },
  'node.stop_id.InvalidType': (record) => {
    return (
      <div>
        <h2>
          Node contains an invalid stop id (must be a valid Masstransit id)
        </h2>
        <br />
        Booking: <Selectable>{record.node.booking_uid}</Selectable>
        <br />
        Node: <Selectable>{record.node.uid}</Selectable>
        <br />
        Stop ID: <Selectable>{record.node.stop_id}</Selectable>
      </div>
    );
  },
  'result.assigned_bookings.stop_id.InvalidType': (record) => {
    return (
      <div>
        <h2>
          Assigned booking record contains an invalid stop id (must be a valid
          Masstransit id)
        </h2>
        <br />
        UID: <Selectable>{record.record.uid}</Selectable>
        <br />
        Assigned vehicle:{' '}
        <Selectable>{record.record.assigned_vehicle_id}</Selectable>
        <br />
        Pickup Stop ID:{' '}
        <Selectable>{record.record.scheduled_pickup_stop_id}</Selectable>
        <br />
        Dropoff Stop ID:{' '}
        <Selectable>{record.record.scheduled_dropoff_stop_id}</Selectable>
      </div>
    );
  },
  'result.vehicles.stop_id.InvalidType': (record) => {
    return (
      <div>
        <h2>
          Vehicle path contains an invalid stop id (must be a valid Masstransit
          id)
        </h2>
        <br />
        Vehicle:&nbsp;
        <Selectable>{record.agent_id}</Selectable>
        <br />
        Booking:&nbsp;
        <Selectable>{record.node.booking_uid}</Selectable>
        <br />
        UID:&nbsp;
        <Selectable>{record.node.uid}</Selectable>
        <br />
        Type:&nbsp;
        <Selectable>{record.node.node_type}</Selectable>
        <br />
        H3 Index:&nbsp;
        <Selectable>{record.node.h3}</Selectable>
        <br />
        Stop ID:&nbsp;
        <Selectable>{record.node.stop_id}</Selectable>
        <br />
        Location Code:&nbsp;
        <Selectable>{record.node.location_code}</Selectable>
        <br />
        Location Name:&nbsp;
        <Selectable>{record.node.location_name}</Selectable>
        <br />
        Lon:&nbsp;
        <Selectable>{record.node.lon}</Selectable>
        <br />
        Lat:&nbsp;
        <Selectable>{record.node.lat}</Selectable>
      </div>
    );
  },
};

const renderRecord = (record, t) =>
  errorRenderers[record.type]
    ? errorRenderers[record.type](record, t)
    : `Unknown Error: ${JSON.stringify(record)}`;

const Table = ({ errors, warnings, fixes, t }) => (
  <React.Fragment>
    <Overflow>
      <Container isLoading={!errors.length}>
        {[
          ...errors.map((record, i) => (
            <RowContainer key={`error-${i}`}>
              {renderRecord(record, t)}
            </RowContainer>
          )),
          ...warnings.map((record, i) => (
            <RowContainer key={`warning-${i}`}>
              {renderRecord(record, t)}
            </RowContainer>
          )),
          ...fixes.map((record, i) => (
            <RowContainer key={`fix-${i}`}>
              {renderRecord(record, t)}
            </RowContainer>
          )),
        ]}
      </Container>
    </Overflow>
  </React.Fragment>
);

export default withTranslation()(Table);

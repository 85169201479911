import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import {
  availableBookingsSelector,
  commuteOfferIsReadOnlySelector,
} from 'modules/commuteOffer/selectors';
import { removeBookingFromRoute } from 'modules/commuteOffer/actions';
import { setEditableBookingId } from 'modules/ui/actions';
import { activeBookingIdSelector } from 'modules/ui/selectors';
import { currentProjectLayoutNameSelector } from 'modules/user/selectors';

import Info from './Info';

const mapStateToProps = createSelector(
  availableBookingsSelector,
  activeBookingIdSelector,
  currentProjectLayoutNameSelector,
  commuteOfferIsReadOnlySelector,
  (bookings, activeBookingId, layoutName, commuteOfferIsReadOnly) => ({
    bookings,
    activeBookingId,
    layoutName,
    commuteOfferIsReadOnly,
  })
);

const mapDispatchToProps = {
  removeBookingFromRoute,
  setEditableBookingId,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Info);

import styled from 'styled-components';

const Value = styled.div`
  font-size: 20px;
  letter-spacing: 0;
  color: #edece8;
  text-align: end;
`;

export default Value;

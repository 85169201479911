import styled from 'styled-components';
import img from 'assets/bus_glif.svg';

const BusMarker = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 20px;
  background: ${({ color }) => color || '#000'} url(${img}) no-repeat center;
  border: 1px solid white;
`;

export default BusMarker;

import moment from 'moment-timezone';

const formatters = {
  en: value => moment.duration(Math.round(value), 'seconds').humanize(),
  jp: value =>
    `${Math.round(
      moment.duration(Math.round(value), 'seconds').as('minutes')
    )} 分`,
};

const formatDurationTime = (value, language) => {
  const formatter = formatters[language] || formatters.en;
  return formatter(value || 0, language);
};

export default formatDurationTime;

import styled from 'styled-components';

export default styled.div`
  position: fixed;
  bottom: 10px;
  left: ${({ isBookingsHidden }) => (isBookingsHidden ? '390' : '640')}px;

  padding: 9px 0px;
  margin-right: 10px;

  border: none;
  border-radius: 5px;

  color: #c5cede;
  font-size: 14px;
  font-weight: bold;

  outline: none;
`;

import React from 'utils/react';
import withDragDropContext from './withDragDropContext';

import Container from './Container';
import Bookings from './Bookings';
import Vehicles from './Vehicles';
import Footer from './Footer';
import Statistics from './Statistics';

const Panels = ({ isDeliveryLayout }) => (
  <Container>
    <Bookings />
    <Vehicles />
    {isDeliveryLayout && <Statistics />}
    <Footer />
  </Container>
);

export default withDragDropContext(Panels);

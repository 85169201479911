import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  position: relative;
  width: 450px;
  margin: auto;
  padding-bottom: 100px;
  flex-direction: column;
  align-items: center;
`;

export default Container;

import moment from 'moment-timezone';

import { applicationTimezone } from 'utils/timezone';

export const defaultVehicleRoutingServer = {
  // url: global.GEODISC_INTERNAL_OSRM_URL,
  // key: global.GEODISC_INTERNAL_OSRM_TOKEN
};

// export const defaultVehicleRoutingServer = {
//   url: window.GEODISC_OSRM_URL,
//   key: window.GEODISC_OSRM_TOKEN
// };

export const defaultVehicleRoutingServerJSON = JSON.stringify(
  defaultVehicleRoutingServer
);

export const defaultVehicleRoutingEngine = {
  ...defaultVehicleRoutingServer,
  make_depot_zero: true,
  road_network: global.GEODISC_OSRM_ROUTING_PROFILE,
  routing_engine_name: 'osrme',
  osrme_timestamp_mode: 'start_time',
  speed: null,
  time_factor: 1,
  use_speed_in_routing: false,
  batch_matrix_size: 250,
  curb: false,
};

export const defaultVehicleRoutingEngineJSON = JSON.stringify(
  defaultVehicleRoutingEngine
);

export const emptyCommuteOffer = {
  name: '<Empty>',
  result: {
    assigned_bookings: [],
    cost: 0,
    rejected_bookings: [],
    routes: {},
    vehicles: {},
    changelog: [],
    $errors: [],
    $warnings: [],
    $fixes: [],
  },
  commute_preferences: [],
  stateless_api_request_data: {
    bookings: {},
    current_time: moment().tz(applicationTimezone).format(),
    engine_settings: {
      calculation_parameters: {
        allow_vehicle_late: false,
        calculations_mode: 'async',
        cvrptw_artificial_nodes_per_vehicle: 0,
        max_possible_lateness: null,
        scheduling_mode: 'prebook',
        use_node_weights_cost: false,
        use_vehicles_nodes: false,
        vehicle_late_penalty_coefficient: 10,
        pickup_later: true,
        use_mixed_time_matrix: true,
      },
      model_parameters: {
        booking_penalty: 100000000,
        mixed_fleet: true,
        optimize_quantity: 'passenger_optimization',
        use_walking_time_to_reduce_time_windows: false,
        vehicle_costs: 100000,
        mutually_exclusive_groups: [],
        max_slack: 0,
      },
      routing_engine: { ...defaultVehicleRoutingEngine },
      solver_parameters: {
        algorithm: 'dynamic',
        cvb_fleetmin_iterations_limit: 3000000,
        cvb_fleetmin_solutions_limit: 30000,
        cvb_fleetmin_time_limit: 10,
        cvb_local_search_iterations_limit: 100000,
        first_solution_strategy: 3,
        guided_local_search_lambda_coefficient: 0.1,
        lns_time_limit_ms: 100,
        log_search: true,
        optimization_step: 1,
        savings_neighbors_ratio: 0.5,
        solution_limit: 100000000,
        time_limit_ms: 1000 * 60 * 15,
        use_all_local_search_operators: false,
        use_cvb_local_search_operator: false,
        use_depth_first_search: false,
        use_local_search_metaheuristic: true,
        use_tsp_opt: true,
        waypoints_optimization_second_phase: false,
        waypoints_solution_limit: 1000,
      },
      demand_settings: {
        ambulant: {
          ambulant: 1,
          passenger: 1,
        },
        wheelchair: {
          passenger: 1,
          wheelchair: 1,
        },
      },
    },
    nodes: [],
    vehicles: [],
    inbound: {
      schedule: {},
      offers: [],
    },
  },
  tags: [],
};

export const emptyCommuteOfferJSON = JSON.stringify(emptyCommuteOffer);

import React from 'utils/react';
import useTranslation from 'utils/react/useTranslation';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';

import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';

import debug from 'utils/debug';

const D2 = debug('c:ModalWindows:SourceEditor');

export default (props) => {
  D2.S.INFO('SourceEditor', props);

  const { t } = useTranslation();

  const [isOpen, setIsOpen] = React.useState(false);
  const [content, setContent] = React.useState('{}');
  const [title, setTitle] = React.useState(t('c.messages.Info'));
  const [config, setConfig] = React.useState({});

  React.useEffect(() => {
    D2.S.INFO('Update', { props });

    window.openSourceEditor = (value, opts) => {
      D2.S.INFO('openSourceEditor', { value, opts });
      const options = opts || {};

      const source =
        typeof value === 'string' ? value : JSON.stringify(value, '\n', 4);
      D2.S.INFO('content', { content });

      setTitle(options.title || t('c.messages.Info'));
      setContent(source);
      setConfig(options);
      setIsOpen(true);
    };
    window.closeSourceEditor = () => {
      D2.S.INFO('closeSourceEditor', {
        isOpen,
        title,
        content,
        config,
      });
      setIsOpen(false);
    };
    return () => {
      D2.S.INFO('Cleanup');
      window.closeSourceEditor = null;
    };
  });

  function onClose() {
    D2.S.INFO('onClonse', { isOpen, title, content, config });

    setIsOpen(false);
  }

  function onSave() {
    D2.S.INFO('onSave', { isOpen, title, content, config });

    if (config.onSave) {
      setConfig({});
      config.onSave(content);
    }

    setIsOpen(false);
  }

  return (
    <React.Fragment>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        overrides={{
          Dialog: {
            style: {
              width: '900px',
              height: '80vh',
              display: 'flex',
              flexDirection: 'column',
            },
          },
        }}
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <AceEditor
            mode='json'
            theme='github'
            value={content}
            defaultValue={content}
            onChange={setContent}
            name='ace'
            width='100%'
            height='100%'
            setOptions={{
              useWorker: false,
            }}
            editorProps={{ $blockScrolling: true }}
          />
        </ModalBody>
        <ModalFooter>
          <ModalButton onClick={onClose}>{t('c.messages.Close')}</ModalButton>
          {!config.readOnly && config.onSave && (
            <ModalButton onClick={onSave}>{t('c.messages.Save')}</ModalButton>
          )}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { countSelector } from 'modules/commuteOffer/selectors';

import Panel from './Panel';

const mapStateToProps = createImmutableSelector(countSelector, count => ({
  count,
}));

const mapDispatchToProps = null;

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Panel);

import * as actions from 'modules/commuteOffer/actions';

import debug from 'utils/debug';

const D2 = debug('m:ui:reducers:commuteOffer');

const nodesSchedulerSuccess = (state, payload) =>
  D2.S.FUNCTION('nodesSchedulerSuccess', { payload }, () => {
    return state.setIn(['commuteOffer', 'isChanged'], true);
  });

nodesSchedulerSuccess.type =
  actions.COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_SUCCESS;

export default nodesSchedulerSuccess;

import React from 'react';
import { LabelWrapper, Info } from './FormStyles';
import { Block } from 'baseui/block';
import { StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';

const FormLabel = ({ label, name, infoHover }) => {
  return (
    <LabelWrapper>
      <label htmlFor={name}>{label}</label>
      {infoHover && (
        <React.Fragment>
          <StatefulPopover
            overrides={{
              Body: {
                style: ({ $theme }) => ({
                  borderRadius: '8px',
                  padding: '8px 12px 8px',
                  background: '#2E353E',
                }),
              },
              Inner: {
                style: ({ $theme }) => ({
                  background: '#2E353E',
                }),
              },
            }}
            content={() => (
              <Block
                dangerouslySetInnerHTML={{ __html: infoHover }}
                style={{
                  color: '#FFFFFF',
                  fontSize: 12,
                }}
              ></Block>
            )}
            triggerType={TRIGGER_TYPE.hover}
            returnFocus
            autoFocus
          >
            <span data-for={name} style={{ margin: '0 0 0 10px' }}>
              <Info />
            </span>
          </StatefulPopover>
        </React.Fragment>
      )}
    </LabelWrapper>
  );
};

export default FormLabel;

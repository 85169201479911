import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';
import { deleteVehicle } from 'modules/fleet/actions';
import withTranslation from 'utils/react/withTranslation';
import {
  dataOnSuccessSelector,
  vehiclesSelector,
} from 'modules/fleet/selectors';
import {
  commuteOfferCurrentDataSelector,
  commuteOfferIsReadOnlySelector,
} from 'modules/commuteOffer/selectors';
import Fleet from './Fleet';
import { isSuperuserSelector } from 'modules/user/selectors';
import { driversSelector } from '../../modules/entities/selectors';

const mapStateToProps = createImmutableSelector(
  vehiclesSelector,
  dataOnSuccessSelector,
  commuteOfferIsReadOnlySelector,
  commuteOfferCurrentDataSelector,
  isSuperuserSelector,
  driversSelector,
  (vehicles, success, isReadOnly, commuteOffer, isSuperUser, drivers) => ({
    vehicles,
    success,
    isReadOnly,
    commuteOffer,
    isSuperUser,
    drivers,
  })
);

const mapDispatchToProps = {
  deleteVehicle,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation()(withConnect(Fleet));

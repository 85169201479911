import createImmutableSelector from 'utils/immutable-selector';
import { createSelector } from 'reselect';

import { featureCollection } from '@turf/helpers';

import { stopSearchQuerySelector } from 'modules/ui/selectors';

import debug from 'utils/debug';

const D2 = debug('m:entities:selectors');

export const entitiesSelector = (state) => {
  D2.S.INFO('entitiesSelector:Request', { state });

  const res = state.get('entities');

  D2.S.INFO('entitiesSelector:Success', { state, result: res });
  return res;
};

export const stopsSelector = createImmutableSelector(
  entitiesSelector,
  (entities) => {
    D2.S.INFO('stopsSelector:Request');

    const result = entities.get('stops');

    D2.S.INFO('stopsSelector:Success', result);
    return result;
  }
);

export const stopsByTypeSelector = createImmutableSelector(
  entitiesSelector,
  (entities) => {
    D2.S.INFO('stopsByTypeSelector:Request');

    const result = entities.get('stopsByType');

    D2.S.INFO('stopsByTypeSelector:Success', result);
    return result;
  }
);

export const filteredStopsSelector = createImmutableSelector(
  stopsSelector,
  stopSearchQuerySelector,
  (stops, stopSearchQuery) => {
    D2.S.INFO('filteredStopsSelector:Request', { stops, stopSearchQuery });

    if (!stops || !stopSearchQuery) {
      D2.S.INFO('filteredStopsSelector:Success', {
        stops,
        stopSearchQuery,
        result: [],
      });
      return [];
    }

    const res = Object.keys(stops).reduce((acc, key) => {
      const query = stopSearchQuery.toLowerCase();

      if (stops[key].properties.name.toLowerCase().match(query)) {
        acc.push({ name: stops[key].properties.name, id: key });
      }
      return acc;
    }, []);

    D2.S.INFO('filteredStopsSelector:Success', {
      stops,
      stopSearchQuery,
      result: res,
    });
    return res;
  }
);

export const stopsSourceSelector = createSelector(stopsSelector, (stops) => {
  D2.S.INFO('stopsSourceSelector:Request', { stops });

  const res = stops
    ? featureCollection(
        Object.keys(stops).map(key => ({
          id: stops[key].properties.id,
          ...stops[key],
        }))
      )
    : featureCollection([]);

  D2.S.INFO('stopsSourceSelector:Success', { stops, result: res });
  return res;
});

export const stopTypesSelector = createSelector(
  stopsByTypeSelector,
  (stopsByType) => {
    D2.S.INFO('stopTypesSelector:Request', { stopsByType });

    const res = stopsByType ? Object.keys(stopsByType) : [];

    D2.S.INFO('stopTypesSelector:Success', { stopsByType, result: res });
    return res;
  }
);

export const driversSelector = createSelector(entitiesSelector, (entities) => {
  D2.S.INFO('driversSelector:Request');

  const result = entities.get('drivers') || [];

  D2.S.INFO('driversSelector:Success', result);
  return result;
});

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';
import {
  commuteOfferAddVehicleRequest,
  commuteOfferEditVehicleRequest,
} from 'modules/commuteOffer/actions';

import { commuteOfferCurrentDataSelector } from 'modules/commuteOffer/selectors';

import { currentProjectUrlSelector } from 'modules/user/selectors';

import CommuteOfferVehicleEditor from './CommuteOfferVehicleEditor';

const mapDispatchToProps = {
  commuteOfferAddVehicleRequest,
  commuteOfferEditVehicleRequest,
};

const mapStateToProps = createImmutableSelector(
  commuteOfferCurrentDataSelector,
  currentProjectUrlSelector,
  (data, projectUrl) => ({ data, projectUrl })
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommuteOfferVehicleEditor);

import { styled } from 'baseui';

const Container = styled('div', ({ $theme }) => ({
  background: $theme.colors.negative,
  border: '1px solid #fff',
  borderRadius: '10px',
}));
const Text = styled('div', ({ $theme }) => ({
  ...$theme.typography.montserrat,
  fontSize: '10px',
  padding: '3px 4px',
}));
export default ({ children }) => {
  return (
    <Container>
      <Text>{children}</Text>
    </Container>
  );
};

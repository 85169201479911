import React from 'utils/react';
import ReactDOM from 'react-dom';
import * as Icons from 'baseui/icon/icon-exports';
import styled from 'styled-components';

import { getNodeAction } from 'api/logistics';

const BackDrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export function useInterval(callback, delay) {
  const savedCallback = React.useRef();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function Portal(props) {
  return ReactDOM.createPortal(props.children, document.body);
}

function PODSection({ node }) {
  const [showModal, setShowModal] = React.useState(false);
  const [modalImageURL, setModalImageURL] = React.useState(null);
  const [nodeAction, setNodeAction] = React.useState(null);
  const [modalLoading, setModalLoading] = React.useState(true);

  const fetchData = () => {
    if (node?.id) {
      getNodeAction(node.id).then((nodeAct) => {
        setNodeAction(nodeAct);
      });
    }
  };

  React.useEffect(fetchData, [node?.id]);
  const numberOfPodStatuses = nodeAction?.objects?.length;
  const completedObject =
    nodeAction?.objects?.find(
      object => object?.action_type === 'proof_of_delivery'
    ) || [];

  // Poll every minute
  // TODO: use websocket in the future
  useInterval(fetchData, numberOfPodStatuses ? null : 60_000);

  if (numberOfPodStatuses) {
    return (
      <React.Fragment>
        {completedObject.image_urls.map(url => (
          // eslint-disable-next-line
          <img
            key={url}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setModalImageURL(url);
              setShowModal(true);
            }}
            src={url}
            width={24}
            height={24}
            style={{
              objectFit: 'cover',
              margin: 4,
              backgroundColor: 'rgba(255,255,255,.05)',
            }}
            alt=''
          />
        ))}
        <Portal>
          {showModal && !!modalImageURL && (
            <BackDrop
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                {/* eslint-disable-next-line */}
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowModal(false);
                    setModalImageURL(null);
                    setModalLoading(false);
                  }}
                >
                  <Icons.Delete size={24} />
                </a>
                <img
                  src={modalImageURL}
                  alt=''
                  style={{
                    maxWidth: '80vw',
                    maxHeight: '80vh',
                    width: modalLoading ? 300 : '',
                    height: modalLoading ? 300 : '',
                    objectFit: 'contain',
                    backgroundColor: 'rgba(255,255,255,.05)',
                  }}
                  onLoad={() => {
                    setModalLoading(false);
                  }}
                />
              </div>
            </BackDrop>
          )}
        </Portal>
      </React.Fragment>
    );
  }

  return null;
}

export default PODSection;

import React from 'utils/react';
import Row from './Row';
import Container from './Container';
import Error from 'components/Error';
import Loading from 'components/Loading';

const Body = ({ list, error, ...props }) => {
  if (error) {
    return <Error />;
  }
  if (!list) {
    return <Loading />;
  }
  return (
    <Container>
      {list.map(value => (
        <Row key={value.id} commuteOffer={value} {...props} />
      ))}
    </Container>
  );
};

export default Body;

import React from 'utils/react';

import { Block } from 'baseui/block';
import { Button, KIND, SIZE } from 'baseui/button';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { StatefulMenu } from 'baseui/menu';

import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';

import debug from 'utils/debug';
import MenuIcon from './MenuIcon';

import ButtonContainer from './ButtonContainer';

const D2 = debug('p:CommuteOffer:Panels:MapStylesMenu');

class MapStylesMenu extends React.PureComponent {
  state = {};

  render() {
    D2.S.INFO('render', { props: this.props, MenuIcon });

    const { mapStyles, activeMapStyle, switchMapStyle, layoutName } =
      this.props;

    const mapStylesJs = mapStyles ? mapStyles.toJS() : [];
    D2.S.INFO('mapStylesJs', { mapStylesJs });

    const setChecked = (mapStyle, value) => {
      D2.S.INFO('setChecked', { mapStyle, value });

      switchMapStyle(mapStyle);
    };

    return (
      <StatefulPopover
        placement={
          layoutName === 'delivery' ? PLACEMENT.top : PLACEMENT.leftTop
        }
        content={() =>
          !mapStylesJs || !mapStylesJs.length ? (
            <StatefulMenu
              overrides={{
                Option: {
                  props: {
                    size: 'large',
                  },
                },
              }}
              items={[]}
            />
          ) : (
            <Block padding='10px' backgroundColor='menuFill'>
              {mapStylesJs &&
                mapStylesJs.map(item => (
                  <Checkbox
                    key={item.mapStyle}
                    checked={activeMapStyle === item.mapStyle}
                    onChange={e =>
                      setChecked(item.mapStyle, e.target.checked)
                    }
                    labelPlacement={LABEL_PLACEMENT.right}
                  >
                    {item.name}
                  </Checkbox>
                ))}
            </Block>
          )
        }
      >
        <ButtonContainer layoutName={layoutName}>
          <Button size={SIZE.compact} kind={KIND.minimal} disabled={!mapStyles}>
            <MenuIcon />
          </Button>
        </ButtonContainer>
      </StatefulPopover>
    );
  }
}

export default MapStylesMenu;

import styled from 'styled-components';

const Column = styled.div`
  display: flex;
  position: sticky;
  right: 0;
  align-items: center;
  width: 50px;
  padding-right: 20px;
  flex-shrink: 0;
  background-color: rgb(54, 61, 74);
  z-index: 1;
  height: 100%;

  div {
    opacity: 0;
    width: 50px;
  }
`;

export default Column;

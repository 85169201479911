import styled from 'styled-components';

export default styled.input`
  display: inline-block;
  width: 279px;
  padding: 9px 30px 9px 15px;
  border: none;
  border-radius: 5px;

  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);

  font-size: 14px;
  color: #edece8;

  background-color: rgb(40, 45, 55);
`;

import withTranslation from 'utils/react/withTranslation';
import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { bookingReadySelector } from 'modules/commuteOffer/selectors';

import {
  isBookingsHiddenSelector,
  commuteOffersIsServiceDataSourceSelector,
  commuteOffersServiceDateSelector,
} from 'modules/ui/selectors';

import { commuteOfferRequestUpdate } from 'modules/commuteOffer/actions';

import { toggleBookingsHidden, setServiceDate } from 'modules/ui/actions';

import DateSelector from './DateSelector';
import { routePageAddressSelector } from 'modules/router/selectors';

const mapStateToProps = createImmutableSelector(
  isBookingsHiddenSelector,
  commuteOffersIsServiceDataSourceSelector,
  commuteOffersServiceDateSelector,
  routePageAddressSelector,
  bookingReadySelector,
  (
    isBookingsHidden,
    isServiceDataSource,
    serviceDate,
    pageAddress,
    finishOfferLoading
  ) => ({
    isBookingsHidden,
    isServiceDataSource,
    serviceDate,
    pageAddress,
    finishOfferLoading,
  })
);

const mapDispatchToProps = {
  toggleBookingsHidden,
  setServiceDate,
  commuteOfferRequestUpdate,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation()(withConnect(DateSelector));

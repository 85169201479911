import { put } from 'redux-saga/effects';
import { api$CommuteOffer as api } from 'api';

import updateSimulation from 'api/CommuteOffer/updateSimulation';

import debug from 'utils/debug';

import {
  normalizeCommuteOffer,
  normalizeCommuteOfferForExport,
} from 'utils/CommuteOffer';

import * as actions from 'modules/commuteOffer/actions';
import * as uiActions from 'modules/ui/actions';
import { toaster } from 'baseui/toast';

const D2 = debug('m:CommuteOffer:saga:updateCommuteOffer');

export const updateCommuteOfferMethod = async (
  id,
  data,
  originalData,
  showModals,
  t
) =>
  D2.A.FUNCTION('Method', { id, data, originalData }, async ({ $D2 }) => {
    const normalizedCommuteOffer = await normalizeCommuteOffer(data);
    $D2.S.INFO('normalizedCommuteOffer', {
      normalizedCommuteOffer,
    });

    if (normalizedCommuteOffer.result.$errors.length > 0) {
      throw new Error('This offer cannot be saved as it contains errors');
    }

    const exportableCommuteOffer = await normalizeCommuteOfferForExport(
      normalizedCommuteOffer
    );
    $D2.S.INFO('exportableCommuteOffer', {
      exportableCommuteOffer,
    });
    if (showModals) {
      global.openProgressWindow(t('c.Saving.progress'), { title: 'Info' });
    }
    if (data.$source?.simulation) {
      return updateSimulation(data, originalData);
    }
    return api.updateCommuteOffer(id, exportableCommuteOffer);
  });

// eslint-disable-next-line
export function* updateCommuteOfferHandler({ payload }) {
  D2.S.INFO('Handler:Request', { payload });
  const { id, data, originalData, showModals = true, t } = payload;
  try {
    yield put(uiActions.setIsSaving(true));

    const response = yield updateCommuteOfferMethod(
      id,
      data,
      originalData,
      showModals,
      t
    );
    if (showModals) {
      global.closeProgressWindow();

      // global.openInfoMessage('Saved successfully', { title: 'Info' });

      toaster.info(<>{t('p.Editor.CommuteOffer.SavedSuccessfully')}</>, {
        autoHideDuration: 3000,
        closeable: true,
      });
    }

    yield put(uiActions.setIsSaving(false));

    D2.S.INFO('Handler:Success', { response, payload });
    yield put({
      type: actions.UPDATE_COMMUTE_OFFER_SUCCESS,
      payload: response,
    });
  } catch (error) {
    D2.S.INFO('Handler:Failure', { error, payload });
    if (showModals) {
      global.closeProgressWindow();
      global.openInfoMessage(
        error.message || 'Failed to save a commute offer',
        {
          title: 'Error',
        }
      );
    }

    yield put(uiActions.setIsSaving(false));

    yield put({ type: actions.UPDATE_COMMUTE_OFFER_FAILURE, payload: error });
  }
}

import React from 'utils/react';
import debug from 'utils/debug';

import Button from 'pages/CommuteOffer/TextButton';

import Container from './Container';
import Time from './Time';
import RouteLine from './RouteLine';
import Stops from './Stops';
import Route from './Route';
import ButtonsWrap from './ButtonsWrap';
import Row from './Row';
import Close from './Close';
import withTranslation from 'utils/react/withTranslation';

const D2 = debug('p:CommuteOffer:Panels:Vehicles:Vehicle:BookingStopsEditor');

class BookingStopsEditor extends React.PureComponent {
  onUnassigned = () => {
    D2.S.INFO('onUnassigned');

    const { nodes, removeBookingFromRoute, cleanEditableBookingId } =
      this.props;
    removeBookingFromRoute(nodes.booking_uid);
    cleanEditableBookingId();
  };

  onClose = () => {
    D2.S.INFO('onClose');

    this.props.cleanEditableBookingId();
  };

  onRemoveNodePickup = () => {
    D2.S.INFO('onRemoveNodePickup');

    const { nodes, removeBookingNode } = this.props;
    removeBookingNode(nodes.booking_uid, 'pickup');
  };

  onRemoveNodeDropoff = () => {
    D2.S.INFO('onRemoveNodeDropoff');

    const { nodes, removeBookingNode } = this.props;
    removeBookingNode(nodes.booking_uid, 'dropoff');
  };

  render() {
    D2.S.INFO('render', { props: this.props });

    const { nodes, t } = this.props;

    return (
      <Container>
        <div>{`Edit Booking #${nodes.booking_uid}`}</div>
        <Route>
          <Time>
            <div>{nodes.pickup.scheduled_ts || '--:--'}</div>
            <div>{nodes.dropoff.scheduled_ts || '--:--'}</div>
          </Time>
          <RouteLine />
          <Stops>
            <Row>
              <div>{nodes.pickup.location_name || '---'}</div>
              <Close onClick={this.onRemoveNodePickup} />
            </Row>
            <Row>
              <div>{nodes.dropoff.location_name || '---'}</div>
              <Close onClick={this.onRemoveNodeDropoff} />
            </Row>
          </Stops>
        </Route>

        <ButtonsWrap>
          <Button onClick={this.onUnassigned}>
            {t('c.BookingStopsEditor.Unassigned')}
          </Button>
          <Button onClick={this.onClose}>
            {t('c.BookingStopsEditor.Close')}
          </Button>
        </ButtonsWrap>
      </Container>
    );
  }
}

export default withTranslation()(BookingStopsEditor);

import { fromJS } from 'immutable';
import { map } from 'config';
import geoViewport from '@mapbox/geo-viewport';
import * as commuteOfferActions from 'modules/commuteOffer/actions';
import debug from 'utils/debug';
import * as actions from './actions';

const D2 = debug('m:maps:index');

export const initialState = fromJS({
  lastProcessedDataHash: null,
  commuteOffer: {},
  dataset: {},
  geofences: {},
  newGeofence: {},
  simulation: {},
})
  .setIn(['commuteOffer', 'viewport'], map.initialViewState)
  .setIn(['dataset', 'viewport'], map.initialViewStateDataset)
  .setIn(['geofences', 'viewport'], map.initialViewState)
  .setIn(['newGeofence', 'viewport'], map.initialViewState)
  .setIn(['simulation', 'viewport'], map.initialViewState);

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case commuteOfferActions.COMMUTE_OFFER_FETCH_RESULTS: {
      return D2.S.REDUX_EVENT_HANDLER2(type, payload, state, ({ $D2 }) => {
        const { error } = payload;
        if (error) {
          return state;
        }

        const { result } = payload;

        const lastProcessedDataHash = state.get('lastProcessedDataHash');

        if (result.loadedHash === lastProcessedDataHash) {
          return state;
        }

        const { projectRecord, commuteOffer } = result;

        if (!projectRecord) {
          return state;
        }

        const project = projectRecord.toJS();

        const bbox = commuteOffer.stateless_api_request_data.nodes
          .filter(node => node.lat && node.lon)
          .reduce(
            (memo, node) =>
              $D2.S.V(
                'nodes.reduce',
                { node, memo },
                {
                  lon: {
                    min: !memo.lon.min
                      ? node.lon
                      : Math.min(memo.lon.min, node.lon),
                    max: !memo.lon.max
                      ? node.lon
                      : Math.max(memo.lon.max, node.lon),
                  },
                  lat: {
                    min: !memo.lat.min
                      ? node.lat
                      : Math.min(memo.lat.min, node.lat),
                    max: !memo.lat.max
                      ? node.lat
                      : Math.max(memo.lat.max, node.lat),
                  },
                }
              ),
            { lon: { min: null, max: null }, lat: { min: null, max: null } }
          );

        const { center, zoom } = geoViewport.viewport(
          [bbox.lon.min, bbox.lat.min, bbox.lon.max, bbox.lat.max],
          [300, 200],
          0, // minZoom
          11 // maxZoom
        );

        const { lon, lat } = project;

        const viewport = {
          longitude: center[0] || lon || map.initialViewState.longitude,
          latitude: center[1] || lat || map.initialViewState.latitude,
          zoom,
          pitch: 0,
          bearing: 0,
        };

        $D2.S.INFO('viewport', { viewport, bbox, lon, lat, project });

        // don't set ['geofences', 'viewport'] if it was set once
        // if it was done before, skip it, to prevent "Map zooms out after some time by itself" bug
        // https://swatmobile.atlassian.net/browse/SP-2943
        if (lastProcessedDataHash) {
          return state
            .set('lastProcessedDataHash', result.loadedHash)
            .setIn(['geofences', 'viewport'], viewport);
        }

        return state
          .set('lastProcessedDataHash', result.loadedHash)
          .setIn(['commuteOffer', 'viewport'], viewport)
          .setIn(['geofences', 'viewport'], viewport);
      });
    }
    case actions.CHANGE_VIEWPORT_COMMUTE_OFFER: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const viewport = payload;
      return state.setIn(['commuteOffer', 'viewport'], viewport);
    }
    case actions.CHANGE_VIEWPORT_SIMULATION: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const viewport = payload;
      return state.setIn(['simulation', 'viewport'], viewport);
    }
    case actions.CHANGE_VIEWPORT_DATASET: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const viewport = payload;
      return state.setIn(['dataset', 'viewport'], viewport);
    }
    case actions.CHANGE_VIEWPORT_GEOFENCES: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const viewport = payload;
      return state.setIn(['geofences', 'viewport'], viewport);
    }
    case actions.CHANGE_VIEWPORT_NEW_GEOFENCE: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const viewport = payload;
      return state.setIn(['newGeofence', 'viewport'], viewport);
    }
    case actions.CHANGE_VIEWPORT_ALL_MAPS: {
      D2.S.REDUX_EVENT_HANDLER(type, payload);

      const { latitude, longitude } = payload;
      return state
        .setIn(['commuteOffer', 'viewport', 'latitude'], latitude)
        .setIn(['commuteOffer', 'viewport', 'longitude'], longitude)
        .setIn(['dataset', 'viewport', 'latitude'], latitude)
        .setIn(['dataset', 'viewport', 'longitude'], longitude)
        .setIn(['simulation', 'viewport', 'latitude'], latitude)
        .setIn(['simulation', 'viewport', 'longitude'], longitude)
        .setIn(['geofences', 'viewport', 'latitude'], latitude)
        .setIn(['geofences', 'viewport', 'longitude'], longitude)
        .setIn(['newGeofence', 'viewport', 'latitude'], latitude)
        .setIn(['newGeofence', 'viewport', 'longitude'], longitude);
    }
    default: {
      return state;
    }
  }
};

export default reducer;

import styled from 'styled-components';

const Container = styled.div`
  margin: 10px;
  cursor: pointer;
  color: #c5cede;

  :hover {
    color: white;
  }
`;

export default Container;

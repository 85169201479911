import moment from 'moment-timezone';

import distanceBetweenPoints from '@turf/distance';
import { point } from '@turf/helpers';

import normalizeTime from 'utils/normalize-time';
import { removeInternalFieldsFromObject } from 'utils/object';

import CSV from 'comma-separated-values';
import { exportFile } from 'utils/file';

import debug from 'utils/debug';
import { Map } from 'immutable';
import getColumnLabelsForLanguage from './getColumnLabelsForLanguage';
import { convertDemandLoadsToDisplay } from 'utils/Logistics/conversions';

const D2 = debug('u:Logistics:OrdersTable');

function* numberGenerator() {
  let value = 1;
  while (42) {
    yield value++;
  }
}

const exportLogisticsOrders = (data, simulation, opts = {}) =>
  D2.S.FUNCTION(
    'exportLogisticsOrders',
    { data, simulation, opts },
    ({ $D2 }) => {
      const {
        t = m => m,
        language = 'en',
        projectName = 'Unnamed',
        exportColumnMap = Map({}),
      } = opts;
      try {
        const defaultColumnNames = getColumnLabelsForLanguage(language);

        const columnNames = {
          ...defaultColumnNames,
          ...exportColumnMap.toJS(),
        };

        const timezone = global.GEODISC_TIMEZONE;
        const { name, stateless_api_request_data } = data;
        if (stateless_api_request_data.bookings.length === 0) {
          global.openInfoMessage(t('p.Editor.Menu.Orders.NothingToExport'), {
            title: t('info'),
          });
          return;
        }

        const formatNodeStatus = (status = '') => {
          const key = `c.messages.Node.Status.${status}`;
          const translatedStatus = t(key);
          return translatedStatus !== key ? translatedStatus : '';
        };

        // Find the maximum number of demand types
        let maxNumberOfDemandTypes = 0;
        Object.keys(stateless_api_request_data.bookings || {}).map(
          (booking_uid) => {
            const booking = stateless_api_request_data.bookings[booking_uid];
            const demand = booking.demand;
            const demandArray = Object.keys(demand || {});
            if (demandArray.length > maxNumberOfDemandTypes) {
              maxNumberOfDemandTypes = demandArray.length;
            }
          }
        );

        const ordersDisplay =
          simulation?.data.logistics_api_settings.orders_display ||
          'dropoff_only';

        const orders = Object.keys(stateless_api_request_data.bookings)
          .reduce((memo, booking_uid) => {
            const booking = stateless_api_request_data.bookings[booking_uid];
            const bookingNodes = stateless_api_request_data.nodes.filter(
              node => node.booking_uid === booking_uid
            );

            const failedToDeliverTime = bookingNodes
              .filter(node => node.failed_to_deliver_at_ts)
              .map(node =>
                moment(node.failed_to_deliver_at_ts)
                  .tz(timezone)
                  .format('DD/MM/YYYY HH:mm:ss')
              )
              .join('; ');

            const pickupNode = bookingNodes.find(
              node => node.node_type === 'pickup'
            );
            const dropoffNode = bookingNodes.find(
              node => node.node_type === 'dropoff'
            );
            const $assigned_vehicle =
              dropoffNode && dropoffNode.assigned_vehicle_id
                ? stateless_api_request_data.vehicles.find(
                    vehicle =>
                      vehicle.agent_id === dropoffNode.assigned_vehicle_id
                  )
                : null;

            if (!pickupNode || !dropoffNode) {
              return memo;
            }

            const date = pickupNode
              ? moment(pickupNode.open_time_ts)
                  .tz(timezone)
                  .format('YYYY-MM-DD')
              : moment(simulation.start_time).tz(timezone).format('YYYY-MM-DD');

            const normalizeFormattedTs = time =>
              time && time !== 'Invalid date' ? time : '';

            const $scheduled_pickup_ts = $assigned_vehicle
              ? moment(normalizeTime(pickupNode.scheduled_ts, timezone))
              : null;
            const $actual_pickup_ts =
              $assigned_vehicle && pickupNode?.completed_service_at
                ? moment(
                    normalizeTime(pickupNode.completed_service_at, timezone)
                  )
                : null;

            const $scheduled_dropoff_ts = $assigned_vehicle
              ? moment(normalizeTime(dropoffNode.scheduled_ts, timezone))
              : null;
            const $completed_dropoff_ts =
              $assigned_vehicle && dropoffNode?.completed_service_at
                ? moment(
                    normalizeTime(dropoffNode.completed_service_at, timezone)
                  )
                : null;
            const $failed_to_deliver_dropoff_ts =
              $assigned_vehicle && dropoffNode?.failed_to_deliver_at_ts
                ? moment(
                    normalizeTime(dropoffNode.failed_to_deliver_at_ts, timezone)
                  )
                : null;
            const $actual_dropoff_ts = $completed_dropoff_ts;

            const columnNumberGenerator = numberGenerator();

            const cid = () => columnNumberGenerator.next().value;

            const normalizeGroups = (groups) => {
              // console.log('*** groups', { groups });
              return groups && Array.isArray(groups) ? groups.join(', ') : '';
            };

            // console.log('*** Booking', {
            //   pickupNode,
            //   dropoffNode,
            //   booking,
            // });

            const trackingPageURL = global.GEODISC_TRACKING_PAGE_URL
              ? `${global.GEODISC_TRACKING_PAGE_URL}/?code=${simulation.id}_${booking_uid}`
              : undefined;

            const customDemandData = pickupNode?.demand;
            const customDemandLoadsAndTypes = {};

            const demandTypes = Object.keys(customDemandData || {});

            for (let i = 0; i < maxNumberOfDemandTypes; i++) {
              const convertedTypeAndValue = convertDemandLoadsToDisplay(
                demandTypes[i],
                customDemandData[demandTypes[i]]
              );
              customDemandLoadsAndTypes[
                columnNames?.demand_type_dynamic + (i + 1)
              ] = convertedTypeAndValue.type;
              customDemandLoadsAndTypes[
                columnNames?.demand_load_dynamic + (i + 1)
              ] = convertedTypeAndValue.value;
            }

            return [
              ...memo,
              {
                [columnNames.date ?? cid()]: date,

                [columnNames.external_id ?? cid()]:
                  booking.data?.external_id || '',

                [columnNames.name ?? cid()]: booking.data?.customer_name || '',

                [columnNames.name2 ?? cid()]:
                  booking.data?.customer_name2 || '',

                [columnNames.phone_number ?? cid()]:
                  booking.data.customer_phone || '',

                [columnNames.vehicle_service_number ?? cid()]: $assigned_vehicle
                  ? $assigned_vehicle.service_number ||
                    $assigned_vehicle.agent_id
                  : '',

                [columnNames.pickup_customer_name ?? cid()]:
                  booking.data.pickup_customer_name || '',
                [columnNames.pickup_customer_name2 ?? cid()]:
                  booking.data.pickup_customer_name2 || '',
                [columnNames.pickup_customer_phone ?? cid()]:
                  booking.data.pickup_customer_phone || '',

                [columnNames.pickup_zip_code ?? cid()]:
                  booking.data?.pickup_postal_code || '',
                [columnNames.pickup_unit_number ?? cid()]:
                  booking.data?.pickup_unit_number || '',
                [columnNames.pickup_address ?? cid()]: pickupNode.location_name,
                [columnNames.pickup_address2 ?? cid()]: '',
                [columnNames.pickup_location_lat ?? cid()]: pickupNode.lat,
                [columnNames.pickup_location_lon ?? cid()]: pickupNode.lon,

                [columnNames.pickup_open_time_ts ?? cid()]: moment(
                  normalizeTime(pickupNode.open_time_ts)
                )
                  .tz(timezone)
                  .format('HH:mm'),
                [columnNames.pickup_open_date_ts ?? cid()]: moment(
                  normalizeTime(pickupNode.open_time_ts)
                )
                  .tz(timezone)
                  .format('DD/MM/YYYY'),
                [columnNames.pickup_close_time_ts ?? cid()]: moment(
                  normalizeTime(pickupNode.close_time_ts)
                )
                  .tz(timezone)
                  .format('HH:mm'),
                [columnNames.pickup_close_date_ts ?? cid()]: moment(
                  normalizeTime(pickupNode.close_time_ts)
                )
                  .tz(timezone)
                  .format('DD/MM/YYYY'),
                [columnNames.pickup_scheduled_ts ?? cid()]: $scheduled_pickup_ts
                  ? normalizeFormattedTs(
                      $scheduled_pickup_ts.tz(timezone).format('HH:mm')
                    )
                  : '',
                [columnNames.pickup_scheduled_date_ts ?? cid()]:
                  $scheduled_pickup_ts
                    ? normalizeFormattedTs(
                        $scheduled_pickup_ts.tz(timezone).format('DD/MM/YYYY')
                      )
                    : '',

                [columnNames.pickup_status ?? cid()]: formatNodeStatus(
                  pickupNode.status
                ),

                [columnNames.pickup_completed_ts ?? cid()]: $actual_pickup_ts
                  ? normalizeFormattedTs(
                      $actual_pickup_ts.tz(timezone).format('HH:mm')
                    )
                  : '',

                [columnNames.dropoff_zip_code ?? cid()]:
                  booking.data?.dropoff_postal_code || '',
                [columnNames.dropoff_unit_number ?? cid()]:
                  booking.data?.dropoff_unit_number || '',
                [columnNames.dropoff_address ?? cid()]:
                  dropoffNode.location_name,
                [columnNames.dropoff_address2 ?? cid()]: '',
                [columnNames.dropoff_location_lat ?? cid()]: dropoffNode.lat,
                [columnNames.dropoff_location_lon ?? cid()]: dropoffNode.lon,

                [columnNames.dropoff_open_time_ts ?? cid()]: moment(
                  normalizeTime(dropoffNode.open_time_ts)
                )
                  .tz(timezone)
                  .format('HH:mm'),
                [columnNames.dropoff_open_date_ts ?? cid()]: moment(
                  normalizeTime(dropoffNode.open_time_ts)
                )
                  .tz(timezone)
                  .format('DD/MM/YYYY'),
                [columnNames.dropoff_close_time_ts ?? cid()]: moment(
                  normalizeTime(dropoffNode.close_time_ts)
                )
                  .tz(timezone)
                  .format('HH:mm'),
                [columnNames.dropoff_close_date_ts ?? cid()]: moment(
                  normalizeTime(dropoffNode.close_time_ts)
                )
                  .tz(timezone)
                  .format('DD/MM/YYYY'),
                [columnNames.dropoff_scheduled_ts ?? cid()]:
                  $scheduled_dropoff_ts
                    ? normalizeFormattedTs(
                        $scheduled_dropoff_ts.tz(timezone).format('HH:mm')
                      )
                    : '',
                [columnNames.dropoff_scheduled_date_ts ?? cid()]:
                  $scheduled_dropoff_ts
                    ? normalizeFormattedTs(
                        $scheduled_dropoff_ts.tz(timezone).format('DD/MM/YYYY')
                      )
                    : '',
                [columnNames.dropoff_completed_ts ?? cid()]: $actual_dropoff_ts
                  ? normalizeFormattedTs(
                      $actual_dropoff_ts.tz(timezone).format('HH:mm')
                    )
                  : '',
                [columnNames.dropoff_completed_date_ts ?? cid()]:
                  $actual_dropoff_ts
                    ? normalizeFormattedTs(
                        $actual_dropoff_ts.tz(timezone).format('DD/MM/YYYY')
                      )
                    : '',
                [columnNames.dropoff_status ?? cid()]: formatNodeStatus(
                  $failed_to_deliver_dropoff_ts &&
                    dropoffNode.status !== 'completed'
                    ? 'fail_to_deliver'
                    : dropoffNode.status
                ),

                [columnNames.failed_to_deliver_time ?? cid()]:
                  failedToDeliverTime,

                [columnNames.pickup_service_time ?? cid()]:
                  pickupNode.service_time,

                [columnNames.dropoff_service_time ?? cid()]:
                  dropoffNode.service_time,

                [columnNames.remarks ?? cid()]: booking.data?.remarks || '',

                [columnNames.tracking_page_url ?? cid()]: trackingPageURL || '',
                ...customDemandLoadsAndTypes,
                $assigned_vehicle,
                $scheduled_dropoff_ts,
                $actual_dropoff_ts,
                $actual_pickup_ts,
              },
            ];
          }, [])
          .sort((a, b) => {
            if (
              a?.$assigned_vehicle?.service_number &&
              b?.$assigned_vehicle?.service_number
            ) {
              if (
                a?.$assigned_vehicle?.service_number !==
                b?.$assigned_vehicle?.service_number
              ) {
                return a?.$assigned_vehicle?.service_number >
                  b?.$assigned_vehicle?.service_number
                  ? 1
                  : -1;
              }

              if (ordersDisplay === 'pickup_only') {
                if (a?.$actual_pickup_ts && b?.$actual_pickup_ts) {
                  return a?.$actual_pickup_ts > b?.$actual_pickup_ts ? 1 : -1;
                } else if (a?.$actual_pickup_ts) {
                  return -1;
                }
              } else if (
                ordersDisplay === 'dropoff_only' ||
                ordersDisplay === 'pickup_and_dropoff'
              ) {
                if (a?.$actual_dropoff_ts && b?.$actual_dropoff_ts) {
                  return a?.$actual_dropoff_ts > b?.$actual_dropoff_ts ? 1 : -1;
                } else if (a?.$actual_dropoff_ts) {
                  return -1;
                }
              }
            } else if (a?.$assigned_vehicle?.service_number) {
              return -1;
            }

            return 1;
          });
        $D2.S.INFO('orders', {
          orders,
          data,
        });

        const publicOrders = removeInternalFieldsFromObject(orders);
        $D2.S.INFO('publicOrders', {
          publicOrders,
          orders,
          data,
        });

        const csv = new CSV(publicOrders, {
          header: true,
        }).encode();
        $D2.S.INFO('csv', {
          csv,
          publicOrders,
          orders,
          data,
        });
        exportFile(csv, `${projectName} - ${name}.csv`);
      } catch (e) {
        // console.log(e);
        global.openInfoMessage(t('p.Editor.Menu.Orders.FailedToExport'), {
          title: t('error'),
        });
      }
    }
  );

export default exportLogisticsOrders;

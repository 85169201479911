import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { changeCommuteOffersOrdering } from 'modules/ui/actions';
import { fetchCommuteOffers } from 'modules/commuteOffer/actions';

import { commuteOffersOrderingSelector } from 'modules/ui/selectors';

import Header from './Header';

const mapStateToProps = createImmutableSelector(
  commuteOffersOrderingSelector,
  ordering => ({
    ordering,
  })
);

const mapDispatchToProps = {
  changeCommuteOffersOrdering,
  clearList: () => {},
  fetchCommuteOffers,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Header);

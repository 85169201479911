import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';
import withTranslation from 'utils/react/withTranslation';

import {
  serialNumberSelector,
  commuteOfferCurrentDataSelector,
  commuteOfferLoadedSimulationSelector,
  commuteOfferIsReadOnlySelector,
  bookingReadySelector,
  availableVehiclesSelector,
} from 'modules/commuteOffer/selectors';

import {
  projectsSelector,
  currentProjectSelector,
  currentProjectIdSelector,
  currentProjectLayoutNameSelector,
  defaultPageSelector,
} from 'modules/user/selectors';

import { stopsSelector } from 'modules/entities/selectors';
import { cleanCommuteOfferUi } from 'modules/ui/actions';

import {
  fetchCommuteOffer,
  commuteOfferRequestUpdate,
  clearCommuteOffer,
  incSerialNumber,
  incPermissionsSerialNumber,
  fetchTransitStopSets,
} from 'modules/commuteOffer/actions';
import { fetchDrivers } from 'modules/entities/actions';

import { geofencesSelector } from 'modules/geofences/selectors';
import { fetchGeofences } from 'modules/geofences/actions';

import { routeIdSelector } from 'modules/router/selectors';

import { setFleetData, setVehicleTypes } from 'modules/fleet/actions';

// import { clearProjects, fetchProjects } from 'modules/user/actions';

import debug from 'utils/debug';
import Logistics from './Logistics';
import {
  vehicleTypeLoadingSelector,
  vehicleTypeModifiedAtSelector,
} from 'modules/fleet/selectors';

const D2 = debug('p:Logistics');

global.GEODISC_ALWAYS_FETCH_GEOFENCES_ON_OPEN = true;
global.GEODISC_ALWAYS_FETCH_COMMUTE_OFFER_ON_OPEN = true;

const mapStateToProps = createImmutableSelector(
  commuteOfferIsReadOnlySelector,
  serialNumberSelector,
  projectsSelector,
  currentProjectIdSelector,
  commuteOfferCurrentDataSelector,
  stopsSelector,
  geofencesSelector,
  routeIdSelector,
  commuteOfferLoadedSimulationSelector,
  currentProjectLayoutNameSelector,
  defaultPageSelector,
  currentProjectSelector,
  availableVehiclesSelector,
  bookingReadySelector,
  vehicleTypeLoadingSelector,
  vehicleTypeModifiedAtSelector,
  (
    isReadOnly,
    serialNumber,
    projects,
    projectId,
    data,
    stops,
    geofences,
    id,
    simulation,
    layoutName,
    defaultPage,
    projectData,
    vehicles,
    finishOfferLoading,
    loading,
    modified_at
  ) => ({
    isReadOnly,
    serialNumber,
    projects,
    projectId,
    data,
    stops,
    geofences,
    id,
    simulation,
    layoutName,
    defaultPage,
    projectData,
    vehicles,
    finishOfferLoading,
    loading,
    modified_at,
  })
);

const mapDispatchToProps = {
  // clearProjects,
  // fetchProjects,
  fetchCommuteOffer,
  commuteOfferRequestUpdate,
  clearCommuteOffer,
  fetchDrivers,
  fetchGeofences,
  fetchTransitStopSets,
  cleanCommuteOfferUi,
  incSerialNumber,
  incPermissionsSerialNumber,
  setFleetData,
  setVehicleTypes,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation()(withConnect(Logistics));

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: 25px;

  div {
    margin: 5px;
    margin-right: 5px;
  }
`;

export default Container;

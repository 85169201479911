import React from 'utils/react';
import debug from 'utils/debug';

import { commuteSchedule$FixedScheduleGenerators } from 'utils/CommuteSchedule';

import { Formik } from 'formik';
import moment from 'moment-timezone';

import Input from 'components/Input';
import InputSelect from 'components/InputSelect';
import DateTimePicker from 'components/DateTimePicker';

import ResetButton from 'components/ResetButton';
import SubmitButton from 'components/SubmitButton';

import Row from './Row';
import ButtonsRow from './ButtonsRow';

import getVehicleSetsConfig from './HardcodedVehicleSets';
import getTimezonesConfig from './HardcodedTimezones';

const D2 = debug('c:ModalWindow:CommuteScheduleImportPassengers:Preview:index');

const handleKeyDown = (event) => {
  if (event.keyCode === 13) {
    event.preventDefault();
    return false;
  }
  return true;
};

const calculationTimeValues = [
  ...[
    { label: '10 seconds', value: 10000 },
    { label: '30 seconds', value: 30000 },
  ],
  // eslint-disable-next-line prefer-spread
  ...Array.apply(null, Array(17)).map((x, index) => {
    const value = 1000 * 60 * (index + 1);
    return {
      label: `${moment
        .duration(Math.round(value + 1), 'milliseconds')
        .humanize()}`,
      value,
    };
  }),
];

const Preview = ({
  file,
  data,
  setProgress,
  onReset,
  uploadData,
  projectUrl,
}) => {
  D2.S.INFO('Preview', {
    file,
    data,
    setProgress,
    onReset,
    uploadData,
    projectUrl,
  });

  const vehicleSetsConfig = getVehicleSetsConfig(projectUrl);
  D2.S.INFO('Preview:vehicleSetsConfig', {
    vehicleSetsConfig,
  });

  const now = new Date();
  now.setHours(0, 0, 0, 0);

  const optimize_quantity_options = [
    { label: 'Total time', value: 'total_time' },
    {
      label: 'Passenger optimisation',
      value: 'passenger_optimization',
    },
  ];

  const initialValues = {
    name: file.name,
    from_date: now,
    till_date: now,
    ignore_errors: { label: 'No', value: false },
    clear_existing_data: { label: 'Yes', value: true },
    human_readable_uids: { label: 'Yes', value: true },
    node_scheduler_mode: { label: 'Enabled', value: true },
    areCalculationGroupsEnabled: { label: 'Disabled', value: false },
    areMutuallyExclusiveGroupsEnabled: { label: 'Enabled', value: true },
    areSpecialDemandsEnabled: { label: 'Disabled', value: false },
    fixedScheduleGeneratorType: {
      label: 'Sample day with all passengers (predefined vehicles)',
      value: 'Sample day with all passengers (predefined vehicles)',
    },
    vehiclesSource: vehicleSetsConfig[0],
    unassigned_only: { label: 'One vehicle for each booking', value: false },
    optimize_quantity: optimize_quantity_options[0],
    first_solution_strategy: { label: 'Automatic', value: 15 },
    source_file_timezone: getTimezonesConfig(projectUrl),
    time_limit_ms: { label: '10 seconds', value: 10000 },
    max_slack: '86400',
    fixedScheduleMaxSlack: '86400',
    timewindowBeforeScheduledTime: '600',
    timewindowAfterScheduledTime: '600',
    morningDestinationTimeWindowDuration: '60',
    eveningDestinationTimeWindowDuration: '60',
    maxTripDurationInSeconds: '7200',
    center_service_time: '0',
    home_service_time: '30',
    vehicle_passenger: '8',
    vehicle_wheelchair: '4',
    project: projectUrl,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        D2.S.INFO('onSubmit', values);
        uploadData(values, { setProgress });
      }}
    >
      {(props) => {
        const {
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          handleBlur,
        } = props;

        return (
          <form onSubmit={handleSubmit} autoComplete='off'>
            {false && (
              <Row>
                <Input
                  name='name'
                  type='text'
                  label='Name'
                  value={values.name}
                  onChange={handleChange}
                  handleBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                  autoComplete='off'
                />
              </Row>
            )}
            <Row>
              <InputSelect
                id='source_file_timezone'
                name='source_file_timezone'
                label='Source file timezone'
                options={moment.tz
                  .names()
                  .map(tz => ({ value: tz, label: tz }))}
                defaultValue={values.source_file_timezone}
                onKeyDown={handleKeyDown}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </Row>
            {false && (
              <Row>
                <DateTimePicker
                  name='from_date'
                  label='Start date'
                  format='MMMM D, YYYY'
                  time={false}
                  value={values.from_date}
                  onKeyDown={handleKeyDown}
                  onChange={setFieldValue}
                />
                <DateTimePicker
                  name='till_date'
                  label='End date'
                  format='MMMM D, YYYY'
                  time={false}
                  value={values.till_date}
                  onKeyDown={handleKeyDown}
                  onChange={setFieldValue}
                />
              </Row>
            )}
            {false && (
              <Row>
                <InputSelect
                  id='clear_existing_data'
                  name='clear_existing_data'
                  label='Clear existing data'
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  defaultValue={values.clear_existing_data}
                  onKeyDown={handleKeyDown}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </Row>
            )}
            <Row>
              <InputSelect
                id='ignore_errors'
                name='ignore_errors'
                label='Ignore records with errors'
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]}
                defaultValue={values.ignore_errors}
                onKeyDown={handleKeyDown}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </Row>
            {false && (
              <Row>
                <InputSelect
                  id='human_readable_uids'
                  name='human_readable_uids'
                  label='Generate human readable identifiers'
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  defaultValue={values.human_readable_uids}
                  onKeyDown={handleKeyDown}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </Row>
            )}
            {false && (
              <Row>
                <InputSelect
                  id='areCalculationGroupsEnabled'
                  name='areCalculationGroupsEnabled'
                  label='Calculation groups'
                  options={[
                    { label: 'Enabled', value: true },
                    { label: 'Disabled', value: false },
                  ]}
                  defaultValue={values.areCalculationGroupsEnabled}
                  onKeyDown={handleKeyDown}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
                <InputSelect
                  id='areMutuallyExclusiveGroupsEnabled'
                  name='areMutuallyExclusiveGroupsEnabled'
                  label='Mutually exclusive groups'
                  options={[
                    { label: 'Enabled', value: true },
                    { label: 'Disabled', value: false },
                  ]}
                  defaultValue={values.areMutuallyExclusiveGroupsEnabled}
                  onKeyDown={handleKeyDown}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
                <InputSelect
                  id='areSpecialDemandsEnabled'
                  name='areSpecialDemandsEnabled'
                  label='Special demands'
                  options={[
                    { label: 'Enabled', value: true },
                    { label: 'Disabled', value: false },
                  ]}
                  defaultValue={values.areSpecialDemandsEnabled}
                  onKeyDown={handleKeyDown}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </Row>
            )}
            {false && (
              <Row>
                <InputSelect
                  id='fixedScheduleGeneratorType'
                  name='fixedScheduleGeneratorType'
                  label='Fixed schedule generation strategy'
                  options={Object.keys(
                    commuteSchedule$FixedScheduleGenerators
                  ).map(name => ({
                    label: name,
                    value: name,
                  }))}
                  defaultValue={values.fixedScheduleGeneratorType}
                  onKeyDown={handleKeyDown}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </Row>
            )}
            <Row>
              <Input
                name='fixedScheduleMaxSlack'
                label='Maximum slack time for the fixed schedule generation, in seconds'
                value={values.fixedScheduleMaxSlack}
                onChange={handleChange}
                handleBlur={handleBlur}
                onKeyDown={handleKeyDown}
                autoComplete='off'
              />
            </Row>
            <Row>
              <Input
                name='timewindowBeforeScheduledTime'
                label='Time-window before scheduled time, in seconds'
                value={values.timewindowBeforeScheduledTime}
                onChange={handleChange}
                handleBlur={handleBlur}
                onKeyDown={handleKeyDown}
                autoComplete='off'
              />
              <Input
                name='timewindowAfterScheduledTime'
                label='Time-window after scheduled time, in seconds'
                value={values.timewindowAfterScheduledTime}
                onChange={handleChange}
                handleBlur={handleBlur}
                onKeyDown={handleKeyDown}
                autoComplete='off'
              />
            </Row>
            <Row>
              <InputSelect
                id='vehiclesSource'
                name='vehiclesSource'
                label='Vehicles source'
                options={vehicleSetsConfig}
                defaultValue={values.vehiclesSource}
                onKeyDown={handleKeyDown}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </Row>
            <Row>
              <InputSelect
                id='optimize_quantity'
                name='optimize_quantity'
                label='Optimize quantity'
                options={optimize_quantity_options}
                defaultValue={values.optimize_quantity}
                onKeyDown={handleKeyDown}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </Row>
            <Row>
              <Input
                name='center_service_time'
                label='Service duration at the center, in seconds'
                value={values.center_service_time}
                onChange={handleChange}
                handleBlur={handleBlur}
                onKeyDown={handleKeyDown}
                autoComplete='off'
              />
              <Input
                name='home_service_time'
                label='Service duration at home, in seconds'
                value={values.home_service_time}
                onChange={handleChange}
                handleBlur={handleBlur}
                onKeyDown={handleKeyDown}
                autoComplete='off'
              />
              {false && (
                <Input
                  name='max_slack'
                  label='Maximum slack time, in seconds'
                  value={values.max_slack}
                  onChange={handleChange}
                  handleBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                  autoComplete='off'
                />
              )}
            </Row>
            <Row>
              <Input
                name='morningDestinationTimeWindowDuration'
                label='Morning destination time-window duration, in seconds'
                value={values.morningDestinationTimeWindowDuration}
                onChange={handleChange}
                handleBlur={handleBlur}
                onKeyDown={handleKeyDown}
                autoComplete='off'
              />
              <Input
                name='eveningDestinationTimeWindowDuration'
                label='Evening destination time-window duration, in seconds'
                value={values.eveningDestinationTimeWindowDuration}
                onChange={handleChange}
                handleBlur={handleBlur}
                onKeyDown={handleKeyDown}
                autoComplete='off'
              />
            </Row>
            <Row>
              <Input
                name='maxTripDurationInSeconds'
                label='Open time-window duration, in seconds'
                value={values.maxTripDurationInSeconds}
                onChange={handleChange}
                handleBlur={handleBlur}
                onKeyDown={handleKeyDown}
                autoComplete='off'
              />
            </Row>
            {false && (
              <Row>
                <Input
                  name='vehicle_passenger'
                  label='Vehicle capacity'
                  value={values.vehicle_passenger}
                  onChange={handleChange}
                  handleBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                  autoComplete='off'
                />
                <Input
                  name='vehicle_wheelchair'
                  label='Wheelchair passengers'
                  value={values.vehicle_wheelchair}
                  onChange={handleChange}
                  handleBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                  autoComplete='off'
                />
              </Row>
            )}
            {false && (
              <Row>
                <InputSelect
                  id='node_scheduler_mode'
                  name='node_scheduler_mode'
                  label='Nodes scheduler'
                  options={[
                    { label: 'Enabled', value: true },
                    { label: 'Disabled', value: false },
                  ]}
                  defaultValue={values.node_scheduler_mode}
                  onKeyDown={handleKeyDown}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </Row>
            )}
            <Row>
              <InputSelect
                id='time_limit_ms'
                name='time_limit_ms'
                label='Calculation time limit'
                options={calculationTimeValues}
                defaultValue={values.time_limit_ms}
                onKeyDown={handleKeyDown}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </Row>
            <ButtonsRow>
              <ResetButton onClick={onReset}>
                Choose a different file
              </ResetButton>
              <SubmitButton type='submit'>Import bookings</SubmitButton>
            </ButtonsRow>
          </form>
        );
      }}
    </Formik>
  );
};

export default Preview;

import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  height: 30px;
  flex-shrink: 0;
  ${({ underline }) =>
    underline &&
    `
    border-bottom: 1px solid gray;
    margin-bottom: 30px;
  `}
  ${({ sticky }) =>
    sticky &&
    `
  position: sticky;
  left: 0;
  top: 100px;
  z-index: 1;
  background: rgb(54, 61, 74);
  `}
`;

export default Row;

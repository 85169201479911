import { useTranslation } from 'react-i18next';

import untranslatable from './untranslatable';

const useTranslationWrapper = (...args) => ({
  ...useTranslation(...args),
  d: value => untranslatable(value),
});

export default useTranslationWrapper;

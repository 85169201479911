import React from 'utils/react';
import LayerTypePanel from 'components/LayerTypePanel';
import Overflow from './Overflow';

const Points = React.lazy(() => import('./Points'));
const Arcs = React.lazy(() => import('./Arcs'));
const Hex = React.lazy(() => import('./Hex'));
const Clusters = React.lazy(() => import('./Clusters'));
const ClusterArc = React.lazy(() => import('./ClustersArc'));

const Component = (props) => {
  switch (props.activeLayer) {
    case 'points':
      return <Points {...props} />;
    case 'arcs':
      return <Arcs {...props} />;
    case 'clusters':
      return <Clusters {...props} />;
    case 'arcClusters':
      return <ClusterArc {...props} />;
    case 'hex_pickup':
      return <Hex {...props} />;
    case 'hex_dropoff':
      return <Hex {...props} />;
    default:
      return <Points {...props} />;
  }
};

const VisualizationPanel = (props) => {
  const { activeLayer, layerStyle, changeLayerPanel, toggleLayer } = props;

  return (
    <React.Fragment>
      <LayerTypePanel value={activeLayer} onChange={toggleLayer} />
      <Overflow>
        <React.Suspense fallback>
          <Component
            activeLayer={activeLayer}
            layerStyle={layerStyle}
            changeLayerPanel={changeLayerPanel}
            toggleLayer={toggleLayer}
          />
        </React.Suspense>
      </Overflow>
    </React.Fragment>
  );
};

export default VisualizationPanel;

const phoneNumberFunctorsByCountry = {
  SG: (phoneNumber) => {
    if (phoneNumber.length === 10 && phoneNumber.indexOf('65') === 0) {
      return `+${phoneNumber}`;
    }
    if (phoneNumber.length === 8) {
      return `+65${phoneNumber}`;
    }
    return phoneNumber;
  },
  JP: (phoneNumber) => {
    if (phoneNumber.length === 12 && phoneNumber.indexOf('81') === 0) {
      return `+${phoneNumber}`;
    }
    if (phoneNumber.length === 10) {
      return `+81${phoneNumber}`;
    }
    return phoneNumber;
  },
};

const normalizePhoneNumber = (phoneNumber, country) => {
  if (!phoneNumber) {
    return phoneNumber;
  }
  const functor = phoneNumberFunctorsByCountry[country];
  return functor ? functor(phoneNumber) : phoneNumber;
};

global.geodisc$normalizePhoneNumber = normalizePhoneNumber;

export default normalizePhoneNumber;

import styled from 'styled-components';

const Border = styled.div`
  width: 4px;
  background: ${({ color }) => color};
  border-radius: 4px;
  margin-right: 5px;
`;

export default Border;

import styled from 'styled-components';

export default styled.div`
  position: relative;
  transition: 0.3s;

  div:last-child {
    opacity: 0;
  }

  :hover {
    color: #dddddd;

    div:last-child {
      opacity: 0.7;

      :hover {
        opacity: 0.4;
      }
    }
  }
`;

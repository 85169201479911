// @flow

import * as React from 'react';
import { debounce } from 'debounce';

import debug from 'utils/debug';
import Form from './Form';
import Input from './Input';
import SearchResults from './SearchResults';

const D2 = debug('p:Logistics:TopButtons:Search');

type Props = {
  changeSearchQuery: Function,
  vehicles: Array<Vehicle>,
  setActiveVehicleId: Function,
  bookings: Array<Booking>,
  setActiveBookingId: Function,
  flyToCommuteOfferActiveBooking: Function,
  flyToCommuteOfferActiveVehicle: Function,
};

const Search = (props: Props) => {
  D2.S.INFO('Search', { props });

  const {
    t,
    changeSearchQuery,
    vehicles,
    setActiveVehicleId,
    bookings,
    setActiveBookingId,
    flyToCommuteOfferActiveBooking,
    flyToCommuteOfferActiveVehicle,
  } = props;

  const delayedChangeSearchQuery = debounce(changeSearchQuery, 300);

  const onChange = (event) => {
    event.persist();
    delayedChangeSearchQuery(event.target.value.toLowerCase());
  };

  return (
    <Form>
      <Input
        type='text'
        onChange={onChange}
        placeholder={t('p.Editor.Menu.Logistics.SearchPlaceholder')}
      />
      {/* TODO: rewrite this render */}
      {((vehicles && vehicles.length > 0) ||
        (bookings && bookings.length > 0)) && (
        <SearchResults
          vehicles={vehicles || []}
          bookings={bookings || []}
          setActiveVehicleId={setActiveVehicleId}
          setActiveBookingId={setActiveBookingId}
          flyToCommuteOfferActiveBooking={flyToCommuteOfferActiveBooking}
          flyToCommuteOfferActiveVehicle={flyToCommuteOfferActiveVehicle}
        />
      )}
    </Form>
  );
};

export default React.memo<Props>(Search);

import { call, put, takeLatest } from 'redux-saga/effects';
import { api$Entities as api } from 'api';
import debug from 'utils/debug';
import * as actions from './actions';

const D2 = debug('m:entities:saga');

function* fetchStops() {
  D2.S.INFO('fetchStops:Request');

  try {
    const stops = yield call(api.getStops);
    const stopsByType = stops.features.reduce((acc, feature) => {
      const { stop_type } = feature.properties;
      acc[stop_type] = acc[stop_type] || [];
      acc[stop_type].push(feature);
      return acc;
    }, {});

    D2.S.INFO('fetchStops:Success', { stops, stopsByType });
    yield put({
      type: actions.STOPS_FETCH_SUCCESS,
      payload: { stops, stopsByType },
    });
  } catch (error) {
    D2.S.INFO('fetchStops:Failure', { error });
    yield put({ type: actions.STOPS_FETCH_FAILURE, payload: error });
  }
}

function* fetchDrivers({ payload }) {
  D2.S.INFO('fetchDrivers:Request');
  const { projectId } = payload || {};

  try {
    let drivers = [];
    if (projectId) {
      drivers = yield call(api.getDriversForProject, projectId);
    }

    D2.S.INFO('fetchDrivers:Success', { drivers });
    yield put({
      type: actions.DRIVERS_FETCH_SUCCESS,
      payload: { drivers },
    });
  } catch (error) {
    D2.S.INFO('fetchDrivers:Failure', { error });
    yield put({ type: actions.DRIVERS_FETCH_FAILURE, payload: error });
  }
}

function* Saga() {
  yield takeLatest(actions.STOPS_FETCH_REQUEST, fetchStops);
  yield takeLatest(actions.DRIVERS_FETCH_REQUEST, fetchDrivers);
}

export default Saga;

import debug from 'utils/debug';
import { urls } from '../config';
import { fetchData } from './net';
import { getHeaders } from './headers';

const D2 = debug('api:commuteOffer');

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const buildingByZipCode = async (country, city, zip_code) => {
  D2.S.INFO('buildingByZipCode:Request', { country, city, zip_code });

  try {
    const response = await fetchData(
      urls.buildingByZipCode(country, city, zip_code),
      {
        headers: getHeaders(),
      }
    );

    if (!response.ok) {
      throw new Error(response.status);
    }

    const result = await response.json();

    D2.S.INFO('buildingByZipCode:Success', {
      zip_code,
      result,
    });
    return { country, city, zip_code, result };
  } catch (error) {
    D2.S.INFO('buildingByZipCode:Failure', { country, city, zip_code, error });
    throw error;
  }
};

export const buildingByAddress = async (country, city, address) => {
  D2.S.INFO('buildingByAddress:Request', { country, city, address });

  try {
    const response = await fetchData(
      urls.buildingByAddress(country, city, address),
      {
        headers: getHeaders(),
      }
    );

    if (!response.ok) {
      throw new Error(response.status);
    }

    const result = await response.json();

    D2.S.INFO('buildingByAddress:Success', {
      address,
      result,
    });
    return { country, city, address, result };
  } catch (error) {
    D2.S.INFO('buildingByAddress:Failure', { country, city, address, error });
    throw error;
  }
};

export const buildingByCode = async (code) => {
  D2.S.INFO('buildingByCode:Request', { code });

  try {
    const response = await fetchData(urls.buildingByCode(code), {
      headers: getHeaders(),
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const result = await response.json();

    D2.S.INFO('buildingByCode:Success', {
      code,
      result,
    });
    return { building_code: code, code, result };
  } catch (error) {
    D2.S.INFO('buildingByCode:Failure', { building_code: code, code, error });
    throw error;
  }
};

export const buildingById = async (id) => {
  D2.S.INFO('buildingById:Request', { id });

  try {
    const response = await fetchData(urls.buildingById(id), {
      headers: getHeaders(),
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const result = await response.json();

    D2.S.INFO('buildingById:Success', {
      id,
      result,
    });
    return { building_id: id, id, result };
  } catch (error) {
    D2.S.INFO('buildingById:Failure', { building_id: id, id, error });
    throw error;
  }
};

export const transitstopByCode = async (code) => {
  D2.S.INFO('transitstopByCode:Request', { code });

  try {
    const response = await fetchData(urls.transitstopByCode(code), {
      headers: getHeaders(),
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const result = await response.json();

    D2.S.INFO('transitstopByCode:Success', {
      code,
      result,
    });
    return { transitstop_code: code, code, result };
  } catch (error) {
    D2.S.INFO('transitstopByCode:Failure', {
      transitstop_code: code,
      code,
      error,
    });
    throw error;
  }
};

export const nearestStop = async (
  lon,
  lat,
  stopTypes,
  transitstopSets,
  search_radius
) => {
  D2.S.INFO('nearestStop:Request', {
    lon,
    lat,
    stopTypes,
    transitstopSets,
    search_radius,
  });

  try {
    const response = await fetchData(
      urls.nearestStop(lon, lat, stopTypes, transitstopSets, search_radius),
      {
        headers: getHeaders(),
      }
    );

    if (!response.ok) {
      throw new Error(response.status);
    }

    const result = await response.json();

    D2.S.INFO('nearestStop:Success', {
      lon,
      lat,
      stopTypes,
      transitstopSets,
      result,
    });
    return { lon, lat, stopTypes, transitstopSets, result };
  } catch (error) {
    D2.S.INFO('nearestStop:Failure', {
      lon,
      lat,
      stopTypes,
      transitstopSets,
      error,
    });
    throw error;
  }
};

export const findStopsByCoordinates = async (coordinates, opts) => {
  D2.S.INFO('findStopsByCoordinates:Request', { coordinates, opts });

  const {
    ignoreErrors = false,
    setProgress = async () => {},
    stopTypes = 'building',
    transitstopSets = null,
    searchRadius = null,
  } = opts;

  setProgress(0);

  const nearestStopRequestsResolved = [];
  const nearestStopRequestsRejected = [];

  const nearestStopRequests = coordinates.map((item) => {
    const lon = item[0];
    const lat = item[1];
    return nearestStop(lon, lat, stopTypes, transitstopSets, searchRadius).then(
      (result) => {
        nearestStopRequestsResolved.push({
          lon,
          lat,
          stopTypes,
          transitstopSets,
          searchRadius,
          result,
        });
      },
      (error) => {
        nearestStopRequestsRejected.push({
          lon,
          lat,
          stopTypes,
          transitstopSets,
          searchRadius,
          error,
        });
      }
    );
  });
  D2.S.INFO('findStopsByCoordinates:nearestStopRequests', {
    nearestStopRequests,
  });

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const progress =
      (nearestStopRequestsResolved.length +
        nearestStopRequestsRejected.length) /
      nearestStopRequests.length;
    D2.S.INFO('findStopsByCoordinates:nearestStopRequests:Progress', {
      nearestStopRequestsResolved,
      nearestStopRequestsRejected,
      nearestStopRequests,
      progress,
    });

    // eslint-disable-next-line no-await-in-loop
    await setProgress(progress);

    if (
      nearestStopRequestsResolved.length +
        nearestStopRequestsRejected.length ===
      nearestStopRequests.length
    ) {
      break;
    }
    // eslint-disable-next-line no-await-in-loop
    await sleep(500);
  }

  D2.S.INFO('findStopsByCoordinates:nearestStopRequests:Resolved', {
    nearestStopRequestsResolved,
  });
  D2.S.INFO('findStopsByCoordinates:nearestStopRequests:Rejected', {
    nearestStopRequestsRejected,
  });

  if (!ignoreErrors) {
    if (nearestStopRequestsRejected.length) {
      throw new Error(
        `Failed to find nearest stops for ${nearestStopRequestsRejected.length} points`
      );
    }
  }

  const stopsByCoordinates = nearestStopRequestsResolved.reduce((acc, stop) => {
    const { lat, lon } = stop;
    const ident = JSON.stringify({ lon, lat });
    acc[ident] = stop.result;
    return acc;
  }, {});
  D2.S.INFO('findStopsByCoordinates:Success', { stopsByCoordinates });

  return stopsByCoordinates;
};

import moment from 'moment';
import formatForDate from './formatForDate';

const formatForThisDate = (currentDate, time, timezone) =>
  formatForDate(currentDate, time, timezone);

export const removeTimezome = (datetimeString) => {
  let datetime = moment(datetimeString).zone('+00:00');
  return datetime.format('YYYY-MM-DDTHH:mm:ssZ');
};

export default formatForThisDate;

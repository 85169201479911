import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { datasetSelector } from 'modules/datasets/selectors';
import { deleteDataset } from 'modules/datasets/actions';
import { setEditableDataset } from 'modules/ui/actions';

import Menu from './Menu';

const mapStateToProps = createImmutableSelector(datasetSelector, dataset => ({
  dataset,
}));

const mapDispatchToProps = {
  deleteDataset,
  setEditableDataset,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Menu);

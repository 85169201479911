import { styled } from 'baseui';
import lock from 'assets/lock.svg';

export default styled('span', ({ $theme }) => {
  return {
    flex: '1 1 auto',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    wordBreak: 'break-all',
    ...$theme.typography.montserrat,
  };
});

import { connect } from 'react-redux';
import createImmutableSelector from 'utils/immutable-selector';

import { changeGeofencesSearch } from 'modules/ui/actions';

import { datasetsSearchSelector } from 'modules/ui/selectors';

import Search from './Search';

const mapStateToProps = createImmutableSelector(
  datasetsSearchSelector,
  text => ({
    text,
  })
);

const mapDispatchToProps = {
  onChange: changeGeofencesSearch,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Search);
